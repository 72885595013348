import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  modalBody: {
    display: 'flex',
    flexDirection: 'column'
  } as SxProps,

  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
  } as SxProps,

  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  } as SxProps
}

export default styles
