import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getAssignedVirtualCardDetailsApi } from './getAssignedVirtualCardDetails.api'
import getAssignedVirtualCardDetailsInitialState from './getAssignedVirtualCardDetails.initialState'
import {
  GetAssignedVirtualCardDetailsErrorResponse,
  GetAssignedVirtualCardDetailsRequestPayload
} from './getAssignedVirtualCardDetails.types'

export const getAssignedVirtualCardDetails = createAsyncThunk(
  urls.payments.getAssignedVirtualCardDetails,
  async (payload: GetAssignedVirtualCardDetailsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAssignedVirtualCardDetailsApi(request)

      onSuccess && onSuccess(response.data)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAssignedVirtualCardDetailsSlice = createSlice({
  name: urls.payments.getAssignedVirtualCardDetails,
  initialState: getAssignedVirtualCardDetailsInitialState,
  reducers: {
    resetGetAssignedVirtualCardDetails: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssignedVirtualCardDetails.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAssignedVirtualCardDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAssignedVirtualCardDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAssignedVirtualCardDetailsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getAssignedVirtualCardDetailsActions, reducer: getAssignedVirtualCardDetailsReducer } =
  getAssignedVirtualCardDetailsSlice
export const { resetGetAssignedVirtualCardDetails } = getAssignedVirtualCardDetailsActions
export default getAssignedVirtualCardDetailsSlice.reducer
