import { MessagingProvider } from '@/shared/components/Chat/context/Messaging.context'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { ModalProvider } from '../../shared/components/Modal/context/Modal.context.container'
import { SnackbarProvider } from '../../shared/components/Snackbar/context/SnackBar.context.container'
import App from '../App/App.container'

import Footer from '../../shared/components/Footer/Footer.component'
import Header from '../../shared/components/Header/Header.component'
import ScrollToTop from '../../shared/components/ScrollToTop/ScrollToTop.component'
import ChatProvider from '@/shared/components/Chat/components/ChatProvider/ChatProvider.component'

import store from '../../redux/store/store'
import reportWebVitals from '../../reportWebVitals'
import { FirebaseService } from '../../shared/functions/Firebase/firebase.functions'
import sentry from '../../shared/functions/Sentry/sentry.functions'
import theme from '../../shared/styles/themes/default.theme'

FirebaseService.configure()
sentry.initialize()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <MessagingProvider>
          <ChatProvider>
            <ScrollToTop />

            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <ModalProvider>
                <SnackbarProvider>
                  <Header />
                  <App />
                  <Footer />
                </SnackbarProvider>
              </ModalProvider>
            </LocalizationProvider>
          </ChatProvider>
        </MessagingProvider>
      </Router>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
