import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getAllItemRequestsForAdminApi } from './getAllItemRequestsForAdmin.api'
import getAllItemRequestsForAdminInitialState from './getAllItemRequestsForAdmin.initialState'
import {
  GetAllItemRequestsForAdminErrorResponse,
  GetAllItemRequestsForAdminRequestPayload
} from './getAllItemRequestsForAdmin.types'

export const getAllItemRequestsForAdmin = createAsyncThunk(
  `${urls.admin.getAllItemRequestsForAdmin}`,
  async (payload: GetAllItemRequestsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAllItemRequestsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAllItemRequestsForAdminSlice = createSlice({
  name: urls.admin.getAllItemRequestsForAdmin,
  initialState: getAllItemRequestsForAdminInitialState,
  reducers: {
    resetGetAllItemRequestsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }

    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllItemRequestsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAllItemRequestsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAllItemRequestsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAllItemRequestsForAdminErrorResponse
        state.success = null
      })
  }
})

export const { actions: getAllItemRequestsForAdminActions, reducer: getAllItemRequestsForAdminReducer } =
  getAllItemRequestsForAdminSlice
export const { resetGetAllItemRequestsForAdmin } = getAllItemRequestsForAdminActions
export default getAllItemRequestsForAdminSlice.reducer
