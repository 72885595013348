import React from 'react'

import { Box, Skeleton, Typography } from '@mui/material'

import Modal from '../../../../../../shared/components/Modal/Modal.component'

import env from '../../../../../../networkRequests/apiClient/env.config'
import { ModalButton } from '../../../../../../shared/components/Modal/Modal.types'
import date, { dateTimeFormats } from '../../../../../../shared/functions/Date/date.functions'
import status from '../../../../../../shared/functions/Status/status.dictionaries'
import { formatMoney, formatTruncatedCardNumber } from '../../../../../../shared/functions/String/string.functions'
import { getEmailBody } from '../../FlightItineraryAccordion.functions'
import virtualCardModalDictionary from './VirtualCardModal.dictionary'
import styles from './VirtualCardModal.styles'
import { VirtualCardModalProps } from './VirtualCardModal.types'

const VirtualCardModal: React.FC<VirtualCardModalProps> = (props) => {
  const { loadingVirtualCardDetails, virtualCardDetails, showVirtualCardModal, setShowVirtualCardModal } = props

  const getModalBody = () => {
    if (loadingVirtualCardDetails) {
      return (
        <Box sx={styles.assignedVirtualCardDetailsModalBodySkeletonContainer}>
          {[1, 2, 3, 4].map((_, i) => (
            <Skeleton key={i} variant="text" height={35} width="60%" />
          ))}
        </Box>
      )
    } else if (virtualCardDetails) {
      switch (virtualCardDetails.status.name) {
        case status.virtualCardStatus.virtualCardAssigned:
          return (
            <Box sx={styles.virtualCardDetailsContainer}>
              <Typography>Card Holder Name: {virtualCardDetails.cardHolderName}</Typography>

              <Typography>Card Number: {formatTruncatedCardNumber(virtualCardDetails.truncatedCardNumber)}</Typography>

              <Typography>
                Card Expiry: {date(virtualCardDetails.expirationDate).format(dateTimeFormats.date.medium)}
              </Typography>

              <Typography>
                Spending Limit: {formatMoney(virtualCardDetails.spendingLimit, virtualCardDetails.orderCurrency)}
              </Typography>
            </Box>
          )

        case status.virtualCardStatus.virtualCardExpired:
        case status.virtualCardStatus.virtualCardRemoved:
          return <Typography>{virtualCardModalDictionary.virtualCardExpired}</Typography>
      }
    } else {
      return <Typography>{virtualCardModalDictionary.virtualCardProcessing}</Typography>
    }
  }

  const getModalSubtitle = () => {
    if (virtualCardDetails) {
      let subtitle = ''

      switch (virtualCardDetails.status.name) {
        case status.virtualCardStatus.virtualCardAssigned:
          subtitle = virtualCardModalDictionary.virtualCardAssigned
          break

        case status.virtualCardStatus.virtualCardExpired:
        case status.virtualCardStatus.virtualCardRemoved:
          subtitle = virtualCardModalDictionary.virtualCardRemoved
          break
      }

      return subtitle
    }
  }

  const getModalPrimaryButton = () => {
    let primaryButton: ModalButton

    if (virtualCardDetails) {
      let emailBody = ''
      let emailSubject = ''

      switch (virtualCardDetails.status.name) {
        case status.virtualCardStatus.virtualCardExpired:
        case status.virtualCardStatus.virtualCardRemoved:
          emailSubject = 'Virtual Card Issue'

          emailBody = getEmailBody(virtualCardDetails)

          primaryButton = {
            label: 'Contact Support',
            onClick: () => {
              window.open(
                `mailto:${env.SUPPORT_EMAIL_ADDRESS}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`,
                '_blank'
              )
            }
          }
          break

        default:
          primaryButton = {
            label: 'See Full Card Details',
            onClick: () => {
              window.open(`${virtualCardDetails?.mercuryCardDetailsUrl}`, '_blank')
            }
          }
          break
      }
    } else {
      primaryButton = {
        label: 'Dismiss',
        onClick: () => setShowVirtualCardModal(false)
      }
    }

    return primaryButton
  }

  const getSecondaryButton = () => {
    if (virtualCardDetails) {
      return {
        label: 'Dismiss',
        onClick: () => setShowVirtualCardModal(false)
      }
    }
  }

  return (
    <Modal
      body={getModalBody()}
      title="Virtual Card"
      subtitle={getModalSubtitle()}
      open={showVirtualCardModal}
      onClose={() => setShowVirtualCardModal(false)}
      primaryButton={getModalPrimaryButton()}
      secondaryButton={getSecondaryButton()}
    />
  )
}

export default VirtualCardModal
