import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import { mobileXPadding, mobileYPadding } from '../../../layouts/MainLayout/MainLayout.constants'
import { fullWidthIgnorePadding } from '../../functions/Styling/styling.functions'
import theme from '../../styles/themes/default.theme'

const styles = {
  container: (hasChildren: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: hasChildren ? theme.spacing(3) : 0
    }
  }),

  content: {
    width: '100%'
  },

  stepperContainer: (noBackground: boolean, areArrowsPresent: boolean) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: areArrowsPresent ? 'space-between' : 'center',
    paddingTop: noBackground ? 0 : theme.spacing(5),
    paddingBottom: noBackground ? 0 : theme.spacing(5),
    backgroundColor: noBackground ? 'transparent' : theme.palette.grey[100],
    paddingLeft: areArrowsPresent ? theme.spacing(10) : 0,
    paddingRight: areArrowsPresent ? theme.spacing(10) : 0,
    borderTop: noBackground ? 'none' : `1px solid ${theme.palette.grey[200]}`,
    borderBottom: noBackground ? 'none' : `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('md')]: {
      width: fullWidthIgnorePadding(mobileXPadding),
      marginLeft: theme.spacing(-mobileXPadding),
      marginTop: theme.spacing(-mobileYPadding - 3),
      justifyContent: 'flex-start',
      paddingLeft: `calc(${theme.spacing(2)} - 1px)`,
      paddingRight: theme.spacing(2),
      paddingY: theme.spacing(2)
    }
  }),

  stepper: (stepperWidth: string, orientation: 'horizontal' | 'vertical') => ({
    width: stepperWidth,

    '& .MuiStep-root': {
      padding: orientation === 'vertical' ? 0 : '0 16px'
    },

    '& .active-step .MuiStepLabel-label': {
      color: '#1976d2',
      fontWeight: 'bold'
    },

    '& .inactive-step .MuiStepLabel-label': {
      color: '#bdbdbd',
      fontWeight: 'normal'
    },

    [theme.breakpoints.down('md')]: {
      ...(orientation === 'vertical'
        ? {
            display: 'flex'
          }
        : {
            display: 'none'
          })
    }
  }),

  stepLabelText: (orientation: 'horizontal' | 'vertical') => ({
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontWeight: orientation === 'vertical' ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
      fontSize: orientation === 'vertical' ? theme.typography.subtitle2.fontSize : theme.typography.subtitle1.fontSize
    }
  }),

  step: (stepsLength: number, orientation: 'horizontal' | 'vertical') => ({
    maxWidth: orientation === 'vertical' ? 'unset' : `${100 / stepsLength}%`,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      width: '100%'
    }
  }),

  stepLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(5)
  },

  stepLabelMobileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1)
  },

  stepDescriptionText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.fontWeightRegular
    }
  } as CSSProperties,

  navButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },

  navButtonContainerMobile: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1)
    }
  },

  navButton: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    color: theme.palette.primary.main
  },

  stepOfStepsText: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    marginLeft: theme.spacing(1)
  } as SxProps,

  stepContent: {
    [theme.breakpoints.down('md')]: {
      borderWidth: '3px',
      paddingLeft: 0,
      borderColor: theme.palette.blue[800]
    }
  } as SxProps,

  header: {
    minHeight: '100px',
    marginTop: theme.spacing(-3)
  }
}

export default styles
