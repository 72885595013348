import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateRequestForVirtualCardErrorResponse,
  CreateRequestForVirtualCardRequest,
  CreateRequestForVirtualCardSuccessResponse
} from './createRequestForVirtualCard.types'

export const createRequestForVirtualCardApi = async (
  request: CreateRequestForVirtualCardRequest
): Promise<CreateRequestForVirtualCardSuccessResponse> => {
  const response = await configureRequest({
    url: urls.payments.createRequestForVirtualCard,
    method: 'POST',
    data: request,
    params: {},
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateRequestForVirtualCardSuccessResponse
  } else {
    throw response as CreateRequestForVirtualCardErrorResponse
  }
}

export default createRequestForVirtualCardApi
