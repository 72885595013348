import { configureRequest, replacePathVariables } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  ConfirmOrderDeliveryErrorResponse,
  ConfirmOrderDeliveryRequest,
  ConfirmOrderDeliverySuccessResponse
} from './confirmOrderDelivery.types'

export const confirmOrderDeliveryApi = async (
  request: ConfirmOrderDeliveryRequest
): Promise<ConfirmOrderDeliverySuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.offers.confirmOrderDelivery, { offerRequestId: request.offerRequestId }),
    method: 'PUT',
    data: request,
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ConfirmOrderDeliverySuccessResponse
  } else {
    throw response as ConfirmOrderDeliveryErrorResponse
  }
}

export default confirmOrderDeliveryApi
