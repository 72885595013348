import React from 'react'

import { ReactComponent } from '@/shared/types/react.types'
import { InfoOutlined } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'
import {
  AccordionDetails,
  AccordionSlots,
  AccordionSummary,
  Box,
  Divider,
  Fade,
  Grid,
  Accordion as MuiAccordion,
  Typography
} from '@mui/material'

import Button from '../Button/Button.component'

import { ButtonOnClick } from '../Button/Button.types'
import styles from './Accordion.styles'
import { AccordionProps } from './Accordion.types'

const Accordion: ReactComponent<AccordionProps> = (props) => {
  const {
    header,
    body,
    footer,
    alert,
    expandIconPosition = 'left',
    noExpandIcon = false,
    noExpandBackgroundColor = false,
    isExpanded = false,
    preventExpandChange = false,
    handleExpansion,
    accordionKey,
    hasBorder,
    hasPadding,
    extraMarginBottom,
    extraPaddingBottom,
    separateHeader = false
  } = props

  const [expanded, setExpanded] = React.useState(isExpanded)

  const handleAccordionExpansion = () => {
    setExpanded((prevExpanded) => {
      handleExpansion && handleExpansion(!prevExpanded, accordionKey)

      return !prevExpanded
    })
  }

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    onClick: ButtonOnClick,
    expandAccordion: boolean
  ) => {
    event.stopPropagation()
    onClick()

    if (expandAccordion) {
      handleAccordionExpansion()
    }
  }

  const renderFooter = () => {
    let footerContent = null
    let content = null
    let mainContent = null
    let buttons = null

    if (footer) {
      mainContent = footer.mainContent
      buttons = footer.buttons
    } else if (alert) {
      mainContent = alert.message
      buttons = alert.buttons
    }

    if (footer || alert) {
      content = (
        <Box sx={styles.accordionFooterContent(!!alert)} data-testid={alert?.testId}>
          {footer ? (
            mainContent
          ) : (
            <Typography sx={styles.accordionFooterAlertText(alert?.type)}>
              <InfoOutlined sx={styles.accordionFooterAlertIcon} /> {mainContent}
            </Typography>
          )}

          <Box sx={styles.accordionFooterButtons}>
            {buttons?.map((buttonProps, index) => (
              <Button
                key={`footer-button-${index}`}
                {...buttonProps}
                data-testid={buttonProps.testId}
                testId={undefined}
                loading={buttonProps.loading}
                disabled={buttonProps.disabled}
                style={styles.accordionFooterButton(alert?.type)}
                onClick={(event) =>
                  handleButtonClick(
                    event as React.MouseEvent<HTMLButtonElement>,
                    buttonProps.onClick as ButtonOnClick,
                    buttonProps?.expandAccordion || false
                  )
                }
              />
            ))}
          </Box>
        </Box>
      )
    }

    if (footer || alert) {
      footerContent = (
        <Box sx={styles.accordionFooterContainer(alert?.type)}>
          <Divider sx={styles.accordionFooterDivider} />
          <Box>{content}</Box>
        </Box>
      )
    }

    return footerContent
  }

  return (
    <Box sx={styles.accordionContainer(!!footer, alert?.type, hasBorder)} id={'accordion-container'}>
      <MuiAccordion
        style={styles.accordionContent(isExpanded && !!footer)}
        expanded={expanded}
        onChange={preventExpandChange ? undefined : handleAccordionExpansion}
        slots={{ transition: Fade as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={styles.accordionContainerSx(
          expanded,
          isExpanded && !!alert,
          separateHeader,
          props.maxWidth,
          extraMarginBottom
        )}
        id={'mui-accordion'}
      >
        <AccordionSummary
          expandIcon={noExpandIcon ? undefined : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={styles.accordionHeader(expandIconPosition, noExpandBackgroundColor, separateHeader, extraPaddingBottom)}
        >
          <Grid container sx={styles.accordionHeaderContent}>
            <Grid
              item
              {...(header.row ? { xs: 8 } : {})}
              md={header.buttons || header.secondaryContent ? 8 : 12}
              sx={styles.accordionHeaderMainContent(header.fullWidth)}
            >
              {typeof header.mainContent === 'string' ? (
                <Typography variant="subtitle1">{header.mainContent}</Typography>
              ) : (
                header.mainContent
              )}
            </Grid>

            {(header.buttons || header.secondaryContent) && (
              <Grid
                item
                {...(header.row ? { xs: 4 } : {})}
                lg={4}
                sx={styles.accordionHeaderButtons(header.buttonsWidth)}
              >
                {header.buttons?.map((buttonProps, index) => (
                  <Button
                    key={`header-button-${index}`}
                    {...buttonProps}
                    data-testid={buttonProps.testId}
                    testId={undefined}
                    onClick={(event) =>
                      handleButtonClick(
                        event as React.MouseEvent<HTMLButtonElement>,
                        buttonProps.onClick as ButtonOnClick,
                        buttonProps?.expandAccordion || false
                      )
                    }
                  />
                ))}

                {header.secondaryContent}
              </Grid>
            )}

            {header.tertiaryContent && (
              <Grid item xs={12} sx={styles.accordionHeaderTertiaryContent}>
                {header.tertiaryContent}
              </Grid>
            )}
          </Grid>
        </AccordionSummary>

        <AccordionDetails sx={styles.accordionDetails(hasPadding)}>{body}</AccordionDetails>
      </MuiAccordion>

      {renderFooter()}
    </Box>
  )
}

export default Accordion
