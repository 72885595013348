import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Snackbar from '@/shared/components/Snackbar/Snackbar.functions'
import { ConversationParticipant } from '@/shared/functions/Firebase/Messaging/messaging.types'
import { ChatOutlined, ExpandMore } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Avatar from '../../../../shared/components/Avatar/Avatar.component'
import Dropdown from '../../../../shared/components/Dropdown/Dropdown.component'
import Link from '../../../../shared/components/Link/Link.component'
import ModalComponent from '../../../../shared/components/Modal/Modal.component'
import StripeConnectOnboardingModal from '../../../../shared/components/StripeConnectOnboardingModal/StripeConnectOnboardingModal.component'
import TrackingDetails from '../../../../shared/components/TrackingDetails/TrackingDetails.component'

import {
  ItemRequestDetails,
  Leg,
  OfferRequestDetails
} from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { commitPurchasePrice } from '../../../../redux/states/items/commitPurchasePrice/commitPurchasePrice.slice'
import { CommitPurchasePriceRequestPayload } from '../../../../redux/states/items/commitPurchasePrice/commitPurchasePrice.types'
import { getItemChanges } from '../../../../redux/states/items/getItemChanges/getItemChanges.slice'
import {
  GetItemChangesData,
  GetItemChangesRequestPayload
} from '../../../../redux/states/items/getItemChanges/getItemChanges.types'
import { acceptItemRequest } from '../../../../redux/states/offers/acceptItemRequest/acceptItemRequest.slice'
import { AcceptItemRequestRequestPayload } from '../../../../redux/states/offers/acceptItemRequest/acceptItemRequest.types'
import { denyItemRequest } from '../../../../redux/states/offers/denyItemRequest/denyItemRequest.slice'
import {
  clearItemRequestTrackingDetailsSuccess,
  getItemRequestTrackingDetails
} from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import { GetItemRequestTrackingDetailsRequestPayload } from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { createAccountSession } from '../../../../redux/states/payments/createAccountSession/createAccountSession.slice'
import { CreateAccountSessionRequestPayload } from '../../../../redux/states/payments/createAccountSession/createAccountSession.types'
import { RootState } from '../../../../redux/store/store.types'
import { AccordionAlertProps, AccordionButtonProps } from '../../../../shared/components/Accordion/Accordion.types'
import { useMessaging } from '../../../../shared/components/Chat/context/Messaging.context'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import modalStyles from '../../../../shared/components/Modal/Modal.styles'
import disclaimers from '../../../../shared/dictionaries/disclaimers.dictionaries'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { getCountries } from '../../../../shared/functions/Places/places.functions'
import status from '../../../../shared/functions/Status/status.dictionaries'
import { formatMoney, joinStringsWithBullet } from '../../../../shared/functions/String/string.functions'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import useResponsiveness from '../../../../shared/hooks/responsive.hooks'
import virtualCardModalDictionary from '../FlightItineraryAccordion/components/VirtualCardModal/VirtualCardModal.dictionary'
import styles from './ItemRequestAccordion.styles'
import { ItemRequestAccordionProps } from './ItemRequestAccordion.types'

const ItemRequestAccordion: React.FC<ItemRequestAccordionProps> = (props) => {
  const {
    flightLeg,
    flightItineraryStatus,
    refreshFlightItineraries,
    virtualCardDetails,
    isExternalBooking,
    traveler
  } = props

  const [expandedItemId, setExpandedItemId] = React.useState<string | null>(null)
  const [loadingAcceptItemIds, setLoadingAcceptItemIds] = React.useState<string[]>([])
  const [loadingDenyItemIds, setLoadingDenyItemIds] = React.useState<string[]>([])
  const [updatedFlightLeg, setUpdatedFlightLeg] = React.useState<Leg>(flightLeg)
  const [alerts, setAlerts] = React.useState<{ [key: string]: AccordionAlertProps | null }>({})

  const [showStripeConnectModal, setShowStripeConnectModal] = useState(false)
  const [accountSessionClientSecret, setAccountSessionClientSecret] = useState('')

  const [committedPrices, setCommittedPrices] = React.useState<{ [key: string]: number }>({})
  const [showCountryDropdownModal, setShowCountryDropdownModal] = useState(false)
  const [stripeConnectAccountCountry, setStripeConnectAccountCountry] = useState('')
  const [itemRequestToCommitPrice, setItemRequestToCommitPrice] = useState<ItemRequestDetails | null>(null)

  const { loading: createAccountSessionLoading } = useAppSelector((state: RootState) => state.createAccountSession)

  const { loading: getAllFlightItinerariesLoading, error: getAllFlightItinerariesError } = useAppSelector(
    (state: RootState) => state.getAllFlightItineraries
  )

  const { loading: getItemChangesLoading, error: getItemChangesError } = useAppSelector(
    (state: RootState) => state.getItemChanges
  )

  const { loading: acceptItemRequestLoading, error: acceptItemRequestError } = useAppSelector(
    (state: RootState) => state.acceptItemRequest
  )

  const { loading: denyItemRequestLoading, error: denyItemRequestError } = useAppSelector(
    (state: RootState) => state.denyItemRequest
  )

  const countries = useMemo(() => getCountries(), [])

  const dispatch = useAppDispatch()

  const [isDesktop] = useResponsiveness()
  const { openChat } = useMessaging()

  useEffect(() => {
    setUpdatedFlightLeg(flightLeg)
  }, [flightLeg])

  useEffect(() => {
    if (!acceptItemRequestLoading && acceptItemRequestError) {
      setLoadingAcceptItemIds([])
    }
  }, [acceptItemRequestLoading, acceptItemRequestError])

  useEffect(() => {
    if (!denyItemRequestLoading && denyItemRequestError) {
      setLoadingDenyItemIds([])
    }
  }, [denyItemRequestLoading, denyItemRequestError])

  useEffect(() => {
    if (!getItemChangesLoading && getItemChangesError) {
      setLoadingAcceptItemIds([])
    }
  }, [getItemChangesLoading, getItemChangesError])

  useEffect(() => {
    if (!getAllFlightItinerariesLoading && getAllFlightItinerariesError) {
      setLoadingAcceptItemIds([])
      setLoadingDenyItemIds([])
    }
  }, [getAllFlightItinerariesLoading, getAllFlightItinerariesError])

  useEffect(() => {
    if (updatedFlightLeg.offerRequestsDetails.length > 0) {
      updatedFlightLeg.offerRequestsDetails.forEach((offerRequest) => {
        offerRequest.itemRequestDetails.forEach((itemRequest) => {
          const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
          const latestAvailabilityChangeDetail = itemRequest.availabilityChangeDetails?.[0]

          // TODO: Refactor so that this will be shown on the new offer request accordion that wraps these item requests accordions
          // instead of being on each item request accordion
          if (offerRequest.paymentStatus?.name === status.paymentStatus.paymentFailed) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequest.id]: {
                message:
                  "The shopper's payment failed. You can choose to wait for them to update their payment method or you can deny the request.",
                type: 'error',
                tag: 'payment-failed'
              }
            }))
          } else {
            const itemHasCommittedPrice = itemRequest.committedPurchasePrice

            // If the traveler has committed to the price, we don't want to show the price change alert or out of stock alert
            if (!itemHasCommittedPrice) {
              if (latestPriceChangeDetail) {
                const itemPriceIncreasedAndNotAcceptedByShopper =
                  latestPriceChangeDetail.changed &&
                  latestPriceChangeDetail.acceptedByShopper === false &&
                  latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
                  latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)
                const currency = itemRequest.item.currency

                if (itemPriceIncreasedAndNotAcceptedByShopper && !itemHasCommittedPrice) {
                  setAlerts((prev) => ({
                    ...prev,
                    [itemRequest.id]: {
                      message: `Price changed on Amazon from ${formatMoney(latestPriceChangeDetail.oldValue, currency)} to ${formatMoney(latestPriceChangeDetail.newValue, currency)}. The shopper needs to agree to the new price and has been notified.`,
                      type: 'warning',
                      tag: 'price-increased'
                    }
                  }))
                }
              }

              if (latestAvailabilityChangeDetail) {
                const itemOutOfStock =
                  latestAvailabilityChangeDetail.changed &&
                  latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
                  latestAvailabilityChangeDetail.oldValue === 'In Stock'

                if (itemOutOfStock) {
                  setAlerts((prev) => ({
                    ...prev,
                    [itemRequest.id]: {
                      message: 'The item is now out of stock.',
                      type: 'warning',
                      tag: 'out-of-stock'
                    }
                  }))
                }
              }
            }
          }
        })
      })
    }
  }, [updatedFlightLeg])

  const handleCountryChange = useCallback((value: string) => {
    setStripeConnectAccountCountry(value)
  }, [])

  const handleSetupStripeConnect = useCallback(() => {
    setShowCountryDropdownModal(true)
  }, [])

  const handleCreateAccountSession = useCallback(() => {
    const payload: CreateAccountSessionRequestPayload = {
      request: {
        countryCode: stripeConnectAccountCountry
      },
      onSuccess: (response) => {
        setAccountSessionClientSecret(response.data.secret)
        setShowCountryDropdownModal(false)
        setShowStripeConnectModal(true)
      }
    }

    dispatch(createAccountSession(payload))
  }, [dispatch, stripeConnectAccountCountry])

  const handleAcceptItemRequest = (itemRequest: ItemRequestDetails) => {
    const itemRequestId = itemRequest.id.toString()
    const itemId = itemRequest.item.id.toString()

    const acceptItemRequestRequestPayload: AcceptItemRequestRequestPayload = {
      request: {
        itemRequestId
      },

      onSuccess: () => {
        refreshFlightItineraries(() => {
          setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequestId))
        })
      }
    }

    const getItemChangesRequestPayload: GetItemChangesRequestPayload = {
      request: {
        id: itemId
      },

      onSuccess: (itemChanges: GetItemChangesData) => {
        if (itemChanges.price.changed) {
          const itemPriceIncreased = itemChanges.price.newValue > itemChanges.price.oldValue
          const currency = itemRequest.item.currency

          if (itemPriceIncreased) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequestId]: {
                message: `Price changed on Amazon from ${formatMoney(itemChanges.price.oldValue, currency)} to ${formatMoney(itemChanges.price.newValue, currency)}. The shopper needs to agree to the new price and has been notified.`,
                type: 'warning',
                tag: 'price-increased'
              }
            }))

            setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequestId))
          }
        } else if (itemChanges.availability.changed) {
          const itemNowOutOfStock =
            itemChanges.availability.oldValue === 'In Stock' &&
            itemChanges.availability.newValue !== itemChanges.availability.oldValue

          if (itemNowOutOfStock) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequestId]: {
                message: 'The item is currently out of stock on Amazon.',
                type: 'warning',
                tag: 'out-of-stock'
              }
            }))

            setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequestId))
          }
        } else {
          dispatch(acceptItemRequest(acceptItemRequestRequestPayload))
        }
      },

      onError: () => {
        Snackbar.show({
          message: 'An error occurred while accepting the item. Please try again.',
          severity: 'error'
        })
      }
    }

    setLoadingAcceptItemIds((prev) => [...prev, itemRequestId])
    dispatch(getItemChanges(getItemChangesRequestPayload))
  }

  const handleDenyItemRequest = (itemRequestId: string) => {
    const request = {
      itemRequestId
    }

    setLoadingDenyItemIds((prev) => [...prev, itemRequestId])

    const onSuccess = () => {
      refreshFlightItineraries(() => {
        setLoadingDenyItemIds((prev) => prev.filter((id) => id !== itemRequestId))
      })
    }

    dispatch(denyItemRequest({ request, onSuccess }))
  }

  const submitCommittedPrice = useCallback(
    (itemRequest: ItemRequestDetails) => {
      const priceCommitedTo = committedPrices[itemRequest.id.toString()] ?? itemRequest.committedPurchasePrice
      const request: CommitPurchasePriceRequestPayload = {
        request: {
          itemRequestId: itemRequest.id.toString()
        },

        onSuccess: () => {
          setCommittedPrices((prev) => ({
            ...prev,
            [itemRequest.id.toString()]: parseFloat(itemRequest.item.price)
          }))

          window.open(itemRequest.item.internalUrl, '_blank')
        },

        onError: () => {
          const priceIncrease = parseFloat(itemRequest.item.price) - itemRequest.initialAmountAllocatedToTraveler
          const currency = itemRequest.item.currency

          Modal.show({
            title: 'Connect a bank account',
            subtitle: `The item price has increased by ${formatMoney(priceIncrease, currency)} since you last accepted the request. The additional amount will be transferred to your account. You will need to connect a bank account to receive payments.`,
            primaryButton: {
              label: 'Connect bank account',
              onClick: () => {
                setItemRequestToCommitPrice(itemRequest)
                handleSetupStripeConnect()
              },
              loading: createAccountSessionLoading
            },
            secondaryButton: {
              label: 'Dismiss',
              onClick: () => {
                setItemRequestToCommitPrice(null)
              }
            }
          })
        }
      }

      if (!priceCommitedTo) {
        dispatch(commitPurchasePrice(request))
      } else {
        window.open(itemRequest.item.internalUrl, '_blank')
      }
    },
    [committedPrices, dispatch, createAccountSessionLoading, handleSetupStripeConnect]
  )

  const handleBuyOnAmazon = useCallback(
    (itemRequest: ItemRequestDetails) => {
      const priceCommitedTo = committedPrices[itemRequest.id.toString()] ?? itemRequest.committedPurchasePrice

      Modal.show({
        title: 'Buy On Amazon Now',
        subtitle: (
          <Typography sx={modalStyles.subtitle()}>
            {priceCommitedTo ? (
              <>
                The price you locked in is{' '}
                <span style={styles.priceIncreasedText}>{formatMoney(priceCommitedTo, itemRequest.item.currency)}</span>
                . Select Buy Now to buy the item on Amazon.
              </>
            ) : (
              <>
                You will need to buy this item now so the item price of{' '}
                <span style={styles.priceIncreasedText}>
                  {formatMoney(parseFloat(itemRequest.item.price), itemRequest.item.currency)}
                </span>{' '}
                does not change. If you are not ready to buy, please select Dismiss, otherwise select Buy Now.
              </>
            )}
          </Typography>
        ),
        primaryButton: {
          label: 'Buy Now',
          onClick: () => {
            if (!priceCommitedTo) {
              submitCommittedPrice(itemRequest)
            } else {
              window.open(itemRequest.item.internalUrl, '_blank')
            }
          },
          tooltipText: disclaimers.amazonAssociateEarnings
        },
        secondaryButton: {
          label: 'Dismiss',
          onClick: () => {
            setItemRequestToCommitPrice(null)
          }
        }
      })
    },
    [committedPrices, submitCommittedPrice]
  )

  const renderItemDetails = (itemRequest: ItemRequestDetails, offerRequest: OfferRequestDetails) => {
    const itemRequestAccordionHeaderButtons: AccordionButtonProps[] = []

    // TODO: Resolve issue with tracking details not being closed properly when another tracking details is opened
    const handleTrackButtonPress = (id: number) => {
      const itemId = id.toString()

      if (expandedItemId === itemId) {
        setExpandedItemId(null)
        dispatch(clearItemRequestTrackingDetailsSuccess())
      } else {
        setExpandedItemId(itemId)

        const payload: GetItemRequestTrackingDetailsRequestPayload = {
          request: {
            itemRequestId: itemId
          }
        }

        dispatch(getItemRequestTrackingDetails(payload))
      }
    }

    const handleAccordionExpansion = (isExpanded: boolean, accordionKey: string | number | undefined) => {
      if (isExpanded) {
        setExpandedItemId(accordionKey?.toString() ?? null)
        handleTrackButtonPress(itemRequest.id)
      } else {
        setExpandedItemId(null)
      }
    }

    const hasFullWeightDetails = itemRequest.item.weight && itemRequest.item.weightUnit
    const isPurchasedByTraveler = itemRequest.status.name === status.deliveryStatus.purchasedByTraveler
    const travelerHasVirtualCard = virtualCardDetails?.status?.name === status.virtualCardStatus.virtualCardAssigned
    const travelerHasRequestedVirtualCard =
      virtualCardDetails?.status?.name === status.virtualCardStatus.virtualCardRequestCreated
    const isBuyButtonVisible = !isPurchasedByTraveler
    const isFlightBooked = flightItineraryStatus === status.flightStatus.flightBooked
    const isTrackButtonVisible = travelerHasVirtualCard && (isExternalBooking ? true : isFlightBooked)

    const isAcceptButtonDisabled = () => {
      const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
      const latestAvailabilityChangeDetail = itemRequest.availabilityChangeDetails?.[0]
      let disabled = false
      let itemPriceIncreasedAndNotAcceptedByShopper = false
      let itemOutOfStock = false

      if (latestPriceChangeDetail) {
        itemPriceIncreasedAndNotAcceptedByShopper =
          latestPriceChangeDetail.changed &&
          latestPriceChangeDetail.acceptedByShopper === false &&
          latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
          latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)
      }

      if (latestAvailabilityChangeDetail) {
        itemOutOfStock =
          latestAvailabilityChangeDetail.changed &&
          latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
          latestAvailabilityChangeDetail.oldValue === 'In Stock'
      }

      if (alerts[itemRequest.id]?.tag === 'price-increased') {
        disabled = true
      } else if (alerts[itemRequest.id]?.tag === 'out-of-stock') {
        disabled = true
      } else if (itemPriceIncreasedAndNotAcceptedByShopper) {
        disabled = true
      } else if (itemOutOfStock) {
        disabled = true
      }

      if (loadingDenyItemIds.includes(itemRequest.id.toString())) {
        disabled = true
      }

      return disabled
    }

    const getTooltipText = () => {
      if (isExternalBooking) {
        if (travelerHasRequestedVirtualCard) {
          return virtualCardModalDictionary.virtualCardProcessing
        } else if (!travelerHasVirtualCard) {
          return 'You must have a virtual card to buy this item on Amazon.'
        } else {
          return disclaimers.amazonAssociateEarnings
        }
      } else if (travelerHasRequestedVirtualCard) {
        return virtualCardModalDictionary.virtualCardProcessing
      } else if (!isFlightBooked) {
        return 'Must book flight before you can buy this item on Amazon.'
      } else {
        return disclaimers.amazonAssociateEarnings
      }
    }

    const denyButton: AccordionButtonProps = {
      text: 'Deny',
      onClick: () => handleDenyItemRequest(itemRequest.id.toString()),
      buttonType: 'tertiary',
      loading:
        (denyItemRequestLoading || getAllFlightItinerariesLoading) &&
        loadingDenyItemIds.includes(itemRequest.id.toString()),
      disabled: loadingAcceptItemIds.includes(itemRequest.id.toString()),
      fullWidth: !isDesktop
    }

    const messageButton: AccordionButtonProps = {
      icon: <ChatOutlined />,
      iconPosition: 'center',
      onClick: () => {
        const currentUser: ConversationParticipant = {
          hurrierUserId: traveler.userId,
          firebaseUserId: traveler.firebaseUserId ?? '',
          firstName: traveler.firstName,
          lastName: traveler.lastName,
          role: UserRole.traveler
        }

        const chatPartner: ConversationParticipant = {
          hurrierUserId: offerRequest.shopperDetails.userId,
          firebaseUserId: offerRequest.shopperDetails.firebaseUserId ?? '',
          firstName: offerRequest.shopperDetails.firstName,
          lastName: offerRequest.shopperDetails.lastName,
          role: UserRole.shopper
        }

        const orderId = offerRequest.id.toString()

        openChat(currentUser, chatPartner, orderId)
      },
      buttonType: 'tertiary',
      tooltipText: `Message ${offerRequest.shopperDetails.firstName}`
    }

    const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
    let itemPriceIncreasedAndNotAcceptedByShopper = false

    if (latestPriceChangeDetail) {
      itemPriceIncreasedAndNotAcceptedByShopper =
        latestPriceChangeDetail.changed &&
        latestPriceChangeDetail.acceptedByShopper === false &&
        latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
        latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)
    }

    if (offerRequest.paymentStatus?.name === status.paymentStatus.paymentFailed) {
      itemRequestAccordionHeaderButtons.push(denyButton)
    } else {
      switch (itemRequest?.status.name) {
        case status.itemRequestStatus.acceptedByTraveler:
        case status.deliveryStatus.purchasedByTraveler:
        case status.deliveryStatus.inTransitToTraveler:
        case status.deliveryStatus.deliveredToTravelerAddress:
        case status.deliveryStatus.confirmedReceivedByTraveler:
        case status.deliveryStatus.confirmedInDestinationCountry:
        case status.deliveryStatus.confirmedDeliveredToShopperByTraveler:
          if (isTrackButtonVisible) {
            itemRequestAccordionHeaderButtons.push(messageButton)

            itemRequestAccordionHeaderButtons.push({
              text: 'Track',
              icon: isDesktop ? (
                <ExpandMore
                  style={{
                    transform: expandedItemId === itemRequest.id.toString() ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s'
                  }}
                />
              ) : undefined,
              iconPosition: 'end',
              onClick: () => handleTrackButtonPress(itemRequest.id),
              expandAccordion: true,
              buttonType: 'tertiary',
              fullWidth: !isDesktop
            })
          }

          if (isBuyButtonVisible) {
            let isByBuyButtonDisabled = false

            if (isExternalBooking) {
              isByBuyButtonDisabled = !travelerHasVirtualCard
            } else if (
              (!itemRequest.committedPurchasePrice && (!isFlightBooked || itemPriceIncreasedAndNotAcceptedByShopper)) ||
              !travelerHasVirtualCard
            ) {
              isByBuyButtonDisabled = true
            }

            itemRequestAccordionHeaderButtons.push({
              text: isDesktop ? 'Buy on Amazon' : 'Buy',
              onClick: () => handleBuyOnAmazon(itemRequest),
              target: '_blank',
              buttonType: 'primary',
              disabled: isByBuyButtonDisabled,
              tooltipText: isDesktop ? getTooltipText() : undefined,
              fullWidth: !isDesktop
            })
          }

          break

        case status.deliveryStatus.confirmedDeliveredToShopperByShopper:
          itemRequestAccordionHeaderButtons.push({
            text: getUserRole() === UserRole.shopper ? 'Received' : 'Delivered',
            buttonType: 'primary',
            disabled: true,
            fullWidth: !isDesktop
          })

          break

        case status.itemRequestStatus.deniedByTraveler:
          itemRequestAccordionHeaderButtons.push({
            text: 'Denied',
            buttonType: 'primary',
            disabled: true,
            fullWidth: !isDesktop
          })

          break

        case status.itemRequestStatus.cancelledByShopper:
          itemRequestAccordionHeaderButtons.push({
            text: 'Canceled By Shopper',
            buttonType: 'primary',
            disabled: true,
            tooltipText: 'The shopper has canceled this request.',
            fullWidth: !isDesktop
          })

          break

        default:
          itemRequestAccordionHeaderButtons.push(denyButton)

          itemRequestAccordionHeaderButtons.push({
            text: 'Accept',
            onClick: () => handleAcceptItemRequest(itemRequest),
            buttonType: 'primary',
            loading:
              (acceptItemRequestLoading || getAllFlightItinerariesLoading || getItemChangesLoading) &&
              loadingAcceptItemIds.includes(itemRequest.id.toString()),
            disabled: isAcceptButtonDisabled(),
            tooltipText: isAcceptButtonDisabled() ? 'The item cannot currently be accepted.' : undefined,
            fullWidth: !isDesktop
          })
          break
      }
    }

    const getUserSection = () => {
      return (
        <Grid item md={4} xs={6} sx={styles.shopperInfoContainer}>
          {isDesktop && (
            <Avatar
              firstName={offerRequest.shopperDetails.firstName}
              lastName={offerRequest.shopperDetails.lastName}
              size={'small'}
            />
          )}

          <Box sx={styles.shopperInfoTextContainer}>
            <Typography sx={styles.shopperNameText}>
              {`${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName.charAt(0)}.`}
            </Typography>

            <Typography sx={styles.itemDetailsText}>
              {isDesktop
                ? `${offerRequest.deliveryDetails.city.name}, ${offerRequest.deliveryDetails.country.name}`
                : 'Buyer'}
            </Typography>
          </Box>
        </Grid>
      )
    }

    const getFlightDiscountSection = () => {
      return (
        <Grid item md={3} sx={styles.shopperInfoTextContainer}>
          <Typography sx={styles.itemPriceText}>
            {formatMoney(itemRequest.travelerBenefit, itemRequest.item.currency)}
          </Typography>

          <Typography sx={styles.itemDetailsText}>Flight Discount</Typography>
        </Grid>
      )
    }

    const itemRequestAccordionHeader = {
      mainContent: (
        <Grid item key={itemRequest.id} sx={styles.itemRequestContainer}>
          <Grid item md={5} sx={styles.itemDetailsContainer}>
            <Box sx={styles.itemImageContainer}>
              <img src={itemRequest.item.images[0]} alt={itemRequest.item.name} style={styles.itemImage} />
            </Box>

            <Box sx={styles.itemDetailsInfoContainer}>
              <Typography sx={styles.itemTitle}>{itemRequest.item.name}</Typography>

              <Typography sx={styles.itemDetailsText}>
                {joinStringsWithBullet([
                  `Qty: ${itemRequest.quantity}`,
                  hasFullWeightDetails ? `Weighing ${itemRequest.item.weight} ${itemRequest.item.weightUnit}` : 'N/A'
                ])}
              </Typography>

              <Typography sx={styles.itemDetailsText}>
                {joinStringsWithBullet([
                  formatMoney(parseFloat(itemRequest.item.price), itemRequest.item.currency),
                  `Amazon price as of ${date(itemRequest.item.priceAt).format(
                    `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
                  )} EST`
                ])}
              </Typography>

              {isDesktop && !isFlightBooked && (
                <Typography sx={styles.itemDetailsText}>
                  <Link href={itemRequest.item.internalUrl} target="_blank" showColor>
                    View on Amazon
                  </Link>
                </Typography>
              )}
            </Box>
          </Grid>

          <Divider orientation={isDesktop ? 'vertical' : 'horizontal'} flexItem sx={styles.itemDetailsDivider} />
          {isDesktop ? (
            <>
              {getUserSection()}

              <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

              {getFlightDiscountSection()}
            </>
          ) : (
            <Grid item container>
              {getUserSection()}

              <Divider
                orientation="vertical"
                flexItem
                sx={[styles.itemDetailsDivider, styles.itemsDetailsDividerVertical]}
              />

              {getFlightDiscountSection()}
            </Grid>
          )}
        </Grid>
      ),
      buttons: itemRequestAccordionHeaderButtons,
      tertiaryContent: isDesktop ? null : <Typography sx={styles.tooltipMessage}>{getTooltipText()}</Typography>
    }

    const getTrackingDetails = () => {
      return (
        <TrackingDetails
          itemRequestId={itemRequest.id}
          destinationCity={offerRequest.deliveryDetails.city.name}
          key={itemRequest.id}
          isOpen={expandedItemId === itemRequest.id.toString()}
          refreshMainList={refreshFlightItineraries}
        />
      )
    }

    // TODO: Add logic to check item tracking status
    const isTrackingDetailsAccessible =
      itemRequest.status.name === status.itemRequestStatus.acceptedByTraveler &&
      isFlightBooked &&
      travelerHasVirtualCard

    return (
      <Accordion
        header={itemRequestAccordionHeader}
        body={getTrackingDetails()}
        noExpandIcon
        noExpandBackgroundColor
        preventExpandChange={!isTrackingDetailsAccessible}
        handleExpansion={handleAccordionExpansion}
        accordionKey={itemRequest.id}
        key={itemRequest.id}
        alert={alerts[itemRequest.id] || undefined}
        hasBorder={!isDesktop}
        extraMarginBottom={isDesktop}
        extraPaddingBottom={isDesktop}
      />
    )
  }

  const renderLegDetails = () => (
    <Box sx={styles.itemDetailsList} key={updatedFlightLeg.id}>
      {updatedFlightLeg.offerRequestsDetails.map((offerRequest) =>
        offerRequest.itemRequestDetails.map((itemRequest) => renderItemDetails(itemRequest, offerRequest))
      )}
    </Box>
  )

  return (
    <Box sx={styles.itemRequestAccordionContainer}>
      {renderLegDetails()}

      <ModalComponent
        open={showCountryDropdownModal}
        onClose={() => setShowCountryDropdownModal(false)}
        title="Select Country"
        subtitle="Please select the country in which the bank account you'd like to receive payments in is registered."
        body={
          <Dropdown
            options={countries.map((country) => ({
              value: country.code,
              label: country.name
            }))}
            name="country"
            label="Country"
            value={stripeConnectAccountCountry}
            setValue={handleCountryChange}
            placeholder="Select Country"
            marginTop={3}
          />
        }
        primaryButton={{
          label: 'Continue',
          onClick: handleCreateAccountSession,
          disabled: !stripeConnectAccountCountry
        }}
        secondaryButton={{
          label: 'Dismiss',
          onClick: () => {
            setItemRequestToCommitPrice(null)
            setShowCountryDropdownModal(false)
          }
        }}
      />

      {showStripeConnectModal && itemRequestToCommitPrice && (
        <StripeConnectOnboardingModal
          open={showStripeConnectModal}
          onClose={() => {
            setItemRequestToCommitPrice(null)
            setShowStripeConnectModal(false)
          }}
          accountSessionClientSecret={accountSessionClientSecret}
          onOnboardingComplete={() => {
            setShowStripeConnectModal(false)
            submitCommittedPrice(itemRequestToCommitPrice)
            refreshFlightItineraries()
          }}
        />
      )}
    </Box>
  )
}

export default ItemRequestAccordion
