import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3)
    }
  },

  offerItemContainer: {
    marginBottom: theme.spacing(2)
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 1, 1, 1.5),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },

  rightContainerButton: {
    // marginLeft: theme.spacing(2)
  },

  rightContainerText: {
    paddingRight: theme.spacing(2)
  }
}

export default styles
