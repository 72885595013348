import React, { useState } from 'react'

import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { Check } from '@mui/icons-material'
import { Box, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  XIcon
} from 'react-share'

import Button from '../Button/Button.component'
import Modal from '../Modal/Modal.component'

import images from '../../../assets/images'
import { copyToClipboard } from '../../functions/Clipboard/clipboard.function'
import logger from '../../functions/Logger/logger.functions'
import ModalFunctions from '../Modal/Modal.functions'
import { SnackbarAutoHideDuration } from '../Snackbar/Snackbar.types'
import styles from './ShareOfferRequestLinkModal.styles'
import { ShareOfferRequestLinkModalProps } from './ShareOfferRequestLinkModal.types'

// TODO: Replace with ShareLinkModal
const ShareOfferRequestLinkModal: React.FC<ShareOfferRequestLinkModalProps> = (props) => {
  const { shareLink, sourceCountry, open } = props

  const [isCopied, setIsCopied] = useState(false)

  const [isDesktop] = useResponsiveness()

  const handleCopyClick = async () => {
    try {
      const successfullyCopied = await copyToClipboard(shareLink)

      if (successfullyCopied) {
        setIsCopied(true)

        setTimeout(() => {
          setIsCopied(false)
        }, SnackbarAutoHideDuration.SHORT)
      }
    } catch (err) {
      logger.logError(err, 'Failed to copy text', 'handleCopyClick')
    }
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const shareLinkMessage = `Anyone traveling from ${sourceCountry.name} can carry an Amazon item for me. View Earnings!`

  const modalBody = (
    <Box sx={styles.modalBody}>
      <Box sx={styles.shareLinkContainer}>
        <Box sx={styles.shareLinkTop}>
          <Box
            component="img"
            src={images.shareLinkWithIconsIcon}
            alt="Share Link Modal Image"
            sx={styles.shareLinkModalImage}
          />

          <Typography variant={isMobile ? 'h6' : 'h5'} sx={styles.shareLinkModalPrimaryText}>
            Share Your Offer
          </Typography>

          <Typography variant={isMobile ? 'body1' : 'h6'} sx={styles.shareLinkModalSecondaryText}>
            Share this offer with travelers who might be able to help.
          </Typography>
        </Box>

        <Box sx={styles.shareViaSocialMediaContainer}>
          <Typography variant={isMobile ? 'body2' : 'body1'} sx={styles.shareViaSocialMedia}>
            Share via social media by clicking on the icon below.
          </Typography>

          <Box sx={styles.socialIconsContainer}>
            <FacebookShareButton url={shareLink} style={styles.socialIconButton}>
              <FacebookIcon size={isDesktop ? 48 : 36} round />
            </FacebookShareButton>

            {isMobile && (
              <IconButton
                sx={styles.socialIconButton}
                href={`https://instagram.com/direct/new/?text=${encodeURIComponent(shareLink)}`}
                target="_blank"
              >
                <img src={images.instagramGraphic} style={styles.socialMediaIcon} alt="Instagram" />
              </IconButton>
            )}

            <TwitterShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <XIcon size={isDesktop ? 48 : 36} round />
            </TwitterShareButton>

            <IconButton
              sx={styles.socialIconButton}
              href={`https://wa.me/?text=${encodeURIComponent(shareLinkMessage + '\n\n' + shareLink)}`}
              target="_blank"
            >
              <WhatsappIcon size={isDesktop ? 48 : 36} round />
            </IconButton>

            <RedditShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <RedditIcon size={isDesktop ? 48 : 36} round />
            </RedditShareButton>

            <TelegramShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <TelegramIcon size={isDesktop ? 48 : 36} round />
            </TelegramShareButton>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.copyLinkContainer}>
        <Typography variant={isMobile ? 'body2' : 'body1'} sx={styles.copyLinkInstructionsText}>
          Copy the link to share on your preferred platform.
        </Typography>

        <Box sx={styles.copyLinkContentContainer}>
          <TextField sx={styles.copyLinkText} value={shareLink} InputProps={{ readOnly: true }} />

          <Button
            onClick={handleCopyClick}
            buttonType="primary"
            text={isCopied ? 'Copied' : 'Copy Link'}
            icon={isCopied ? <Check /> : undefined}
            disabled={isCopied}
            fullWidth={!isDesktop}
          />
        </Box>
      </Box>
    </Box>
  )

  return <Modal open={open} onClose={() => ModalFunctions.close()} body={modalBody} showCloseButton />
}

export default ShareOfferRequestLinkModal
