import theme from '@/shared/styles/themes/default.theme'

const styles = {
  container: (error?: boolean) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius / 4,
    display: 'flex',
    flexDirection: 'column',
    ...(error && {
      border: `1px solid ${theme.palette.error.main}`
    }),
    [theme.breakpoints.down('md')]: {
      maxWidth: '400px'
    }
  }),

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },

  closeIcon: {
    marginLeft: 'auto'
  },

  error: {
    fontSize: '12px',
    color: theme.palette.error.main,
    marginTop: theme.spacing(1)
  }
}

export default styles
