import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { ReactComponent } from '@/shared/types/react.types'
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material'
import { Box, Stepper as MUIStepper, Step, StepContent, StepLabel, Typography } from '@mui/material'

import ResponsiveDisplayWrapper from '../ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'
import CustomConnector from './components/CustomConnector/CustomConnector.component'
import CustomStepIcon from './components/CustomStepIcon/CustomStepIcon.component'

import styles from './Stepper.styles'
import { Step as StepType } from './Stepper.types'
import { StepperProps } from './Stepper.types'

const Stepper: ReactComponent<StepperProps> = (props, _ref) => {
  const {
    activeStep,
    steps,
    children,
    stepperWidth = '100%',
    showLeftArrow = false,
    showRightArrow = false,
    showStepper = true,
    stepContent,
    onStepClick,
    preventNavigationBackAfterLastStep = true,
    isLastStepCompleted,
    noBackground = false,
    orientation = 'horizontal',
    header
  } = props

  const [isDesktop] = useResponsiveness()

  const handleStepClick = (index: number) => {
    const isLastStep = index === steps.length - 1

    if (index < activeStep || (isLastStep && preventNavigationBackAfterLastStep)) {
      onStepClick && onStepClick(index)
    }
  }

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      onStepClick && onStepClick(activeStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      onStepClick && onStepClick(activeStep - 1)
    }
  }

  const areArrowsPresent = (showLeftArrow && activeStep !== 0) || (showRightArrow && activeStep !== steps.length - 1)

  const getStep = (index: number, length: number, step: StepType) => {
    return (
      <Step key={step.label} sx={styles.step(length, orientation)}>
        <Box sx={styles.stepLabelContainer}>
          <StepLabel
            onClick={() => handleStepClick(index)}
            optional={<Typography sx={styles.stepDescriptionText}>{step.description}</Typography>}
            StepIconComponent={(props) => {
              const isLastStep = activeStep === steps.length - 1
              const lastStepCompleted = isLastStepCompleted ?? isLastStep
              const isCompleted = !!props.completed || lastStepCompleted

              return <CustomStepIcon active={!!props.active} completed={isCompleted} index={index} key={step.label} />
            }}
          >
            {orientation === 'horizontal' && (
              <ResponsiveDisplayWrapper isDesktop={false}>
                <Typography sx={styles.stepOfStepsText}>
                  {activeStep + 1} of {steps.length}
                </Typography>
              </ResponsiveDisplayWrapper>
            )}

            <Typography sx={styles.stepLabelText(orientation)}>{step.label}</Typography>
          </StepLabel>

          <Box sx={styles.stepLabelMobileContainer}>
            {showLeftArrow && !isDesktop && (
              <Box
                sx={{
                  ...styles.navButtonContainer,
                  visibility: activeStep !== 0 ? 'visible' : 'hidden',
                  ...styles.navButtonContainerMobile
                }}
              >
                <ArrowBackRounded onClick={handleBack} sx={styles.navButton} />
              </Box>
            )}

            {showRightArrow && !isDesktop && (
              <Box
                sx={{
                  ...styles.navButtonContainer,
                  visibility: activeStep !== steps.length - 1 ? 'visible' : 'hidden'
                }}
              >
                <ArrowForwardRounded onClick={handleNext} sx={styles.navButton} />
              </Box>
            )}
          </Box>
        </Box>

        {stepContent && <StepContent sx={styles.stepContent}>{stepContent}</StepContent>}
      </Step>
    )
  }

  return (
    <Box sx={styles.container(!!children)}>
      {header && <Box sx={styles.header}>{header}</Box>}

      {showStepper && (
        <Box sx={styles.stepperContainer(noBackground, areArrowsPresent)}>
          {showLeftArrow && isDesktop && (
            <Box sx={{ ...styles.navButtonContainer, visibility: activeStep !== 0 ? 'visible' : 'hidden' }}>
              <ArrowBackRounded onClick={handleBack} sx={styles.navButton} />
            </Box>
          )}

          <MUIStepper
            {...props}
            activeStep={activeStep}
            connector={<CustomConnector orientation={orientation} />}
            sx={styles.stepper(stepperWidth, orientation)}
            orientation={orientation === 'vertical' ? 'vertical' : 'horizontal'}
          >
            {steps.map((step, index) => getStep(index, steps.length, step))}
          </MUIStepper>

          {orientation === 'horizontal' && (
            <ResponsiveDisplayWrapper isDesktop={false}>
              {getStep(activeStep, 1, steps[activeStep])}
            </ResponsiveDisplayWrapper>
          )}

          {showRightArrow && isDesktop && (
            <Box
              sx={{
                ...styles.navButtonContainer,
                visibility: activeStep !== steps.length - 1 ? 'visible' : 'hidden'
              }}
            >
              <ArrowForwardRounded onClick={handleNext} sx={styles.navButton} />
            </Box>
          )}
        </Box>
      )}

      {children && <Box sx={styles.content}>{children}</Box>}
    </Box>
  )
}

export default Stepper
