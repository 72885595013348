import logger from '../../functions/Logger/logger.functions'
import { ModalContextProps, ShowModalProps } from './context/Modal.context.types'

let modalContext: ModalContextProps | null = null

export const setModalContext = (context: ModalContextProps) => {
  modalContext = context
}

export const showModal = (props: ShowModalProps) => {
  if (process.env.NODE_ENV === 'test') {
    if (modalContext) {
      modalContext.show({
        ...props
      })
    } else {
      logger.logError('Modal context is not set', 'showModal', 'Modal')
    }
  } else {
    setTimeout(() => {
      if (modalContext) {
        modalContext.show({
          ...props
        })
      } else {
        logger.logError('Modal context is not set', 'showModal', 'Modal')
      }
    }, 150)
  }
}

const isOpen = () => {
  return modalContext?.isOpen
}

const closeModal = () => {
  if (modalContext) {
    modalContext.close()
  }
}

const Modal = {
  show: showModal,
  isOpen: isOpen,
  close: closeModal
}

export default Modal
