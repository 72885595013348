import React, { useCallback, useEffect, useState } from 'react'

import { copyToClipboard } from '@/shared/functions/Clipboard/clipboard.function'
import logger from '@/shared/functions/Logger/logger.functions'
import linkStyles from '@/shared/styles/link.styles'
import { Alert, Box, Skeleton, Typography } from '@mui/material'
import { FormikProps, FormikValues } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import Dropdown from '../../../../shared/components/Dropdown/Dropdown.component'
import EmptyState from '../../../../shared/components/EmptyState/EmptyState.component'
import FormComponent from '../../../../shared/components/Form/Form.component'
import Modal from '../../../../shared/components/Modal/Modal.component'
import Table from '../../../../shared/components/Table/Table.component'
import Tabs from '../../../../shared/components/Tabs/Tabs.component'
import Link from '@/shared/components/Link/Link.component'

import env from '../../../../networkRequests/apiClient/env.config'
import { assignVirtualCardToFlightItineraryForAdmin } from '../../../../redux/states/admin/payments/virtualCards/assignVirtualCardToFlightItineraryForAdmin/assignVirtualCardToFlightItineraryForAdmin.slice'
import { AssignVirtualCardToFlightItineraryForAdminRequestPayload } from '../../../../redux/states/admin/payments/virtualCards/assignVirtualCardToFlightItineraryForAdmin/assignVirtualCardToFlightItineraryForAdmin.types'
import { getAllAssignedVirtualCardsForAdmin } from '../../../../redux/states/admin/payments/virtualCards/getAllAssignedVirtualCardsForAdmin/getAllAssignedVirtualCardsForAdmin.slice'
import {
  AssignedVirtualCard,
  GetAllAssignedVirtualCardsForAdminRequestPayload
} from '../../../../redux/states/admin/payments/virtualCards/getAllAssignedVirtualCardsForAdmin/getAllAssignedVirtualCardsForAdmin.types'
import { getAllUnassignedVirtualCardsForAdmin } from '../../../../redux/states/admin/payments/virtualCards/getAllUnassignedVirtualCardsForAdmin/getAllUnassignedVirtualCardsForAdmin.slice'
import {
  UnassignedVirtualCard,
  getAllUnassignedVirtualCardsForAdminRequestPayload
} from '../../../../redux/states/admin/payments/virtualCards/getAllUnassignedVirtualCardsForAdmin/getAllUnassignedVirtualCardsForAdmin.types'
import { getAllVirtualCardRequestsForAdmin } from '../../../../redux/states/admin/payments/virtualCards/getAllVirtualCardRequestsForAdmin/getAllVirtualCardRequestsForAdmin.slice'
import {
  GetAllVirtualCardRequestsForAdminRequestPayload,
  VirtualCardRequest
} from '../../../../redux/states/admin/payments/virtualCards/getAllVirtualCardRequestsForAdmin/getAllVirtualCardRequestsForAdmin.types'
import { getAssignedVirtualCardDetailsForAdmin } from '../../../../redux/states/admin/payments/virtualCards/getAssignedVirtualCardDetailsForAdmin/getAssignedVirtualCardDetailsForAdmin.slice'
import { GetAssignedVirtualCardDetailsForAdminRequestPayload } from '../../../../redux/states/admin/payments/virtualCards/getAssignedVirtualCardDetailsForAdmin/getAssignedVirtualCardDetailsForAdmin.types'
import { updateAssignedVirtualCardDetails } from '../../../../redux/states/admin/payments/virtualCards/updateAssignedVirtualCardDetails/updateAssignedVirtualCardDetails.slice'
import { UpdateAssignedVirtualCardDetailsRequestPayload } from '../../../../redux/states/admin/payments/virtualCards/updateAssignedVirtualCardDetails/updateAssignedVirtualCardDetails.types'
import { RootState } from '../../../../redux/store/store.types'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import {
  formatMoney,
  formatTruncatedCardNumber,
  joinElementsWithBullet
} from '../../../../shared/functions/String/string.functions'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import {
  assignedVirtualCardTableColumns,
  updateSpendingLimitFormFieldsSections,
  virtualCardRequestTableColumns
} from './VirtualCards.dictionary'
import styles from './VirtualCards.styles'
import { UpdateSpendingLimitFormValues, VirtualCardsProps } from './VirtualCards.types'

const VirtualCards: React.FC<VirtualCardsProps> = (props) => {
  const { searchQuery, setVirtualCardsTabIndex, virtualCardsTabIndex } = props

  const [virtualCardRequestsPage, setVirtualCardRequestsPage] = useState(1)
  const [assignedVirtualCardsPage, setAssignedVirtualCardsPage] = useState(1)
  const [virtualCardRequestsTotalPages, setVirtualCardRequestsTotalPages] = useState(1)
  const [assignedVirtualCardsTotalPages, setAssignedVirtualCardsTotalPages] = useState(1)
  const [virtualCardRequestsTotalRecords, setVirtualCardRequestsTotalRecords] = useState(0)
  const [assignedVirtualCardsTotalRecords, setAssignedVirtualCardsTotalRecords] = useState(0)
  const [virtualCardRequestsData, setVirtualCardRequestsData] = useState<VirtualCardRequest[]>([])
  const [assignedVirtualCardsData, setAssignedVirtualCardsData] = useState<AssignedVirtualCard[]>([])

  const [virtualCardAssignmentModalOpen, setVirtualCardAssignmentModalOpen] = useState(false)
  const [assignedVirtualCardDetailsModalOpen, setAssignedVirtualCardDetailsModalOpen] = useState(false)
  const [virtualCardRequest, setVirtualCardRequest] = useState<VirtualCardRequest | null>(null)
  const [selectedVirtualCardId, setSelectedVirtualCardId] = useState<string | null>(null)
  const [formikInstance, setFormikInstance] = useState<FormikProps<UpdateSpendingLimitFormValues>>(
    {} as FormikProps<UpdateSpendingLimitFormValues>
  )

  const pageSize = env.DEFAULT_PAGE_SIZE

  const dispatch = useAppDispatch()

  const {
    loading: virtualCardRequestsLoading,
    success: virtualCardRequestsSuccess,
    error: virtualCardRequestsError
  } = useSelector((state: RootState) => state.getAllVirtualCardRequestsForAdmin)

  const { loading: unassignedVirtualCardsLoading, success: unassignedVirtualCardsSuccess } = useSelector(
    (state: RootState) => state.getAllUnassignedVirtualCardsForAdmin
  )

  const { loading: virtualCardAssignmentLoading } = useSelector(
    (state: RootState) => state.assignVirtualCardToFlightItineraryForAdmin
  )

  const {
    loading: assignedVirtualCardsLoading,
    success: assignedVirtualCardsSuccess,
    error: assignedVirtualCardsError
  } = useSelector((state: RootState) => state.getAllAssignedVirtualCardsForAdmin)

  const {
    loading: assignedVirtualCardDetailsLoading,
    success: assignedVirtualCardDetailsSuccess,
    error: assignedVirtualCardDetailsError
  } = useSelector((state: RootState) => state.getAssignedVirtualCardDetailsForAdmin)

  const { loading: updateAssignedVirtualCardDetailsLoading } = useSelector(
    (state: RootState) => state.updateAssignedVirtualCardDetails
  )

  useEffect(() => {
    if (virtualCardRequestsSuccess?.data) {
      const { currentPage, totalPages, totalRecords, records } = virtualCardRequestsSuccess.data

      setVirtualCardRequestsTotalPages(totalPages)
      setVirtualCardRequestsTotalRecords(totalRecords)
      setVirtualCardRequestsPage(currentPage)
      setVirtualCardRequestsData(records)
    }
  }, [virtualCardRequestsSuccess])

  useEffect(() => {
    if (assignedVirtualCardsSuccess?.data) {
      const { currentPage, totalPages, totalRecords, records } = assignedVirtualCardsSuccess.data

      setAssignedVirtualCardsTotalPages(totalPages)
      setAssignedVirtualCardsTotalRecords(totalRecords)
      setAssignedVirtualCardsPage(currentPage)
      setAssignedVirtualCardsData(records)
    }
  }, [assignedVirtualCardsSuccess])

  const handleGetVirtualCardRequests = useCallback(
    (page: number = 1, size: number = pageSize, onSuccess?: () => void) => {
      const payload: GetAllVirtualCardRequestsForAdminRequestPayload = {
        request: {
          page,
          size,
          searchQuery
        },
        onSuccess
      }

      dispatch(getAllVirtualCardRequestsForAdmin(payload))
    },
    [dispatch, searchQuery, pageSize]
  )

  const handleGetAssignedVirtualCards = useCallback(
    (page: number = 1, size: number = pageSize, onSuccess?: () => void) => {
      const payload: GetAllAssignedVirtualCardsForAdminRequestPayload = {
        request: {
          page,
          size,
          searchQuery
        },
        onSuccess
      }

      dispatch(getAllAssignedVirtualCardsForAdmin(payload))
    },
    [dispatch, searchQuery, pageSize]
  )

  const handleGetUnassignedVirtualCards = useCallback(
    (page: number = 1, size: number = pageSize, onSuccess?: () => void) => {
      const payload: getAllUnassignedVirtualCardsForAdminRequestPayload = {
        request: {
          page,
          size,
          searchQuery
        },
        onSuccess
      }

      dispatch(getAllUnassignedVirtualCardsForAdmin(payload))
    },
    [dispatch, searchQuery, pageSize]
  )

  const handleGetAssignedVirtualCardDetails = useCallback(
    (assignedVirtualCardId: string) => {
      const payload: GetAssignedVirtualCardDetailsForAdminRequestPayload = {
        request: {
          assignedVirtualCardId
        }
      }

      setAssignedVirtualCardDetailsModalOpen(true)
      dispatch(getAssignedVirtualCardDetailsForAdmin(payload))
    },
    [dispatch]
  )

  const handleUpdateSpendingLimit = useCallback(
    (values: unknown) => {
      const { spendingLimit } = values as FormikValues
      const assignedVirtualCardId = assignedVirtualCardDetailsSuccess?.data?.cardDetails.id
      const currency = assignedVirtualCardDetailsSuccess?.data?.cardDetails.orderCurrency
      const customerName = `${assignedVirtualCardDetailsSuccess?.data?.user.firstName} ${assignedVirtualCardDetailsSuccess?.data?.user.lastName}`
      const cardNumber = formatTruncatedCardNumber(
        assignedVirtualCardDetailsSuccess?.data?.cardDetails.truncatedCardNumber ?? ''
      )

      if (assignedVirtualCardId) {
        const payload: UpdateAssignedVirtualCardDetailsRequestPayload = {
          request: {
            assignedVirtualCardId: assignedVirtualCardId.toString(),
            spendingLimit: Number(spendingLimit)
          },
          onSuccess: () => {
            Snackbar.show({
              message: `Spending limit for ${customerName} (${cardNumber}) updated successfully to ${formatMoney(Number(spendingLimit), currency)}`,
              severity: 'success'
            })

            handleGetAssignedVirtualCards()
          }
        }

        dispatch(updateAssignedVirtualCardDetails(payload))
      }
    },
    [dispatch, assignedVirtualCardDetailsSuccess, handleGetAssignedVirtualCards]
  )

  const handleUpdateSpendingLimitFormChange = useCallback((formik: Partial<FormikProps<unknown>>) => {
    setFormikInstance(formik as FormikProps<UpdateSpendingLimitFormValues>)
  }, [])

  useEffect(() => {
    if (virtualCardsTabIndex === 0) {
      handleGetVirtualCardRequests()
    } else {
      handleGetAssignedVirtualCards()
    }
  }, [virtualCardsTabIndex, handleGetAssignedVirtualCards, handleGetVirtualCardRequests])

  const handleTabChange = (newValue: number) => {
    setVirtualCardsTabIndex(newValue)
  }

  const handleShowVirtualCardAssignmentModal = (virtualCardRequest: VirtualCardRequest) => {
    setVirtualCardRequest(virtualCardRequest)
    setVirtualCardAssignmentModalOpen(true)
    handleGetUnassignedVirtualCards()
  }

  const handleCloseVirtualCardAssignmentModal = () => {
    setVirtualCardAssignmentModalOpen(false)
    setVirtualCardRequest(null)
    setSelectedVirtualCardId(null)
  }

  const handleOpenAssignedVirtualCardDetailsModal = (assignedVirtualCardId: string) => {
    setAssignedVirtualCardDetailsModalOpen(true)
    handleGetAssignedVirtualCardDetails(assignedVirtualCardId)
  }

  const handleCloseAssignedVirtualCardDetailsModal = () => {
    setAssignedVirtualCardDetailsModalOpen(false)
  }

  const handleSetSelectedVirtualCardId = (cardId: string) => {
    setSelectedVirtualCardId(cardId)
  }

  const handleAssignVirtualCard = () => {
    if (virtualCardRequest && selectedVirtualCardId) {
      const payload: AssignVirtualCardToFlightItineraryForAdminRequestPayload = {
        request: {
          flightItineraryId: virtualCardRequest.flightItineraryDetails.id.toString(),
          virtualCardId: selectedVirtualCardId
        },
        onSuccess: () => {
          handleGetVirtualCardRequests(1, pageSize, () => {
            handleCloseVirtualCardAssignmentModal()

            Snackbar.show({
              message: `Virtual card assigned successfully to flight itinerary ${virtualCardRequest.flightItineraryDetails.id}`,
              severity: 'success',
              action: {
                label: 'See Assigned Card',
                onClick: () => {
                  handleGetAssignedVirtualCards()
                  setVirtualCardsTabIndex(1)
                }
              }
            })
          })

          handleGetUnassignedVirtualCards()
        }
      }

      dispatch(assignVirtualCardToFlightItineraryForAdmin(payload))
    }
  }

  const tabs = [
    {
      label: 'Requests',
      content: (
        <Table
          columns={virtualCardRequestTableColumns}
          data={virtualCardRequestsData}
          onRowClick={(_, index) => {
            const virtualCardRequest = virtualCardRequestsData[index]

            if (virtualCardRequest) {
              handleShowVirtualCardAssignmentModal(virtualCardRequest)
            }
          }}
          pagination={{
            currentPage: virtualCardRequestsPage,
            totalPages: virtualCardRequestsTotalPages,
            totalRecords: virtualCardRequestsTotalRecords,
            onNext: () => handleGetVirtualCardRequests(virtualCardRequestsPage + 1),
            onPrevious: () => handleGetVirtualCardRequests(virtualCardRequestsPage - 1)
          }}
          initialLoading={virtualCardRequestsLoading}
          error={!!virtualCardRequestsError}
          onRetry={() => handleGetVirtualCardRequests()}
          inPage
        />
      )
    },
    {
      label: 'Assigned Cards',
      content: (
        <Table
          columns={assignedVirtualCardTableColumns}
          data={assignedVirtualCardsData}
          onRowClick={(_, index) => {
            const assignedVirtualCard = assignedVirtualCardsData[index]

            if (assignedVirtualCard) {
              handleOpenAssignedVirtualCardDetailsModal(assignedVirtualCard.id.toString())
            }
          }}
          pagination={{
            currentPage: assignedVirtualCardsPage,
            totalPages: assignedVirtualCardsTotalPages,
            totalRecords: assignedVirtualCardsTotalRecords,
            onNext: () => handleGetAssignedVirtualCards(assignedVirtualCardsPage + 1),
            onPrevious: () => handleGetAssignedVirtualCards(assignedVirtualCardsPage - 1)
          }}
          initialLoading={assignedVirtualCardsLoading}
          error={!!assignedVirtualCardsError}
          onRetry={() => handleGetAssignedVirtualCards()}
          inPage
        />
      )
    }
  ]

  const getAssignVirtualCardModalBody = () => {
    const handleCopyClick = async () => {
      try {
        const successfullyCopied = await copyToClipboard(virtualCardRequest?.user.email ?? '')

        if (successfullyCopied) {
          Snackbar.show({
            message: 'Email copied to clipboard',
            severity: 'success'
          })
        }
      } catch (err) {
        logger.logError(err, 'Failed to copy text', 'handleCopyClick')
      }
    }

    return (
      <Box sx={styles.dropdownContainer}>
        <Typography variant="body1" sx={styles.dropdownLabel}>
          {joinElementsWithBullet([
            `${virtualCardRequest?.user.firstName} ${virtualCardRequest?.user.lastName}`,
            <Link style={linkStyles} onClick={handleCopyClick}>
              {virtualCardRequest?.user.email}
            </Link>,
            `${formatMoney(virtualCardRequest?.purchaseDetails.spendingLimit, virtualCardRequest?.purchaseDetails.orderCurrency)}`,
            `${date(virtualCardRequest?.purchaseDetails.expirationDate).format(dateTimeFormats.date.medium)}`
          ])}
        </Typography>

        <Dropdown
          name="virtualCard"
          label="Virtual Cards"
          loading={unassignedVirtualCardsLoading}
          loadingText="Loading virtual cards..."
          options={
            unassignedVirtualCardsSuccess?.data?.virtualCards.map((card: UnassignedVirtualCard) => ({
              value: card.id,
              label: `${card.cardHolderName} (${formatTruncatedCardNumber(card.truncatedCardNumber)})`
            })) ?? []
          }
          value={selectedVirtualCardId ?? ''}
          setValue={handleSetSelectedVirtualCardId}
          placeholder="Select Virtual Card"
        />
      </Box>
    )
  }

  const getAssignedVirtualCardDetailsModalBody = () => {
    if (assignedVirtualCardDetailsLoading) {
      return (
        <Box sx={styles.assignedVirtualCardDetailsModalBodySkeletonElement}>
          {[1, 2].map((_, i) => (
            <Box key={i}>
              <Skeleton variant="text" height={35} width="40%" />
              <Skeleton variant="rounded" height={135} />
            </Box>
          ))}
        </Box>
      )
    } else if (assignedVirtualCardDetailsError) {
      return (
        <EmptyState
          title="Error loading assigned virtual card details"
          errorId={assignedVirtualCardDetailsError.correlationId}
        />
      )
    } else if (assignedVirtualCardDetailsSuccess) {
      const cardHolderName = `${assignedVirtualCardDetailsSuccess?.data?.user.firstName} ${assignedVirtualCardDetailsSuccess?.data?.user.lastName}`
      const userId = assignedVirtualCardDetailsSuccess?.data?.user.id
      const email = assignedVirtualCardDetailsSuccess?.data?.user.email
      const phoneNumber = assignedVirtualCardDetailsSuccess?.data?.user.phoneNumber
      const spendingLimit = assignedVirtualCardDetailsSuccess?.data?.cardDetails.spendingLimit
      const cardNumber = formatTruncatedCardNumber(
        assignedVirtualCardDetailsSuccess?.data?.cardDetails.truncatedCardNumber
      )
      const orderCurrency = assignedVirtualCardDetailsSuccess?.data?.cardDetails.orderCurrency
      const latestSpendingLimitRequired = assignedVirtualCardDetailsSuccess?.data?.latestSpendingLimitRequired

      const initialValues: UpdateSpendingLimitFormValues = {
        cardHolderName,
        userId: userId.toString(),
        email: email ?? '',
        phoneNumber: phoneNumber ?? '',
        cardNumber,
        expirationDate: date(assignedVirtualCardDetailsSuccess?.data?.cardDetails.expirationDate).format(
          dateTimeFormats.date.medium
        ),
        latestSpendingLimitRequired: formatMoney(latestSpendingLimitRequired?.amount, orderCurrency),
        spendingLimit: spendingLimit
      }

      const validationSchema = Yup.object({
        spendingLimit: Yup.string()
          .required('Required')
          .test(
            'min-spending-limit',
            `Spending limit must be at least ${formatMoney(latestSpendingLimitRequired?.amount, orderCurrency)}`,
            function (value) {
              if (!latestSpendingLimitRequired || !value) return true

              return Number(Number(value).toFixed(2)) >= Number(latestSpendingLimitRequired.amount.toFixed(2))
            }
          )
      })

      const latestSpendingLimitRequiredDate = date(latestSpendingLimitRequired?.createdAt).format(
        dateTimeFormats.date.medium
      )

      // TODO: Figure out how currencies outside of the default currencies will be handled in Mercury
      // Example: Item was bought in the UK (GBP) but the cards issued in Mercury are in USD; the
      // item price does not translate 1:1 to the card's currency.
      const currentSpendingLimit = assignedVirtualCardDetailsSuccess?.data?.cardDetails.spendingLimit
      const latestSpendingLimitRequiredAmount = formatMoney(latestSpendingLimitRequired?.amount, orderCurrency)
      const islatestSpendingLimitRequiredAlertRequired =
        latestSpendingLimitRequired &&
        Number(latestSpendingLimitRequired?.amount.toFixed(2)) > Number(currentSpendingLimit.toFixed(2))

      return (
        <Box>
          {islatestSpendingLimitRequiredAlertRequired && (
            <Alert severity="warning" sx={styles.latestSpendingLimitRequiredAlert}>
              <Typography variant="body1">
                The spending limit for this card requires an update to {latestSpendingLimitRequiredAmount} since{' '}
                {latestSpendingLimitRequiredDate}.
              </Typography>
            </Alert>
          )}

          <FormComponent
            key={assignedVirtualCardDetailsSuccess ? 'loaded' : 'loading'}
            hideButtons={true}
            buttonText=""
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdateSpendingLimit}
            formSections={updateSpendingLimitFormFieldsSections}
            onFormChange={handleUpdateSpendingLimitFormChange}
          />
        </Box>
      )
    }
  }

  const handleUpdateSpendingLimitDisabled = () => {
    const { values } = formikInstance

    if (assignedVirtualCardDetailsSuccess) {
      const updatedSpendingLimit = values?.spendingLimit?.toString()
      const currentSpendingLimit = assignedVirtualCardDetailsSuccess.data.cardDetails.spendingLimit.toString()
      const latestSpendingLimitRequired = assignedVirtualCardDetailsSuccess?.data?.latestSpendingLimitRequired
      const isSpendingLimitInvalid =
        updatedSpendingLimit === currentSpendingLimit ||
        (latestSpendingLimitRequired &&
          Number(Number(updatedSpendingLimit).toFixed(2)) < Number(latestSpendingLimitRequired?.amount.toFixed(2)))

      return isSpendingLimitInvalid
    } else {
      return true
    }
  }

  return (
    <Box sx={styles.container}>
      <Tabs tabs={tabs} value={virtualCardsTabIndex} onChange={handleTabChange} />

      <Modal
        open={virtualCardAssignmentModalOpen}
        onClose={handleCloseVirtualCardAssignmentModal}
        title="Assign Virtual Card"
        subtitle="Please select the virtual card you would like to assign to the flight itinerary."
        body={getAssignVirtualCardModalBody()}
        primaryButton={{
          label: 'Assign',
          onClick: handleAssignVirtualCard,
          disabled: !selectedVirtualCardId,
          loading: virtualCardAssignmentLoading
        }}
        secondaryButton={{
          label: 'Cancel',
          onClick: handleCloseVirtualCardAssignmentModal
        }}
      />

      <Modal
        open={assignedVirtualCardDetailsModalOpen}
        onClose={handleCloseAssignedVirtualCardDetailsModal}
        title="Virtual Card Details"
        body={getAssignedVirtualCardDetailsModalBody()}
        primaryButton={{
          label: 'Update Spending Limit',
          disabled: handleUpdateSpendingLimitDisabled(),
          loading: updateAssignedVirtualCardDetailsLoading,
          onClick: () => {
            handleUpdateSpendingLimit(formikInstance.values)
          }
        }}
        secondaryButton={{
          label: 'Dismiss',
          onClick: handleCloseAssignedVirtualCardDetailsModal
        }}
      />
    </Box>
  )
}

export default VirtualCards
