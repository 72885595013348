import { FirebaseError } from 'firebase/app'
import {
  DocumentData,
  Firestore,
  Timestamp,
  UpdateData,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where
} from 'firebase/firestore'

import { Message } from '../../../components/Chat/context/Messaging.types'
import logger from '../../../functions/Logger/logger.functions'
import firebase, { getCurrentFirebaseUser, updateUserMapping } from '../firebase.functions'
import { Conversation, ConversationParticipant, FirebaseMessage } from './messaging.types'

const { db } = firebase

class MessagingService {
  private db: Firestore | null = null

  constructor() {
    try {
      this.db = db
    } catch (error) {
      logger.logError(error, 'Failed to initialize Firestore:', 'MessagingService')
    }
  }

  // Check if an error is a missing index error
  private isMissingIndexError(error: unknown): boolean {
    return (
      error instanceof FirebaseError &&
      error.code === 'failed-precondition' &&
      error.message.includes('requires an index')
    )
  }

  /**
   * Create or get a conversation between two users
   */
  async getOrCreateConversation(
    currentUserChatParticipant: ConversationParticipant,
    recipientChatParticipant: ConversationParticipant,
    orderId: string
  ): Promise<string> {
    try {
      // Always ensure we get the current Firebase user
      const currentUser = await getCurrentFirebaseUser()

      if (!currentUser || !currentUser.uid) {
        throw new Error('User not authenticated')
      }

      if (currentUser.uid !== currentUserChatParticipant.firebaseUserId) {
        currentUserChatParticipant.firebaseUserId = currentUser.uid

        // Also update the mapping to maintain relationship between Firebase UID and Hurrier ID
        await updateUserMapping(currentUser.uid, currentUserChatParticipant.hurrierUserId)
      }

      if (!this.db) {
        throw new Error('Firestore not initialized')
      }

      // Check if conversation already exists for this order
      const conversationsRef = collection(this.db, 'conversations')

      // Query only by orderId to find if any conversation exists for this order
      const orderQuery = query(conversationsRef, where('orderId', '==', orderId))

      const orderQuerySnapshot = await getDocs(orderQuery)

      if (!orderQuerySnapshot.empty) {
        // Get the first conversation for this order
        const existingConversation = orderQuerySnapshot.docs[0]
        const conversationData = existingConversation.data() as Conversation
        const conversationId = existingConversation.id

        // Check if both participants are already in the conversation
        const participants = conversationData.participants || []
        const currentUserExists =
          (Array.isArray(participants) &&
            participants.some((p) => p.hurrierUserId === currentUserChatParticipant.hurrierUserId)) ||
          false

        const recipientExists =
          (Array.isArray(participants) &&
            participants.some((p) => p.hurrierUserId === recipientChatParticipant.hurrierUserId)) ||
          false

        // Update the Firebase UID in the participantMap if the user exists but has a different UID
        const updateData: { [key: string]: unknown } = {}

        // If the current user exists in participants but has a different Firebase UID or isn't in the map
        if (currentUserExists) {
          const currentUserParticipant = participants.find(
            (p) => p.hurrierUserId === currentUserChatParticipant.hurrierUserId
          )

          if (
            currentUserParticipant &&
            (currentUserParticipant.firebaseUserId !== currentUserChatParticipant.firebaseUserId ||
              !conversationData.participantMap?.[currentUserChatParticipant.firebaseUserId])
          ) {
            // Update Firebase UID in participants array
            const updatedParticipants = Array.isArray(participants) ? [...participants] : []

            const index = updatedParticipants.findIndex(
              (p) => p.hurrierUserId === currentUserChatParticipant.hurrierUserId
            )

            if (index !== -1) {
              updatedParticipants[index] = {
                ...updatedParticipants[index],
                firebaseUserId: currentUserChatParticipant.firebaseUserId
              }
            }
            updateData.participants = updatedParticipants

            // Update participantMap
            updateData[`participantMap.${currentUserChatParticipant.firebaseUserId}`] = true

            // Remove old entry if it exists and is different
            if (currentUserParticipant.firebaseUserId !== currentUserChatParticipant.firebaseUserId) {
              // Mark for removal - we'll handle this separately if needed
              updateData[`participantMap.${currentUserParticipant.firebaseUserId}`] = null
            }
          }
        }

        // If either participant is missing, update the conversation to include them
        if (!currentUserExists || !recipientExists) {
          const updatedParticipants = Array.isArray(participants) ? [...participants] : []

          if (!currentUserExists) {
            updatedParticipants.push({
              hurrierUserId: currentUserChatParticipant.hurrierUserId,
              firebaseUserId: currentUserChatParticipant.firebaseUserId,
              firstName: currentUserChatParticipant.firstName,
              lastName: currentUserChatParticipant.lastName,
              role: currentUserChatParticipant.role
            })

            // Add to participantMap
            updateData[`participantMap.${currentUserChatParticipant.firebaseUserId}`] = true
          }

          if (!recipientExists) {
            updatedParticipants.push({
              hurrierUserId: recipientChatParticipant.hurrierUserId,
              firebaseUserId: recipientChatParticipant.firebaseUserId,
              firstName: recipientChatParticipant.firstName,
              lastName: recipientChatParticipant.lastName,
              role: recipientChatParticipant.role
            })

            // Add to participantMap
            updateData[`participantMap.${recipientChatParticipant.firebaseUserId}`] = true
          }

          // Also ensure unreadCount has entries for all participants
          const updatedUnreadCount = { ...conversationData.unreadCount }

          if (!currentUserExists) {
            updatedUnreadCount[currentUserChatParticipant.hurrierUserId] = 0
          }

          if (!recipientExists) {
            updatedUnreadCount[recipientChatParticipant.hurrierUserId] = 0
          }

          // Update the conversation with the new participants
          updateData.participants = updatedParticipants
          updateData.unreadCount = updatedUnreadCount
        }

        // Only update if we have something to update
        if (Object.keys(updateData).length > 0) {
          await updateDoc(
            doc(this.db, 'conversations', conversationId),
            updateData as unknown as UpdateData<DocumentData>
          )
        }

        // Return the existing conversation ID
        return conversationId
      }

      // Create new conversation
      const newConversation = {
        participants: [
          {
            hurrierUserId: currentUserChatParticipant.hurrierUserId,
            firebaseUserId: currentUserChatParticipant.firebaseUserId,
            firstName: currentUserChatParticipant.firstName,
            lastName: currentUserChatParticipant.lastName,
            role: currentUserChatParticipant.role
          },
          {
            hurrierUserId: recipientChatParticipant.hurrierUserId,
            firebaseUserId: recipientChatParticipant.firebaseUserId,
            firstName: recipientChatParticipant.firstName,
            lastName: recipientChatParticipant.lastName,
            role: recipientChatParticipant.role
          }
        ],
        participantMap: {
          [currentUserChatParticipant.firebaseUserId]: true,
          [recipientChatParticipant.firebaseUserId]: true
        },
        lastMessage: '',
        lastMessageTimestamp: Timestamp.now(),
        orderId,
        unreadCount: {
          [currentUserChatParticipant.hurrierUserId]: 0,
          [recipientChatParticipant.hurrierUserId]: 0
        }
      }

      // Log the conversation data for debugging
      logger.logError(
        null,
        `Creating new conversation: 
        CurrentUserFirebaseID: ${currentUserChatParticipant.firebaseUserId}
        RecipientFirebaseID: ${recipientChatParticipant.firebaseUserId}
        ParticipantMap: ${JSON.stringify(newConversation.participantMap)}`,
        'MessagingService'
      )

      // Create the conversation
      const docRef = await addDoc(collection(this.db, 'conversations'), newConversation)

      return docRef.id
    } catch (error) {
      logger.logError(error, 'Error in getOrCreateConversation:', 'MessagingService')
      throw error
    }
  }

  /**
   * Send a message in a conversation
   */
  async sendMessage(
    conversationId: string,
    sender: ConversationParticipant,
    receiver: ConversationParticipant,
    text: string
  ): Promise<string> {
    try {
      // Ensure user is authenticated
      const currentUser = await getCurrentFirebaseUser()

      // If the Firebase UID has changed, update it
      if (currentUser.uid !== sender.firebaseUserId) {
        const oldFirebaseUserId = sender.firebaseUserId
        sender.firebaseUserId = currentUser.uid

        // Also update the mapping
        await updateUserMapping(currentUser.uid, sender.hurrierUserId)

        // Update the participant in the conversation to maintain the participantMap
        const conversationRef = doc(this.db!, 'conversations', conversationId)
        const conversationSnap = await getDoc(conversationRef)

        if (conversationSnap.exists()) {
          const conversationData = conversationSnap.data() as Conversation

          // Find the sender in the participants array
          const participants = Array.isArray(conversationData.participants) ? [...conversationData.participants] : []
          const senderIndex = participants.findIndex((p) => p.hurrierUserId === sender.hurrierUserId)

          if (senderIndex !== -1) {
            // Update the Firebase UID
            participants[senderIndex].firebaseUserId = currentUser.uid

            // Update the conversation with the new participant info and participantMap
            await updateDoc(conversationRef, {
              participants,
              [`participantMap.${currentUser.uid}`]: true,
              [`participantMap.${oldFirebaseUserId}`]: null // Mark old ID for removal
            })
          }
        }
      }

      if (!this.db) {
        throw new Error('Firestore not initialized')
      }

      const messagesRef = collection(this.db, 'messages')

      const message: Omit<FirebaseMessage, 'id'> = {
        conversationId,
        sender,
        receiver,
        text,
        timestamp: Timestamp.now(),
        read: false
      }

      // Add message
      const docRef = await addDoc(messagesRef, message)

      // Update conversation with last message
      const conversationRef = doc(this.db, 'conversations', conversationId)

      // Get current conversation data to properly update unread count
      const conversationSnap = await getDoc(conversationRef)
      if (!conversationSnap.exists()) {
        throw new Error('Conversation not found')
      }

      const conversationData = conversationSnap.data() as Conversation

      // Get the current unread count for the receiver or default to 0
      const currentUnreadCount = conversationData.unreadCount[receiver.hurrierUserId] || 0

      // Check if sender and receiver are the same user
      const isSelfMessage = sender.hurrierUserId === receiver.hurrierUserId

      // Create update object for Firestore
      const updateObj = {
        lastMessage: text,
        lastMessageTimestamp: message.timestamp,
        // If it's a self-message, don't increment unread count
        [`unreadCount.${receiver.hurrierUserId}`]: isSelfMessage ? 0 : currentUnreadCount + 1,
        // Always reset sender's unread count to 0
        [`unreadCount.${sender.hurrierUserId}`]: 0
      }

      // Update the conversation with new values
      await updateDoc(conversationRef, updateObj)

      return docRef.id
    } catch (error) {
      logger.logError(error, 'Error in sendMessage:', 'MessagingService')
      throw error
    }
  }

  /**
   * Subscribe to messages for a conversation
   */
  subscribeToMessages(conversationId: string, callback: (messages: Message[]) => void): () => void {
    try {
      if (!this.db) {
        logger.logError(new Error('Firestore not initialized'), 'Firestore not initialized', 'MessagingService')
        throw new Error('Firestore not initialized')
      }

      // Create a reference we can update from the Promise
      let unsubscribeFunc: (() => void) | undefined

      // First, ensure we have the latest Firebase UID and that we have permission to access this conversation
      getCurrentFirebaseUser()
        .then(async (currentUser) => {
          if (!currentUser || !currentUser.uid) {
            throw new Error('User not authenticated')
          }

          // Get the conversation to check if the user is a participant
          const conversationRef = doc(this.db!, 'conversations', conversationId)
          const conversationSnap = await getDoc(conversationRef)

          if (!conversationSnap.exists()) {
            throw new Error('Conversation not found')
          }

          const conversationData = conversationSnap.data() as Conversation

          // Check if the user is in the participantMap
          if (!conversationData.participantMap?.[currentUser.uid]) {
            // User is not in the participantMap, but they might be in the participants array
            // with a different Firebase UID. Let's check and update if needed.
            const participants = conversationData.participants || []
            const participant = participants.find((p) => p.firebaseUserId === currentUser.uid)

            if (!participant) {
              // Check if there's a participant with the same Hurrier ID
              // We need to get the Hurrier ID for the current Firebase UID
              const userMappingRef = doc(this.db!, 'userMappings', currentUser.uid)
              const userMappingSnap = await getDoc(userMappingRef)

              if (!userMappingSnap.exists()) {
                throw new Error('User mapping not found')
              }

              const userMapping = userMappingSnap.data()
              const hurrierUserId = userMapping.hurrierUserId

              // Find the participant with this Hurrier ID
              const participantByHurrierId = participants.find((p) => p.hurrierUserId === hurrierUserId)

              if (participantByHurrierId) {
                // Update the Firebase UID
                const oldFirebaseUserId = participantByHurrierId.firebaseUserId

                // Update the conversation
                await updateDoc(conversationRef, {
                  [`participants.${participants.findIndex((p) => p.hurrierUserId === hurrierUserId)}.firebaseUserId`]:
                    currentUser.uid,
                  [`participantMap.${currentUser.uid}`]: true,
                  [`participantMap.${oldFirebaseUserId}`]: null
                })
              } else {
                throw new Error('User is not a participant in this conversation')
              }
            } else {
              // User is in participants but not in participantMap
              await updateDoc(conversationRef, {
                [`participantMap.${currentUser.uid}`]: true
              })
            }

            // After updating, get the fresh conversation data to verify participantMap
            await getDoc(conversationRef)
          }

          // Store a reference to the db for safety
          const db = this.db

          if (!db) {
            throw new Error('Firestore not initialized')
          }

          // Try the query with proper ordering and limited to match security rules
          try {
            // Query for messages in this conversation
            // Important: This needs to match what the security rules expect with 'conversationId' filter
            const q = query(
              collection(db, 'messages'),
              where('conversationId', '==', conversationId),
              orderBy('timestamp', 'asc')
            )

            // Set up real-time listener
            const unsub = onSnapshot(
              q,
              (querySnapshot) => {
                const messages = querySnapshot.docs.map((doc) => {
                  const data = doc.data() as FirebaseMessage

                  return this.firebaseMessageToUIMessage({
                    ...data,
                    id: doc.id
                  })
                })

                callback(messages)
              },
              (error) => {
                logger.logError(error, 'Error in Firestore listener:', 'MessagingService')

                // If we get an error trying to query with ordering, try without ordering
                if (this.isMissingIndexError(error)) {
                  // Try a simpler query without the ordering
                  const simpleQ = query(collection(db, 'messages'), where('conversationId', '==', conversationId))

                  const simpleUnsub = onSnapshot(
                    simpleQ,
                    (simpleSnapshot) => {
                      // Manual sorting after fetching
                      const messages = simpleSnapshot.docs
                        .map((doc) => {
                          const data = doc.data() as FirebaseMessage

                          return this.firebaseMessageToUIMessage({
                            ...data,
                            id: doc.id
                          })
                        })
                        .sort((a, b) => a.timestamp - b.timestamp)

                      callback(messages)
                    },
                    (simpleError) => {
                      logger.logError(simpleError, 'Error in simple Firestore listener:', 'MessagingService')
                    }
                  )

                  // Update our reference
                  unsubscribeFunc = simpleUnsub
                }
              }
            )

            // Update our reference
            unsubscribeFunc = unsub
          } catch (error) {
            logger.logError(error, 'Error setting up query:', 'MessagingService')
            throw error
          }
        })
        .catch((error) => {
          logger.logError(error, 'Error in subscribeToMessages:', 'MessagingService')
        })

      // Return a function that calls the unsubscribe function if it exists
      return () => {
        if (unsubscribeFunc) {
          unsubscribeFunc()
        }
      }
    } catch (error) {
      logger.logError(error, 'Error in subscribeToMessages:', 'MessagingService')

      return () => {
        // Do nothing
      }
    }
  }

  /**
   * Mark messages as read
   */
  async markMessagesAsRead(conversationId: string, userId: string): Promise<void> {
    try {
      // Ensure user is authenticated
      const currentUser = await getCurrentFirebaseUser()
      if (currentUser.uid !== userId) {
        userId = currentUser.uid
      }

      if (!this.db) {
        throw new Error('Firestore not initialized')
      }

      // Get the current conversation to check unread count
      const conversationRef = doc(this.db, 'conversations', conversationId)
      const conversationSnap = await getDoc(conversationRef)

      if (!conversationSnap.exists()) {
        throw new Error('Conversation not found')
      }

      // Query for unread messages sent to this user
      const q = query(
        collection(this.db, 'messages'),
        where('conversationId', '==', conversationId),
        where('receiver.hurrierUserId', '==', userId),
        where('read', '==', false)
      )

      const querySnapshot = await getDocs(q)

      // Batch update all unread messages
      const batch = querySnapshot.docs.map(async (document) => {
        if (!this.db) {
          throw new Error('Firestore not initialized')
        }

        const messageRef = doc(this.db, 'messages', document.id)
        await updateDoc(messageRef, { read: true })
      })

      await Promise.all(batch)

      // Reset unread count in conversation
      await updateDoc(conversationRef, {
        [`unreadCount.${userId}`]: 0
      })
    } catch (error) {
      logger.logError(error, 'Error in markMessagesAsRead:', 'MessagingService')
      throw error
    }
  }

  /**
   * Subscribe to user conversations
   */
  subscribeToUserConversations(
    participant: ConversationParticipant,
    callback: (conversations: Conversation[]) => void
  ): () => void {
    try {
      if (!this.db) {
        throw new Error('Firestore not initialized')
      }

      // Ensure we're using the current Firebase UID
      getCurrentFirebaseUser()
        .then((currentUser) => {
          if (currentUser && currentUser.uid && currentUser.uid !== participant.firebaseUserId) {
            // Also update the mapping
            updateUserMapping(currentUser.uid, participant.hurrierUserId).catch((error) => {
              logger.logError(error, 'Error updating user mapping:', 'MessagingService')
            })
          }

          // Safely store a reference to the db
          const db = this.db

          if (!db) {
            throw new Error('Firestore not initialized')
          }

          // Create query for conversations where the user is a participant
          // We check for their firebaseUserId in the participantMap
          const firestoreQuery = query(
            collection(db, 'conversations'),
            where(`participantMap.${participant.firebaseUserId}`, '==', true)
          )

          return onSnapshot(
            firestoreQuery,
            (querySnapshot) => {
              const conversations = querySnapshot.docs.map(
                (doc) =>
                  ({
                    id: doc.id,
                    ...doc.data()
                  }) as Conversation
              )

              // Notify the UI of the conversations
              callback(conversations)
            },
            (error) => {
              // Special handling for missing index errors
              if (this.isMissingIndexError(error)) {
                logger.logError(
                  error,
                  'Missing index error in subscribeToUserConversations. Falling back to client-side filtering.',
                  'MessagingService'
                )

                // Fallback to getting all conversations and filtering client-side
                const fallbackQuery = query(collection(db, 'conversations'))

                return onSnapshot(
                  fallbackQuery,
                  (querySnapshot) => {
                    // Filter conversations where the user is a participant by checking participantMap
                    const conversations = querySnapshot.docs
                      .map((doc) => ({ id: doc.id, ...doc.data() }) as Conversation)
                      .filter((conversation) => conversation.participantMap?.[participant.firebaseUserId] === true)

                    callback(conversations)
                  },
                  (fallbackError) => {
                    logger.logError(
                      fallbackError,
                      'Error in fallback query for subscribeToUserConversations:',
                      'MessagingService'
                    )
                  }
                )
              } else {
                logger.logError(error, 'Error in subscribeToUserConversations:', 'MessagingService')
              }
            }
          )
        })
        .catch((error) => {
          logger.logError(error, 'Error getting current Firebase user:', 'MessagingService')

          return () => {
            // Do nothing
          }
        })

      // Return a dummy unsubscribe function that will be replaced when the Promise resolves
      return () => {
        // This will be replaced when the Promise resolves
      }
    } catch (error) {
      logger.logError(error, 'Error in subscribeToUserConversations:', 'MessagingService')

      return () => {
        // Do nothing
      }
    }
  }

  firebaseMessageToUIMessage = (firebaseMessage: FirebaseMessage & { id: string }): Message => {
    return {
      id: firebaseMessage.id,
      senderHurrierUserId: firebaseMessage.sender.hurrierUserId,
      senderName: `${firebaseMessage.sender.firstName} ${firebaseMessage.sender.lastName}`,
      text: firebaseMessage.text,
      timestamp: firebaseMessage.timestamp.toMillis(),
      read: firebaseMessage.read
    }
  }
}

export default new MessagingService()
