import localStorage from '../LocalStorage/localStorage'

/**
 * Sets the authentication status in localStorage.
 *
 * @param {boolean} status - The authentication status to set.
 * @example
 * setAuthenticationStatus(true)
 */
export const setAuthenticationStatus = (status: boolean) => {
  localStorage.setItem('sessionAuthenticated', status)
}

/**
 * Checks if the user is authenticated based on the stored authentication status.
 *
 * @returns {boolean} - True if the user is authenticated, otherwise false.
 * @example
 * const isAuth = isAuthenticated()
 * console.log(isAuth)
 */
export const isAuthenticated = () => {
  const authStatus = localStorage.getItem('sessionAuthenticated')

  return authStatus === true
}

/**
 * Sets the admin login status in localStorage.
 *
 * @param {boolean} status - The admin login status to set.
 * @example
 * setAdminLoginStatus(true)
 */
export const setAdminLoginStatus = (status: boolean) => {
  localStorage.setItem('adminLoginStatus', status)
}

/**
 * Checks if the admin is logged in based on the stored admin login status.
 *
 * @returns {boolean} - True if the admin is logged in, otherwise false.
 * @example
 * const isAdminLoggedIn = isAdminLoggedIn()
 * console.log(isAdminLoggedIn)
 */
export const isAdminLoggedIn = () => {
  const authStatus = localStorage.getItem('adminLoginStatus')

  return authStatus === true
}
