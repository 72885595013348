import env from '@/networkRequests/apiClient/env.config'

import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAllExternalFlightBookingRequestsErrorResponse,
  GetAllExternalFlightBookingRequestsRequest,
  GetAllExternalFlightBookingRequestsSuccessResponse
} from './getAllExternalFlightBookingRequests.types'

export const getAllExternalFlightBookingRequestsApi = async (
  request: GetAllExternalFlightBookingRequestsRequest
): Promise<GetAllExternalFlightBookingRequestsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.getAllExternalFlightBookingRequests,
    method: 'GET',
    data: request,
    params: {
      status: request.status || '',
      searchQuery: request.searchQuery || '',
      page: request.page || 1,
      size: request.size || env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllExternalFlightBookingRequestsSuccessResponse
  } else {
    throw response as GetAllExternalFlightBookingRequestsErrorResponse
  }
}

export default getAllExternalFlightBookingRequestsApi
