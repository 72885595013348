import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getProfileApi } from './getProfile.api'
import getProfileInitialState from './getProfile.initialState'
import { GetProfileErrorResponse, GetProfileRequestPayload } from './getProfile.types'

export const getProfile = createAsyncThunk(
  urls.profile.getProfile,
  async (payload: GetProfileRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload || {}

    try {
      const response = await getProfileApi(request)

      onSuccess && onSuccess(response?.data)

      return response
    } catch (error) {
      if (request.firebaseData) {
        errorHandler.handleApiError(error as ApiErrorResponse, undefined, true)
      } else {
        errorHandler.handleApiError(error as ApiErrorResponse)
      }

      return rejectWithValue(error)
    }
  }
)

const getProfileSlice = createSlice({
  name: urls.profile.getProfile,
  initialState: getProfileInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getProfile.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetProfileErrorResponse
        state.success = null
      })
  }
})

export const { actions: getProfileActions, reducer: getProfileReducer } = getProfileSlice
export default getProfileSlice.reducer
