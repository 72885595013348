import React, { useMemo, useState } from 'react'

import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { Check } from '@mui/icons-material'
import { Box, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  XIcon
} from 'react-share'

import Button from '../Button/Button.component'
import Modal from '../Modal/Modal.component'

import images from '../../../assets/images'
import { Country, CountryMarketplaceMap } from '../../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { RootState } from '../../../redux/store/store.types'
import { copyToClipboard } from '../../functions/Clipboard/clipboard.function'
import logger from '../../functions/Logger/logger.functions'
import { getCountryByCode } from '../../functions/Places/places.functions'
import { useAppSelector } from '../../hooks/redux.hooks'
import { SnackbarAutoHideDuration } from '../Snackbar/Snackbar.types'
import styles from './ShareFlightItineraryLinkModal.styles'
import { ShareFlightItineraryLinkModalProps } from './ShareFlightItineraryLinkModal.types'

const ShareFlightItineraryLinkModal: React.FC<ShareFlightItineraryLinkModalProps> = (props) => {
  const { shareLink, travelerFirstName, destinationCountries, originCountries, tripType, open, onClose } = props

  const { values } = useAppSelector((state: RootState) => state.firebaseRemoteConfig)

  const [isCopied, setIsCopied] = useState(false)

  const [isDesktop] = useResponsiveness()

  const handleCopyClick = async () => {
    try {
      const successfullyCopied = await copyToClipboard(shareLink)

      if (successfullyCopied) {
        setIsCopied(true)

        setTimeout(() => {
          setIsCopied(false)
        }, SnackbarAutoHideDuration.SHORT)
      }
    } catch (err) {
      logger.logError(err, 'Failed to copy text', 'handleCopyClick')
    }
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const countryMarketplaceMap = useMemo(() => {
    return (values?.countryMarketplaceMap as CountryMarketplaceMap) ?? {}
  }, [values])

  const uniqueCountriesWithMarketplace = useMemo(() => {
    const uniqueCountries: Country[] = []
    const seenCodes = new Set()
    const countries = [...destinationCountries, ...originCountries]
    const countriesToCheck = tripType === 'oneWay' ? originCountries : countries

    countriesToCheck.forEach((country) => {
      if (countryMarketplaceMap[country.iataCode] && !seenCodes.has(country.iataCode)) {
        const countryObject = getCountryByCode(country.iataCode)

        if (countryObject) {
          seenCodes.add(country.iataCode)
          uniqueCountries.push(countryObject)
        }
      }
    })

    return uniqueCountries
  }, [destinationCountries, originCountries, countryMarketplaceMap, tripType])

  const countriesWithMarketplaceNames = uniqueCountriesWithMarketplace.map((country) => country.name).join(' or ')

  const shareLinkMessage = `${travelerFirstName} is traveling from ${countriesWithMarketplaceNames}. Take advantage of this opportunity to have your Amazon items brought back at a lower cost! Paste URL for your Amazon ${countriesWithMarketplaceNames} items. You only pay for the item and delivery. No other extra fees.`

  const modalBody = (
    <Box sx={styles.modalBody}>
      <Box sx={styles.shareLinkContainer}>
        <Box sx={styles.shareLinkTop}>
          <Box
            component="img"
            src={images.shareLinkWithIconsIcon}
            alt="Share Link Modal Image"
            sx={styles.shareLinkModalImage}
          />

          <Typography variant={isMobile ? 'h6' : 'h5'} sx={styles.shareLinkModalPrimaryText}>
            Fly Smart & Save Big!
          </Typography>

          <Typography variant={isMobile ? 'body1' : 'h6'} sx={styles.shareLinkModalSecondaryText}>
            Share Hurrier link to get paid to carry items.
          </Typography>

          {/* <Typography variant="body1" sx={styles.shareLinkModalTertiaryText}>
            Send link to <span style={styles.linkText}>3 friends or family</span> to get item requests and fly for less.
            Finish booking flight within <span style={styles.linkText}>3 days</span>.
          </Typography> */}
        </Box>

        <Box sx={styles.shareViaSocialMediaContainer}>
          <Typography variant={isMobile ? 'body2' : 'body1'} sx={styles.shareViaSocialMedia}>
            Share via social media by clicking on the icon below.
          </Typography>

          <Box sx={styles.socialIconsContainer}>
            <FacebookShareButton url={shareLink} style={styles.socialIconButton}>
              <FacebookIcon size={isDesktop ? 48 : 36} round />
            </FacebookShareButton>

            {isMobile && (
              <IconButton
                sx={styles.socialIconButton}
                href={`https://instagram.com/direct/new/?text=${encodeURIComponent(shareLink)}`}
                target="_blank"
              >
                <img src={images.instagramGraphic} style={styles.socialMediaIcon} alt="Instagram" />
              </IconButton>
            )}

            <TwitterShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <XIcon size={isDesktop ? 48 : 36} round />
            </TwitterShareButton>

            <IconButton
              sx={styles.socialIconButton}
              href={`https://wa.me/?text=${encodeURIComponent(shareLinkMessage + '\n\n' + shareLink)}`}
              target="_blank"
            >
              <WhatsappIcon size={isDesktop ? 48 : 36} round />
            </IconButton>

            <RedditShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <RedditIcon size={isDesktop ? 48 : 36} round />
            </RedditShareButton>

            <TelegramShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <TelegramIcon size={isDesktop ? 48 : 36} round />
            </TelegramShareButton>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.copyLinkContainer}>
        <Typography variant={isMobile ? 'body2' : 'body1'} sx={styles.copyLinkInstructionsText}>
          Copy the link to share on your preferred platform.
        </Typography>

        <Box sx={styles.copyLinkContentContainer}>
          <TextField sx={styles.copyLinkText} value={shareLink} InputProps={{ readOnly: true }} />

          <Button
            onClick={handleCopyClick}
            buttonType="primary"
            text={isCopied ? 'Copied' : 'Copy Link'}
            icon={isCopied ? <Check /> : undefined}
            disabled={isCopied}
            fullWidth={!isDesktop}
          />
        </Box>
      </Box>
    </Box>
  )

  return <Modal open={open} onClose={onClose} body={modalBody} showCloseButton />
}

export default ShareFlightItineraryLinkModal
