import router from './functions/router.functions'
import { Route } from './router.types'
import routes from './routes.dictionary'

const ROUTES = router.generateRoutes([
  routes.home,
  routes.contactUs,
  routes.signUp,
  routes.signUpSuccess,
  routes.signUpVerification,
  routes.login,
  routes.flightSearchResults,
  // routes.tripSummary
  routes.tripSummary as unknown as Route, // Todo: Fix typings for TripSummaryProps
  routes.forgetPassword,
  routes.forgetPasswordSuccess,
  routes.resetForgottenPassword,
  routes.changePassword,
  routes.profile,
  routes.shop,
  routes.offers,
  routes.cart,
  routes.sharedTrip,
  routes.orders,
  routes.requests,
  routes.myTrips,
  routes.flightBooking,
  routes.termsOfServices,
  routes.privacyPolicy,
  routes.deliveryPolicy,
  routes.trustAndSafety,
  routes.cookiePolicy,
  routes.adminDashboard,
  routes.requestVirtualCardForExternalBooking,
  routes.faqs
])

export default ROUTES
