import React, { useMemo } from 'react'

import { resendEmailVerification } from '@/redux/states/auth/resendEmailVerification/resendEmailVerification.slice'
import { ResendEmailVerificationRequestPayload } from '@/redux/states/auth/resendEmailVerification/resendEmailVerification.types'
import { RootState } from '@/redux/store/store.types'
import Snackbar from '@/shared/components/Snackbar/Snackbar.functions'
import { useAppDispatch } from '@/shared/hooks/redux.hooks'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import StatusUpdatePage from '../../shared/components/StatusUpdatePage/StatusUpdatePage.component'

const SignUpSuccess: React.FC = () => {
  const dispatch = useAppDispatch()
  const { loading } = useSelector((state: RootState) => state.resendEmailVerification)
  const { success } = useSelector((state: RootState) => state.verifyEmail)

  const emailFromSuccess = useMemo(() => {
    return success?.data.email
  }, [success])

  const onResendEmailVerification = (values?: { email: string }) => {
    const emailToUse = values?.email || emailFromSuccess

    if (!emailToUse) {
      return
    }

    const payload: ResendEmailVerificationRequestPayload = {
      request: { email: emailToUse },
      onSuccess: () => {
        Snackbar.show({
          message: 'Email sent successfully. Please check your email and click the link to verify your account.',
          severity: 'success'
        })
      }
    }

    dispatch(resendEmailVerification(payload))
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  })

  return (
    <StatusUpdatePage
      title="Sign Up Successful!"
      subtitle="A confirmation link has been sent to your email address. Please check your email and click the link to verify your account."
      status="success"
      action={
        emailFromSuccess
          ? {
              prompt: "Didn't receive the email?",
              loading: loading,
              linkText: 'Resend',
              onClick: onResendEmailVerification
            }
          : undefined
      }
      form={
        !emailFromSuccess
          ? {
              subtitle: "Didn't receive the email?",
              initialValues: { email: '' },
              validationSchema,
              formFields: [
                {
                  name: 'email',
                  label: 'Email Address',
                  type: 'email',
                  grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                  required: true,
                  fullWidth: true,
                  autoComplete: 'email'
                }
              ],
              onSubmit: (values) => onResendEmailVerification(values as { email: string }),
              buttonText: 'Resend',
              loading: loading
            }
          : undefined
      }
    />
  )
}

export default SignUpSuccess
