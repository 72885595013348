import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../../shared/types/api.types'
import { getAssignedVirtualCardDetailsForAdminApi } from './getAssignedVirtualCardDetailsForAdmin.api'
import getAssignedVirtualCardDetailsForAdminInitialState from './getAssignedVirtualCardDetailsForAdmin.initialState'
import {
  GetAssignedVirtualCardDetailsForAdminErrorResponse,
  GetAssignedVirtualCardDetailsForAdminRequestPayload
} from './getAssignedVirtualCardDetailsForAdmin.types'

export const getAssignedVirtualCardDetailsForAdmin = createAsyncThunk(
  urls.admin.getAssignedVirtualCardDetailsForAdmin,
  async (payload: GetAssignedVirtualCardDetailsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAssignedVirtualCardDetailsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAssignedVirtualCardDetailsForAdminSlice = createSlice({
  name: urls.admin.getAssignedVirtualCardDetailsForAdmin,
  initialState: getAssignedVirtualCardDetailsForAdminInitialState,
  reducers: {
    resetGetAssignedVirtualCardDetailsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssignedVirtualCardDetailsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAssignedVirtualCardDetailsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAssignedVirtualCardDetailsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAssignedVirtualCardDetailsForAdminErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: getAssignedVirtualCardDetailsForAdminActions,
  reducer: getAssignedVirtualCardDetailsForAdminReducer
} = getAssignedVirtualCardDetailsForAdminSlice
export const { resetGetAssignedVirtualCardDetailsForAdmin } = getAssignedVirtualCardDetailsForAdminActions
export default getAssignedVirtualCardDetailsForAdminSlice.reducer
