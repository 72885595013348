import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../Button/Button.component'
import EmptyState from '../EmptyState/EmptyState.component'
import Loader from '../Loader/Loader.component'

import images from '../../../assets/images'
import styles from './PaymentSubmittedSuccessfully.styles'
import { PaymentSubmittedSuccessfullyProps } from './PaymentSubmittedSuccessfully.types'

const { OrderSubmittedCheckmarkIcon } = images

export const PaymentSubmittedSuccessfully: React.FC<PaymentSubmittedSuccessfullyProps> = (props) => {
  const { title, description, button, loadingState, success, errorState } = props

  if (loadingState.loading) {
    return <Loader loading={loadingState.loading} text={loadingState.text} size="large" marginTop={10} />
  } else if (success) {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.iconContainer}>
          <OrderSubmittedCheckmarkIcon style={styles.icon} />
        </Box>

        <Typography sx={styles.title}>{title}</Typography>

        <Typography sx={styles.description}>{description}</Typography>

        <Button {...button} />
      </Box>
    )
  } else if (errorState) {
    return <EmptyState {...errorState} marginTop={10} />
  } else {
    return null
  }
}
