import React from 'react'

import { StepConnector } from '@mui/material'

import styles from './CustomConnector.styles'
import { CustomConnectorProps } from './CustomConnector.types'

const CustomConnector: React.FC<CustomConnectorProps> = (props) => {
  const { orientation = 'horizontal' } = props

  return <StepConnector {...props} sx={styles.connector(orientation)} />
}

export default CustomConnector
