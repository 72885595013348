import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import env from '@/networkRequests/apiClient/env.config'
import { FlightItinerary } from '@/redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { createCustomerSession } from '@/redux/states/payments/createCustomerSession/createCustomerSession.slice'
import { CreateCustomerSessionRequestPayload } from '@/redux/states/payments/createCustomerSession/createCustomerSession.types'
import {
  createExternalFlightBookingPaymentIntent,
  resetCreateExternalFlightBookingPaymentIntent
} from '@/redux/states/payments/createExternalFlightBookingPaymentIntent/createExternalFlightBookingPaymentIntent.slice'
import { CreateExternalFlightBookingPaymentIntentRequestPayload } from '@/redux/states/payments/createExternalFlightBookingPaymentIntent/createExternalFlightBookingPaymentIntent.types'
import { createRequestForVirtualCard } from '@/redux/states/payments/createRequestForVirtualCard/createRequestForVirtualCard.slice'
import { CreateRequestForVirtualCardRequestPayload } from '@/redux/states/payments/createRequestForVirtualCard/createRequestForVirtualCard.types'
import { AccordionButtonProps } from '@/shared/components/Accordion/Accordion.types'
import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material'

import Button from '../../shared/components/Button/Button.component'
import Dropdown from '../../shared/components/Dropdown/Dropdown.component'
import { PaymentSubmittedSuccessfully } from '../../shared/components/PaymentSubmittedSuccessfully/PaymentSubmittedSuccessfully.component'
import Stepper from '../../shared/components/Stepper/Stepper.component'
import TripOverview from '../TripSummary/components/TripOverview/TripOverview.component'
import EmptyState from '@/shared/components/EmptyState/EmptyState.component'
import PageTitle from '@/shared/components/PageTitle/PageTitle.component'
import StripeCheckoutForm from '@/shared/components/StripeCheckoutForm/StripeCheckoutForm.component'
import StripeConnectOnboardingForm from '@/shared/components/StripeConnectOnboardingForm/StripeConnectOnboardingForm.component'

import { checkOnboardingInfo } from '../../redux/states/payments/checkOnboardingInfo/checkOnboardingInfo.slice'
import {
  CheckOnboardingInfoSuccessResponse,
  OnboardingInfoType
} from '../../redux/states/payments/checkOnboardingInfo/checkOnboardingInfo.types'
import {
  createAccountSession,
  resetCreateAccountSession
} from '../../redux/states/payments/createAccountSession/createAccountSession.slice'
import { CreateAccountSessionRequestPayload } from '../../redux/states/payments/createAccountSession/createAccountSession.types'
import {
  CreateFlightBookingPaymentIntentSuccessResponse,
  StripeSession
} from '../../redux/states/payments/createFlightBookingPaymentIntent/createFlightBookingPaymentIntent.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import ModalFunctions from '../../shared/components/Modal/Modal.functions'
import { PaymentFormRef } from '../../shared/components/StripeCheckoutForm/StripeCheckoutForm.types'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { getCountries } from '../../shared/functions/Places/places.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import {
  ACCOUNT_SESSION_STORAGE_KEY,
  CREATE_EXTERNAL_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY,
  FLIGHT_ITINERARY_STORAGE_KEY,
  stepDetails,
  steps
} from './RequestVirtualCardForExternalBooking.dictionary'
import styles from './RequestVirtualCardForExternalBooking.styles'
import { RequestVirtualCardForExternalBookingProps } from './RequestVirtualCardForExternalBooking.types'

const RequestVirtualCardForExternalBooking: React.FC = () => {
  const paymentIntent = router.getUrlParam('payment_intent')
  const redirectStatusFromPayment = router.getUrlParam('redirect_status')
  const paymentSuccess = redirectStatusFromPayment === 'succeeded' && paymentIntent !== null

  const dispatch = useAppDispatch()
  const [isDesktop] = useResponsiveness()
  const navigationProps = router.getNavigationProps() as RequestVirtualCardForExternalBookingProps
  const navigate = router.navigate()

  const [activeStep, setActiveStep] = useState(
    paymentSuccess ? stepDetails.virtualCardRequested.index : stepDetails.setupStripeConnectAccount.index
  )

  const [isPaymentDetailsSetupFormValid, setIsPaymentDetailsSetupFormValid] = useState(false)
  const [isOnboadingComlpete, setIsOnboadingComplete] = useState(false)
  const [, setPaymentRequestLoading] = useState(false)
  const [paymentDetailsSetupLoading, setPaymentDetailsSetupLoading] = useState(false)
  const [stripeConnectAccountCountry, setStripeConnectAccountCountry] = useState<string | null>(null)

  const [accountSession, setAccountSession] = useState<StripeSession | null>(
    localStorage.getItem(ACCOUNT_SESSION_STORAGE_KEY)
  )

  const {
    loading: createExternalFlightBookingPaymentIntentLoading,
    success: createExternalFlightBookingPaymentIntentSuccess,
    error: createExternalFlightBookingPaymentIntentError
  } = useAppSelector((state: RootState) => state.createExternalFlightBookingPaymentIntent)

  const {
    loading: createAccountSessionLoading,
    success: createAccountSessionSuccess,
    error: createAccountSessionError
  } = useAppSelector((state: RootState) => state.createAccountSession)

  const { success: customerSessionSuccess, loading: createCustomerSessionLoading } = useAppSelector(
    (state) => state.createCustomerSession
  )

  const {
    loading: createRequestForVirtualCardLoading,
    success: createRequestForVirtualCardSuccess,
    error: createRequestForVirtualCardError
  } = useAppSelector((state: RootState) => state.createRequestForVirtualCard)

  const countries = getCountries()

  const paymentDetailsSetupFormRef = useRef<PaymentFormRef>(null)

  const flightItinerary =
    navigationProps?.state?.itinerary || (localStorage.getItem(FLIGHT_ITINERARY_STORAGE_KEY) as FlightItinerary)

  const setupIntent = router.getUrlParam('setup_intent')
  const redirectStatusFromSetup = router.getUrlParam('redirect_status')
  const setupPaymentDetailsSuccess = redirectStatusFromSetup === 'succeeded' && setupIntent !== null

  const externalFlightBookingPaymentIntent: CreateFlightBookingPaymentIntentSuccessResponse | null = useMemo(() => {
    return (
      createExternalFlightBookingPaymentIntentSuccess ||
      localStorage.getItem(CREATE_EXTERNAL_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY)
    )
  }, [createExternalFlightBookingPaymentIntentSuccess])

  const { loading: checkOnboardingInfoLoading } = useAppSelector((state: RootState) => state.checkOnboardingInfo)

  const handleCreateAccountSession = useCallback(() => {
    setPaymentDetailsSetupLoading(true)

    const payload: CreateAccountSessionRequestPayload = {
      request: {
        countryCode: stripeConnectAccountCountry ?? undefined
      },

      onSuccess: (response) => {
        if (externalFlightBookingPaymentIntent) {
          const accountSession = {
            secret: response.data.secret,
            expiresAt: response.data.expiresAt
          }

          localStorage.setItem(
            CREATE_EXTERNAL_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY,
            externalFlightBookingPaymentIntent
          )

          localStorage.setItem(ACCOUNT_SESSION_STORAGE_KEY, accountSession)
          localStorage.setItem(FLIGHT_ITINERARY_STORAGE_KEY, flightItinerary)
        }

        setPaymentDetailsSetupLoading(false)
      },

      onError: () => {
        setPaymentDetailsSetupLoading(false)
      }
    }

    dispatch(createAccountSession(payload))
  }, [dispatch, externalFlightBookingPaymentIntent, stripeConnectAccountCountry, flightItinerary])

  const handleCreateExternalFlightBookingPaymentIntentRequest = useCallback(() => {
    const payload: CreateExternalFlightBookingPaymentIntentRequestPayload = {
      request: {
        flightItineraryId: flightItinerary.id.toString()
      }
    }

    dispatch(createExternalFlightBookingPaymentIntent(payload))
  }, [dispatch, flightItinerary])

  useEffect(() => {
    if (
      !setupPaymentDetailsSuccess &&
      createExternalFlightBookingPaymentIntentSuccess?.data.isCustomerOnboardedToStripeConnect &&
      accountSession?.secret === undefined
    ) {
      handleCreateAccountSession()
    }
  }, [
    createExternalFlightBookingPaymentIntentSuccess,
    accountSession,
    handleCreateAccountSession,
    setupPaymentDetailsSuccess
  ])

  useEffect(() => {
    if (!setupPaymentDetailsSuccess) {
      handleCreateExternalFlightBookingPaymentIntentRequest()
    }
  }, [handleCreateExternalFlightBookingPaymentIntentRequest, setupPaymentDetailsSuccess])

  const isCustomerOnboardedToStripeConnect = useMemo(() => {
    return externalFlightBookingPaymentIntent?.data?.isCustomerOnboardedToStripeConnect
  }, [externalFlightBookingPaymentIntent])

  useEffect(() => {
    window.scrollTo(0, 0)

    return () => {
      localStorage.removeItem(ACCOUNT_SESSION_STORAGE_KEY)
      localStorage.removeItem(CREATE_EXTERNAL_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY)
      localStorage.removeItem(FLIGHT_ITINERARY_STORAGE_KEY)

      dispatch(resetCreateAccountSession())
      dispatch(resetCreateExternalFlightBookingPaymentIntent())
    }
  }, [dispatch])

  useEffect(() => {
    if (createAccountSessionSuccess?.data.secret) {
      setAccountSession(createAccountSessionSuccess.data)
    }
  }, [createAccountSessionSuccess])

  const handleCheckOnboardingInfo = useCallback(
    (onSuccess?: () => void, onError?: () => void) => {
      const onIdCheckError = () => {
        setAccountSession(null)
        localStorage.removeItem(ACCOUNT_SESSION_STORAGE_KEY)
        setIsOnboadingComplete(false)

        if (externalFlightBookingPaymentIntent?.data?.setupIntent && stripeConnectAccountCountry) {
          setPaymentDetailsSetupLoading(true)

          const payload: CreateAccountSessionRequestPayload = {
            request: {
              countryCode: stripeConnectAccountCountry
            },

            onSuccess: (response) => {
              if (externalFlightBookingPaymentIntent) {
                const newAccountSession = {
                  secret: response.data.secret,
                  expiresAt: response.data.expiresAt
                }

                setAccountSession(newAccountSession)
                setPaymentDetailsSetupLoading(false)
              }
            },

            onError: () => {
              setPaymentDetailsSetupLoading(false)

              ModalFunctions.show({
                title: 'Error',
                body: 'Failed to create account session. Please try again.',
                primaryButton: {
                  label: 'OK'
                }
              })
            }
          }

          dispatch(createAccountSession(payload))
        }

        ModalFunctions.show({
          title: 'ID Verification Required',
          subtitle: 'In order to get paid, you will need to verify your identity.',
          body: 'Please complete the onboarding process to continue.',
          primaryButton: {
            label: 'Okay',
            onClick: () => {
              // Dismiss the modal
            }
          }
        })
      }

      const onIdCheckSuccess = (response: CheckOnboardingInfoSuccessResponse) => {
        if (response.data.value) {
          onSuccess?.()
        } else {
          onIdCheckError()
          onError?.()
        }
      }

      dispatch(
        checkOnboardingInfo({
          request: {
            infoType: OnboardingInfoType.ID_DOCUMENT_UPLOADED
          },

          onSuccess: onIdCheckSuccess
        })
      )
    },
    [dispatch, externalFlightBookingPaymentIntent, stripeConnectAccountCountry]
  )

  const handleSubmitRequestForVirtualCard = useCallback(() => {
    const payload: CreateRequestForVirtualCardRequestPayload = {
      request: {
        flightItineraryId: flightItinerary?.id?.toString()
      }
    }

    dispatch(createRequestForVirtualCard(payload))
  }, [dispatch, flightItinerary])

  useEffect(
    function requestVirtualCardAndLoadSuccessPageAfterPaymentDetailsSetup() {
      if (setupPaymentDetailsSuccess) {
        window.scrollTo(0, 0)
        handleSubmitRequestForVirtualCard()
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleStepClick = (step: number) => {
    if (step !== stepDetails.reviewTripDetails.index) {
      setActiveStep(step)
    } else if (step < stepDetails.setupPaymentDetails.index) {
      dispatch(resetCreateExternalFlightBookingPaymentIntent())
    }
  }

  const handleContinueToPaymentDetails = () => {
    const onSuccess = () => {
      const payload: CreateCustomerSessionRequestPayload = {
        onSuccess: () => {
          setActiveStep(stepDetails.setupPaymentDetails.index)
        }
      }

      dispatch(createCustomerSession(payload))
    }

    handleCheckOnboardingInfo(onSuccess)
  }

  const handleSubmitPaymentDetails = () => {
    paymentDetailsSetupFormRef.current?.submitPayment()
  }

  const renderStepHeader = () => {
    let headerTitle = ''
    let headerSubtitle = ''

    switch (activeStep) {
      case stepDetails.reviewTripDetails.index:
        break

      case stepDetails.setupStripeConnectAccount.index:
        headerTitle = 'Payout Details'
        headerSubtitle = 'Connect a bank account to receive payments.'
        break

      case stepDetails.setupPaymentDetails.index:
        headerTitle = 'Payment Details'
        headerSubtitle = 'We collect payment information just in case you want to Shop with us in the future.'
        break

      case stepDetails.virtualCardRequested.index:
        return null

      default:
        return null
    }

    return (
      <Box sx={styles.stepHeader}>
        <PageTitle title={headerTitle} subtitle={headerSubtitle} />
      </Box>
    )
  }

  const renderStepContent = () => {
    const renderStripeConnectAccountSetupForm = () => {
      if (createExternalFlightBookingPaymentIntentLoading) {
        return (
          <Box sx={styles.stripeConnectAccountSetupFormLoadingContainer}>
            <Box sx={styles.stripeConnectAccountSetupFormLoadingContainerItem}>
              <Skeleton variant="text" height={40} width="90%" />
              <Skeleton variant="rounded" height={60} width="100%" sx={styles.skeletonItem} />
            </Box>

            <Skeleton variant="rounded" height={200} width="100%" sx={styles.skeletonItem} />
          </Box>
        )
      } else if (createExternalFlightBookingPaymentIntentSuccess) {
        const isStripeConnectAccountCountrySelectionFormFieldVisible = isCustomerOnboardedToStripeConnect !== true

        const renderStripeConnectAccountCountrySelectionFormField = () => {
          if (isStripeConnectAccountCountrySelectionFormFieldVisible) {
            return (
              <Box sx={styles.stripeConnectAccountCountrySelectionFormFieldContainer}>
                <Typography variant="body1">
                  Please select the country in which the bank account you'd like to receive payments in is registered.
                </Typography>

                <Box sx={styles.stripeConnectAccountCountrySelectionFormFieldContainerItem}>
                  <Dropdown
                    options={countries.map((country) => ({
                      value: country.code,
                      label: country.name
                    }))}
                    name="country"
                    label="Country"
                    value={stripeConnectAccountCountry ?? ''}
                    setValue={handleCountryChange}
                    placeholder="Select Country"
                    readOnly={!!accountSession?.secret}
                  />

                  <Button
                    text="Select"
                    onClick={handleCreateAccountSession}
                    buttonType="tertiary"
                    disabled={
                      !!accountSession?.secret || createAccountSessionLoading || stripeConnectAccountCountry === null
                    }
                    style={styles.selectButton}
                  />
                </Box>
              </Box>
            )
          }
        }

        const renderStripeConnectOnboardingForm = () => {
          if (createAccountSessionLoading) {
            return (
              <Box sx={styles.stripeConnectAccountSetupFormLoadingContainerFormOnly}>
                <Skeleton variant="rounded" height={250} width="100%" sx={styles.skeletonItem} />
              </Box>
            )
          } else if (accountSession?.secret) {
            return (
              <Box id="stripe-connect-onboarding-form" sx={styles.stripeConnectOnboardingFormContainer}>
                {stripeConnectAccountCountry !== null && <Divider sx={styles.stripeConnectOnboardingFormDivider} />}

                <StripeConnectOnboardingForm
                  accountSessionClientSecret={accountSession?.secret}
                  onOnboardingComplete={() => setIsOnboadingComplete(true)}
                />
              </Box>
            )
          } else if (createAccountSessionError) {
            return (
              <EmptyState
                title="Error"
                subtitle="Failed to load Stripe Connect onboarding form. Please try again."
                errorId={createAccountSessionError.correlationId}
                button={{
                  text: 'Try Again',
                  onClick: handleCreateAccountSession
                }}
                marginTop={3}
              />
            )
          } else {
            return null
          }
        }

        return (
          <Box sx={styles.stripeConnectAccountSetupFormContainer}>
            {renderStripeConnectAccountCountrySelectionFormField()}
            {renderStripeConnectOnboardingForm()}
          </Box>
        )
      } else if (createExternalFlightBookingPaymentIntentError) {
        return (
          <EmptyState
            title="Error"
            subtitle="Failed to load Stripe Connect onboarding form. Please try again."
            errorId={createExternalFlightBookingPaymentIntentError.correlationId}
            button={{
              text: 'Try Again',
              onClick: handleCreateExternalFlightBookingPaymentIntentRequest
            }}
            marginTop={3}
          />
        )
      }
    }

    const renderPaymentDetailsSetupForm = () => {
      return (
        <StripeCheckoutForm
          mode="setup"
          redirectPath={routes.requestVirtualCardForExternalBooking.path}
          paymentIntentClientSecret={customerSessionSuccess?.data.setupIntentClientSecret}
          customerSessionClientSecret={customerSessionSuccess?.data.customerSessionClientSecret}
          setIsPaymentFormValid={setIsPaymentDetailsSetupFormValid}
          setPaymentRequestLoading={setPaymentRequestLoading}
          setSetupPaymentLoading={setPaymentDetailsSetupLoading}
          paymentFormRef={paymentDetailsSetupFormRef}
          currency={env.STRIPE_MAIN_CURRENCY}
          showTitle
        />
      )
    }

    const renderVirtualCardRequestedPage = () => {
      return (
        <PaymentSubmittedSuccessfully
          title="Delivery Booked Successfully"
          description="Time to buy and deliver items. A confirmation email will be sent to you with your virtual card and item delivery details."
          button={{
            text: 'View Virtual Card',
            onClick: () => navigate(routes.requests.path),
            buttonType: 'primary'
          }}
          loadingState={{
            loading: createRequestForVirtualCardLoading,
            text: 'Submitting Your Request'
          }}
          success={createRequestForVirtualCardSuccess !== null}
          errorState={
            createRequestForVirtualCardError
              ? {
                  apiResponseError: createRequestForVirtualCardError,
                  button: {
                    text: 'Try Again',
                    onClick: handleSubmitRequestForVirtualCard
                  }
                }
              : undefined
          }
        />
      )
    }

    switch (activeStep) {
      case stepDetails.reviewTripDetails.index:
        return null

      case stepDetails.setupStripeConnectAccount.index:
        return renderStripeConnectAccountSetupForm()

      case stepDetails.setupPaymentDetails.index:
        return renderPaymentDetailsSetupForm()

      case stepDetails.virtualCardRequested.index:
        return renderVirtualCardRequestedPage()

      default:
        return null
    }
  }

  const handleCountryChange = useCallback((value: string) => {
    setStripeConnectAccountCountry(value)
  }, [])

  const getOverviewButton = (): AccordionButtonProps | undefined => {
    switch (activeStep) {
      case stepDetails.setupStripeConnectAccount.index:
        return {
          text: 'Continue',
          fullWidth: true,
          buttonType: 'primary',
          onClick: handleContinueToPaymentDetails,
          loading: checkOnboardingInfoLoading || createCustomerSessionLoading,
          disabled: !isOnboadingComlpete,
          tooltipText: !isOnboadingComlpete ? 'Please complete the onboarding process.' : undefined
        }

      case stepDetails.setupPaymentDetails.index:
        return {
          fullWidth: true,
          buttonType: 'primary',
          text: 'Submit Information',
          onClick: handleSubmitPaymentDetails,
          loading: paymentDetailsSetupLoading,
          disabled: !isPaymentDetailsSetupFormValid,
          tooltipText: !isPaymentDetailsSetupFormValid
            ? 'Please ensure all required fields are filled correctly.'
            : undefined
        }
    }
  }

  const renderButtons = () => {
    switch (activeStep) {
      case stepDetails.reviewTripDetails.index:
        return null

      case stepDetails.setupStripeConnectAccount.index:
        return (
          <Button
            text="Continue"
            fullWidth
            buttonType="primary"
            onClick={handleContinueToPaymentDetails}
            loading={checkOnboardingInfoLoading || createCustomerSessionLoading}
            disabled={!isOnboadingComlpete}
            tooltipText={!isOnboadingComlpete ? 'Please complete the onboarding process.' : undefined}
          />
        )

      case stepDetails.setupPaymentDetails.index:
        return (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            buttonType="primary"
            text="Submit Information"
            onClick={handleSubmitPaymentDetails}
            loading={paymentDetailsSetupLoading}
            disabled={!isPaymentDetailsSetupFormValid}
            tooltipText={
              !isPaymentDetailsSetupFormValid ? 'Please ensure all required fields are filled correctly.' : ''
            }
          />
        )

      case stepDetails.virtualCardRequested.index:
        return null

      default:
        return null
    }
  }

  const isOverviewSectionVisible =
    activeStep === stepDetails.setupStripeConnectAccount.index || activeStep === stepDetails.setupPaymentDetails.index

  return (
    <Stepper stepperWidth="70%" activeStep={activeStep} steps={steps} showStepper onStepClick={handleStepClick}>
      {renderStepHeader()}

      <Grid container spacing={isOverviewSectionVisible ? 4 : 0} sx={styles.gridContainer}>
        <Grid item xs={12} md={isOverviewSectionVisible ? 9 : 12}>
          <Box sx={styles.contentContainer} id="step-content-container">
            {renderStepContent()}
          </Box>
        </Grid>

        {isOverviewSectionVisible && (
          <Grid item xs={12} sm={3}>
            <Box sx={styles.tripOverviewContainer}>
              <TripOverview
                itinerary={flightItinerary.itinerary}
                currency={externalFlightBookingPaymentIntent?.data?.currency}
                discount={externalFlightBookingPaymentIntent?.data?.discount}
                tax={externalFlightBookingPaymentIntent?.data?.tax}
                fees={externalFlightBookingPaymentIntent?.data?.fees}
                subtotal={externalFlightBookingPaymentIntent?.data?.subtotal}
                total={externalFlightBookingPaymentIntent?.data?.total}
                overviewAccordionButton={getOverviewButton()}
                isExternalBooking={true}
              />
            </Box>

            {isDesktop && renderButtons()}
          </Grid>
        )}
      </Grid>
    </Stepper>
  )
}

export default RequestVirtualCardForExternalBooking
