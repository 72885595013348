import { Step } from '../../shared/components/Stepper/Stepper.types'

export const CREATE_EXTERNAL_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY = 'CREATE_EXTERNAL_FLIGHT_BOOKING_PAYMENT_INTENT'
export const ACCOUNT_SESSION_STORAGE_KEY = 'ACCOUNT_SESSION'
export const FLIGHT_ITINERARY_STORAGE_KEY = 'FLIGHT_ITINERARY'

export const steps: Step[] = [
  { label: 'Review Trip Details' },
  { label: 'Payout Details' },
  { label: 'Payment Details' },
  { label: 'Virtual Card Requested' }
]

export const stepDetails = {
  reviewTripDetails: {
    headerTitle: steps[0].label,
    headerSubtitle: 'Review the details of your trip.',
    step: steps[0],
    index: 0
  },
  setupStripeConnectAccount: {
    headerTitle: steps[1].label,
    headerSubtitle: 'Set up a payout that can be used to pay for your trips.',
    step: steps[1],
    index: 1
  },
  setupPaymentDetails: {
    headerTitle: steps[2].label,
    headerSubtitle: 'Set up a card that can be used to pay for your trips.',
    step: steps[2],
    index: 2
  },
  virtualCardRequested: {
    headerTitle: steps[3].label,
    headerSubtitle: 'Your virtual card is being requested.',
    step: steps[3],
    index: 3
  }
}
