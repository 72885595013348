import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    flexGrow: 1,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  } as SxProps,

  contentContainer: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'center'
  } as SxProps,

  tripOverviewContainer: {} as SxProps,

  stepHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },

  title: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold
  } as CSSProperties,

  subtitle: {
    textAlign: 'left',
    color: theme.palette.text.secondary
  },

  stripeConnectAccountSetupFormContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  } as SxProps,

  stripeConnectAccountSetupFormLoadingContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  } as SxProps,

  stripeConnectAccountSetupFormLoadingContainerFormOnly: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 3
  } as SxProps,

  stripeConnectAccountSetupFormLoadingContainerItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  } as SxProps,

  stripeConnectOnboardingFormContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100vh'
  } as SxProps,

  stripeConnectOnboardingFormDivider: {
    width: '100%',
    marginTop: 3,
    marginBottom: 3
  } as SxProps,

  stripeConnectAccountCountrySelectionFormFieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  } as SxProps,

  stripeConnectAccountCountrySelectionFormFieldContainerItem: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2
  } as SxProps,

  selectButton: {
    width: '100px',
    height: '55px'
  } as CSSProperties,

  skeletonItem: {
    borderRadius: theme.spacing(2)
  } as SxProps
}

export default styles
