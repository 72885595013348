import { CabinClass, CabinClassDisplayName, TripType, TripTypeDisplayName, tripTypeMapping } from './flights.types'
import { cabinClassMapping } from './flights.types'

/**
 * Get the cabin classes
 * @returns The cabin classes
 */
export const getCabinClasses = (): { value: CabinClass; label: CabinClassDisplayName }[] => {
  const cabinClasses = Object.keys(cabinClassMapping).map((key) => ({
    value: key as CabinClass,
    label: cabinClassMapping[key as CabinClass]
  }))

  return cabinClasses
}

/**
 * Get the formatted cabin class
 * @param cabinClass - The cabin class to format
 * @returns The formatted cabin class
 */
export const getFormattedCabinClass = (cabinClass: CabinClass): CabinClassDisplayName => {
  return cabinClassMapping[cabinClass]
}

/**
 * Get the trip types
 * @returns The trip types
 */
export const getTripTypes = (): { value: TripType; label: TripTypeDisplayName }[] => {
  const tripTypes = Object.keys(tripTypeMapping).map((key) => ({
    value: key as TripType,
    label: tripTypeMapping[key as TripType]
  }))

  return tripTypes
}

/**
 * Get the formatted trip type
 * @param tripType - The trip type to format
 * @returns The formatted trip type
 */
export const getFormattedTripType = (tripType: TripType): TripTypeDisplayName => {
  return tripTypeMapping[tripType]
}
