import { useCallback, useEffect, useState } from 'react'

import { TripType } from '@/shared/functions/Flights/flights.types'
import { AttachMoney, FlightTakeoffOutlined, ShareOutlined } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import EmptyState from '../../../../shared/components/EmptyState/EmptyState.component'
import FlightItineraryHeader from '../../../../shared/components/FlightItineraryHeader/FlightItineraryHeader.component'
import ShareFlightItineraryLinkModal from '../../../../shared/components/ShareFlightItineraryLinkModal/ShareFlightItineraryLinkModal.component'
import ItemRequestAccordion from '../ItemRequestAccordion/ItemRequestAccordion.component'
import VirtualCardModal from './components/VirtualCardModal/VirtualCardModal.component'

import {
  FlightItinerary,
  Leg
} from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { getFlightItineraryShareLink } from '../../../../redux/states/flights/getFlightItineraryShareLink/getFlightItineraryShareLink.slice'
import { GetFlightItineraryShareLinkRequest } from '../../../../redux/states/flights/getFlightItineraryShareLink/getFlightItineraryShareLink.types'
import { getUpdatedFlightOffer } from '../../../../redux/states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.slice'
import { GetUpdatedFlightOfferRequestPayload } from '../../../../redux/states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.types'
import { getAssignedVirtualCardDetails } from '../../../../redux/states/payments/getAssignedVirtualCardDetails/getAssignedVirtualCardDetails.slice'
import {
  GetAssignedVirtualCardDetailsRequestPayload,
  UserAssignedVirtualCardDetails
} from '../../../../redux/states/payments/getAssignedVirtualCardDetails/getAssignedVirtualCardDetails.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { ButtonProps } from '../../../../shared/components/Button/Button.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../../../shared/functions/Status/status.dictionaries'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import useResponsiveness from '../../../../shared/hooks/responsive.hooks'
import styles from './FlightItineraryAccordion.styles'
import virtualCardModalDictionary from './components/VirtualCardModal/VirtualCardModal.dictionary'

const FlightItineraryAccordion = (props: PaginatedRenderProps) => {
  const flightItinerary = props.item as FlightItinerary
  const refreshData = props.refreshData
  const index = props.index
  const listLength = props.listLength

  const [updatedFlightItinerary, setUpdatedFlightItinerary] = useState<FlightItinerary>(flightItinerary)
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState<string | null>(null)
  const [loadingVirtualCardDetails, setLoadingVirtualCardDetails] = useState(false)
  const [virtualCardDetails, setVirtualCardDetails] = useState<UserAssignedVirtualCardDetails | null>(null)
  const [isFirstAccordionExpanded, setIsFirstAccordionExpanded] = useState(index === 0 && listLength === 1)
  const [showVirtualCardModal, setShowVirtualCardModal] = useState(false)
  const [isDesktop] = useResponsiveness()

  const { success: getFlightItineraryShareLinkSuccess, loading: getFlightItineraryShareLinkLoading } = useAppSelector(
    (state: RootState) => state.getFlightItineraryShareLink
  )

  const { loading: getUpdatedFlightOfferLoading, error: getUpdatedFlightOfferError } = useAppSelector(
    (state: RootState) => state.getUpdatedFlightOffer
  )

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    if (index === 0 && listLength !== 1) {
      setIsFirstAccordionExpanded(false)
    }
  }, [index, listLength])

  useEffect(() => {
    setUpdatedFlightItinerary(flightItinerary)
  }, [flightItinerary])

  useEffect(() => {
    if (!getUpdatedFlightOfferLoading && getUpdatedFlightOfferError) {
      setLoadingButton(null)
    }
  }, [getUpdatedFlightOfferLoading, getUpdatedFlightOfferError])

  const refreshFlightItineraries = (onSuccess?: (data?: unknown) => void) => {
    refreshData?.(onSuccess)
  }

  const getShareLink = () => {
    setLoadingButton(updatedFlightItinerary.id.toString())

    const request: GetFlightItineraryShareLinkRequest = {
      id: updatedFlightItinerary.id.toString()
    }

    const onSuccess = () => {
      setShareLinkModalOpen(true)
      setLoadingButton(null)
    }

    dispatch(getFlightItineraryShareLink({ request, onSuccess }))
  }

  const hasOfferRequestDetails = updatedFlightItinerary.itinerary.legs.some(
    (leg) => leg.offerRequestsDetails && leg.offerRequestsDetails.length > 0
  )

  const onBookFlight = useCallback(() => {
    setLoadingButton(updatedFlightItinerary.id.toString())

    const payload: GetUpdatedFlightOfferRequestPayload = {
      request: {
        id: updatedFlightItinerary.id.toString()
      },

      onSuccess: (success) => {
        if (success.data.offer) {
          navigate(routes.tripSummary.path, {
            state: { fare: success.data.offer, isBookingFlight: true, itinerary: updatedFlightItinerary }
          })
        }
      },

      onError: () => {
        Modal.show({
          title: 'Offer Expired',
          subtitle: 'We were unable to find a new offer for your flight. Please search for a new offer manually.',
          primaryButton: {
            label: 'Search for new offer',
            onClick: () => {
              navigate(routes.flightSearchResults.path, {
                state: {
                  existingFlightItinerary: updatedFlightItinerary,
                  isBookingFlight: true
                }
              })
            }
          },
          secondaryButton: {
            label: 'Dismiss'
          }
        })

        setLoadingButton(null)
      }
    }

    dispatch(getUpdatedFlightOffer(payload))
  }, [updatedFlightItinerary, dispatch, navigate])

  const isExternalBooking = updatedFlightItinerary.itinerary.itinerarySource === 'external'

  const onGetPaid = () => {
    navigate(routes.tripSummary.path, {
      state: {
        isExternalBooking: true,
        itinerary: updatedFlightItinerary
      }
    })
  }

  const getAccordionHeader = () => {
    const isBookFlightDisabled = updatedFlightItinerary.itinerary.legs.some((leg) =>
      leg.offerRequestsDetails.some((offerRequest) =>
        offerRequest.itemRequestDetails.some(
          (detail) =>
            detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler ||
            detail.status.name === status.itemRequestStatus.reassignedByShopper
        )
      )
    )

    const itineraryHasValidOffers = updatedFlightItinerary.itinerary.legs.some((leg) =>
      leg.offerRequestsDetails.some(
        (offerRequest) =>
          offerRequest.itemRequestDetails.length > 0 &&
          offerRequest.itemRequestDetails.some(
            (detail) =>
              detail.status.name !== status.itemRequestStatus.cancelledByShopper &&
              detail.status.name !== status.itemRequestStatus.deniedByTraveler
          )
      )
    )

    const travelerHasRequestedVirtualCard =
      updatedFlightItinerary.itinerary.virtualCardDetails?.status.name ===
      status.virtualCardStatus.virtualCardRequestCreated

    const travelerHasVirtualCard =
      updatedFlightItinerary.itinerary.virtualCardDetails?.status.name === status.virtualCardStatus.virtualCardAssigned

    const isBookFlightButtonVisible =
      !isExternalBooking &&
      updatedFlightItinerary.status.name !== status.flightStatus.flightBooked &&
      updatedFlightItinerary.status.name !== status.flightStatus.flightBookingCancelledBeforePayment &&
      updatedFlightItinerary.status.name !== status.flightStatus.flightCancelledAfterPayment

    const isViewVirtualCardButtonVisible = travelerHasRequestedVirtualCard || travelerHasVirtualCard

    const accordionButtons: ButtonProps[] = []

    const shareLinkButton: ButtonProps = {
      text: isDesktop ? 'Share Link' : undefined,
      onClick: getShareLink,
      buttonType: 'secondary',
      loading: loadingButton === updatedFlightItinerary.id.toString() && getFlightItineraryShareLinkLoading,
      icon: <ShareOutlined />,
      iconPosition: isDesktop ? 'start' : 'center'
    }

    const virtualCardButton: ButtonProps = {
      text: isDesktop ? 'View Virtual Card' : 'Virtual Card',
      buttonType: 'primary',
      tooltipText: !travelerHasVirtualCard
        ? virtualCardModalDictionary.virtualCardProcessing
        : 'View your virtual card.',
      disabled: travelerHasRequestedVirtualCard && !travelerHasVirtualCard,
      loading: loadingVirtualCardDetails,
      onClick: () => {
        if (updatedFlightItinerary.itinerary.virtualCardDetails?.id) {
          const payload: GetAssignedVirtualCardDetailsRequestPayload = {
            request: { assignedVirtualCardId: updatedFlightItinerary.itinerary.virtualCardDetails?.id },
            onSuccess: (data) => {
              setVirtualCardDetails(data.cardDetails)
              setLoadingVirtualCardDetails(false)
              setShowVirtualCardModal(true)
            },
            onError: () => {
              setLoadingVirtualCardDetails(false)
            }
          }

          setLoadingVirtualCardDetails(true)
          dispatch(getAssignedVirtualCardDetails(payload))
        }
      }
    }

    if (isExternalBooking) {
      const isGetPaidButtonVisible =
        isExternalBooking &&
        updatedFlightItinerary.status.name !== status.flightStatus.flightBooked &&
        !travelerHasRequestedVirtualCard &&
        !travelerHasVirtualCard &&
        itineraryHasValidOffers

      const isShareLinkButtonVisible = !isViewVirtualCardButtonVisible

      if (isShareLinkButtonVisible) {
        accordionButtons.push(shareLinkButton)
      }

      if (isGetPaidButtonVisible) {
        accordionButtons.push({
          text: isDesktop ? 'Get Paid' : undefined,
          onClick: onGetPaid,
          buttonType: 'primary',
          icon: !isDesktop ? <AttachMoney /> : undefined,
          iconPosition: isDesktop ? 'start' : 'center',
          disabled: isBookFlightDisabled,
          tooltipText: isDesktop
            ? isBookFlightDisabled
              ? 'Must action all item requests before you can add payout information and receive a Virtual Card to buy items.'
              : 'Must add payout information and receive a Virtual Card before you can buy items on Amazon.'
            : undefined
        })
      }

      if (isViewVirtualCardButtonVisible) {
        accordionButtons.push(virtualCardButton)
      }
    } else if (isBookFlightButtonVisible) {
      accordionButtons.push(
        { ...shareLinkButton },
        {
          text: isDesktop ? 'Book Flight' : undefined,
          onClick: onBookFlight,
          icon: !isDesktop ? <FlightTakeoffOutlined /> : undefined,
          iconPosition: isDesktop ? 'start' : 'center',
          buttonType: 'primary',
          disabled: isBookFlightDisabled,
          loading: loadingButton === updatedFlightItinerary.id.toString() && getUpdatedFlightOfferLoading,
          tooltipText: isDesktop
            ? isBookFlightDisabled
              ? 'Must action all item requests before you can book the flight.'
              : 'Must book flight before you can buy the items on Amazon.'
            : undefined
        }
      )
    } else if (
      updatedFlightItinerary.status.name === status.flightStatus.flightBookingCancelledBeforePayment ||
      updatedFlightItinerary.status.name === status.flightStatus.flightCancelledAfterPayment
    ) {
      accordionButtons.push({
        text: 'Trip Canceled',
        buttonType: 'error',
        tooltipText: 'This trip has been canceled.'
      })
    } else if (isViewVirtualCardButtonVisible) {
      accordionButtons.push(virtualCardButton)
    }

    const accordionHeader = {
      mainContent: <FlightItineraryHeader flightItinerary={updatedFlightItinerary} />,
      buttons: accordionButtons,
      buttonsWidth: !isDesktop ? 'auto' : undefined,
      style: {
        paddingBottom: !isDesktop ? '0px' : undefined
      }
    }

    return accordionHeader
  }

  const renderFlightLegHeader = (leg: Leg) => (
    <Box key={leg.id} sx={styles.legHeader} my={2}>
      <Typography sx={styles.legHeaderTitle}>
        {`${leg.origin.airport.iataCode} → ${leg.destination.airport.iataCode}`}
      </Typography>
    </Box>
  )

  const renderFlightLegDetails = (leg: Leg) => (
    <Box key={leg.id} sx={styles.legDetails}>
      {renderFlightLegHeader(leg)}

      <ItemRequestAccordion
        flightLeg={leg}
        flightItineraryStatus={updatedFlightItinerary.status.name}
        refreshFlightItineraries={refreshFlightItineraries}
        virtualCardDetails={updatedFlightItinerary.itinerary.virtualCardDetails}
        isExternalBooking={isExternalBooking}
        traveler={updatedFlightItinerary.traveler}
      />

      {updatedFlightItinerary.itinerary.legs.indexOf(leg) !== updatedFlightItinerary.itinerary.legs.length - 1 && (
        <Divider sx={styles.divider} />
      )}
    </Box>
  )

  const renderDetails = () => {
    if (!hasOfferRequestDetails) {
      if (updatedFlightItinerary.status.name === status.flightStatus.flightBooked) {
        return (
          <Box sx={styles.emptyStateContainer}>
            <EmptyState
              title={'Flight Booked'}
              subtitle={'Your flight has been booked. You can now view your trip details.'}
              noIcon
              button={{
                text: 'View Trip Details',
                onClick: () => navigate(routes.myTrips.path)
              }}
            />
          </Box>
        )
      } else {
        return (
          <Box sx={styles.emptyStateContainer}>
            <EmptyState
              title={'No Offers Available'}
              subtitle={
                'Share your flight itinerary to your friends and receive offers and earn discounts towards your flight.'
              }
              button={{
                text: 'Share Link',
                icon: <ShareOutlined />,
                loading: loadingButton === updatedFlightItinerary.id.toString() && getFlightItineraryShareLinkLoading,
                onClick: getShareLink
              }}
            />
          </Box>
        )
      }
    } else {
      return (
        <Box sx={styles.flightLegDetailsContainer}>
          {updatedFlightItinerary.itinerary.legs.map(renderFlightLegDetails)}
        </Box>
      )
    }
  }

  return (
    <>
      <Accordion
        separateHeader
        header={getAccordionHeader()}
        body={renderDetails()}
        isExpanded={isFirstAccordionExpanded}
      />

      <VirtualCardModal
        loadingVirtualCardDetails={loadingVirtualCardDetails}
        virtualCardDetails={virtualCardDetails}
        showVirtualCardModal={showVirtualCardModal}
        setShowVirtualCardModal={setShowVirtualCardModal}
        assignedVirtualCardId={updatedFlightItinerary.itinerary.virtualCardDetails?.id?.toString()}
      />

      {updatedFlightItinerary && (
        <ShareFlightItineraryLinkModal
          shareLink={getFlightItineraryShareLinkSuccess?.data?.shareLink ?? ''}
          travelerFirstName={updatedFlightItinerary.traveler.firstName}
          destinationCountries={updatedFlightItinerary.itinerary.legs.map((leg) => ({
            name: leg.destination.country.name,
            iataCode: leg.destination.country.iataCode
          }))}
          originCountries={updatedFlightItinerary.itinerary.legs.map((leg) => ({
            name: leg.origin.country.name,
            iataCode: leg.origin.country.iataCode
          }))}
          tripType={updatedFlightItinerary.itinerary.tripType as TripType}
          open={shareLinkModalOpen}
          onClose={() => setShareLinkModalOpen(false)}
        />
      )}
    </>
  )
}

export default FlightItineraryAccordion
