import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getAllUsersForAdminApi } from './getAllUsersForAdmin.api'
import getAllUsersForAdminInitialState from './getAllUsersForAdmin.initialState'
import { GetAllUsersForAdminErrorResponse, GetAllUsersForAdminRequestPayload } from './getAllUsersForAdmin.types'

export const getAllUsersForAdmin = createAsyncThunk(
  urls.admin.getAllUsersForAdmin,
  async (payload: GetAllUsersForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAllUsersForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAllUsersForAdminSlice = createSlice({
  name: urls.admin.getAllUsersForAdmin,
  initialState: getAllUsersForAdminInitialState,
  reducers: {
    resetGetAllUsersForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }

    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAllUsersForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAllUsersForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAllUsersForAdminErrorResponse
        state.success = null
      })
  }
})

export const { actions: getAllUsersForAdminActions, reducer: getAllUsersForAdminReducer } = getAllUsersForAdminSlice
export const { resetGetAllUsersForAdmin } = getAllUsersForAdminActions
export default getAllUsersForAdminSlice.reducer
