import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getAllExternalFlightBookingRequestsForAdminApi } from './getAllExternalFlightBookingRequestsForAdmin.api'
import getAllExternalFlightBookingRequestsForAdminInitialState from './getAllExternalFlightBookingRequestsForAdmin.initialState'
import {
  GetAllExternalFlightBookingRequestsForAdminErrorResponse,
  GetAllExternalFlightBookingRequestsForAdminRequestPayload
} from './getAllExternalFlightBookingRequestsForAdmin.types'

export const getAllExternalFlightBookingRequestsForAdmin = createAsyncThunk(
  urls.admin.getAllExternalFlightBookingRequestsForAdmin,
  async (payload: GetAllExternalFlightBookingRequestsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAllExternalFlightBookingRequestsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAllExternalFlightBookingRequestsForAdminSlice = createSlice({
  name: urls.admin.getAllExternalFlightBookingRequestsForAdmin,
  initialState: getAllExternalFlightBookingRequestsForAdminInitialState,
  reducers: {
    resetGetAllExternalFlightBookingRequestsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllExternalFlightBookingRequestsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAllExternalFlightBookingRequestsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAllExternalFlightBookingRequestsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAllExternalFlightBookingRequestsForAdminErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: getAllExternalFlightBookingRequestsForAdminActions,
  reducer: getAllExternalFlightBookingRequestsForAdminReducer
} = getAllExternalFlightBookingRequestsForAdminSlice
export const { resetGetAllExternalFlightBookingRequestsForAdmin } = getAllExternalFlightBookingRequestsForAdminActions
export default getAllExternalFlightBookingRequestsForAdminSlice.reducer
