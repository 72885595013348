import React from 'react'

import { Box } from '@mui/material'

import { markdownRegexRules } from './MarkdownRenderer.regex'
import styles from './MarkdownRenderer.styles'
import './MarkdownRenderer.styles.css'
import { MarkdownRendererProps } from './MarkdownRenderer.types'

const MarkdownRenderer: React.FC<MarkdownRendererProps> = (props) => {
  const { content } = props

  let renderedContent = content

  markdownRegexRules.forEach(({ regex, replacer }) => {
    renderedContent = renderedContent.replace(regex, replacer)
  })

  return (
    <Box sx={styles.container}>
      <Box dangerouslySetInnerHTML={{ __html: renderedContent }} />
    </Box>
  )
}

export default MarkdownRenderer
