const styles = {
  container: {},

  slice: {
    mb: 4,
    p: 2,
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 1
  },

  sliceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1
  },

  travelDatePicker: {
    mb: 2,
    width: '100%'
  },

  segment: {
    mb: 2,
    p: 2,
    bgcolor: 'background.paper',
    borderRadius: 1
  },

  segmentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2
  },

  segmentFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 2
  },

  datePicker: {
    width: '100%'
  }
}

export default styles
