import React from 'react'

import { Box, Chip, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'

import EmptyState from '../../../../../../../../../../shared/components/EmptyState/EmptyState.component'
import Link from '../../../../../../../../../../shared/components/Link/Link.component'
import Table from '../../../../../../../../../../shared/components/Table/Table.component'
import PaymentDetailsCard from '../../../OrderDetails/components/PaymentDetailsCard/PaymentDetailsCard.component'

import env from '../../../../../../../../../../networkRequests/apiClient/env.config'
import { RootState } from '../../../../../../../../../../redux/store/store.types'
import date, { dateTimeFormats } from '../../../../../../../../../../shared/functions/Date/date.functions'
import { getStatusColor } from '../../../../../../../../../../shared/functions/Status/status.functions'
import {
  formatMoney,
  formatTruncatedCardNumber
} from '../../../../../../../../../../shared/functions/String/string.functions'
import linkStyles from '../../../../../../../../../../shared/styles/link.styles'
import styles from './PaymentDetailsCard.styles'
import { PaymentDetailsCardProps } from './PaymentDetailsCard.types'

const TripPaymentDetailsCard: React.FC<PaymentDetailsCardProps> = () => {
  const { loading: paymentDetailsLoading, success: paymentDetailsSuccess } = useSelector(
    (state: RootState) => state.getTripPaymentDetailsForAdmin
  )

  if (paymentDetailsLoading) {
    return (
      <Box sx={styles.skeletonContainer}>
        <Skeleton variant="text" width="40%" height={40} />
        <Skeleton variant="rectangular" height={270} sx={styles.skeletonItem} />
        <Skeleton variant="text" width="40%" height={40} />
        <Skeleton variant="rectangular" height={150} sx={styles.skeletonItem} />
      </Box>
    )
  } else if (paymentDetailsSuccess) {
    const { payment, transfers, itemsCost, travelerBenefit, flightCost, virtualCard } = paymentDetailsSuccess.data

    const getPaymentSummary = () => {
      if (payment !== null) {
        return <PaymentDetailsCard paymentDetails={payment} service="trip" />
      } else if (payment === null && transfers) {
        const currency = transfers[0]?.currency ?? env.STRIPE_MAIN_CURRENCY
        const totalReceived = transfers?.reduce((acc, transfer) => acc + transfer.amount, 0)

        return (
          <Table
            title="Payment Details Summary"
            columns={[
              { key: 'key', label: 'Key' },
              { key: 'value', label: 'Data' }
            ]}
            data={[
              { key: 'Flight Cost', value: formatMoney(flightCost, currency) },
              { key: 'Traveler Benefit', value: formatMoney(travelerBenefit, currency) },
              {
                key: 'Total Received',
                value: totalReceived === 0 ? 'N/A' : formatMoney(totalReceived, currency)
              },
              { key: 'Amazon Credit', value: formatMoney(itemsCost, currency) }
            ]}
          />
        )
      }
    }

    const renderTransferDetails = () => {
      return (
        <Table
          title="Transfers"
          columns={[
            { key: 'amount', label: 'Amount' },
            { key: 'status', label: 'Status' },
            { key: 'date', label: 'Date' },
            { key: 'destination', label: 'Destination' }
          ]}
          data={
            transfers
              ? transfers.map((transfer, index) => ({
                  amount: formatMoney(transfer.amount, transfer.currency),
                  status: <Chip label={transfer.status.displayName} color={getStatusColor(transfer.status.name)} />,
                  date: date(transfer.createdAt).format(dateTimeFormats.date.medium),
                  destination: (
                    <Link href={transfer.transferUrl} sx={linkStyles}>
                      {transfer.destination}
                    </Link>
                  ),
                  key: transfer.destination || `transfer-${index}`
                }))
              : []
          }
        />
      )
    }

    const renderVirtualCardDetails = () => {
      return (
        <Table
          title="Virtual Card Details"
          columns={[
            { key: 'cardNumber', label: 'Card Number' },
            { key: 'expirationDate', label: 'Expiration Date' },
            { key: 'spendingLimit', label: 'Spending Limit' },
            { key: 'status', label: 'Status' }
          ]}
          data={
            virtualCard
              ? [
                  {
                    cardNumber: formatTruncatedCardNumber(virtualCard.truncatedCardNumber),
                    expirationDate: virtualCard.expirationDate
                      ? date(virtualCard.expirationDate).format(dateTimeFormats.date.medium)
                      : '',
                    spendingLimit: formatMoney(virtualCard.spendingLimit, virtualCard.orderCurrency),
                    status: (
                      <Chip label={virtualCard.status.displayName} color={getStatusColor(virtualCard.status.name)} />
                    )
                  }
                ]
              : []
          }
        />
      )
    }

    return (
      <Box sx={styles.container}>
        {renderVirtualCardDetails()}
        {getPaymentSummary()}
        {renderTransferDetails()}
      </Box>
    )
  } else {
    return <EmptyState title="No payment details found" />
  }
}

export default TripPaymentDetailsCard
