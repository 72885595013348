import { CSSProperties } from 'react'

import theme from '@/shared/styles/themes/default.theme'
import { SxProps } from '@mui/material'

const styles = {
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    width: '100%',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  } as SxProps,

  shareLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
    maxHeight: 'calc(100vh - 200px)',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset'
    }
  } as SxProps,

  shareLinkTop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      gap: theme.spacing(2)
    }
  } as SxProps,

  shareLinkModalImage: {
    height: '200px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      height: '120px'
    }
  } as SxProps,

  shareLinkModalPrimaryText: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center'
  } as SxProps,

  shareLinkModalSecondaryText: {
    fontWeight: 500,
    textAlign: 'center'
  } as SxProps,

  shareLinkModalTertiaryText: {
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  } as SxProps,

  linkText: {
    fontWeight: 600,
    color: 'primary.main'
  } as CSSProperties,

  shareViaSocialMediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    width: '100%',
    textAlign: 'center'
  } as SxProps,

  shareViaSocialMedia: {
    textAlign: 'center',
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  socialIconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: theme.spacing(1),
    margin: theme.spacing(2, 0),
    width: '100%'
  } as SxProps,

  socialIconButton: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0),
    width: '48px',
    height: '48px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '36px',
      height: '36px'
    }
  },

  socialMediaIcon: {
    width: '100%',
    height: '100%'
  } as CSSProperties,

  copyLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  } as SxProps,

  copyLinkInstructionsText: {
    textAlign: 'center',
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  copyLinkContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius / 4,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingX: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
      gap: 0
    }
  } as SxProps,

  copyLinkText: {
    color: theme.palette.grey[500],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '70%',
    border: 'none',
    boxShadow: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none'
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  } as SxProps
}

export default styles
