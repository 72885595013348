const styles = {
  container: {
    height: '100%',
    overflowY: 'auto'
  },

  skeletonCard: {
    marginBottom: 3,
    borderRadius: 2
  },

  tripOverviewTableContainer: {
    marginBottom: 4
  }
}

export default styles
