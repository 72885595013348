import { CSSProperties } from 'react'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  emptyStateContainer: {
    padding: theme.spacing(1, 0)
  } as CSSProperties,

  accordionHeaderMainContent: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: theme.spacing(2)
    }
  } as CSSProperties,

  shopperDetailsContainer: {
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    },
    width: '100%'
  } as CSSProperties,

  shopperDetailsTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  } as CSSProperties,

  shopperDetailsName: {
    fontWeight: 'bold',
    fontSize: '0.9rem'
  } as CSSProperties,

  arrivalDateContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1)
  } as CSSProperties,

  shopperDetailsTag: {
    fontSize: '0.8rem',
    color: 'text.secondary'
  } as CSSProperties,

  itemRequestsContainer: {
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  } as CSSProperties,

  shopperDetailsWrapper: {
    display: 'flex',
    alignItems: 'center'
  } as CSSProperties
}

export default styles
