import React, { useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

import Table from '../../../../../../shared/components/Table/Table.component'
import Tabs from '../../../../../../shared/components/Tabs/Tabs.component'

import env from '../../../../../../networkRequests/apiClient/env.config'
import { getAllItemRequestsForAdmin } from '../../../../../../redux/states/admin/orders/getAllItemRequestsForAdmin/getAllItemRequestsForAdmin.slice'
import { GetAllItemRequestsForAdminRequestPayload } from '../../../../../../redux/states/admin/orders/getAllItemRequestsForAdmin/getAllItemRequestsForAdmin.types'
import { getAllUserOfferRequestsForAdmin } from '../../../../../../redux/states/admin/orders/getAllUserOfferRequestsForAdmin/getAllUserOfferRequestsForAdmin.slice'
import {
  GetAllUserOfferRequestsForAdminOfferRequest,
  GetAllUserOfferRequestsForAdminRequestPayload
} from '../../../../../../redux/states/admin/orders/getAllUserOfferRequestsForAdmin/getAllUserOfferRequestsForAdmin.types'
import { getOfferRequestPaymentDetailsForAdmin } from '../../../../../../redux/states/admin/orders/getOfferRequestPaymentDetailsForAdmin/getOfferRequestPaymentDetailsForAdmin.slice'
import { GetOfferRequestPaymentDetailsForAdminRequestPayload } from '../../../../../../redux/states/admin/orders/getOfferRequestPaymentDetailsForAdmin/getOfferRequestPaymentDetailsForAdmin.types'
import { getOfferRequestTripDetailsForAdmin } from '../../../../../../redux/states/admin/orders/getOfferRequestTripDetailsForAdmin/getOfferRequestTripDetailsForAdmin.slice'
import { GetOfferRequestTripDetailsForAdminRequestPayload } from '../../../../../../redux/states/admin/orders/getOfferRequestTripDetailsForAdmin/getOfferRequestTripDetailsForAdmin.types'
import { getTripDetailsForAdmin } from '../../../../../../redux/states/admin/trips/getTripDetailsForAdmin/getTripDetailsForAdmin.slice'
import { GetTripDetailsForAdminRequestPayload } from '../../../../../../redux/states/admin/trips/getTripDetailsForAdmin/getTripDetailsForAdmin.types'
import { getTripOfferRequestsForAdmin } from '../../../../../../redux/states/admin/trips/getTripOfferRequestsForAdmin/getTripOfferRequestsForAdmin.slice'
import { GetTripOfferRequestsForAdminRequestPayload } from '../../../../../../redux/states/admin/trips/getTripOfferRequestsForAdmin/getTripOfferRequestsForAdmin.types'
import { getTripPaymentDetailsForAdmin } from '../../../../../../redux/states/admin/trips/getTripPaymentDetailsForAdmin/getTripPaymentDetailsForAdmin.slice'
import { GetTripPaymentDetailsForAdminRequestPayload } from '../../../../../../redux/states/admin/trips/getTripPaymentDetailsForAdmin/getTripPaymentDetailsForAdmin.types'
import { getUserTripsForAdmin } from '../../../../../../redux/states/admin/trips/getUserTripsForAdmin/getUserTripsForAdmin.slice'
import {
  GetUserTripsForAdminRequestPayload,
  GetUserTripsForAdminTrip
} from '../../../../../../redux/states/admin/trips/getUserTripsForAdmin/getUserTripsForAdmin.types'
import { RootState } from '../../../../../../redux/store/store.types'
import { useAppDispatch } from '../../../../../../shared/hooks/redux.hooks'
import { adminNavigationPaths } from '../../../../AdminDashboard.dictionary'
import { orderTableColumns, tripTableColumns } from './UserDetails.dictionary'
import styles from './UserDetails.styles'
import { UserDetailsProps } from './UserDetails.types'

const UserDetails: React.FC<UserDetailsProps> = (props) => {
  const { userId, searchQuery, demoRouter, setUserDetailsTabIndex, userDetailsTabIndex } = props
  const {
    success: ordersSuccess,
    loading: ordersLoading,
    error: ordersError
  } = useSelector((state: RootState) => state.getAllUserOfferRequestsForAdmin)

  const {
    success: tripsSuccess,
    loading: tripsLoading,
    error: tripsError
  } = useSelector((state: RootState) => state.getUserTripsForAdmin)

  const [ordersPage, setOrdersPage] = useState(1)
  const [tripsPage, setTripsPage] = useState(1)
  const [ordersData, setOrdersData] = useState<GetAllUserOfferRequestsForAdminOfferRequest[]>([])
  const [tripsData, setTripsData] = useState<GetUserTripsForAdminTrip[]>([])
  const [ordersTotalPages, setOrdersTotalPages] = useState(1)
  const [ordersTotalRecords, setOrdersTotalRecords] = useState(0)
  const [tripsTotalPages, setTripsTotalPages] = useState(1)
  const [tripsTotalRecords, setTripsTotalRecords] = useState(0)

  const pageSize = env.DEFAULT_PAGE_SIZE

  const dispatch = useAppDispatch()

  const handleGetAllUserOfferRequests = useCallback(
    (page: number = 1, size: number = pageSize) => {
      const offerRequestSearchQuery = userDetailsTabIndex === 0 ? searchQuery : undefined

      const payload: GetAllUserOfferRequestsForAdminRequestPayload = {
        request: { userId, page, size, searchQuery: offerRequestSearchQuery }
      }

      dispatch(getAllUserOfferRequestsForAdmin(payload))
    },
    [dispatch, userId, searchQuery, userDetailsTabIndex, pageSize]
  )

  const handleGetOfferRequestDetailsForAdmin = useCallback(
    (page: number = 1, size: number = pageSize, offerRequestId: number) => {
      setOrdersPage(page)

      const itemRequestPayload: GetAllItemRequestsForAdminRequestPayload = {
        request: { page, size, offerRequestId }
      }

      const paymentDetailsPayload: GetOfferRequestPaymentDetailsForAdminRequestPayload = {
        request: { offerRequestId }
      }

      const tripDetailsPayload: GetOfferRequestTripDetailsForAdminRequestPayload = {
        request: { offerRequestId }
      }

      demoRouter.navigate(`/${adminNavigationPaths.users}/${userId}/Orders/${offerRequestId}`)
      dispatch(getAllItemRequestsForAdmin(itemRequestPayload))
      dispatch(getOfferRequestPaymentDetailsForAdmin(paymentDetailsPayload))
      dispatch(getOfferRequestTripDetailsForAdmin(tripDetailsPayload))
    },
    [dispatch, demoRouter, userId, pageSize]
  )

  const handleGetTripDetailsForAdmin = useCallback(
    (page: number = 1, size: number = pageSize, tripId: string) => {
      const tripDetailsPayload: GetTripDetailsForAdminRequestPayload = {
        request: { flightItineraryId: tripId, page, size }
      }

      const tripOfferRequestsPayload: GetTripOfferRequestsForAdminRequestPayload = {
        request: { flightItineraryId: tripId, page, size }
      }

      const tripPaymentDetailsPayload: GetTripPaymentDetailsForAdminRequestPayload = {
        request: { flightItineraryId: tripId }
      }

      demoRouter.navigate(`/${adminNavigationPaths.users}/${userId}/Trips/${tripId}`)
      dispatch(getTripDetailsForAdmin(tripDetailsPayload))
      dispatch(getTripOfferRequestsForAdmin(tripOfferRequestsPayload))
      dispatch(getTripPaymentDetailsForAdmin(tripPaymentDetailsPayload))
    },
    [dispatch, demoRouter, userId, pageSize]
  )

  const handleGetAllUserTrips = useCallback(
    (page: number = 1, size: number = pageSize) => {
      const tripSearchQuery = userDetailsTabIndex === 1 ? searchQuery : undefined

      const payload: GetUserTripsForAdminRequestPayload = {
        request: { userId, page, size, searchQuery: tripSearchQuery }
      }

      dispatch(getUserTripsForAdmin(payload))
    },
    [dispatch, userId, searchQuery, userDetailsTabIndex, pageSize]
  )

  useEffect(() => {
    if (ordersSuccess?.data) {
      const { currentPage, totalPages, records, totalRecords } = ordersSuccess.data

      setOrdersData(records)
      setOrdersPage(currentPage)
      setOrdersTotalPages(totalPages)
      setOrdersTotalRecords(totalRecords)
    }
  }, [ordersSuccess])

  useEffect(() => {
    if (tripsSuccess?.data) {
      const { currentPage, totalPages, totalRecords, records } = tripsSuccess.data

      setTripsData(records)
      setTripsPage(currentPage)
      setTripsTotalPages(totalPages)
      setTripsTotalRecords(totalRecords)
    }
  }, [tripsSuccess])

  useEffect(() => {
    if (userId) {
      const initialFetch = async () => {
        await Promise.all([handleGetAllUserOfferRequests(), handleGetAllUserTrips()])

        const totalOrders = ordersSuccess?.data.records.length
        const totalTrips = tripsSuccess?.data.records.length

        if (totalOrders === 0 && totalTrips !== 0) {
          setUserDetailsTabIndex(1)
        } else {
          setUserDetailsTabIndex(0)
        }
      }

      initialFetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userDetailsTabIndex === 0) {
      setOrdersPage(1)
      setOrdersData([])
      handleGetAllUserOfferRequests(1)
    } else if (userDetailsTabIndex === 1) {
      setTripsPage(1)
      setTripsData([])
      handleGetAllUserTrips(1)
    }
  }, [searchQuery, userDetailsTabIndex, handleGetAllUserOfferRequests, handleGetAllUserTrips])

  const handleTabChange = (newValue: number) => {
    setUserDetailsTabIndex(newValue)
  }

  const tabs = [
    {
      label: 'Orders',
      content: (
        <Box sx={styles.tabPanel}>
          <Table
            columns={orderTableColumns}
            data={ordersData}
            pagination={{
              currentPage: ordersPage,
              totalPages: ordersTotalPages,
              totalRecords: ordersTotalRecords,
              onNext: () => handleGetAllUserOfferRequests(ordersPage + 1),
              onPrevious: () => handleGetAllUserOfferRequests(ordersPage - 1)
            }}
            onRowClick={(_, index) => {
              const offerRequestId = ordersData[index].id

              if (offerRequestId) {
                handleGetOfferRequestDetailsForAdmin(1, pageSize, offerRequestId)
              }
            }}
            inPage
            initialLoading={ordersLoading}
            error={!!ordersError}
            onRetry={() => handleGetAllUserOfferRequests()}
          />
        </Box>
      )
    },
    {
      label: 'Trips',
      content: (
        <Box sx={styles.tabPanel}>
          <Table
            columns={tripTableColumns}
            data={tripsData}
            pagination={{
              currentPage: tripsPage,
              totalPages: tripsTotalPages,
              totalRecords: tripsTotalRecords,
              onNext: () => handleGetAllUserTrips(tripsPage + 1),
              onPrevious: () => handleGetAllUserTrips(tripsPage - 1)
            }}
            inPage
            initialLoading={tripsLoading}
            error={!!tripsError}
            onRetry={() => handleGetAllUserTrips()}
            onRowClick={(_, index) => {
              const tripId = tripsData[index].flightItineraryDetails.id

              if (tripId) {
                handleGetTripDetailsForAdmin(1, pageSize, tripId)
              }
            }}
          />
        </Box>
      )
    }
  ]

  return <Tabs tabs={tabs} value={userDetailsTabIndex} onChange={handleTabChange} />
}

export default UserDetails
