import { getFormattedTripType } from '@/shared/functions/Flights/flights.functions'
import { TripType } from '@/shared/functions/Flights/flights.types'
import { Chip } from '@mui/material'

import { GetTripDetailsForAdminData } from '../../../../../../../../redux/states/admin/trips/getTripDetailsForAdmin/getTripDetailsForAdmin.types'
import { FlightItineraryLeg } from '../../../../../../../../shared/functions/Places/places.types'
import { getStatusColor } from '../../../../../../../../shared/functions/Status/status.functions'
import { changeCase, formatMoney } from '../../../../../../../../shared/functions/String/string.functions'
import { getFormattedTripDetails } from '../../../../../../../Trips/Trips.functions'

const tripDetailsTableColumns = [
  {
    key: 'id',
    label: 'Trip ID',
    render: (row: GetTripDetailsForAdminData) => row.tripDetails.flightItineraryDetails.id
  },
  {
    key: 'tripType',
    label: 'Trip Type',
    render: (row: GetTripDetailsForAdminData) =>
      getFormattedTripType(row.tripDetails.flightItineraryDetails.tripType as TripType)
  },
  {
    key: 'tripDetails',
    label: 'Trip Details',
    render: (row: GetTripDetailsForAdminData) =>
      getFormattedTripDetails(
        row.tripDetails.flightItineraryDetails.legs as unknown as FlightItineraryLeg[],
        row.tripDetails.flightItineraryDetails.tripType as TripType
      )
  },
  {
    key: 'itinerarySource',
    label: 'Booking Type',
    render: (row: GetTripDetailsForAdminData) =>
      changeCase(row.tripDetails.flightItineraryDetails.itinerarySource).sentence
  },
  {
    key: 'flightCost',
    label: 'Flight Cost',
    render: (row: GetTripDetailsForAdminData) =>
      row.tripDetails.flightBookingDetails?.flightCost
        ? formatMoney(row.tripDetails.flightBookingDetails.flightCost, row.tripDetails.flightBookingDetails.currency)
        : '-'
  },
  {
    key: 'tripStatus',
    label: 'Trip Status',
    render: (row: GetTripDetailsForAdminData) => (
      <Chip
        label={row.tripDetails.flightItineraryDetails.status.displayName}
        color={getStatusColor(row.tripDetails.flightItineraryDetails.status.name)}
      />
    )
  }
]

export { tripDetailsTableColumns }
