import React, { useEffect, useMemo, useState } from 'react'

import env from '@/networkRequests/apiClient/env.config'
import { formatMoney } from '@/shared/functions/String/string.functions'
import theme from '@/shared/styles/themes/default.theme'
import { Box, Typography, useMediaQuery } from '@mui/material'

import Button from '../Button/Button.component'
import Modal from '../Modal/Modal.component'

import styles from './TripPreferencesModal.styles'
import { TripPreferencesModalOption, TripPreferencesModalProps } from './TripPreferencesModal.types'

const TripPreferencesModal: React.FC<TripPreferencesModalProps> = (props) => {
  const { open, onClose, onSubmit, minimumStops, flightTotalPrice } = props
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const priceOptions: TripPreferencesModalOption[] = [
    { label: '+$20', value: 20 },
    { label: '+$40', value: 40 },
    { label: '+$50', value: 50 }
  ]

  const stopOptions: TripPreferencesModalOption[] = useMemo(
    () => [
      { label: isMobile ? 'None' : 'Non Stop', value: 0 },
      { label: isMobile ? '1' : '1 Stop', value: 1 },
      { label: isMobile ? '+2' : '+2 Stops', value: 2 }
    ],
    [isMobile]
  )

  const defaultStopOption = useMemo(() => {
    return stopOptions.find((option) => option.value >= minimumStops) || stopOptions[stopOptions.length - 1]
  }, [minimumStops, stopOptions])

  const [selectedPrice, setSelectedPrice] = useState(priceOptions[0])
  const [selectedStops, setSelectedStops] = useState(defaultStopOption)

  useEffect(() => {
    setSelectedStops(defaultStopOption)
  }, [defaultStopOption])

  const handlePriceSelection = (option: TripPreferencesModalOption) => {
    setSelectedPrice(option)
  }

  const handleStopsSelection = (option: TripPreferencesModalOption) => {
    setSelectedStops(option)
  }

  const handleSetPreferences = () => {
    onSubmit({ price: selectedPrice.value, stops: selectedStops.value })
    onClose()
  }

  const modalBody = (
    <Box sx={styles.modalBody}>
      <Box sx={styles.priceContainer}>
        <Box sx={styles.currentPriceBox}>
          <Typography variant="subtitle2">Current Price</Typography>

          <Typography variant="h5" sx={styles.priceText}>
            {formatMoney(flightTotalPrice, env.STRIPE_MAIN_CURRENCY)}
          </Typography>
        </Box>

        <Box sx={styles.saveBox}>
          <Typography variant={isMobile ? 'body2' : 'body1'} sx={styles.saveText}>
            Get Item Requests! Fly for Less!
          </Typography>

          <Typography variant="body2">After you set preferences, go to Share Link to get item requests.</Typography>
        </Box>
      </Box>

      <Box sx={styles.sectionContainer}>
        <Typography variant="h6" sx={styles.sectionTitle}>
          Price
        </Typography>

        <Box sx={styles.optionsContainer}>
          {priceOptions.map((option) => (
            <Button
              key={option.value}
              buttonType="secondary"
              variant="outlined"
              text={option.label}
              containerStyle={styles.optionButtonContainer}
              sx={{
                ...styles.optionButton,
                ...(selectedPrice.value === option.value ? styles.selectedOption : {})
              }}
              onClick={() => handlePriceSelection(option)}
            />
          ))}
        </Box>
      </Box>

      <Box sx={styles.sectionContainer}>
        <Typography variant="h6" sx={styles.sectionTitle}>
          Stops (per leg)
        </Typography>

        <Box sx={styles.optionsContainer}>
          {stopOptions.map((option) => (
            <Button
              key={option.value}
              buttonType="secondary"
              variant="outlined"
              text={option.label}
              containerStyle={styles.optionButtonContainer}
              sx={{
                ...styles.optionButton,
                ...(selectedStops.value === option.value ? styles.selectedOption : {})
              }}
              onClick={() => handleStopsSelection(option)}
              disabled={option.value < minimumStops}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Trip Preferences"
      subtitle="Please select your flight preferences. Just in case the price or flight becomes unavailable, we'll offer you a similar flight."
      body={modalBody}
      primaryButton={{
        label: 'Set Now',
        onClick: handleSetPreferences
      }}
    />
  )
}

export default TripPreferencesModal
