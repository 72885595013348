import { Theme } from '@mui/material'

const styles = {
  notificationIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '8px',
    marginRight: '8px',
    cursor: 'pointer'
  },

  badge: {
    position: 'absolute',
    top: 0,
    right: 3,
    backgroundColor: (theme: Theme) => theme.palette.primary.main,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    borderRadius: '50%',
    fontSize: '10px',
    fontWeight: 'bold',
    // padding: '2px 4px',
    // width: '16px',
    minWidth: '10px',
    height: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(50%, -50%)'
  },

  menu: {
    marginTop: '8px'
  },

  menuPaper: {
    maxWidth: '320px',
    width: '320px',
    maxHeight: '400px',
    overflow: 'auto'
  },

  menuItem: {
    whiteSpace: 'normal',
    padding: '12px',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`
  },

  menuHeader: {
    padding: '10px 16px',
    fontWeight: 'bold',
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`
  },

  conversationInfo: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%'
  },

  conversationTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '4px'
  },

  participantName: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  messageTime: {
    fontSize: '0.75rem',
    color: (theme: Theme) => theme.palette.text.secondary
  },

  messagePreview: {
    fontSize: '0.85rem',
    color: (theme: Theme) => theme.palette.text.secondary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  orderInfo: {
    fontSize: '0.75rem',
    color: (theme: Theme) => theme.palette.primary.main,
    marginTop: '2px'
  },

  emptyMessage: {
    padding: '16px',
    textAlign: 'center',
    color: (theme: Theme) => theme.palette.text.secondary
  },

  unreadBadge: {
    backgroundColor: (theme: Theme) => theme.palette.primary.main,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    borderRadius: '50%',
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '2px 4px',
    minWidth: '10px',
    height: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '8px'
  },

  unreadMenuItem: {
    backgroundColor: (theme: Theme) => theme.palette.action.hover
  }
}

export default styles
