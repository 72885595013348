import { CSSProperties } from 'react'

import theme from '../../styles/themes/default.theme'

const styles = {
  card: {
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2)
  },

  header: {
    backgroundColor: theme.palette.grey[100]
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(5),
    width: '100%'
  },

  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },

  headerLeftTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(0.5),
    textAlign: 'left'
  },

  headerRight: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },

  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.subtitle1.fontWeight
  } as CSSProperties,

  subtitle: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary
  } as CSSProperties,

  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[100]}`
  },

  actionButton: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.primary.main
  },

  actionText: {
    fontSize: theme.typography.body1.fontSize
  } as CSSProperties,

  expandButton: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },

  mainContent: {
    // paddingTop: 0
  },

  expandButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginLeft: 'auto',
    cursor: 'pointer'
  },

  additionalContent: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`
  } as CSSProperties,

  expandButtonText: {
    fontSize: theme.typography.body1.fontSize
  } as CSSProperties
}

export default styles
