import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
    gap: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(1)
    }
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    maxWidth: 'calc(100% - 130px)',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  } as SxProps,

  itemImageContainer: {
    width: '35px',
    height: '35px',
    minWidth: '35px',
    minHeight: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },

  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  } as CSSProperties,

  itemDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
    overflow: 'hidden'
  },

  itemName: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    width: '100%'
  } as SxProps,

  itemInfo: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },

  itemPrice: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },

  viewOnAmazonButton: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontSize: '1rem',
    padding: theme.spacing(1, 1),
    flexShrink: 0,
    minWidth: '120px'
  } as SxProps
}

export default styles
