import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  offerContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius / 4,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  } as SxProps,

  topInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius / 4,
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1)
  } as SxProps,

  avatar: {
    marginRight: theme.spacing(2)
  } as SxProps,

  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as SxProps,

  userName: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[800],
    textAlign: 'left'
  } as SxProps,

  userLocation: {
    textAlign: 'left'
  } as SxProps,

  getAmount: {
    marginLeft: 'auto',
    color: theme.palette.secondary.dark
  } as SxProps,

  itemDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  } as SxProps,

  bottomInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  locationInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1.6, 2),
    borderRadius: theme.shape.borderRadius / 6,
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.6, 1)
    }
  } as SxProps,

  locationIcon: {
    display: 'flex',
    color: theme.palette.grey[400],
    fontSize: '1.2rem',
    marginRight: theme.spacing(1)
  } as SxProps,

  neededByContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1.6, 2),
    borderRadius: theme.shape.borderRadius / 6,
    paddingRight: theme.spacing(2),
    flex: 1,
    alignSelf: 'stretch',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.6, 1)
    }
  },

  boldText: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600]
  } as CSSProperties,

  linkText: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none'
  } as CSSProperties,

  lightText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500]
  } as CSSProperties
}

export default styles
