import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getTripPaymentDetailsForAdminApi } from './getTripPaymentDetailsForAdmin.api'
import getTripPaymentDetailsForAdminInitialState from './getTripPaymentDetailsForAdmin.initialState'
import {
  GetTripPaymentDetailsForAdminErrorResponse,
  GetTripPaymentDetailsForAdminRequestPayload
} from './getTripPaymentDetailsForAdmin.types'

export const getTripPaymentDetailsForAdmin = createAsyncThunk(
  urls.admin.getTripPaymentDetailsForAdmin,
  async (payload: GetTripPaymentDetailsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getTripPaymentDetailsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse, undefined, true)

      return rejectWithValue(error)
    }
  }
)

const getTripPaymentDetailsForAdminSlice = createSlice({
  name: urls.admin.getTripPaymentDetailsForAdmin,
  initialState: getTripPaymentDetailsForAdminInitialState,
  reducers: {
    resetGetTripPaymentDetailsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTripPaymentDetailsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getTripPaymentDetailsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getTripPaymentDetailsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetTripPaymentDetailsForAdminErrorResponse
        state.success = null
      })
  }
})

export const { actions: getTripPaymentDetailsForAdminActions, reducer: getTripPaymentDetailsForAdminReducer } =
  getTripPaymentDetailsForAdminSlice
export const { resetGetTripPaymentDetailsForAdmin } = getTripPaymentDetailsForAdminActions
export default getTripPaymentDetailsForAdminSlice.reducer
