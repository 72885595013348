import { CSSProperties } from 'react'

import theme from '@/shared/styles/themes/default.theme'
import { SxProps } from '@mui/material'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${theme.palette.primary.main}1A`,
    borderRadius: '16px',
    padding: theme.spacing(3),
    alignItems: 'flex-start'
  },

  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },

  flightInfoContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },

  flightStopCount: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold
  },

  flightDuration: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[500]
  },

  airlineContainer: {
    display: 'flex'
  },

  airlineLogo: {
    height: '12px',
    width: '12px',
    objectFit: 'contain',
    marginRight: theme.spacing(0.5)
  } as CSSProperties,

  airline: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium
  },

  flightTime: {
    display: 'flex',
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium
  },

  divider: {
    marginY: theme.spacing(1.5)
  },

  totalAmount: {
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold
  },

  perPerson: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
    textAlign: 'left'
  } as SxProps,

  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  }
}

export default styles
