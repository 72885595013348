import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(5, 10),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3)
    }
  } as SxProps,

  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1.75),
    borderRadius: 1,
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.background.paper,
    minHeight: '50px',
    width: '100%',

    '&:focus-within': {
      borderColor: theme.palette.primary.main
    },

    '&:hover:not(:focus-within)': {
      borderColor: theme.palette.grey[300]
    },

    [theme.breakpoints.down('md')]: {
      paddingY: 0,
      minHeight: theme.spacing(4),
      fontSize: '14px'
    }
  },

  input: {
    flex: 1,
    '& .MuiInputBase-input': {
      padding: theme.spacing(0.25, 0)
    }
  },

  searchButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    height: '50px'
  } as CSSProperties,

  searchIconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: theme.palette.background.paper
    }
  } as SxProps,

  searchIcon: {
    color: theme.palette.primary.main
  } as SxProps,

  clearButton: {
    height: '25px',
    width: '25px',
    marginRight: theme.spacing(0)
  } as SxProps,

  clearIcon: {
    height: '24px',
    width: '24px'
  } as SxProps,

  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(1.5)
  } as SxProps
}

export default styles
