import React, { useCallback, useEffect, useRef, useState } from 'react'

import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { Box, Stack, Typography } from '@mui/material'
import { FormikErrors } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'

import Avatar from '../../shared/components/Avatar/Avatar.component'
import Button from '../../shared/components/Button/Button.component'
import EmptyState from '../../shared/components/EmptyState/EmptyState.component'
import Form from '../../shared/components/Form/Form.component'
import Loader from '../../shared/components/Loader/Loader.component'
import LoyaltyProgramForm from '../../shared/components/LoyaltyProgramForm/LoyaltyProgramForm.component'
import PageTitle from '../../shared/components/PageTitle/PageTitle.component'
import StripeCheckoutForm from '../../shared/components/StripeCheckoutForm/StripeCheckoutForm.component'
import LoyaltyProgramsList from './components/LoyaltyProgramsList/LoyaltyProgramsList.component'
import ProfileActionCard from './components/ProfileActionCard/ProfileActionCard.component'

import {
  createCustomerSession,
  resetCreateCustomerSessionState
} from '../../redux/states/payments/createCustomerSession/createCustomerSession.slice'
import { logPaymentMethodUpdate } from '../../redux/states/payments/logPaymentMethodUpdate/logPaymentMethodUpdate.slice'
import { addDeliveryAddress } from '../../redux/states/profile/addDeliveryAddress/addDeliveryAddress.slice'
import { addLoyaltyProgram } from '../../redux/states/profile/addLoyaltyProgram/addLoyaltyProgram.slice'
import { AddLoyaltyProgramRequestPayload } from '../../redux/states/profile/addLoyaltyProgram/addLoyaltyProgram.types'
import { getLoyaltyPrograms } from '../../redux/states/profile/getLoyaltyPrograms/getLoyaltyPrograms.slice'
import { getProfile } from '../../redux/states/profile/getProfile/getProfile.slice'
import { GetProfileData, GetProfileRequestPayload } from '../../redux/states/profile/getProfile/getProfile.types'
import { updateContactInfo } from '../../redux/states/profile/updateContactInfo/updateContactInfo.slice'
import { updateDeliveryAddress } from '../../redux/states/profile/updateDeliveryAddress/updateDeliveryAddress.slice'
import { updatePersonalInfo } from '../../redux/states/profile/updatePersonalInfo/updatePersonalInfo.slice'
import { getSupportedLoyaltyPrograms } from '../../redux/states/supportedLoyaltyProgram/getSupportedLoyaltyPrograms/getSupportedLoyaltyPrograms.slice'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import { LoyaltyProgramFormValues } from '../../shared/components/LoyaltyProgramForm/LoyaltyProgramForm.types'
import Modal from '../../shared/components/Modal/Modal.functions'
import { PaymentFormRef } from '../../shared/components/StripeCheckoutForm/StripeCheckoutForm.types'
import cities from '../../shared/dictionaries/cities.dictionaries.json'
import countries from '../../shared/dictionaries/countries.dictionaries.json'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { sanitizePhoneNumber } from '../../shared/functions/String/string.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './Profile.styles'
import { ContactDetailsFormValues, DeliveryAddressFormValues, PersonalDetailsFormValues } from './Profile.types'
import { ProfileSection } from './Profile.types'
import { ProfileActionCardHeader } from './components/ProfileActionCard/ProfileActionCard.types'

const Profile: React.FC = () => {
  const { success: profileSuccess, loading: profileLoading } = useAppSelector((state) => state.getProfile)
  const { success: loyaltyPrograms } = useAppSelector((state) => state.getLoyaltyPrograms)
  const { loading: updatePersonalInfoLoading } = useAppSelector((state) => state.updatePersonalInfo)
  const { loading: updateContactInfoLoading } = useAppSelector((state) => state.updateContactInfo)
  const { loading: addDeliveryAddressLoading } = useAppSelector((state) => state.addDeliveryAddress)
  const { loading: updateDeliveryAddressLoading } = useAppSelector((state) => state.updateDeliveryAddress)
  const { loading: getLoyaltyProgramsLoading } = useAppSelector((state) => state.getLoyaltyPrograms)
  const { loading: addLoyaltyProgramLoading } = useAppSelector((state) => state.addLoyaltyProgram)

  const [isPersonalDetailsDisabled, setIsPersonalDetailsDisabled] = React.useState<boolean>(false)
  const [isContactDetailsDisabled, setIsContactDetailsDisabled] = React.useState<boolean>(false)
  const [personalDetailsInitialValues, setPersonalDetailsInitialValues] = React.useState<PersonalDetailsFormValues>({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: ''
  })

  const [contactDetailsInitialValues, setContactDetailsInitialValues] = React.useState<ContactDetailsFormValues>({
    mobileNumber: '',
    email: ''
  })

  const [deliveryAddressInitialValues, setDeliveryAddressInitialValues] = React.useState<DeliveryAddressFormValues>({
    deliveryAddressId: 0,
    cityId: 83, // Default to the Kingston
    countryId: 83, // Default to the Jamaica
    streetAddress: ''
  })

  const selectedSectionFromRoute = router.getNavigationProps().state?.selectedSection as ProfileSection
  const selectedSectionFromUrl = router.getUrlParam('selectedSection')
  const initialSection =
    selectedSectionFromUrl !== '' ? (selectedSectionFromUrl as ProfileSection) : selectedSectionFromRoute ?? 'basic'

  const [selectedSection, setSelectedSection] = useState<ProfileSection>(initialSection)
  const [, setIsPaymentFormValid] = useState(false)
  const [, setPaymentRequestLoading] = useState(false)
  const [setupPaymentLoading, setSetupPaymentLoading] = useState(false)
  const [isFormLoaded, setIsFormLoaded] = useState(false)
  const [hasFormBeenInteractedWith, setHasFormBeenInteractedWith] = useState(false)
  const [airlineIataCode, setAirlineIataCode] = useState<string>('')
  const [loyaltyProgramAccountNumber, setLoyaltyProgramAccountNumber] = useState<string>('')
  const [loyaltyProgramFormErrors, setLoyaltyProgramFormErrors] = useState<FormikErrors<LoyaltyProgramFormValues>>({})
  const [isDesktop] = useResponsiveness()

  const paymentFormRef = useRef<PaymentFormRef>(null)

  const navigate = router.navigate()
  const dispatch = useAppDispatch()
  const redirectStatus = router.getUrlParam('redirect_status')

  const {
    success: customerSessionSuccess,
    loading: customerSessionLoading,
    error: customerSessionError
  } = useAppSelector((state) => state.createCustomerSession)

  useEffect(() => {
    return () => {
      dispatch(resetCreateCustomerSessionState())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getLoyaltyPrograms({}))
    dispatch(getSupportedLoyaltyPrograms({}))
  }, [dispatch])

  useEffect(() => {
    if (redirectStatus === 'succeeded') {
      setSelectedSection('payment')
      dispatch(createCustomerSession({}))
    }
  }, [redirectStatus, dispatch])

  useEffect(() => {
    if (initialSection === 'payment') {
      dispatch(createCustomerSession({}))
    }
  }, [dispatch, initialSection])

  const updateInitialValues = useCallback((data: GetProfileData) => {
    let dateOfBirth = ''

    if (data.dateOfBirth) {
      dateOfBirth = date(data.dateOfBirth).format(dateTimeFormats.date.api) ?? ''
    }

    setPersonalDetailsInitialValues({
      firstName: data.firstName || '',
      middleName: data.middleName || '',
      lastName: data.lastName || '',
      dateOfBirth
    })

    setContactDetailsInitialValues({
      mobileNumber: data.mobileNumber || '',
      email: data.email || ''
    })

    /**
     * Defaulting to the first item in the array of delivery addresses
     * until we separate the delivery addresses from the profile data
     */
    setDeliveryAddressInitialValues({
      deliveryAddressId: data?.deliveryAddresses[0]?.id || 0,
      cityId: data?.deliveryAddresses[0]?.city_id || 83,
      countryId: data?.deliveryAddresses[0]?.country_id || 83,
      streetAddress: data?.deliveryAddresses[0]?.street_address || ''
    })
  }, [])

  const handleGetProfile = useCallback(() => {
    const payload: GetProfileRequestPayload = {
      request: {},
      onSuccess: (data: GetProfileData) => {
        updateInitialValues(data)
      }
    }

    dispatch(getProfile(payload))
  }, [dispatch, updateInitialValues])

  useEffect(() => {
    handleGetProfile()
  }, [handleGetProfile])

  const personalDetailsFields: FormField[] = [
    {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      autoComplete: 'given-name',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'middleName',
      label: 'Middle Name',
      type: 'text',
      autoComplete: 'additional-name',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      autoComplete: 'family-name',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'dateOfBirth',
      label: 'Date of Birth',
      type: 'date',
      autoComplete: 'bday',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    }
  ]

  const contactDetailsFields: FormField[] = [
    {
      name: 'mobileNumber',
      label: 'Mobile Number',
      type: 'tel',
      autoComplete: 'tel',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'cityId',
      label: 'City',
      type: 'select',
      options: cities.map((city) => ({ value: city.id, label: city.name })),
      autoComplete: 'address-level2',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
      // onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleCityChange(Number(event.target.value))
    },
    {
      name: 'countryId',
      label: 'Country',
      type: 'select',
      options: countries.map((country) => ({ value: country.id, label: country.name })),
      autoComplete: 'country',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    },
    {
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      autoComplete: 'street-address',
      grid: { xs: 12 },
      fullWidth: true
    }
  ]

  const personalDetailsValidationSchema = Yup.object({
    firstName: Yup.string().min(2, 'First name must be at least 2 characters long').required('Required'),
    middleName: Yup.string().min(2, 'Middle name must be at least 2 characters long'),
    lastName: Yup.string().min(2, 'Last name must be at least 2 characters long').required('Required'),
    dateOfBirth: Yup.date().optional()
  })

  const contactDetailsValidationSchema = Yup.object({
    mobileNumber: Yup.string().min(10, 'Mobile number must be at least 10 characters long').required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    cityId: Yup.number().required('Required'),
    countryId: Yup.number().required('Required'),
    streetAddress: Yup.string().min(2, 'Street address must be at least 2 characters long')
  })

  const handlePersonalDetailsSubmit = (values: PersonalDetailsFormValues) => {
    const onSuccess = () => {
      setIsPersonalDetailsDisabled(false)
      handleGetProfile()
    }

    dispatch(updatePersonalInfo({ updatePersonalInfoRequest: values, onSuccess }))
  }

  const handleContactDetailsSubmit = (values: ContactDetailsFormValues & DeliveryAddressFormValues) => {
    const onSuccess = () => {
      setIsContactDetailsDisabled(false)
      handleGetProfile()
    }

    const contactDetailsValues: ContactDetailsFormValues = {
      mobileNumber: values.mobileNumber ? sanitizePhoneNumber(values.mobileNumber) || '' : '',
      email: values.email
    }

    const deliveryAddressValues: DeliveryAddressFormValues = {
      deliveryAddressId: values.deliveryAddressId,
      cityId: values.cityId,
      countryId: values.countryId,
      streetAddress: values.streetAddress
    }

    const deliveryValues = {
      ...deliveryAddressValues,
      postalCode: '00000'
    }

    const isDeliveryAddressEmpty = Object.values(deliveryAddressValues).every((value) => value === '')

    if (!isDeliveryAddressEmpty) {
      const profileData = profileSuccess?.data as GetProfileData

      if (_.isEmpty(profileData?.deliveryAddresses)) {
        dispatch(addDeliveryAddress({ addDeliveryAddressRequest: deliveryValues, onSuccess }))
      } else {
        dispatch(updateDeliveryAddress({ updateDeliveryAddressRequest: deliveryValues, onSuccess }))
      }
    }

    dispatch(updateContactInfo({ updateContactInfoRequest: contactDetailsValues, onSuccess }))
  }

  // const handleCityChange = (cityId: number) => {
  //   const selectedCity = cities.find((city) => city.id === cityId)

  //   if (selectedCity) {
  //     const countryId = selectedCity.country_id

  //     setDeliveryAddressInitialValues((prevValues) => ({
  //       ...prevValues,
  //       cityId,
  //       countryId
  //     }))
  //   }
  // }

  const handleSectionChange = (section: ProfileSection) => {
    setSelectedSection(section)

    if (section === 'payment' && !customerSessionSuccess) {
      dispatch(createCustomerSession({}))
    }
  }

  const handleSavePaymentMethod = () => {
    paymentFormRef.current?.submitPayment()
    dispatch(logPaymentMethodUpdate({}))
  }

  const profileHeader = () => {
    return (
      <Stack sx={styles.profileHeader}>
        <Box sx={styles.profilePictureContainer}>
          <Avatar
            size={'xLarge'}
            firstName={profileSuccess?.data?.firstName}
            lastName={profileSuccess?.data?.lastName}
            noRightMargin
          />
        </Box>

        <Stack sx={styles.profileButtonsContainer}>
          <Button
            buttonType={selectedSection === 'basic' ? 'primary' : 'tertiary'}
            fullWidth
            text="Basic Information"
            style={styles.profileButton}
            onClick={() => handleSectionChange('basic')}
          />

          <Button
            buttonType={selectedSection === 'payment' ? 'primary' : 'tertiary'}
            fullWidth
            text="Payment Methods"
            onClick={() => handleSectionChange('payment')}
          />
        </Stack>
      </Stack>
    )
  }

  const handleAddLoyaltyProgramSubmit = useCallback(() => {
    if (_.isEmpty(loyaltyProgramFormErrors)) {
      const onSuccess = () => {
        dispatch(getLoyaltyPrograms({}))
      }
      const addLoyaltyProgramPayload: AddLoyaltyProgramRequestPayload = {
        request: {
          airline_iata_code: airlineIataCode,
          account_number: loyaltyProgramAccountNumber
        },
        onSuccess
      }

      dispatch(addLoyaltyProgram({ ...addLoyaltyProgramPayload }))
    }
  }, [airlineIataCode, dispatch, loyaltyProgramAccountNumber, loyaltyProgramFormErrors])

  const reShowModalWithUpdatedValues = useCallback(() => {
    Modal.show({
      title: 'Loyalty Program',
      subtitle: 'Register your Loyalty Program. You could be eligible for better pricing or flight awards.',
      body: (
        <LoyaltyProgramForm
          setAirlineIataCode={setAirlineIataCode}
          placeholder
          setLoyaltyProgramAccountNumber={setLoyaltyProgramAccountNumber}
          setErrors={setLoyaltyProgramFormErrors}
          onSubmit={handleAddLoyaltyProgramSubmit}
        />
      ),
      primaryButton: {
        label: 'Save',
        disabled: !airlineIataCode || !loyaltyProgramAccountNumber || !_.isEmpty(loyaltyProgramFormErrors),
        onClick: () => {
          handleAddLoyaltyProgramSubmit()
        }
      },
      secondaryButton: {
        label: 'Cancel',
        onClick: () => {
          setAirlineIataCode('')
          setLoyaltyProgramAccountNumber('')
          setLoyaltyProgramFormErrors({})
        }
      }
    })
  }, [airlineIataCode, loyaltyProgramAccountNumber, loyaltyProgramFormErrors, handleAddLoyaltyProgramSubmit])

  useEffect(() => {
    if (airlineIataCode || loyaltyProgramAccountNumber) {
      reShowModalWithUpdatedValues()
    }
  }, [airlineIataCode, loyaltyProgramAccountNumber, reShowModalWithUpdatedValues])

  const handleAddLoyaltyProgram = useCallback(() => {
    setAirlineIataCode('')
    setLoyaltyProgramAccountNumber('')
    setLoyaltyProgramFormErrors({})
    reShowModalWithUpdatedValues()
  }, [reShowModalWithUpdatedValues])

  const renderLoyaltyProgramsContent = () => {
    if (loyaltyPrograms && loyaltyPrograms.data.length > 0) {
      return <LoyaltyProgramsList programs={loyaltyPrograms?.data} />
    }

    return (
      <Box sx={styles.profileActionCardContainer}>
        <Typography sx={styles.changePasswordText}>
          Simply add your loyalty program and save more when you book your flights. All we need is your{' '}
          <span style={styles.highlightText}>program name</span> and <span style={styles.highlightText}>number</span>.
        </Typography>

        <Button
          buttonType="primary"
          outline
          text="Add Program"
          onClick={handleAddLoyaltyProgram}
          fullWidth={!isDesktop}
        />
      </Box>
    )
  }

  const generateLoyaltyProgramHeader = (): ProfileActionCardHeader => {
    return {
      title: 'Loyalty Program',
      buttons:
        loyaltyPrograms?.data && loyaltyPrograms?.data?.length > 0
          ? [{ text: 'Add Program', onClick: handleAddLoyaltyProgram, buttonType: 'tertiary' }]
          : []
    }
  }

  const renderContent = () => {
    if (selectedSection === 'basic') {
      return (
        <>
          <Form
            title="Personal Details"
            initialValues={{ ...personalDetailsInitialValues }}
            onSubmit={(values: unknown) => handlePersonalDetailsSubmit(values as PersonalDetailsFormValues)}
            formFields={personalDetailsFields}
            validationSchema={personalDetailsValidationSchema}
            buttonText="Save"
            isContained
            isEditable={isPersonalDetailsDisabled}
            setIsEditable={setIsPersonalDetailsDisabled}
            loading={profileLoading || updatePersonalInfoLoading}
            formPadding={3}
            enableReinitialize
          />

          <Form
            title="Contact Details"
            initialValues={{ ...contactDetailsInitialValues, ...deliveryAddressInitialValues }}
            onSubmit={(values: unknown) =>
              handleContactDetailsSubmit(values as ContactDetailsFormValues & DeliveryAddressFormValues)
            }
            formFields={contactDetailsFields}
            validationSchema={contactDetailsValidationSchema}
            buttonText="Save"
            isContained
            isEditable={isContactDetailsDisabled}
            setIsEditable={setIsContactDetailsDisabled}
            loading={
              profileLoading || addDeliveryAddressLoading || updateDeliveryAddressLoading || updateContactInfoLoading
            }
            formPadding={3}
            enableReinitialize
          />

          <ProfileActionCard
            loading={getLoyaltyProgramsLoading || addLoyaltyProgramLoading}
            loadingText="Just a moment. We are getting your loyalty programs"
            header={generateLoyaltyProgramHeader()}
          >
            {renderLoyaltyProgramsContent()}
          </ProfileActionCard>

          <ProfileActionCard>
            <Box sx={styles.profileActionCardContainer}>
              <Typography sx={styles.changePasswordText}>
                If you want to change your password please click the option. A email with a link to change your password
                will be sent to the email attached to this account.
              </Typography>

              <Button
                buttonType="primary"
                outline
                text="Change Password"
                onClick={() => navigate(routes.changePassword.path)}
                fullWidth={!isDesktop}
              />
            </Box>
          </ProfileActionCard>
        </>
      )
    } else {
      if (customerSessionLoading && !isFormLoaded) {
        return <Loader loading={customerSessionLoading} marginTop={10} text="Loading payment methods..." />
      } else if (
        customerSessionSuccess?.data?.customerSessionClientSecret &&
        customerSessionSuccess?.data?.setupIntentClientSecret
      ) {
        return (
          <Box sx={styles.paymentMethodsContainer}>
            <StripeCheckoutForm
              mode="setup"
              redirectPath={routes.profile.path}
              paymentIntentClientSecret={customerSessionSuccess.data.setupIntentClientSecret}
              customerSessionClientSecret={customerSessionSuccess.data.customerSessionClientSecret}
              setIsPaymentFormValid={setIsPaymentFormValid}
              setPaymentRequestLoading={setPaymentRequestLoading}
              setSetupPaymentLoading={setSetupPaymentLoading}
              paymentFormRef={paymentFormRef}
              currency="usd"
              showTitle
              setIsFormLoaded={setIsFormLoaded}
              setHasFormBeenInteractedWith={setHasFormBeenInteractedWith}
            />

            <Button
              buttonType="primary"
              fullWidth
              altText="Save payment method"
              text="Save"
              onClick={handleSavePaymentMethod}
              loading={setupPaymentLoading}
              disabled={!hasFormBeenInteractedWith}
            />
          </Box>
        )
      } else if (customerSessionError) {
        return (
          <Box sx={styles.paymentMethodsContainer}>
            <EmptyState
              title="Payment Method Error"
              subtitle="There was an issue creating your payment method. Please try again shortly."
              errorId={customerSessionError.correlationId}
              button={{
                text: 'Try Again',
                onClick: () => dispatch(createCustomerSession({}))
              }}
            />
          </Box>
        )
      } else {
        return null
      }
    }
  }

  return (
    <Box sx={styles.container}>
      <PageTitle title="Profile" marginBottom={0} />

      <Stack sx={styles.contentContainer} id="profile-content-container">
        {profileHeader()}

        <Box sx={styles.profileFormsContainer} id="profile-forms-container">
          {renderContent()}
        </Box>
      </Stack>
    </Box>
  )
}

export default Profile
