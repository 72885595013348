import { Theme } from '@mui/material'

const styles = {
  inputContainer: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 15px',
    borderTop: `1px solid ${theme.palette.divider}`
  }),

  textField: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      padding: '8px 12px',
      backgroundColor: (theme: Theme) => theme.palette.grey[100],
      borderRadius: '18px',
      fontSize: '0.9rem'
    },
    '& .MuiInputBase-input': {
      padding: '0',
      lineHeight: '1.4',
      maxHeight: 'calc(2.8em + 16px)',
      overflowY: 'auto'
    }
  },

  sendButton: {
    marginLeft: '8px'
  }
}

export default styles
