import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  legHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('md')]: {
      border: 'none',
      borderRadius: 0,
      padding: 0
    }
  } as SxProps,

  emptyStateContainer: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  } as SxProps,

  accordionHeaderMainContent: {
    textAlign: 'left'
  } as CSSProperties,

  legHeaderMainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as CSSProperties,

  legHeaderTitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightMedium
    }
  } as CSSProperties,

  legDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  } as SxProps,

  divider: {
    margin: theme.spacing(1, 0, 1, 0),
    width: '40%'
  } as CSSProperties,

  flightLegDetailsContainer: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1.5),
      borderRadius: theme.spacing(2),
      border: `1px solid ${theme.palette.grey[200]}`
    }
  } as SxProps,

  bookingMessage: {
    fontSize: '12px',
    marginTop: theme.spacing(1)
  } as CSSProperties
}

export default styles
