import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createItineraryFromExternalFlightBookingRequestForAdminApi } from './createItineraryFromExternalFlightBookingRequestForAdmin.api'
import createItineraryFromExternalFlightBookingRequestForAdminInitialState from './createItineraryFromExternalFlightBookingRequestForAdmin.initialState'
import {
  CreateItineraryFromExternalFlightBookingRequestForAdminErrorResponse,
  CreateItineraryFromExternalFlightBookingRequestForAdminRequestPayload
} from './createItineraryFromExternalFlightBookingRequestForAdmin.types'

export const createItineraryFromExternalFlightBookingRequestForAdmin = createAsyncThunk(
  urls.admin.createItineraryFromExternalFlightBookingRequestForAdmin,
  async (payload: CreateItineraryFromExternalFlightBookingRequestForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await createItineraryFromExternalFlightBookingRequestForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const createItineraryFromExternalFlightBookingRequestForAdminSlice = createSlice({
  name: urls.admin.createItineraryFromExternalFlightBookingRequestForAdmin,
  initialState: createItineraryFromExternalFlightBookingRequestForAdminInitialState,
  reducers: {
    resetCreateItineraryFromExternalFlightBookingRequestForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }

    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(createItineraryFromExternalFlightBookingRequestForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createItineraryFromExternalFlightBookingRequestForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createItineraryFromExternalFlightBookingRequestForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateItineraryFromExternalFlightBookingRequestForAdminErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: createItineraryFromExternalFlightBookingRequestForAdminActions,
  reducer: createItineraryFromExternalFlightBookingRequestForAdminReducer
} = createItineraryFromExternalFlightBookingRequestForAdminSlice
export const { resetCreateItineraryFromExternalFlightBookingRequestForAdmin } =
  createItineraryFromExternalFlightBookingRequestForAdminActions
export default createItineraryFromExternalFlightBookingRequestForAdminSlice.reducer
