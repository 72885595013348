import date from '@/shared/functions/Date/date.functions'
import { Box, Divider, Typography } from '@mui/material'

import styles from './FlightOfferSummaryHeader.styles'
import { FlightOfferSummaryHeaderProps } from './FlightOfferSummaryHeader.types'

const FlightDetails = (props: FlightOfferSummaryHeaderProps) => {
  const {
    offer,
    duration,
    stopCount,
    airlineLogo,
    originAirportName,
    destinationAirportName,
    departureTime,
    arrivalTime,
    airline,
    flightNumber,
    departureDate
  } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography sx={styles.departureDate}>Departing: {date(departureDate).format('MMM dd, yyyy')}</Typography>
      </Box>

      <Box sx={styles.flightDetailsContainer}>
        <img style={styles.airlineLogo} src={airlineLogo} alt={offer.airline} />

        <Box>
          <Typography sx={styles.flightLocations}>
            {originAirportName} to {destinationAirportName}
          </Typography>

          <Typography sx={styles.flightDetails}>
            {date(departureTime).format('h:mma')?.toLocaleLowerCase()} -{' '}
            {date(arrivalTime).format('h:mma')?.toLocaleLowerCase()} ({duration})
          </Typography>

          <Box sx={styles.flightDetailsContainer}>
            <Typography sx={styles.flightDetails}>{airline}</Typography>

            <Divider orientation="vertical" flexItem />

            <Typography sx={styles.flightDetails}>Flight {flightNumber}</Typography>

            <Divider orientation="vertical" flexItem />

            <Typography sx={styles.flightDetails}>
              {stopCount === 0 ? 'Non Stop' : `${stopCount} Stop${stopCount > 1 ? 's' : ''}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FlightDetails
