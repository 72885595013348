import React from 'react'

import { Box, Divider, Grid, Typography } from '@mui/material'

import { isAdminLoggedIn } from '../../functions/Auth/auth.functions'
import useResponsiveness from '../../hooks/responsive.hooks'
import { Copyright, footerSections, showFooterItem, showFooterSection } from './Footer.functions'
import useFooterState from './Footer.hooks'
import styles from './Footer.styles'

const Footer: React.FC = () => {
  const [isDesktop] = useResponsiveness()
  const [isMain] = useFooterState()

  if (isAdminLoggedIn()) {
    return null
  } else {
    return (
      <Box component="footer" sx={styles.footer}>
        <Box sx={styles.container}>
          <Grid container direction={isDesktop ? 'row' : 'column'} spacing={{ xs: isMain ? 8 : 2, md: 4 }}>
            {footerSections(isDesktop).map(
              (section, index) =>
                showFooterSection(section, isMain, isDesktop) && (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    lg={2}
                    key={index}
                    sx={isMain || isDesktop ? styles.sectionContainer : undefined}
                  >
                    {section.title && (isMain || isDesktop) && (
                      <Typography variant="h6" sx={styles.title}>
                        {section.title}
                      </Typography>
                    )}

                    {section.items.map(
                      (item, idx) =>
                        showFooterItem(item, isMain, isDesktop) && (
                          <Box key={idx} sx={styles.sectionItemContainer}>
                            {item.component}
                          </Box>
                        )
                    )}
                  </Grid>
                )
            )}
          </Grid>
        </Box>

        <Divider />

        <Box sx={styles.copyright} textAlign="center">
          <Copyright />
        </Box>
      </Box>
    )
  }
}

export default Footer
