import { configureRequest, replacePathVariables } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAssignedVirtualCardDetailsErrorResponse,
  GetAssignedVirtualCardDetailsRequest,
  GetAssignedVirtualCardDetailsSuccessResponse
} from './getAssignedVirtualCardDetails.types'

export const getAssignedVirtualCardDetailsApi = async (
  request: GetAssignedVirtualCardDetailsRequest
): Promise<GetAssignedVirtualCardDetailsSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.payments.getAssignedVirtualCardDetails, {
      assignedVirtualCardId: request.assignedVirtualCardId.toString()
    }),
    method: 'GET',
    data: {},
    params: {},
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAssignedVirtualCardDetailsSuccessResponse
  } else {
    throw response as GetAssignedVirtualCardDetailsErrorResponse
  }
}

export default getAssignedVirtualCardDetailsApi
