import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { confirmOrderDeliveryApi } from './confirmOrderDelivery.api'
import confirmOrderDeliveryInitialState from './confirmOrderDelivery.initialState'
import { ConfirmOrderDeliveryErrorResponse, ConfirmOrderDeliveryRequestPayload } from './confirmOrderDelivery.types'

export const confirmOrderDelivery = createAsyncThunk(
  urls.offers.confirmOrderDelivery,
  async (payload: ConfirmOrderDeliveryRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await confirmOrderDeliveryApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const confirmOrderDeliverySlice = createSlice({
  name: urls.offers.confirmOrderDelivery,
  initialState: confirmOrderDeliveryInitialState,
  reducers: {
    resetConfirmOrderDelivery: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmOrderDelivery.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(confirmOrderDelivery.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(confirmOrderDelivery.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ConfirmOrderDeliveryErrorResponse
        state.success = null
      })
  }
})

export const { actions: confirmOrderDeliveryActions, reducer: confirmOrderDeliveryReducer } = confirmOrderDeliverySlice
export const { resetConfirmOrderDelivery } = confirmOrderDeliveryActions
export default confirmOrderDeliverySlice.reducer
