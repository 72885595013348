import logger from '../Logger/logger.functions'

/**
 * Strips the prefix from a custom ID that was generated with a prefix from TABLE_ID_PREFIXES
 * @example stripPrefixFromId('cus_lmt_A1B2-C3D4-E5F6-G7H8') returns 'A1B2-C3D4-E5F6-G7H8'
 * @param id - The ID to strip the prefix from
 * @returns The ID without the prefix
 */
export function stripPrefixFromId(id: string): string {
  try {
    // Find last underscore that separates prefix from random string
    const lastUnderscoreIndex = id.lastIndexOf('_')

    // If no underscore found, return original id
    if (lastUnderscoreIndex === -1) {
      return id
    }

    // Return everything after the last underscore
    return id.substring(lastUnderscoreIndex + 1)
  } catch (error) {
    logger.logError(error, 'Error stripping prefix from ID', 'stripPrefixFromId')
    throw error
  }
}
