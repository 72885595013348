import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getAllExternalFlightBookingRequestsApi } from './getAllExternalFlightBookingRequests.api'
import getAllExternalFlightBookingRequestsInitialState from './getAllExternalFlightBookingRequests.initialState'
import {
  GetAllExternalFlightBookingRequestsErrorResponse,
  GetAllExternalFlightBookingRequestsRequestPayload
} from './getAllExternalFlightBookingRequests.types'

export const getAllExternalFlightBookingRequests = createAsyncThunk(
  urls.flights.getAllExternalFlightBookingRequests,
  async (payload: GetAllExternalFlightBookingRequestsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAllExternalFlightBookingRequestsApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAllExternalFlightBookingRequestsSlice = createSlice({
  name: urls.flights.getAllExternalFlightBookingRequests,
  initialState: getAllExternalFlightBookingRequestsInitialState,
  reducers: {
    resetGetAllExternalFlightBookingRequests: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllExternalFlightBookingRequests.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAllExternalFlightBookingRequests.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAllExternalFlightBookingRequests.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAllExternalFlightBookingRequestsErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: getAllExternalFlightBookingRequestsActions,
  reducer: getAllExternalFlightBookingRequestsReducer
} = getAllExternalFlightBookingRequestsSlice
export const { resetGetAllExternalFlightBookingRequests } = getAllExternalFlightBookingRequestsActions
export default getAllExternalFlightBookingRequestsSlice.reducer
