import status, { statusColors } from './status.dictionaries'
import { StatusCategory, StatusColor } from './status.types'

/**
 * Get the color associated with a status
 * @param status - The status to get the color of
 * @returns The color of the status
 */
export const getStatusColor = (status: string | null | undefined): StatusColor => {
  if (!status) return 'default'

  const category = getStatusCategoryByStatusName(status)

  if (!category) return 'default'

  const statusColor =
    statusColors[category.category][category.status as keyof (typeof statusColors)[typeof category.category]]

  return statusColor
}

/**
 * Get the category associated with a status
 * @param statusName - The name of the status
 * @returns The category of the status
 */
export const getStatusCategoryByStatusName = (statusName: string) => {
  try {
    return (Object.keys(status) as StatusCategory[]).reduce<{ category: StatusCategory; status: string } | undefined>(
      (acc, category) => {
        if (acc) return acc

        const statusKey = Object.keys(status[category]).find(
          (key) => status[category][key as keyof (typeof status)[StatusCategory]] === statusName
        )

        return statusKey ? { category: category as StatusCategory, status: statusKey } : undefined
      },
      undefined
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error finding status category:', error)

    return undefined
  }
}
