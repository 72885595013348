import theme from '../../styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(5),
    gap: theme.spacing(20),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}
