import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getAllUserOfferRequestsForAdminApi } from './getAllUserOfferRequestsForAdmin.api'
import getAllUserOfferRequestsForAdminInitialState from './getAllUserOfferRequestsForAdmin.initialState'
import {
  GetAllUserOfferRequestsForAdminErrorResponse,
  GetAllUserOfferRequestsForAdminRequestPayload
} from './getAllUserOfferRequestsForAdmin.types'

export const getAllUserOfferRequestsForAdmin = createAsyncThunk(
  urls.admin.getAllUserOfferRequestsForAdmin,
  async (payload: GetAllUserOfferRequestsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAllUserOfferRequestsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAllUserOfferRequestsForAdminSlice = createSlice({
  name: urls.admin.getAllUserOfferRequestsForAdmin,
  initialState: getAllUserOfferRequestsForAdminInitialState,
  reducers: {
    resetGetAllUserOfferRequestsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserOfferRequestsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAllUserOfferRequestsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAllUserOfferRequestsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAllUserOfferRequestsForAdminErrorResponse
        state.success = null
      })
  }
})

export const { actions: getAllUserOfferRequestsForAdminActions, reducer: getAllUserOfferRequestsForAdminReducer } =
  getAllUserOfferRequestsForAdminSlice
export const { resetGetAllUserOfferRequestsForAdmin } = getAllUserOfferRequestsForAdminActions
export default getAllUserOfferRequestsForAdminSlice.reducer
