import theme from '../../../../shared/styles/themes/default.theme'

export default {
  container: {
    width: '100%'
  },

  dropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    gap: 2
  },

  dropdownLabel: {
    fontWeight: 600
  },

  latestSpendingLimitRequiredAlert: {
    marginTop: 1,
    alignItems: 'center'
  },

  assignedVirtualCardDetailsModalBodySkeletonElement: {
    marginTop: 3,
    gap: 3,
    display: 'flex',
    flexDirection: 'column'
  },

  bulletPoint: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.info.main,
    alignSelf: 'center'
  }
}
