import env from '@/networkRequests/apiClient/env.config'

import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAllExternalFlightBookingRequestsForAdminErrorResponse,
  GetAllExternalFlightBookingRequestsForAdminRequest,
  GetAllExternalFlightBookingRequestsForAdminSuccessResponse
} from './getAllExternalFlightBookingRequestsForAdmin.types'

export const getAllExternalFlightBookingRequestsForAdminApi = async (
  request: GetAllExternalFlightBookingRequestsForAdminRequest
): Promise<GetAllExternalFlightBookingRequestsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: urls.admin.getAllExternalFlightBookingRequestsForAdmin,
    method: 'GET',
    data: request,
    params: {
      page: request.page ?? '1',
      size: request.size ?? env.DEFAULT_PAGE_SIZE,
      status: request.status ?? ''
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllExternalFlightBookingRequestsForAdminSuccessResponse
  } else {
    throw response as GetAllExternalFlightBookingRequestsForAdminErrorResponse
  }
}

export default getAllExternalFlightBookingRequestsForAdminApi
