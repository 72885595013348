import React from 'react'

import { Box } from '@mui/material'

import styles from './Spacer.styles'
import { SpacerProps } from './Spacer.types'

const Spacer: React.FC<SpacerProps> = ({ flex = 1 }) => {
  return <Box sx={{ ...styles.spacer, flex }} />
}

export default Spacer
