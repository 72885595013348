import { SxProps } from '@mui/material'

import theme from '../../../../../../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  } as SxProps,

  contentSection: {},

  sectionTitle: {
    marginBottom: 1,
    fontWeight: 600,
    color: 'text.primary'
  },

  airlineInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5
  },

  airlineLogo: {
    height: 24,
    width: 'auto'
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}

export default styles
