import { configureRequest, replacePathVariables } from '../../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  UpdateAssignedVirtualCardDetailsErrorResponse,
  UpdateAssignedVirtualCardDetailsRequest,
  UpdateAssignedVirtualCardDetailsSuccessResponse
} from './updateAssignedVirtualCardDetails.types'

export const updateAssignedVirtualCardDetailsApi = async (
  request: UpdateAssignedVirtualCardDetailsRequest
): Promise<UpdateAssignedVirtualCardDetailsSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.updateAssignedVirtualCardDetailsForAdmin, {
      assignedVirtualCardId: request.assignedVirtualCardId
    }),
    method: 'PUT',
    data: {
      spendingLimit: request.spendingLimit
    },
    params: {},
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as UpdateAssignedVirtualCardDetailsSuccessResponse
  } else {
    throw response as UpdateAssignedVirtualCardDetailsErrorResponse
  }
}

export default updateAssignedVirtualCardDetailsApi
