import { getFormattedTripType } from '@/shared/functions/Flights/flights.functions'
import { TripType } from '@/shared/functions/Flights/flights.types'
import { Chip } from '@mui/material'

import { GetAllUserOfferRequestsForAdminOfferRequest } from '../../../../../../redux/states/admin/orders/getAllUserOfferRequestsForAdmin/getAllUserOfferRequestsForAdmin.types'
import { GetUserTripsForAdminTrip } from '../../../../../../redux/states/admin/trips/getUserTripsForAdmin/getUserTripsForAdmin.types'
import date, { dateTimeFormats } from '../../../../../../shared/functions/Date/date.functions'
import { FlightItineraryLeg } from '../../../../../../shared/functions/Places/places.types'
import { getStatusColor } from '../../../../../../shared/functions/Status/status.functions'
import { changeCase } from '../../../../../../shared/functions/String/string.functions'
import { getFormattedTripDetails } from '../../../../../Trips/Trips.functions'

const orderTableColumns = [
  { key: 'id', label: 'Order Number' },
  {
    key: 'creationDate',
    label: 'Created At',
    type: 'date',
    render: (row: GetAllUserOfferRequestsForAdminOfferRequest) =>
      date(row.creationDate).format(dateTimeFormats.date.medium)
  },
  {
    key: 'source',
    label: 'Source',
    render: (row: GetAllUserOfferRequestsForAdminOfferRequest) => `${row.source.country.name}`
  },
  {
    key: 'destination',
    label: 'Destination',
    render: (row: GetAllUserOfferRequestsForAdminOfferRequest) =>
      `${row.destination.city.name ?? '-'} ${row.destination.city.name ? ',' : ''} ${row.destination.country.name}`
  },
  {
    key: 'traveler',
    label: 'Traveler',
    render: (row: GetAllUserOfferRequestsForAdminOfferRequest) =>
      row.traveler ? `${row.traveler.firstName} ${row.traveler.lastName}` : '-'
  },
  {
    key: 'status',
    label: 'Status',
    render: (row: GetAllUserOfferRequestsForAdminOfferRequest) => (
      <Chip label={row.status.displayName} color={getStatusColor(row.status.name)} />
    )
  }
]

const tripTableColumns = [
  { key: 'id', label: 'Trip ID', render: (row: GetUserTripsForAdminTrip) => row.flightItineraryDetails?.id },
  {
    key: 'tripType',
    label: 'Trip Type',
    render: (row: GetUserTripsForAdminTrip) => getFormattedTripType(row.flightItineraryDetails?.tripType as TripType)
  },
  {
    key: 'tripDetails',
    label: 'Trip Details',
    render: (row: GetUserTripsForAdminTrip) =>
      getFormattedTripDetails(
        row.flightItineraryDetails?.legs as unknown as FlightItineraryLeg[],
        row.flightItineraryDetails?.tripType as TripType
      )
  },
  {
    key: 'itinerarySource',
    label: 'Booking Type',
    render: (row: GetUserTripsForAdminTrip) => changeCase(row.flightItineraryDetails?.itinerarySource).sentence
  },
  {
    key: 'duffelOrderId',
    label: 'Duffel Order ID',
    render: (row: GetUserTripsForAdminTrip) => row.flightBookingDetails?.internalDetails?.duffelOrderId ?? '-'
  },
  {
    key: 'confirmationCode',
    label: 'Confirmation Code',
    render: (row: GetUserTripsForAdminTrip) => row.flightBookingDetails?.confirmationCode ?? '-'
  },
  {
    key: 'tripStatus',
    label: 'Trip Status',
    render: (row: GetUserTripsForAdminTrip) => (
      <Chip
        label={row.flightItineraryDetails?.status.displayName}
        color={getStatusColor(row.flightItineraryDetails?.status.name)}
      />
    )
  }
]

export { orderTableColumns, tripTableColumns }
