import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetTripPaymentDetailsForAdminErrorResponse,
  GetTripPaymentDetailsForAdminRequest,
  GetTripPaymentDetailsForAdminSuccessResponse
} from './getTripPaymentDetailsForAdmin.types'

export const getTripPaymentDetailsForAdminApi = async (
  request: GetTripPaymentDetailsForAdminRequest
): Promise<GetTripPaymentDetailsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getTripPaymentDetailsForAdmin, {
      flightItineraryId: request.flightItineraryId
    }),
    method: 'GET',
    params: {},
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetTripPaymentDetailsForAdminSuccessResponse
  } else {
    throw response as GetTripPaymentDetailsForAdminErrorResponse
  }
}

export default getTripPaymentDetailsForAdminApi
