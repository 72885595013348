import { CSSProperties } from 'react'

import { AvailabilityStatus } from '../../../../../../../../../../redux/states/admin/orders/getAllItemRequestsForAdmin/getAllItemRequestsForAdmin.types'
import theme from '../../../../../../../../../../shared/styles/themes/default.theme'

const styles = {
  card: {
    marginBottom: 2
  },

  availabilityStatus: (availabilityStatus: AvailabilityStatus) => ({
    color:
      availabilityStatus === 'In Stock'
        ? theme.palette.success.dark
        : availabilityStatus === 'Out of Stock'
          ? theme.palette.error.dark
          : theme.palette.warning.dark
  }),

  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },

  media: {
    objectFit: 'contain'
  },

  contentSection: {
    marginBottom: 5
  },

  contentSectionTitle: {
    marginBottom: 1,
    fontWeight: 600
  },

  expandButton: {
    marginLeft: 'auto'
  },

  priceChange: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    marginBottom: 1
  },

  productImageContainer: {
    width: 65,
    height: 65,
    borderRadius: 65 / 2,
    overflow: 'hidden'
  },

  productImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  } as CSSProperties,

  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  } as CSSProperties
}

export default styles
