import React from 'react'

import { Stack, Typography } from '@mui/material'

import styles from './PageTitle.styles'
import { PageTitleProps } from './PageTitle.types'

const PageTitle: React.FC<PageTitleProps> = (props) => {
  const { title, subtitle, marginBottom, rightArea } = props

  return (
    <Stack sx={styles.container(marginBottom)}>
      <Stack sx={styles.titleContainer}>
        <Typography variant="h4" sx={styles.title}>
          {title}
        </Typography>

        {subtitle && (
          <Typography variant="subtitle1" sx={styles.subtitle}>
            {subtitle}
          </Typography>
        )}
      </Stack>

      {rightArea && rightArea}
    </Stack>
  )
}

export default PageTitle
