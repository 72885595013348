import { SxProps } from '@mui/material'

import theme from '../../../../styles/themes/default.theme'

const styles = {
  menu: (removeContentPadding: boolean = false) =>
    ({
      '& .MuiMenu-paper': {
        width: '100%',
        maxWidth: '100%',
        mt: theme.spacing(2),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      },
      '& .MuiPaper-root': {
        left: 'unset!important' // This removes the left positioning
      },
      '& .MuiList-root': {
        paddingX: removeContentPadding ? 0 : theme.spacing(3),
        marginBottom: '-1px',
        [theme.breakpoints.down('md')]: {
          paddingTop: removeContentPadding ? 0 : theme.spacing(2),
          paddingBottom: removeContentPadding ? 0 : undefined
        }
      }
    }) as SxProps,

  paper: {
    elevation: 0,
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(0,0,0,0.32))'
    }
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px'
  } as SxProps,

  closeIcon: {
    color: theme.palette.primary.main
  } as SxProps
}

export default styles
