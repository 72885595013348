import { useCallback, useEffect, useMemo, useState } from 'react'

import { AccordionButtonProps } from '@/shared/components/Accordion/Accordion.types'
import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { ReactComponent } from '@/shared/types/react.types'
import { Alert, Box, Divider, Grid, Typography } from '@mui/material'

import Button from '../../shared/components/Button/Button.component'
import EmptyState from '../../shared/components/EmptyState/EmptyState.component'
import Loader from '../../shared/components/Loader/Loader.component'
import ShareFlightItineraryLink from '../../shared/components/ShareFlightItineraryLink/ShareFlightItineraryLink.component'
import ShareFlightItineraryLinkModal from '../../shared/components/ShareFlightItineraryLinkModal/ShareFlightItineraryLinkModal.component'
import { useProfileData } from '../Cart/components/DeliveryDetails/DeliveryDetails.component'
import FlightOffer from '../FlightSearchResults/components/TripSelector/FlightOffer/FlightOffer.component'
import TripOverview from './components/TripOverview/TripOverview.component'

import {
  clearCreateFlightItinerarySuccess,
  createFlightItinerary
} from '../../redux/states/flights/createFlightItinerary/createFlightItinerary.slice'
import {
  CreateFlightItinaryLeg,
  CreateFlightItineraryRequestPayload
} from '../../redux/states/flights/createFlightItinerary/createFlightItinerary.types'
import { FlightItinerary } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import {
  getBaggageDetails,
  resetGetBaggageDetails
} from '../../redux/states/flights/getBaggageDetails/getBaggageDetails.slice'
import { GetBaggageDetailsRequestPayload } from '../../redux/states/flights/getBaggageDetails/getBaggageDetails.types'
import { FareOffer } from '../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { getSeatDetails, resetGetSeatDetails } from '../../redux/states/flights/getSeatDetails/getSeatDetails.slice'
import { GetSeatDetailsRequestPayload } from '../../redux/states/flights/getSeatDetails/getSeatDetails.types'
import { getUpdatedFlightOffer } from '../../redux/states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.slice'
import { GetUpdatedFlightOfferRequestPayload } from '../../redux/states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.types'
import { SearchFlightsSuccessResponse } from '../../redux/states/flights/searchFlights/searchFlights.types'
import { Passenger } from '../../redux/states/flights/searchFlights/types/SearchFlightRequest.types'
import { OfferRequest } from '../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { getProfile } from '../../redux/states/profile/getProfile/getProfile.slice'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import Modal from '../../shared/components/Modal/Modal.functions'
import { TripPreferences } from '../../shared/components/TripPreferencesModal/TripPreferencesModal.types'
import { isAuthenticated } from '../../shared/functions/Auth/auth.functions'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { CabinClass, PassengerType, cabinClassMapping } from '../../shared/functions/Flights/flights.types'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { getCountryByCode } from '../../shared/functions/Places/places.functions'
import { formatMoney } from '../../shared/functions/String/string.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import { ReduxPromiseResult } from '../../shared/types/redux.types'
import { formatFlightPartialOffers, formatSlicesToOffers } from '../FlightSearchResults/FlightSearchResults.functions'
import { FlightSearchInitialValues } from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'
import {
  EXISTING_FLIGHT_ITINERARY_KEY,
  FARE_OFFER_STORAGE_KEY,
  IS_BOOKING_FLIGHT_KEY,
  IS_EXTERNAL_BOOKING_KEY,
  ITINERARY_KEY,
  REDIRECT_TO_TRIP_SUMMARY_KEY,
  SEARCHED_VALUES_STORAGE_KEY,
  SELECTED_OFFERS_STORAGE_KEY,
  TRIP_PREFERENCES_STORAGE_KEY
} from './TripSummary.dictionary'
import styles from './TripSummary.styles'
import { TripSummaryProps } from './TripSummary.types'

const TripSummary: ReactComponent<TripSummaryProps> = (props, _ref) => {
  const {
    tripFare,
    selectedOffers,
    searchedValues: searchedValuesFromProps,
    tripPreferences: tripPreferencesFromProps
  } = props

  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState<string | null>(null)
  const [isDesktop] = useResponsiveness()

  const dispatch = useAppDispatch()
  const navigate = router.navigate()
  const navigationProps = router.getNavigationProps()

  const profileData = useProfileData()

  const isBookingFlight: boolean = navigationProps.state.isBookingFlight
  const itinerary: FlightItinerary = navigationProps.state.itinerary ?? undefined
  const fareOffer: FareOffer = navigationProps.state.fare ?? tripFare
  const tripPreferences: TripPreferences = navigationProps.state.tripPreferences ?? tripPreferencesFromProps
  const searchedValues: FlightSearchInitialValues = navigationProps.state.searchedValues ?? searchedValuesFromProps
  const existingFlightItinerary: FlightItinerary = navigationProps.state.existingFlightItinerary ?? undefined
  const selectedOffersToUse: OfferRequest[] | null = navigationProps.state.selectedOffers ?? selectedOffers
  const flightItineraryId: string = navigationProps.state.flightItineraryId ?? undefined
  const isRenewedOffer: boolean = navigationProps.state.isRenewedOffer ?? false
  const isExternalBooking: boolean = navigationProps.state.isExternalBooking ?? false

  const { success: createItinerarySuccess, loading: createItineraryLoading } = useAppSelector(
    (state) => state.createFlightItinerary
  )
  const { loading: getBaggageDetailsLoading, error: getBaggageDetailsError } = useAppSelector(
    (state) => state.getBaggageDetails
  )

  const { loading: getSeatDetailsLoading, error: getSeatDetailsError } = useAppSelector((state) => state.getSeatDetails)

  const { loading: getUpdatedFlightOfferLoading } = useAppSelector((state) => state.getUpdatedFlightOffer)

  const isTripUpdated =
    itinerary &&
    !isExternalBooking &&
    Number(itinerary?.itinerary?.totalFlightPrice) !== Number(fareOffer?.total_amount)

  const getNewUpdatedFlightOffer = useCallback(() => {
    const payload: GetUpdatedFlightOfferRequestPayload = {
      request: {
        id: itinerary?.id?.toString()
      },

      onSuccess: (success) => {
        if (success.data.offer) {
          navigate(routes.tripSummary.path, {
            state: { fare: success.data.offer, isBookingFlight: true, itinerary }
          })
        }
      },

      onError: () => {
        Modal.show({
          title: 'Offer Expired',
          subtitle: 'We were unable to find a new offer for your flight. Please search for a new offer manually.',
          primaryButton: {
            label: 'Search for new offer',
            onClick: () => {
              navigate(routes.flightSearchResults.path, {
                state: {
                  existingFlightItinerary: itinerary,
                  isBookingFlight: true
                }
              })
            }
          },
          secondaryButton: {
            label: 'Dismiss'
          }
        })

        setLoadingButton(null)
      }
    }

    dispatch(resetGetBaggageDetails())
    dispatch(resetGetSeatDetails())
    dispatch(getUpdatedFlightOffer(payload))
  }, [dispatch, itinerary, navigate])

  useEffect(
    function resetCreateFlightItinerarySuccessState() {
      window.scrollTo(0, 0)

      return () => {
        dispatch(clearCreateFlightItinerarySuccess())
      }
    },
    [dispatch, isExternalBooking]
  )

  useEffect(() => {
    if (!fareOffer && !isExternalBooking) return
  }, [fareOffer, isExternalBooking])

  const onEditFlight = useCallback(() => {
    if (!selectedOffers) {
      window.history.back()
    }
  }, [selectedOffers])

  const getNumberOfStops = (offer: FareOffer) => {
    return offer.slices.map((slice) => slice.segments.length - 1).reduce((acc, curr) => acc + curr, 0)
  }

  const createItinerary = useCallback(
    (onSuccess: () => void) => {
      const passengers: Passenger[] = []

      for (let i = 0; i < searchedValues.adults; i++) {
        passengers.push({ type: 'adult' })
      }

      for (let i = 0; i < searchedValues.children; i++) {
        passengers.push({ type: 'child', age: searchedValues.childrenAges[i] })
      }

      let legs: CreateFlightItinaryLeg[] = []

      if (searchedValues.tripType === 'roundTrip') {
        legs.push({
          travelDate: searchedValues.flights[0].departure as string,
          originAirportIataCode: fareOffer.slices[0].origin.iata_code,
          destinationAirportIataCode: fareOffer.slices[0].destination.iata_code
        })

        legs.push({
          travelDate: searchedValues.flights[0].return as string,
          originAirportIataCode: fareOffer.slices[1].origin.iata_code,
          destinationAirportIataCode: fareOffer.slices[1].destination.iata_code
        })
      } else {
        legs = fareOffer?.slices.map((slice, index) => ({
          travelDate: searchedValues.flights[index].departure as string,
          originAirportIataCode: slice.origin.iata_code,
          destinationAirportIataCode: slice.destination.iata_code
        }))
      }

      const requestPayload: CreateFlightItineraryRequestPayload = {
        request: {
          cabinClass: Object.keys(cabinClassMapping).find(
            (key) => cabinClassMapping[key as CabinClass] === searchedValues.cabinClass
          ) as CabinClass,
          tripType: searchedValues.tripType,
          totalFlightPrice: Number(fareOffer.total_amount),
          currency: fareOffer?.total_currency,
          numberOfStops: getNumberOfStops(fareOffer),
          legs: legs.map((leg) => ({
            ...leg,
            travelDate: date(leg.travelDate).format(dateTimeFormats.date.api) as string
          })),
          passengers: passengers.map((passenger) => ({
            type: passenger.type as PassengerType,
            age: passenger.age
          })),
          allowedVariances: {
            price: tripPreferences.price,
            numberOfStops: tripPreferences.stops
          },
          externalOfferId: fareOffer.id,
          selectedOfferRequestIds: selectedOffersToUse?.map((offer) => offer.offerRequestId)
        },

        onSuccess: onSuccess
      }

      dispatch(createFlightItinerary(requestPayload))
    },
    [dispatch, searchedValues, fareOffer, selectedOffersToUse, tripPreferences]
  )

  const onCreateItinerary = useCallback(() => {
    setLoadingButton('createItinerary')

    if (fareOffer?.id) {
      if (createItinerarySuccess?.data?.shareLink) {
        setShareLinkModalOpen(true)
        setLoadingButton(null)
      } else {
        createItinerary(() => {
          setShareLinkModalOpen(true)
          setLoadingButton(null)
        })
      }
    }
  }, [fareOffer?.id, createItinerarySuccess?.data?.shareLink, createItinerary, setShareLinkModalOpen])

  const onContinueToRequests = useCallback(() => {
    setLoadingButton('continueToRequests')

    if (createItinerarySuccess?.data?.shareLink) {
      navigate(routes.requests.path)
    } else {
      createItinerary(() => {
        navigate(routes.requests.path)
        setLoadingButton(null)
      })
    }
  }, [navigate, createItinerary, createItinerarySuccess?.data?.shareLink])

  const saveTripSummaryToLocalStorage = useCallback(() => {
    localStorage.setItem(REDIRECT_TO_TRIP_SUMMARY_KEY, true)
    localStorage.setItem(FARE_OFFER_STORAGE_KEY, fareOffer)
    localStorage.setItem(SELECTED_OFFERS_STORAGE_KEY, selectedOffersToUse)
    localStorage.setItem(TRIP_PREFERENCES_STORAGE_KEY, tripPreferences)
    localStorage.setItem(SEARCHED_VALUES_STORAGE_KEY, searchedValues)
    localStorage.setItem(EXISTING_FLIGHT_ITINERARY_KEY, existingFlightItinerary)
    localStorage.setItem(ITINERARY_KEY, itinerary)
    localStorage.setItem(IS_BOOKING_FLIGHT_KEY, isBookingFlight)
    localStorage.setItem(IS_EXTERNAL_BOOKING_KEY, isExternalBooking)
  }, [
    fareOffer,
    selectedOffersToUse,
    tripPreferences,
    searchedValues,
    existingFlightItinerary,
    itinerary,
    isBookingFlight,
    isExternalBooking
  ])

  const onLogin = useCallback(() => {
    navigate(routes.login.path)
    saveTripSummaryToLocalStorage()
  }, [navigate, saveTripSummaryToLocalStorage])

  const onContinueBooking = useCallback(() => {
    const goToBooking = () => {
      const baggagePayload: GetBaggageDetailsRequestPayload = {
        request: {
          'external-system-flight-offer-id': fareOffer.id
        }
      }

      const seatPayload: GetSeatDetailsRequestPayload = {
        request: {
          'external-system-flight-offer-id': fareOffer.id
        }
      }

      setLoadingButton('continueToBooking')

      Promise.allSettled([
        dispatch(getProfile({ request: {} })),
        dispatch(getBaggageDetails(baggagePayload)),
        dispatch(getSeatDetails(seatPayload))
      ]).then((results) => {
        const hasError = results.some(
          (result: PromiseFulfilledResult<unknown> | PromiseRejectedResult) =>
            (result as PromiseFulfilledResult<ReduxPromiseResult>)?.value?.error?.message === 'Rejected'
        )

        if (hasError) {
          setLoadingButton(null)
        } else {
          navigate(routes.flightBooking.path, {
            state: { fareOffer, flightItineraryId: itinerary?.id || flightItineraryId }
          })
        }
      })
    }

    if (existingFlightItinerary) {
      const payload: GetUpdatedFlightOfferRequestPayload = {
        request: {
          id: existingFlightItinerary.id.toString(),
          manualOfferId: fareOffer.id
        },

        onSuccess: () => {
          goToBooking()
        }
      }

      dispatch(getUpdatedFlightOffer(payload))
    } else {
      goToBooking()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fareOffer, navigate, dispatch, itinerary, existingFlightItinerary])

  const onContinuePayout = useCallback(() => {
    navigate(routes.requestVirtualCardForExternalBooking.path, {
      state: { itinerary }
    })
  }, [navigate, itinerary])

  const formatTripChangeDetails = () => {
    if (!fareOffer) return

    const currency = fareOffer.total_currency
    const oldPrice = formatMoney(itinerary?.itinerary?.totalFlightPrice)
    const newPrice = formatMoney(fareOffer.total_amount)
    const hasPriceChanged = oldPrice !== newPrice
    let tripChangeDetails =
      'The flight option selected is no longer available. Please see new option based on your preferences.'

    if (hasPriceChanged) {
      tripChangeDetails += ` The price has changed from ${currency} ${oldPrice} to ${currency} ${newPrice}.`
    }

    tripChangeDetails += ' Continue to Booking to receive your flight discounts.'

    return tripChangeDetails
  }

  const getButtons = () => {
    if (isAuthenticated()) {
      if (isBookingFlight) {
        return (
          <Button
            text="Continue to Booking"
            fullWidth
            variant={'contained'}
            buttonType="primary"
            loading={loadingButton === 'continueToBooking' && (getBaggageDetailsLoading || getSeatDetailsLoading)}
            onClick={onContinueBooking}
          />
        )
      } else if (isExternalBooking) {
        return (
          <Button
            text="Continue to Payout"
            fullWidth
            variant={'contained'}
            buttonType="primary"
            loading={loadingButton === 'continueToPayout'}
            onClick={onContinuePayout}
          />
        )
      } else {
        return (
          <>
            <Button
              text="Continue to Requests"
              fullWidth
              variant={'outlined'}
              buttonType="primary"
              loading={loadingButton === 'continueToRequests' && createItineraryLoading}
              onClick={onContinueToRequests}
            />

            {/* <Button
              text="Skip to Payment"
              fullWidth
              variant={'text'}
              buttonType="primary"
              loading={loadingButton === 'skipToPayment' && createItineraryLoading}
              onClick={onSkipToPayment}
            /> */}
          </>
        )
      }
    } else {
      return <Button text="Login to continue" fullWidth variant={'contained'} buttonType="primary" onClick={onLogin} />
    }
  }

  const getOverviewButton = (): AccordionButtonProps => {
    if (isAuthenticated()) {
      if (isBookingFlight) {
        return {
          text: 'Continue to Booking',
          buttonType: 'primary',
          loading: loadingButton === 'continueToBooking' && (getBaggageDetailsLoading || getSeatDetailsLoading),
          onClick: onContinueBooking
        }
      } else if (isExternalBooking) {
        return {
          text: 'Continue to Payout',
          buttonType: 'primary',
          loading: loadingButton === 'continueToPayout',
          onClick: onContinuePayout
        }
      } else {
        return {
          text: 'Continue to Requests',
          buttonType: 'primary',
          loading: loadingButton === 'continueToRequests' && createItineraryLoading,
          onClick: onContinueToRequests
        }
      }
    } else {
      return {
        text: 'Login to continue',
        buttonType: 'primary',
        onClick: onLogin
      }
    }
  }

  const formattedOffer = useMemo(() => {
    if (isExternalBooking && itinerary) {
      const { offer } = formatSlicesToOffers(itinerary.itinerary)

      return offer
    } else if (fareOffer) {
      const { offers } = formatFlightPartialOffers({
        data: {
          offers: [fareOffer],
          cabin_class: fareOffer.cabinClass
        }
      } as unknown as SearchFlightsSuccessResponse)

      return offers[0]
    } else {
      return null
    }
  }, [fareOffer, isExternalBooking, itinerary])

  const getCountries = useMemo(() => {
    const getCountryData = (countryCode: string | undefined) => {
      if (!countryCode) return null
      const country = getCountryByCode(countryCode)
      if (!country?.name) return null

      return {
        name: country.name,
        iataCode: countryCode
      }
    }

    if (itinerary) {
      return {
        destination: itinerary.itinerary.legs.map((leg) => ({
          name: leg.destination.country.name,
          iataCode: leg.destination.country.iataCode
        })),
        origin: itinerary.itinerary.legs.map((leg) => ({
          name: leg.origin.country.name,
          iataCode: leg.origin.country.iataCode
        }))
      }
    } else if (searchedValues?.flights?.length) {
      if (searchedValues.tripType === 'roundTrip') {
        const firstLeg = {
          destination: getCountryData(searchedValues.flights[0].to?.iata_country_code),
          origin: getCountryData(searchedValues.flights[0].from?.iata_country_code)
        }

        const secondLeg = {
          destination: getCountryData(searchedValues.flights[0].from?.iata_country_code),
          origin: getCountryData(searchedValues.flights[0].to?.iata_country_code)
        }

        return {
          destination: [firstLeg.destination, secondLeg.destination],
          origin: [firstLeg.origin, secondLeg.origin]
        }
      } else {
        const countries = searchedValues.flights.reduce(
          (acc, flight) => {
            const destinationCountry = getCountryData(flight.to?.iata_country_code)
            const originCountry = getCountryData(flight.from?.iata_country_code)

            if (destinationCountry) acc.destination.push(destinationCountry)
            if (originCountry) acc.origin.push(originCountry)

            return acc
          },
          {
            destination: [] as Array<{ name: string; iataCode: string }>,
            origin: [] as Array<{ name: string; iataCode: string }>
          }
        )

        return countries
      }
    }

    return {
      destination: [],
      origin: []
    }
  }, [itinerary, searchedValues])

  const renderContent = () => {
    if (getUpdatedFlightOfferLoading) {
      return (
        <Box sx={styles.loaderContainer}>
          <Loader text="Locating new flight" loading={getUpdatedFlightOfferLoading} marginTop={10} />
        </Box>
      )
    } else if (formattedOffer) {
      return (
        <>
          <Grid item xs={12} sm={9}>
            <Box sx={styles.container}>
              <Typography sx={styles.summaryTitle} variant="h5">
                Trip Summary
              </Typography>

              <Typography sx={styles.summarySubtitle} variant="body2">
                Prices are shown in {formattedOffer.currency} and are inclusive of taxes and fees.
              </Typography>

              {(isTripUpdated || isRenewedOffer) && (
                <>
                  <Alert severity="warning" sx={styles.tripUpdatedAlert}>
                    <Typography sx={styles.tripUpdatedAlertText}>{formatTripChangeDetails()}</Typography>
                  </Alert>

                  <Divider sx={styles.divider} />
                </>
              )}

              <Box sx={styles.tripDetailsContainer}>
                {formattedOffer.slices.map((slice) => (
                  <Box key={slice.id}>
                    <Typography variant="subtitle1" sx={styles.tripDetailsTitle}>
                      {slice.origin.iata_code} → {slice.destination.iata_code}
                    </Typography>

                    <FlightOffer
                      sliceId={slice.id}
                      duration={slice.totalDuration}
                      stopCount={slice.segments.length - 1}
                      airlineLogo={formattedOffer.airlineLogo}
                      originAirportName={slice.origin.iata_code}
                      destinationAirportName={slice.destination.iata_code}
                      departureTime={slice.segments[0].departure.time}
                      arrivalTime={slice.segments[slice.segments.length - 1].arrival.time}
                      cost={formattedOffer.totalAmount.toString()}
                      currency={formattedOffer.currency}
                      travelClass={formattedOffer.travelClass}
                      offer={formattedOffer}
                      hideSelect
                      mode="summary"
                      expanded={true}
                      flightNumber={slice.segments[0].flightNumber}
                      summary={!isDesktop}
                    />
                  </Box>
                ))}
              </Box>

              {!isBookingFlight && !isExternalBooking && itinerary && (
                <ShareFlightItineraryLinkModal
                  shareLink={createItinerarySuccess?.data?.shareLink ?? ''}
                  travelerFirstName={profileData?.firstName ?? ''}
                  destinationCountries={getCountries.destination.map((country) => ({
                    name: country?.name ?? '',
                    iataCode: country?.iataCode ?? ''
                  }))}
                  originCountries={getCountries.origin.map((country) => ({
                    name: country?.name ?? '',
                    iataCode: country?.iataCode ?? ''
                  }))}
                  tripType={searchedValues.tripType}
                  open={shareLinkModalOpen}
                  onClose={() => setShareLinkModalOpen(false)}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}>
            {!selectedOffers && !isExternalBooking && !isBookingFlight && (
              <ShareFlightItineraryLink
                onPress={onCreateItinerary}
                showButton={isAuthenticated()}
                loading={loadingButton === 'createItinerary' && createItineraryLoading}
              />
            )}

            <Box sx={styles.tripOverviewContainer}>
              <TripOverview
                onEditFlight={isBookingFlight || isExternalBooking ? undefined : onEditFlight}
                offerData={fareOffer}
                itinerary={itinerary?.itinerary}
                overviewAccordionButton={getOverviewButton()}
                isExternalBooking={isExternalBooking}
              />
            </Box>

            {isDesktop && getButtons()}
          </Grid>
        </>
      )
    } else if (getBaggageDetailsError || getSeatDetailsError) {
      return (
        <EmptyState
          title="There was an issue with this flight."
          subtitle="Let's try getting you a new one."
          errorId={getBaggageDetailsError?.correlationId || getSeatDetailsError?.correlationId}
          button={{
            text: 'Get a new flight',
            onClick: getNewUpdatedFlightOffer
          }}
        />
      )
    }

    return null
  }

  return (
    <Grid container sx={styles.gridContainer}>
      {renderContent()}

      {!isBookingFlight && !isExternalBooking && (
        <ShareFlightItineraryLinkModal
          shareLink={createItinerarySuccess?.data?.shareLink ?? ''}
          travelerFirstName={profileData?.firstName ?? ''}
          destinationCountries={getCountries.destination.map((country) => ({
            name: country?.name ?? '',
            iataCode: country?.iataCode ?? ''
          }))}
          originCountries={getCountries.origin.map((country) => ({
            name: country?.name ?? '',
            iataCode: country?.iataCode ?? ''
          }))}
          tripType={searchedValues.tripType}
          open={shareLinkModalOpen}
          onClose={() => setShareLinkModalOpen(false)}
        />
      )}
    </Grid>
  )
}

export default TripSummary
