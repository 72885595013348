import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    width: '100%',
    minHeight: 'calc(100vh - 64px - 56px)',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      padding: 0
    }
  },

  container: {
    paddingRight: theme.spacing(4),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      marginTop: theme.spacing(6)
    }
  } as SxProps,

  loaderContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },

  summaryTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    lineHeight: '24px',
    marginBottom: theme.spacing(1)
  } as SxProps,

  summarySubtitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[500],
    lineHeight: '24px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      lineHeight: '20px'
    }
  } as SxProps,

  tripDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4)
  } as SxProps,

  tripDetailsTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2)
  } as SxProps,

  tripOverviewContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.background.default,
      boxShadow: '0px -2px 4px rgba(0,0,0,0.32)'
    }
  } as SxProps,

  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  } as SxProps,

  tripUpdatedAlert: {
    marginBottom: theme.spacing(2)
  } as SxProps,

  tripUpdatedAlertText: {
    fontSize: theme.typography.fontSize
  } as SxProps
}

export default styles
