import React from 'react'

import { useMessaging } from '@/shared/components/Chat/context/Messaging.context'
import { Logout } from '@mui/icons-material'
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'

import Avatar from '../../../Avatar/Avatar.component'
import Button from '../../../Button/Button.component'
import ResponsiveDisplayWrapper from '../../../ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'
import AuthButtons from '../AuthButtons/AuthButtons.component'
import MessagingNotification from '../MessagingNotification/MessagingNotification.component'

import { logout } from '../../../../../redux/states/auth/logout/logout.slice'
import router from '../../../../../router/functions/router.functions'
import routes from '../../../../../router/routes.dictionary'
import logger from '../../../../functions/Logger/logger.functions'
import useMenu from '../../../../hooks/menu.hooks'
import { useAppDispatch } from '../../../../hooks/redux.hooks'
import styles from './ProfileMenu.styles'
import { ProfileMenuProps } from './ProfileMenu.types'

const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isProfileMenuOpen = Boolean(anchorEl)

  const navigate = router.navigate()
  const dispatch = useAppDispatch()
  const messaging = useMessaging()
  const [getAdjustedMarginTop] = useMenu()

  const handleClickProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)

    logger.logEvent(`profile_menu_opened_${router.getCurrentPage().path}`)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    const onSuccess = () => {
      navigate(routes.home.path)
    }

    const onError = () => {
      navigate(routes.login.path)
    }

    dispatch(logout({ onSuccess, onError }))

    messaging.closeChat()
  }

  const menuItems = [
    {
      label: 'Profile',
      onClick: () => {
        handleClose()
        navigate(routes.profile.path)
        logger.logEvent(`profile_option_clicked_${router.getCurrentPage().path}`)
      }
    },

    {
      label: 'Logout',
      onClick: () => {
        handleClose()
        handleLogout()
        logger.logEvent(`logout_option_clicked_${router.getCurrentPage().path}`)
      }
    }
  ]

  const mobileMenuItems = props.userRoleText
    ? [
        {
          label: props.userRoleText,
          onClick: props.onClick
        },
        ...menuItems
      ]
    : menuItems

  const getMenuList = () => {
    if (props.isAuthenticated) {
      return (
        <>
          <ResponsiveDisplayWrapper isDesktop={true}>
            {menuItems.map((item, index) => {
              if (item.label !== 'Logout') {
                return (
                  <MenuItem key={index} onClick={item.onClick}>
                    {item.label}
                  </MenuItem>
                )
              } else {
                return (
                  <Box key={index}>
                    <Divider />

                    <MenuItem key={item.label} onClick={item.onClick}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>

                      {item.label}
                    </MenuItem>
                  </Box>
                )
              }
            })}
          </ResponsiveDisplayWrapper>

          <ResponsiveDisplayWrapper isDesktop={false}>
            {mobileMenuItems.map((item, index) => {
              return (
                <MenuItem divider={index < mobileMenuItems.length - 1} key={index} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              )
            })}
          </ResponsiveDisplayWrapper>
        </>
      )
    }

    return (
      <ResponsiveDisplayWrapper isDesktop={false}>
        <AuthButtons currentPage={router.getCurrentPage()} />
      </ResponsiveDisplayWrapper>
    )
  }

  return (
    <Box sx={props.containerStyle}>
      {props.userRoleText && (
        <ResponsiveDisplayWrapper isDesktop={true}>
          <Button
            buttonType="tertiary"
            text={props.userRoleText}
            isNav
            role="button"
            onClick={props.onClick}
            style={props.buttonIconStyle}
          />
        </ResponsiveDisplayWrapper>
      )}

      <Box sx={styles.rightSideArea}>
        <MessagingNotification />

        <Tooltip title="Account Settings">
          <IconButton
            onClick={handleClickProfileMenu}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={isProfileMenuOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isProfileMenuOpen ? 'true' : undefined}
          >
            <Avatar size="small" />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isProfileMenuOpen}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: styles.paper
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          sx={styles.profileMenu(getAdjustedMarginTop())}
        >
          {getMenuList()}
        </Menu>
      </Box>
    </Box>
  )
}

export default ProfileMenu
