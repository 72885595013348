import React, { useCallback, useEffect, useState } from 'react'

import { Box, Divider, Skeleton, TextField, Typography } from '@mui/material'

import Button from '../Button/Button.component'
import EmptyState from '../EmptyState/EmptyState.component'
import Stepper from '../Stepper/Stepper.component'

import { addItemTrackingNumber } from '../../../redux/states/offers/addItemTrackingNumber/addItemTrackingNumber.slice'
import { AddItemTrackingNumberRequestPayload } from '../../../redux/states/offers/addItemTrackingNumber/addItemTrackingNumber.types'
import { getItemRequestTrackingDetails } from '../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import {
  GetItemRequestTrackingDetailsRequestPayload,
  TrackingDetailsKeys
} from '../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { updateItemRequestTrackingDetails } from '../../../redux/states/offers/updateItemRequestTrackingDetails/updateItemRequestTrackingDetails.slice'
import { UpdateItemRequestTrackingDetailsRequestPayload } from '../../../redux/states/offers/updateItemRequestTrackingDetails/updateItemRequestTrackingDetails.types'
import { RootState } from '../../../redux/store/store.types'
import status from '../../functions/Status/status.dictionaries'
import { UserRole } from '../../functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks'
import useResponsiveness from '../../hooks/responsive.hooks'
import Modal from '../Modal/Modal.functions'
import { getCurrentTrackingDetailsStatus, getStatuses } from './TrackingDetails.functions'
import styles from './TrackingDetails.styles'
import { TrackingDetailsProps, TrackingDetailsStatus } from './TrackingDetails.types'

const TrackingDetails: React.FC<TrackingDetailsProps> = (props: TrackingDetailsProps) => {
  const { itemRequestId, isOpen, destinationCity } = props

  const [visibleStatuses, setVisibleStatuses] = useState<TrackingDetailsStatus[]>([])
  const [currentStatus, setCurrentStatus] = useState<TrackingDetailsKeys | null>(null)
  const [currentStatusIndex, setCurrentStatusIndex] = useState<number>(-1)
  const [isLastStepCompleted, setIsLastStepCompleted] = useState<boolean>(false)
  const [isConfirmedDeliveredByShopper, setIsConfirmedDeliveredByShopper] = useState<boolean>(false)
  const [isConfirmedDeliveredByTraveler, setIsConfirmedDeliveredByTraveler] = useState<boolean>(false)

  const [trackingNumber, setTrackingNumber] = useState<string>('')

  const { loading: getItemRequestTrackingDetailsLoading, success: getItemRequestTrackingDetailsSuccess } =
    useAppSelector((state: RootState) => state.getItemRequestTrackingDetails)

  const { loading: addItemTrackingNumberLoading } = useAppSelector((state: RootState) => state.addItemTrackingNumber)

  const { loading: updateItemRequestTrackingDetailsLoading } = useAppSelector(
    (state: RootState) => state.updateItemRequestTrackingDetails
  )

  const [isDesktop] = useResponsiveness()

  const dispatch = useAppDispatch()

  useEffect(() => {
    const trackingDetails = getItemRequestTrackingDetailsSuccess?.data.trackingDetails

    if (trackingDetails) {
      const statuses = getStatuses(trackingDetails, destinationCity)
      const visibleStatuses = statuses.filter((status) => status.visible)
      const currentStatus = getCurrentTrackingDetailsStatus(visibleStatuses)
      const currentStatusIndex = visibleStatuses.findIndex((status) => status.id === currentStatus)

      const isConfirmedDeliveredByShopper = statuses.some(
        (status) => status.id === TrackingDetailsKeys.ConfirmedDeliveredToShopperByShopper && status.status === true
      )

      const isConfirmedDeliveredByTraveler = statuses.some(
        (status) => status.id === TrackingDetailsKeys.ConfirmedDeliveredToShopperByTraveler && status.status === true
      )

      setVisibleStatuses(visibleStatuses)
      setCurrentStatus(currentStatus)
      setCurrentStatusIndex(currentStatusIndex)
      setIsConfirmedDeliveredByShopper(isConfirmedDeliveredByShopper)
      setIsConfirmedDeliveredByTraveler(isConfirmedDeliveredByTraveler)

      if (isConfirmedDeliveredByShopper) {
        setIsLastStepCompleted(true)
      }
    }
  }, [getItemRequestTrackingDetailsSuccess, destinationCity])

  const handleGetItemRequestTrackingDetails = useCallback(() => {
    const payload: GetItemRequestTrackingDetailsRequestPayload = {
      request: {
        itemRequestId: itemRequestId.toString()
      }
    }

    dispatch(getItemRequestTrackingDetails(payload))
  }, [dispatch, itemRequestId])

  const handleAddItemTrackingNumber = useCallback(
    (trackingNumber: string) => {
      const payload: AddItemTrackingNumberRequestPayload = {
        request: {
          itemRequestId: itemRequestId.toString(),
          trackingNumber: trackingNumber
        },

        onSuccess: () => {
          handleGetItemRequestTrackingDetails()
          setTrackingNumber('')
        }
      }

      dispatch(addItemTrackingNumber(payload))
    },
    [dispatch, handleGetItemRequestTrackingDetails, itemRequestId]
  )

  const handleUpdateTrackingStatus = useCallback(() => {
    if (currentStatus) {
      const isDeliveryConfirmationStatus =
        currentStatus === TrackingDetailsKeys.ConfirmedDeliveredToShopperByTraveler ||
        currentStatus === TrackingDetailsKeys.ConfirmedDeliveredToShopperByShopper

      const payload: UpdateItemRequestTrackingDetailsRequestPayload = {
        request: {
          itemRequestId: itemRequestId.toString(),
          status: status.deliveryStatus[currentStatus as keyof typeof status.deliveryStatus]
        },

        onSuccess: () => {
          handleGetItemRequestTrackingDetails()
        }
      }

      if (isDeliveryConfirmationStatus) {
        const isTraveler = getUserRole() === UserRole.traveler
        const subtitle = isTraveler
          ? 'Are you sure you want to confirm delivery of this item to the shopper?'
          : 'Are you sure you want to confirm receipt of this item from the traveler?'

        Modal.show({
          title: 'Confirm Delivery',
          subtitle,
          primaryButton: {
            label: 'Confirm',
            onClick: () => {
              dispatch(updateItemRequestTrackingDetails(payload))
            }
          },
          secondaryButton: {
            label: 'Dismiss'
          }
        })
      } else {
        dispatch(updateItemRequestTrackingDetails(payload))
      }
    }
  }, [currentStatus, dispatch, handleGetItemRequestTrackingDetails, itemRequestId])

  const renderLoader = () => {
    return (
      <Box sx={styles.loaderContainer}>
        <Skeleton variant="rounded" height={20} width="40%" />

        <Box sx={styles.skeletonStepperContainer}>
          <Box sx={styles.skeletonStepsContainer}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" height={20} width="20%" />
            ))}
          </Box>

          <Skeleton variant="rounded" height={45} width="100%" sx={styles.skeletonButton} />
        </Box>
      </Box>
    )
  }

  const renderTrackingDetails = useCallback(() => {
    const trackingDetails = getItemRequestTrackingDetailsSuccess?.data.trackingDetails
    const steps = visibleStatuses.map((status) => ({ label: status.name }))
    const userRole = getUserRole()

    if (trackingDetails) {
      const getStepContent = () => {
        if (userRole === UserRole.traveler) {
          let buttonText = 'Confirm'
          let buttonDisabled = false

          // TODO: Put these in a grid so that they can go directly below the relevant step in the stepper
          switch (currentStatus) {
            case TrackingDetailsKeys.PurchasedByTraveler:
              return (
                <Box sx={styles.trackingDetailsFormContainer}>
                  <TextField
                    placeholder="Enter Tracking Number"
                    value={trackingNumber}
                    onChange={(event) => setTrackingNumber(event.target.value)}
                    fullWidth
                    sx={styles.trackingDetailsFormInput}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    buttonType="primary"
                    text="Find Order"
                    onClick={() => handleAddItemTrackingNumber(trackingNumber)}
                    disabled={trackingNumber?.length < 1}
                    loading={addItemTrackingNumberLoading}
                    style={styles.trackingDetailsFormButton}
                  />
                </Box>
              )

            case TrackingDetailsKeys.ConfirmedDeliveredToShopperByTraveler:
              if (isConfirmedDeliveredByTraveler) {
                if (!isConfirmedDeliveredByShopper) {
                  buttonText = isDesktop ? 'Awaiting confirmation from shopper' : 'Awaiting shopper confirmation'
                  buttonDisabled = true
                } else {
                  buttonText = 'Item Delivered'
                  buttonDisabled = true
                }
              } else {
                buttonText = 'Confirm'
                buttonDisabled = false
              }

              return (
                <Box sx={styles.trackingStatusActionContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    loading={updateItemRequestTrackingDetailsLoading}
                    fullWidth
                    buttonType="primary"
                    text={buttonText}
                    disabled={buttonDisabled}
                    onClick={handleUpdateTrackingStatus}
                  />
                </Box>
              )

            default:
              return (
                <Box sx={styles.trackingStatusActionContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    loading={updateItemRequestTrackingDetailsLoading}
                    fullWidth
                    buttonType="primary"
                    text={buttonText}
                    onClick={handleUpdateTrackingStatus}
                  />
                </Box>
              )
          }
        } else {
          let buttonText = 'Confirm'
          let buttonDisabled = false

          switch (currentStatus) {
            case TrackingDetailsKeys.ConfirmedDeliveredToShopperByShopper:
              if (isConfirmedDeliveredByTraveler) {
                if (isConfirmedDeliveredByShopper) {
                  buttonText = 'Item Delivered'
                  buttonDisabled = true
                } else {
                  buttonText = 'Confirm Delivery'
                  buttonDisabled = false
                }
              } else {
                buttonText = 'Confirm Delivery'
                buttonDisabled = true
              }

              return (
                <Box sx={styles.trackingStatusActionContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    loading={updateItemRequestTrackingDetailsLoading}
                    fullWidth
                    buttonType="primary"
                    text={buttonText}
                    disabled={buttonDisabled}
                    onClick={handleUpdateTrackingStatus}
                    style={styles.trackingDetailsFormButton}
                    tooltipText={!isConfirmedDeliveredByTraveler ? 'Awaiting confirmation from traveler' : undefined}
                  />
                </Box>
              )

            default:
              return null
          }
        }
      }

      return (
        <Box sx={styles.trackingDetailsContainer}>
          <Typography sx={styles.trackingDetailsTitle}>
            {userRole === UserRole.traveler
              ? 'Update the tracker below to inform the shopper'
              : 'View status updates for this item'}
          </Typography>

          <Box sx={styles.trackingDetailsStepperContainer(!!getStepContent())}>
            <Stepper
              activeStep={currentStatusIndex}
              steps={steps}
              noBackground
              isLastStepCompleted={isLastStepCompleted}
              orientation={isDesktop ? 'horizontal' : 'vertical'}
            >
              {getStepContent()}
            </Stepper>
          </Box>
        </Box>
      )
    }
  }, [
    getItemRequestTrackingDetailsSuccess?.data.trackingDetails,
    visibleStatuses,
    currentStatusIndex,
    isLastStepCompleted,
    currentStatus,
    trackingNumber,
    addItemTrackingNumberLoading,
    handleUpdateTrackingStatus,
    updateItemRequestTrackingDetailsLoading,
    handleAddItemTrackingNumber,
    isConfirmedDeliveredByShopper,
    isConfirmedDeliveredByTraveler,
    isDesktop
  ])

  const renderError = () => {
    return (
      <Box sx={styles.errorContainer}>
        <EmptyState
          title="Error"
          subtitle="There was an error loading the tracking details."
          button={{
            text: 'Try again',
            onClick: () => {
              const payload: GetItemRequestTrackingDetailsRequestPayload = {
                request: {
                  itemRequestId: itemRequestId.toString()
                }
              }

              dispatch(getItemRequestTrackingDetails(payload))
            }
          }}
        />
      </Box>
    )
  }

  const renderContent = () => {
    let content = null

    if (getItemRequestTrackingDetailsLoading) {
      content = renderLoader()
    } else if (visibleStatuses.length > 0) {
      content = renderTrackingDetails()
    } else {
      content = renderError()
    }

    return <Box sx={styles.contentContainer}>{content}</Box>
  }

  if (isOpen) {
    return (
      <Box sx={styles.container}>
        <Divider sx={styles.divider} />
        {renderContent()}
      </Box>
    )
  } else {
    return null
  }
}

export default TrackingDetails
