import { configureRequest } from '../../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../../networkRequests/apiClient/env.config'
import { UserRole } from '../../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAllAssignedVirtualCardsForAdminErrorResponse,
  GetAllAssignedVirtualCardsForAdminRequest,
  GetAllAssignedVirtualCardsForAdminSuccessResponse
} from './getAllAssignedVirtualCardsForAdmin.types'

export const getAllAssignedVirtualCardsForAdminApi = async (
  request: GetAllAssignedVirtualCardsForAdminRequest
): Promise<GetAllAssignedVirtualCardsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: urls.admin.getAllAssignedVirtualCardsForAdmin,
    method: 'GET',
    data: request,
    params: {
      searchQuery: request.searchQuery ?? '',
      status: request.status ?? '',
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllAssignedVirtualCardsForAdminSuccessResponse
  } else {
    throw response as GetAllAssignedVirtualCardsForAdminErrorResponse
  }
}

export default getAllAssignedVirtualCardsForAdminApi
