import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createExternalFlightBookingPaymentIntentApi } from './createExternalFlightBookingPaymentIntent.api'
import createExternalFlightBookingPaymentIntentInitialState from './createExternalFlightBookingPaymentIntent.initialState'
import {
  CreateExternalFlightBookingPaymentIntentErrorResponse,
  CreateExternalFlightBookingPaymentIntentRequestPayload
} from './createExternalFlightBookingPaymentIntent.types'

export const createExternalFlightBookingPaymentIntent = createAsyncThunk(
  urls.payments.createExternalFlightBookingPaymentIntent,
  async (payload: CreateExternalFlightBookingPaymentIntentRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await createExternalFlightBookingPaymentIntentApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const createExternalFlightBookingPaymentIntentSlice = createSlice({
  name: urls.payments.createExternalFlightBookingPaymentIntent,
  initialState: createExternalFlightBookingPaymentIntentInitialState,
  reducers: {
    resetCreateExternalFlightBookingPaymentIntent: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createExternalFlightBookingPaymentIntent.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createExternalFlightBookingPaymentIntent.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createExternalFlightBookingPaymentIntent.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateExternalFlightBookingPaymentIntentErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: createExternalFlightBookingPaymentIntentActions,
  reducer: createExternalFlightBookingPaymentIntentReducer
} = createExternalFlightBookingPaymentIntentSlice
export const { resetCreateExternalFlightBookingPaymentIntent } = createExternalFlightBookingPaymentIntentActions
export default createExternalFlightBookingPaymentIntentSlice.reducer
