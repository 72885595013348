import React from 'react'

import { ReactComponent } from '@/shared/types/react.types'
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

import styles from './Link.styles'
import { LinkProps } from './Link.types'

const CustomLink = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props

    return <RouterLink ref={ref} to={`/${href}`} {...other} />
  }
)

const Link: ReactComponent<MuiLinkProps & LinkProps> = (props, _ref) => {
  const { href, showColor, isUnderlined, ...other } = props
  const isExternal = href?.startsWith('http') || href?.startsWith('www')

  if (href === undefined) {
    return <MuiLink sx={styles.link(showColor, isUnderlined)} {...other} />
  } else {
    if (isExternal) {
      return <MuiLink href={href} sx={styles.link(showColor, isUnderlined)} {...other} />
    } else {
      return <MuiLink component={CustomLink} sx={styles.link(showColor, isUnderlined)} {...props} />
    }
  }
}

export default Link
