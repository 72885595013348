import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetOfferRequestTripDetailsForAdminErrorResponse,
  GetOfferRequestTripDetailsForAdminRequest,
  GetOfferRequestTripDetailsForAdminSuccessResponse
} from './getOfferRequestTripDetailsForAdmin.types'

export const getOfferRequestTripDetailsForAdminApi = async (
  request: GetOfferRequestTripDetailsForAdminRequest
): Promise<GetOfferRequestTripDetailsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getOfferRequestTripDetailsForAdmin, {
      offerRequestId: request.offerRequestId
    }),
    method: 'GET',
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetOfferRequestTripDetailsForAdminSuccessResponse
  } else {
    throw response as GetOfferRequestTripDetailsForAdminErrorResponse
  }
}

export default getOfferRequestTripDetailsForAdminApi
