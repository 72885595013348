import { Calendar } from '@/shared/components/shadcn/ui/calendar'
import theme from '@/shared/styles/themes/default.theme'
import { Close } from '@mui/icons-material'
import { Box, IconButton, Modal, Typography } from '@mui/material'

import styles from './DatePicker.styles'
import { DateRangePickerProps } from './DatePicker.types'

// TODO: Add make a single date picker component
const DateRangePicker = (props: DateRangePickerProps) => {
  const { open, onClose, setDate, toDate, onBlur, onFocus, disablePast, date, error } = props

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.container(!!error)}>
        <Box sx={styles.header}>
          <Typography variant="h6" component="h2">
            Select Date Range
          </Typography>

          <IconButton sx={styles.closeIcon} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={setDate}
          numberOfMonths={2}
          toDate={toDate}
          fromDate={disablePast ? new Date() : undefined}
          onDayBlur={onBlur}
          onDayFocus={onFocus}
          modifiersStyles={{
            selected: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText
            },
            range_end: {
              borderTopRightRadius: theme.shape.borderRadius,
              borderBottomRightRadius: theme.shape.borderRadius
            },
            range_start: {
              borderTopLeftRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: theme.shape.borderRadius
            },
            disabled: {
              color: theme.palette.text.disabled
            },
            today: {
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: theme.shape.borderRadius
            }
          }}
        />

        {error && <Typography sx={styles.error}>{error}</Typography>}
      </Box>
    </Modal>
  )
}

export default DateRangePicker
