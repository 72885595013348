import React, { useCallback, useEffect, useState } from 'react'

import { SHARED_OFFER_DETAILS_KEY } from '@/pages/Offers/Offers.dictionary'
import { REDIRECT_TO_OFFERS_PAGE_TO_ADD_EXISTING_FLIGHT_KEY } from '@/pages/TripSummary/TripSummary.dictionary'
import { isAuthenticated } from '@/shared/functions/Auth/auth.functions'
import { TripType } from '@/shared/functions/Flights/flights.types'
import localStorage from '@/shared/functions/LocalStorage/localStorage'
import { formatMoney } from '@/shared/functions/String/string.functions'
import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { Box, Typography } from '@mui/material'

import AcceptOffer from '../../../../pages/Offers/components/AcceptOffer/AcceptOffer.component'
import Avatar from '../../Avatar/Avatar.component'
import Button from '../../Button/Button.component'
import RequestAddExistingFlightModal from '../../RequestAddExistingFlightModal/RequestAddExistingFlightModal.component'

import { validateOfferAcceptance } from '../../../../pages/Offers/components/OfferItem/OfferItem.functions'
import { getFormattedTripDetails } from '../../../../pages/Requests/Requests.functions'
import { FlightItinerary } from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { acceptPublicOffer } from '../../../../redux/states/offerRequest/acceptPublicOffer/acceptPublicOffer.slice'
import { OfferRequest } from '../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import Modal from '../../../components/Modal/Modal.functions'
import Snackbar from '../../../components/Snackbar/Snackbar.functions'
import date, { dateTimeFormats } from '../../../functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks'
import { RequestAddExistingFlightFormValues } from '../../RequestAddExistingFlightModal/RequestAddExistingFlightModal.types'
import styles from './OfferDealItem.styles'
import { OfferDealItemProps } from './OfferDealItem.types'

const OfferDealItem: React.FC<OfferDealItemProps> = ({ deal, flightItineraries }) => {
  const [offerAccepted, setOfferAccepted] = useState(false)
  const [loadingOfferId, setLoadingOfferId] = useState<number | null>(null)
  const [isAddExistingFlightModalOpen, setIsAddExistingFlightModalOpen] = useState(false)
  const [acceptButtonPressed, setAcceptButtonPressed] = useState(false)

  const { loading: acceptPublicOfferLoading } = useAppSelector((state: RootState) => state.acceptPublicOffer)
  const { loading: getAllFlightItinerariesLoading } = useAppSelector(
    (state: RootState) => state.getAllFlightItineraries
  )

  const [isDesktop] = useResponsiveness()

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const onConfirm = useCallback(
    (offer: OfferRequest, flightItinerary: FlightItinerary, onOfferAccepted?: () => void) => {
      setLoadingOfferId(offer.offerRequestId)
      dispatch(
        acceptPublicOffer({
          acceptPublicOfferRequest: {
            offerRequestId: offer.offerRequestId,
            confirmationFlightItineraryId: flightItinerary.id,
            isConfirming: true
          },
          onSuccess: () => {
            Snackbar.show({
              message: 'Offer successfully accepted! Go to the Requests page to see your accepted offers.',
              action: {
                label: 'Go to Requests',
                onClick: () => {
                  navigate(routes.requests.path)
                  window.scrollTo(0, 0)
                }
              },
              severity: 'success'
            })
            onOfferAccepted?.()
            setLoadingOfferId(null)
            setOfferAccepted(true)
          }
        })
      )
    },
    [navigate, dispatch]
  )

  const handleAddExistingFlightSubmit = (_values: RequestAddExistingFlightFormValues) => {
    setIsAddExistingFlightModalOpen(false)
  }

  const onAccepOfferValidation = useCallback(() => {
    const items = deal.offerRequestDetails.map((item) => item.itemDetails)
    const deliveryLocation = deal.shopperDetails.deliveryTo
    const validation = validateOfferAcceptance(flightItineraries, items, deliveryLocation, deal)

    if (validation.noMatchingFlightItineraries) {
      const neededByDate = deal.neededByDate ? `by ${date(deal.neededByDate).format(dateTimeFormats.date.medium)}` : ''

      // Show "Book Flight" confirmation modal
      Modal.show({
        title: 'Book Flight',
        subtitle: (
          <span>
            You are required to have a flight leg from{' '}
            <span style={styles.linkText}>{validation.requiredCountries.itemLocation.name}</span> to{' '}
            <span style={styles.linkText}>
              {validation.requiredCountries.deliveryLocation.city}, {validation.requiredCountries.deliveryLocation.name}
            </span>{' '}
            {neededByDate && (
              <span>
                arriving by <span style={styles.linkText}>{neededByDate}</span>
              </span>
            )}{' '}
            to accept this offer.
          </span>
        ),
        primaryButton: {
          label: 'Book Flight',
          onClick: () => {
            navigate(routes.flightSearchResults.path, {
              state: {
                requiredCountries: validation.requiredCountries,
                selectedOffer: deal
              }
            })
          }
        },
        secondaryButton: {
          label: 'Add My Flight',
          onClick: () => {
            if (!isAuthenticated()) {
              const sharedOfferDetails = {
                offerRequestId: deal.offerRequestId,
                shopperId: deal.shopperDetails.userId
              }

              localStorage.setItem(SHARED_OFFER_DETAILS_KEY, sharedOfferDetails)
              localStorage.setItem(REDIRECT_TO_OFFERS_PAGE_TO_ADD_EXISTING_FLIGHT_KEY, true)
              navigate(routes.login.path)

              return
            } else {
              setIsAddExistingFlightModalOpen(true)
            }
          }
        },
        tertiaryButton: {
          label: 'Cancel'
        }
      })
    } else if (!validation.noMatchingFlightItineraries && !validation.multipleOptions) {
      Modal.show({
        title: 'Select Offer',
        subtitle: `This offer will be assigned to the following itinerary:`,
        body: (
          <Box>
            <Typography variant="body1">
              {getFormattedTripDetails(
                validation.flightItineraries[0].itinerary.legs,
                validation.flightItineraries[0].itinerary.tripType as TripType
              )}

              {`\u00A0 (${date(validation.flightItineraries[0].itinerary.legs[0].travelDate).format(
                dateTimeFormats.date.medium
              )})`}
            </Typography>
          </Box>
        ),
        primaryButton: {
          label: 'Confirm',
          onClick: () => {
            onConfirm(deal, validation.flightItineraries[0])
          }
        },
        secondaryButton: {
          label: 'Close'
        }
      })
    } else if (validation.multipleOptions) {
      // Show "Choose Flight" confirmation modal (if multiple options)
      let selectedFlightItineraryId: number | null = null

      const handleConfirm = () => {
        if (selectedFlightItineraryId !== null) {
          const flightItinerary = validation.flightItineraries?.find(
            (flightItinerary) => flightItinerary.id === selectedFlightItineraryId
          )

          if (flightItinerary) {
            onConfirm(deal, flightItinerary)
          } else {
            Snackbar.show({
              message: 'Please select a valid flight booking to select the offer.',
              severity: 'error'
            })
          }
        }
      }

      Modal.show({
        title: 'Choose Flight',
        subtitle: `You have multiple flight bookings available. Please choose one to select the offer.`,
        body: (
          <AcceptOffer
            response={validation}
            onConfirm={(flightItineraryId) => {
              selectedFlightItineraryId = flightItineraryId
            }}
          />
        ),
        primaryButton: {
          label: 'Confirm',
          onClick: handleConfirm
        },
        secondaryButton: {
          label: 'Close'
        }
      })
    }

    return validation
  }, [deal, navigate, flightItineraries, onConfirm])

  // Effect to trigger validation after loading completes
  useEffect(() => {
    if (acceptButtonPressed && !getAllFlightItinerariesLoading) {
      onAccepOfferValidation()
      setAcceptButtonPressed(false)
    }
  }, [acceptButtonPressed, getAllFlightItinerariesLoading, onAccepOfferValidation])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.userInfoPill}>
        {isDesktop && (
          <Avatar firstName={deal.shopperDetails.firstName} lastName={deal.shopperDetails.lastName} size="medium" />
        )}

        <Box sx={styles.userInfoContainer}>
          <Typography variant={isDesktop ? 'body1' : 'body2'} sx={styles.userName}>
            {deal.shopperDetails.firstName} {deal.shopperDetails.lastName.charAt(0)}
          </Typography>

          <Typography variant={isDesktop ? 'body2' : 'caption'} sx={styles.location}>
            {`${deal.offerRequestDetails[0].itemDetails.country.name} to ${deal.shopperDetails.deliveryTo.city}, ${deal.shopperDetails.deliveryTo.country}`}
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.imageContainer}>
        <img
          src={deal.offerRequestDetails[0].itemDetails.images[0]}
          alt={deal.shopperDetails.firstName}
          style={styles.image}
        />
      </Box>

      <Box sx={styles.spacer} />

      <Box sx={styles.carrigeInfoContainer}>
        <Typography variant="body1" sx={styles.itemsCount}>
          Carry {deal.offerRequestDetails.length} item{deal.offerRequestDetails.length > 1 ? 's' : ''}
        </Typography>

        <Typography variant="body2" sx={styles.discount}>
          {formatMoney(deal.travelerBenefit, deal.offerRequestDetails[0].itemDetails.currency)}{' '}
          <Box component="span" sx={styles.discountText}>
            off Flight
          </Box>
        </Typography>
      </Box>

      <Box sx={styles.buttonContainer}>
        <Button
          sx={styles.bookButton}
          text={offerAccepted ? 'Offer Accepted' : 'Book now'}
          fullWidth
          variant={'outlined'}
          buttonType={'primary'}
          onClick={() => setAcceptButtonPressed(true)}
          loading={
            (acceptPublicOfferLoading && loadingOfferId === deal.offerRequestId) ||
            (getAllFlightItinerariesLoading && acceptButtonPressed)
          }
          disabled={offerAccepted || deal.isCurrentUserShopper}
          tooltipText={deal.isCurrentUserShopper ? 'You are not allowed to book your own offer.' : undefined}
        />
      </Box>

      <RequestAddExistingFlightModal
        open={isAddExistingFlightModalOpen}
        onClose={() => setIsAddExistingFlightModalOpen(false)}
        onSubmit={handleAddExistingFlightSubmit}
        offerRequest={deal}
      />
    </Box>
  )
}

export default OfferDealItem
