import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Box, Card, CardContent, Typography } from '@mui/material'
import { isValidPhoneNumber } from 'libphonenumber-js'
import _ from 'lodash'
import * as Yup from 'yup'

import Form from '../../../../shared/components/Form/Form.component'
import BaggageDetails from './components/BaggageDetails/BaggageDetails.component'
import SeatDetails from './components/SeatDetails/SeatDetails.component'

import env from '../../../../networkRequests/apiClient/env.config'
import { getBaggageDetails } from '../../../../redux/states/flights/getBaggageDetails/getBaggageDetails.slice'
import { GetBaggageDetailsRequestPayload } from '../../../../redux/states/flights/getBaggageDetails/getBaggageDetails.types'
import { getSeatDetails } from '../../../../redux/states/flights/getSeatDetails/getSeatDetails.slice'
import { GetSeatDetailsRequestPayload } from '../../../../redux/states/flights/getSeatDetails/getSeatDetails.types'
import { FormSection } from '../../../../shared/components/Form/Form.types'
import date from '../../../../shared/functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import styles from './PassengerDetails.styles'
import { PassengerDetailsProps, PassengerDetailsValues } from './PassengerDetails.types'

const PassengerDetails: React.FC<PassengerDetailsProps> = (props) => {
  const {
    fareOffer,
    onFormChange,
    onBaggageSelectionsChange,
    onSeatSelectionsChange,
    countriesAndCities,
    userProfile
  } = props

  const { success: baggageDetailsResponse } = useAppSelector((state) => state.getBaggageDetails)
  const { success: seatDetailsResponse } = useAppSelector((state) => state.getSeatDetails)

  const dispatch = useAppDispatch()

  const onLoadBaggageDetails = useCallback(() => {
    if (baggageDetailsResponse) {
      return
    }

    const payload: GetBaggageDetailsRequestPayload = {
      request: {
        'external-system-flight-offer-id': fareOffer.id
      },
      onSuccess: () => {
        //
      }
    }

    dispatch(getBaggageDetails(payload))
  }, [dispatch, fareOffer.id, baggageDetailsResponse])

  const onLoadSeatDetails = useCallback(() => {
    if (seatDetailsResponse) {
      return
    }

    const payload: GetSeatDetailsRequestPayload = {
      request: {
        'external-system-flight-offer-id': fareOffer.id
      },
      onSuccess: () => {
        //
      }
    }

    dispatch(getSeatDetails(payload))
  }, [dispatch, fareOffer.id, seatDetailsResponse])

  useEffect(() => {
    onLoadBaggageDetails()
    onLoadSeatDetails()
  }, [onLoadBaggageDetails, onLoadSeatDetails])

  const [passengers, setPassengers] = useState<PassengerDetailsValues[]>(
    fareOffer?.passengers.map((passenger, index) => {
      let dateOfBirth = ''

      if (index === 0 && userProfile?.dateOfBirth) {
        dateOfBirth = date(userProfile.dateOfBirth).format('yyyy-MM-dd') ?? ''
      }

      return {
        id: passenger.id,
        title: '',
        firstName: index === 0 ? userProfile?.firstName ?? '' : '',
        lastName: index === 0 ? userProfile?.lastName ?? '' : '',
        dateOfBirth,
        gender: '',
        phoneNumber: index === 0 ? userProfile?.mobileNumber ?? '' : '',
        passportNumber: '',
        issuingCountry: '',
        expirationDate: ''
      }
    })
  )

  const getValidationSchema = (currentPassengerId: string) => {
    return Yup.object().shape({
      title: Yup.string().required('Title is required'),
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      dateOfBirth: Yup.date()
        .required('Date of birth is required')
        .test('date-of-birth', function (value, validationContext) {
          const dateOfBirth = date(value.toISOString())
          const departureDate = date(fareOffer.slices[0].segments[0].departing_at)
          const returnFlightDate = date(
            fareOffer.slices[fareOffer.slices.length - 1].segments[
              fareOffer.slices[fareOffer.slices.length - 1].segments.length - 1
            ].arriving_at
          )

          const passenger = fareOffer.passengers.find((passenger) => passenger.id === currentPassengerId)
          const calculatedAgeAtReturnFlight = date().calculateAge(dateOfBirth.dateTime, returnFlightDate.dateTime)
          const age = passenger?.age ?? calculatedAgeAtReturnFlight
          const childPassportAgeLimit = parseInt(env.PASSPORT_CHILD_AGE_LIMIT)

          switch (passenger?.type) {
            case 'child':
            case 'infant_without_seat':
              if (passenger?.age && Math.floor(passenger?.age) !== Math.floor(calculatedAgeAtReturnFlight)) {
                return validationContext.createError({
                  message: `Child must be the same age for entire journey. The age provided at booking (age: ${passenger?.age}) and at return flight (age: ${calculatedAgeAtReturnFlight}) does not match.`
                })
              } else if (age >= childPassportAgeLimit) {
                return validationContext.createError({
                  message: `Child must be younger than ${childPassportAgeLimit} years old`
                })
              } else {
                if (Math.floor(calculatedAgeAtReturnFlight) !== Math.floor(age)) {
                  return validationContext.createError({
                    message: `Child must be the same age for entire journey. Please use child's age as of the date of the returning flight.`
                  })
                }
              }
              break

            case 'adult':
              if (age < childPassportAgeLimit) {
                return validationContext.createError({
                  message: `Adult must be at least ${childPassportAgeLimit} years old`
                })
              }
              break
          }

          if (!value || !departureDate) return false

          if (date(dateOfBirth.dateTime).isAfter(departureDate.dateTime)) {
            return validationContext.createError({ message: 'Date of birth must be before the departure date' })
          }

          return true
        }),
      gender: Yup.string().required('Gender is required'),
      phoneNumber: Yup.string()
        .required('Phone number is required')
        .test('phone-number', 'Phone number must be a valid phone number', (value) => {
          if (!value) return false

          return isValidPhoneNumber(value)
        }),
      passportNumber: Yup.string()
        .required('Passport number is required')
        .matches(/^[A-Z0-9]+$/, 'Passport number can only contain uppercase letters and numbers')
        .test(
          'unique-passport',
          'Passport number must be unique for each passenger',
          function (value, validationContext) {
            if (!value) return false

            // Check if this passport number is used by any other passenger
            const isDuplicate = passengers.some(
              (passenger) =>
                passenger.id !== currentPassengerId && passenger.passportNumber?.toUpperCase() === value.toUpperCase()
            )

            if (isDuplicate) {
              return validationContext.createError({
                message: 'This passport number is already used by another passenger'
              })
            }

            return true
          }
        ),
      issuingCountry: Yup.string().required('Issuing country is required'),
      expirationDate: Yup.date()
        .required('Expiration date is required')
        .test('expiration-date', function (value, validationContext) {
          const expirationDate = date(value.toISOString())
          const lastSlice = fareOffer.slices[fareOffer.slices.length - 1]
          const lastSegment = lastSlice.segments[lastSlice.segments.length - 1]
          const arrivalDate = date(lastSegment.arriving_at)
          const currentPassenger = passengers.find((passenger) => passenger.id === currentPassengerId)

          if (date(expirationDate.dateTime.startOf('day')).isSameOrBefore(arrivalDate.dateTime.startOf('day'))) {
            return validationContext.createError({
              message: 'Expiration date must be after the last arrival date of the trip'
            })
          }

          if (date(expirationDate.dateTime).isBefore(date().dateTime)) {
            return validationContext.createError({ message: 'Expiration date must be a future date' })
          }

          if (date(expirationDate.dateTime).isBefore(date(currentPassenger?.dateOfBirth ?? '').dateTime)) {
            return validationContext.createError({ message: 'Expiration date must be after the date of birth' })
          }

          return true
        })
    })
  }

  const handleFormChange = useCallback(
    (values: PassengerDetailsValues, passengerId: string, errors: unknown) => {
      setPassengers((prevPassengers) => {
        const updatedPassengers = prevPassengers.map((passenger) =>
          passenger.id === passengerId ? { ...passenger, ...values } : passenger
        )

        const hasChanged = !_.isEqual(prevPassengers, updatedPassengers)

        const allValid = updatedPassengers.every(
          (p, index) =>
            p.title &&
            p.firstName &&
            p.lastName &&
            p.dateOfBirth &&
            p.gender &&
            (index === 0 ? p.phoneNumber : true) &&
            p.passportNumber &&
            p.issuingCountry &&
            p.expirationDate
        )

        const noErrors = errors ? Object.keys(errors).length === 0 : true

        const isFormValid = allValid && noErrors

        if (!hasChanged) {
          onFormChange(prevPassengers, isFormValid)

          return prevPassengers
        } else {
          onFormChange(updatedPassengers, isFormValid)

          return updatedPassengers
        }
      })
    },
    [onFormChange]
  )

  const getFormSections = (passengerIndex: number): FormSection[] => [
    {
      title: 'Personal Information',
      fields: [
        {
          name: 'title',
          label: 'Title',
          type: 'select',
          options: [
            { value: 'mr', label: 'Mr.' },
            { value: 'mrs', label: 'Mrs.' },
            { value: 'ms', label: 'Ms.' },
            { value: 'dr', label: 'Dr.' }
          ],
          grid: { sm: 12, md: 2, xs: 12 },
          required: true,
          fullWidth: true
        },
        {
          name: 'firstName',
          label: 'First Name',
          type: 'text',
          grid: { sm: 12, md: 5, xs: 12 },
          required: true,
          fullWidth: true,
          autoFocus: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          type: 'text',
          grid: { sm: 12, md: 5, xs: 12 },
          required: true,
          fullWidth: true
        },
        {
          name: 'dateOfBirth',
          label: 'Date of Birth',
          type: 'date',
          grid: { sm: 12, md: 6, xs: 12 },
          required: true,
          fullWidth: true
        },
        {
          name: 'gender',
          label: 'Gender',
          type: 'select',
          options: [
            { value: 'm', label: 'Male' },
            { value: 'f', label: 'Female' }
          ],
          grid: { sm: 12, md: 6, xs: 12 },
          required: true,
          fullWidth: true
        },
        // Only include phone number field for first passenger
        ...(passengerIndex === 0
          ? [
              {
                name: 'phoneNumber',
                label: 'Phone Number',
                type: 'tel',
                grid: { sm: 12, md: 12, xs: 12 },
                required: true,
                fullWidth: true
              }
            ]
          : [])
      ]
    },
    {
      title: 'Passport Information',
      fields: [
        {
          name: 'passportNumber',
          label: 'Passport Number',
          type: 'text',
          grid: { sm: 12, md: 6, xs: 12 },
          required: true,
          fullWidth: true,
          preventAutoFocus: true
        },
        {
          name: 'issuingCountry',
          label: 'Issuing Country',
          type: 'select',
          options: countriesAndCities?.map((country) => ({
            value: country.iataCountryCode,
            label: country.countryName
          })),
          grid: { sm: 12, md: 6, xs: 12 },
          required: true,
          fullWidth: true
        },
        {
          name: 'expirationDate',
          label: 'Expiration Date',
          type: 'date',
          grid: { sm: 12, md: 12, xs: 12 },
          required: true,
          fullWidth: true
        }
      ]
    }
  ]

  return (
    <Box sx={styles.container}>
      {passengers.map((passenger, index) => (
        <Card key={passenger.id} sx={styles.passengerCard(passengers.length)}>
          <CardContent>
            <Typography variant="h6" sx={styles.passengerTitle}>
              Passenger {index + 1} Details
            </Typography>

            <Alert severity="warning" sx={styles.warningBox}>
              <Typography sx={styles.warningText}>
                Please ensure the personal details and passport details are for the passenger.
              </Typography>
            </Alert>

            <Form
              hideButtons={true}
              buttonText=""
              initialValues={passenger}
              validationSchema={getValidationSchema(passenger.id)}
              onSubmit={() => {
                // Handle form submission if necessary
              }}
              formSections={getFormSections(index)}
              onFormChange={(values) => {
                handleFormChange(values.values as PassengerDetailsValues, passenger.id, values.errors)
              }}
            />
          </CardContent>
        </Card>
      ))}

      <BaggageDetails onBaggageSelectionsChange={onBaggageSelectionsChange} fareOffer={fareOffer} />
      <SeatDetails fareOffer={fareOffer} onSeatSelectionsChange={onSeatSelectionsChange} />
    </Box>
  )
}

export default PassengerDetails
