import { SxProps } from '@mui/material'

const styles = {
  container: {
    p: 2,
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 1
  } as SxProps,

  tabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    width: '100%'
  },

  tabPanel: {
    marginTop: 2
  }
}

export default styles
