import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getTripOfferRequestsForAdminApi } from './getTripOfferRequestsForAdmin.api'
import getTripOfferRequestsForAdminInitialState from './getTripOfferRequestsForAdmin.initialState'
import {
  GetTripOfferRequestsForAdminErrorResponse,
  GetTripOfferRequestsForAdminRequestPayload
} from './getTripOfferRequestsForAdmin.types'

export const getTripOfferRequestsForAdmin = createAsyncThunk(
  urls.admin.getTripOfferRequestDetailsForAdmin,
  async (payload: GetTripOfferRequestsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getTripOfferRequestsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getTripOfferRequestsForAdminSlice = createSlice({
  name: urls.admin.getTripOfferRequestDetailsForAdmin,
  initialState: getTripOfferRequestsForAdminInitialState,
  reducers: {
    resetGetTripOfferRequestsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTripOfferRequestsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getTripOfferRequestsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getTripOfferRequestsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetTripOfferRequestsForAdminErrorResponse
        state.success = null
      })
  }
})

export const { actions: getTripOfferRequestsForAdminActions, reducer: getTripOfferRequestsForAdminReducer } =
  getTripOfferRequestsForAdminSlice
export const { resetGetTripOfferRequestsForAdmin } = getTripOfferRequestsForAdminActions
export default getTripOfferRequestsForAdminSlice.reducer
