import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../networkRequests/apiClient/env.config'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetUserTripsForAdminErrorResponse,
  GetUserTripsForAdminRequest,
  GetUserTripsForAdminSuccessResponse
} from './getUserTripsForAdmin.types'

export const getUserTripsForAdminApi = async (
  request: GetUserTripsForAdminRequest
): Promise<GetUserTripsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getUserTripsForAdmin, {
      userId: request.userId
    }),
    method: 'GET',
    params: {
      tripStatus: request.tripStatus ?? '',
      searchQuery: request.searchQuery ?? '',
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetUserTripsForAdminSuccessResponse
  } else {
    throw response as GetUserTripsForAdminErrorResponse
  }
}

export default getUserTripsForAdminApi
