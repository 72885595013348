import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getOfferRequestPaymentDetailsForAdminApi } from './getOfferRequestPaymentDetailsForAdmin.api'
import getOfferRequestPaymentDetailsForAdminInitialState from './getOfferRequestPaymentDetailsForAdmin.initialState'
import {
  GetOfferRequestPaymentDetailsForAdminErrorResponse,
  GetOfferRequestPaymentDetailsForAdminRequestPayload
} from './getOfferRequestPaymentDetailsForAdmin.types'

export const getOfferRequestPaymentDetailsForAdmin = createAsyncThunk(
  urls.admin.getOfferRequestPaymentDetailsForAdmin,
  async (payload: GetOfferRequestPaymentDetailsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getOfferRequestPaymentDetailsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse, undefined, true)

      return rejectWithValue(error)
    }
  }
)

const getOfferRequestPaymentDetailsForAdminSlice = createSlice({
  name: urls.admin.getOfferRequestPaymentDetailsForAdmin,
  initialState: getOfferRequestPaymentDetailsForAdminInitialState,
  reducers: {
    resetGetOfferRequestPaymentDetailsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }

    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfferRequestPaymentDetailsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getOfferRequestPaymentDetailsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getOfferRequestPaymentDetailsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetOfferRequestPaymentDetailsForAdminErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: getOfferRequestPaymentDetailsForAdminActions,
  reducer: getOfferRequestPaymentDetailsForAdminReducer
} = getOfferRequestPaymentDetailsForAdminSlice
export const { resetGetOfferRequestPaymentDetailsForAdmin } = getOfferRequestPaymentDetailsForAdminActions
export default getOfferRequestPaymentDetailsForAdminSlice.reducer
