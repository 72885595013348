import { Chip } from '@mui/material'

import Link from '../../../../../../../../../../shared/components/Link/Link.component'

import { getStatusColor } from '../../../../../../../../../../shared/functions/Status/status.functions'
import { formatMoney } from '../../../../../../../../../../shared/functions/String/string.functions'
import linkStyles from '../../../../../../../../../../shared/styles/link.styles'
import {
  OrderPaymentDetails,
  StripePayment,
  TravelerPaymentDetails
} from '../../../../../../../../../../shared/types/payments.types'

const tripPaymentDetailsColumns = [
  {
    key: 'flightCost',
    label: 'Flight Cost',
    render: (row: TravelerPaymentDetails) => formatMoney(row.flightCost, row.currency)
  },
  {
    key: 'travelerBenefit',
    label: 'Traveler Benefit',
    render: (row: TravelerPaymentDetails) =>
      row.travelerBenefit ? formatMoney(row.travelerBenefit, row.currency) : '-'
  },
  {
    key: 'total',
    label: 'Total Paid',
    render: (row: TravelerPaymentDetails) => formatMoney(row.total, row.currency)
  },
  {
    key: 'itemsCost',
    label: 'Amazon Credit',
    render: (row: TravelerPaymentDetails) => formatMoney(row.itemsCost, row.currency)
  }
]
const orderPaymentDetailsColumns = [
  {
    key: 'total',
    label: 'Total Paid',
    render: (row: OrderPaymentDetails) => formatMoney(row.total, row.currency)
  },
  {
    key: 'subtotal',
    label: 'Items Cost',
    render: (row: OrderPaymentDetails) => formatMoney(row.subtotal, row.currency)
  },
  {
    key: 'tax',
    label: 'Tax',
    render: (row: OrderPaymentDetails) => formatMoney(row.tax, row.currency)
  },
  {
    key: 'deliveryFee',
    label: 'Delivery Fee',
    render: (row: OrderPaymentDetails) => formatMoney(row.deliveryFee, row.currency)
  },
  {
    key: 'stripeFee',
    label: 'Stripe Fee (Charged)',
    render: (row: OrderPaymentDetails) => formatMoney(row.stripeFee, row.currency)
  },
  {
    key: 'totalFees',
    label: 'Total Fees',
    render: (row: OrderPaymentDetails) => formatMoney(row.totalFees, row.currency)
  }
]

const stripePaymentDetailsColumns = [
  {
    key: 'id',
    label: 'ID',
    render: (row: StripePayment) => (
      <Link href={row.stripePaymentUrl} sx={linkStyles} target="_blank">
        {row.paymentIntentId}
      </Link>
    )
  },
  {
    key: 'status',
    label: 'Status',
    render: (row: StripePayment) => <Chip label={row.paymentStatus} color={getStatusColor(row.paymentStatus)} />
  },
  {
    key: 'amount',
    label: 'Amount',
    render: (row: StripePayment) => formatMoney(row.amount, row.currency)
  },
  {
    key: 'stripeFee',
    label: 'Stripe Fee (Actual Fee)',
    render: (row: StripePayment) => formatMoney(row.stripeFee, row.currency)
  }
]

export { tripPaymentDetailsColumns, orderPaymentDetailsColumns, stripePaymentDetailsColumns }
