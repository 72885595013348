import React from 'react'

import { Box, Tabs as MuiTabs, Tab } from '@mui/material'

import styles from './Tabs.styles'
import { TabsProps } from './Tabs.types'

const Tabs: React.FC<TabsProps> = (props) => {
  const { tabs, value, onChange } = props

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue)
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tabsContainer}>
        <MuiTabs value={value} onChange={handleChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} icon={tab.icon} iconPosition={tab.iconPosition || 'start'} />
          ))}
        </MuiTabs>
      </Box>

      <Box sx={styles.tabPanel}>
        {tabs.map((tab, index) => (
          <div key={index} role="tabpanel" hidden={value !== index}>
            {value === index && tab.content}
          </div>
        ))}
      </Box>
    </Box>
  )
}

export default Tabs
