import theme from '../../../../styles/themes/default.theme'

const styles = {
  connector: (orientation: 'horizontal' | 'vertical') => ({
    '& .MuiStepConnector-line': {
      borderWidth: orientation === 'horizontal' ? 2 : null,
      borderColor: theme.palette.blue[800],
      borderRadius: '2px',
      [theme.breakpoints.down('lg')]: {
        marginTop: '-1px'
      }
    }
  })
}

export default styles
