import { CSSProperties } from 'react'

import theme from '../../../../../../shared/styles/themes/default.theme'

const styles = {
  assignedVirtualCardDetailsModalBodySkeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2)
  } as CSSProperties,

  assignedVirtualCardDetailsModalBodySkeletonElement: {
    width: '100%'
  } as CSSProperties,

  virtualCardDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2)
  } as CSSProperties
}

export default styles
