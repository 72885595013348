import {
  Analytics as FireBaseAnalytics,
  getAnalytics,
  logEvent as logFirebaseEvent,
  setUserId
} from 'firebase/analytics'
import { FirebaseApp, FirebaseError, initializeApp } from 'firebase/app'
import { Auth, User, getAuth, onAuthStateChanged, signInAnonymously, signOut } from 'firebase/auth'
import {
  Firestore,
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  writeBatch
} from 'firebase/firestore'
import {
  RemoteConfig,
  fetchAndActivate,
  getBoolean,
  getNumber,
  getRemoteConfig,
  getString
} from 'firebase/remote-config'

import localStorage from '../LocalStorage/localStorage'
import logger from '../Logger/logger.functions'
import { UserRole } from '../UserRole/userRoleManagement.types'
import { getUserRole } from '../UserRole/userRoleManagment.functions'
import { Conversation, ConversationParticipant } from './Messaging/messaging.types'
import firebaseConfig from './firebase.config'
import firebaseDictionary from './firebase.dictionaries'
import { ConfigDescription, ConfigKey, FirebaseConfig } from './firebase.types'

/**
 * Wrapper class for Firebase Analytics.
 */
class Analytics {
  private firebaseAnalytics: FireBaseAnalytics

  public constructor(firebaseAnalytics: FireBaseAnalytics) {
    this.firebaseAnalytics = firebaseAnalytics
  }

  public setUserId(userId: string): void {
    setUserId(this.firebaseAnalytics, userId)
  }

  /**
   * Logs an event in Firebase, Apptentive, and Instana.
   * @param {string} eventName Name of event to log | Max length = 40 characters,
   * @param {string} parameters Array of objects to log to Firebase | Max parameter value length = 100 characters
   * @example logEvent('Login_Button_Pressed')
   * @example logEvent('User_Created', {date: '12/12/2023'})
   */
  public logEvent = (eventName: string, parameters?: Record<string, string | number | boolean>) => {
    try {
      logFirebaseEvent(this.firebaseAnalytics, eventName, parameters)
    } catch (error) {
      logger.logError(error, 'Error logging event in logger', 'logEvent', parameters)
    }
  }
}

/**
 * Wrapper class for Firebase Remote Config.
 */
class FirebaseRemoteConfig {
  private remoteConfig: RemoteConfig

  private configKeys: Record<ConfigKey, ConfigDescription> = firebaseDictionary.remoteConfigVariables

  public constructor(firebaseRemoteConfig: RemoteConfig) {
    this.remoteConfig = firebaseRemoteConfig
    this.remoteConfig.settings = {
      fetchTimeoutMillis: 60000, // 1 minute
      minimumFetchIntervalMillis: 3600000 // 1 hour
    }
    this.refreshConfig()
  }

  /**
   * Refreshes the Firebase Remote Config.
   * @returns A promise that resolves when the config is refreshed.
   */
  public async refreshConfig(): Promise<void> {
    await fetchAndActivate(this.remoteConfig)
  }

  /**
   * Retrieves the value of a config key.
   * @param key The config key.
   * @returns The value of the config key.
   * @throws {Error} An error if the data type of the config key is unsupported.
   */
  public getConfigValue<T>(key: ConfigKey): T {
    const configDescription = this.configKeys[key]
    let jsonString = ''

    switch (configDescription.dataType) {
      case 'string':
        return getString(this.remoteConfig, key) as T

      case 'number':
        return getNumber(this.remoteConfig, key) as T

      case 'boolean':
        return getBoolean(this.remoteConfig, key) as T

      case 'array':
        // Assuming the array is stored as a JSON string
        jsonString = getString(this.remoteConfig, key)

        if (jsonString) {
          try {
            return JSON.parse(jsonString) as T
          } catch (error) {
            logger.logError(error, `Error parsing JSON for config key: ${key}`, 'getConfigValue')
          }
        } else {
          logger.logError('Invalid JSON string', `Empty JSON string for config key: ${key}`, 'getConfigValue')
        }
        break

      default:
        logger.logError('Unsupported data type', `Unsupported data type for config key: ${key}`, 'getConfigValue')
    }

    return undefined as unknown as T
  }
}

/**
 * Firebase service.
 */
export class FirebaseService {
  private static instance: FirebaseService
  public analytics: Analytics
  public remoteConfig: FirebaseRemoteConfig | null = null
  public app: FirebaseApp
  public db: Firestore
  public auth: Auth

  private constructor(firebaseConfig: FirebaseConfig) {
    this.app = initializeApp(firebaseConfig)
    this.analytics = new Analytics(getAnalytics())
    this.db = getFirestore(this.app)
    this.auth = getAuth(this.app)

    if (this.isIndexedDBAvailable()) {
      const remoteConfigInstance = getRemoteConfig()

      remoteConfigInstance.settings = {
        fetchTimeoutMillis: 60000, // 1 minute
        minimumFetchIntervalMillis: 3600000 // 1 hour
      }

      this.remoteConfig = new FirebaseRemoteConfig(remoteConfigInstance)
    } else {
      logger.logError(
        new Error('IndexedDB is not supported by the current browser. Remote Config will not be initialized.'),
        'FirebaseService',
        'constructor'
      )
    }
  }

  isIndexedDBAvailable(): boolean {
    try {
      return !!window.indexedDB
    } catch (e) {
      return false
    }
  }

  public static configure(): void {
    if (!this.instance) {
      this.instance = new FirebaseService(firebaseConfig)
      this.instance.remoteConfig?.refreshConfig()
    }
  }

  public static getInstance(): FirebaseService {
    if (!this.instance) {
      this.configure()
    }

    if (!this.instance) {
      throw new Error('FirebaseService not initialized')
    }

    return this.instance
  }
}

// Firebase Authentication Functions

/**
 * Gets the Firebase Auth instance.
 */
export const getFirebaseAuth = () => {
  return getAuth(firebase.app)
}

/**
 * Updates the mapping between Firebase UID and Hurrier User ID
 */
export const updateUserMapping = async (firebaseUid: string, hurrierUserId: string): Promise<void> => {
  try {
    const db = getFirestore()

    await setDoc(doc(db, 'userMappings', firebaseUid), {
      firebaseUserId: firebaseUid,
      hurrierUserId,
      lastUpdated: Timestamp.now()
    })
  } catch (error) {
    logger.logError(error, 'Error updating user mapping', 'updateUserMapping')
  }
}

/**
 * Gets the Hurrier user ID for a Firebase UID
 */
export const getHurrierUserIdForFirebaseUser = async (firebaseUid: string): Promise<string | null> => {
  try {
    const db = getFirestore()
    const mappingDoc = await getDoc(doc(db, 'userMappings', firebaseUid))

    if (mappingDoc.exists()) {
      return mappingDoc.data().hurrierUserId
    }

    return null
  } catch (error) {
    logger.logError(error, 'Error getting Hurrier user ID', 'getHurrierUserIdForFirebaseUser')

    return null
  }
}

/**
 * Called when a user authenticates with both a Firebase UID and Hurrier User ID
 * Updates the mapping and conversation data
 */
export const onUserAuthenticated = async (
  firebaseUid: string,
  hurrierUserId: string,
  firstName: string = '',
  lastName: string = '',
  role: UserRole = getUserRole() || UserRole.traveler
): Promise<void> => {
  try {
    // Update the mapping
    await updateUserMapping(firebaseUid, hurrierUserId)

    // Update Firebase UID in conversations where this user is a participant
    await updateUserDataInConversations(firebaseUid, hurrierUserId, firstName, lastName, role)
  } catch (error) {
    logger.logError(error, 'Error processing user authentication', 'onUserAuthenticated')
  }
}

/**
 * Updates the Firebase UID for a user in all their conversations
 */
const updateUserDataInConversations = async (
  firebaseUid: string,
  hurrierUserId: string,
  firstName: string = '',
  lastName: string = '',
  role: UserRole = getUserRole() || UserRole.traveler
): Promise<void> => {
  try {
    const db = getFirestore()
    // Get all conversations
    const conversationsRef = collection(db, 'conversations')
    const q = query(conversationsRef)
    const querySnapshot = await getDocs(q)

    // Prepare batch write
    const batch = writeBatch(db)
    let updateCount = 0

    querySnapshot.forEach((doc) => {
      const conversation = doc.data() as Conversation
      let updated = false

      if (conversation.participants?.length > 0) {
        // Create updated participants array
        const updatedParticipants = conversation.participants?.map((p) => {
          // If this participant is the current user by Hurrier ID
          if (p.hurrierUserId === hurrierUserId) {
            updated = true

            // Update their Firebase UID and other info if provided
            return {
              ...p,
              firebaseUserId: firebaseUid,
              ...(firstName ? { firstName } : {}),
              ...(lastName ? { lastName } : {}),
              ...(role ? { role } : {})
            }
          }

          return p
        })

        // If we found and updated the participant, add to batch
        if (updated) {
          batch.update(doc.ref, { participants: updatedParticipants })
          updateCount++
        }
      }
    })

    // Commit the batch if we have updates
    if (updateCount > 0) {
      await batch.commit()

      logger.logEvent(`Updated Firebase UID in ${updateCount} conversations for Hurrier user ${hurrierUserId}`, {
        updateCount,
        hurrierUserId
      })
    }
  } catch (error) {
    logger.logError(error, 'Error updating user data in conversations', 'updateUserDataInConversations')
  }
}

/**
 * Signs in a user anonymously to Firebase.
 *
 * @param {Object} userData - Optional user data for the Hurrier user
 * @param {string} userData.hurrierUserId - The Hurrier user ID
 * @param {string} userData.firstName - The user's first name
 * @param {string} userData.lastName - The user's last name
 * @returns {Promise<User>} The authenticated user object.
 */
export const signInAnonymouslyToFirebase = async (userData?: {
  hurrierUserId: string
  firstName: string
  lastName: string
  role: UserRole
}): Promise<User> => {
  try {
    const auth = getFirebaseAuth()
    const result = await signInAnonymously(auth)

    localStorage.setItem('firebaseAnonymousUserId', result.user.uid)

    // If we have Hurrier user data, update the mapping
    if (userData && userData.hurrierUserId) {
      await onUserAuthenticated(
        result.user.uid,
        userData.hurrierUserId,
        userData.firstName,
        userData.lastName,
        userData.role
      )
    }

    return result.user
  } catch (error: unknown) {
    if (error instanceof FirebaseError) {
      logger.logError(error, 'Error signing in anonymously', 'signInAnonymouslyToFirebase')

      throw error
    } else {
      logger.logError(error, 'Unknown error signing in anonymously', 'signInAnonymouslyToFirebase')
      throw new Error('Unknown error signing in anonymously')
    }
  }
}

export const setCurrentHurrierUser = async (user: ConversationParticipant) => {
  try {
    const firebaseUserId = localStorage.getItem('firebaseAnonymousUserId') || null

    const hurrierFirebaseUser = {
      hurrierUserId: user.hurrierUserId,
      firebaseUserId: firebaseUserId,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role
    }

    localStorage.setItem('hurrierFirebaseUser', hurrierFirebaseUser)

    // Update the mapping and conversations if we have a Firebase user ID
    if (firebaseUserId) {
      await onUserAuthenticated(firebaseUserId, user.hurrierUserId, user.firstName, user.lastName, user.role)
    }
  } catch (error) {
    logger.logError(error, 'Error setting current Hurrier user', 'setCurrentHurrierUser')
  }
}

/**
 * Gets the current Hurrier user's participant data.
 *
 * @returns {Object} The current user's participant data
 */
export const getCurrentHurrierUser = (): ConversationParticipant | null => {
  const hurrierFirebaseUser = localStorage.getItem('hurrierFirebaseUser') as ConversationParticipant | null

  if (hurrierFirebaseUser) {
    return hurrierFirebaseUser
  } else {
    const error = new Error('No hurrier user found')
    logger.logError(error, 'No hurrier user found', 'getCurrentHurrierUser')
    throw error
  }
}

/**
 * Logs out the current Firebase user and removes all associated data from local storage.
 */
export const logoutFirebaseUser = async () => {
  try {
    const auth = getFirebaseAuth()

    await signOut(auth)

    localStorage.removeItem('hurrierFirebaseUser')
    localStorage.removeItem('firebaseAnonymousUserId')
    localStorage.removeItem('chatState')
  } catch (error) {
    logger.logError(error, 'Error logging out Firebase user', 'logoutFirebaseUser')
  }
}

/**
 * Gets the current Firebase user or signs in anonymously if no user is available.
 *
 * @returns {Promise<User>} The current or newly created user.
 */
export const getCurrentFirebaseUser = async (): Promise<User> => {
  try {
    const auth = getFirebaseAuth()

    // Check if there's a current user
    if (auth.currentUser) {
      return auth.currentUser
    } else {
      // The session might have expired. Sign in anonymously to get a new session.
      try {
        return await signInAnonymouslyToFirebase()
      } catch (error) {
        logger.logError(error, 'Error getting current Firebase user', 'getCurrentFirebaseUser')

        throw error
      }
    }
  } catch (error) {
    logger.logError(error, 'Error getting current Firebase user', 'getCurrentFirebaseUser')
    throw error
  }
}

/**
 * Subscribes to auth state changes.
 *
 * @param {(user: User | null) => void} callback - Function to call when auth state changes.
 * @returns {() => void} Unsubscribe function.
 */
export const subscribeToAuthChanges = (callback: (user: User | null) => void): (() => void) => {
  const auth = getFirebaseAuth()

  return onAuthStateChanged(auth, callback)
}

/**
 * Gets the current user's display name for chat.
 *
 * @returns {string} The display name to use for the current user.
 */
export const getCurrentUserDisplayName = (): string => {
  return localStorage.getItem('currentUserDisplayName') || 'You'
}

/**
 * Sets the current user's display name for chat.
 *
 * @param {string} displayName - The display name to set for the current user.
 */
export const setCurrentUserDisplayName = (displayName: string): void => {
  localStorage.setItem('currentUserDisplayName', displayName)
}

const firebase = FirebaseService.getInstance()

export default firebase
