import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { getProfile } from '@/redux/states/profile/getProfile/getProfile.slice'
import { GetProfileData, GetProfileRequestPayload } from '@/redux/states/profile/getProfile/getProfile.types'
import { GetProfileDataForFirebase } from '@/redux/states/profile/getProfile/getProfile.types'
import { updateFirebaseUserId } from '@/redux/states/profile/updateFirebaseUserId/updateFirebaseUserId.slice'
import { UpdateFirebaseUserIdRequestPayload } from '@/redux/states/profile/updateFirebaseUserId/updateFirebaseUserId.types'
import { isAuthenticated } from '@/shared/functions/Auth/auth.functions'
import logger from '@/shared/functions/Logger/logger.functions'
import { UserRole } from '@/shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '@/shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch } from '@/shared/hooks/redux.hooks'

import MessagingService from '../../../functions/Firebase/Messaging/messaging.functions'
import { Conversation, ConversationParticipant } from '../../../functions/Firebase/Messaging/messaging.types'
import { getCurrentFirebaseUser, setCurrentHurrierUser } from '../../../functions/Firebase/firebase.functions'
import localStorage from '../../../functions/LocalStorage/localStorage'
import { ChatPartner, Message, MessagingContextType } from './Messaging.types'

// Create the context with a default value
const MessagingContext = createContext<MessagingContextType | null>(null)

// Provider component
export const MessagingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [currentChatPartner, setCurrentChatPartner] = useState<ChatPartner | null>(null)
  const [messages, setMessages] = useState<Message[]>([])
  const [conversationId, setConversationId] = useState<string | null>(null)
  const [currentFirebaseUserId, setCurrentFirebaseUserId] = useState<string>('')
  const [currentUserParticipant, setCurrentUserParticipant] = useState<ConversationParticipant | null>(null)
  const [isAuthInitialized, setIsAuthInitialized] = useState(false)
  const [userConversations, setUserConversations] = useState<Conversation[]>([])
  const [totalUnreadCount, setTotalUnreadCount] = useState(0)

  const dispatch = useAppDispatch()

  // Initialize Firebase Authentication and user data
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const firebaseUser = await getCurrentFirebaseUser()

        const updateFirebaseUserIdPayload: UpdateFirebaseUserIdRequestPayload = {
          request: { firebaseUserId: firebaseUser.uid }
        }

        const getProfilePayload: GetProfileRequestPayload = {
          request: {
            firebaseData: true
          },
          onSuccess: (data: GetProfileData) => {
            const firebaseProfileData = data as unknown as GetProfileDataForFirebase

            const currentUser: ConversationParticipant = {
              firebaseUserId: firebaseProfileData.firebaseUserId,
              hurrierUserId: firebaseProfileData.userId.toString(),
              firstName: firebaseProfileData.firstName,
              lastName: firebaseProfileData.lastName,
              role: getUserRole() ?? UserRole.traveler
            }

            setCurrentUserParticipant(currentUser)

            if (firebaseProfileData.firebaseUserId !== firebaseUser.uid) {
              dispatch(updateFirebaseUserId(updateFirebaseUserIdPayload))
            }
          }
        }

        if (isAuthenticated() && getUserRole() !== UserRole.admin) {
          dispatch(getProfile(getProfilePayload))
        }

        setCurrentFirebaseUserId(firebaseUser.uid)
        setIsAuthInitialized(true)
      } catch (error) {
        logger.logError(error, 'Error initializing Firebase Auth:', 'initializeAuth')
        setIsAuthInitialized(true) // Still set this to true so UI doesn't hang
      }
    }

    initializeAuth()
  }, [dispatch])

  useEffect(
    function saveHurrierUserToLocalStorage() {
      if (currentUserParticipant) {
        setCurrentHurrierUser(currentUserParticipant)
      }
    },
    [currentUserParticipant]
  )

  useEffect(function loadChatStateFromLocalStorage() {
    try {
      const savedChatState = localStorage.getItem('chatState')

      if (savedChatState) {
        const { isOpen, isExpanded, currentChatPartner, conversationId } = savedChatState

        setIsOpen(isOpen)
        setIsExpanded(isExpanded)
        setCurrentChatPartner(currentChatPartner)
        setConversationId(conversationId)
      }
    } catch (error) {
      logger.logError(error, 'Error loading chat state:', 'loadChatStateFromLocalStorage')
    }
  }, [])

  useEffect(
    function saveChatStateToLocalStorage() {
      try {
        localStorage.setItem('chatState', {
          isOpen,
          isExpanded,
          currentChatPartner,
          conversationId
        })
      } catch (error) {
        logger.logError(error, 'Error saving chat state:', 'saveChatStateToLocalStorage')
      }
    },
    [isOpen, isExpanded, currentChatPartner, conversationId]
  )

  // Subscribe to messages for the current conversation
  useEffect(() => {
    let unsubscribe: (() => void) | null = null

    if (conversationId && isAuthInitialized && currentFirebaseUserId) {
      try {
        unsubscribe = MessagingService.subscribeToMessages(conversationId, (newMessages) => {
          setMessages(newMessages)
        })
      } catch (error) {
        logger.logError(error, 'Error subscribing to messages:', 'subscribeToMessages')
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [conversationId, isAuthInitialized, currentFirebaseUserId])

  // Create or get conversation when chat partner changes
  useEffect(() => {
    const getOrCreateConversation = async () => {
      if (currentChatPartner && isAuthInitialized && currentUserParticipant) {
        try {
          const conversationId = await MessagingService.getOrCreateConversation(
            currentUserParticipant,
            currentChatPartner.recipient,
            currentChatPartner.orderId
          )

          setConversationId(conversationId)
        } catch (error) {
          logger.logError(error, 'Error creating conversation:', 'getOrCreateConversation')
        }
      }
    }

    getOrCreateConversation()
  }, [currentChatPartner, isAuthInitialized, currentUserParticipant])

  useEffect(
    function markMessagesAsReadWhenChatIsOpened() {
      const markMessagesAsRead = async () => {
        // Only mark messages as read when chat is both open AND expanded
        if (
          isOpen &&
          isExpanded &&
          conversationId &&
          isAuthInitialized &&
          currentFirebaseUserId &&
          currentUserParticipant
        ) {
          try {
            // Find the current conversation
            const currentConversation = userConversations.find((conv) => conv.id === conversationId)

            if (currentConversation) {
              const userId = currentUserParticipant.hurrierUserId
              const unreadCountForConversation = currentConversation.unreadCount[userId] || 0

              // Only update UI if there are unread messages
              if (unreadCountForConversation > 0) {
                // Update total unread count immediately for responsive UI
                setTotalUnreadCount((prev) => Math.max(0, prev - unreadCountForConversation))

                // Update conversation unread count immediately for responsive UI
                setUserConversations((prevConversations) =>
                  prevConversations.map((conv) => {
                    if (conv.id === conversationId) {
                      return {
                        ...conv,
                        unreadCount: {
                          ...conv.unreadCount,
                          [userId]: 0
                        }
                      }
                    }

                    return conv
                  })
                )
              }
            }

            // Call Firebase to persist the change
            await MessagingService.markMessagesAsRead(conversationId, currentFirebaseUserId)
          } catch (error) {
            logger.logError(error, 'Error marking messages as read:', 'markMessagesAsRead')
          }
        }
      }

      // Only run the effect if all necessary dependencies are available
      if (isAuthInitialized && currentFirebaseUserId && isOpen && isExpanded) {
        markMessagesAsRead()
      }
    },
    [
      isOpen,
      isExpanded,
      conversationId,
      isAuthInitialized,
      currentFirebaseUserId,
      currentUserParticipant,
      userConversations
    ]
  )

  // Subscribe to user conversations
  useEffect(() => {
    let unsubscribe: (() => void) | null = null

    const subscribeToConversations = async () => {
      if (isAuthInitialized && currentUserParticipant) {
        try {
          unsubscribe = MessagingService.subscribeToUserConversations(currentUserParticipant, (conversations) => {
            // Always update with the conversations from Firebase directly
            setUserConversations(conversations)

            // Calculate total unread messages by taking values directly from Firebase data
            if (currentUserParticipant) {
              const userId = currentUserParticipant.hurrierUserId

              // Calculate the total unread count by summing all conversation unread counts
              const totalUnread = conversations.reduce((total, conversation) => {
                return total + (conversation.unreadCount[userId] || 0)
              }, 0)

              // Set the total unread count directly from Firebase data
              setTotalUnreadCount(totalUnread)
            }
          })
        } catch (error) {
          logger.logError(error, 'Error subscribing to conversations:', 'subscribeToConversations')
        }
      }
    }

    subscribeToConversations()

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [isAuthInitialized, currentUserParticipant])

  const openChat = useCallback(
    (currentUser: ConversationParticipant, recipient: ConversationParticipant, orderId: string) => {
      const currentChatPartner: ChatPartner = {
        recipient,
        orderId
      }

      setCurrentUserParticipant(currentUser)
      setCurrentChatPartner(currentChatPartner)
      setIsOpen(true)
      setIsExpanded(true)
    },
    []
  )

  const closeChat = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggleExpand = useCallback(() => {
    const wasExpanded = isExpanded
    setIsExpanded((prev) => !prev)

    // If we're expanding the chat and there's an active conversation,
    // mark messages as read through Firebase
    if (!wasExpanded && conversationId && currentUserParticipant && currentFirebaseUserId) {
      // Find the current conversation to check unread count
      const currentConversation = userConversations.find((conv) => conv.id === conversationId)

      if (currentConversation) {
        const userId = currentUserParticipant.hurrierUserId
        const unreadCountForConversation = currentConversation.unreadCount[userId] || 0

        // Only update if there are unread messages
        if (unreadCountForConversation > 0) {
          // Update total unread count immediately for responsive UI
          setTotalUnreadCount((prev) => Math.max(0, prev - unreadCountForConversation))

          // Update conversation unread count immediately for responsive UI
          setUserConversations((prevConversations) =>
            prevConversations.map((conv) => {
              if (conv.id === conversationId) {
                return {
                  ...conv,
                  unreadCount: {
                    ...conv.unreadCount,
                    [userId]: 0
                  }
                }
              }

              return conv
            })
          )
        }
      }

      // Call Firebase to persist the change
      MessagingService.markMessagesAsRead(conversationId, currentFirebaseUserId)
        .then(() => {
          // Do nothing
        })
        .catch((error) => {
          logger.logError(error, 'Error marking messages as read on expand:', 'toggleExpand')
        })
    }
  }, [isExpanded, conversationId, currentUserParticipant, currentFirebaseUserId, userConversations])

  const sendMessage = useCallback(
    (text: string) => {
      if (!text.trim() || !currentChatPartner || !conversationId || !isAuthInitialized || !currentUserParticipant)
        return

      try {
        // Send the message
        MessagingService.sendMessage(conversationId, currentUserParticipant, currentChatPartner.recipient, text)

        // Check if the user is messaging themselves (same hurrierUserId)
        if (currentUserParticipant.hurrierUserId === currentChatPartner.recipient.hurrierUserId) {
          // Find the conversation
          const currentConversation = userConversations.find((conv) => conv.id === conversationId)

          if (currentConversation) {
            const userId = currentUserParticipant.hurrierUserId

            // For self-messages, immediately ensure unread count shows 0 in the UI
            if (currentConversation.unreadCount[userId] > 0) {
              // Update conversation unread count immediately for responsive UI
              setUserConversations((prevConversations) =>
                prevConversations.map((conv) => {
                  if (conv.id === conversationId) {
                    return {
                      ...conv,
                      unreadCount: {
                        ...conv.unreadCount,
                        [userId]: 0
                      }
                    }
                  }

                  return conv
                })
              )

              // Update total unread count
              setTotalUnreadCount((prev) => Math.max(0, prev - (currentConversation.unreadCount[userId] || 0)))
            }
          }

          // Call Firebase to mark messages as read
          MessagingService.markMessagesAsRead(conversationId, currentFirebaseUserId)
            .then(() => {
              // Do nothing
            })
            .catch((error) => {
              logger.logError(error, 'Error marking self-messages as read:', 'sendMessage')
            })
        }
      } catch (error) {
        logger.logError(error, 'Error sending message:', 'sendMessage')
      }
    },
    [
      conversationId,
      currentChatPartner,
      currentUserParticipant,
      isAuthInitialized,
      currentFirebaseUserId,
      userConversations
    ]
  )

  return (
    <MessagingContext.Provider
      value={{
        isOpen,
        isExpanded,
        currentChatPartner,
        messages,
        currentFirebaseUserId,
        currentUserParticipant,
        userConversations,
        totalUnreadCount,
        openChat,
        closeChat,
        toggleExpand,
        sendMessage
      }}
    >
      {children}
    </MessagingContext.Provider>
  )
}

// Custom hook for consuming the context
export const useMessaging = () => {
  const context = useContext(MessagingContext)

  if (!context) {
    throw new Error('useMessaging must be used within a MessagingProvider')
  }

  return context
}
