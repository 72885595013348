import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius / 4,
    border: '1px solid transparent',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[5]
  } as SxProps,

  cardTop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingX: theme.spacing(2)
    }
  } as SxProps,

  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    width: '100%'
  } as SxProps,

  stopDurationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  } as SxProps,

  stopCount: {
    backgroundColor: '#E6F4EA',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius / 10,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.success.dark
  } as SxProps,

  stopText: {
    fontSize: '0.700rem',
    color: theme.palette.success.dark
  } as SxProps,

  duration: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: theme.typography.caption.fontSize
  } as SxProps,

  airlineInfo: {
    display: 'flex',
    alignItems: 'center'
  } as SxProps,

  logo: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2)
  },

  airportInfo: {
    textAlign: 'left'
  } as SxProps,

  airportName: {
    color: theme.palette.text.primary
  } as SxProps,

  time: {
    color: '#5A5A5A',
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  info: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginRight: theme.spacing(2)
  } as SxProps,

  separator: {
    flex: 1,
    height: '1px',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3.5)
  } as SxProps,

  bottomDivider: {
    width: '100%',
    backgroundColor: theme.palette.divider,
    marginTop: theme.spacing(3)
  } as SxProps,

  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  } as SxProps,

  actionButton: {} as SxProps,

  verticalSeparator: {
    width: '1px',
    height: '80px',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  } as SxProps,

  departureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  } as SxProps,

  arrivalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  } as SxProps,

  priceInfo: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row-reverse'
    }
  } as SxProps,

  price: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.blue[800]
    }
  } as SxProps,

  perPerson: {
    display: 'none',
    color: theme.palette.grey[500],
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      display: 'inline-block'
    }
  } as SxProps,

  cabinClass: {
    textAlign: 'left',
    color: theme.palette.grey[500]
  } as SxProps,

  detailsContent: {
    padding: theme.spacing(3),
    width: '100%'
  } as SxProps,

  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s'
  } as SxProps,

  expandIconExpanded: {
    transform: 'rotate(180deg)'
  } as SxProps,

  legSummaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  } as SxProps,

  airlineLogoContainer: {
    height: '30px',
    width: '30px',
    justifyContent: 'center',
    alignItems: 'center'
  } as SxProps,

  airlineLogo: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  } as CSSProperties,

  legSummaryContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
      alignItems: 'flex-start'
    }
  } as SxProps,

  legSummaryContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as SxProps,

  legSummaryContentTitleText: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle2.fontSize
    }
  } as SxProps,

  airlineNameText: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as SxProps,

  legSummaryContentSubtitleText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left'
  } as SxProps,

  legDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  } as SxProps,

  legDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(3)
    }
  } as SxProps,

  legStepDescriptionText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2)
  } as SxProps,

  legStepLabelText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2)
  } as SxProps,

  additionalDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(2, 0, 0, 4),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      flexWrap: 'wrap',
      gap: theme.spacing(2)
    }
  } as SxProps,

  additionalDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      minWidth: '40%'
    }
  } as SxProps,

  additionalDetailLabelText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.grey[500]
  } as SxProps,

  additionalDetailDescriptionText: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'left'
  } as SxProps,

  durationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: theme.spacing(0),
    marginRight: theme.spacing(1)
  } as SxProps,

  segmentDivider: {
    margin: theme.spacing(3, 0, 3, 0),
    width: '100%'
  } as SxProps
}

export default styles
