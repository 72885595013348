import React, { useCallback, useEffect } from 'react'

import { getAllExternalFlightBookingRequestsForAdmin } from '@/redux/states/admin/getAllExternalFlightBookingRequestsForAdmin/getAllExternalFlightBookingRequestsForAdmin.slice'
import { GetAllExternalFlightBookingRequestsForAdminRequestPayload } from '@/redux/states/admin/getAllExternalFlightBookingRequestsForAdmin/getAllExternalFlightBookingRequestsForAdmin.types'
import { getCountriesAndCities } from '@/redux/states/flights/getCountriesAndCities/getCountriesAndCities.slice'
import { getExternalAirlines } from '@/redux/states/flights/getExternalAirlines/getExternalAirlines.slice'
import status from '@/shared/functions/Status/status.dictionaries'
import { Chip, Stack, Typography } from '@mui/material'
import { AppProvider, DashboardLayout, PageContainer } from '@toolpad/core'
import { useDemoRouter } from '@toolpad/core/internal'
import { useSelector } from 'react-redux'

import ExternalBookings from './components/ExternalBookings/ExternalBookings.component'
import SearchHeader from './components/SearchHeader/SearchHeader.component'
import Users from './components/Users/Users.component'
import UserDetails from './components/Users/components/UserDetails/UserDetails.component'
import OrderDetails from './components/Users/components/UserDetails/components/OrderDetails/OrderDetails.component'
import TripDetails from './components/Users/components/UserDetails/components/TripDetails/TripDetails.component'
import VirtualCards from './components/VirtualCards/VirtualCards.component'

import images from '../../assets/images'
import env from '../../networkRequests/apiClient/env.config'
import { getAllVirtualCardRequestsForAdmin } from '../../redux/states/admin/payments/virtualCards/getAllVirtualCardRequestsForAdmin/getAllVirtualCardRequestsForAdmin.slice'
import { GetAllVirtualCardRequestsForAdminRequestPayload } from '../../redux/states/admin/payments/virtualCards/getAllVirtualCardRequestsForAdmin/getAllVirtualCardRequestsForAdmin.types'
import { logout } from '../../redux/states/auth/logout/logout.slice'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { joinElementsWithBullet } from '../../shared/functions/String/string.functions'
import { useAppDispatch } from '../../shared/hooks/redux.hooks'
import linkStyles from '../../shared/styles/link.styles'
import theme from '../../shared/styles/themes/default.theme'
import { adminNavigation, adminNavigationPaths } from './AdminDashboard.dictionary'
import styles from './AdminDashboard.styles'
import {
  AdminNavigationItem,
  AdminNavigationPath,
  AdminPageContentHeaderText,
  Breadcrumb
} from './AdminDashboard.types'

const AdminDashboard: React.FC = () => {
  const [currentSearch, setCurrentSearch] = React.useState('')
  const [userDetailsTabIndex, setUserDetailsTabIndex] = React.useState(0)
  const [virtualCardsTabIndex, setVirtualCardsTabIndex] = React.useState(0)
  const [externalBookingsTabIndex, setExternalBookingsTabIndex] = React.useState(0)
  const [adminNavigationPath, setAdminNavigationPath] = React.useState<AdminNavigationItem[]>(adminNavigation)

  const { success: usersSuccess } = useSelector((state: RootState) => state.getAllUsersForAdmin)

  const { success: virtualCardRequestsSuccess } = useSelector(
    (state: RootState) => state.getAllVirtualCardRequestsForAdmin
  )

  const { success: externalBookingRequestsSuccess } = useSelector(
    (state: RootState) => state.getAllExternalFlightBookingRequestsForAdmin
  )

  const dispatch = useAppDispatch()
  const navigate = router.navigate()
  const demoRouter = useDemoRouter('users')

  const loadHomePageEndpoints = useCallback(
    async (pathname: string) => {
      const promises: Promise<unknown>[] = []

      const payload: GetAllVirtualCardRequestsForAdminRequestPayload = {
        request: {}
      }

      const externalBookingRequestsPayload: GetAllExternalFlightBookingRequestsForAdminRequestPayload = {
        request: {
          status: status.externalFlightBookingStatus.externalFlightBookingRequestCreated
        }
      }

      switch (pathname) {
        case `/${adminNavigationPaths.virtualCards}`:
        case `/${adminNavigationPaths.users}`:
          promises.push(dispatch(getAllExternalFlightBookingRequestsForAdmin(externalBookingRequestsPayload)))
          break

        case `/${adminNavigationPaths.externalBookings}`:
          promises.push(dispatch(getAllVirtualCardRequestsForAdmin(payload)))
          break
      }

      await Promise.all(promises)
    },
    [dispatch]
  )

  const loadExternalBookingsAuxiliaryEndpoints = useCallback(async () => {
    dispatch(getCountriesAndCities({}))
    dispatch(getExternalAirlines({}))
  }, [dispatch])

  useEffect(() => {
    loadHomePageEndpoints(adminNavigationPaths.users)
    loadExternalBookingsAuxiliaryEndpoints()
  }, [loadExternalBookingsAuxiliaryEndpoints, loadHomePageEndpoints])

  useEffect(
    function loadHomePageEndpointsOnPathChange() {
      loadHomePageEndpoints(demoRouter.pathname)
    },
    [demoRouter.pathname, loadHomePageEndpoints]
  )

  useEffect(() => {
    const virtualCardsItem = adminNavigationPath.find((item) => item.segment === adminNavigationPaths.virtualCards)
    const newVirtualCardsCount = virtualCardRequestsSuccess?.data?.totalRecords ?? 0

    if (virtualCardsItem && newVirtualCardsCount > 0) {
      virtualCardsItem.action = <Chip size="small" label={`${newVirtualCardsCount} new`} color="info" />

      const navPath = adminNavigationPath.map((item) => {
        if (item.segment === adminNavigationPaths.virtualCards) {
          return { ...item, action: virtualCardsItem.action }
        }

        return item
      })

      setAdminNavigationPath(navPath)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualCardRequestsSuccess])

  useEffect(() => {
    const externalBookingsItem = adminNavigationPath.find(
      (item) => item.segment === adminNavigationPaths.externalBookings
    )

    const records = externalBookingRequestsSuccess?.data?.records ?? []
    const newExternalBookingRequestsCount = records.filter(
      (record) => record.status.name === status.externalFlightBookingStatus.externalFlightBookingRequestCreated
    ).length

    if (externalBookingsItem && newExternalBookingRequestsCount > 0) {
      externalBookingsItem.action = <Chip size="small" label={`${newExternalBookingRequestsCount} new`} color="info" />

      const navPath = adminNavigationPath.map((item) => {
        if (item.segment === adminNavigationPaths.externalBookings) {
          return { ...item, action: externalBookingsItem.action }
        }

        return item
      })

      setAdminNavigationPath(navPath)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalBookingRequestsSuccess])

  const handleSearchSubmit = React.useCallback((query: string) => {
    setCurrentSearch(query)
  }, [])

  const handleClearSearch = React.useCallback(() => {
    setCurrentSearch('')
  }, [])

  function getBreadcrumbConfig(currentPath: string) {
    const basePath = routes.adminDashboard.path
    const pathSegments = currentPath.replace(basePath, '').split('/').filter(Boolean)
    let accumulatedPath = basePath

    const baseCrumb = {
      title: '🏠',
      path: basePath
    }

    const breadcrumbs: Breadcrumb[] = [baseCrumb]

    const pathDisplayNames: Record<string, string> = {
      [adminNavigationPaths.users]: 'Users',
      [adminNavigationPaths.externalBookings]: 'External Bookings',
      [adminNavigationPaths.virtualCards]: 'Virtual Cards'
    }

    for (const [index, segment] of pathSegments.entries()) {
      const displayName = pathDisplayNames[segment] || segment
      const isLast = index === pathSegments.length - 1
      accumulatedPath += `/${segment}`

      breadcrumbs.push({
        title: displayName,
        path: isLast ? '' : accumulatedPath
      })
    }

    // Truncate long IDs (like user IDs) in last breadcrumb
    if (breadcrumbs.length > 1) {
      const lastCrumb = breadcrumbs[breadcrumbs.length - 1]

      if (lastCrumb.title.length > 20) {
        lastCrumb.title = `${lastCrumb.title.substring(0, 8)}...${lastCrumb.title.substring(
          lastCrumb.title.length - 4
        )}`
      }

      lastCrumb.path = '' // Remove link from current page
    }

    return breadcrumbs
  }

  function getPageContent({ pathname }: { pathname: string }) {
    const pathSegments = pathname.split('/').filter(Boolean)
    const mainSection = pathSegments[0] as AdminNavigationPath
    const userId = pathSegments[1]
    const isOnUserDetailsPath = pathSegments[1] !== undefined
    const isOnOrderDetailsPath = pathSegments[2] !== undefined && userDetailsTabIndex === 0
    const isOnTripDetailsPath = pathSegments[2] !== undefined && userDetailsTabIndex === 1

    let pageContent: React.ReactNode | null = null
    let header: AdminPageContentHeaderText | null = null
    let searchPlaceholder: string | undefined = undefined
    let hasSearch = false

    switch (mainSection) {
      case adminNavigationPaths.users:
        header = {
          title: 'Users',
          subtitle: 'Manage platform users'
        }

        if (isOnUserDetailsPath) {
          const user = usersSuccess?.data.records.find((user) => user.id.toString() === userId)

          const emailLink = (
            <a href={`mailto:${user?.email}`} style={linkStyles}>
              {user?.email}
            </a>
          )

          const phoneNumber = user?.phoneNumber ? (
            <a href={`tel:${user?.phoneNumber}`} style={linkStyles}>
              {user?.phoneNumber}
            </a>
          ) : null

          header = {
            title: `${user?.firstName} ${user?.lastName}`,
            subtitle: joinElementsWithBullet([user?.id.toString(), emailLink, phoneNumber])
          }

          if (isOnOrderDetailsPath) {
            pageContent = <OrderDetails />
          } else if (isOnTripDetailsPath) {
            pageContent = <TripDetails />
          } else {
            hasSearch = true
            pageContent = (
              <UserDetails
                userId={userId}
                searchQuery={currentSearch}
                demoRouter={demoRouter}
                setUserDetailsTabIndex={setUserDetailsTabIndex}
                userDetailsTabIndex={userDetailsTabIndex}
              />
            )
          }

          searchPlaceholder = userDetailsTabIndex === 0 ? 'Search orders...' : 'Search trips...'
        } else {
          hasSearch = true
          searchPlaceholder = 'Search users...'
          pageContent = <Users searchQuery={currentSearch} demoRouter={demoRouter} />
        }
        break

      case adminNavigationPaths.externalBookings:
        pageContent = (
          <ExternalBookings
            searchQuery={currentSearch}
            setExternalBookingsTabIndex={setExternalBookingsTabIndex}
            externalBookingsTabIndex={externalBookingsTabIndex}
          />
        )
        hasSearch = false
        searchPlaceholder =
          externalBookingsTabIndex === 0 ? 'Search pending requests...' : 'Search processed requests...'
        header = {
          title: 'External Bookings',
          subtitle: 'Manage external flight booking requests'
        }
        break

      case adminNavigationPaths.virtualCards:
        pageContent = (
          <VirtualCards
            searchQuery={currentSearch}
            setVirtualCardsTabIndex={setVirtualCardsTabIndex}
            virtualCardsTabIndex={virtualCardsTabIndex}
          />
        )
        hasSearch = true
        searchPlaceholder = virtualCardsTabIndex === 0 ? 'Search requests...' : 'Search assigned cards...'
        header = {
          title: 'Virtual Cards',
          subtitle: 'Manage virtual cards'
        }
        break
    }

    return (
      <PageContainer
        slots={{
          header: () => (
            <SearchHeader
              navigate={demoRouter.navigate}
              breadcrumbs={getBreadcrumbConfig(pathname)}
              header={{
                title: header?.title || '',
                subtitle: header?.subtitle || ''
              }}
              search={
                hasSearch
                  ? {
                      initialQuery: currentSearch,
                      onSearchSubmit: handleSearchSubmit,
                      onClear: handleClearSearch,
                      placeholder: searchPlaceholder
                    }
                  : undefined
              }
            />
          )
        }}
      >
        {pageContent}
      </PageContainer>
    )
  }

  function getCustomAppTitle() {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <img src={images.hourrierLogo} alt="Hurrier Logo" width={32} height={32} />
        <Typography variant="h6">{env.PRODUCT_NAME} Admin Dashboard</Typography>
        {env.NODE_ENV !== 'production' && <Chip size="small" label={env.NODE_ENV.toUpperCase()} color="info" />}
      </Stack>
    )
  }

  const handleLogout = async () => {
    const onSuccess = () => {
      navigate(routes.login.path, { replace: true })
    }

    await dispatch(logout({ onSuccess }))
  }

  return (
    <AppProvider
      navigation={adminNavigation}
      router={demoRouter}
      branding={{
        title: 'Admin Dashboard'
      }}
      session={{
        user: {
          name: 'Dashboard Admin'
        }
      }}
      authentication={{
        signIn: () => Promise.resolve(),
        signOut: handleLogout
      }}
      theme={theme}
    >
      <DashboardLayout
        sx={styles.dashboardLayout}
        slots={{
          appTitle: getCustomAppTitle
        }}
      >
        {getPageContent({ pathname: demoRouter.pathname })}
      </DashboardLayout>
    </AppProvider>
  )
}

export default AdminDashboard
