import React, { memo, useCallback, useEffect } from 'react'

import { extractBaseUrl } from '@/shared/functions/String/string.functions'
import linkStyles from '@/shared/styles/link.styles'
import { Box, Divider, Typography } from '@mui/material'
import { FormikProps, useFormik } from 'formik'

import FlightSegments from '../FlightSegments/FlightSegments.component'
import Form from '@/shared/components/Form/Form.component'
import Link from '@/shared/components/Link/Link.component'
import Modal from '@/shared/components/Modal/Modal.component'

import styles from '../../ExternalBookings.styles'
import {
  flightDetailsFormSchema,
  initialFlightDetailsValues,
  initialTripDetailsValues,
  tripDetailsFormSchema,
  tripDetailsFormSection
} from './ExternalBookingsModal.dictionary'
import {
  ExternalBookingsModalProps,
  FlightDetailsFormValues,
  TripDetailsFormValues
} from './ExternalBookingsModal.types'

const ExternalBookingsModal: React.FC<ExternalBookingsModalProps> = (props) => {
  const { open, onClose, bookingDetails, onSubmit, airports, airlines, loading } = props

  const tripDetailsFormik: FormikProps<TripDetailsFormValues> = useFormik({
    initialValues: initialTripDetailsValues,
    validationSchema: tripDetailsFormSchema,
    onSubmit: () => {
      // Do nothing
    }
  })

  const flightDetailsFormik: FormikProps<FlightDetailsFormValues> = useFormik({
    initialValues: initialFlightDetailsValues,
    validationSchema: flightDetailsFormSchema,
    onSubmit: () => {
      // Do nothing
    }
  })

  useEffect(() => {
    if (bookingDetails?.airlineBookingDetails?.airline) {
      const airlineId = bookingDetails.airlineBookingDetails.airline.id

      initialFlightDetailsValues.slices.forEach((slice) => {
        slice.segments.forEach((segment) => {
          segment.airlineId = airlineId
        })
      })

      flightDetailsFormik.setValues(initialFlightDetailsValues)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingDetails])

  const handleCloseModal = useCallback(() => {
    tripDetailsFormik.resetForm()
    flightDetailsFormik.resetForm()
    onClose()
  }, [onClose, tripDetailsFormik, flightDetailsFormik])

  const handleTripDetailsFormChange = useCallback(
    (formik: Partial<FormikProps<unknown>>) => {
      const currentValues = tripDetailsFormik.values
      const updatedValues = formik.values as TripDetailsFormValues

      if (JSON.stringify(currentValues) !== JSON.stringify(updatedValues)) {
        tripDetailsFormik.setValues(updatedValues)
      }
    },
    [tripDetailsFormik]
  )

  const isFormValid = useCallback(() => {
    const isTripDetailsFormValid =
      tripDetailsFormik.isValid &&
      Object.keys(tripDetailsFormik.errors).length === 0 &&
      !Object.values(tripDetailsFormik.values).includes('')

    const isFlightDetailsFormValid =
      flightDetailsFormik.isValid &&
      Object.keys(flightDetailsFormik.errors).length === 0 &&
      !Object.values(flightDetailsFormik.values).includes('')

    return isTripDetailsFormValid && isFlightDetailsFormValid
  }, [tripDetailsFormik, flightDetailsFormik])

  const handleSubmit = useCallback(() => {
    onSubmit({
      tripType: tripDetailsFormik.values.tripType,
      cabinClass: tripDetailsFormik.values.cabinClass,
      tripPrice: tripDetailsFormik.values.tripPrice,
      tripCurrency: tripDetailsFormik.values.tripCurrency,
      slices: flightDetailsFormik.values.slices.map((slice) => ({
        originAirportIataCode: slice.segments[0].originAirportIataCode,
        destinationAirportIataCode: slice.segments[0].destinationAirportIataCode,
        travelDate: slice.segments[0].departureTime,
        segments: slice.segments.map((segment) => ({
          flightNumber: segment.flightNumber,
          airlineId: segment.airlineId,
          departureTime: segment.departureTime,
          arrivalTime: segment.arrivalTime,
          originAirportIataCode: segment.originAirportIataCode,
          destinationAirportIataCode: segment.destinationAirportIataCode
        }))
      }))
    })
  }, [tripDetailsFormik, flightDetailsFormik, onSubmit])

  const getModalHeader = useCallback(() => {
    if (!bookingDetails) return null

    if (bookingDetails.providerBookingType === 'airline') {
      const airlineWebsite = bookingDetails.airlineBookingDetails?.airline?.conditionsOfCarriageUrl
      const airlineWebsiteBaseUrl = airlineWebsite ? extractBaseUrl(airlineWebsite) : undefined
      const airlineName = bookingDetails.airlineBookingDetails?.airline?.name
      let airlineWebsiteLink = null

      if (airlineWebsiteBaseUrl) {
        airlineWebsiteLink = (
          <Link href={airlineWebsiteBaseUrl} sx={linkStyles} target="_blank" rel="noopener noreferrer">
            {airlineName} →
          </Link>
        )
      }

      const rows = [
        {
          label: 'Traveler',
          value: `${bookingDetails.traveler.firstName} ${bookingDetails.traveler.lastName}`
        },
        {
          label: 'Source',
          value: airlineWebsiteLink ?? airlineName
        },
        {
          label: 'Ticket Number',
          value: bookingDetails.airlineBookingDetails?.ticketNumber ?? 'N/A'
        },
        {
          label: 'Confirmation Code',
          value: bookingDetails.airlineBookingDetails?.confirmationCode ?? 'N/A'
        }
      ]

      return (
        <Box sx={styles.modalInfo}>
          {rows.map((row, index) => (
            <Box key={index} sx={styles.infoRow}>
              <Typography variant="body2" sx={styles.infoLabel}>
                {row.label}
              </Typography>

              <Typography variant="body2" sx={styles.infoValue}>
                {row.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )
    } else {
      const travelAgencyWebsite = bookingDetails.travelAgencyBookingDetails?.travelAgency?.website
      const travelAgencyName = bookingDetails.travelAgencyBookingDetails?.travelAgency?.name
      let travelAgencyWebsiteLink = null

      if (travelAgencyWebsite) {
        travelAgencyWebsiteLink = (
          <Link href={travelAgencyWebsite} sx={linkStyles} target="_blank" rel="noopener noreferrer">
            {travelAgencyName} →
          </Link>
        )
      }

      const rows = [
        {
          label: 'Traveler',
          value: `${bookingDetails.traveler.firstName} ${bookingDetails.traveler.lastName}`
        },
        {
          label: 'Source',
          value: travelAgencyWebsiteLink ?? travelAgencyName
        },
        {
          label: 'Itinerary Reference Number',
          value: bookingDetails.travelAgencyBookingDetails?.itineraryReferenceNumber ?? 'N/A'
        },
        {
          label: 'Email',
          value: bookingDetails.travelAgencyBookingDetails?.email ?? 'N/A'
        }
      ]

      return (
        <Box sx={styles.modalInfo}>
          {rows.map((row, index) => (
            <Box key={index} sx={styles.infoRow}>
              <Typography variant="body2" sx={styles.infoLabel}>
                {row.label}
              </Typography>

              <Typography variant="body2" sx={styles.infoValue}>
                {row.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )
    }
  }, [bookingDetails])

  const getModalContent = useCallback(() => {
    if (!bookingDetails) return null

    return (
      <Box sx={styles.modalContent}>
        {getModalHeader()}

        <Divider />

        <Box sx={{ mt: 3 }}>
          <Form
            initialValues={initialTripDetailsValues}
            hideButtons
            validationSchema={tripDetailsFormSchema}
            onSubmit={handleSubmit}
            onFormChange={(formik) => handleTripDetailsFormChange(formik)}
            formSections={[tripDetailsFormSection]}
          />

          {flightDetailsFormik && (
            <Box sx={styles.modalContent}>
              <Typography variant="h6" sx={styles.modalTitle}>
                Flight Details
              </Typography>

              <FlightSegments
                formik={flightDetailsFormik}
                airports={airports}
                airlines={airlines}
                tripType={tripDetailsFormik.values.tripType}
                airlineInBookingRequest={bookingDetails.airlineBookingDetails?.airline}
              />
            </Box>
          )}
        </Box>
      </Box>
    )
  }, [
    bookingDetails,
    flightDetailsFormik,
    handleSubmit,
    airports,
    airlines,
    getModalHeader,
    handleTripDetailsFormChange,
    tripDetailsFormik.values.tripType
  ])

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      maxWidth="md"
      title="Process External Flight Booking Requests"
      subtitle="Enter flight details to create an itinerary"
      body={getModalContent()}
      primaryButton={{
        label: 'Process Booking',
        loading: loading,
        tooltipText: isFormValid() ? undefined : 'You must fill in all fields to process the booking',
        disabled: !isFormValid(),
        onClick: handleSubmit
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: handleCloseModal
      }}
    />
  )
}

export default memo(ExternalBookingsModal)
