import React, { useCallback, useEffect, useState } from 'react'

import status from '@/shared/functions/Status/status.dictionaries'
import { joinStringsWithBullet } from '@/shared/functions/String/string.functions'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

import Table from '../../../../shared/components/Table/Table.component'
import Tabs from '../../../../shared/components/Tabs/Tabs.component'
import ExternalBookingsModal from './components/ExternalBookingsModal/ExternalBookingsModal.component'

import env from '../../../../networkRequests/apiClient/env.config'
import { createItineraryFromExternalFlightBookingRequestForAdmin } from '../../../../redux/states/admin/createItineraryFromExternalFlightBookingRequestForAdmin/createItineraryFromExternalFlightBookingRequestForAdmin.slice'
import { CreateItineraryFromExternalFlightBookingRequestForAdminRequestPayload } from '../../../../redux/states/admin/createItineraryFromExternalFlightBookingRequestForAdmin/createItineraryFromExternalFlightBookingRequestForAdmin.types'
import { getAllExternalFlightBookingRequestsForAdmin } from '../../../../redux/states/admin/getAllExternalFlightBookingRequestsForAdmin/getAllExternalFlightBookingRequestsForAdmin.slice'
import {
  ExternalFlightBookingRequestAdmin,
  GetAllExternalFlightBookingRequestsForAdminRequestPayload
} from '../../../../redux/states/admin/getAllExternalFlightBookingRequestsForAdmin/getAllExternalFlightBookingRequestsForAdmin.types'
import { RootState } from '../../../../redux/store/store.types'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import {
  externalFlightBookingProcessedTableColumns,
  externalFlightBookingRequestTableColumns
} from './ExternalBookings.dictionary'
import styles from './ExternalBookings.styles'
import { BookingProcessState, ExternalBookingsProps } from './ExternalBookings.types'
import { CreateItineraryFormValues } from './components/ExternalBookingsModal/ExternalBookingsModal.types'

const ExternalBookings: React.FC<ExternalBookingsProps> = (props) => {
  const { searchQuery, setExternalBookingsTabIndex, externalBookingsTabIndex } = props

  const [pendingRequestsPage, setPendingRequestsPage] = useState(1)
  const [processedRequestsPage, setProcessedRequestsPage] = useState(1)
  const [pendingRequestsTotalPages, setPendingRequestsTotalPages] = useState(1)
  const [processedRequestsTotalPages, setProcessedRequestsTotalPages] = useState(1)
  const [pendingRequestsTotalRecords, setPendingRequestsTotalRecords] = useState(0)
  const [processedRequestsTotalRecords, setProcessedRequestsTotalRecords] = useState(0)
  const [pendingRequestsData, setPendingRequestsData] = useState<ExternalFlightBookingRequestAdmin[]>([])
  const [processedRequestsData, setProcessedRequestsData] = useState<ExternalFlightBookingRequestAdmin[]>([])
  const [bookingDetailsModalOpen, setBookingDetailsModalOpen] = useState(false)
  const [bookingProcessState, setBookingProcessState] = useState<BookingProcessState>({
    requestDetails: null,
    formikInstance: null
  })

  const [allAirports, setAllAirports] = useState<{ value: string; label: string }[]>([])
  const [allAirlines, setAllAirlines] = useState<{ value: string; label: string }[]>([])

  const pageSize = env.DEFAULT_PAGE_SIZE
  const dispatch = useAppDispatch()

  const {
    loading: requestsLoading,
    success: requestsSuccess,
    error: requestsError
  } = useSelector((state: RootState) => state.getAllExternalFlightBookingRequestsForAdmin)

  const { loading: createItineraryLoading, success: createItinerarySuccess } = useSelector(
    (state: RootState) => state.createItineraryFromExternalFlightBookingRequestForAdmin
  )

  const { success: airlinesSuccess } = useSelector((state: RootState) => state.getExternalAirlines)

  const { success: countriesAndCitiesSuccess } = useSelector((state: RootState) => state.getCountriesAndCities)

  useEffect(() => {
    if (countriesAndCitiesSuccess) {
      const allAirports = countriesAndCitiesSuccess.data
        .flatMap((country) =>
          country.cities.flatMap((city) =>
            city.airports.map((airport) => ({
              value: airport.iataCode,
              label: joinStringsWithBullet([
                `${airport.iataCode} (${airport.airportName})`,
                `${city.cityName}, ${country.countryName}`
              ])
            }))
          )
        )
        .sort((a, b) => a.value.localeCompare(b.value))

      setAllAirports(allAirports)
    }
  }, [countriesAndCitiesSuccess])

  useEffect(() => {
    if (airlinesSuccess) {
      setAllAirlines(
        airlinesSuccess.data
          .map((airline) => ({
            value: airline.id,
            label: airline.name
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    }
  }, [airlinesSuccess])

  const handleGetPendingRequests = useCallback(
    (page: number = 1, size: number = pageSize, onSuccess?: () => void) => {
      const payload: GetAllExternalFlightBookingRequestsForAdminRequestPayload = {
        request: {
          page: page.toString(),
          size: size.toString(),
          searchQuery: searchQuery,
          status: status.externalFlightBookingStatus.externalFlightBookingRequestCreated
        },
        onSuccess
      }

      dispatch(getAllExternalFlightBookingRequestsForAdmin(payload))
    },
    [dispatch, searchQuery, pageSize]
  )

  const handleGetProcessedRequests = useCallback(
    (page: number = 1, size: number = pageSize, onSuccess?: () => void) => {
      const payload: GetAllExternalFlightBookingRequestsForAdminRequestPayload = {
        request: {
          page: page.toString(),
          size: size.toString(),
          searchQuery: searchQuery,
          status: status.externalFlightBookingStatus.itineraryCreatedForExternalFlightBooking
        },
        onSuccess
      }

      dispatch(getAllExternalFlightBookingRequestsForAdmin(payload))
    },
    [dispatch, searchQuery, pageSize]
  )

  const handleCreateItinerarySubmit = useCallback(
    (values: unknown) => {
      const formValues = values as CreateItineraryFormValues
      const requestDetails = bookingProcessState.requestDetails
      const requestId = requestDetails?.id

      if (requestId && requestDetails) {
        const payload: CreateItineraryFromExternalFlightBookingRequestForAdminRequestPayload = {
          request: {
            requestId: requestId.toString(),
            tripType: formValues.tripType,
            tripPrice: Number(formValues.tripPrice),
            tripCurrency: formValues.tripCurrency,
            cabinClass: formValues.cabinClass,
            slices: formValues.slices.map((slice) => ({
              originAirportIataCode: slice.originAirportIataCode,
              destinationAirportIataCode: slice.destinationAirportIataCode,
              travelDate: slice.travelDate,
              segments: slice.segments.map((segment) => ({
                flightNumber: segment.flightNumber,
                departureTime: segment.departureTime,
                arrivalTime: segment.arrivalTime,
                originAirportIataCode: segment.originAirportIataCode,
                destinationAirportIataCode: segment.destinationAirportIataCode,
                airlineId: segment.airlineId
              }))
            }))
          },
          onSuccess: () => {
            Snackbar.show({
              message: `Itinerary created successfully for ${requestDetails.traveler.firstName} ${requestDetails.traveler.lastName}`,
              severity: 'success'
            })

            handleCloseBookingDetailsModal()

            // Refresh data after successful creation
            if (externalBookingsTabIndex === 0) {
              handleGetPendingRequests()
            } else {
              handleGetProcessedRequests()
            }
          }
        }

        dispatch(createItineraryFromExternalFlightBookingRequestForAdmin(payload))
      }
    },
    [dispatch, bookingProcessState, externalBookingsTabIndex, handleGetPendingRequests, handleGetProcessedRequests]
  )

  useEffect(() => {
    if (externalBookingsTabIndex === 0) {
      handleGetPendingRequests()
    } else {
      handleGetProcessedRequests()
    }
  }, [externalBookingsTabIndex, searchQuery, handleGetPendingRequests, handleGetProcessedRequests])

  useEffect(() => {
    if (createItinerarySuccess) {
      if (externalBookingsTabIndex === 0) {
        handleGetPendingRequests()
      } else {
        handleGetProcessedRequests()
      }
    }
  }, [createItinerarySuccess, externalBookingsTabIndex, handleGetPendingRequests, handleGetProcessedRequests])

  useEffect(() => {
    if (requestsSuccess?.data) {
      const { currentPage, totalPages, totalRecords, records } = requestsSuccess.data

      if (externalBookingsTabIndex === 0) {
        setPendingRequestsTotalPages(totalPages)
        setPendingRequestsTotalRecords(totalRecords)
        setPendingRequestsPage(currentPage)
        setPendingRequestsData(records)
      } else {
        setProcessedRequestsTotalPages(totalPages)
        setProcessedRequestsTotalRecords(totalRecords)
        setProcessedRequestsPage(currentPage)
        setProcessedRequestsData(records)
      }
    }
  }, [requestsSuccess, externalBookingsTabIndex])

  const handlePendingRequestsPageChange = useCallback(
    (newPage: number) => {
      setPendingRequestsPage(newPage)
      handleGetPendingRequests(newPage)
    },
    [handleGetPendingRequests]
  )

  const handleProcessedRequestsPageChange = useCallback(
    (newPage: number) => {
      setProcessedRequestsPage(newPage)
      handleGetProcessedRequests(newPage)
    },
    [handleGetProcessedRequests]
  )

  const handleTabChange = (newValue: number) => {
    setExternalBookingsTabIndex(newValue)
  }

  const handleShowBookingDetailsModal = (request: ExternalFlightBookingRequestAdmin) => {
    setBookingProcessState({
      requestDetails: request,
      formikInstance: null
    })

    setBookingDetailsModalOpen(true)
  }

  const handleCloseBookingDetailsModal = () => {
    setBookingDetailsModalOpen(false)
    setBookingProcessState({
      requestDetails: null,
      formikInstance: null
    })
  }

  const tabs = [
    {
      label: 'Requests',
      content: (
        <Table
          columns={externalFlightBookingRequestTableColumns}
          data={pendingRequestsData}
          initialLoading={requestsLoading}
          onRowClick={(row) => handleShowBookingDetailsModal(row as ExternalFlightBookingRequestAdmin)}
          onRetry={() => handleGetPendingRequests()}
          error={!!requestsError}
          pagination={{
            currentPage: pendingRequestsPage,
            totalPages: pendingRequestsTotalPages,
            totalRecords: pendingRequestsTotalRecords,
            onNext: () => handlePendingRequestsPageChange(Math.min(pendingRequestsPage + 1, pendingRequestsTotalPages)),
            onPrevious: () => handlePendingRequestsPageChange(Math.max(pendingRequestsPage - 1, 1))
          }}
          inPage
        />
      )
    },
    {
      label: 'Processed',
      content: (
        <Table
          columns={externalFlightBookingProcessedTableColumns}
          data={processedRequestsData}
          initialLoading={requestsLoading}
          onRetry={() => handleGetProcessedRequests()}
          error={!!requestsError}
          pagination={{
            currentPage: processedRequestsPage,
            totalPages: processedRequestsTotalPages,
            totalRecords: processedRequestsTotalRecords,
            onNext: () =>
              handleProcessedRequestsPageChange(Math.min(processedRequestsPage + 1, processedRequestsTotalPages)),
            onPrevious: () => handleProcessedRequestsPageChange(Math.max(processedRequestsPage - 1, 1))
          }}
          inPage
        />
      )
    }
  ]

  return (
    <Box sx={styles.container}>
      <Tabs tabs={tabs} value={externalBookingsTabIndex} onChange={handleTabChange} />

      <ExternalBookingsModal
        open={bookingDetailsModalOpen}
        onClose={handleCloseBookingDetailsModal}
        bookingDetails={bookingProcessState.requestDetails}
        onSubmit={handleCreateItinerarySubmit}
        airports={allAirports}
        airlines={allAirlines}
        loading={createItineraryLoading}
      />
    </Box>
  )
}

export default ExternalBookings
