import { TripType } from '@/shared/functions/Flights/flights.types'

import { Leg } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'

export const getFormattedTripDetails = (legs: Leg[], tripType: TripType): string => {
  const firstLeg = legs[0]
  let tripDetails = ''

  switch (tripType) {
    case 'oneWay':
      tripDetails = `${firstLeg.origin.airport.iataCode} → ${firstLeg.destination.airport.iataCode}`
      break

    case 'roundTrip':
      tripDetails = `${firstLeg.origin.airport.iataCode} ⇌ ${firstLeg.destination.airport.iataCode}`
      break

    case 'multiCity':
      tripDetails = legs
        .map((leg) => `${leg.origin.airport.iataCode} → ${leg.destination.airport.iataCode}`)
        .join('\u00A0 • \u00A0')
      break
  }

  return tripDetails
}
