import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Divider, Link, Typography } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import ResponsiveDisplayWrapper from '../../../../shared/components/ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'
import PriceDisplay from '../Price/Price.component'

import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { joinElementsWithBullet } from '../../../../shared/functions/String/string.functions'
import styles from './ItemDetails.styles'
import { ItemDetailsProps } from './ItemDetails.types'

const ItemDetails: React.FC<ItemDetailsProps> = (props) => {
  const { item, updateQuantity, removeItemFromCart } = props

  const handleIncrease = () => {
    if (item.stockStatus === 'In Stock') {
      updateQuantity(item.quantity + 1)
    } else if (item.stockStatus === 'Low Stock') {
      if (item.quantity < item.stockLeft) {
        updateQuantity(item.quantity + 1)
      }
    }
  }

  const handleDecrease = () => {
    if (item.quantity > 1) {
      updateQuantity(item.quantity - 1)
    }
  }

  const handleDelete = () => {
    removeItemFromCart(item)
  }

  const getItemQuantityButtons = () => {
    if (item.stockStatus === 'In Stock' || item.stockStatus === 'Low Stock') {
      return (
        <Box sx={styles.quantityControlContainer}>
          <Box sx={styles.quantityControl}>
            <ResponsiveDisplayWrapper isDesktop>
              <Button
                onClick={handleDelete}
                text="Delete"
                altText="Delete item"
                iconPosition="center"
                buttonType="tertiary"
              />
            </ResponsiveDisplayWrapper>

            <Divider orientation="vertical" flexItem sx={styles.divider} />

            <Button
              onClick={handleDecrease}
              altText="Decrease item quantity"
              iconPosition="center"
              icon={<RemoveIcon />}
              buttonType="primary"
              disabled={item.quantity === 1}
              sx={styles.quantityButton}
            />

            <Typography sx={styles.quantityText}>{item.quantity}</Typography>

            <Button
              onClick={handleIncrease}
              altText="Increase item quantity"
              iconPosition="center"
              icon={<AddIcon />}
              buttonType="primary"
              disabled={item.stockStatus === 'Low Stock' && item.quantity >= item.stockLeft}
              sx={styles.quantityButton}
            />
          </Box>

          {item.stockStatus === 'Low Stock' && (
            <Typography sx={styles.lowStockText} color="error">
              Low Stock
            </Typography>
          )}
        </Box>
      )
    } else {
      return <Typography color="error">Out of Stock</Typography>
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <img src={item.images[0]} alt={item.title} style={styles.image} />
        <ResponsiveDisplayWrapper isDesktop={false}>
          <Button
            onClick={handleDelete}
            text="Delete"
            altText="Delete item"
            iconPosition="center"
            buttonType="tertiary"
          />
        </ResponsiveDisplayWrapper>
      </Box>

      <Box sx={styles.details}>
        <Typography variant="h6" sx={styles.title}>
          {item.title}
        </Typography>

        <Box sx={styles.priceQuantityContainer}>
          <Box sx={styles.priceContainer}>
            <PriceDisplay price={item.price} />

            <Typography variant="caption" sx={styles.priceValidText}>
              {joinElementsWithBullet([
                `Amazon price as of ${date(item.priceAt).format(`${dateTimeFormats.date.medium} (${dateTimeFormats.time.short})`)} EST`,
                <Link sx={styles.priceValidLink} href={item.internalUrl} target="_blank">
                  Details
                </Link>
              ])}
            </Typography>
          </Box>

          {getItemQuantityButtons()}
        </Box>
      </Box>
    </Box>
  )
}

export default ItemDetails
