import { configureRequest, replacePathVariables } from '../../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAssignedVirtualCardDetailsForAdminErrorResponse,
  GetAssignedVirtualCardDetailsForAdminRequest,
  GetAssignedVirtualCardDetailsForAdminSuccessResponse
} from './getAssignedVirtualCardDetailsForAdmin.types'

export const getAssignedVirtualCardDetailsForAdminApi = async (
  request: GetAssignedVirtualCardDetailsForAdminRequest
): Promise<GetAssignedVirtualCardDetailsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getAssignedVirtualCardDetailsForAdmin, {
      assignedVirtualCardId: request.assignedVirtualCardId
    }),
    method: 'GET',
    data: request,
    params: {},
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAssignedVirtualCardDetailsForAdminSuccessResponse
  } else {
    throw response as GetAssignedVirtualCardDetailsForAdminErrorResponse
  }
}

export default getAssignedVirtualCardDetailsForAdminApi
