import theme from './themes/default.theme'

const linkStyles = {
  textDecoration: 'none',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
}

export default linkStyles
