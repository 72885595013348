import React from 'react'

import { Avatar, Box, Typography } from '@mui/material'

import date, { dateTimeFormats } from '../../../../functions/Date/date.functions'
import styles from './ChatMessage.styles'
import { ChatMessageProps } from './ChatMessage.types'

const ChatMessage: React.FC<ChatMessageProps> = ({ message, isCurrentUser }) => {
  const formatTime = (timestamp: number) => {
    return date(timestamp).format(dateTimeFormats.time.short)
  }

  // Get first letter of the sender's name for the avatar
  const getInitial = () => {
    return message.senderName.charAt(0).toUpperCase()
  }

  return (
    <Box sx={[styles.messageWrapper, isCurrentUser ? styles.messageWrapperSent : styles.messageWrapperReceived]}>
      {!isCurrentUser && <Avatar sx={styles.avatar}>{getInitial()}</Avatar>}

      <Box sx={styles.messageContent}>
        <Box sx={isCurrentUser ? styles.messageSent : styles.messageReceived}>
          <Typography variant="body2" sx={styles.messageText(isCurrentUser)}>
            {message.text}
          </Typography>
        </Box>

        <Typography
          variant="caption"
          sx={[styles.messageTime, isCurrentUser ? styles.messageTimeSent : styles.messageTimeReceived]}
        >
          {formatTime(message.timestamp)}
        </Typography>
      </Box>
    </Box>
  )
}

export default ChatMessage
