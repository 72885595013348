import React from 'react'

import { formatMoney } from '@/shared/functions/String/string.functions'
import { Box, Divider, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import ResponsiveDisplayWrapper from '../../../../shared/components/ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'
import Price from '../Price/Price.component'

import theme from '../../../../shared/styles/themes/default.theme'
import styles from './Overview.styles'
import { OverviewProps } from './Overview.types'

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { items, currency, tax, fees, subtotal, total, getActiveButton } = props

  const overviewCurrency = currency ?? items[0].currency

  const totalItems = items
    .filter((item) => item.stockStatus !== 'Out of Stock')
    .reduce((sum, item) => sum + item.quantity, 0)

  const overviewSubtotal =
    subtotal ??
    items
      .filter((item) => item.stockStatus !== 'Out of Stock')
      .reduce((sum, item) => sum + item.price * item.quantity, 0)

  const overviewTotal = total ?? overviewSubtotal + (fees ?? 0) + (tax ?? 0)

  const getOverViewSubtotal = () => {
    return (
      overviewSubtotal && (
        <Box sx={styles.row}>
          <Typography sx={[styles.fieldText, styles.totalText]}>
            {totalItems} item{totalItems > 1 ? 's' : ''}
          </Typography>

          <Typography sx={styles.fieldText}>{formatMoney(overviewSubtotal, overviewCurrency)}</Typography>
        </Box>
      )
    )
  }

  const getHurrierFees = () => {
    return (
      fees && (
        <Box sx={styles.row}>
          <Typography sx={styles.fieldText}>Delivery Fees</Typography>

          <Typography sx={styles.fieldText}>{formatMoney(fees, overviewCurrency)}</Typography>
        </Box>
      )
    )
  }

  const getTax = () => {
    return (
      tax && (
        <Box sx={styles.row}>
          <Typography sx={styles.fieldText}>Tax</Typography>

          <Typography sx={styles.fieldText}>
            <ResponsiveDisplayWrapper isDesktop={false}>~&nbsp;</ResponsiveDisplayWrapper>
            {formatMoney(tax, overviewCurrency)}
          </Typography>
        </Box>
      )
    )
  }

  return (
    <Box sx={styles.container}>
      <Box>
        <ResponsiveDisplayWrapper isDesktop={true}>
          <>
            <Typography variant="h6" sx={styles.title}>
              Overview
            </Typography>

            <Divider sx={styles.divider} />
          </>
        </ResponsiveDisplayWrapper>

        <Box sx={styles.subTotalRow}>
          <ResponsiveDisplayWrapper isDesktop={true}>
            <Typography variant="subtitle1" sx={styles.subtotalTitleText}>
              Subtotal
            </Typography>
          </ResponsiveDisplayWrapper>

          <ResponsiveDisplayWrapper isDesktop={false}>
            <Accordion
              expandIconPosition="right"
              maxWidth={theme.spacing(total ? 15 : 7.5)}
              header={{
                mainContent: (
                  <Typography variant="subtitle1" sx={styles.subtotalTitleText}>
                    {total ? 'Amount To Pay' : 'Subtotal'}
                  </Typography>
                )
              }}
              body={
                <>
                  {getOverViewSubtotal()}
                  {getTax()}
                  {getHurrierFees()}
                </>
              }
            />
          </ResponsiveDisplayWrapper>
        </Box>

        <ResponsiveDisplayWrapper isDesktop={false}>
          <Typography sx={styles.totalValueText}>
            {formatMoney(total ? overviewTotal : overviewSubtotal, overviewCurrency)}
          </Typography>
        </ResponsiveDisplayWrapper>

        <ResponsiveDisplayWrapper isDesktop>{getOverViewSubtotal()}</ResponsiveDisplayWrapper>

        <ResponsiveDisplayWrapper isDesktop>{getTax()}</ResponsiveDisplayWrapper>

        <ResponsiveDisplayWrapper isDesktop>{getHurrierFees()}</ResponsiveDisplayWrapper>

        {total && (
          <ResponsiveDisplayWrapper isDesktop>
            <Divider sx={styles.divider} />

            <Box sx={styles.amountToPayContainer}>
              <Typography variant="body1" sx={styles.amountToPayText}>
                Amount to Pay
              </Typography>

              <Price showSeparator={true} currency={overviewCurrency} superscript={false} price={overviewTotal} />
            </Box>
          </ResponsiveDisplayWrapper>
        )}
      </Box>

      {getActiveButton && getActiveButton()}
    </Box>
  )
}

export default Overview
