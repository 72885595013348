import theme from '../../styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },

  divider: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    gap: theme.spacing(3)
  },

  skeletonStepperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(2)
  },

  skeletonStepsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },

  skeletonStep: {
    borderRadius: theme.spacing(2)
  },

  skeletonButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(1)
  },

  trackingDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      paddingTop: 0
    }
  },

  trackingDetailsTitle: {
    fontSize: theme.typography.body1.fontSize,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      color: theme.palette.grey[500]
    }
  },

  trackingDetailsStepperContainer: (hasChildren: boolean) => ({
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingBottom: hasChildren ? theme.spacing(3) : 0
    }
  }),

  trackingStatusActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  trackingDetailsFormContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2)
  },

  trackingDetailsFormInput: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 2)
    }
  },

  trackingDetailsFormButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    padding: theme.spacing(0, 2),
    marginLeft: theme.spacing(2),
    height: theme.spacing(5)
  },

  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  }
}
