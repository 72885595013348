import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  autocompletePaper: {
    boxShadow: theme.shadows[5],
    maxWidth: 300,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      maxWidth: 'unset',
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2)
    }
  }
}

export default styles
