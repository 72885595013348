import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getUserTripsForAdminApi } from './getUserTripsForAdmin.api'
import getUserTripsForAdminInitialState from './getUserTripsForAdmin.initialState'
import { GetUserTripsForAdminErrorResponse, GetUserTripsForAdminRequestPayload } from './getUserTripsForAdmin.types'

export const getUserTripsForAdmin = createAsyncThunk(
  urls.admin.getUserTripsForAdmin,
  async (payload: GetUserTripsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getUserTripsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getUserTripsForAdminSlice = createSlice({
  name: urls.admin.getUserTripsForAdmin,
  initialState: getUserTripsForAdminInitialState,
  reducers: {
    resetGetUserTripsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTripsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getUserTripsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getUserTripsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetUserTripsForAdminErrorResponse
        state.success = null
      })
  }
})

export const { actions: getUserTripsForAdminActions, reducer: getUserTripsForAdminReducer } = getUserTripsForAdminSlice
export const { resetGetUserTripsForAdmin } = getUserTripsForAdminActions
export default getUserTripsForAdminSlice.reducer
