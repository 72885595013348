import React, { useEffect } from 'react'

import { Box, Typography } from '@mui/material'

import EmptyState from '../../shared/components/EmptyState/EmptyState.component'
import Loader from '../../shared/components/Loader/Loader.component'
import StepByStepGuide from '../../shared/components/StepByStepGuide/StepByStepGuide.component'
import { MarketplaceLinksInput } from '../Shop/components/MarketplaceLinksInput/MarketplaceLinksInput.component'

import { getFlightItinerary } from '../../redux/states/flights/getFlightItinerary/getFlightItinerary.slice'
import { GetFlightItineraryRequestPayload } from '../../redux/states/flights/getFlightItinerary/getFlightItinerary.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import status from '../../shared/functions/Status/status.dictionaries'
import { UserRole } from '../../shared/functions/UserRole/userRoleManagement.types'
import { setUserRole } from '../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import useResponsiveness from '../../shared/hooks/responsive.hooks'
import dictionary from '../Shop/Shop.dictionary'
import styles from './SharedTrip.styles'

export const SharedTrip: React.FC = () => {
  const { success: flightItineraryResponse, loading: flightItineraryLoading } = useAppSelector(
    (state: RootState) => state.getFlightItinerary
  )
  const [isDesktop] = useResponsiveness()

  const dispatch = useAppDispatch()
  const flightItineraryId = router.getUrlParam('trip-id')

  useEffect(() => {
    setUserRole(UserRole.shopper)
  }, [])

  useEffect(() => {
    const getAllFlightItinerariesPayload: GetFlightItineraryRequestPayload = {
      request: {
        id: flightItineraryId
      }
    }

    dispatch(getFlightItinerary(getAllFlightItinerariesPayload))
  }, [flightItineraryId, dispatch])

  const renderMainContent = () => {
    const isFlightValid = flightItineraryResponse?.data?.status.name === status.flightStatus.interestedInFlight

    if (flightItineraryLoading) {
      return <Loader loading={flightItineraryLoading} text="Getting the trip details" size="large" marginTop={10} />
    } else if (!flightItineraryLoading && !flightItineraryResponse?.data) {
      return (
        <Box sx={styles.sharedTripContainer}>
          <EmptyState title="No trip found" subtitle="Please check the link and try again" />
        </Box>
      )
    } else if (!isFlightValid) {
      return (
        <Box sx={styles.sharedTripContainer}>
          <EmptyState title="Trip unavailable" subtitle="This trip is no longer accepting orders" />
        </Box>
      )
    } else if (flightItineraryResponse?.data) {
      return (
        <Box sx={styles.sharedTripContainer}>
          <Box sx={styles.pageTextContainer}>
            <Typography variant={isDesktop ? 'h4' : 'h5'} sx={styles.title}>
              {flightItineraryResponse?.data.traveler.firstName}'s Trip
            </Typography>

            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={styles.subtitle}>
              Please copy and place your Amazon url orders below. Select Item destination on the payment page.
            </Typography>
          </Box>

          <Box sx={styles.stepByStepGuideContainer}>
            <StepByStepGuide steps={dictionary.howToTravelAndEarn.steps} isCompact />
          </Box>

          <MarketplaceLinksInput flightItinerary={flightItineraryResponse?.data} />
        </Box>
      )
    }
  }

  return <Box sx={styles.container}>{renderMainContent()}</Box>
}

export default SharedTrip
