import React from 'react'

import { CheckCircleOutline } from '@mui/icons-material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box, Chip, Typography } from '@mui/material'

import Card from '../../../../../../../../../../shared/components/Card/Card.component'
import Link from '../../../../../../../../../../shared/components/Link/Link.component'
import Table from '../../../../../../../../../../shared/components/Table/Table.component'

import date from '../../../../../../../../../../shared/functions/Date/date.functions'
import { dateTimeFormats } from '../../../../../../../../../../shared/functions/Date/date.functions'
import { getStatusColor } from '../../../../../../../../../../shared/functions/Status/status.functions'
import {
  formatMoney,
  joinElementsWithBullet,
  joinStringsWithBullet,
  truncateString
} from '../../../../../../../../../../shared/functions/String/string.functions'
import { ItemRequestCardProps } from '../../OrderDetails.types'
import styles from './ItemRequestCard.styles'

const ItemRequestCard: React.FC<ItemRequestCardProps> = (props) => {
  const { itemRequest } = props
  const { item } = itemRequest

  const subtitle = joinElementsWithBullet([
    item.asin,
    <Typography variant="caption" sx={styles.availabilityStatus(item.availabilityStatus)}>
      {item.availabilityStatus}
    </Typography>
  ]) as string

  const link = (
    <Link href={item.url} target="_blank" rel="noopener noreferrer" sx={styles.link}>
      View on Amazon
    </Link>
  )

  const linkAndSubtitle = joinElementsWithBullet([link, subtitle]) as string

  return (
    <Card
      header={{
        left: {
          component: (
            <Box sx={styles.productImageContainer}>
              <img src={item.pictureUrl} style={styles.productImage} alt="Product image" />
            </Box>
          ),
          title: truncateString(item.productName, 85),
          subtitle: linkAndSubtitle
        },
        right: {
          component: <Chip label={itemRequest.status.displayName} color={getStatusColor(itemRequest.status.name)} />
        }
      }}
      body={{
        content: (
          <>
            <Box sx={styles.contentSection}>
              <Table
                title="Item Details"
                columns={[
                  { key: 'country', label: 'Country' },
                  { key: 'asin', label: 'ASIN' },
                  { key: 'quantity', label: 'Quantity' },
                  { key: 'weight', label: 'Weight' },
                  { key: 'price', label: 'Latest Amazon Price' }
                ]}
                data={[
                  {
                    country: item.sourceCountry.name,
                    asin: item.asin,
                    quantity: item.quantity,
                    weight: `${item.weight} ${item.weightUnit ? item.weightUnit : 'lbs'}`,
                    price: formatMoney(item.latestPriceOnAmazon, item.currency)
                  }
                ]}
              />
            </Box>

            <Box sx={styles.contentSection}>
              <Table
                title="Price Details"
                columns={[
                  { key: 'acceptedPrice', label: 'Accepted Price' },
                  { key: 'paidByShopper', label: 'Paid by Shopper' },
                  { key: 'deliveryFee', label: 'Delivery Fee' },
                  { key: 'tax', label: 'Tax' }
                ]}
                data={[
                  {
                    acceptedPrice: formatMoney(item.acceptedItemPrice, item.currency),
                    paidByShopper: formatMoney(item.pricePaidByShopper, item.currency),
                    deliveryFee: formatMoney(item.deliveryFee, item.currency),
                    tax: formatMoney(item.tax, item.currency)
                  }
                ]}
              />
            </Box>

            <Box sx={styles.contentSection}>
              <Table
                title="Tax Details"
                columns={[
                  { key: 'jurisdiction', label: 'Jurisdiction' },
                  { key: 'rate', label: 'Tax Rate' },
                  { key: 'isTaxed', label: 'Is Taxed' },
                  { key: 'exemptions', label: 'Has Exemptions' }
                ]}
                data={[
                  {
                    jurisdiction: joinStringsWithBullet([
                      item.taxDetails.jurisdiction.state,
                      item.taxDetails.jurisdiction.country.name
                    ]),
                    rate: `${(item.taxDetails.rate * 100).toFixed(1)}%`,
                    isTaxed: item.taxDetails.isTaxed ? 'Yes' : 'No',
                    exemptions: item.taxDetails.conditionalExemptions ? 'Yes' : 'No'
                  }
                ]}
              />
            </Box>

            <Box sx={styles.contentSection}>
              <Table
                title="Traveler Details"
                columns={[
                  { key: 'benefit', label: 'Benefit' },
                  { key: 'initialAmount', label: 'Initial Amount Allocated' },
                  { key: 'finalPrice', label: 'Final Price Committed to Paying' }
                ]}
                data={[
                  {
                    benefit: item.travelerBenefit ? formatMoney(item.travelerBenefit, item.currency) : '-',
                    initialAmount: item.initialAmountAllocatedToTraveler
                      ? formatMoney(item.initialAmountAllocatedToTraveler, item.currency)
                      : '-',
                    finalPrice: item.finalPriceTravelerCommittedToPaying
                      ? formatMoney(item.finalPriceTravelerCommittedToPaying, item.currency)
                      : '-'
                  }
                ]}
              />
            </Box>
          </>
        ),
        additionalContent: {
          labelText: joinStringsWithBullet(['View Price Change History', item.priceChangeDetails.length.toString()]),
          component: (
            <>
              <Table
                columns={[
                  { key: 'change', label: 'Change' },
                  { key: 'oldValue', label: 'Old Value' },
                  { key: 'newValue', label: 'New Value' },
                  { key: 'changeTime', label: 'Change Time' },
                  { key: 'acceptedByShopper', label: 'Accepted by Shopper' }
                ]}
                data={item.priceChangeDetails.map((change) => ({
                  change:
                    change.newValue > change.oldValue ? (
                      <ArrowUpwardIcon color="error" />
                    ) : (
                      <ArrowDownwardIcon color="success" />
                    ),
                  oldValue: formatMoney(change.oldValue, item.currency),
                  newValue: formatMoney(change.newValue, item.currency),
                  changeTime: date(change.changeTime).format(dateTimeFormats.date.long),
                  acceptedByShopper: change.acceptedByShopper ? <CheckCircleOutline color="success" /> : '-'
                }))}
              />
            </>
          )
        }
      }}
    />
  )
}

export default ItemRequestCard
