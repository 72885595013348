import { SxProps, Theme } from '@mui/material'

import theme from '../../styles/themes/default.theme'

export const styles = {
  stepsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  stepContent: (isLast: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    paddingRight: {
      xs: theme.spacing(0),
      sm: isLast ? theme.spacing(0) : theme.spacing(4),
      md: isLast ? theme.spacing(0) : theme.spacing(4),
      lg: isLast ? theme.spacing(0) : theme.spacing(4)
    },
    paddingBottom: { xs: theme.spacing(4), sm: theme.spacing(4), md: theme.spacing(0) }
  }),

  step: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(3)
    }
  },

  icon: (isCompact: boolean) => ({
    background: `linear-gradient(30deg, transparent 0%, #F2E2F9 100%)`,
    color: '#421785',
    borderRadius: '50%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: isCompact ? '45px' : '90px',
    height: isCompact ? '45px' : '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: isCompact ? '42px' : '70px',
      height: isCompact ? '42px' : '70px'
    },
    '& svg': {
      fontSize: isCompact ? '20px' : '40px',
      width: isCompact ? '20px' : '40px',
      height: isCompact ? '20px' : '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: isCompact ? '15px' : '30px',
        width: isCompact ? '15px' : '30px',
        height: isCompact ? '15px' : '30px'
      }
    }
  }),

  stepTitle: (isCompact: boolean) =>
    ({
      marginBottom: theme.spacing(1),
      fontSize: isCompact ? '14px' : '24px',
      fontWeight: 'bold',
      textAlign: { sm: 'center', md: 'left' },
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        textAlign: 'center'
      }
    }) as SxProps<Theme>,

  stepDescription: (isCompact: boolean) =>
    ({
      color: theme.palette.text.secondary,
      fontSize: isCompact ? '12px' : '16px',
      textAlign: { sm: 'center', md: 'left' },
      [theme.breakpoints.down('md')]: {
        textAlign: 'left'
      }
    }) as SxProps<Theme>,

  rotatedIcon: {
    transform: 'rotate(-45deg)'
  }
}
