import React, { useMemo } from 'react'

import { formatMoney } from '@/shared/functions/String/string.functions'
import useResponsiveness from '@/shared/hooks/responsive.hooks'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { ChevronDown } from 'lucide-react'

import Button from '../../../../shared/components/Button/Button.component'
import Price from '../../../Cart/components/Price/Price.component'
import Accordion from '@/shared/components/Accordion/Accordion.component'

import date, { dateTimeFormats, formatDuration } from '../../../../shared/functions/Date/date.functions'
import styles from './TripOverview.styles'
import { TripOverviewProps } from './TripOverview.types'

const TripOverview: React.FC<TripOverviewProps> = (props) => {
  const {
    offerData,
    baggageSelections,
    seatSelections,
    itinerary,
    onEditFlight,
    currency,
    discount,
    fees,
    tax,
    subtotal,
    total,
    overviewAccordionButton,
    isExternalBooking
  } = props

  const [isDesktop] = useResponsiveness()

  const baggageCost = Object.values(baggageSelections ?? {}).reduce(
    (total, passengerSelections) =>
      total +
      Object.values(passengerSelections).reduce(
        (passengerTotal, baggageItems) =>
          passengerTotal + baggageItems.reduce((itemTotal, item) => itemTotal + item.cost, 0),
        0
      ),
    0
  )

  const seatCost = Object.values(seatSelections ?? {}).reduce(
    (total, passengerSeats) =>
      total +
      Object.values(passengerSeats).reduce((passengerTotal, seat) => passengerTotal + (Number(seat?.price) ?? 0), 0),
    0
  )

  const nestedSubtotal = offerData ? Number(offerData.total_amount) : itinerary?.totalFlightPrice
  const nestedCurrency = offerData ? offerData.base_currency : itinerary?.currency
  const overviewSubtotal = subtotal ? subtotal - (baggageCost + seatCost) : nestedSubtotal ?? 0
  const overviewCurrency = currency ?? nestedCurrency
  const overviewTotal = total ?? overviewSubtotal + baggageCost + seatCost
  const flightTotal = overviewSubtotal - (discount ?? 0)

  const slices = useMemo(() => {
    if (offerData) {
      return offerData.slices.map((slice, index) => {
        return {
          id: `slice-${index}`,
          airlineLogo: offerData.owner.logo_symbol_url,
          origin: {
            iata_code: slice.origin.iata_code,
            city_name: slice.origin.city_name
          },
          destination: {
            iata_code: slice.destination.iata_code,
            city_name: slice.destination.city_name
          },
          segments: slice.segments.map((segment, index) => ({
            id: index,
            departing_at: segment.departing_at,
            arriving_at: segment.arriving_at,
            duration: segment.duration,
            origin: {
              iata_code: segment.origin.iata_code,
              city_name: segment.origin.city_name
            },
            destination: {
              iata_code: segment.destination.iata_code,
              city_name: segment.destination.city_name
            }
          }))
        }
      })
    } else if (itinerary?.slices) {
      const airlineLogo = itinerary.slices[0].segments[0].airline.logoUrl

      return itinerary.slices.map((slice, index) => {
        return {
          id: `slice-${index}`,
          airlineLogo,
          origin: {
            iata_code: slice.origin.city.iataCode,
            city_name: slice.origin.city.name
          },
          destination: {
            iata_code: slice.destination.city.iataCode,
            city_name: slice.destination.city.name
          },
          segments: slice.segments.map((segment, index) => ({
            id: index,
            departing_at: segment.departureTime,
            arriving_at: segment.arrivalTime,
            duration: segment.duration,
            origin: {
              iata_code: segment.origin.airport.iataCode,
              city_name: segment.origin.airport.name
            },
            destination: {
              iata_code: segment.destination.airport.iataCode,
              city_name: segment.destination.airport.name
            }
          }))
        }
      })
    } else {
      return []
    }
  }, [offerData, itinerary])

  const overviewItems = [
    {
      title: 'Flight (incl. taxes)',
      value: overviewSubtotal,
      show: true
    },
    {
      title: 'Extra Baggage',
      value: baggageCost,
      show: baggageCost !== 0,
      isPositive: true
    },
    {
      title: 'Seats',
      value: seatCost,
      show: seatCost !== 0,
      isPositive: true
    },
    {
      title: 'Flight Discount',
      value: discount ?? 0,
      show: !isExternalBooking && discount ? discount > 0 : false,
      isNegative: true,
      isBold: true
    },
    {
      title: 'Flight Total',
      value: flightTotal,
      show: !isExternalBooking,
      isTitle: true,
      isBold: true
    },
    {
      title: 'Amount to Receive',
      value: Math.abs(overviewTotal),
      show: isExternalBooking && total && !isDesktop,
      isBold: true,
      tooltip: 'You will receive this amount after you have delivered the items.'
    },
    {
      title: 'Amazon Credit',
      value: fees && tax ? fees + tax : 0,
      show: fees && tax ? fees + tax !== 0 : false,
      isSpecial: true,
      isBold: true,
      tooltip: 'You will receive a virtual card with the value of the Amazon items to purchase these items on Amazon.'
    }
  ]

  const getFlights = () => {
    return (
      <Box>
        {slices.map((slice) => (
          <Box key={slice.id} sx={styles.section}>
            <Box sx={styles.sectionContent}>
              <Typography variant="body2" sx={styles.sectionTitle}>
                {slice.origin.city_name} to {slice.destination.city_name}
              </Typography>

              {slice.segments.map((segment) => (
                <Box key={segment.id}>
                  <Typography sx={styles.segmentValueText} variant="body2">
                    {date(segment.departing_at).format(dateTimeFormats.date.long)}
                  </Typography>

                  <Box sx={styles.gap} />

                  <Typography sx={styles.segmentValueText} variant="body2">
                    <span style={styles.timeSpan}>
                      {date(segment.departing_at).format(dateTimeFormats.time.short)} -{' '}
                      {date(segment.arriving_at).format(dateTimeFormats.time.short)}
                    </span>{' '}
                    ({formatDuration(segment.duration)})
                  </Typography>

                  <Typography sx={styles.segmentValueText} variant="body2">
                    {segment.origin.iata_code} - {segment.destination.iata_code}
                  </Typography>
                </Box>
              ))}
            </Box>

            <img src={slice.airlineLogo} alt="Airline Logo" style={styles.logo} />
          </Box>
        ))}
      </Box>
    )
  }

  const getPriceSummary = () => {
    return (
      <Box sx={styles.priceSummaryContainer}>
        <Typography variant="h6" sx={styles.priceSummaryTitle}>
          Price Summary
        </Typography>

        {overviewItems.map((item, index) => {
          if (item.show) {
            return (
              <Box sx={styles.row(index === overviewItems.length - 1)}>
                <Box sx={styles.rowContentTitle}>
                  <Typography
                    variant={item.isTitle ? 'h6' : 'body1'}
                    sx={styles.subtotalTitleText(item.isBold, item.isSpecial)}
                  >
                    {item.title}
                  </Typography>

                  {item.tooltip && (
                    <Tooltip title={item.tooltip} color={item.isSpecial ? 'primary' : 'default'}>
                      <InfoOutlinedIcon sx={styles.infoIcon} />
                    </Tooltip>
                  )}
                </Box>

                <Typography
                  variant={item.isTitle ? 'h6' : 'body1'}
                  sx={styles.subtotalValue(item.isBold, item.isSpecial)}
                >
                  {item.isPositive && '+ '}
                  {item.isNegative && '- '}
                  {formatMoney(item.value, overviewCurrency)}
                </Typography>
              </Box>
            )
          } else {
            return null
          }
        })}
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={styles.cardTop}>
          <Typography variant="h5" sx={styles.title}>
            Overview
          </Typography>

          {onEditFlight && (
            <Button
              onClick={onEditFlight}
              text="Edit"
              altText="Edit flight"
              iconPosition="center"
              buttonType="tertiary"
            />
          )}
        </Box>

        <Divider sx={styles.divider} />

        {getFlights()}

        <Divider sx={styles.divider} />

        {getPriceSummary()}

        {total && (
          <>
            <Divider sx={styles.divider} />

            <Box sx={styles.amountToPayContainer}>
              <Typography variant="h6" sx={styles.amountToPayTitle}>
                Amount to {overviewTotal <= 0 || isExternalBooking ? 'Receive' : 'Pay'}
              </Typography>

              <Price
                showSeparator={true}
                currency={overviewCurrency}
                superscript={false}
                price={Math.abs(overviewTotal)}
              />
            </Box>
          </>
        )}
      </Box>

      <Box sx={styles.accordionContainer}>
        <Accordion
          noExpandIcon
          header={{
            row: true,
            fullWidth: true,
            buttons: [...(overviewAccordionButton ? [overviewAccordionButton] : [])],
            mainContent: (
              <Box sx={styles.accordionMainContent}>
                <Typography>Amount to {overviewTotal <= 0 || isExternalBooking ? 'Receive' : 'Pay'}</Typography>

                <Typography>{formatMoney(Math.abs(overviewTotal), overviewCurrency)}</Typography>

                <Button
                  buttonType="primary"
                  variant="text"
                  text="View Overview"
                  icon={<ChevronDown size={16} />}
                  animateIconOnPress
                  sx={styles.viewOverviewButton}
                  iconPosition="end"
                />
              </Box>
            )
          }}
          body={
            <Box sx={styles.accordionBody}>
              {getFlights()}

              <Divider sx={styles.divider} />

              {getPriceSummary()}
            </Box>
          }
        />
      </Box>
    </Box>
  )
}

export default TripOverview
