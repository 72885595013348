import React from 'react'

import { Box } from '@mui/material'

import styles from './ResponsiveDisplayWrapper.styles'
import { ResponsiveDisplayWrapperProps } from './ResponsiveDisplayWrapper.types'

const ResponsiveDisplayWrapper: React.FC<ResponsiveDisplayWrapperProps> = ({
  isDesktop,
  children,
  style,
  fullWidth
}) => {
  const combinedStyles = {
    ...(isDesktop ? styles.desktop : styles.mobile),
    ...(style ?? {}),
    ...(fullWidth ? styles.fullWidth : {})
  }

  return <Box sx={combinedStyles}>{children}</Box>
}

export default ResponsiveDisplayWrapper
