import { CSSProperties } from 'react'

import { mobileXPadding } from '@/layouts/MainLayout/MainLayout.constants'
import { fullWidthIgnorePadding } from '@/shared/functions/Styling/styling.functions'
import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[50],
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper
    }
  },

  stepper: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5)
  },

  tripSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(5, 10),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      marginTop: theme.spacing(6),
      flexDirection: 'column-reverse',
      gap: theme.spacing(5),
      backgroundColor: theme.palette.background.paper
    }
  },

  tripSelectorGrid: {
    width: '100%'
  },

  requiredFlightsTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800]
  },

  requiredFlightsSubtitle: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '28px'
  },

  shareFlightItineraryLinkContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  },

  requiredFlightsContainer: {
    // paddingTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: 'calc(100vh - 64px - 56px)'
  },

  requiredFlightsItemsContainer: {
    marginTop: theme.spacing(20)
  },

  iconContainer: {
    marginBottom: theme.spacing(2)
  },

  infoIcon: {
    height: '80px',
    width: '80px',
    color: theme.palette.secondary.main
  },

  infoText: {
    textAlign: 'center',
    color: theme.palette.grey[700]
  } as CSSProperties,

  linkText: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  } as CSSProperties,

  searchBarContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(-mobileXPadding),
      marginTop: theme.spacing(-5),
      display: 'flex',
      width: fullWidthIgnorePadding(mobileXPadding)
    }
  } as SxProps,

  searchIcon: {
    color: theme.palette.primary.main
  } as SxProps,

  flightSearchWidgetWrapper: {
    backgroundColor: 'red',
    width: '100%'
  },

  flightDetails: {
    display: 'flex',
    flexDirection: 'column',
    color: 'red',
    alignItems: 'flex-start'
  },

  flightDetailsTitle: {
    color: theme.palette.grey[800],
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium
  },

  flightDetailsSubtitle: {
    color: theme.palette.grey[500],
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium
  }
}

export default styles
