import React, { useCallback, useMemo } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Link from '../../../../shared/components/Link/Link.component'
import TrackingDetails from '../../../../shared/components/TrackingDetails/TrackingDetails.component'

import { acceptItemPriceChange } from '../../../../redux/states/items/acceptItemPriceChange/acceptItemPriceChange.slice'
import { AcceptItemPriceChangeRequestPayload } from '../../../../redux/states/items/acceptItemPriceChange/acceptItemPriceChange.types'
import { cancelItemRequest } from '../../../../redux/states/offers/cancelItemRequest/cancelItemRequest.slice'
import { CancelItemRequestRequestPayload } from '../../../../redux/states/offers/cancelItemRequest/cancelItemRequest.types'
import {
  clearItemRequestTrackingDetailsSuccess,
  getItemRequestTrackingDetails
} from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import { GetItemRequestTrackingDetailsRequestPayload } from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { reassignItemRequest } from '../../../../redux/states/offers/reassignItemRequest/reassignItemRequest.slice'
import { ReassignItemRequestRequestPayload } from '../../../../redux/states/offers/reassignItemRequest/reassignItemRequest.types'
import { RootState } from '../../../../redux/store/store.types'
import { AccordionAlertProps, AccordionButtonProps } from '../../../../shared/components/Accordion/Accordion.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import status from '../../../../shared/functions/Status/status.dictionaries'
import { formatMoney } from '../../../../shared/functions/String/string.functions'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import useResponsiveness from '../../../../shared/hooks/responsive.hooks'
import styles from './ItemRequestAccordion.styles'
import { ItemRequestAccordionProps } from './ItemRequestAccordion.types'

const ItemRequestAccordion: React.FC<ItemRequestAccordionProps> = (props) => {
  const { itemRequest, deliveryDetails, tripDetails, refreshOfferRequests, isInteractionEnabled } = props

  const [expandedItemId, setExpandedItemId] = React.useState<string | null>(null)
  const [loadingAcceptItemIds, setLoadingAcceptItemIds] = React.useState<string[]>([])
  const [loadingCancelItemIds, setLoadingCancelItemIds] = React.useState<string[]>([])
  const [loadingReassignItemIds, setLoadingReassignItemIds] = React.useState<string[]>([])

  const { loading: cancelItemRequestLoading } = useAppSelector((state: RootState) => state.cancelItemRequest)
  const { loading: acceptItemPriceChangeLoading } = useAppSelector((state: RootState) => state.acceptItemPriceChange)
  const { loading: reassignItemRequestLoading } = useAppSelector((state: RootState) => state.reassignItemRequest)
  const { loading: getOfferRequestsLoading } = useAppSelector((state: RootState) => state.getOfferRequests)

  const [isDesktop, isLargeDesktop] = useResponsiveness()

  const dispatch = useAppDispatch()

  const currentAlert = useMemo(() => {
    if (
      itemRequest.itemDetails.priceChangeDetails.length > 0 ||
      itemRequest.itemDetails.availabilityChangeDetails.length > 0
    ) {
      const latestPriceChangeDetail = itemRequest.itemDetails.priceChangeDetails[0]
      const latestAvailabilityChangeDetail = itemRequest.itemDetails.availabilityChangeDetails[0]

      if (latestAvailabilityChangeDetail) {
        const itemOutOfStock =
          latestAvailabilityChangeDetail.changed &&
          latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
          latestAvailabilityChangeDetail.oldValue === 'In Stock'

        if (itemOutOfStock) {
          return {
            testId: `out-of-stock-alert-${itemRequest.itemRequestId}`,
            message: 'The item is currently out of stock on Amazon.',
            type: 'warning',
            tag: 'out-of-stock',
            buttons: [
              {
                text: 'Cancel',
                testId: `cancel-item-request-button-${itemRequest.itemRequestId}`,
                onClick: () => {
                  handleCancel()
                },
                loading:
                  (cancelItemRequestLoading || getOfferRequestsLoading) &&
                  loadingCancelItemIds.includes(itemRequest.itemRequestId.toString())
              }
            ]
          } as AccordionAlertProps
        }
      } else if (latestPriceChangeDetail) {
        const itemPriceIncreased =
          latestPriceChangeDetail.changed &&
          latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
          latestPriceChangeDetail.newValue !== itemRequest.acceptedItemPrice

        const isLoadingCancel =
          (cancelItemRequestLoading || getOfferRequestsLoading) &&
          loadingCancelItemIds.includes(itemRequest.itemRequestId.toString())

        const isLoadingAccept =
          (acceptItemPriceChangeLoading || getOfferRequestsLoading) &&
          loadingAcceptItemIds.includes(itemRequest.itemRequestId.toString())

        if (itemPriceIncreased && latestPriceChangeDetail.acceptedByShopper === false) {
          return {
            message: `Price changed on Amazon from ${formatMoney(latestPriceChangeDetail.oldValue, itemRequest.itemDetails.currency)} to ${formatMoney(latestPriceChangeDetail.newValue, itemRequest.itemDetails.currency)}.`,
            type: 'warning',
            tag: 'price-increased',
            testId: `price-increased-alert-${itemRequest.itemRequestId}`,
            buttons: [
              {
                text: 'Accept Change',
                testId: `accept-change-button-${itemRequest.itemRequestId}`,
                loading: isLoadingAccept,
                onClick: () => {
                  handleAcceptPriceChange()
                }
              },
              {
                text: 'Cancel',
                testId: `cancel-item-request-button-${itemRequest.itemRequestId}`,
                loading: isLoadingCancel,
                onClick: () => {
                  handleCancel()
                }
              }
            ]
          } as AccordionAlertProps
        }
      }
    }

    return null

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    itemRequest.itemDetails.priceChangeDetails,
    itemRequest.itemDetails.availabilityChangeDetails,
    itemRequest.acceptedItemPrice,
    cancelItemRequestLoading,
    loadingCancelItemIds,
    loadingAcceptItemIds,
    acceptItemPriceChangeLoading,
    itemRequest.itemDetails.currency,
    getOfferRequestsLoading
  ])

  const handleTrackButtonPress = useCallback(
    (itemRequestId: number) => {
      const itemRequestIdString = itemRequestId.toString()

      if (expandedItemId === itemRequestIdString) {
        setExpandedItemId(null)
        dispatch(clearItemRequestTrackingDetailsSuccess())
      } else {
        const payload: GetItemRequestTrackingDetailsRequestPayload = {
          request: {
            itemRequestId: itemRequestIdString
          }
        }

        setExpandedItemId(itemRequestIdString)
        dispatch(getItemRequestTrackingDetails(payload))
      }
    },
    [dispatch, expandedItemId]
  )

  const handleAccordionExpansion = useCallback(
    (isExpanded: boolean, accordionKey: string | number | undefined) => {
      if (isExpanded) {
        setExpandedItemId(accordionKey?.toString() ?? null)
        handleTrackButtonPress(itemRequest.itemRequestId)
      } else {
        setExpandedItemId(null)
      }
    },
    [itemRequest.itemRequestId, handleTrackButtonPress]
  )

  const handleCancel = useCallback(() => {
    const payload: CancelItemRequestRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },
      onSuccess: () => {
        refreshOfferRequests(() =>
          setLoadingCancelItemIds((prev) => prev.filter((id) => id !== itemRequest.itemRequestId.toString()))
        )
      },
      onError: () => {
        setLoadingCancelItemIds((prev) => prev.filter((id) => id !== itemRequest.itemRequestId.toString()))
      }
    }

    Modal.show({
      title: 'Cancel Item Request',
      body: 'Are you sure you want to cancel this item request? This will remove the item from your order.',
      testId: `cancel-item-request-modal-${itemRequest.itemRequestId}`,
      primaryButton: {
        label: 'Yes, Cancel',
        onClick: () => {
          setLoadingCancelItemIds((prev) => [...prev, itemRequest.itemRequestId.toString()])
          dispatch(cancelItemRequest(payload))
        }
      },
      secondaryButton: {
        label: 'Dismiss'
      }
    })
  }, [dispatch, itemRequest.itemRequestId, refreshOfferRequests])

  const handleAcceptPriceChange = useCallback(() => {
    const payload: AcceptItemPriceChangeRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },
      onSuccess: () => {
        refreshOfferRequests(() =>
          setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequest.itemRequestId.toString()))
        )
      }
    }

    setLoadingAcceptItemIds((prev) => [...prev, itemRequest.itemRequestId.toString()])
    dispatch(acceptItemPriceChange(payload))
  }, [dispatch, itemRequest.itemRequestId, refreshOfferRequests])

  const handleFindTraveler = useCallback(() => {
    const payload: ReassignItemRequestRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },
      onSuccess: () => {
        refreshOfferRequests(() =>
          setLoadingReassignItemIds((prev) => prev.filter((id) => id !== itemRequest.itemRequestId.toString()))
        )
      }
    }

    setLoadingReassignItemIds((prev) => [...prev, itemRequest.itemRequestId.toString()])
    dispatch(reassignItemRequest(payload))
  }, [dispatch, itemRequest.itemRequestId, refreshOfferRequests])

  const renderItemDetails = useCallback(() => {
    const itemRequestAccordionHeaderButtons: AccordionButtonProps[] = []
    const priceIncreaseDetected = currentAlert?.tag === 'price-increased'
    const itemOutOfStock = currentAlert?.tag === 'out-of-stock'

    const isTrackButtonVisible = () => {
      const deliveryStatus = itemRequest?.itemDetails.status?.name
      const isFlightBooked = tripDetails?.tripStatus === status.flightStatus.flightBooked
      let isVisible = false
      let isValidDeliveryStatus = false

      switch (deliveryStatus) {
        case status.itemRequestStatus.acceptedByTraveler:
        case status.deliveryStatus.purchasedByTraveler:
        case status.deliveryStatus.inTransitToTraveler:
        case status.deliveryStatus.deliveredToTravelerAddress:
        case status.deliveryStatus.confirmedReceivedByTraveler:
        case status.deliveryStatus.confirmedInDestinationCountry:
        case status.deliveryStatus.confirmedDeliveredToShopperByTraveler:
          isValidDeliveryStatus = true
          break
      }

      if (isFlightBooked && isValidDeliveryStatus && !itemOutOfStock) {
        isVisible = true
      }

      return isVisible
    }

    const cancelItemRequestButton: AccordionButtonProps = {
      text: 'Cancel',
      buttonType: 'tertiary',
      testId: `cancel-item-request-button-${itemRequest.itemRequestId}`,
      fullWidth: !isLargeDesktop,
      onClick: handleCancel,
      disabled:
        loadingAcceptItemIds.includes(itemRequest.itemRequestId.toString()) ||
        (cancelItemRequestLoading && loadingCancelItemIds.includes(itemRequest.itemRequestId.toString())),
      loading:
        (cancelItemRequestLoading || getOfferRequestsLoading) &&
        loadingCancelItemIds.includes(itemRequest.itemRequestId.toString())
    }

    switch (itemRequest?.itemDetails.status?.name) {
      case status.itemRequestStatus.acceptedByTraveler:
        // No buttons needed for accepted state
        break

      case status.itemRequestStatus.reassignedByShopper:
      case status.itemRequestStatus.pendingAcceptanceByTraveler:
        if (!currentAlert) {
          itemRequestAccordionHeaderButtons.push(cancelItemRequestButton)
        }
        break

      case status.itemRequestStatus.deniedByTraveler:
        if (!currentAlert) {
          itemRequestAccordionHeaderButtons.push(cancelItemRequestButton)
        }

        itemRequestAccordionHeaderButtons.push({
          text: 'Find Traveler',
          buttonType: 'primary',
          fullWidth: !isLargeDesktop,
          onClick: handleFindTraveler,
          loading:
            (reassignItemRequestLoading || getOfferRequestsLoading) &&
            loadingReassignItemIds.includes(itemRequest.itemRequestId.toString())
        })
        break

      case status.itemRequestStatus.cancelledByShopper:
        itemRequestAccordionHeaderButtons.push({
          text: 'Canceled',
          buttonType: 'tertiary',
          disabled: true,
          testId: `canceled-item-request-button-${itemRequest.itemRequestId}`,
          fullWidth: !isLargeDesktop
        })
        break

      case status.deliveryStatus.confirmedDeliveredToShopperByShopper:
        itemRequestAccordionHeaderButtons.push({
          text: getUserRole() === UserRole.shopper ? 'Received' : 'Delivered',
          buttonType: 'primary',
          disabled: true,
          fullWidth: !isLargeDesktop
        })

        break

      default:
        if (!isTrackButtonVisible() && !currentAlert) {
          itemRequestAccordionHeaderButtons.push(cancelItemRequestButton)
        }
        break
    }

    if (isTrackButtonVisible()) {
      // itemRequestAccordionHeaderButtons.push({
      //   buttonType: 'tertiary',
      //   icon: <ChatOutlined />,
      //   iconPosition: 'center',
      //   onClick: () => {
      //     const currentUser: ConversationParticipant = {
      //       hurrierUserId: shopperDetails.userId.toString(),
      //       firebaseUserId: shopperDetails.firebaseUserId ?? '',
      //       firstName: shopperDetails.firstName,
      //       lastName: shopperDetails.lastName,
      //       role: UserRole.shopper
      //     }

      //     const chatPartner: ConversationParticipant = {
      //       hurrierUserId: tripDetails.traveler.userId.toString(),
      //       firebaseUserId: tripDetails.traveler.firebaseUserId ?? '',
      //       firstName: tripDetails.traveler.firstName,
      //       lastName: tripDetails.traveler.lastName,
      //       role: UserRole.traveler
      //     }

      //     const orderId = offerRequestId.toString()

      //     openChat(currentUser, chatPartner, orderId)
      //   },
      //   tooltipText: `Message ${tripDetails.traveler.firstName}`
      // })

      itemRequestAccordionHeaderButtons.push({
        text: 'Track',
        icon: (
          <ExpandMore
            style={{
              transform: expandedItemId === itemRequest.itemRequestId.toString() ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          />
        ),
        iconPosition: 'end',
        fullWidth: !isLargeDesktop,
        onClick: () => handleTrackButtonPress(itemRequest.itemRequestId),
        expandAccordion: true,
        buttonType: 'primary'
      })
    }

    if (priceIncreaseDetected && !currentAlert) {
      itemRequestAccordionHeaderButtons.push({
        text: 'Accept Change',
        buttonType: 'primary',
        onClick: handleAcceptPriceChange,
        fullWidth: !isLargeDesktop,
        loading:
          (acceptItemPriceChangeLoading || getOfferRequestsLoading) &&
          loadingAcceptItemIds.includes(itemRequest.itemRequestId.toString())
      })
    }

    // Disable interaction if the item request is not interactable
    if (!isInteractionEnabled) {
      itemRequestAccordionHeaderButtons.length = 0
    }

    const getQuantity = () => {
      return (
        <Grid item md={2} sx={styles.shopperInfoContainer}>
          <Typography sx={styles.rowTitleText}>Quantity</Typography>

          <Typography sx={styles.rowValueText}>
            {itemRequest.itemDetails.quantity} item{itemRequest.itemDetails.quantity > 1 ? 's' : ''}
          </Typography>
        </Grid>
      )
    }

    const getPrice = () => {
      return (
        <Grid item md={4} sx={styles.priceContainer}>
          <Typography sx={styles.rowTitleText}>Price</Typography>

          <Typography sx={styles.rowValueText}>
            {`${formatMoney(parseFloat(itemRequest.itemDetails.price), itemRequest.itemDetails.currency)}`}
          </Typography>

          <Typography sx={styles.amazonPriceText}>
            Amazon price as of{' '}
            {date(itemRequest.itemDetails.priceAt).format(
              `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
            )}{' '}
            EST
          </Typography>
        </Grid>
      )
    }

    const itemRequestAccordionHeader = {
      mainContent: (
        <Grid item key={itemRequest.itemRequestId} sx={styles.itemRequestContainer}>
          <Grid item md={6} sx={styles.itemDetailsContainer}>
            <Box sx={styles.itemImageContainer}>
              <img
                src={itemRequest.itemDetails.images[0]}
                alt={itemRequest.itemDetails.title}
                style={styles.itemImage}
              />
            </Box>

            <Box sx={styles.itemDetailsInfoContainer}>
              <Typography sx={styles.itemTitle}>{itemRequest.itemDetails.title}</Typography>

              <Link sx={styles.itemDetailsLinkText} href={itemRequest.itemDetails.internal_url} target="_blank">
                View on Amazon
              </Link>
            </Box>
          </Grid>

          <Divider
            orientation={isDesktop ? 'vertical' : 'horizontal'}
            flexItem
            sx={styles.itemDetailsDivider(isDesktop ? 'vertical' : 'horizontal')}
          />

          {isDesktop ? (
            <>
              {getQuantity()}
              <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider('vertical')} />
              {getPrice()}
            </>
          ) : (
            <Box sx={styles.itemQuantityAndPriceContainer}>
              {getQuantity()}
              <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider('vertical')} />
              {getPrice()}
            </Box>
          )}
        </Grid>
      ),
      buttons: itemRequestAccordionHeaderButtons
    }

    const getTrackingDetails = () => (
      <TrackingDetails
        itemRequestId={itemRequest.itemRequestId}
        isOpen={expandedItemId === itemRequest.itemRequestId.toString()}
        key={itemRequest.itemRequestId}
        refreshMainList={refreshOfferRequests}
        destinationCity={deliveryDetails.city.name}
      />
    )

    const isTrackingDetailsAccessible =
      itemRequest.itemDetails.status.name === status.itemRequestStatus.acceptedByTraveler &&
      tripDetails?.tripStatus === status.flightStatus.flightBooked

    return (
      <Accordion
        header={itemRequestAccordionHeader}
        body={getTrackingDetails()}
        handleExpansion={handleAccordionExpansion}
        noExpandIcon
        noExpandBackgroundColor
        preventExpandChange={!isTrackingDetailsAccessible}
        alert={currentAlert || undefined}
        accordionKey={itemRequest.itemRequestId}
        hasBorder
      />
    )
  }, [
    itemRequest,
    loadingAcceptItemIds,
    loadingCancelItemIds,
    loadingReassignItemIds,
    expandedItemId,
    handleCancel,
    handleAcceptPriceChange,
    handleFindTraveler,
    handleTrackButtonPress,
    currentAlert,
    acceptItemPriceChangeLoading,
    cancelItemRequestLoading,
    deliveryDetails,
    getOfferRequestsLoading,
    handleAccordionExpansion,
    isInteractionEnabled,
    reassignItemRequestLoading,
    refreshOfferRequests,
    tripDetails,
    isDesktop,
    isLargeDesktop
  ])

  return <Box>{renderItemDetails()}</Box>
}

export default ItemRequestAccordion
