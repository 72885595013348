import theme from '../../styles/themes/default.theme'

export const styles = {
  container: {
    textAlign: 'center',
    width: '100%'
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },

  headerContentLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  },

  headerContentRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(2)
  },

  title: (headerAlignment: 'left' | 'center') => ({
    marginBottom: theme.spacing(1),
    fontSize: '48px',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px'
    },
    textAlign: headerAlignment
  }),

  subtitle: (headerAlignment: 'left' | 'center') => ({
    color: theme.palette.text.secondary,
    fontSize: '24px',
    marginBottom: theme.spacing(6),
    textAlign: headerAlignment,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
      fontSize: '16px'
    }
  }),

  childrenContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}
