import { FormSection } from '@/shared/components/Form/Form.types'
import { getCabinClasses, getTripTypes } from '@/shared/functions/Flights/flights.functions'
import * as Yup from 'yup'

import { FlightDetailsFormValues, TripDetailsFormValues } from './ExternalBookingsModal.types'

export const tripDetailsFormSchema = Yup.object({
  tripType: Yup.string().required('Trip type is required'),
  cabinClass: Yup.string().required('Cabin class is required'),
  tripPrice: Yup.number().required('Trip price is required').min(0, 'Trip price must be a positive number'),
  tripCurrency: Yup.string().required('Currency is required')
})

export const flightDetailsFormSchema = Yup.object({
  slices: Yup.array()
    .of(
      Yup.object({
        segments: Yup.array()
          .of(
            Yup.object({
              flightNumber: Yup.string().required('Flight number is required'),
              airlineId: Yup.string().required('Airline is required'),
              departureTime: Yup.string().required('Departure time is required'),
              arrivalTime: Yup.string().required('Arrival time is required'),
              originAirportIataCode: Yup.string().required('Origin airport is required'),
              destinationAirportIataCode: Yup.string().required('Destination airport is required')
            })
          )
          .min(1, 'At least one segment is required')
      })
    )
    .min(1, 'At least one flight leg is required')
})

export const initialTripDetailsValues: TripDetailsFormValues = {
  tripType: 'roundTrip',
  cabinClass: 'economy',
  tripPrice: '',
  tripCurrency: 'USD'
}

export const initialFlightDetailsValues: FlightDetailsFormValues = {
  slices: [
    {
      segments: [
        {
          flightNumber: '',
          airlineId: '',
          departureTime: '',
          arrivalTime: '',
          originAirportIataCode: '',
          destinationAirportIataCode: ''
        }
      ]
    },
    {
      segments: [
        {
          flightNumber: '',
          airlineId: '',
          departureTime: '',
          arrivalTime: '',
          originAirportIataCode: '',
          destinationAirportIataCode: ''
        }
      ]
    }
  ]
}

export const currencies = [
  { value: 'USD', label: 'USD' }
  // { value: 'EUR', label: 'EUR' },
  // { value: 'GBP', label: 'GBP' },
  // { value: 'CAD', label: 'CAD' },
  // { value: 'JMD', label: 'JMD' },
  // { value: 'AUD', label: 'AUD' },
  // { value: 'NZD', label: 'NZD' },
  // { value: 'ZAR', label: 'ZAR' },
  // { value: 'HKD', label: 'HKD' },
  // { value: 'BRL', label: 'BRL' },
  // { value: 'ARS', label: 'ARS' },
  // { value: 'CLP', label: 'CLP' },
  // { value: 'COP', label: 'COP' },
  // { value: 'MXN', label: 'MXN' },
  // { value: 'PEN', label: 'PEN' },
  // { value: 'UYU', label: 'UYU' }
]

export const tripDetailsFormSection: FormSection = {
  title: 'Trip Details',
  fields: [
    {
      name: 'tripType',
      label: 'Trip Type',
      type: 'select',
      options: getTripTypes(),
      grid: {
        sm: 3
      },
      required: true
    },
    {
      name: 'cabinClass',
      label: 'Cabin Class',
      type: 'select',
      grid: {
        sm: 3
      },
      options: getCabinClasses(),
      required: true
    },
    {
      name: 'tripCurrency',
      label: 'Currency',
      type: 'select',
      grid: {
        sm: 3
      },
      options: currencies,
      required: true
    },
    {
      name: 'tripPrice',
      label: 'Trip Price',
      type: 'number',
      grid: {
        sm: 3
      },
      required: true,
      placeholder: 'Enter trip price'
    }
  ]
}
