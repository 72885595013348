import { SxProps, Theme } from '@mui/material'

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  } as SxProps<Theme>,

  modalContent: {
    mt: 3
  } as SxProps<Theme>,

  modalHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mb: 2
  } as SxProps<Theme>,

  modalTitle: {
    mb: 2
  } as SxProps<Theme>,

  modalSubtitle: {
    color: 'text.secondary',
    fontSize: 14
  } as SxProps<Theme>,

  modalInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mb: 3,
    p: 2,
    bgcolor: 'background.paper',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'divider'
  } as SxProps<Theme>,

  infoRow: {
    display: 'flex',
    justifyContent: 'space-between'
  } as SxProps<Theme>,

  infoLabel: {
    fontWeight: 500,
    color: 'text.secondary'
  } as SxProps<Theme>,

  infoValue: {
    fontWeight: 500
  } as SxProps<Theme>,

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
    mt: 3
  } as SxProps<Theme>,

  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    p: 4
  } as SxProps<Theme>,

  emptyContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    p: 4
  } as SxProps<Theme>
}

export default styles
