import theme from '../../styles/themes/default.theme'

const styles = {
  headingWrapper: {
    width: '100%',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    }
  },

  carouselWrapperContainer: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%',
    gap: '32px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      maxWidth: '100vw'
    }
  },

  carouselWrapper: {
    flex: '0 0 40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flex: '1 0 auto',
      position: 'relative'
    }
  },

  contentWrapper: {
    flex: '0 0 60%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '30px',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      paddingX: '20px',
      paddingY: 0,
      pointerEvents: 'none'
    }
  },

  testimonialImage: {
    width: '100%',
    height: '320px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius / 4,
    [theme.breakpoints.down('md')]: {
      height: '450px',
      width: '100%'
    }
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },

  testimonialContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      marginY: 'auto'
    }
  },

  heading: {
    fontSize: '48px',
    textAlign: 'left',
    fontWeight: 600
  },

  subHeading: {
    color: 'text.secondary',
    fontSize: '24px',
    display: 'block',
    textAlign: 'left'
  },

  quote: {
    textAlign: 'left',
    minHeight: 85,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      color: 'white',
      borderRadius: theme.shape.borderRadius / 4,
      padding: '10px',
      minHeight: 'unset',
      background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))'
    }
  },

  userInfo: {
    marginTop: 'auto',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
      borderRadius: theme.shape.borderRadius / 4,
      padding: '10px',
      background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))'
    }
  },

  name: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      color: 'white'
    }
  },

  userTitle: {
    color: 'text.secondary',
    fontStyle: 'italic',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      color: 'white'
    }
  },

  statsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '60px',
    marginTop: '20px',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      gap: '20px',
      marginTop: '5px',
      background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
      padding: '10px',
      borderRadius: theme.shape.borderRadius / 4
    }
  },

  statItem: {
    textAlign: 'center'
  },

  statIconText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px'
  },

  statValue: {
    fontWeight: 'bold',
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      color: 'white'
    }
  },

  statLabel: {
    color: 'text.secondary',
    fontSize: '1.25rem',
    display: 'block',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      color: 'white'
    }
  },

  icon: {
    width: '22px',
    height: '23px',
    color: 'black',
    [theme.breakpoints.down('md')]: {
      color: 'white'
    }
  }
}

export default styles
