import React from 'react'

import { Box, Grid2 as Grid, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'

import EmptyState from '../../../../../../../../shared/components/EmptyState/EmptyState.component'
import Table from '../../../../../../../../shared/components/Table/Table.component'
import OfferRequestsAccordion from './components/OfferRequestsAccordion/OfferRequestsAccordion.component'
import PaymentDetailsCard from './components/PaymentDetailsCard/PaymentDetailsCard.component'
import TripDetailsCard from './components/TripDetailsCard/TripDetailsCard.component'

import { GetTripDetailsForAdminData } from '../../../../../../../../redux/states/admin/trips/getTripDetailsForAdmin/getTripDetailsForAdmin.types'
import { RootState } from '../../../../../../../../redux/store/store.types'
import { tripDetailsTableColumns } from './TripDetails.dictionary'
import styles from './TripDetails.styles'
import { TripDetailsProps } from './TripDetails.types'

const TripDetails: React.FC<TripDetailsProps> = () => {
  const {
    loading: tripDetailsLoading,
    success: tripDetailsSuccess,
    error: tripDetailsError
  } = useSelector((state: RootState) => state.getTripDetailsForAdmin)

  const renderTripOverviewDetails = () => {
    if (tripDetailsLoading) {
      return (
        <Box sx={styles.skeletonCard}>
          <Skeleton variant="text" width="40%" height={40} />
          <Skeleton variant="rectangular" sx={styles.skeletonCard} height={120} />
        </Box>
      )
    } else if (tripDetailsSuccess) {
      return (
        <Box sx={styles.tripOverviewTableContainer}>
          <Table
            columns={tripDetailsTableColumns}
            data={[tripDetailsSuccess?.data].filter(Boolean) as GetTripDetailsForAdminData[]}
            initialLoading={tripDetailsLoading}
            error={!!tripDetailsError}
            title="Trip Overview"
          />
        </Box>
      )
    } else {
      return <EmptyState title="No trip details found" />
    }
  }

  const renderTripDetails = () => {
    return <TripDetailsCard />
  }

  const renderPaymentDetails = () => {
    return <PaymentDetailsCard />
  }

  const renderOfferRequests = () => {
    if (tripDetailsSuccess?.data.tripDetails.flightItineraryDetails.id) {
      return <OfferRequestsAccordion tripId={tripDetailsSuccess?.data.tripDetails.flightItineraryDetails.id} />
    }
  }

  return (
    <Box sx={styles.container}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6.5 }}>
          {renderTripOverviewDetails()}
          {renderTripDetails()}
        </Grid>

        <Grid size={{ xs: 12, md: 5.5 }}>
          {renderOfferRequests()}
          {renderPaymentDetails()}
        </Grid>
      </Grid>
    </Box>
  )
}

export default TripDetails
