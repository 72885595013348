import React from 'react'

import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import Accordion from '../../../../../../../../shared/components/Accordion/Accordion.component'
import EmptyState from '../../../../../../../../shared/components/EmptyState/EmptyState.component'
import ItemRequestCard from './components/ItemRequestCard/ItemRequestCard.component'
import PaymentDetailsCard from './components/PaymentDetailsCard/PaymentDetailsCard.component'
import TripDetailsCard from './components/TripDetailsCard/TripDetailsCard.component'

import { RootState } from '../../../../../../../../redux/store/store.types'
import { joinStringsWithBullet } from '../../../../../../../../shared/functions/String/string.functions'
import styles from './OrderDetails.styles'
import { OrderDetailsProps } from './OrderDetails.types'

const OrderDetails: React.FC<OrderDetailsProps> = () => {
  const {
    loading: itemRequestsLoading,
    success: itemRequestsSuccess,
    error: itemRequestsError
  } = useSelector((state: RootState) => state.getAllItemRequestsForAdmin)

  const {
    loading: paymentDetailsLoading,
    success: paymentDetailsSuccess,
    error: paymentDetailsError
  } = useSelector((state: RootState) => state.getOfferRequestPaymentDetailsForAdmin)

  const {
    loading: tripDetailsLoading,
    success: tripDetailsSuccess,
    error: tripDetailsError
  } = useSelector((state: RootState) => state.getOfferRequestTripDetailsForAdmin)

  const renderItemRequests = () => {
    if (itemRequestsLoading) {
      return <Skeleton variant="rectangular" sx={styles.skeletonCard} height={850} />
    } else {
      let content = null
      let title = 'Item Requests'

      if (itemRequestsSuccess?.data.itemRequests) {
        title = joinStringsWithBullet([
          'Item Requests',
          itemRequestsSuccess?.data.itemRequests.length?.toString()
        ]) as string

        content = (
          <Box sx={styles.contentContainer}>
            {itemRequestsSuccess.data.itemRequests.map((itemRequest) => (
              <ItemRequestCard key={itemRequest.id} itemRequest={itemRequest} />
            ))}
          </Box>
        )
      } else if (itemRequestsError) {
        content = (
          <EmptyState
            title="No item requests found"
            subtitle="No item requests found"
            errorId={itemRequestsError.correlationId}
          />
        )
      }

      return (
        <Accordion
          header={{
            mainContent: <Typography variant="subtitle1">{title}</Typography>
          }}
          body={content}
          isExpanded={true}
          preventExpandChange
          noExpandIcon
        />
      )
    }

    return null
  }

  const renderTripDetails = () => {
    if (tripDetailsLoading) {
      return <Skeleton variant="rectangular" sx={styles.skeletonCard} height={50} />
    } else {
      let content = null

      if (tripDetailsSuccess?.data) {
        content = (
          <Box sx={styles.contentContainer}>
            <TripDetailsCard tripDetails={tripDetailsSuccess.data} />
          </Box>
        )
      } else if (tripDetailsError) {
        content = (
          <EmptyState
            title="No trip details found"
            subtitle="No traveler has accepted this offer has yet"
            errorId={tripDetailsError.correlationId}
          />
        )
      }

      return (
        <Accordion
          header={{
            mainContent: 'Trip Details'
          }}
          body={content}
          isExpanded={false}
        />
      )
    }
  }

  const renderPaymentDetails = () => {
    if (paymentDetailsLoading) {
      return <Skeleton variant="rectangular" sx={styles.skeletonCard} height={850} />
    } else {
      let content = null

      if (paymentDetailsSuccess?.data) {
        const paymentDetails = paymentDetailsSuccess.data

        content = (
          <Box sx={styles.contentContainer}>
            <PaymentDetailsCard paymentDetails={paymentDetails} service="order" />
          </Box>
        )
      } else if (paymentDetailsError) {
        content = (
          <EmptyState
            title="No payment details found"
            subtitle="No payment details found"
            errorId={paymentDetailsError.correlationId}
          />
        )
      }

      return (
        <Accordion
          header={{
            mainContent: 'Payment Details'
          }}
          body={content}
          isExpanded={true}
        />
      )
    }
  }

  return (
    <Box sx={styles.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6.5}>
          {renderItemRequests()}
        </Grid>

        <Grid item xs={12} md={5.5}>
          {renderTripDetails()}
          {renderPaymentDetails()}
        </Grid>
      </Grid>
    </Box>
  )
}

export default OrderDetails
