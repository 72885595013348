import { mobileXPadding, mobileYPadding } from '../../../../layouts/MainLayout/MainLayout.constants'
import { fullWidthIgnorePadding } from '../../../../shared/functions/Styling/styling.functions'
import theme from '../../../../shared/styles/themes/default.theme'

const containerWidth = fullWidthIgnorePadding(mobileXPadding)

const styles = {
  largeWidgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { md: 2, xs: 3 },
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    padding: { md: theme.spacing(5), xs: theme.spacing(3) },
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[10]
  },

  compactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('md')]: {
      paddingY: theme.spacing(2),
      paddingX: theme.spacing(3),
      maxWidth: containerWidth,
      minWidth: containerWidth,
      minHeight: '64px',
      marginTop: theme.spacing(-mobileYPadding)
    }
  },

  searchAreaContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      justifyContent: 'unset',
      gap: 0
    }
  },

  buttonContainer: (hasErrors: boolean) => ({
    display: 'flex',
    flexDirection: { md: 'row', xs: 'column' },
    justifyContent: hasErrors ? 'space-between' : 'flex-end',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('md')]: { marginTop: theme.spacing(-2) }
  }),

  buttonWrapper: {
    [theme.breakpoints.down('md')]: { marginTop: theme.spacing(2), width: '100%' }
  },

  largeButton: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1)
  },

  compactButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    height: '50px'
  },

  compactButtonMobile: (loading: boolean) => ({
    display: 'flex',
    alignSelf: 'flex-start',
    gap: theme.spacing(1),
    height: theme.spacing(4),
    width: theme.spacing(loading ? 16.75 : 12.75),
    marginLeft: theme.spacing(1.5)
  }),

  errorListContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap'
  },

  errorList: {},

  errorListItem: {
    color: theme.palette.error.main,

    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',

    alignItems: 'center',
    display: 'block',

    width: '100%'
  }
}

export default styles
