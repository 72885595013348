const virtualCardModalDictionary = {
  virtualCardProcessing:
    'Your virtual card is being processed and should be available shortly. You will be notified via email when it is ready.',
  virtualCardAssigned: 'Use this card to pay for the items on Amazon.',
  virtualCardExpired: 'Your virtual card has expired. Please request a new one.',
  virtualCardRemoved:
    'Your virtual card is no longer available. If you have not yet purchased any items, feel free to reach out to support for assistance.'
}

export default virtualCardModalDictionary
