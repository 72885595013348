import theme from '../../styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down('md')]: {
      padding: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }
  },

  shareIconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: '170px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      minWidth: '64px',
      minHeight: '64px'
    }
  },

  shareIcon: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      minWidth: '64px',
      minHeight: '64px'
    }
  },

  icon: {
    width: '40px',
    height: '40px'
  },

  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    }
  },

  description: {
    textAlign: 'center',
    color: theme.palette.grey[500]
  },

  shareButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2)
    }
  },

  shareTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  shareContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(2),
      borderRadius: theme.shape.borderRadius / 4,
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2)
    }
  }
}

export default styles
