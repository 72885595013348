import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Masonry } from '@mui/lab'
import { Alert, AlertTitle, Box, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'

import Button from '../Button/Button.component'
import EmptyState from '../EmptyState/EmptyState.component'
import Loader from '../Loader/Loader.component'
import PageTitle from '../PageTitle/PageTitle.component'
import SearchBar from '../SearchBar/SearchBar.component'
import UniversalFilterSideBar from '../UniversalFilterSideBar/UniversalFilterSideBar.component'
import FilterMenu from './components/FilterMenu/FilterMenu.component'
import SkeletonLoading from './components/SkeletonLoading/SkeletonLoading.component'
import InfiniteScroll from 'react-infinite-scroll-component'

import theme from '../../../shared/styles/themes/default.theme'
import useResponsiveness from '../../hooks/responsive.hooks'
import { SearchBarRef } from '../SearchBar/SearchBar.types'
import { FilterSectionConfig, Filters } from '../UniversalFilterSideBar/UniversalFilterSideBar.types'
import { styles } from './PaginatedPage.styles'
import { PaginatedAlerts, PaginatedPageProps, PaginatedResponse } from './PaginatedPage.types'

const PaginatedPage: React.ForwardRefRenderFunction<HTMLDivElement, PaginatedPageProps> = (props, _ref) => {
  const {
    title,
    subtitle,
    headerButton,
    isFetchingData: initialIsFetchingData,
    fetchCall,
    renderListData: RenderListData,
    emptyState,
    hideFilters,
    filterEmptyState,
    onResetSearch,
    horizontalFilterTabs,
    verticalFilters,
    searchBar,
    layout,
    filterMutateDef,
    rightContainer,
    removeExtraPadding = false,
    fullWidth = false,
    alerts
  } = props

  const [listData, setListData] = useState<unknown[]>([])
  const [visibleListData, setVisibleListData] = useState<unknown[]>([])
  const [page, setPage] = useState(1)
  const [, setTotalPages] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [paginatedLoading, setPaginatedLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(horizontalFilterTabs ? horizontalFilterTabs[0].id : '')
  const [, setSearchQuery] = useState<string>('')
  const [lastFilters, setLastFilters] = useState<Filters>({})
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const initialFetchRef = useRef(false)
  const filterSidebarRef = useRef<{ resetFilters: () => void }>(null)
  const searchBarRef = useRef<SearchBarRef>(null)
  const [selectedFilters, setSelectedFilters] = useState<Filters | undefined>(undefined)
  const [builtVerticalFilters, setBuiltVerticalFilters] = useState<FilterSectionConfig[]>([])

  const [isDesktop] = useResponsiveness()

  const fetchData = useCallback(
    async (onSuccess?: (data?: unknown[]) => void) => {
      const response: PaginatedResponse<unknown> = await fetchCall(1)

      setListData(response.results)
      setVisibleListData(response.results)
      setTotalPages(response.totalPages)
      setPage(response.currentPage + 1)
      setHasMore(response.currentPage < response.totalPages)

      onSuccess?.()
    },
    [fetchCall]
  )

  const fetchMoreData = useCallback(async () => {
    if (paginatedLoading) return

    setPaginatedLoading(true)

    try {
      const response: PaginatedResponse<unknown> = await fetchCall(page)
      const newListData = [...listData, ...response.results]

      setListData(newListData)
      setBuiltVerticalFilters(verticalFilters?.(newListData, lastFilters) || [])

      if (Object.keys(lastFilters).length > 0) {
        // Apply filters to the new data
        const filteredData = newListData.filter((item) => {
          return verticalFilters?.(newListData, lastFilters).every((section) => {
            return section.filterDef ? section.filterDef(item, lastFilters) : true
          })
        })

        if (filterMutateDef) {
          const mutatedData = filterMutateDef(filteredData, lastFilters)
          setVisibleListData(mutatedData)
        } else {
          setVisibleListData(filteredData)
        }
      } else {
        setVisibleListData((prev) => [...prev, ...response.results])
      }

      setTotalPages(response.totalPages)
      setPage(response.currentPage + 1)
      setHasMore(response.currentPage < response.totalPages)
    } finally {
      setPaginatedLoading(false)
    }
  }, [fetchCall, page, paginatedLoading, verticalFilters, lastFilters, listData, filterMutateDef])

  useEffect(() => {
    if (!initialFetchRef.current) {
      fetchMoreData()
      initialFetchRef.current = true
    }
  }, [fetchMoreData])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const filteredListData = horizontalFilterTabs
    ? horizontalFilterTabs.find((tab) => tab.id === activeTab)?.filterDef(visibleListData) || visibleListData
    : visibleListData

  const handleTabChange = async (_event: React.SyntheticEvent, newValue: string) => {
    const selectedTab = horizontalFilterTabs?.find((tab) => tab.id === newValue)

    setActiveTab(newValue)
    setPage(1)
    setVisibleListData([])

    handleResetSearch()

    if (selectedTab?.isApiFilter) {
      initialFetchRef.current = false
      await fetchMoreData()
    } else {
      initialFetchRef.current = true
      setVisibleListData(listData)
    }
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)

    if (searchBar) {
      const filteredData = searchBar.onSearchFilter(listData, query)
      setVisibleListData(filteredData)
    }
  }

  const hideAllFilters = useMemo(() => {
    return hideFilters ? hideFilters(listData) : false
  }, [listData, hideFilters])

  const isListDataEmpty = useMemo(() => {
    return listData.length === 0
  }, [listData])

  const handleResetSearch = () => {
    setSearchQuery('')
    setVisibleListData(listData)
    onResetSearch?.()
    filterSidebarRef.current?.resetFilters()
    searchBarRef.current?.clear()
  }

  const handleFilterChange = (newFilters: Filters) => {
    setLastFilters(newFilters)

    const filteredData = listData.filter((item) => {
      return verticalFilters?.(listData, newFilters).every((section) => {
        return section.filterDef ? section.filterDef(item, newFilters) || section.forceShow?.(item) : true
      })
    })
    if (filterMutateDef) {
      const mutatedData = filterMutateDef(filteredData, newFilters)
      setVisibleListData(mutatedData)
    } else {
      setVisibleListData(filteredData)
    }
  }

  const getMasonryColumns = useCallback(() => {
    if (layout === 'masonry') {
      let numberOfColumns = 1

      if (windowWidth < theme.breakpoints.values.lg) {
        numberOfColumns = 1
      } else {
        numberOfColumns = 2
      }

      return numberOfColumns
    } else {
      return 1
    }
  }, [windowWidth, layout])

  const getRenderedList = () => {
    return filteredListData.map((item, index) => (
      <RenderListData
        key={index}
        item={item}
        refreshData={fetchData}
        index={index}
        listLength={filteredListData?.length ?? 0}
      />
    ))
  }

  const renderScrollContent = () => {
    if (filteredListData.length === 0) {
      return (
        <EmptyState
          title={filterEmptyState?.title ?? `No ${title.id.toLowerCase()} found that match your search criteria`}
          subtitle={filterEmptyState?.subtitle ?? 'Try changing your filter settings.'}
          button={filterEmptyState?.button ?? { text: 'Reset Search', onClick: handleResetSearch }}
        />
      )
    } else {
      //The functionality for displaying the end message was not working as expected on mobile when the list is wrapped in a masonry container.
      return isDesktop && layout === 'masonry' ? (
        <Masonry
          columns={getMasonryColumns()}
          spacing={2}
          sx={(layout === 'masonry' ? styles.masonryContainer : styles.listContainer, styles.scrollContentContainer)}
        >
          {getRenderedList()}
        </Masonry>
      ) : (
        <Box sx={styles.listContainer}>{getRenderedList()}</Box>
      )
    }
  }

  const getAlerts = () => {
    if (!alerts || alerts.length === 0) return null

    const alertAction = (action: PaginatedAlerts['action']) => {
      return action ? <Button buttonType="tertiary" text={action.label} onClick={action.onClick} /> : null
    }

    return (
      <Box sx={styles.alertsContainer}>
        {alerts?.map((alert) => (
          <Alert severity={alert.type} action={alertAction(alert.action)} sx={styles.alert}>
            <AlertTitle>{alert.title}</AlertTitle>
            {alert.description}
          </Alert>
        ))}

        <Divider sx={styles.divider} />
      </Box>
    )
  }

  const getListDataView = () => {
    if (isListDataEmpty) {
      return (
        <Box sx={styles.emptyStateContainer}>
          <EmptyState {...emptyState} />
        </Box>
      )
    } else {
      return (
        <InfiniteScroll
          dataLength={filteredListData.length}
          next={fetchMoreData}
          hasMore={hasMore}
          style={styles.infiniteScroll(isDesktop, removeExtraPadding)}
          loader={<Loader loading={paginatedLoading} />}
          scrollThreshold={0.9}
          endMessage={
            <Typography variant="overline" sx={styles.endOfScrollMessage}>
              {filteredListData.length !== 0 && `End of ${title.id}`}
            </Typography>
          }
        >
          {renderScrollContent()}
        </InfiniteScroll>
      )
    }
  }

  const getContent = () => {
    const showMainLoadingState = initialIsFetchingData && page === 1

    if (showMainLoadingState) {
      return (
        <SkeletonLoading
          searchBar={!!searchBar}
          verticalFilters={!!verticalFilters}
          layout={layout}
          isDesktop={isDesktop}
        />
      )
    } else {
      let rightHeaderArea = null

      if (headerButton) {
        rightHeaderArea = <Button {...headerButton} />
      } else if (rightContainer) {
        rightHeaderArea = rightContainer
      } else if (horizontalFilterTabs && !isListDataEmpty && !hideAllFilters) {
        rightHeaderArea = (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="filter tabs"
            TabIndicatorProps={{ style: styles.tabIndicator }}
            sx={styles.tabRoot}
            variant={isDesktop ? undefined : 'fullWidth'}
          >
            {horizontalFilterTabs.map((tab) => (
              <Tab
                key={tab.id}
                label={`${tab.label} (${tab.filterDef(visibleListData).length})`}
                value={tab.id}
                sx={styles.tab}
              />
            ))}
          </Tabs>
        )
      }

      return (
        <Grid item sx={styles.bottomContainer}>
          {verticalFilters && !isListDataEmpty && !hideAllFilters && (
            <Grid item sx={styles.sidebar} md={2}>
              <UniversalFilterSideBar
                ref={filterSidebarRef}
                sections={builtVerticalFilters}
                onChange={handleFilterChange}
              />
            </Grid>
          )}

          <Grid item sx={styles.main(fullWidth)} md={verticalFilters && !isListDataEmpty && !hideAllFilters ? 10 : 12}>
            <Box sx={styles.titleAndFilterContainer(!!searchBar)}>
              <PageTitle title={title.text} subtitle={subtitle} rightArea={rightHeaderArea} />
            </Box>

            {getAlerts()}

            {getListDataView()}
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid container sx={styles.container}>
      {searchBar && (
        <Grid item sx={styles.searchBarContainer} md={12}>
          {!isListDataEmpty ? (
            <SearchBar
              ref={searchBarRef}
              onSearch={handleSearch}
              placeholder={searchBar.placeholder}
              isMobile={false}
              additionalButtons={
                isDesktop
                  ? []
                  : [
                      <FilterMenu>
                        {verticalFilters && !isListDataEmpty && !hideAllFilters && (
                          <Box sx={styles.filterMenuContainer}>
                            <UniversalFilterSideBar
                              ref={filterSidebarRef}
                              sections={builtVerticalFilters}
                              onChange={handleFilterChange}
                              setSelectedFilters={setSelectedFilters}
                              selectedValues={selectedFilters}
                            />
                          </Box>
                        )}
                      </FilterMenu>
                    ]
              }
            />
          ) : null}
        </Grid>
      )}

      {getContent()}
    </Grid>
  )
}

export default PaginatedPage
