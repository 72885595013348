import React from 'react'

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import {
  Box,
  LinearProgress,
  Table as MuiTable,
  Paper,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'

import Button from '../Button/Button.component'
import EmptyState from '../EmptyState/EmptyState.component'

import styles from './Table.styles'
import { Order, TableProps } from './Table.types'

const Table = <T extends object>(props: TableProps<T>) => {
  const {
    columns,
    data = [],
    loading,
    initialLoading,
    error,
    onSort,
    defaultSort,
    stickyHeader,
    onRowClick,
    inPage,
    onRetry,
    title,
    pagination
  } = props

  const [order, setOrder] = React.useState<Order>(defaultSort?.order || 'asc')
  const [orderBy, setOrderBy] = React.useState<string | undefined>(defaultSort?.field)

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(property)
    onSort?.(property, newOrder)
  }

  const getTable = () => {
    const table = (
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <MuiTable stickyHeader={stickyHeader} sx={styles.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  sortDirection={orderBy === column.key ? order : false}
                  sx={styles.tableHeadCell}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={orderBy === column.key ? order : 'asc'}
                      onClick={() => handleRequestSort(column.key)}
                    >
                      {column.label}
                      {orderBy === column.key && (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      )}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} onClick={() => onRowClick?.(row, index)} sx={styles.tableRow(onRowClick)}>
                {columns.map((column) => {
                  let value = null

                  if (column.render) {
                    value = column.render(row)
                  } else {
                    if (typeof row[column.key as keyof T] === 'object') {
                      value = row[column.key as keyof T]
                    } else {
                      value = String(row[column.key as keyof T])
                    }
                  }

                  return (
                    <TableCell key={`${index}-${column.key}`} sx={styles.tableCell}>
                      {value as React.ReactNode}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}

            {loading && (
              <TableRow>
                <TableCell colSpan={columns.length} sx={styles.loadingCell}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}

            {!data.length && !loading && (
              <TableRow>
                <TableCell colSpan={columns.length} sx={styles.emptyCell}>
                  <Typography variant="body2" color="textSecondary">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
    )

    const getPaginationElement = () => {
      if (pagination && pagination.totalPages > 0) {
        const { currentPage, totalPages, onNext, onPrevious } = pagination

        return (
          <Box sx={styles.paginationContainer}>
            <Button
              onClick={onPrevious}
              disabled={currentPage <= 1}
              buttonType="tertiary"
              startIcon={<ArrowBackIosNew />}
              size="small"
            />

            <Typography variant="body2" sx={styles.paginationText}>
              Page {currentPage} of {totalPages}
            </Typography>

            <Button
              onClick={onNext}
              disabled={currentPage >= totalPages}
              buttonType="tertiary"
              endIcon={<ArrowForwardIos />}
              size="small"
            />
          </Box>
        )
      }
    }

    return (
      <Box sx={styles.container}>
        <Box>
          {title && (
            <Typography variant="subtitle1" sx={styles.title}>
              {title}
            </Typography>
          )}

          {table}
        </Box>

        {getPaginationElement()}
      </Box>
    )
  }

  if (!inPage) {
    return getTable()
  } else {
    if (initialLoading) {
      return (
        <Box sx={styles.skeletonContainer}>
          <Box sx={styles.skeletonTableHeaderContainer}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" height={10} width="15%" />
            ))}
          </Box>

          <Box sx={styles.skeletonTableBodyContainer}>
            {Array.from({ length: 8 }).map((_, index) => (
              <Box key={index} sx={styles.skeletonTableBodyRowContainer}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton key={index} variant="rounded" height={10} width="15%" />
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      )
    } else if (error) {
      return (
        <EmptyState
          title="Error loading data"
          subtitle="Please try again later"
          button={onRetry ? { text: 'Retry', onClick: onRetry } : undefined}
        />
      )
    } else {
      return getTable()
    }
  }
}

export default Table
