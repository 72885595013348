import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../networkRequests/apiClient/env.config'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAllUserOfferRequestsForAdminErrorResponse,
  GetAllUserOfferRequestsForAdminRequest,
  GetAllUserOfferRequestsForAdminSuccessResponse
} from './getAllUserOfferRequestsForAdmin.types'

export const getAllUserOfferRequestsForAdminApi = async (
  request: GetAllUserOfferRequestsForAdminRequest
): Promise<GetAllUserOfferRequestsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getAllUserOfferRequestsForAdmin, {
      userId: request.userId
    }),
    method: 'GET',
    params: {
      status: request.status || '',
      searchQuery: request.searchQuery || '',
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllUserOfferRequestsForAdminSuccessResponse
  } else {
    throw response as GetAllUserOfferRequestsForAdminErrorResponse
  }
}

export default getAllUserOfferRequestsForAdminApi
