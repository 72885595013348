import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'

import EmptyState from '../EmptyState/EmptyState.component'
import Loader from '../Loader/Loader.component'
import MarkdownRenderer from '../MarkdownRenderer/MarkdownRenderer.component'

import env from '../../../networkRequests/apiClient/env.config'
import { RootState } from '../../../redux/store/store.types'
import router from '../../../router/functions/router.functions'
import routes from '../../../router/routes.dictionary'
import { changeCase, formatPhoneNumber, sanitizePhoneNumber } from '../../functions/String/string.functions'
import { useAppSelector } from '../../hooks/redux.hooks'
import styles from './WebsitePolicyContent.styles'

const WebsitePolicyContent: React.FC = () => {
  const navigate = router.getPreviousPage()

  const [content, setContent] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const { values } = useAppSelector((state: RootState) => state.firebaseRemoteConfig)
  const { pathname } = useLocation()

  const policyKey = pathname.split('/').pop() || ''
  const formattedPolicyKey = changeCase(policyKey, true).camel

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const markdownContent = values[formattedPolicyKey.replace(/\s/g, '')]
        setContent(markdownContent as string)
      } catch (err) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchContent()
  }, [formattedPolicyKey, pathname, values])

  const formattedPhoneNumber = formatPhoneNumber(env.BUSINESS_PHONE_NUMBER)
  const sanitizedPhoneNumber = '+' + sanitizePhoneNumber(env.BUSINESS_PHONE_NUMBER)
  const pageTitle = changeCase(formattedPolicyKey, true).sentence

  const processedContent = content
    ? content
        .replace(/\{platformName\}/g, env.PRODUCT_NAME)
        .replace(/\{supportEmail\}/g, env.SUPPORT_EMAIL_ADDRESS)
        .replace(/\{businessPhoneNumber\}/g, formattedPhoneNumber || '')
        .replace(/\{privacyPolicyUrl\}/g, `/${routes.privacyPolicy.path}`)
        .replace(/\{sanitizedPhoneNumber\}/g, sanitizedPhoneNumber || '')
    : ''

  if (loading) {
    return (
      <Box sx={styles.container}>
        <Loader loading={loading} text={`Loading ${pageTitle} page`} size="large" marginTop={10} />
      </Box>
    )
  }

  if (error || content === undefined) {
    return (
      <Box sx={styles.container}>
        <EmptyState
          title="No content found"
          subtitle="There is no content available for this policy page."
          button={{ text: 'Go back', onClick: () => navigate.goBack() }}
        />
      </Box>
    )
  }

  return <MarkdownRenderer content={processedContent} />
}

export default WebsitePolicyContent
