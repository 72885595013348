import { SxProps } from '@mui/material'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    gap: 4,
    paddingBottom: 4,
    paddingTop: 2
  } as SxProps,

  header: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    gap: 1
  } as SxProps,

  breadcrumbs: {} as SxProps,

  breadcrumbText: {
    fontSize: '.8rem'
  } as SxProps,

  searchForm: (breadcrumbPresent: boolean) =>
    ({
      display: 'flex',
      gap: 2,
      maxWidth: 600,
      marginTop: breadcrumbPresent ? 4.5 : 0
    }) as SxProps,

  searchButton: {
    whiteSpace: 'nowrap'
  } as SxProps
}

export default styles
