import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  itemRequestAccordionContainer: {
    width: '100%'
  } as CSSProperties,

  itemRequestContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(3)
    }
  } as SxProps,

  itemDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  } as CSSProperties,

  itemDetailsInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as CSSProperties,

  itemImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px'
  } as CSSProperties,

  itemImage: {
    objectFit: 'contain',
    width: '32px',
    height: '32px'
  } as CSSProperties,

  itemTitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  } as CSSProperties,

  itemDetailsText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.grey[500]
  } as CSSProperties,

  itemDetailsDivider: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(0, 4),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0)
    }
  } as CSSProperties,

  itemsDetailsDividerVertical: {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 1.5)
    }
  } as CSSProperties,

  shopperInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },

  shopperAvatar: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(2)
  } as CSSProperties,

  shopperNameText: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as CSSProperties,

  itemPriceText: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.blue[800]
    }
  } as CSSProperties,

  priceIncreasedText: {
    fontWeight: theme.typography.fontWeightBold
  } as CSSProperties,

  itemDetailsList: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5)
    }
  } as SxProps,

  shopperInfoTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 1
  },

  messageButton: {
    color: 'primary.main',
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },

  tooltipMessage: {
    fontSize: '10px',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontWeight: theme.typography.fontWeightMedium,
    color: '#421785'
  } as CSSProperties
}

export default styles
