import { configureStore } from '@reduxjs/toolkit'

import createItineraryFromExternalFlightBookingRequestForAdminReducer from '../states/admin/createItineraryFromExternalFlightBookingRequestForAdmin/createItineraryFromExternalFlightBookingRequestForAdmin.slice'
import getAllExternalFlightBookingRequestsForAdminReducer from '../states/admin/getAllExternalFlightBookingRequestsForAdmin/getAllExternalFlightBookingRequestsForAdmin.slice'
import getAllItemRequestsForAdminReducer from '../states/admin/orders/getAllItemRequestsForAdmin/getAllItemRequestsForAdmin.slice'
import getAllUserOfferRequestsForAdminReducer from '../states/admin/orders/getAllUserOfferRequestsForAdmin/getAllUserOfferRequestsForAdmin.slice'
import getOfferRequestPaymentDetailsForAdminReducer from '../states/admin/orders/getOfferRequestPaymentDetailsForAdmin/getOfferRequestPaymentDetailsForAdmin.slice'
import getOfferRequestTripDetailsForAdminReducer from '../states/admin/orders/getOfferRequestTripDetailsForAdmin/getOfferRequestTripDetailsForAdmin.slice'
import assignVirtualCardToFlightItineraryForAdminReducer from '../states/admin/payments/virtualCards/assignVirtualCardToFlightItineraryForAdmin/assignVirtualCardToFlightItineraryForAdmin.slice'
import { getAllAssignedVirtualCardsForAdminReducer } from '../states/admin/payments/virtualCards/getAllAssignedVirtualCardsForAdmin/getAllAssignedVirtualCardsForAdmin.slice'
import getAllUnassignedVirtualCardsForAdminReducer from '../states/admin/payments/virtualCards/getAllUnassignedVirtualCardsForAdmin/getAllUnassignedVirtualCardsForAdmin.slice'
import getAllVirtualCardRequestsForAdminReducer from '../states/admin/payments/virtualCards/getAllVirtualCardRequestsForAdmin/getAllVirtualCardRequestsForAdmin.slice'
import getAssignedVirtualCardDetailsForAdminReducer from '../states/admin/payments/virtualCards/getAssignedVirtualCardDetailsForAdmin/getAssignedVirtualCardDetailsForAdmin.slice'
import updateAssignedVirtualCardDetailsReducer from '../states/admin/payments/virtualCards/updateAssignedVirtualCardDetails/updateAssignedVirtualCardDetails.slice'
import getTripDetailsForAdminReducer from '../states/admin/trips/getTripDetailsForAdmin/getTripDetailsForAdmin.slice'
import getTripOfferRequestsForAdminReducer from '../states/admin/trips/getTripOfferRequestsForAdmin/getTripOfferRequestsForAdmin.slice'
import getTripPaymentDetailsForAdminReducer from '../states/admin/trips/getTripPaymentDetailsForAdmin/getTripPaymentDetailsForAdmin.slice'
import getUserTripsForAdminReducer from '../states/admin/trips/getUserTripsForAdmin/getUserTripsForAdmin.slice'
import getAllUsersForAdminReducer from '../states/admin/users/getAllUsersForAdmin/getAllUsersForAdmin.slice'
import changePasswordReducer from '../states/auth/changePassword/changePassword.slice'
import forgetPasswordReducer from '../states/auth/forgetPassword/forgetPassword.slice'
import loginReducer from '../states/auth/login/login.slice'
import logoutReducer from '../states/auth/logout/logout.slice'
import resendEmailVerificationReducer from '../states/auth/resendEmailVerification/resendEmailVerification.slice'
import resetForgottenPasswordReducer from '../states/auth/resetForgottenPassword/resetForgottenPassword.slice'
import signUpReducer from '../states/auth/signUp/signUp.slice'
import verifyEmailReducer from '../states/auth/verifyEmail/verifyEmail.slice'
import firebaseRemoteConfigReducer from '../states/firebaseRemoteConfig/firebaseRemoteConfig.slice'
import cancelFlightItineraryReducer from '../states/flights/cancelFlightItinerary/cancelFlightItinerary.slice'
import createFlightBookingReducer from '../states/flights/createFlightBooking/createFlightBooking.slice'
import { createFlightItineraryReducer } from '../states/flights/createFlightItinerary/createFlightItinerary.slice'
import createRequestForExternalFlightReducer from '../states/flights/createRequestForExternalFlight/createRequestForExternalFlight.slice'
import getAllExternalFlightBookingRequestsReducer from '../states/flights/getAllExternalFlightBookingRequests/getAllExternalFlightBookingRequests.slice'
import getAllFlightItinerariesReducer from '../states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import getBaggageDetailsReducer from '../states/flights/getBaggageDetails/getBaggageDetails.slice'
import getCountriesAndCitiesReducer from '../states/flights/getCountriesAndCities/getCountriesAndCities.slice'
import getExternalAgenciesReducer from '../states/flights/getExternalAgencies/getExternalAgencies.slice'
import getExternalAirlinesReducer from '../states/flights/getExternalAirlines/getExternalAirlines.slice'
import { getFlightFaresReducer } from '../states/flights/getFlightFares/getFlightFares.slice'
import { getFlightItineraryReducer } from '../states/flights/getFlightItinerary/getFlightItinerary.slice'
import getFlightItineraryShareLinkReducer from '../states/flights/getFlightItineraryShareLink/getFlightItineraryShareLink.slice'
import getFlightPlacesReducer from '../states/flights/getFlightPlaces/getFlightPlaces.slice'
import { getNextFlightsReducer } from '../states/flights/getNextFlights/getNextFlights.slice'
import getSeatDetailsReducer from '../states/flights/getSeatDetails/getSeatDetails.slice'
import getUpdatedFlightOfferReducer from '../states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.slice'
import searchFlightsReducer from '../states/flights/searchFlights/searchFlights.slice'
import flightSearchWidgetReducer from '../states/flights/searchWidget/flightSearchWidget.reducer'
import acceptItemPriceChangeReducer from '../states/items/acceptItemPriceChange/acceptItemPriceChange.slice'
import commitPurchasePriceReducer from '../states/items/commitPurchasePrice/commitPurchasePrice.slice'
import getItemChangesReducer from '../states/items/getItemChanges/getItemChanges.slice'
import { getItemDetailsReducer } from '../states/items/getItemDetails/getItemDetails.slice'
import { acceptPublicOfferReducer } from '../states/offerRequest/acceptPublicOffer/acceptPublicOffer.slice'
import { getPublicOffersReducer } from '../states/offerRequest/getPublicOffers/getPublicOffers.slice'
import acceptItemRequestReducer from '../states/offers/acceptItemRequest/acceptItemRequest.slice'
import addItemTrackingNumberReducer from '../states/offers/addItemTrackingNumber/addItemTrackingNumber.slice'
import cancelItemRequestReducer from '../states/offers/cancelItemRequest/cancelItemRequest.slice'
import confirmOrderDeliveryReducer from '../states/offers/confirmOrderDelivery/confirmOrderDelivery.slice'
import { createOfferRequestReducer } from '../states/offers/createOfferRequest/createOfferRequest.slice'
import denyItemRequestReducer from '../states/offers/denyItemRequest/denyItemRequest.slice'
import getItemRequestTrackingDetailsReducer from '../states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import getOfferRequestsReducer from '../states/offers/getOfferRequests/getOfferRequests.slice'
import reassignItemRequestReducer from '../states/offers/reassignItemRequest/reassignItemRequest.slice'
import releaseOfferRequestReducer from '../states/offers/releaseOfferRequest/releaseOfferRequest.slice'
import resetOfferRequestFlightItineraryReducer from '../states/offers/resetOfferRequestFlightItinerary/resetOfferRequestFlightItinerary.slice'
import updateItemRequestTrackingDetailsReducer from '../states/offers/updateItemRequestTrackingDetails/updateItemRequestTrackingDetails.slice'
import { checkOnboardingInfoReducer } from '../states/payments/checkOnboardingInfo/checkOnboardingInfo.slice'
import createAccountSessionReducer from '../states/payments/createAccountSession/createAccountSession.slice'
import createCustomerSessionReducer from '../states/payments/createCustomerSession/createCustomerSession.slice'
import createExternalFlightBookingPaymentIntentReducer from '../states/payments/createExternalFlightBookingPaymentIntent/createExternalFlightBookingPaymentIntent.slice'
import createFlightBookingPaymentIntentReducer from '../states/payments/createFlightBookingPaymentIntent/createFlightBookingPaymentIntent.slice'
import createOfferRequestPaymentIntentReducer from '../states/payments/createOfferRequestPaymentIntent/createOfferRequestPaymentIntent.slice'
import createRequestForVirtualCardReducer from '../states/payments/createRequestForVirtualCard/createRequestForVirtualCard.slice'
import getAssignedVirtualCardDetailsReducer from '../states/payments/getAssignedVirtualCardDetails/getAssignedVirtualCardDetails.slice'
import logPaymentMethodUpdateReducer from '../states/payments/logPaymentMethodUpdate/logPaymentMethodUpdate.slice'
import requestOfferRequestRefundReducer from '../states/payments/requestOfferRequestRefund/requestOfferRequestRefund.slice'
import addDeliveryAddressReducer from '../states/profile/addDeliveryAddress/addDeliveryAddress.slice'
import { addLoyaltyProgramReducer } from '../states/profile/addLoyaltyProgram/addLoyaltyProgram.slice'
import { deleteLoyaltyProgramReducer } from '../states/profile/deleteLoyaltyProgram/deleteLoyaltyProgram.slice'
import { getLoyaltyProgramsReducer } from '../states/profile/getLoyaltyPrograms/getLoyaltyPrograms.slice'
import getProfileReducer from '../states/profile/getProfile/getProfile.slice'
import updateContactInfoReducer from '../states/profile/updateContactInfo/updateContactInfo.slice'
import updateDeliveryAddressReducer from '../states/profile/updateDeliveryAddress/updateDeliveryAddress.slice'
import updateFirebaseUserIdReducer from '../states/profile/updateFirebaseUserId/updateFirebaseUserId.slice'
import { updateLoyaltyProgramReducer } from '../states/profile/updateLoyaltyProgram/updateLoyaltyProgram.slice'
import updatePersonalInfoReducer from '../states/profile/updatePersonalInfo/updatePersonalInfo.slice'
import { getSupportedLoyaltyProgramsReducer } from '../states/supportedLoyaltyProgram/getSupportedLoyaltyPrograms/getSupportedLoyaltyPrograms.slice'

export default configureStore({
  reducer: {
    resendEmailVerification: resendEmailVerificationReducer,
    updateFirebaseUserId: updateFirebaseUserIdReducer,
    createRequestForVirtualCard: createRequestForVirtualCardReducer,
    createExternalFlightBookingPaymentIntent: createExternalFlightBookingPaymentIntentReducer,
    createItineraryFromExternalFlightBookingRequestForAdmin:
      createItineraryFromExternalFlightBookingRequestForAdminReducer,
    getAllExternalFlightBookingRequestsForAdmin: getAllExternalFlightBookingRequestsForAdminReducer,
    getAllExternalFlightBookingRequests: getAllExternalFlightBookingRequestsReducer,
    getExternalAgencies: getExternalAgenciesReducer,
    getExternalAirlines: getExternalAirlinesReducer,
    createRequestForExternalFlight: createRequestForExternalFlightReducer,
    checkOnboardingInfo: checkOnboardingInfoReducer,
    getAssignedVirtualCardDetails: getAssignedVirtualCardDetailsReducer,
    confirmOrderDelivery: confirmOrderDeliveryReducer,
    updateAssignedVirtualCardDetails: updateAssignedVirtualCardDetailsReducer,
    getAssignedVirtualCardDetailsForAdmin: getAssignedVirtualCardDetailsForAdminReducer,
    getAllAssignedVirtualCardsForAdmin: getAllAssignedVirtualCardsForAdminReducer,
    assignVirtualCardToFlightItineraryForAdmin: assignVirtualCardToFlightItineraryForAdminReducer,
    getAllUnassignedVirtualCardsForAdmin: getAllUnassignedVirtualCardsForAdminReducer,
    getAllVirtualCardRequestsForAdmin: getAllVirtualCardRequestsForAdminReducer,
    getTripOfferRequestsForAdmin: getTripOfferRequestsForAdminReducer,
    getTripPaymentDetailsForAdmin: getTripPaymentDetailsForAdminReducer,
    getTripDetailsForAdmin: getTripDetailsForAdminReducer,
    getUserTripsForAdmin: getUserTripsForAdminReducer,
    getOfferRequestTripDetailsForAdmin: getOfferRequestTripDetailsForAdminReducer,
    getOfferRequestPaymentDetailsForAdmin: getOfferRequestPaymentDetailsForAdminReducer,
    getAllItemRequestsForAdmin: getAllItemRequestsForAdminReducer,
    getAllUserOfferRequestsForAdmin: getAllUserOfferRequestsForAdminReducer,
    commitPurchasePrice: commitPurchasePriceReducer,
    acceptItemPriceChange: acceptItemPriceChangeReducer,
    acceptItemRequest: acceptItemRequestReducer,
    acceptPublicOffer: acceptPublicOfferReducer,
    addDeliveryAddress: addDeliveryAddressReducer,
    addItemTrackingNumber: addItemTrackingNumberReducer,
    addLoyaltyProgram: addLoyaltyProgramReducer,
    cancelFlightItinerary: cancelFlightItineraryReducer,
    cancelItemRequest: cancelItemRequestReducer,
    changePassword: changePasswordReducer,
    createAccountSession: createAccountSessionReducer,
    createFlightBooking: createFlightBookingReducer,
    createFlightBookingPaymentIntent: createFlightBookingPaymentIntentReducer,
    createFlightItinerary: createFlightItineraryReducer,
    createCustomerSession: createCustomerSessionReducer,
    createOfferRequest: createOfferRequestReducer,
    createOfferRequestPaymentIntent: createOfferRequestPaymentIntentReducer,
    deleteLoyaltyProgram: deleteLoyaltyProgramReducer,
    denyItemRequest: denyItemRequestReducer,
    firebaseRemoteConfig: firebaseRemoteConfigReducer,
    flightSearchWidget: flightSearchWidgetReducer,
    forgetPassword: forgetPasswordReducer,
    getAllFlightItineraries: getAllFlightItinerariesReducer,
    getAllUsersForAdmin: getAllUsersForAdminReducer,
    getBaggageDetails: getBaggageDetailsReducer,
    getCountriesAndCities: getCountriesAndCitiesReducer,
    getFlightItinerary: getFlightItineraryReducer,
    getFlightItineraryShareLink: getFlightItineraryShareLinkReducer,
    getFlightFares: getFlightFaresReducer,
    getFlightPlaces: getFlightPlacesReducer,
    getItemChanges: getItemChangesReducer,
    getItemDetails: getItemDetailsReducer,
    getItemRequestTrackingDetails: getItemRequestTrackingDetailsReducer,
    getLoyaltyPrograms: getLoyaltyProgramsReducer,
    getNextFlights: getNextFlightsReducer,
    getOfferRequests: getOfferRequestsReducer,
    getProfile: getProfileReducer,
    getPublicOffers: getPublicOffersReducer,
    getSeatDetails: getSeatDetailsReducer,
    getSupportedLoyaltyPrograms: getSupportedLoyaltyProgramsReducer,
    getUpdatedFlightOffer: getUpdatedFlightOfferReducer,
    login: loginReducer,
    logout: logoutReducer,
    logPaymentMethodUpdate: logPaymentMethodUpdateReducer,
    reassignItemRequest: reassignItemRequestReducer,
    releaseOfferRequest: releaseOfferRequestReducer,
    requestOfferRequestRefund: requestOfferRequestRefundReducer,
    resetForgottenPassword: resetForgottenPasswordReducer,
    resetOfferRequestFlightItinerary: resetOfferRequestFlightItineraryReducer,
    searchFlights: searchFlightsReducer,
    signUp: signUpReducer,
    updateContactInfo: updateContactInfoReducer,
    updateDeliveryAddress: updateDeliveryAddressReducer,
    updateItemRequestTrackingDetails: updateItemRequestTrackingDetailsReducer,
    updateLoyaltyProgram: updateLoyaltyProgramReducer,
    updatePersonalInfo: updatePersonalInfoReducer,
    verifyEmail: verifyEmailReducer
  }
})
