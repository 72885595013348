import { SxProps } from '@mui/material'

import theme from '../../../../styles/themes/default.theme'

const styles = {
  menuContainer: {
    flexGrow: 1,
    display: { xs: 'flex', md: 'none' }
  } as SxProps,

  menuIcon: {
    padding: 0
  } as SxProps,

  menuContentContainer: (marginTop: string = '10px') =>
    ({
      boxShadow: theme.shadows[1],
      display: { xs: 'block', md: 'none' },
      '& .MuiMenu-paper': {
        width: '100%',
        maxWidth: '100%',
        left: 0,
        right: 0,
        boxShadow: 'none',
        mt: '10px',
        filter: 'drop-shadow(0px 1px 0px rgba(0,0,0,0.32))',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        [theme.breakpoints.down('md')]: {
          mt: marginTop
        }
      },
      '& .MuiPaper-root': {
        left: 'unset!important'
      },
      '& .MuiList-root': {
        paddingX: theme.spacing(3),
        marginBottom: '-1px'
      }
    }) as SxProps
}

export default styles
