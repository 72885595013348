import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '70%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius / 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    [theme.breakpoints.down('md')]: {
      width: '90%',
      maxWidth: '400px'
    }
  },

  passengerSelectionContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)',
    padding: theme.spacing(0, 0, 2, 0)
  },

  divider: {
    margin: theme.spacing(-2, 0, 1, 0)
  },

  closeButton: {
    alignSelf: 'flex-end'
  }
}

export default styles
