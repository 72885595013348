import React from 'react'

import linkStyles from '@/shared/styles/link.styles'
import { Box, Typography } from '@mui/material'

import Carousel from '../Carousel/Carousel.component'
import Link from '../Link/Link.component'

import { PageBannerImage } from '../../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { AutoCycleDuration } from '../../../shared/components/Carousel/Carousel.types'
import useResponsiveness from '../../hooks/responsive.hooks'
import styles from './PageBanner.styles'
import { PageBannerProps } from './PageBanner.types'

const PageBanner: React.FC<PageBannerProps> = (props) => {
  const { images, title, subtitle, links } = props

  const [isDesktop] = useResponsiveness()

  const carouselItems = images?.map((image: PageBannerImage, index: number) => (
    <Box key={index} sx={styles.imageContainer}>
      <img src={image.src} alt={image.alt} style={{ ...styles.image, objectFit: 'cover' }} />
    </Box>
  ))

  return (
    <Box sx={styles.bannerContainer}>
      <Box sx={styles.textContainer}>
        <Typography variant={isDesktop ? 'h4' : 'h5'} sx={styles.title}>
          {title}
        </Typography>

        <Typography variant="h6" sx={styles.subtitle}>
          {subtitle}
        </Typography>

        {links && links.length > 0 && (
          <Box sx={styles.linksContainer}>
            {links.map((link, index) => (
              <Typography key={index} sx={styles.link}>
                <Link href={link.url} isUnderlined target="_blank" sx={linkStyles}>
                  {link.text}
                </Link>
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      <Box sx={styles.carouselContainer}>
        <Carousel items={carouselItems} autoCycle={true} autoCycleDuration={AutoCycleDuration.LONG} />
      </Box>
    </Box>
  )
}

export default PageBanner
