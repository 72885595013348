import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../../shared/types/api.types'
import { getAllAssignedVirtualCardsForAdminApi } from './getAllAssignedVirtualCardsForAdmin.api'
import getAllAssignedVirtualCardsForAdminInitialState from './getAllAssignedVirtualCardsForAdmin.initialState'
import {
  GetAllAssignedVirtualCardsForAdminErrorResponse,
  GetAllAssignedVirtualCardsForAdminRequestPayload
} from './getAllAssignedVirtualCardsForAdmin.types'

export const getAllAssignedVirtualCardsForAdmin = createAsyncThunk(
  urls.admin.getAllAssignedVirtualCardsForAdmin,
  async (payload: GetAllAssignedVirtualCardsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAllAssignedVirtualCardsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAllAssignedVirtualCardsForAdminSlice = createSlice({
  name: urls.admin.getAllAssignedVirtualCardsForAdmin,
  initialState: getAllAssignedVirtualCardsForAdminInitialState,
  reducers: {
    resetGetAllAssignedVirtualCardsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAssignedVirtualCardsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAllAssignedVirtualCardsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAllAssignedVirtualCardsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAllAssignedVirtualCardsForAdminErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: getAllAssignedVirtualCardsForAdminActions,
  reducer: getAllAssignedVirtualCardsForAdminReducer
} = getAllAssignedVirtualCardsForAdminSlice
export const { resetGetAllAssignedVirtualCardsForAdmin } = getAllAssignedVirtualCardsForAdminActions
export default getAllAssignedVirtualCardsForAdminSlice.reducer
