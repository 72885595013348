import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'

const useMenu = () => {
  const getAdjustedMarginTop = () => {
    const pagesWithAdjustedMarginTop = [routes.orders.path, routes.cart.path]

    return pagesWithAdjustedMarginTop.includes(router.getCurrentPage().path) ? '44px' : undefined
  }

  return [getAdjustedMarginTop]
}

export default useMenu
