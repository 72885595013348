import { FlightItinerary } from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { OfferRequest } from '../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import date from '../../../../shared/functions/Date/date.functions'
import status from '../../../../shared/functions/Status/status.dictionaries'
import { AccepOfferValidationResult, RequiredCountries } from './OfferItem.types'

export function formatArrayWithAnd(arr: string[]): string {
  if (arr.length === 0) return ''
  if (arr.length === 1) return arr[0]
  if (arr.length === 2) return arr.join(' and ')

  return `${arr.slice(0, -1).join(', ')} and ${arr[arr.length - 1]}`
}

export const validateOfferAcceptance = (
  flightItineraries: FlightItinerary[],
  items: { country: { iataCode: string; name: string } }[],
  deliveryLocation: { countryCode: string; country: string; city: string; cityIataCode: string },
  offer: OfferRequest
): AccepOfferValidationResult => {
  const item = items[0] // Since all items have the same country, we can use the first item's country
  const itemCountry = item.country
  const matchingFlightItineraries: FlightItinerary[] = []

  flightItineraries = flightItineraries.filter((flightItinerary) => {
    if (flightItinerary.itinerary.itinerarySource === 'external') {
      const isVirtualCardRequestCreated =
        flightItinerary.itinerary.virtualCardDetails?.status.name === status.virtualCardStatus.virtualCardRequestCreated
      const isVirtualCardAssigned =
        flightItinerary.itinerary.virtualCardDetails?.id !== null &&
        flightItinerary.itinerary.virtualCardDetails?.id !== undefined

      if (isVirtualCardAssigned) {
        return false
      } else if (isVirtualCardRequestCreated) {
        return false
      } else {
        return true
      }
    } else {
      return (
        date(flightItinerary.itinerary.legs[0].travelDate).isSameOrAfter(date().currentDate) &&
        flightItinerary.status.name !== status.flightStatus.flightBooked
      )
    }
  })

  for (const flightItinerary of flightItineraries) {
    const visitedCountryCodes = new Set<string>()
    const visitedCityNames = new Set<string>()

    for (let i = 0; i < flightItinerary.itinerary.legs.length; i++) {
      const leg = flightItinerary.itinerary.legs[i]

      const destinationCountryCode = leg.destination.country.iataCode
      const destinationCityName = leg.destination.city.name
      const originCountryCode = leg.origin.country.iataCode
      const originCityName = leg.origin.city.name

      if (i === 0) {
        if (originCountryCode === itemCountry.iataCode) {
          visitedCountryCodes.add(originCountryCode)
        } else {
          continue
        }
      } else {
        visitedCountryCodes.add(originCountryCode)
      }
      if (destinationCountryCode) visitedCountryCodes.add(destinationCountryCode)
      if (destinationCityName) visitedCityNames.add(destinationCityName)
      if (originCityName) visitedCityNames.add(originCityName)
    }

    const isMatch =
      itemCountry.iataCode &&
      visitedCountryCodes.has(itemCountry.iataCode) &&
      visitedCountryCodes.has(deliveryLocation.countryCode) &&
      visitedCityNames.has(deliveryLocation.city)

    if (isMatch) {
      matchingFlightItineraries.push(flightItinerary)
    }
  }

  const requiredCountries: RequiredCountries = {
    itemLocation: {
      iataCode: itemCountry.iataCode,
      name: itemCountry.name
    },
    deliveryLocation: {
      iataCode: deliveryLocation.countryCode,
      cityIataCode: deliveryLocation.cityIataCode,
      name: deliveryLocation.country,
      city: deliveryLocation.city,
      neededByDate: offer.neededByDate
    }
  }

  if (matchingFlightItineraries.length === 0) {
    return {
      multipleOptions: false,
      noMatchingFlightItineraries: true,
      requiredCountries,
      flightItineraries: []
    }
  } else if (matchingFlightItineraries.length > 1) {
    return {
      multipleOptions: true,
      noMatchingFlightItineraries: false,
      flightItineraries: matchingFlightItineraries,
      requiredCountries
    }
  } else {
    return {
      multipleOptions: false,
      noMatchingFlightItineraries: false,
      flightItineraries: matchingFlightItineraries,
      requiredCountries
    }
  }
}
