import React, { useCallback, useEffect } from 'react'

import { joinStringsWithBullet } from '@/shared/functions/String/string.functions'
import { Box } from '@mui/material'

import PaginatedPage from '../../shared/components/PaginatedPage/PaginatedPage.component'
import TripDetailsAccordion from './components/TripDetailsAccordion/TripDetailsAccordion.component'

import env from '../../networkRequests/apiClient/env.config'
import { getAllExternalFlightBookingRequests } from '../../redux/states/flights/getAllExternalFlightBookingRequests/getAllExternalFlightBookingRequests.slice'
import { GetAllExternalFlightBookingRequestsRequestPayload } from '../../redux/states/flights/getAllExternalFlightBookingRequests/getAllExternalFlightBookingRequests.types'
import { getAllFlightItineraries } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import {
  FlightItinerary,
  GetAllFlightItinerariesRequest
} from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { EmptyStateProps } from '../../shared/components/EmptyState/EmptyState.types'
import {
  FilterTab,
  PaginatedAlerts,
  PaginatedResponse
} from '../../shared/components/PaginatedPage/PaginatedPage.types'
import date from '../../shared/functions/Date/date.functions'
import status from '../../shared/functions/Status/status.dictionaries'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './Trips.styles'

const Trips: React.FC = () => {
  const { loading: loadingFlightItineraries } = useAppSelector((state: RootState) => state.getAllFlightItineraries)
  const { loading: loadingRequests, success: flightBookingRequestsSuccess } = useAppSelector(
    (state: RootState) => state.getAllExternalFlightBookingRequests
  )

  const isItineraryCancelled = (flightItinerary: FlightItinerary) =>
    flightItinerary.status.name === status.flightStatus.flightBookingCancelledBeforePayment ||
    flightItinerary.status.name === status.flightStatus.flightCancelledAfterPayment

  const pageSize = env.DEFAULT_PAGE_SIZE

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(
    function handleFetchPendingRequests() {
      const fetchPendingRequests = async () => {
        const payload: GetAllExternalFlightBookingRequestsRequestPayload = {
          request: {
            page: 1,
            size: env.DEFAULT_PAGE_SIZE,
            status: status.externalFlightBookingStatus.externalFlightBookingRequestCreated
          }
        }

        dispatch(getAllExternalFlightBookingRequests(payload))
      }

      fetchPendingRequests()
    },
    [dispatch]
  )

  const fetchFlightItineraries = useCallback(
    async (page: number): Promise<PaginatedResponse<FlightItinerary>> => {
      return new Promise((resolve) => {
        const getAllFlightItinerariesPayload: GetAllFlightItinerariesRequest = {
          page,
          size: pageSize,
          withAssociatedFlights: true
        }

        dispatch(
          getAllFlightItineraries({
            request: getAllFlightItinerariesPayload,
            onSuccess: (response) => {
              resolve({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                results: response.data.results
              })
            }
          })
        )
      })
    },
    [dispatch, pageSize]
  )

  const filterTabs: FilterTab<unknown>[] = [
    {
      id: 'upcoming',
      label: 'Upcoming',
      filterDef: (flightItineraries: unknown[]) =>
        (flightItineraries as FlightItinerary[]).filter((flightItinerary) => {
          const lastLeg = flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1]
          const isCurrentDateBeforeLastLegDate = date(date().currentDate).isSameOrBefore(
            date(lastLeg.travelDate).dateTime,
            'day'
          )

          const isTripCancelled = isItineraryCancelled(flightItinerary)

          return isCurrentDateBeforeLastLegDate && !isTripCancelled
        })
    },

    {
      id: 'completed',
      label: 'Completed',
      filterDef: (flightItineraries: unknown[]) =>
        (flightItineraries as FlightItinerary[]).filter((flightItinerary) => {
          const lastLeg = flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1]
          const isLastLegDateInThePast = date(lastLeg.travelDate).isBefore(date().currentDate)
          const isTripCancelled = isItineraryCancelled(flightItinerary)

          return isLastLegDateInThePast || isTripCancelled
        })
    }
  ]

  const emptyState: EmptyStateProps = {
    title: 'You have no trips available',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Trip',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  const filterEmptyState: EmptyStateProps = {
    title: 'No trips available in this category',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Flight',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  const pendingFlightBookingRequestsCount = flightBookingRequestsSuccess?.data.records.length
  let alerts: PaginatedAlerts[] = []

  if (pendingFlightBookingRequestsCount && pendingFlightBookingRequestsCount > 0) {
    const title = joinStringsWithBullet([
      'Pending Flight Booking Request',
      pendingFlightBookingRequestsCount.toString()
    ])

    const description = `Your request${
      pendingFlightBookingRequestsCount > 1 ? 's are' : ' is'
    } being reviewed. You will receive an email when your itinerar${
      pendingFlightBookingRequestsCount > 1 ? 'ies are' : 'y is'
    } ready.`

    alerts = [
      {
        id: 'pending-flight-booking-requests',
        title,
        description,
        type: 'info'
      }
    ]
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        title={{ text: 'My Trips', id: 'Trips' }}
        subtitle="View your past and upcoming trips."
        isFetchingData={loadingFlightItineraries || loadingRequests}
        emptyState={emptyState}
        fetchCall={fetchFlightItineraries}
        alerts={alerts}
        renderListData={TripDetailsAccordion}
        horizontalFilterTabs={filterTabs}
        filterEmptyState={filterEmptyState}
        removeExtraPadding
        fullWidth
      />
    </Box>
  )
}

export default Trips
