import React, { useCallback, useEffect, useState } from 'react'

import { Chip } from '@mui/material'
import { useSelector } from 'react-redux'

import Table from '../../../../shared/components/Table/Table.component'

import env from '../../../../networkRequests/apiClient/env.config'
import { getAllUserOfferRequestsForAdmin } from '../../../../redux/states/admin/orders/getAllUserOfferRequestsForAdmin/getAllUserOfferRequestsForAdmin.slice'
import { GetAllUserOfferRequestsForAdminRequestPayload } from '../../../../redux/states/admin/orders/getAllUserOfferRequestsForAdmin/getAllUserOfferRequestsForAdmin.types'
import { getAllUsersForAdmin } from '../../../../redux/states/admin/users/getAllUsersForAdmin/getAllUsersForAdmin.slice'
import {
  GetAllUsersForAdminRequestPayload,
  User
} from '../../../../redux/states/admin/users/getAllUsersForAdmin/getAllUsersForAdmin.types'
import { RootState } from '../../../../redux/store/store.types'
import { getStatusColor } from '../../../../shared/functions/Status/status.functions'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import { adminNavigationPaths } from '../../AdminDashboard.dictionary'
import { UsersProps } from './Users.types'

const Users: React.FC<UsersProps> = (props) => {
  const { searchQuery, demoRouter } = props
  const { success, loading, error } = useSelector((state: RootState) => state.getAllUsersForAdmin)

  const [page, setPage] = useState(1)
  const [usersData, setUsersData] = useState<User[]>([])
  const [totalPages, setTotalPages] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)

  const pageSize = env.DEFAULT_PAGE_SIZE

  const dispatch = useAppDispatch()
  const navigate = demoRouter.navigate

  const columns = [
    { key: 'id', label: 'ID' },
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'email', label: 'Email' },
    {
      key: 'accountStatus',
      label: 'Status',
      render: (row: User) => (
        <Chip label={row.accountStatus.displayName} color={getStatusColor(row.accountStatus.name)} />
      )
    }
  ]

  useEffect(() => {
    if (success?.data) {
      const { records, totalPages, totalRecords, currentPage } = success.data

      setUsersData(records)
      setTotalPages(totalPages)
      setTotalRecords(totalRecords)
      setPage(currentPage)
    }
  }, [success])

  useEffect(() => {
    setPage(1)
    setUsersData([])
  }, [searchQuery])

  const handleRequestForGetAllUsers = useCallback(
    (page: number = 1, size: number = pageSize) => {
      const payload: GetAllUsersForAdminRequestPayload = {
        request: {
          page,
          size,
          searchQuery
        }
      }

      dispatch(getAllUsersForAdmin(payload))
    },
    [dispatch, searchQuery, pageSize]
  )

  const handleRequestForGetAllUserOfferRequests = useCallback(
    (userId: string, page: number = 1, size: number = pageSize) => {
      const payload: GetAllUserOfferRequestsForAdminRequestPayload = {
        request: {
          userId,
          page,
          size
        },
        onSuccess: () => {
          navigate(`/${adminNavigationPaths.users}/${userId}`)
        }
      }

      dispatch(getAllUserOfferRequestsForAdmin(payload))
    },
    [dispatch, navigate, pageSize]
  )

  useEffect(() => {
    handleRequestForGetAllUsers()
  }, [handleRequestForGetAllUsers])

  return (
    <Table
      columns={columns}
      data={usersData}
      pagination={{
        currentPage: page,
        totalPages,
        totalRecords,
        onNext: () => handleRequestForGetAllUsers(page + 1),
        onPrevious: () => handleRequestForGetAllUsers(page - 1)
      }}
      onRowClick={(_, index) => {
        const userId = usersData[index]?.id.toString()

        if (userId) {
          handleRequestForGetAllUserOfferRequests(userId)
        }
      }}
      inPage
      initialLoading={loading}
      error={!!error}
      onRetry={() => handleRequestForGetAllUsers()}
    />
  )
}

export default Users
