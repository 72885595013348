const styles = {
  container: {
    gap: 3,
    display: 'flex',
    flexDirection: 'column'
  },

  sectionTitle: {
    marginBottom: 2,
    fontWeight: 'medium'
  },

  subSectionTitle: {
    marginTop: 2,
    marginBottom: 1.5,
    color: 'text.secondary'
  },

  paymentSection: {
    paddingTop: 2,
    borderTop: '1px solid',
    borderColor: 'divider'
  },

  skeletonContainer: {},

  skeletonItem: {
    marginBottom: 3,
    borderRadius: 2
  },

  paymentSummaryContainer: {}
}

export default styles
