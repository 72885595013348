import React from 'react'

import date from '@/shared/functions/Date/date.functions'
import { formatMoney } from '@/shared/functions/String/string.functions'
import { Box, Divider, Typography } from '@mui/material'

import Button from '@/shared/components/Button/Button.component'

import styles from './SelectedFlightOffer.styles'
import { SelectedFlightOfferProps } from './SelectedFlightOffer.types'

const SelectedFlightOffer: React.FC<SelectedFlightOfferProps> = ({ offer, stopCount, change }) => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        Your departure flight:
      </Typography>

      <Box sx={styles.flightInfoContainer}>
        <Typography sx={styles.flightStopCount}>
          {stopCount === 0 ? 'Non Stop' : `${stopCount} Stop${stopCount > 1 ? 's' : ''}`}
        </Typography>

        <Divider orientation="vertical" flexItem />

        <Typography sx={styles.flightDuration}>{offer.slices[0].totalDuration}</Typography>

        <Divider orientation="vertical" flexItem />

        <Box sx={styles.airlineContainer}>
          <img src={offer.airlineLogo} alt={offer.airline} style={styles.airlineLogo} />

          <Typography sx={styles.airline}>{offer.airline}</Typography>
        </Box>
      </Box>

      <Typography sx={styles.flightTime}>
        {date(offer.slices[0].segments[0].departure.at).format('h:mm a')} (
        {offer.slices[0].segments[0].origin.iata_code}) -{date(offer.slices[0].segments[0].arrival.at).format('h:mm a')}{' '}
        ({offer.slices[0].segments[0].destination.iata_code})
      </Typography>

      <Divider orientation="horizontal" flexItem sx={styles.divider} />

      <Box sx={styles.footer}>
        <Typography sx={styles.totalAmount}>{formatMoney(offer.totalAmount, offer.currency)}</Typography>
        <Button variant="contained" color="primary" buttonType="primary" text={'Change'} onClick={change} />
      </Box>
    </Box>
  )
}

export default SelectedFlightOffer
