import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateItineraryFromExternalFlightBookingRequestForAdminErrorResponse,
  CreateItineraryFromExternalFlightBookingRequestForAdminRequest,
  CreateItineraryFromExternalFlightBookingRequestForAdminSuccessResponse
} from './createItineraryFromExternalFlightBookingRequestForAdmin.types'

export const createItineraryFromExternalFlightBookingRequestForAdminApi = async (
  request: CreateItineraryFromExternalFlightBookingRequestForAdminRequest
): Promise<CreateItineraryFromExternalFlightBookingRequestForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: urls.admin.createItineraryFromExternalFlightBookingRequestForAdmin,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateItineraryFromExternalFlightBookingRequestForAdminSuccessResponse
  } else {
    throw response as CreateItineraryFromExternalFlightBookingRequestForAdminErrorResponse
  }
}

export default createItineraryFromExternalFlightBookingRequestForAdminApi
