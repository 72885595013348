import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Box } from '@mui/material'

import FilterSection from './FilterSection/FilterSection.component'

import styles from './UniversalFilterSideBar.styles'
import { FilterSectionConfig, Filters, UniversalFilterSideBarProps } from './UniversalFilterSideBar.types'

const UniversalFilterSideBar = forwardRef(
  ({ sections, onChange, selectedValues: selectedValues, setSelectedFilters }: UniversalFilterSideBarProps, ref) => {
    const [filterValues, setFilterValues] = useState<Filters>(selectedValues ?? {})
    const [firstSectionUpdate, setFirstSectionUpdate] = useState(!!selectedValues)

    useEffect(() => {
      if (filterValues) {
        setSelectedFilters?.(filterValues)
      }
    }, [filterValues, setSelectedFilters])

    useEffect(() => {
      if (firstSectionUpdate) {
        setFirstSectionUpdate(false)

        return
      }

      const initialValues: Filters = {}
      sections.forEach((section) => {
        initialValues[section.id] = section.defaultValue || (section.type === 'multi-select' ? [] : '')
      })
      setFilterValues(initialValues)
    }, [sections]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleFilterChange = (section: FilterSectionConfig, value: unknown) => {
      const newFilterValues = { ...filterValues, [section.id]: value }
      setFilterValues(newFilterValues)
      onChange(newFilterValues)
    }

    const handleReset = (sectionId: string) => {
      const section = sections.find((s) => s.id === sectionId)
      if (section) {
        const defaultValue = section.defaultValue || (section.type === 'multi-select' ? [] : '')
        handleFilterChange(section, defaultValue)
      }
    }

    useImperativeHandle(ref, () => ({
      resetFilters: () => {
        const initialValues: Filters = {}
        sections.forEach((section) => {
          initialValues[section.id] = section.defaultValue || (section.type === 'multi-select' ? [] : '')
        })
        setFilterValues(initialValues)
        onChange(initialValues)
      }
    }))

    return (
      <Box sx={styles.container}>
        {sections.map((section) => (
          <FilterSection
            key={section.id}
            {...section}
            value={filterValues[section.id]}
            onChange={(value) => handleFilterChange(section, value)}
            onReset={() => handleReset(section.id)}
          />
        ))}
      </Box>
    )
  }
)

export default UniversalFilterSideBar
