import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateRequestForExternalAirlineBookingRequest,
  CreateRequestForExternalFlightErrorResponse,
  CreateRequestForExternalFlightSuccessResponse,
  CreateRequestForExternalTravelAgencyBookingRequest
} from './createRequestForExternalFlight.types'

export const createRequestForExternalFlightApi = async (
  request: CreateRequestForExternalAirlineBookingRequest | CreateRequestForExternalTravelAgencyBookingRequest
): Promise<CreateRequestForExternalFlightSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.createExternalFlightBookingRequest,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateRequestForExternalFlightSuccessResponse
  } else {
    throw response as CreateRequestForExternalFlightErrorResponse
  }
}

export default createRequestForExternalFlightApi
