import React from 'react'

import { getFormattedTripType } from '@/shared/functions/Flights/flights.functions'
import { TripType } from '@/shared/functions/Flights/flights.types'
import { Box, Chip } from '@mui/material'

import Link from '../../../../../../../../../../shared/components/Link/Link.component'
import Table from '../../../../../../../../../../shared/components/Table/Table.component'

import date, { dateTimeFormats } from '../../../../../../../../../../shared/functions/Date/date.functions'
import { FlightItineraryLeg } from '../../../../../../../../../../shared/functions/Places/places.types'
import { getStatusColor } from '../../../../../../../../../../shared/functions/Status/status.functions'
import { joinElementsWithBullet } from '../../../../../../../../../../shared/functions/String/string.functions'
import { getFormattedTripDetails } from '../../../../../../../../../Trips/Trips.functions'
import styles from './TripDetailsCard.styles'
import { TripDetailsCardProps } from './TripDetailsCard.types'

const TripDetailsCard: React.FC<TripDetailsCardProps> = (props) => {
  const { tripDetails } = props

  return (
    <Box sx={styles.container}>
      {/* Traveler Info Section */}
      <Box sx={styles.contentSection}>
        <Table
          title="Traveler Information"
          columns={[
            { key: 'key', label: 'Key' },
            { key: 'value', label: 'Data' }
          ]}
          data={[
            { key: 'Name', value: `${tripDetails.traveler.firstName} ${tripDetails.traveler.lastName}` },
            { key: 'ID', value: tripDetails.traveler.id },
            {
              key: 'Email',
              value: (
                <a href={`mailto:${tripDetails.traveler.email}`} style={styles.link} target="_blank">
                  {tripDetails.traveler.email}
                </a>
              )
            }
          ]}
        />
      </Box>

      {/* Flight Itinerary Section */}
      <Box sx={styles.contentSection}>
        <Table
          title="Flight Itinerary"
          columns={[
            { key: 'key', label: 'Key' },
            { key: 'value', label: 'Data' }
          ]}
          data={[
            { key: 'Itinerary ID', value: tripDetails.tripDetails.flightItineraryDetails.id },
            {
              key: 'Status',
              value: (
                <Chip
                  label={tripDetails.tripDetails.flightItineraryDetails.status.displayName}
                  color={getStatusColor(tripDetails.tripDetails.flightItineraryDetails.status.name)}
                />
              )
            },
            {
              key: 'Trip Type',
              value: getFormattedTripType(tripDetails.tripDetails.flightItineraryDetails.tripType as TripType)
            },
            {
              key: 'Route Details',
              value: getFormattedTripDetails(
                tripDetails.tripDetails.flightItineraryDetails.legs as unknown as FlightItineraryLeg[],
                tripDetails.tripDetails.flightItineraryDetails.tripType as TripType
              )
            }
          ]}
        />
      </Box>

      {/* Flight Booking Section */}
      {tripDetails.tripDetails.flightBookingDetails && (
        <Box sx={styles.contentSection}>
          <Table
            title="Flight Booking Details"
            columns={[
              { key: 'key', label: 'Key' },
              { key: 'value', label: 'Data' }
            ]}
            data={[
              {
                key: 'Status',
                value: (
                  <Chip
                    label={tripDetails.tripDetails.flightBookingDetails.status.displayName}
                    color={getStatusColor(tripDetails.tripDetails.flightBookingDetails.status.name)}
                  />
                )
              },
              { key: 'Booking Type', value: tripDetails.tripDetails.flightItineraryDetails.itinerarySource },
              {
                key: 'Confirmation Code',
                value: tripDetails.tripDetails.flightBookingDetails.confirmationCode || 'N/A'
              },
              {
                key:
                  tripDetails.tripDetails.flightItineraryDetails.itinerarySource === 'external'
                    ? tripDetails.tripDetails.flightBookingDetails.externalDetails?.itineraryReferenceNumber
                      ? 'Itinerary Reference Number'
                      : 'Ticket Number'
                    : 'Duffel Order ID',
                value:
                  tripDetails.tripDetails.flightItineraryDetails.itinerarySource === 'external' ? (
                    tripDetails.tripDetails.flightBookingDetails.externalDetails?.itineraryReferenceNumber ||
                    tripDetails.tripDetails.flightBookingDetails.externalDetails?.ticketNumber
                  ) : (
                    <Link
                      href={tripDetails.tripDetails.flightBookingDetails.internalDetails?.duffelOrderUrl}
                      target="_blank"
                      sx={styles.link}
                    >
                      {tripDetails.tripDetails.flightBookingDetails.internalDetails?.duffelOrderId}
                    </Link>
                  )
              }
            ]}
          />
        </Box>
      )}

      {/* Linked Flight Section */}
      {tripDetails.tripDetails.flightBookingDetails?.flightLinkedToOffer && (
        <Box sx={styles.contentSection}>
          <Table
            title="Linked Flight Details"
            columns={[
              { key: 'key', label: 'Key' },
              { key: 'value', label: 'Data' }
            ]}
            data={[
              {
                key: 'Flight Number',
                value: tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.flightNumber
              },
              {
                key: 'Origin',
                value: `${tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.origin.city.name} (${tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.origin.airport.name}), ${tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.origin.country.name}`
              },
              {
                key: 'Destination',
                value: `${tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.destination.city.name} (${tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.destination.airport.name}), ${tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.destination.country.name}`
              },
              {
                key: 'Arrival Time',
                value: joinElementsWithBullet([
                  date(tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.arrivalTime).format(
                    dateTimeFormats.date.long
                  ),
                  date(tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.arrivalTime).format(
                    dateTimeFormats.time.short
                  )
                ])
              },
              {
                key: 'Airline',
                value: (
                  <Box sx={styles.airlineInfo}>
                    {tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.airline.logo && (
                      <img
                        src={tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.airline.logo}
                        alt={tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.airline.name}
                        style={styles.airlineLogo}
                      />
                    )}
                    <span>{tripDetails.tripDetails.flightBookingDetails.flightLinkedToOffer.airline.name}</span>
                  </Box>
                )
              }
            ]}
          />
        </Box>
      )}
    </Box>
  )
}

export default TripDetailsCard
