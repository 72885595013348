import { configureRequest } from '../../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../../networkRequests/apiClient/env.config'
import status from '../../../../../../shared/functions/Status/status.dictionaries'
import { UserRole } from '../../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAllVirtualCardRequestsForAdminErrorResponse,
  GetAllVirtualCardRequestsForAdminRequest,
  GetAllVirtualCardRequestsForAdminSuccessResponse
} from './getAllVirtualCardRequestsForAdmin.types'

export const getAllVirtualCardRequestsForAdminApi = async (
  request: GetAllVirtualCardRequestsForAdminRequest
): Promise<GetAllVirtualCardRequestsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: urls.admin.getAllVirtualCardRequestsForAdmin,
    method: 'GET',
    data: request,
    params: {
      flightItineraryId: request.flightItineraryId || '',
      status: status.virtualCardStatus.virtualCardRequestCreated,
      searchQuery: request.searchQuery || '',
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllVirtualCardRequestsForAdminSuccessResponse
  } else {
    throw response as GetAllVirtualCardRequestsForAdminErrorResponse
  }
}

export default getAllVirtualCardRequestsForAdminApi
