import Modal from '../../components/Modal/Modal.functions'
import { ShowModalProps } from '../../components/Modal/context/Modal.context.types'
import Snackbar from '../../components/Snackbar/Snackbar.functions'
import { ApiErrorResponse } from '../../types/api.types'
import { setAuthenticationStatus } from '../Auth/auth.functions'
import { sentenceCase } from '../String/string.functions'

/**
 * Handles API errors and displays a snackbar or modal if the error is not handled by the caller.
 * @param error - The error object.
 * @param onError - An optional callback function to be called after the error is handled.
 * @param options - Optional configuration for error display
 */
export const handleApiError = (
  error: ApiErrorResponse,
  onError?: (data?: ApiErrorResponse) => void,
  hideSnackbar?: boolean,
  modalContent?: ShowModalProps
): void => {
  const errorMessage = error?.userFriendlyMessage || 'An error occurred processing your request. Please try again.'

  if (
    error?.status === 401 &&
    (error?.message.includes('Token is required') || error?.message.includes('Invalid or expired token'))
  ) {
    setAuthenticationStatus(false)

    if (!hideSnackbar) {
      const message = 'Your session has expired. Please login again.'

      if (modalContent) {
        Modal.show({
          ...modalContent,
          title: modalContent.title || 'Session Expired',
          body: modalContent.body || message,
          icon: modalContent.icon || 'warning'
        })
      } else {
        Snackbar.show({ message, severity: 'warning' })
      }
    }
  } else {
    if (!hideSnackbar) {
      if (modalContent) {
        Modal.show({
          ...modalContent,
          title: sentenceCase(modalContent.title || 'Request Failed'),
          body: modalContent.body || errorMessage,
          icon: modalContent.icon || 'error'
        })
      } else {
        Snackbar.show({ message: errorMessage, severity: 'error' })
      }
    }
  }

  onError && onError(error)
}

/**
 * Checks if the error code matches any of the provided codes.
 * @param error - The error object.
 * @param codes - The list of error codes to check against.
 * @returns A boolean indicating whether the error code matches any of the provided codes.
 */
const isErrorCode = (error: unknown, ...codes: number[]): boolean => codes.includes((error as ApiErrorResponse).status)

/**
 * Handles default errors and displays a snackbar if the error is not handled by the caller.
 * @param error - The error object.
 */
export const handleDefaultError = (error: unknown) => {
  errorHandler.handleApiError(error as ApiErrorResponse)
}

export const getIssuesFromError = (error: ApiErrorResponse): string[] | undefined => {
  if (!error.details?.length) return undefined

  const issues: string[] = []

  for (const detail of error.details) {
    if (detail.issue && typeof detail.issue === 'string') {
      issues.push(detail.issue)
    }
  }

  return issues.length ? issues : undefined
}

const errorHandler = {
  handleDefaultError,
  handleApiError,
  isErrorCode
}

export default errorHandler
