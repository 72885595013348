import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../../shared/types/api.types'
import { assignVirtualCardToFlightItineraryForAdminApi } from './assignVirtualCardToFlightItineraryForAdmin.api'
import assignVirtualCardToFlightItineraryForAdminInitialState from './assignVirtualCardToFlightItineraryForAdmin.initialState'
import {
  AssignVirtualCardToFlightItineraryForAdminErrorResponse,
  AssignVirtualCardToFlightItineraryForAdminRequestPayload
} from './assignVirtualCardToFlightItineraryForAdmin.types'

export const assignVirtualCardToFlightItineraryForAdmin = createAsyncThunk(
  urls.admin.assignVirtualCardToFlightItineraryForAdmin,
  async (payload: AssignVirtualCardToFlightItineraryForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await assignVirtualCardToFlightItineraryForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const assignVirtualCardToFlightItineraryForAdminSlice = createSlice({
  name: urls.admin.assignVirtualCardToFlightItineraryForAdmin,
  initialState: assignVirtualCardToFlightItineraryForAdminInitialState,
  reducers: {
    resetAssignVirtualCardToFlightItineraryForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignVirtualCardToFlightItineraryForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(assignVirtualCardToFlightItineraryForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(assignVirtualCardToFlightItineraryForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as AssignVirtualCardToFlightItineraryForAdminErrorResponse
        state.success = null
      })
  }
})

export const {
  actions: assignVirtualCardToFlightItineraryForAdminActions,
  reducer: assignVirtualCardToFlightItineraryForAdminReducer
} = assignVirtualCardToFlightItineraryForAdminSlice
export const { resetAssignVirtualCardToFlightItineraryForAdmin } = assignVirtualCardToFlightItineraryForAdminActions
export default assignVirtualCardToFlightItineraryForAdminSlice.reducer
