import { useCallback, useMemo, useState } from 'react'

import { useMessaging } from '@/shared/components/Chat/context/Messaging.context'
import { ConversationParticipant } from '@/shared/functions/Firebase/Messaging/messaging.types'
import { UserRole } from '@/shared/functions/UserRole/userRoleManagement.types'
import { ChatOutlined, ShareOutlined } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Avatar from '../../../../shared/components/Avatar/Avatar.component'
import EmptyState from '../../../../shared/components/EmptyState/EmptyState.component'
import ShareOfferRequestLinkModal from '../../../../shared/components/ShareOfferRequestLinkModal/ShareOfferRequestLinkModal.component'
import ItemRequestAccordion from '../ItemRequestAccordion/ItemRequestAccordion.component'

import { confirmOrderDelivery } from '../../../../redux/states/offers/confirmOrderDelivery/confirmOrderDelivery.slice'
import { ConfirmOrderDeliveryRequestPayload } from '../../../../redux/states/offers/confirmOrderDelivery/confirmOrderDelivery.types'
import { ItemRequest, OfferRequest } from '../../../../redux/states/offers/getOfferRequests/getOfferRequests.types'
import { releaseOfferRequest } from '../../../../redux/states/offers/releaseOfferRequest/releaseOfferRequest.slice'
import { ReleaseOfferRequestRequestPayload } from '../../../../redux/states/offers/releaseOfferRequest/releaseOfferRequest.types'
import { requestOfferRequestRefund } from '../../../../redux/states/payments/requestOfferRequestRefund/requestOfferRequestRefund.slice'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import {
  AccordionAlertProps,
  AccordionButtonProps,
  AccordionHeaderProps
} from '../../../../shared/components/Accordion/Accordion.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import { isItemInTransitToShopper } from '../../../../shared/components/TrackingDetails/TrackingDetails.functions'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import status from '../../../../shared/functions/Status/status.dictionaries'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import useResponsiveness from '../../../../shared/hooks/responsive.hooks'
import styles from './OfferRequestsAccordion.styles'

const OfferRequestsAccordion = (props: PaginatedRenderProps) => {
  const offerRequest = props.item as unknown as OfferRequest
  const refreshData = props.refreshData

  const [isRequestRefundLoading, setIsRequestRefundLoading] = useState<string[]>([])
  const [isReleaseOfferRequestLoading, setIsReleaseOfferRequestLoading] = useState<string[]>([])
  const [isConfirmDeliveryLoading, setIsConfirmDeliveryLoading] = useState<string[]>([])

  const [isDesktop] = useResponsiveness()
  const { openChat } = useMessaging()

  const navigate = router.navigate()
  const dispatch = useAppDispatch()

  const onRequestRefund = useCallback(() => {
    Modal.show({
      title: 'Accept Refund',
      subtitle: 'Your offer will no longer be available for any Hurrier to carry and a refund will be processed.',
      primaryButton: {
        label: 'Confirm',
        onClick: () => {
          const payload = {
            request: {
              offerRequestId: offerRequest.offerRequestId.toString()
            },
            onSuccess: () => {
              refreshData?.(() => {
                setIsRequestRefundLoading((prev) => prev.filter((id) => id !== offerRequest.offerRequestId.toString()))
              })
            },
            onError: () => {
              setIsRequestRefundLoading((prev) => prev.filter((id) => id !== offerRequest.offerRequestId.toString()))
            }
          }

          setIsRequestRefundLoading((prev) => [...prev, offerRequest.offerRequestId.toString()])
          dispatch(requestOfferRequestRefund(payload))
        }
      },
      secondaryButton: {
        label: 'Dismiss'
      }
    })
  }, [dispatch, offerRequest.offerRequestId, refreshData])

  const onFindTraveler = useCallback(() => {
    Modal.show({
      title: 'Find Traveler',
      subtitle: 'Your offer will be placed on the Offers marketplace for other Hurriers to accept.',
      primaryButton: {
        label: 'Confirm',
        onClick: () => {
          const payload: ReleaseOfferRequestRequestPayload = {
            request: {
              offerRequestId: offerRequest.offerRequestId.toString()
            },
            onSuccess: () => {
              refreshData?.(() => {
                setIsReleaseOfferRequestLoading((prev) =>
                  prev.filter((id) => id !== offerRequest.offerRequestId.toString())
                )
              })
            },
            onError: () => {
              setIsReleaseOfferRequestLoading((prev) =>
                prev.filter((id) => id !== offerRequest.offerRequestId.toString())
              )
            }
          }

          setIsReleaseOfferRequestLoading((prev) => [...prev, offerRequest.offerRequestId.toString()])
          dispatch(releaseOfferRequest(payload))
        }
      },
      secondaryButton: {
        label: 'Dismiss'
      }
    })
  }, [dispatch, offerRequest.offerRequestId, refreshData])

  const currentAlert = useMemo(() => {
    const paymentStatus = offerRequest.paymentStatus?.name
    const tripStatus = offerRequest.tripDetails?.tripStatus

    const isFlightItineraryCancelled =
      tripStatus === status.flightStatus.flightBookingCancelledBeforePayment ||
      tripStatus === status.flightStatus.flightCancelledAfterPayment

    const isOfferRequestRefunded =
      paymentStatus === status.paymentStatus.refundConfirmed ||
      paymentStatus === status.paymentStatus.paymentHoldCancelled

    const isPaymentFailed =
      paymentStatus === status.paymentStatus.paymentFailed || paymentStatus === status.paymentStatus.paymentHoldFailed

    if (isFlightItineraryCancelled && isOfferRequestRefunded) {
      return {
        message: `Your order has been refunded.`,
        type: 'warning',
        tag: 'refund-processed'
      } as AccordionAlertProps
    } else if (isFlightItineraryCancelled) {
      const travelerName = `${offerRequest.tripDetails.traveler.firstName}`

      return {
        message: `${travelerName} canceled their trip. You may accept a refund or find a new traveler.`,
        type: 'error',
        tag: 'flight-cancelled',
        buttons: [
          {
            text: 'Accept Refund',
            onClick: onRequestRefund,
            loading: isRequestRefundLoading.includes(offerRequest.offerRequestId.toString())
          },
          {
            text: 'Find Traveler',
            onClick: onFindTraveler,
            loading: isReleaseOfferRequestLoading.includes(offerRequest.offerRequestId.toString())
          }
        ]
      } as AccordionAlertProps
    } else if (isPaymentFailed) {
      return {
        message: `There was an issue processing your payment for this order.`,
        type: 'error',
        tag: 'payment-failed',
        buttons: [
          {
            text: 'Update Payment Details',
            onClick: () => navigate(routes.profile.path, { state: { selectedSection: 'payment' } })
          }
        ]
      } as AccordionAlertProps
    }

    return null
  }, [
    offerRequest.paymentStatus?.name,
    offerRequest.tripDetails?.tripStatus,
    offerRequest.tripDetails?.traveler.firstName,
    offerRequest.offerRequestId,
    isRequestRefundLoading,
    onRequestRefund,
    navigate,
    isReleaseOfferRequestLoading,
    onFindTraveler
  ])

  const canOfferRequestBeInteractedWith = useMemo(() => {
    const tripStatus = offerRequest.tripDetails?.tripStatus
    const paymentStatus = offerRequest.paymentStatus?.name
    const offerStatus = offerRequest.offerStatus
    const isDeliveryConfirmed = offerStatus === status.deliveryStatus.confirmedDeliveredToShopperByShopper

    let canBeInteractedWith = true

    const isFlightItineraryCancelled =
      tripStatus === status.flightStatus.flightBookingCancelledBeforePayment ||
      tripStatus === status.flightStatus.flightCancelledAfterPayment

    const isPaymentRefunded =
      paymentStatus === status.paymentStatus.refundConfirmed ||
      paymentStatus === status.paymentStatus.paymentHoldCancelled

    if (isFlightItineraryCancelled || isPaymentRefunded || isDeliveryConfirmed) {
      canBeInteractedWith = false
    }

    return canBeInteractedWith
  }, [offerRequest.tripDetails?.tripStatus, offerRequest.paymentStatus?.name, offerRequest.offerStatus])

  const refreshOfferRequests = (onSuccess?: (data?: unknown) => void) => {
    refreshData?.(onSuccess)
  }

  const getAccordionHeader = () => {
    const orderNumber = offerRequest.offerRequestId
    const buttons: AccordionButtonProps[] = []
    const isDeliveryConfirmed = offerRequest.offerStatus === status.deliveryStatus.confirmedDeliveredToShopperByShopper
    const travelerName = `${offerRequest.tripDetails?.traveler.firstName} ${offerRequest.tripDetails?.traveler.lastName}`
    const isOfferSharable = offerRequest.tripDetails === null

    const isOfferRequestCancelled = offerRequest.itemRequests.every(
      (itemRequest) => itemRequest.itemDetails.status?.name === status.itemRequestStatus.cancelledByShopper
    )

    const isOfferRequestInTransit = offerRequest.itemRequests.some((itemRequest) =>
      isItemInTransitToShopper(itemRequest.itemDetails.status?.name)
    )

    const isChatVisible = isOfferRequestInTransit && offerRequest.tripDetails?.traveler?.userId !== undefined

    if (isChatVisible) {
      buttons.push({
        buttonType: 'tertiary',
        icon: <ChatOutlined />,
        iconPosition: 'center',
        onClick: () => {
          const currentUser: ConversationParticipant = {
            hurrierUserId: offerRequest.shopperDetails.userId.toString(),
            firebaseUserId: offerRequest.shopperDetails.firebaseUserId ?? '',
            firstName: offerRequest.shopperDetails.firstName,
            lastName: offerRequest.shopperDetails.lastName,
            role: UserRole.shopper
          }

          const chatPartner: ConversationParticipant = {
            hurrierUserId: offerRequest.tripDetails?.traveler.userId.toString(),
            firebaseUserId: offerRequest.tripDetails?.traveler.firebaseUserId ?? '',
            firstName: offerRequest.tripDetails?.traveler.firstName,
            lastName: offerRequest.tripDetails?.traveler.lastName,
            role: UserRole.traveler
          }

          const orderId = offerRequest.offerRequestId.toString()

          openChat(currentUser, chatPartner, orderId)
        },
        tooltipText: `Message ${offerRequest.tripDetails?.traveler.firstName}`
      })
    }

    if (isOfferRequestInTransit) {
      buttons.push({
        text: isDeliveryConfirmed ? 'Delivery Confirmed' : 'Confirm Delivery',
        buttonType: 'primary',
        onClick: () => {
          Modal.show({
            title: 'Confirm Delivery',
            subtitle: `Have you received all items in this order from ${travelerName}?`,
            primaryButton: {
              label: 'Yes, Confirm',
              loading: isConfirmDeliveryLoading.includes(offerRequest.offerRequestId.toString()),
              onClick: () => {
                const payload: ConfirmOrderDeliveryRequestPayload = {
                  request: {
                    offerRequestId: offerRequest.offerRequestId.toString()
                  },
                  onSuccess: () => {
                    refreshData?.(() => {
                      setIsConfirmDeliveryLoading((prev) =>
                        prev.filter((id) => id !== offerRequest.offerRequestId.toString())
                      )
                    })
                  },
                  onError: () => {
                    setIsConfirmDeliveryLoading((prev) =>
                      prev.filter((id) => id !== offerRequest.offerRequestId.toString())
                    )
                  }
                }

                dispatch(confirmOrderDelivery(payload))
              }
            },
            secondaryButton: {
              label: 'Dismiss'
            }
          })
        },
        disabled: isDeliveryConfirmed,
        loading: isConfirmDeliveryLoading.includes(offerRequest.offerRequestId.toString()),
        tooltipText: isDeliveryConfirmed
          ? 'Delivery has been confirmed.'
          : 'Confirm if you have received all items in this order.'
      })
    } else if (isOfferRequestCancelled) {
      buttons.push({
        text: 'Canceled',
        buttonType: 'primary',
        disabled: true,
        tooltipText: 'All items in this order have been canceled.'
      })
    } else if (isOfferSharable) {
      buttons.push({
        text: isDesktop ? 'Share Link' : undefined,
        buttonType: 'secondary',
        icon: <ShareOutlined />,
        onClick: () => {
          const userId = offerRequest.shopperDetails.userId
          const offerRequestId = offerRequest.offerRequestId.toString()
          const shareLink = `${window.location.origin}/${routes.offers.path}?offerRequestId=${offerRequestId}&shopperId=${userId}`
          const sourceCountry = offerRequest.itemRequests[0].itemDetails.country

          Modal.show({
            title: 'Share Offer Request',
            body: <ShareOfferRequestLinkModal sourceCountry={sourceCountry} shareLink={shareLink} open={true} />,
            primaryButton: {
              label: 'Close',
              onClick: () => Modal.close()
            }
          })
        },
        tooltipText: 'Share item carry request to easily get travelers'
      })
    }

    const accordionHeader: AccordionHeaderProps = {
      mainContent: (
        <Typography variant={isDesktop ? 'h6' : 'h5'} sx={styles.accordionHeaderMainContent}>
          Order# {orderNumber}
        </Typography>
      ),
      buttons,
      buttonsWidth: null
    }

    return accordionHeader
  }

  const renderItemRequest = (itemRequest: ItemRequest) => (
    <ItemRequestAccordion
      key={itemRequest.itemRequestId}
      offerRequestId={offerRequest.offerRequestId.toString()}
      itemRequest={itemRequest}
      deliveryDetails={offerRequest.deliveryDetails}
      tripDetails={offerRequest.tripDetails}
      shopperDetails={offerRequest.shopperDetails}
      refreshOfferRequests={refreshOfferRequests}
      isInteractionEnabled={canOfferRequestBeInteractedWith}
    />
  )

  const getArrivalDate = () => {
    const deliveryCity = offerRequest.deliveryDetails.city
    const flightItinerary = offerRequest.tripDetails.flights

    for (let i = flightItinerary.length - 1; i >= 0; i--) {
      const flight = flightItinerary[i]

      if (flight.destination?.city?.name === deliveryCity.name) {
        return date(flight.arrivalTime).format(dateTimeFormats.date.medium)
      }
    }

    return null
  }

  const renderDetails = () => {
    const hasItemRequests = offerRequest.itemRequests.length > 0
    const travelerName = `${offerRequest.tripDetails?.traveler.firstName} ${offerRequest.tripDetails?.traveler.lastName}`

    const shopperDetails = () => {
      if (offerRequest.tripDetails !== null && offerRequest.tripDetails.traveler !== null) {
        const arrivalDate = getArrivalDate() ?? ''
        const formattedArrivalDate = date(arrivalDate).format(dateTimeFormats.date.medium)
        const isArrivalDatePast = date(formattedArrivalDate!).isBefore(date().currentDate)

        return (
          <Box sx={styles.shopperDetailsContainer}>
            <Box sx={styles.shopperDetailsWrapper}>
              <Avatar
                firstName={offerRequest.tripDetails.traveler.firstName}
                lastName={offerRequest.tripDetails.traveler.lastName}
                size={'small'}
              />

              <Box style={styles.shopperDetailsTextContainer}>
                <Typography sx={styles.shopperDetailsName}>{travelerName}</Typography>
                <Typography sx={styles.shopperDetailsTag}>Hurrier</Typography>
              </Box>
            </Box>

            {!isArrivalDatePast && <Chip label={`Arriving: ${getArrivalDate()}`} size="small" color="secondary" />}
          </Box>
        )
      }
    }

    if (!hasItemRequests) {
      return (
        <Box style={styles.emptyStateContainer}>
          <EmptyState
            title={'You have no orders available'}
            button={{
              text: 'Shop Now',
              onClick: () => navigate(routes.shop.path)
            }}
          />
        </Box>
      )
    } else {
      return (
        <Box sx={styles.itemRequestsContainer}>
          {offerRequest.itemRequests.map(renderItemRequest)}

          {/* TODO: Add logic to check if the trip is completed */}
          {shopperDetails()}
        </Box>
      )
    }
  }

  return (
    <Accordion
      header={getAccordionHeader()}
      body={renderDetails()}
      alert={currentAlert ?? undefined}
      noExpandIcon
      noExpandBackgroundColor
      isExpanded
      preventExpandChange
      hasBorder
      hasPadding
    />
  )
}

export default OfferRequestsAccordion
