import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import PageBanner from '../../shared/components/PageBanner/PageBanner.component'
import StepByStepGuide from '../../shared/components/StepByStepGuide/StepByStepGuide.component'
import TestimonialCarousel from '../../shared/components/TestimonialCarousel/TestimonialCarousel.component'
import { MarketplaceLinksInput } from './components/MarketplaceLinksInput/MarketplaceLinksInput.component'

import { PageBannerImage } from '../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { RootState } from '../../redux/store/store.types'
import { UserRole } from '../../shared/functions/UserRole/userRoleManagement.types'
import { setUserRole } from '../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppSelector } from '../../shared/hooks/redux.hooks'
import dictionary from './Shop.dictionary'
import styles from './Shop.styles'

const ShopPage: React.FC = () => {
  const { values } = useAppSelector((state: RootState) => state.firebaseRemoteConfig)
  const shopBannerImages = (values?.shopBannerImages as PageBannerImage[]) ?? []

  useEffect(() => {
    setUserRole(UserRole.shopper)
  }, [])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.bannerContainer}>
        <PageBanner
          images={shopBannerImages}
          title={dictionary.pageBanner.title}
          subtitle={dictionary.pageBanner.subtitle}
          links={dictionary.pageBanner.links}
        />

        <MarketplaceLinksInput />
      </Box>

      <StepByStepGuide
        steps={dictionary.howToTravelAndEarn.steps}
        title={dictionary.howToTravelAndEarn.title}
        subtitle={dictionary.howToTravelAndEarn.subtitle}
      />

      <TestimonialCarousel />
    </Box>
  )
}

export default ShopPage
