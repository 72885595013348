import { Calendar } from '@/shared/components/shadcn/ui/calendar'
import theme from '@/shared/styles/themes/default.theme'
import { Close } from '@mui/icons-material'
import { Box, IconButton, Modal, Typography } from '@mui/material'

import styles from './DatePicker.styles'
import { SingleDatePickerProps } from './DatePicker.types'

const SingleDatePicker = (props: SingleDatePickerProps) => {
  const { open, onClose, setDate, toDate, onBlur, onFocus, disablePast, date } = props

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.container()}>
        <Box sx={styles.header}>
          <Typography variant="h6" component="h2">
            Select Date
          </Typography>

          <IconButton sx={styles.closeIcon} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Calendar
          initialFocus
          mode="single"
          defaultMonth={date}
          selected={date}
          onSelect={setDate}
          numberOfMonths={1}
          toDate={toDate}
          fromDate={disablePast ? new Date() : undefined}
          onDayBlur={onBlur}
          onDayFocus={onFocus}
          modifiersStyles={{
            selected: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              borderRadius: theme.shape.borderRadius
            },
            disabled: {
              color: theme.palette.text.disabled
            },
            today: {
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: theme.shape.borderRadius
            }
          }}
        />
      </Box>
    </Modal>
  )
}

export default SingleDatePicker
