import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  container: (marginBottom?: number) =>
    ({
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(marginBottom ?? 2),
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      gap: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    }) as SxProps,

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  } as SxProps,

  title: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    textAlign: 'left',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '24px'
    }
  } as SxProps,

  subtitle: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '16px',
    textAlign: 'left',
    flexWrap: 'wrap'
  } as SxProps
}

export default styles
