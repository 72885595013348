import React from 'react'

import { getFormattedTripType } from '@/shared/functions/Flights/flights.functions'
import { TripType } from '@/shared/functions/Flights/flights.types'
import { Box, Typography } from '@mui/material'

import {
  getFormattedTravelerDateDetails,
  getFormattedTripDetails,
  getFormattedTripDetailsMobile
} from '../../../pages/Trips/Trips.functions'
import { FlightItineraryLeg } from '../../functions/Places/places.types'
import useResponsiveness from '../../hooks/responsive.hooks'
import styles from './FlightItineraryHeader.styles'
import { FlightItineraryHeaderProps } from './FlightItineraryHeader.types'

const FlightItineraryHeader: React.FC<FlightItineraryHeaderProps> = (props) => {
  const { flightItinerary } = props
  const [isDesktop] = useResponsiveness()

  const locationsDetails = isDesktop
    ? getFormattedTripDetails(
        flightItinerary?.itinerary?.legs as unknown as FlightItineraryLeg[],
        flightItinerary?.itinerary?.tripType as TripType
      )
    : getFormattedTripDetailsMobile(
        flightItinerary?.itinerary?.legs as unknown as FlightItineraryLeg[],
        flightItinerary?.itinerary?.tripType as TripType
      )
  const travelerDateDetails = getFormattedTravelerDateDetails(flightItinerary)
  const formattedTripType = getFormattedTripType(flightItinerary?.itinerary?.tripType as TripType)

  return (
    <Box sx={styles.accordionHeaderMainContent}>
      <Box sx={styles.accordionHeaderMainContentTitleContainer}>
        <Typography sx={styles.accordionHeaderMainContentTitleText}>{locationsDetails}</Typography>

        <Box sx={styles.tripTypeContainer}>
          <Typography sx={styles.tripTypeText}>{formattedTripType}</Typography>
        </Box>
      </Box>

      <Typography sx={styles.accordionHeaderMainContentSubtitleText}>{travelerDateDetails}</Typography>
    </Box>
  )
}

export default FlightItineraryHeader
