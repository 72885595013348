import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  UpdateFirebaseUserIdErrorResponse,
  UpdateFirebaseUserIdRequest,
  UpdateFirebaseUserIdSuccessResponse
} from './updateFirebaseUserId.types'

export const updateFirebaseUserIdApi = async (
  request: UpdateFirebaseUserIdRequest
): Promise<UpdateFirebaseUserIdSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.updateFirebaseUserId,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as UpdateFirebaseUserIdSuccessResponse
  } else {
    throw response as UpdateFirebaseUserIdErrorResponse
  }
}

export default updateFirebaseUserIdApi
