import React, { KeyboardEvent, useState } from 'react'

import { SendOutlined } from '@mui/icons-material'
import { Box, IconButton, TextField } from '@mui/material'

import styles from './ChatInput.styles'
import { ChatInputProps } from './ChatInput.types'

const ChatInput: React.FC<ChatInputProps> = (props) => {
  const { onSendMessage, disabled = false } = props
  const [message, setMessage] = useState('')

  const handleSendMessage = () => {
    if (message.trim() && !disabled) {
      onSendMessage(message)
      setMessage('')
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <Box sx={styles.inputContainer}>
      <TextField
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent<HTMLTextAreaElement>)}
        fullWidth
        disabled={disabled}
        variant="standard"
        multiline
        maxRows={3}
        sx={styles.textField}
        InputProps={{
          disableUnderline: true
        }}
      />

      <IconButton
        color="primary"
        onClick={handleSendMessage}
        disabled={disabled || !message.trim()}
        sx={styles.sendButton}
      >
        <SendOutlined fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default ChatInput
