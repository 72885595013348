import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { checkOnboardingInfoApi } from './checkOnboardingInfo.api'
import checkOnboardingInfoInitialState from './checkOnboardingInfo.initialState'
import { CheckOnboardingInfoErrorResponse, CheckOnboardingInfoRequestPayload } from './checkOnboardingInfo.types'

export const checkOnboardingInfo = createAsyncThunk(
  urls.payments.checkOnboardingInfo,
  async (payload: CheckOnboardingInfoRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess, onError } = payload

    try {
      const response = await checkOnboardingInfoApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      onError && onError()
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const checkOnboardingInfoSlice = createSlice({
  name: urls.payments.checkOnboardingInfo,
  initialState: checkOnboardingInfoInitialState,
  reducers: {
    resetCheckOnboardingInfo: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }

    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkOnboardingInfo.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(checkOnboardingInfo.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(checkOnboardingInfo.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CheckOnboardingInfoErrorResponse
        state.success = null
      })
  }
})

export const { actions: checkOnboardingInfoActions, reducer: checkOnboardingInfoReducer } = checkOnboardingInfoSlice
export const { resetCheckOnboardingInfo } = checkOnboardingInfoActions
export default checkOnboardingInfoSlice.reducer
