export type PassengerType = 'adult' | 'child'

export const cabinClassDisplayName = {
  Economy: 'Economy',
  PremiumEconomy: 'Economy Premium',
  Business: 'Business',
  First: 'First'
} as const

export type CabinClassDisplayName = (typeof cabinClassDisplayName)[keyof typeof cabinClassDisplayName]

export const cabinClassMapping = {
  economy: cabinClassDisplayName.Economy,
  premium_economy: cabinClassDisplayName.PremiumEconomy,
  business: cabinClassDisplayName.Business,
  first: cabinClassDisplayName.First
} as const

export const tripTypeDisplayName = {
  RoundTrip: 'Round Trip',
  OneWay: 'One Way',
  MultiCity: 'Multi-City'
} as const

export type TripTypeDisplayName = (typeof tripTypeDisplayName)[keyof typeof tripTypeDisplayName]

export const tripTypeMapping = {
  roundTrip: tripTypeDisplayName.RoundTrip,
  oneWay: tripTypeDisplayName.OneWay,
  multiCity: tripTypeDisplayName.MultiCity
} as const

export type TripType = keyof typeof tripTypeMapping

export type CabinClass = keyof typeof cabinClassMapping

export type FlightItinerarySource = 'internal' | 'external'
