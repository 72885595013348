import React from 'react'

import { Box, Grid, Typography } from '@mui/material'

import MainPageSection from '../MainPageSection/MainPageSection.component'

import { styles } from './StepByStepGuide.styles'
import { StepByStepGuideProps } from './StepByStepGuide.types'

const StepByStepGuide: React.FC<StepByStepGuideProps> = (props) => {
  const { steps, title, subtitle, isCompact = false } = props

  return (
    <MainPageSection title={title} subtitle={subtitle} headerAlignment="center">
      <Grid container sx={styles.stepsContainer}>
        {steps.map((step, index) => (
          <Grid item key={index} xs={12} md={3} sx={styles.step}>
            <Box sx={styles.stepContent(index === steps.length - 1)}>
              <Box sx={styles.icon(isCompact)}>{step.icon}</Box>

              <Typography variant="h6" sx={styles.stepTitle(isCompact)}>
                {step.title}
              </Typography>

              <Typography sx={styles.stepDescription(isCompact)}>{step.description}</Typography>
            </Box>

            {/* {index !== steps.length - 1 && <Divider orientation="vertical" />} */}
          </Grid>
        ))}
      </Grid>
    </MainPageSection>
  )
}

export default StepByStepGuide
