import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../styles/themes/default.theme'

const styles = {
  searchBarSkeletonItem: {
    borderRadius: theme.spacing(2)
  } as SxProps,

  skeletonMainContainer: (searchBar: boolean) =>
    ({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: theme.spacing(2),
      ...(searchBar ? { padding: 0 } : { padding: theme.spacing(5, 10) }),
      [theme.breakpoints.down('md')]: {
        ...(searchBar ? { padding: 0 } : { padding: theme.spacing(0) })
      }
    }) as CSSProperties,

  skeletonContentContainer: (searchBar: boolean) =>
    ({
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(5),
      ...(searchBar ? { padding: theme.spacing(5, 10) } : { padding: 0 }),
      [theme.breakpoints.down('md')]: {
        ...(searchBar ? { padding: theme.spacing(2, 0) } : { padding: 0 })
      }
    }) as CSSProperties,

  skeletonContentLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    width: '20%'
  } as SxProps,

  skeletonContentLeftContainerItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  } as SxProps,

  skeletonContentLeftContainerItemTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1)
  } as SxProps,

  skeletonContentRightContainer: (leftContainerPresent: boolean) =>
    ({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      width: leftContainerPresent ? '80%' : '100%'
    }) as CSSProperties,

  searchBarSkeleton: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    padding: theme.spacing(5, 10),
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3)
    }
  } as SxProps,

  skeletonGroupHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2)
  } as SxProps,

  skeletonGroupHeaderTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1)
  } as SxProps,

  skeletonGroupTabContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '30%'
  } as SxProps,

  skeletonGroupTabs: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
    width: '100%'
  } as SxProps,

  skeletonGroupBody: (masonry: boolean) =>
    ({
      display: 'flex',
      flexDirection: masonry ? 'row' : 'column',
      gap: theme.spacing(2)
    }) as CSSProperties
}

export default styles
