import FlightTakeoff from '@mui/icons-material/FlightTakeoff'
import LinkIcon from '@mui/icons-material/Link'
import SearchIcon from '@mui/icons-material/Search'

import images from '../../assets/images'
import { styles } from '../../shared/components/StepByStepGuide/StepByStepGuide.styles'

const TruckIcon = images.TruckIcon

const dictionary = {
  pageBanner: {
    title: "Let's Help You Earn and Travel For Less",
    subtitle: 'Get Paid or Flight Discounts to deliver items to international shoppers',
    links: [
      {
        text: 'How It Works - Book a Flight',
        url: 'https://www.youtube.com/watch?v=7qJTx97WGow'
      },
      {
        text: 'How It Works - Add Existing Flight',
        url: 'https://www.youtube.com/watch?v=Z7QXaCca8tc'
      }
    ]
  },

  howToTravelAndEarn: {
    title: 'How to travel and earn',
    subtitle: 'In 4 simple steps, you can fly for less.',
    steps: [
      {
        icon: <SearchIcon />,
        title: 'Add A Flight',
        description: 'Search for a new flight or add your existing one.'
      },

      {
        icon: <LinkIcon sx={styles.rotatedIcon} />,
        title: 'Share Link or Select Offers',
        description: "Let others know you're traveling or select available offers on Hurrier."
      },

      {
        icon: <FlightTakeoff />,
        title: 'Book or Confirm Your Flight',
        description: 'Carry items, get paid — with flight discounts or cash straight to your bank account.'
      },

      {
        icon: <TruckIcon />,
        title: 'Buy and Deliver The Item',
        description: 'Use our virtual card to buy the Amazon item and deliver it directly to the shopper.'
      }
    ]
  }
}

export default dictionary
