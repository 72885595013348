import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetOfferRequestPaymentDetailsForAdminErrorResponse,
  GetOfferRequestPaymentDetailsForAdminRequest,
  GetOfferRequestPaymentDetailsForAdminSuccessResponse
} from './getOfferRequestPaymentDetailsForAdmin.types'

export const getOfferRequestPaymentDetailsForAdminApi = async (
  request: GetOfferRequestPaymentDetailsForAdminRequest
): Promise<GetOfferRequestPaymentDetailsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getOfferRequestPaymentDetailsForAdmin, {
      offerRequestId: request.offerRequestId
    }),
    method: 'GET',
    params: {},
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetOfferRequestPaymentDetailsForAdminSuccessResponse
  } else {
    throw response as GetOfferRequestPaymentDetailsForAdminErrorResponse
  }
}

export default getOfferRequestPaymentDetailsForAdminApi
