import { configureRequest } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../networkRequests/apiClient/env.config'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAllUsersForAdminErrorResponse,
  GetAllUsersForAdminRequest,
  GetAllUsersForAdminSuccessResponse
} from './getAllUsersForAdmin.types'

export const getAllUsersForAdminApi = async (
  request: GetAllUsersForAdminRequest
): Promise<GetAllUsersForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: urls.admin.getAllUsersForAdmin,
    method: 'GET',
    params: {
      role: request?.role || '',
      searchQuery: request?.searchQuery || '',
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllUsersForAdminSuccessResponse
  } else {
    throw response as GetAllUsersForAdminErrorResponse
  }
}

export default getAllUsersForAdminApi
