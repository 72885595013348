import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: theme.spacing(4),
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },

  contentContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    width: '100%'
  },

  titleText: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    textAlign: 'left',
    marginBottom: theme.spacing(1)
  } as SxProps,

  profileHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: { xs: 'row', md: 'column' },
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[3],
    width: { xs: '100%', md: 'auto' },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(3)
    }
  } as SxProps,

  profilePictureContainer: {
    marginBottom: { md: theme.spacing(6), xs: theme.spacing(3) }
  },

  profileButton: {
    marginBottom: theme.spacing(2)
  },

  profileButtonsContainer: {
    marginLeft: { xs: theme.spacing(2), md: 0 },
    width: '100%',
    [theme.breakpoints.down('md')]: {
      margin: 0
    }
  },

  profileFormsContainer: {
    gap: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  changePasswordContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4)
  },

  changePasswordText: {
    width: '70%',
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },

  paymentMethodsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    width: '100%'
  },

  highlightText: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main
  },

  profileActionCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2)
    }
  }
}

export default styles
