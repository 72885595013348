import { configureRequest } from '../../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  AssignVirtualCardToFlightItineraryForAdminErrorResponse,
  AssignVirtualCardToFlightItineraryForAdminRequest,
  AssignVirtualCardToFlightItineraryForAdminSuccessResponse
} from './assignVirtualCardToFlightItineraryForAdmin.types'

export const assignVirtualCardToFlightItineraryForAdminApi = async (
  request: AssignVirtualCardToFlightItineraryForAdminRequest
): Promise<AssignVirtualCardToFlightItineraryForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: urls.admin.assignVirtualCardToFlightItineraryForAdmin,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as AssignVirtualCardToFlightItineraryForAdminSuccessResponse
  } else {
    throw response as AssignVirtualCardToFlightItineraryForAdminErrorResponse
  }
}

export default assignVirtualCardToFlightItineraryForAdminApi
