import { Itinerary } from '@/redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { changeCase } from '@/shared/functions/String/string.functions'

import { SearchFlightsSuccessResponse } from '../../redux/states/flights/searchFlights/searchFlights.types'
import date, { formatDuration, formatTime } from '../../shared/functions/Date/date.functions'
import { FormattedPartialOffer } from './types/FormattedSearchResults.types'
import { Route } from './types/FormattedSearchResults.types'

/**
 * Formats flight offers from a search results into the format expected by the UI
 * @param flightSearchResults Search results to format
 * @returns Formatted flight offers and routes that can be displayed in the UI
 */
export const formatFlightPartialOffers = (
  flightSearchResults: SearchFlightsSuccessResponse
): { offers: FormattedPartialOffer[]; routes: Route[] } => {
  if (!flightSearchResults.data.offers) {
    return { offers: [], routes: [] }
  }

  const offers: FormattedPartialOffer[] = flightSearchResults.data.offers.map((offer) => {
    return {
      id: offer.id,
      totalAmount: Number(offer.total_amount),
      currency: offer.total_currency,
      airlineLogo: offer.owner.logo_symbol_url,
      airline: offer.owner.name,
      travelClass: changeCase(flightSearchResults.data.cabin_class).sentence,
      slices: offer.slices.map((slice) => {
        return {
          id: slice.id,
          totalDuration: formatDuration(slice.duration),
          origin: slice.origin,
          destination: slice.destination,
          totalAmount: Number(offer.total_amount),
          currency: offer.total_currency,
          airlineLogo: offer.owner.logo_symbol_url,
          travelClass: changeCase(flightSearchResults.data.cabin_class).sentence,
          segments: slice.segments.map((segment) => ({
            id: segment.id,
            duration: formatDuration(segment.duration),
            distance: segment.distance,
            flightNumber: segment.operating_carrier_flight_number,
            origin: segment.origin,
            destination: segment.destination,
            departure: {
              at: segment.departing_at,
              time: formatTime(segment.departing_at)
            },
            arrival: {
              at: segment.arriving_at,
              time: formatTime(segment.arriving_at)
            }
          }))
        }
      })
    }
  })

  if (flightSearchResults.data.slices) {
    const routes = flightSearchResults.data.slices.map((slice) => ({
      origin: slice.origin,
      destination: slice.destination
    }))

    return { offers: offers, routes }
  }

  return { offers: offers, routes: [] }
}

/**
 * Formats associated flights from an itinerary into the format expected by the UI
 * @param itinerary Itinerary with slices to format
 * @returns Formatted flight offers and routes that can be displayed in the UI
 */
export const formatSlicesToOffers = (
  itinerary: Itinerary
): { offer: FormattedPartialOffer | null; routes: Route[] } => {
  if (!itinerary || !itinerary.slices || itinerary.slices.length === 0) {
    return { offer: null, routes: [] }
  }

  const airlineLogo = itinerary.slices[0].segments[0].airline.logoUrl
  const airlineName = itinerary.slices[0].segments[0].airline.name

  const offer: FormattedPartialOffer = {
    id: '1',
    totalAmount: Number(itinerary.totalFlightPrice),
    currency: itinerary.currency,
    airlineLogo: airlineLogo,
    airline: airlineName,
    travelClass: changeCase(itinerary.cabinClass).sentence,
    slices: itinerary.slices.map((slice, index) => {
      const sliceId = `slice-${index}`
      const totalDuration = slice.segments.reduce(
        (acc, segment) => acc + (date().parseISO8601Duration(segment.duration)?.toMillis() ?? 0),
        0
      )
      const totalDurationString = formatDuration(date().convertMillisecondsToISO8601(totalDuration))

      return {
        id: sliceId,
        totalDuration: totalDurationString,
        origin: {
          iata_code: slice.origin.airport.iataCode,
          city_name: slice.origin.airport.name,
          iata_country_code: slice.origin.country.code,
          iata_city_code: slice.origin.city.iataCode,
          time_zone: '',
          name: slice.origin.airport.name,
          id: slice.origin.airport.iataCode
        },
        destination: {
          iata_code: slice.destination.airport.iataCode,
          city_name: slice.destination.airport.name,
          iata_country_code: slice.destination.country.code,
          iata_city_code: slice.destination.city.iataCode,
          time_zone: '',
          name: slice.destination.airport.name,
          id: slice.destination.airport.iataCode
        },
        totalAmount: Number(itinerary.totalFlightPrice),
        currency: itinerary.currency,
        airlineLogo: airlineLogo,
        airline: airlineName,
        travelClass: changeCase(itinerary.cabinClass).sentence,
        segments: slice.segments.map((segment) => {
          const segmentId = `segment-${index}`

          return {
            id: segmentId,
            duration: formatDuration(segment.duration),
            distance: '',
            flightNumber: segment.flightNumber,
            airlineLogo: segment.airline.logoUrl,
            airline: segment.airline.name,
            origin: {
              iata_code: segment.origin.airport.iataCode,
              city_name: segment.origin.airport.name,
              iata_country_code: segment.origin.country.code,
              iata_city_code: segment.origin.city.iataCode,
              time_zone: '',
              name: segment.origin.airport.name,
              id: segment.origin.airport.iataCode
            },
            destination: {
              iata_code: segment.destination.airport.iataCode,
              city_name: segment.destination.airport.name,
              iata_country_code: segment.destination.country.code,
              iata_city_code: segment.destination.city.iataCode,
              time_zone: '',
              name: segment.destination.airport.name,
              id: segment.destination.airport.iataCode
            },
            departure: {
              at: segment.departureTime,
              time: formatTime(segment.departureTime)
            },
            arrival: {
              at: segment.arrivalTime,
              time: formatTime(segment.arrivalTime)
            }
          }
        })
      }
    })
  }

  return { offer: offer, routes: [] }
}
