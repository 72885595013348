import { CSSProperties } from 'react'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  flightDetailsContainer: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '100%'
    }
  } as CSSProperties,

  legHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`
  } as CSSProperties,

  accordionHeaderMainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as CSSProperties,

  tripTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(0.25, 1),
    backgroundColor: theme.palette.warning.light,
    borderRadius: theme.spacing(0.7)
  } as CSSProperties,

  tripTypeText: {
    fontSize: '0.6rem',
    color: theme.palette.warning.contrastText,
    fontWeight: theme.typography.fontWeightBold
  } as CSSProperties,

  accordionHeaderMainContentTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1)
  } as CSSProperties,

  accordionHeaderMainContentTitleText: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as CSSProperties,

  accordionHeaderMainContentSubtitleText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left'
  } as CSSProperties,

  accordionHeaderSecondaryContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  } as CSSProperties,

  accordionHeaderSecondaryContentTitleText: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as CSSProperties,

  accordionHeaderSecondaryContentSubtitleText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500],
    textAlign: 'left'
  } as CSSProperties,

  legHeaderMainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as CSSProperties,

  legHeaderTitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as CSSProperties,

  legDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  } as CSSProperties,

  legSummaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  } as CSSProperties,

  airlineLogoContainer: {
    height: '30px',
    width: '30px',
    justifyContent: 'center',
    alignItems: 'center'
  } as CSSProperties,

  airlineLogo: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  } as CSSProperties,

  legSummaryContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(2)
    }
  } as CSSProperties,

  legSummaryContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as CSSProperties,

  legSummaryContentTitleText: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as CSSProperties,

  legSummaryContentSubtitleText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left'
  } as CSSProperties,

  divider: {
    margin: theme.spacing(3, 0, 3, 0),
    width: '100%'
  } as CSSProperties,

  legDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(10)
  } as CSSProperties,

  legStepDescriptionText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2)
  } as CSSProperties,

  legStepLabelText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2)
  } as CSSProperties,

  additionalDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(2, 0, 0, 4),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      flexWrap: 'wrap'
    }
  } as CSSProperties,

  additionalDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '40%',
      marginLeft: 0,
      justifyContent: 'space-evenly'
    }
  } as CSSProperties,

  additionalDetailLabelText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.grey[500]
  } as CSSProperties,

  additionalDetailDescriptionText: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'left'
  } as CSSProperties,

  accordionFooterMainContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(2)
  } as CSSProperties,

  accordionFooterMainContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.tertiary.main,
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between'
    }
  } as CSSProperties,

  accordionFooterMainContentText: {
    fontSize: theme.typography.body2.fontSize,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: 0
    }
  } as CSSProperties,

  accordionFooterMainContentLink: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left'
    }
  } as CSSProperties,

  goToRequestsArrowIcon: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  } as CSSProperties,

  flightCancelledNoticeText: {
    fontSize: theme.typography.body2.fontSize,
    textAlign: 'left',
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(1)
  } as CSSProperties,

  accordionHeaderMainContentDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingX: theme.spacing(2)
  } as CSSProperties
}

export default styles
