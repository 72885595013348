export const TRIP_FARE_STORAGE_KEY = 'trip_fare'
export const TRIP_PREFERENCES_STORAGE_KEY = 'trip_preferences'
export const SEARCHED_VALUES_STORAGE_KEY = 'searched_values'
export const FARE_OFFER_STORAGE_KEY = 'fare_offer'
export const SELECTED_OFFERS_STORAGE_KEY = 'selected_offers'
export const REDIRECT_TO_TRIP_SUMMARY_KEY = 'should_redirect_to_trip_summary'
export const EXISTING_FLIGHT_ITINERARY_KEY = 'existing_flight_itinerary'
export const ITINERARY_KEY = 'itinerary'
export const IS_BOOKING_FLIGHT_KEY = 'is_booking_flight'
export const IS_EXTERNAL_BOOKING_KEY = 'is_external_booking'
export const REDIRECT_TO_TRAVEL_PAGE_TO_ADD_EXISTING_FLIGHT_KEY = 'redirect_to_travel_page_to_add_existing_flight'
export const REDIRECT_TO_OFFERS_PAGE_TO_ADD_EXISTING_FLIGHT_KEY = 'redirect_to_offers_page_to_add_existing_flight'
