import React from 'react'

import { Box, Skeleton, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import EmptyState from '../../../../../../../../../../shared/components/EmptyState/EmptyState.component'
import Link from '../../../../../../../../../../shared/components/Link/Link.component'
import Table from '../../../../../../../../../../shared/components/Table/Table.component'

import { RootState } from '../../../../../../../../../../redux/store/store.types'
import date, {
  dateTimeFormats,
  formatDuration
} from '../../../../../../../../../../shared/functions/Date/date.functions'
import { joinStringWithDash } from '../../../../../../../../../../shared/functions/String/string.functions'
import linkStyles from '../../../../../../../../../../shared/styles/link.styles'
import styles from './TripDetailsCard.styles'
import { TripDetailsCardProps } from './TripDetailsCard.types'

const TripDetailsCard: React.FC<TripDetailsCardProps> = () => {
  const {
    loading: tripDetailsLoading,
    success: tripDetailsSuccess,
    error: tripDetailsError
  } = useSelector((state: RootState) => state.getTripDetailsForAdmin)

  if (tripDetailsLoading) {
    return (
      <Box sx={styles.skeletonContainer}>
        <Skeleton variant="text" width="40%" height={40} />
        <Skeleton variant="rectangular" height={200} sx={styles.skeletonItem} />
        <Skeleton variant="text" width="40%" height={40} />
        <Skeleton variant="rectangular" height={200} sx={styles.skeletonItem} />
      </Box>
    )
  } else if (tripDetailsError) {
    return <EmptyState title="Error loading trip details" errorId={tripDetailsError.correlationId} />
  } else if (!tripDetailsSuccess) {
    return <EmptyState title="No trip details found" />
  } else {
    const tripDetails = tripDetailsSuccess?.data.tripDetails

    const renderFlightTable = () => {
      if (!tripDetails.flightBookingDetails?.flights?.length) return null

      return (
        <Table
          title="Flight Details"
          columns={[
            { key: 'airline', label: 'Airline' },
            { key: 'flightNumber', label: 'Flight Number' },
            { key: 'dates', label: 'Dates' },
            { key: 'route', label: 'Route' },
            { key: 'duration', label: 'Duration' }
          ]}
          data={tripDetails.flightBookingDetails.flights.map((flight) => ({
            airline: (
              <Box sx={styles.airlineInfo}>
                <img src={flight.airline.logo} alt={flight.airline.name} style={styles.airlineLogo} />
                <Typography>{flight.airline.code}</Typography>
              </Box>
            ),
            flightNumber: flight.flightNumber,
            dates: joinStringWithDash([
              date(flight.departureTime).format(dateTimeFormats.date.medium),
              date(flight.arrivalTime).format(dateTimeFormats.date.medium)
            ]),
            route: `${flight.origin.airport.iataCode} → ${flight.destination.airport.iataCode}`,
            duration: formatDuration(flight.duration)
          }))}
        />
      )
    }

    return (
      <Box sx={styles.container}>
        <Box sx={styles.section}>{renderFlightTable()}</Box>

        <Box sx={styles.section}>
          <Table
            title="Booking Details"
            columns={[
              { key: 'key', label: 'Key' },
              { key: 'value', label: 'Data' }
            ]}
            data={
              tripDetails.flightBookingDetails
                ? [
                    {
                      key: 'Confirmation Code',
                      value: tripDetails.flightBookingDetails.confirmationCode || 'N/A'
                    },
                    {
                      key:
                        tripDetails.flightItineraryDetails.itinerarySource === 'external'
                          ? tripDetails.flightBookingDetails.externalDetails?.ticketNumber
                            ? 'Ticket Number'
                            : 'Itinerary Reference Number'
                          : 'Duffel Order ID',
                      value: (
                        <Link href={tripDetails.flightBookingDetails.internalDetails?.duffelOrderUrl} sx={linkStyles}>
                          {tripDetails.flightItineraryDetails.itinerarySource === 'external'
                            ? tripDetails.flightBookingDetails.externalDetails?.ticketNumber
                              ? tripDetails.flightBookingDetails.externalDetails?.ticketNumber
                              : tripDetails.flightBookingDetails.externalDetails?.itineraryReferenceNumber
                            : tripDetails.flightBookingDetails.internalDetails?.duffelOrderId}
                        </Link>
                      )
                    }
                  ]
                : []
            }
          />
        </Box>
      </Box>
    )
  }
}

export default TripDetailsCard
