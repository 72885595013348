import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Menu } from '@mui/material'

import images from '../../../../../assets/images'
import styles from './FilterMenu.styles'
import { FilterMenuProps } from './FilterMenu.types'

const { FilterIcon } = images

const FilterMenu: React.FC<FilterMenuProps> = ({ children, icon, removeContentPadding }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsOpen(false)
  }

  return (
    <Box>
      <Box>
        <IconButton onClick={handleMenuOpen} sx={styles.icon}>
          {isOpen ? <CloseIcon sx={styles.closeIcon} /> : icon ?? <FilterIcon />}
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isOpen}
          onClose={handleClose}
          slotProps={{
            paper: styles.paper
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          sx={styles.menu(removeContentPadding)}
        >
          {children}
        </Menu>
      </Box>
    </Box>
  )
}

export default FilterMenu
