import React, { useState } from 'react'

import { ChatOutlined } from '@mui/icons-material'
import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material'

import { useMessaging } from '../../../../components/Chat/context/Messaging.context'
import date, { dateTimeFormats } from '../../../../functions/Date/date.functions'
import { ConversationParticipant } from '../../../../functions/Firebase/Messaging/messaging.types'
import logger from '../../../../functions/Logger/logger.functions'
import styles from './MessagingNotification.styles'
import { MessagingNotificationProps } from './MessagingNotification.types'

const MessagingNotification: React.FC<MessagingNotificationProps> = (props) => {
  const { containerStyle } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const { userConversations, totalUnreadCount, currentUserParticipant, openChat } = useMessaging()

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    logger.logEvent('messaging_notifications_opened')
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleOpenChat = (recipientParticipant: ConversationParticipant, orderId: string) => {
    if (!currentUserParticipant) return

    handleCloseMenu()
    openChat(currentUserParticipant, recipientParticipant, orderId)
    logger.logEvent('conversation_opened_from_notifications')
  }

  const formatTime = (timestamp: number) => {
    const now = new Date()
    const messageTime = new Date(timestamp)

    // If message is from today, show time
    if (
      messageTime.getDate() === now.getDate() &&
      messageTime.getMonth() === now.getMonth() &&
      messageTime.getFullYear() === now.getFullYear()
    ) {
      return date(timestamp).format(dateTimeFormats.time.short)
    }

    // If message is from this week, show day name
    const dayDiff = Math.floor((now.getTime() - messageTime.getTime()) / (1000 * 60 * 60 * 24))

    if (dayDiff < 7) {
      return date(timestamp).format(dateTimeFormats.date.day.medium)
    }

    // Otherwise show date
    return date(timestamp).format(dateTimeFormats.date.medium)
  }

  // Function to find a participant other than the current user
  const getOtherParticipant = (participants: ConversationParticipant[]) => {
    if (!currentUserParticipant || !Array.isArray(participants) || participants.length === 0) return null

    return participants.find((p) => p.hurrierUserId !== currentUserParticipant.hurrierUserId) || null
  }

  return (
    <Box sx={containerStyle}>
      <Tooltip title="Messages">
        <Box sx={styles.notificationIcon} onClick={handleOpenMenu}>
          <ChatOutlined />
          {/* {totalUnreadCount > 0 && <Box sx={styles.badge}>{totalUnreadCount > 99 ? '99+' : totalUnreadCount}</Box>} */}
          {totalUnreadCount > 0 && <Box sx={styles.badge}></Box>}
        </Box>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleCloseMenu}
        sx={styles.menu}
        slotProps={{
          paper: {
            sx: styles.menuPaper
          }
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Typography sx={styles.menuHeader}>Messages</Typography>

        {userConversations.length === 0 ? (
          <Typography sx={styles.emptyMessage}>No conversations yet</Typography>
        ) : (
          userConversations
            .sort((a, b) => b.lastMessageTimestamp.toMillis() - a.lastMessageTimestamp.toMillis())
            .map((conversation) => {
              const otherParticipant = getOtherParticipant(conversation.participants)
              if (!otherParticipant || !currentUserParticipant) return null

              const unreadCount = conversation.unreadCount[currentUserParticipant.hurrierUserId] || 0
              const hasUnread = unreadCount > 0

              return (
                <MenuItem
                  key={conversation.id}
                  onClick={() => handleOpenChat(otherParticipant, conversation.orderId)}
                  sx={[styles.menuItem, hasUnread && styles.unreadMenuItem]}
                >
                  <Box sx={styles.conversationInfo}>
                    <Box sx={styles.conversationTitle}>
                      <Typography sx={styles.participantName}>
                        {otherParticipant.firstName} {otherParticipant.lastName}
                      </Typography>

                      <Typography sx={styles.messageTime}>
                        {formatTime(conversation.lastMessageTimestamp.toMillis())}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={styles.messagePreview}>
                        {conversation.lastMessage || 'No messages yet'}
                      </Typography>

                      {/* {hasUnread && <Box sx={styles.unreadBadge}>{unreadCount}</Box>} */}
                      {hasUnread && <Box sx={styles.unreadBadge}></Box>}
                    </Box>
                    <Typography sx={styles.orderInfo}>Order #{conversation.orderId}</Typography>
                  </Box>
                </MenuItem>
              )
            })
        )}
      </Menu>
    </Box>
  )
}

export default MessagingNotification
