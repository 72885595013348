import React, { useCallback, useEffect, useState } from 'react'

import env from '@/networkRequests/apiClient/env.config'
import { formatMoney } from '@/shared/functions/String/string.functions'
import { Check } from '@mui/icons-material'
import { Box, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography } from '@mui/material'

import Modal from '../../../../../../../../shared/components/Modal/Modal.component'
import FlightsDropdown from '../../../FlightsDropdown/FlightsDropdown.component'

import { useAppSelector } from '../../../../../../../../shared/hooks/redux.hooks'
import styles from './BaggageDetailsModal.styles'
import { BaggageDetailsModalProps, BaggageSelections } from './BaggageDetailsModal.types'

// TODO: Update next flight button accordingly
const BaggageDetailsModal: React.FC<BaggageDetailsModalProps> = (props) => {
  const { open, onClose, fareOffer, onConfirmSelections } = props
  const { success: baggageDetailsResponse } = useAppSelector((state) => state.getBaggageDetails)
  const passengerIds = fareOffer.passengers.map((passenger) => passenger.id)

  const [baggageSelections, setBaggageSelections] = useState<BaggageSelections>({})
  const [totalCost, setTotalCost] = useState(0)
  const [selectedSegmentId, setSelectedSegmentId] = useState<string>(fareOffer.slices[0].segments[0].id)
  const [selectedPassenger, setSelectedPassenger] = useState(0)
  const [isLastFlight, setIsLastFlight] = useState(false)

  useEffect(() => {
    initializeBaggageSelections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initializeBaggageSelections = () => {
    const newSelections: BaggageSelections = {}
    fareOffer.slices.forEach((slice) => {
      slice.segments.forEach((segment) => {
        newSelections[segment.id] = {}
        passengerIds.forEach((id) => {
          newSelections[segment.id][id] = []
        })
      })
    })
    setBaggageSelections(newSelections)
  }

  const updateIsLastFlight = useCallback(
    (segmentId: string) => {
      const flightOptions = fareOffer.slices.flatMap((slice) => slice.segments)
      setIsLastFlight(flightOptions[flightOptions.length - 1].id === segmentId)
    },
    [fareOffer]
  )

  const handleFlightChange = useCallback(
    (newSegmentId: string) => {
      setSelectedSegmentId(newSegmentId)
      updateIsLastFlight(newSegmentId)
    },
    [updateIsLastFlight]
  )

  const handleBaggageChange = (passengerId: string, bagIndex: number, value: string) => {
    setBaggageSelections((prevSelections) => {
      const newSelections = { ...prevSelections }
      const baggageOption = baggageDetailsResponse?.data.find(
        (option) =>
          option.passengerId === passengerId &&
          option.segmentId === selectedSegmentId &&
          option.baggageDetails.baggageType === 'checked'
      )

      if (baggageOption) {
        if (value === 'None') {
          newSelections[selectedSegmentId][passengerId] = newSelections[selectedSegmentId][passengerId].filter(
            (_, index) => index !== bagIndex
          )
        } else {
          newSelections[selectedSegmentId][passengerId][bagIndex] = {
            type: value,
            cost: baggageOption.baggageCost,
            currency: baggageOption.currency,
            segmentId: selectedSegmentId,
            baggageId: baggageOption.id
          }
        }
      }

      return newSelections
    })
  }

  const calculateTotalCost = useCallback(() => {
    const cost = Object.values(baggageSelections).reduce(
      (totalSegments, segment) =>
        totalSegments +
        Object.values(segment).reduce(
          (totalPassengers, passengerSelections) =>
            totalPassengers + passengerSelections.reduce((sum, selection) => sum + selection.cost, 0),
          0
        ),
      0
    )
    setTotalCost(cost)
  }, [baggageSelections])

  useEffect(() => {
    calculateTotalCost()
  }, [baggageSelections, calculateTotalCost])

  const getBagsSelectedText = () => {
    const bagsSelected = Object.values(baggageSelections).reduce(
      (totalSegments, segment) =>
        totalSegments +
        Object.values(segment).reduce(
          (totalPassengers, passengerSelections) => totalPassengers + passengerSelections.length,
          0
        ),
      0
    )

    return bagsSelected === 0 ? 'None Selected' : `${bagsSelected} Selected`
  }

  const handleNextFlight = useCallback(() => {
    const flightOptions = fareOffer.slices.flatMap((slice) => slice.segments)
    const currentIndex = flightOptions.findIndex((segment) => segment.id === selectedSegmentId)

    if (currentIndex < flightOptions.length - 1) {
      const nextSegmentId = flightOptions[currentIndex + 1].id

      setSelectedSegmentId(nextSegmentId)
      updateIsLastFlight(nextSegmentId)
    }
  }, [fareOffer, selectedSegmentId, updateIsLastFlight])

  const handlePreviousFlight = useCallback(() => {
    const flightOptions = fareOffer.slices.flatMap((slice) => slice.segments)
    const currentIndex = flightOptions.findIndex((segment) => segment.id === selectedSegmentId)

    if (currentIndex > 0) {
      const previousSegmentId = flightOptions[currentIndex - 1].id

      setSelectedSegmentId(previousSegmentId)
      updateIsLastFlight(previousSegmentId)
    }
  }, [fareOffer, selectedSegmentId, updateIsLastFlight])

  const getBaggageInfo = useCallback(
    (segmentId: string) => {
      const segment = fareOffer.slices.flatMap((slice) => slice.segments).find((seg) => seg.id === segmentId)
      if (!segment) return { carryOn: 0, checked: 0 }
      const baggages = segment.passengers[0].baggages
      const carryOn = baggages.find((bag) => bag.type === 'carry_on')?.quantity || 0
      const checked = baggages.find((bag) => bag.type === 'checked')?.quantity || 0

      return { carryOn, checked }
    },
    [fareOffer]
  )

  const handlePassengerChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedPassenger(newValue)
  }

  const renderBaggageOptions = (passengerId: string) => {
    const passengerBaggageOptions =
      baggageDetailsResponse?.data.filter(
        (option) =>
          option.passengerId === passengerId &&
          option.segmentId === selectedSegmentId &&
          option.baggageDetails.baggageType === 'checked'
      ) || []

    const maxQuantity = passengerBaggageOptions[0]?.maximumQuantity || 0

    if (maxQuantity === 0) {
      return <Typography sx={styles.noBaggageAvailable}>No checked bags available for this flight</Typography>
    }

    return Array.from({ length: maxQuantity }, (_, bagIndex) => {
      const baggageOption = passengerBaggageOptions[0]
      if (!baggageOption) return null

      const currentSelection = baggageSelections[selectedSegmentId]?.[passengerId]?.[bagIndex]

      return (
        <Box key={`${passengerId}-${bagIndex}`}>
          <Typography>{`${bagIndex + 1}${bagIndex === 0 ? 'st' : 'nd'} Checked bag`}</Typography>

          <RadioGroup
            value={currentSelection ? currentSelection.type : 'None'}
            onChange={(e) => handleBaggageChange(passengerId, bagIndex, e.target.value)}
          >
            <FormControlLabel value="None" control={<Radio />} label="None" />

            <FormControlLabel
              value={`Up to ${baggageOption.baggageDetails.maximumWeightKg}kg`}
              control={<Radio />}
              label={
                <>
                  Up to {baggageOption.baggageDetails.maximumWeightKg}kg
                  <Typography component="span" sx={styles.price}>
                    +{formatMoney(baggageOption.baggageCost, baggageOption.currency)}
                  </Typography>
                </>
              }
            />
          </RadioGroup>
        </Box>
      )
    })
  }

  const modalBody = (
    <Box sx={styles.modalBody}>
      <FlightsDropdown fareOffer={fareOffer} value={selectedSegmentId} onChange={handleFlightChange} />

      <Box sx={styles.includedBaggageInfo}>
        {(() => {
          const { carryOn, checked } = getBaggageInfo(selectedSegmentId)

          return (
            <>
              {carryOn > 0 && (
                <Box sx={styles.carryOnContainer}>
                  <Check sx={styles.checkIcon} />
                  <Typography>{carryOn} Carry-on included</Typography>
                </Box>
              )}
              {checked > 0 && (
                <Box sx={styles.carryOnContainer}>
                  <Check sx={styles.checkIcon} />
                  <Typography>{checked} Checked Bag included</Typography>
                </Box>
              )}
            </>
          )
        })()}
      </Box>

      <Tabs value={selectedPassenger} onChange={handlePassengerChange} aria-label="passenger tabs" sx={styles.tabs}>
        {fareOffer.passengers.map((passenger, index) => (
          <Tab key={passenger.id} label={`Passenger ${index + 1}`} />
        ))}
      </Tabs>

      {renderBaggageOptions(fareOffer.passengers[selectedPassenger].id)}
    </Box>
  )

  const handleConfirm = useCallback(() => {
    onConfirmSelections(baggageSelections)
    onClose()
  }, [baggageSelections, onConfirmSelections, onClose])

  const getModalSecondaryButton = () => {
    const flightOptions = fareOffer.slices.flatMap((slice) => slice.segments)
    const multipleFlights = flightOptions.length > 1

    if (multipleFlights) {
      if (isLastFlight) {
        return {
          label: 'Previous Flight',
          onClick: handlePreviousFlight
        }
      } else {
        return {
          label: 'Cancel',
          onClick: onClose
        }
      }
    } else {
      return {
        label: 'Cancel',
        onClick: onClose
      }
    }
  }

  const getModalTertiaryButton = () => {
    const flightOptions = fareOffer.slices.flatMap((slice) => slice.segments)
    const multipleFlights = flightOptions.length > 1

    if (multipleFlights) {
      if (isLastFlight) {
        return {
          label: 'Cancel',
          onClick: onClose
        }
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Baggage Selection"
      body={modalBody}
      maxWidth="md"
      headerRightSection={
        <Box sx={styles.headerRightSection}>
          <Typography sx={styles.totalCost} variant="h5">
            Total: {formatMoney(totalCost, baggageDetailsResponse?.data[0]?.currency ?? env.STRIPE_MAIN_CURRENCY)}
          </Typography>

          <Typography sx={styles.bagsSelected} variant="body2">
            Bags: {getBagsSelectedText()}
          </Typography>
        </Box>
      }
      primaryButton={{
        label: isLastFlight ? 'Done' : 'Next Flight',
        onClick: isLastFlight ? handleConfirm : handleNextFlight
      }}
      secondaryButton={getModalSecondaryButton()}
      tertiaryButton={getModalTertiaryButton()}
    />
  )
}

export default BaggageDetailsModal
