import useMediaQuery from '@mui/material/useMediaQuery'

import theme from '../styles/themes/default.theme'

const useResponsiveness = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return [isDesktop, isLargeDesktop]
}

export default useResponsiveness
