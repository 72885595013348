import React, { useCallback, useEffect } from 'react'

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Box, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { FormikProps } from 'formik'
import { DateTime } from 'luxon'

import Button from '@/shared/components/Button/Button.component'

import { FlightDetailsFormValues } from '../ExternalBookingsModal/ExternalBookingsModal.types'
import styles from './FlightSegments.styles'
import { FlightSegmentsProps } from './FlightSegments.types'

const FlightSegments: React.FC<FlightSegmentsProps> = (props) => {
  const { formik, airports, airlines, tripType, airlineInBookingRequest } = props
  const { values, setFieldValue, errors, touched } = formik as FormikProps<FlightDetailsFormValues>

  const addSlice = useCallback(() => {
    const newSlices = [...values.slices]

    newSlices.push({
      segments: [
        {
          flightNumber: '',
          airlineId: airlineInBookingRequest?.id || '',
          departureTime: '',
          arrivalTime: '',
          originAirportIataCode: '',
          destinationAirportIataCode: ''
        }
      ]
    })

    setFieldValue('slices', newSlices)
  }, [setFieldValue, values.slices, airlineInBookingRequest?.id])

  const removeSlice = useCallback(
    (sliceIndex: number) => {
      const newSlices = [...values.slices]

      newSlices.splice(sliceIndex, 1)
      setFieldValue('slices', newSlices)
    },
    [setFieldValue, values.slices]
  )

  const addSegment = useCallback(
    (sliceIndex: number) => {
      const newSlices = [...values.slices]

      newSlices[sliceIndex].segments.push({
        flightNumber: '',
        airlineId: airlineInBookingRequest?.id || '',
        departureTime: '',
        arrivalTime: '',
        originAirportIataCode: '',
        destinationAirportIataCode: ''
      })

      setFieldValue('slices', newSlices)
    },
    [setFieldValue, values.slices, airlineInBookingRequest?.id]
  )

  const removeSegment = useCallback(
    (sliceIndex: number, segmentIndex: number) => {
      const newSlices = [...values.slices]

      newSlices[sliceIndex].segments.splice(segmentIndex, 1)
      setFieldValue('slices', newSlices)
    },
    [setFieldValue, values.slices]
  )

  const canAddSlice = useCallback(() => {
    const currentSlices = values.slices.length

    switch (tripType) {
      case 'oneWay':
        return currentSlices < 1

      case 'roundTrip':
        return currentSlices < 2

      case 'multiCity':
        return true

      default:
        return false
    }
  }, [values.slices, tripType])

  const getNestedError = useCallback(
    (path: string): string | undefined => {
      const pathParts = path.split('.')
      let current: Record<string, unknown> = errors as Record<string, unknown>
      let currentTouched: Record<string, unknown> = touched as Record<string, unknown>

      for (const part of pathParts) {
        if (!current || typeof current !== 'object') return undefined
        if (!currentTouched || typeof currentTouched !== 'object') return undefined

        current = current[part] as Record<string, unknown>
        currentTouched = currentTouched[part] as Record<string, unknown>
      }

      return currentTouched && current && typeof current === 'string' ? current : undefined
    },
    [errors, touched]
  )

  useEffect(
    function updateSlices() {
      switch (tripType) {
        case 'oneWay':
          if (values.slices.length > 1) {
            const newSlices = [values.slices[0]]

            setFieldValue('slices', newSlices)
          }
          break

        case 'roundTrip':
          if (values.slices.length > 2) {
            const newSlices = values.slices.slice(0, 2)

            setFieldValue('slices', newSlices)
          } else if (values.slices.length === 1) {
            addSlice()
          }
          break
      }
    },
    [tripType, values.slices, setFieldValue, addSlice]
  )

  return (
    <Box sx={styles.container}>
      {values.slices.map((slice, sliceIndex) => (
        <Box key={sliceIndex} sx={styles.slice}>
          <Box sx={styles.sliceHeader}>
            <Typography variant="h6">
              {tripType === 'roundTrip'
                ? `${sliceIndex === 0 ? 'Outbound' : 'Return'} Flight`
                : tripType === 'multiCity'
                  ? `Flight ${sliceIndex + 1}`
                  : null}
            </Typography>

            {(tripType === 'multiCity' || (values.slices.length > 1 && tripType !== 'roundTrip')) && (
              <IconButton onClick={() => removeSlice(sliceIndex)} color="error" size="small">
                <DeleteIcon />
              </IconButton>
            )}
          </Box>

          {slice.segments.map((segment, segmentIndex) => (
            <Box key={segmentIndex} sx={styles.segment}>
              <Box sx={styles.segmentHeader}>
                <Typography variant="subtitle1">
                  {slice.segments.length > 1 ? `Segment ${segmentIndex + 1}` : 'Flight Details'}
                </Typography>

                {slice.segments.length > 1 && (
                  <IconButton onClick={() => removeSegment(sliceIndex, segmentIndex)} color="error" size="small">
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>

              <Box sx={styles.segmentFields}>
                <TextField
                  select
                  label="Airline"
                  value={segment.airlineId || ''}
                  onChange={(event) => {
                    const selectedAirlineCode = event.target.value
                    const selectedAirline = airlines.find((airline) => airline.value === selectedAirlineCode)

                    if (selectedAirline) {
                      setFieldValue(`slices.${sliceIndex}.segments.${segmentIndex}.airlineId`, selectedAirlineCode)
                    }
                  }}
                  disabled={!!airlineInBookingRequest}
                  error={!!getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.airlineId`)}
                  helperText={getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.airlineId`) as string}
                  fullWidth
                >
                  {airlines.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Flight Number"
                  value={segment.flightNumber}
                  onChange={(e) =>
                    setFieldValue(`slices.${sliceIndex}.segments.${segmentIndex}.flightNumber`, e.target.value)
                  }
                  error={!!getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.flightNumber`)}
                  helperText={getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.flightNumber`) as string}
                  fullWidth
                />

                <TextField
                  select
                  label="Departure Airport"
                  value={segment.originAirportIataCode}
                  onChange={(event) =>
                    setFieldValue(
                      `slices.${sliceIndex}.segments.${segmentIndex}.originAirportIataCode`,
                      event.target.value
                    )
                  }
                  error={!!getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.originAirportIataCode`)}
                  helperText={
                    getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.originAirportIataCode`) as string
                  }
                  fullWidth
                >
                  {airports.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  label="Arrival Airport"
                  value={segment.destinationAirportIataCode}
                  onChange={(event) =>
                    setFieldValue(
                      `slices.${sliceIndex}.segments.${segmentIndex}.destinationAirportIataCode`,
                      event.target.value
                    )
                  }
                  error={!!getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.destinationAirportIataCode`)}
                  helperText={
                    getNestedError(`slices.${sliceIndex}.segments.${segmentIndex}.destinationAirportIataCode`) as string
                  }
                  fullWidth
                >
                  {airports.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <DateTimePicker
                  label="Departure Time"
                  value={segment.departureTime ? DateTime.fromISO(segment.departureTime) : null}
                  onChange={(value) =>
                    setFieldValue(`slices.${sliceIndex}.segments.${segmentIndex}.departureTime`, value?.toISO() || null)
                  }
                  sx={styles.datePicker}
                  disablePast
                />

                <DateTimePicker
                  label="Arrival Time"
                  value={segment.arrivalTime ? DateTime.fromISO(segment.arrivalTime) : null}
                  onChange={(value) =>
                    setFieldValue(`slices.${sliceIndex}.segments.${segmentIndex}.arrivalTime`, value?.toISO() || null)
                  }
                  sx={styles.datePicker}
                  disablePast
                  minDateTime={segment.departureTime ? DateTime.fromISO(segment.departureTime) : undefined}
                />
              </Box>
            </Box>
          ))}

          <Button icon={<AddIcon />} onClick={() => addSegment(sliceIndex)} buttonType="tertiary" text="Add Stop" />
        </Box>
      ))}

      {canAddSlice() && (
        <Button
          icon={<AddIcon />}
          onClick={addSlice}
          buttonType="tertiary"
          text={
            tripType === 'roundTrip'
              ? 'Add Return Flight'
              : tripType === 'multiCity'
                ? 'Add Another Flight'
                : 'Add Flight'
          }
        />
      )}
    </Box>
  )
}

export default FlightSegments
