import React from 'react'

import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  container: (isContained: boolean, formPadding?: number) =>
    ({
      backgroundColor: isContained ? 'background.paper' : 'transparent',
      width: '100%',
      borderRadius: isContained ? 2 : 0,
      padding: isContained ? 3 : 0,
      boxShadow: isContained ? 1 : 'none',
      [theme.breakpoints.down('md')]: {
        padding: formPadding !== undefined ? formPadding : isContained ? 1 : 0
      }
    }) as SxProps,

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 3
  },

  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 1
  } as React.CSSProperties,

  containedTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: 0
  } as React.CSSProperties,

  subtitle: {
    fontSize: '1rem',
    color: 'text.secondary',
    textAlign: 'left',
    marginBottom: 2
  } as React.CSSProperties,

  containedTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  } as SxProps,

  sectionTitle: (alignment?: 'left' | 'center' | 'right') =>
    ({
      fontSize: '20px',
      fontWeight: '500',
      color: 'text.primary',
      marginBottom: -2,

      textAlign: alignment || 'left',

      width: '100%'
    }) as React.CSSProperties,

  textField: (isEditable: boolean, isDisabled?: boolean) => {
    const mainBorderColor = isDisabled ? theme.palette.grey[100] : isEditable ? theme.palette.grey[300] : 'transparent'

    const focusedBorderColor = isDisabled
      ? theme.palette.grey[100]
      : isEditable
        ? theme.palette.primary.main
        : 'transparent'

    return {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: mainBorderColor
        },

        '&:hover fieldset': {
          borderColor: mainBorderColor
        },

        '&.Mui-focused fieldset': {
          borderColor: focusedBorderColor
        }
      },
      '& .MuiInputBase-input': {
        color: isEditable ? 'text.primary' : 'text.secondary'
      },

      '& .MuiInputLabel-root': {
        color: isEditable ? 'text.primary' : 'text.secondary',
        [theme.breakpoints.down('md')]: {
          zIndex: 0
        }
      }
    } as SxProps
  },

  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  checkboxText: {
    fontSize: '1rem',
    color: 'text.primary'
  } as React.CSSProperties,

  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
    marginTop: 3
  },

  loading: {
    marginRight: theme.spacing(2)
  } as React.CSSProperties,

  radioGroup: {
    flexDirection: 'row',
    gap: 2
  } as SxProps
}

export default styles
