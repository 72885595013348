import { SxProps } from '@mui/material'

import theme from '../../../../styles/themes/default.theme'

const styles = {
  completedIconContainer: {
    backgroundColor: theme.palette.blue[800],
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '12px',
    width: '24px',
    height: '24px'
  },

  activeIconContainer: {
    backgroundColor: theme.palette.blue[800],
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '12px',
    width: '24px',
    height: '24px',
    border: `2px solid ${theme.palette.blue[800]}`,
    position: 'relative',
    zIndex: 2,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-6px',
      left: '-6px',
      right: '-6px',
      bottom: '-6px',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.blue[800]}`,
      zIndex: 1
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '1px'
    }
  } as SxProps,

  inactiveIconContainer: {
    backgroundColor: theme.palette.blue[100],
    color: theme.palette.blue[800],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '12px',
    width: '24px',
    height: '24px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1px'
    }
  } as SxProps
}

export default styles
