import React from 'react'

import { Navigate } from 'react-router-dom'

import Snackbar from '../../shared/components/Snackbar/Snackbar.functions'
import { isAdminLoggedIn, isAuthenticated, setAdminLoginStatus } from '../../shared/functions/Auth/auth.functions'
import { UserRole } from '../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole, removeUserRole } from '../../shared/functions/UserRole/userRoleManagment.functions'
import { Route as HourrierRoute } from '../router.types'
import routes from '../routes.dictionary'

interface RedirectHandlerProps {
  route: HourrierRoute
}

const RedirectHandler: React.FC<RedirectHandlerProps> = ({ route }) => {
  const isUserAuthenticated = isAuthenticated()
  const isAdminDashboardRoute = route.path === routes.adminDashboard.path

  if (isAdminLoggedIn() && !isAdminDashboardRoute) {
    return <Navigate to={`/${routes.adminDashboard.path}`} />
  } else if (!isAdminLoggedIn() && isAdminDashboardRoute) {
    const userRole = getUserRole()
    const pathToNavigate = userRole === UserRole.shopper ? routes.shop.path : ''

    return <Navigate to={`/${pathToNavigate}`} />
  } else if (route.isAuthenticationRequired && !isUserAuthenticated) {
    Snackbar.show({
      message: 'Please login to continue.',
      severity: 'error'
    })

    if (isAdminLoggedIn()) {
      setAdminLoginStatus(false)
    }

    removeUserRole()

    return <Navigate to={`/${routes.login.path}`} />
  } else {
    return route.component ? React.createElement(route.component) : null
  }
}

export default RedirectHandler
