import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'

import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { formatMoney, joinStringsWithBullet } from '../../../../shared/functions/String/string.functions'
import styles from './ItemDetails.styles'
import { ItemDetailsProps } from './ItemDetails.types'

const ItemDetails: React.FC<ItemDetailsProps> = (props) => {
  const { item } = props
  const hasFullWeightDetails = item.weight && item.weightUnit

  return (
    <Box sx={styles.itemContainer}>
      <Box sx={styles.leftContainer}>
        <Box sx={styles.itemImageContainer}>
          <img src={item.images[0]} alt={item.title} style={styles.itemImage} />
        </Box>

        <Box sx={styles.itemDetails}>
          <Typography variant="h6" sx={styles.itemName}>
            {item.title}
          </Typography>

          <Typography variant="body2" sx={styles.itemInfo}>
            {joinStringsWithBullet([
              `Qty: ${item.quantity}`,
              hasFullWeightDetails ? `Weighing ${item.weight} ${item.weightUnit}` : 'Weighing: N/A'
            ])}
          </Typography>

          <Typography variant="body2" sx={styles.itemPrice}>
            {joinStringsWithBullet([
              `${formatMoney(parseFloat(item.price), item.currency)}`,
              `Amazon price as of ${date(item.priceAt).format(
                `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
              )} EST`
            ])}
          </Typography>
        </Box>
      </Box>

      <Button
        text="View on Amazon"
        buttonType="primary"
        size="small"
        variant="text"
        href={item.internal_url}
        target="_blank"
        sx={styles.viewOnAmazonButton}
      >
        View on Amazon
      </Button>
    </Box>
  )
}

export default ItemDetails
