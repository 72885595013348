import React, { useCallback, useEffect, useState } from 'react'

import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { Box, Typography } from '@mui/material'

import Button from '../../../../../shared/components/Button/Button.component'
import PaginatedPage from '../../../../../shared/components/PaginatedPage/PaginatedPage.component'
import OfferItem from '../../../../Offers/components/OfferItem/OfferItem.component'

import env from '../../../../../networkRequests/apiClient/env.config'
import { getAllFlightItineraries } from '../../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import {
  FlightItinerary,
  GetAllFlightItinerariesRequest
} from '../../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { getPublicOffers } from '../../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.slice'
import {
  GetPublicOffersRequestPayload,
  OfferRequest
} from '../../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import {
  PaginatedRenderProps,
  PaginatedResponse
} from '../../../../../shared/components/PaginatedPage/PaginatedPage.types'
import { isAuthenticated } from '../../../../../shared/functions/Auth/auth.functions'
import { useAppDispatch } from '../../../../../shared/hooks/redux.hooks'
import { RequiredCountries } from '../../../../Offers/components/OfferItem/OfferItem.types'
import styles from './OffersMultiSelect.styles'

interface OffersMultiSelectProps {
  onGotoTripSummary: (selectedOffers: OfferRequest[]) => void
  onFetchedOffers?: (offers: OfferRequest[]) => void
  requiredCountries: RequiredCountries
  initialSelectedOffers: OfferRequest[]
  maxDate?: string
}

const OffersMultiSelect: React.FC<OffersMultiSelectProps> = (props) => {
  const { onGotoTripSummary, requiredCountries, initialSelectedOffers, onFetchedOffers, maxDate } = props

  const [selectedOffers, setSelectedOffers] = useState<OfferRequest[]>(initialSelectedOffers)
  const [flightItineraries, setFlightItineraries] = useState<FlightItinerary[]>([])

  const pageSize = env.DEFAULT_PAGE_SIZE

  const dispatch = useAppDispatch()
  const [isDesktop] = useResponsiveness()

  const fetchOffers = useCallback(
    async (page: number): Promise<PaginatedResponse<OfferRequest>> => {
      return new Promise((resolve) => {
        const getPublicOffersPayload: GetPublicOffersRequestPayload = {
          request: {
            page,
            size: pageSize,
            searchQuery: '',
            countries: [requiredCountries.itemLocation.iataCode, requiredCountries.deliveryLocation.iataCode],
            deliveryCity: requiredCountries.deliveryLocation.city,
            maxDate
          },
          onSuccess: (response) => {
            onFetchedOffers?.(response.data.results)
            resolve({
              currentPage: response.data.currentPage,
              totalPages: response.data.totalPages,
              results: response.data.results
            })
          }
        }

        dispatch(getPublicOffers(getPublicOffersPayload))
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, requiredCountries, maxDate, pageSize]
  )

  useEffect(() => {
    const fetchFlightItineraries = async () => {
      if (!isAuthenticated()) return
      const getAllFlightItinerariesPayload: GetAllFlightItinerariesRequest = {}

      const response = await dispatch(
        getAllFlightItineraries({
          request: getAllFlightItinerariesPayload
        })
      ).unwrap()

      setFlightItineraries(response.data.results)
    }

    fetchFlightItineraries()
    fetchOffers(1)
  }, [dispatch, fetchOffers])

  const handleSelectOffer = (offer: OfferRequest) => {
    setSelectedOffers((prev) => [...prev, offer])
  }

  const handleDeselectOffer = (offer: OfferRequest) => {
    setSelectedOffers((prev) => prev.filter((o) => o.offerRequestId !== offer.offerRequestId))
  }

  const handleGotoTripSummary = () => {
    onGotoTripSummary(selectedOffers)
  }

  const OfferItemsView = (props: PaginatedRenderProps) => {
    const offer = props.item as OfferRequest
    const isSelected = selectedOffers?.some((o) => o?.offerRequestId === offer?.offerRequestId)

    return (
      <Box sx={styles.offerItemContainer}>
        <OfferItem
          offer={offer}
          onSelect={isSelected ? handleDeselectOffer : handleSelectOffer}
          isSelected={isSelected}
          flightItineraries={flightItineraries}
        />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        fetchCall={fetchOffers}
        renderListData={OfferItemsView}
        layout="masonry"
        title={{
          text: 'Select additional offers below.',
          id: 'offers'
        }}
        subtitle="You can accept additional offers to the same destination."
        isFetchingData={false}
        emptyState={{
          title: 'No offers found',
          subtitle: 'Try changing your search criteria.'
        }}
        removeExtraPadding
        rightContainer={
          <Box sx={styles.rightContainer}>
            <Typography sx={styles.rightContainerText} variant="body2">
              Total Selected: {selectedOffers.length}
            </Typography>

            <Button
              sx={styles.rightContainerButton}
              buttonType="primary"
              text={isDesktop ? 'Continue to Trip Summary' : 'Continue'}
              onClick={handleGotoTripSummary}
            />
          </Box>
        }
      />
    </Box>
  )
}

export default OffersMultiSelect
