import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  } as SxProps,

  contentContainer: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'center'
  } as SxProps,

  tripOverviewContainer: {} as SxProps,

  stepHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingX: 0
    }
  },

  title: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold
  } as CSSProperties,

  subtitle: {
    textAlign: 'left',
    color: theme.palette.text.secondary
  }
}

export default styles
