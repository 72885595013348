import React, { useCallback, useEffect, useState } from 'react'

import { TripType } from '@/shared/functions/Flights/flights.types'
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { FlightItinerary } from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import status from '../../../../shared/functions/Status/status.dictionaries'
import { getFormattedTripDetails } from '../../../Requests/Requests.functions'
import styles from './AcceptOffer.styles'
import { AcceptOfferProps } from './AcceptOffer.types'

const AcceptOffer: React.FC<AcceptOfferProps> = ({ response, onConfirm }) => {
  const [selectedFlightItinerary, setSelectedFlightItinerary] = useState<number>(response.flightItineraries[0].id)

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      setSelectedFlightItinerary(event.target.value as number)
      onConfirm(event.target.value as number)
    },
    [onConfirm]
  )

  useEffect(() => {
    handleSelectChange({ target: { value: response.flightItineraries[0].id } } as SelectChangeEvent<number>)
  }, [handleSelectChange, response.flightItineraries])

  const validFlightItineraries = response.flightItineraries.filter(
    (itinerary: FlightItinerary) => itinerary.status.name !== status.flightStatus.flightBooked
  )

  return (
    <Box sx={styles.container}>
      <Select value={selectedFlightItinerary} onChange={handleSelectChange} displayEmpty sx={styles.select}>
        <MenuItem value="" disabled>
          Select a flight booking
        </MenuItem>

        {validFlightItineraries.map((itinerary: FlightItinerary) => (
          <MenuItem key={itinerary.id} value={itinerary.id}>
            {getFormattedTripDetails(itinerary.itinerary.legs, itinerary.itinerary.tripType as TripType)}
            {`\u00A0 (${date(itinerary.itinerary.legs[0].travelDate).format(dateTimeFormats.date.medium)})`}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default AcceptOffer
