import theme from '@/shared/styles/themes/default.theme'

const styles = {
  container: {
    paddingX: theme.spacing(3),
    paddingY: theme.spacing(1)
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5)
  },
  departureDate: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '20px'
  },

  airlineLogo: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1)
  },

  flightLocations: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold
  },

  flightDetails: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[500]
  },

  flightDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}

export default styles
