import React, { useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Card as MuiCard,
  Typography
} from '@mui/material'

import styles from './Card.styles'
import { CardProps } from './Card.types'

const Card: React.FC<CardProps> = (props) => {
  const { header, body, actions } = props
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <MuiCard sx={styles.card}>
      <CardHeader
        title={
          <Box sx={styles.headerContainer}>
            <Box sx={styles.headerLeft}>
              {header.left.component}

              <Box sx={styles.headerLeftTextContainer}>
                <Typography style={styles.title}>{header.left.title}</Typography>
                {header.left.subtitle && <Typography style={styles.subtitle}>{header.left.subtitle}</Typography>}
              </Box>
            </Box>

            {header.right && <Box sx={styles.headerRight}>{header.right.component}</Box>}
          </Box>
        }
        sx={styles.header}
      />

      <CardContent sx={styles.mainContent}>{body.content}</CardContent>

      {(actions || body.additionalContent) && (
        <CardActions disableSpacing sx={styles.actionsContainer}>
          {actions && (
            <IconButton onClick={actions.onPress} sx={styles.actionButton} aria-label={actions.text}>
              {actions.icon}
              {actions.text && <span style={styles.actionText}>{actions.text}</span>}
            </IconButton>
          )}

          {body.additionalContent && (
            <Box sx={styles.expandButtonContainer} onClick={handleExpandClick}>
              <Typography sx={styles.expandButtonText}>{body.additionalContent.labelText}</Typography>

              <IconButton
                onClick={handleExpandClick}
                sx={{
                  ...styles.expandButton,
                  marginLeft: 'auto',
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)'
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          )}
        </CardActions>
      )}

      {body.additionalContent && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={styles.additionalContent}>{body.additionalContent.component}</CardContent>
        </Collapse>
      )}
    </MuiCard>
  )
}

export default Card
