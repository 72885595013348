import React from 'react'

import Close from '@mui/icons-material/Close'
import { Box, Breadcrumbs, IconButton, InputAdornment, Stack, TextField } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import PageTitle from '../../../../shared/components/PageTitle/PageTitle.component'

import { adminNavigationPaths } from '../../AdminDashboard.dictionary'
import styles from './SearchHeader.styles'
import { SearchHeaderProps } from './SearchHeader.types'

const SearchHeader: React.FC<SearchHeaderProps> = (props) => {
  const { breadcrumbs, header, search, navigate, defaultHomePath = adminNavigationPaths.users } = props
  const [localQuery, setLocalQuery] = React.useState(search?.initialQuery)
  const breadcrumbPresent = breadcrumbs.length > 0

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    search?.onSearchSubmit(localQuery || '')
  }

  const handleInternalClear = () => {
    setLocalQuery('')
    search?.onClear()
  }

  const handleBreadcrumbClick = (path: string) => {
    if (path) {
      const pathSegments = path.split('/').filter(Boolean)
      const withoutBasePath = pathSegments.slice(1).join('/')
      const pathToNavigate = withoutBasePath === '' ? defaultHomePath : withoutBasePath

      navigate(pathToNavigate)
    }
  }

  const renderBreadcrumbs = () => {
    if (!breadcrumbPresent) return null

    return (
      <Breadcrumbs aria-label="breadcrumb" sx={styles.breadcrumbs}>
        {breadcrumbs.map((breadcrumb, index) => (
          <Box
            key={index}
            component="button"
            onClick={() => handleBreadcrumbClick(breadcrumb.path)}
            sx={{
              ...styles.breadcrumbText,
              cursor: breadcrumb.path ? 'pointer' : 'default',
              background: 'none',
              border: 'none',
              padding: 0
            }}
            disabled={!breadcrumb.path}
          >
            {breadcrumb.title}
          </Box>
        ))}
      </Breadcrumbs>
    )
  }

  return (
    <Box sx={styles.container}>
      <Stack spacing={1}>
        {renderBreadcrumbs()}
        <PageTitle title={header.title} subtitle={header.subtitle} />
      </Stack>

      {/* TODO: Replace with SearchBar component */}
      {search && (
        <Box component="form" onSubmit={handleSubmit} sx={styles.searchForm(breadcrumbPresent)}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={search.placeholder ?? 'Enter your query...'}
            value={localQuery}
            onChange={(e) => setLocalQuery(e.target.value)}
            size="small"
            slotProps={{
              input: {
                endAdornment: localQuery && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleInternalClear} edge="end">
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />

          <Button
            buttonType="primary"
            onClick={(e) => {
              e?.preventDefault()
              handleSubmit(e as React.FormEvent<Element>)
            }}
            disabled={!localQuery}
            text="Search"
          />
        </Box>
      )}
    </Box>
  )
}

export default React.memo(SearchHeader)
