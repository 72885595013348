import logger from '../Logger/logger.functions'

/**
 * Copies text to clipboard with fallback for unsupported browsers and insecure contexts
 * @param text - The text to copy to clipboard
 * @returns Promise<boolean> - Returns true if copy was successful, false otherwise
 */

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    // If the browser supports the Clipboard API and the current context is secure, use it
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(text)

      return true
    }

    // Fallback for unsupported browsers and insecure contexts
    const textArea = document.createElement('textarea')

    textArea.value = text
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'

    document.body.appendChild(textArea)

    textArea.select()
    textArea.focus()

    try {
      document.execCommand('copy')
      textArea.remove()

      return true
    } catch (err) {
      logger.logError(err, 'Failed to copy text', 'copyToClipboard')
      textArea.remove()

      return false
    }
  } catch (err) {
    logger.logError(err, 'Failed to copy text', 'copyToClipboard')

    return false
  }
}
