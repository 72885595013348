import theme from '@/shared/styles/themes/default.theme'
import { Theme } from '@mui/material'

const styles = {
  messageWrapper: {
    display: 'flex',
    width: '100%',
    marginBottom: '12px'
  },

  messageWrapperSent: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start'
  },

  messageWrapperReceived: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },

  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '75%',
    margin: '0 8px'
  },

  messageSent: (theme: Theme) => ({
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '12px 12px 0 12px',
    padding: '8px 12px',
    wordBreak: 'break-word'
  }),

  messageReceived: (theme: Theme) => ({
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    borderRadius: '12px 12px 12px 0',
    padding: '8px 12px',
    wordBreak: 'break-word'
  }),

  messageTime: {
    fontSize: '0.7rem',
    marginTop: '4px',
    opacity: 0.6
  },

  messageTimeSent: {
    alignSelf: 'flex-end',
    textAlign: 'right'
  },

  messageTimeReceived: {
    alignSelf: 'flex-start',
    textAlign: 'left'
  },

  avatar: {
    width: 28,
    height: 28,
    fontSize: '0.75rem',
    marginTop: '4px'
  },

  messageText: (isCurrentUser: boolean) => ({
    color: isCurrentUser ? theme.palette.primary.contrastText : theme.palette.text.primary
  })
}

export default styles
