import React from 'react'

import { Box, Typography } from '@mui/material'

import styles from './Price.styles'
import { PriceDisplayProps } from './Price.types'

const Price: React.FC<PriceDisplayProps> = ({ price, currency, superscript = true, showSeparator = false }) => {
  const dollars = Math.floor(price)
  const cents = (price % 1).toFixed(2).substring(2)

  return (
    <Typography variant="h4" component="div" sx={styles.container}>
      {currency && (
        <Box component="span" sx={styles.currency}>
          {currency}
        </Box>
      )}

      {/* If there is no currency, show the dollar sign */}
      {!currency && (
        <Box
          component="span"
          sx={{
            ...(superscript ? styles.dollarSignSuper : styles.dollarSign),
            ...((!superscript || !currency) && styles.noLeftMargin)
          }}
        >
          $
        </Box>
      )}

      <Box component="span" sx={styles.dollars}>
        {dollars}
      </Box>

      {showSeparator && (
        <Box component="span" sx={styles.separator}>
          .
        </Box>
      )}

      <Box
        component="span"
        sx={{
          ...(superscript ? styles.centsSuper : styles.cents),
          ...(showSeparator && styles.noLeftMargin)
        }}
      >
        {cents}
      </Box>
    </Typography>
  )
}

export default Price
