import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../networkRequests/apiClient/env.config'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetTripOfferRequestsForAdminErrorResponse,
  GetTripOfferRequestsForAdminRequest,
  GetTripOfferRequestsForAdminSuccessResponse
} from './getTripOfferRequestsForAdmin.types'

export const getTripOfferRequestsForAdminApi = async (
  request: GetTripOfferRequestsForAdminRequest
): Promise<GetTripOfferRequestsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getTripOfferRequestDetailsForAdmin, {
      flightItineraryId: request.flightItineraryId
    }),
    method: 'GET',
    params: {
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE,
      searchQuery: request.searchQuery ?? '',
      offerRequestStatus: request.offerRequestStatus ?? '',
      itemRequestStatus: request.itemRequestStatus ?? ''
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetTripOfferRequestsForAdminSuccessResponse
  } else {
    throw response as GetTripOfferRequestsForAdminErrorResponse
  }
}

export default getTripOfferRequestsForAdminApi
