import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../Button/Button.component'

import images from '../../../assets/images'
import styles from './ShareFlightItineraryLink.styles'
import { ShareFlightItineraryLinkProps } from './ShareFlightItineraryLink.types'

const ShareFlightItineraryLink: React.FC<ShareFlightItineraryLinkProps> = (props) => {
  const { onPress, showButton = false, loading } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.shareContainer}>
        <Box sx={styles.shareIconsContainer}>
          <img src={images.shareLinkWithIconsIcon} alt="Share Link" style={styles.shareIcon} />
        </Box>

        <Box sx={styles.shareTextContainer}>
          <Typography variant="h6" sx={styles.title}>
            Share Link! Earn Now!
          </Typography>

          <Typography variant="body2" sx={styles.description}>
            Share Hurrier link so you can fly for less. Share link with friends and family so they can send you item
            requests.
          </Typography>
        </Box>
      </Box>

      {showButton && (
        <Button
          text="Share Link"
          buttonType="primary"
          fullWidth
          onClick={onPress}
          sx={styles.shareButton}
          loading={loading}
        />
      )}
    </Box>
  )
}

export default ShareFlightItineraryLink
