import React from 'react'

import { Box, Chip, Typography } from '@mui/material'

import Table from '../../../../../../../../../../shared/components/Table/Table.component'

import date, { dateTimeFormats } from '../../../../../../../../../../shared/functions/Date/date.functions'
import { getStatusColor } from '../../../../../../../../../../shared/functions/Status/status.functions'
import { OrderPaymentDetails, TravelerPaymentDetails } from '../../../../../../../../../../shared/types/payments.types'
import {
  orderPaymentDetailsColumns,
  stripePaymentDetailsColumns,
  tripPaymentDetailsColumns
} from './PaymentDetailsCard.dictionary'
import styles from './PaymentDetailsCard.styles'
import { PaymentDetailsCardProps } from './PaymentDetailsCard.types'

const PaymentDetailsCard: React.FC<PaymentDetailsCardProps> = (props) => {
  const { paymentDetails, service } = props

  const getPaymentDetailsColumns = () => {
    switch (service) {
      case 'trip':
        return tripPaymentDetailsColumns

      case 'order':
        return orderPaymentDetailsColumns

      default:
        return []
    }
  }

  return (
    <Box sx={styles.container}>
      <Table
        title="Payment Details"
        columns={[
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Data' }
        ]}
        data={
          paymentDetails
            ? getPaymentDetailsColumns().map((column) => ({
                key: column.label,
                value: column.render(paymentDetails as TravelerPaymentDetails & OrderPaymentDetails)
              }))
            : []
        }
      />

      {paymentDetails?.stripePayments && (
        <Box sx={styles.contentSection}>
          <Typography variant="subtitle1" sx={styles.contentSectionTitle}>
            Related Stripe Payments
          </Typography>

          {(paymentDetails?.stripePayments ?? []).map((payment, index) => (
            <Box key={payment.paymentIntentId} sx={styles.contentSection}>
              <Table
                title={`Stripe Payment: ${index + 1}`}
                columns={[
                  { key: 'key', label: 'Key' },
                  { key: 'value', label: 'Data' }
                ]}
                data={stripePaymentDetailsColumns.map((column) => ({
                  key: column.label,
                  value: column.render(payment)
                }))}
              />

              {(payment.refunds ?? []).length > 0 && (
                <Box sx={styles.contentSection}>
                  {payment.refunds.map((refund) => (
                    <Table
                      key={refund.id}
                      title="Related Refunds"
                      columns={[
                        { key: 'key', label: 'Key' },
                        { key: 'value', label: 'Data' }
                      ]}
                      data={[
                        { key: 'Refund ID', value: refund.id },
                        {
                          key: 'Status',
                          value: <Chip label={refund.status} size="small" color={getStatusColor(refund.status)} />
                        },
                        { key: 'Amount', value: `${refund.currency} ${refund.refundAmount}` },
                        {
                          key: 'Date',
                          value: date(refund.refundDate).format(dateTimeFormats.date.medium)
                        }
                      ]}
                    />
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default PaymentDetailsCard
