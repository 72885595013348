import { ApiErrorResponse, ApiSuccessResponse } from '../../../../shared/types/api.types'
import { SliceActions } from '../../../../shared/types/slice.types'
import { checkOnboardingInfoActions } from './checkOnboardingInfo.slice'

export const enum OnboardingInfoType {
  ID_DOCUMENT_UPLOADED = 'ID_DOCUMENT_UPLOADED'
}

export interface CheckOnboardingInfoRequest {
  infoType: OnboardingInfoType
}

export interface CheckOnboardingInfoRequestPayload {
  request: CheckOnboardingInfoRequest
  onSuccess?: (response: CheckOnboardingInfoSuccessResponse) => void
  onError?: () => void
}

export interface CheckOnboardingInfoSuccessResponse extends ApiSuccessResponse<CheckOnboardingInfoData> {}

export interface CheckOnboardingInfoErrorResponse extends ApiErrorResponse {}

interface CheckOnboardingInfoData {
  infoType: OnboardingInfoType
  value: boolean | string
}

export interface CheckOnboardingInfoState {
  loading: boolean
  error: CheckOnboardingInfoErrorResponse | null
  success: CheckOnboardingInfoSuccessResponse | null
  // Add any additional state properties here
}

export type CheckOnboardingInfoActionTypes = SliceActions<typeof checkOnboardingInfoActions>
