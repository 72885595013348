import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import { mobileXPadding } from '../../../layouts/MainLayout/MainLayout.constants'
import { fullWidthIgnorePadding } from '../../functions/Styling/styling.functions'
import theme from '../../styles/themes/default.theme'

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  } as SxProps,

  main: (fullWidth?: boolean) =>
    ({
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        ...(fullWidth && { width: '100%' })
      }
    }) as SxProps,

  titleAndFilterContainer: (searchBar?: boolean) =>
    ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: theme.spacing(searchBar ? 4 : 0)
      }
    }) as SxProps,

  infiniteScroll: (isDesktop: boolean, removeExtraPadding: boolean) =>
    ({
      overflow: 'visible',
      ...(isDesktop ? {} : { maxWidth: `calc(100% - ${theme.spacing(removeExtraPadding ? 0 : mobileXPadding)})` })
    }) as CSSProperties,

  endOfScrollMessage: {
    textAlign: 'center',
    my: 2
  } as SxProps,

  scrollContentContainer: {
    width: '101.52%'
  } as SxProps,

  masonryContainer: {
    width: '100%'
  } as SxProps,

  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      borderRadius: theme.spacing(1.5),
      gap: theme.spacing(2)
    }
  } as SxProps,

  tab: {
    display: 'flex',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
    textTransform: 'none',
    '&.Mui-selected': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      paddingX: 0,
      fontSize: '12px'
    }
  } as SxProps,

  tabIndicator: {
    backgroundColor: theme.palette.primary.main
  },

  tabRoot: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main
    },
    '& .MuiTab-root': {
      borderBottom: `5px solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4)
      }
    },
    '& .Mui-selected': {
      borderBottom: `5px solid ${theme.palette.primary.main}`
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: `calc(100% - ${theme.spacing(mobileXPadding)})`
    }
  } as CSSProperties,

  searchBarContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(-mobileXPadding),
      marginTop: theme.spacing(-5)
    },
    [theme.breakpoints.down('lg')]: {
      width: fullWidthIgnorePadding(mobileXPadding)
    }
  } as SxProps,

  sidebar: {
    maxWidth: '250px',
    minWidth: '250px',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  } as SxProps,

  filterMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingRight: theme.spacing(3)
  } as SxProps,

  emptyStateContainer: {
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(3)
    }
  } as SxProps,

  alertsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  } as SxProps,

  divider: {
    marginBottom: theme.spacing(2)
  } as SxProps,

  alert: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left'
  } as SxProps
}
