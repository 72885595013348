// TODO: Pull from api
export const marketplaceCountryUrls = {
  amazon: [
    { countryCode: 'US', url: 'amazon.com', shortUrl: 'a.co' },
    { countryCode: 'UK', url: 'amazon.co.uk', shortUrl: 'a.co.uk' },
    { countryCode: 'DE', url: 'amazon.de', shortUrl: 'a.de' },
    { countryCode: 'FR', url: 'amazon.fr', shortUrl: 'a.fr' },
    { countryCode: 'IT', url: 'amazon.it', shortUrl: 'a.it' },
    { countryCode: 'ES', url: 'amazon.es', shortUrl: 'a.es' },
    { countryCode: 'CA', url: 'amazon.ca', shortUrl: 'a.ca' },
    { countryCode: 'JP', url: 'amazon.co.jp', shortUrl: 'a.co.jp' },
    { countryCode: 'IN', url: 'amazon.in', shortUrl: 'a.in' },
    { countryCode: 'AU', url: 'amazon.com.au', shortUrl: 'a.com.au' },
    { countryCode: 'BR', url: 'amazon.com.br', shortUrl: 'a.com.br' },
    { countryCode: 'MX', url: 'amazon.com.mx', shortUrl: 'a.com.mx' },
    { countryCode: 'NL', url: 'amazon.nl', shortUrl: 'a.nl' },
    { countryCode: 'SG', url: 'amazon.sg', shortUrl: 'a.sg' },
    { countryCode: 'TR', url: 'amazon.com.tr', shortUrl: 'a.com.tr' },
    { countryCode: 'AE', url: 'amazon.ae', shortUrl: 'a.ae' },
    { countryCode: 'SA', url: 'amazon.sa', shortUrl: 'a.sa' },
    { countryCode: 'PL', url: 'amazon.pl', shortUrl: 'a.pl' },
    { countryCode: 'ZA', url: 'amazon.co.za', shortUrl: 'a.co.za' }
  ]
}
