const urls = {
  auth: {
    signUp: 'auth/sign-up',
    verifyEmail: 'auth/verify-email',
    resendEmailVerification: 'auth/verify-email/resend',
    login: 'auth/login',
    logout: 'auth/logout',
    changePassword: 'auth/change-password',
    forgetPassword: 'auth/forget-password',
    resetForgottenPassword: 'auth/forget-password/reset'
  },
  profile: {
    getProfile: 'profile',
    updatePersonalInfo: 'profile/personal-info/update',
    updateContactInfo: 'profile/contact-info/update',
    updateDeliveryAddress: 'profile/delivery-address/update',
    addDeliveryAddress: 'profile/delivery-address/add',
    deleteDeliveryAddress: 'profile/delivery-address/delete',
    addLoyaltyProgram: 'profile/loyalty-program/add',
    getLoyaltyPrograms: 'profile/loyalty-program/view-all',
    updateLoyaltyProgram: 'profile/loyalty-program/update',
    deleteLoyaltyProgram: 'profile/loyalty-program/delete',
    deliveryAddress: 'profile/deliveryAddress',
    updateFirebaseUserId: 'profile/firebase/update-user-id'
  },
  flights: {
    getFlightItinerary: 'flights/itineraries/view',
    getFlightPlaces: 'flights/places',
    getCountriesAndCities: 'flights/places/countries-and-cities',
    search: 'flights/search',
    searchNext: 'flights/search/next',
    getFlightFares: 'flights/search/fare',
    getAllFlightItineraries: 'flights/itineraries/view-all',
    createFlightItinerary: 'flights/itineraries/create',
    getFlightItineraryShareLink: 'flights/itineraries/share-link',
    itineraries: 'flights/itineraries',
    getUpdatedFlightOffer: 'flights/itineraries/get-updated-flight-offer',
    cancelFlightItinerary: 'flights/itineraries/cancel',
    baggageDetails: 'flights/booking/baggage-details',
    seatDetails: 'flights/booking/seat-details',
    createFlightBooking: 'flights/booking/create',
    createExternalFlightBookingRequest: 'flights/external-flight-booking/requests/create',
    airlines: 'flights/airlines',
    agencies: 'flights/travel-agencies',
    getAllExternalFlightBookingRequests: 'flights/external-flight-booking/requests/view-all'
  },
  supportedLoyaltyPrograms: {
    getSupportedLoyaltyPrograms: 'supported-loyalty-program/view-all'
  },
  items: {
    getItemDetails: 'items/details',
    changes: 'items/changes',
    acceptItemPriceChange: 'items/price-change/accept',
    commitPurchase: 'items/commit-purchase'
  },
  publicOffers: {
    getPublicOffers: 'offers/offer-requests/view-all',
    acceptPublicOffer: 'offers/offer-requests/accept'
  },
  offers: {
    createOfferRequest: 'offers/offer-requests/create',
    viewOfferRequests: 'offers/offer-requests/view',
    acceptItemRequest: 'offers/item-requests/accept',
    denyItemRequest: 'offers/item-requests/deny',
    cancelItemRequest: 'offers/item-requests/cancel',
    resetOfferRequestFlightItinerary: 'offers/offer-requests/reset-flight-itinerary',
    releaseOfferRequest: 'offers/offer-requests/release',
    reassignItemRequest: 'offers/item-requests/reassign',
    getItemRequestTrackingDetails: 'offers/item-requests/tracking-details/get',
    updateItemRequestTrackingDetails: 'offers/item-requests/tracking-details/update',
    addItemTrackingNumber: 'offers/item-requests/external-tracking-details/add-tracking-number',
    confirmOrderDelivery: 'offers/offer-requests/:offerRequestId/confirm-delivery'
  },
  payments: {
    createOfferRequestPaymentIntent: 'payments/payment-intents/offer-requests/create',
    createFlightBookingPaymentIntent: 'payments/payment-intents/flight-bookings/create',
    createCustomerSession: 'payments/customer-sessions/create',
    createAccountSession: 'payments/customer-sessions/account/create',
    requestOfferRequestRefund: 'payments/refunds/offer-requests/request',
    requestFlightBookingRefund: 'payments/refunds/flight-bookings/request',
    logPaymentMethodUpdate: 'payments/payment-methods/log-update',
    checkOnboardingInfo: 'payments/customer-sessions/account/check-onboarding-info',
    getAssignedVirtualCardDetails: 'payments/virtual-cards/:assignedVirtualCardId/view',
    createExternalFlightBookingPaymentIntent: 'payments/payment-intents/external-flight-bookings/create',
    createRequestForVirtualCard: 'payments/virtual-cards/requests/create'
  },
  admin: {
    getAllUsersForAdmin: 'admin/users/view-all',
    getUserDetailsForAdmin: 'admin/users/:userId',
    getAllUserOfferRequestsForAdmin: 'admin/offer-requests/user/:userId/view-all',
    getAllItemRequestsForAdmin: 'admin/offer-requests/:offerRequestId/item-requests/view-all',
    getOfferRequestPaymentDetailsForAdmin: 'admin/offer-requests/:offerRequestId/payment-details/view',
    getOfferRequestTripDetailsForAdmin: 'admin/offer-requests/:offerRequestId/trip-details/view',
    getUserTripsForAdmin: 'admin/trips/user/:userId',
    getTripDetailsForAdmin: 'admin/trips/:flightItineraryId/trip-details/view',
    getTripPaymentDetailsForAdmin: 'admin/trips/:flightItineraryId/payment-details/view',
    getTripOfferRequestDetailsForAdmin: 'admin/trips/:flightItineraryId/offer-requests/view-all',
    getAllVirtualCardRequestsForAdmin: 'admin/payments/virtual-cards/requests/view-all',
    getAllUnassignedVirtualCardsForAdmin: 'admin/payments/virtual-cards/unassigned/view-all',
    getAllAssignedVirtualCardsForAdmin: 'admin/payments/virtual-cards/assigned/view-all',
    assignVirtualCardToFlightItineraryForAdmin: 'admin/payments/virtual-cards/assign',
    getAssignedVirtualCardDetailsForAdmin: 'admin/payments/virtual-cards/assigned/:assignedVirtualCardId/view',
    updateAssignedVirtualCardDetailsForAdmin: 'admin/payments/virtual-cards/assigned/:assignedVirtualCardId/update',
    getAllExternalFlightBookingRequestsForAdmin: 'admin/trips/external-flight-bookings/requests/view-all',
    createItineraryFromExternalFlightBookingRequestForAdmin: 'admin/trips/external-flight-bookings/create-itinerary'
  }
}

export default urls
