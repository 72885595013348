import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../Button/Button.component'

import images from '../../../assets/images'
import styles from './EmptyState.styles'
import { EmptyStateProps } from './EmptyState.types'

const { warning } = images

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { title, subtitle, button, noIcon, marginTop, errorId, apiResponseError } = props

  const correlationId = apiResponseError?.correlationId || errorId

  return (
    <Box sx={styles.container(marginTop)}>
      {!noIcon && <img src={warning} style={styles.icon} alt="warning" />}

      <Box sx={styles.textContainer(!!correlationId)}>
        <Typography variant="body1" sx={styles.title}>
          {apiResponseError ? apiResponseError.message : title}
        </Typography>

        <Typography variant="body1" sx={styles.subtitle}>
          {apiResponseError ? apiResponseError.userFriendlyMessage : subtitle}
        </Typography>

        {correlationId && (
          <Typography variant="body1" sx={styles.correlationId}>
            Error Code: {correlationId}
          </Typography>
        )}
      </Box>

      {button && <Button {...button} buttonType="secondary" />}
    </Box>
  )
}

export default EmptyState
