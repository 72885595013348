import { CalendarMonth, CreditCard, People } from '@mui/icons-material'

import { AdminNavigationItem } from './AdminDashboard.types'

export const adminNavigationPaths = {
  users: 'users',
  externalBookings: 'external-bookings',
  virtualCards: 'virtual-cards'
}

export const adminNavigation: AdminNavigationItem[] = [
  {
    segment: adminNavigationPaths.users,
    title: 'Users',
    icon: <People />,
    pattern: 'users{/:userId}*'
  },
  {
    segment: adminNavigationPaths.externalBookings,
    title: 'External Bookings',
    icon: <CalendarMonth />
  },
  {
    segment: adminNavigationPaths.virtualCards,
    title: 'Virtual Cards',
    icon: <CreditCard />
  }
]
