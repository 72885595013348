import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  CreateRequestForExternalAirlineBookingRequest,
  CreateRequestForExternalFlightRequestPayload,
  CreateRequestForExternalTravelAgencyBookingRequest
} from '@/redux/states/flights/createRequestForExternalFlight/createRequestForExternalFlight.types'
import router from '@/router/functions/router.functions'
import routes from '@/router/routes.dictionary'
import { Box } from '@mui/material'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import Form from '../Form/Form.component'
import Modal from '../Modal/Modal.component'

import {
  createRequestForExternalFlight,
  resetCreateRequestForExternalFlight
} from '../../../redux/states/flights/createRequestForExternalFlight/createRequestForExternalFlight.slice'
import { getExternalAgencies } from '../../../redux/states/flights/getExternalAgencies/getExternalAgencies.slice'
import {
  ExternalAgency,
  GetExternalAgenciesRequestPayload
} from '../../../redux/states/flights/getExternalAgencies/getExternalAgencies.types'
import { getExternalAirlines } from '../../../redux/states/flights/getExternalAirlines/getExternalAirlines.slice'
import {
  ExternalAirline,
  GetExternalAirlinesRequestPayload
} from '../../../redux/states/flights/getExternalAirlines/getExternalAirlines.types'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks'
import { FormSection } from '../Form/Form.types'
import styles from './RequestAddExistingFlightModal.styles'
import {
  BookingType,
  RequestAddExistingFlightFormValues,
  RequestAddExistingFlightModalProps
} from './RequestAddExistingFlightModal.types'

const RequestAddExistingFlightModal: React.FC<RequestAddExistingFlightModalProps> = (props) => {
  const { open, onClose, onSubmit, offerRequest } = props

  const dispatch = useAppDispatch()
  const navigate = router.navigate()
  const formRef = useRef<HTMLFormElement>(null)

  const [currentBookingType, setCurrentBookingType] = useState<BookingType>('airline')
  const [airlines, setAirlines] = useState<ExternalAirline[]>([])
  const [agencies, setAgencies] = useState<ExternalAgency[]>([])
  const [isFormValid, setIsFormValid] = useState(false)

  const { loading: airlinesLoading } = useAppSelector((state) => state.getExternalAirlines)
  const { loading: agenciesLoading } = useAppSelector((state) => state.getExternalAgencies)
  const { loading: submitLoading } = useAppSelector((state) => state.createRequestForExternalFlight)

  useEffect(() => {
    const getExternalAirlinesPayload: GetExternalAirlinesRequestPayload = {
      onSuccess: (airlines) => {
        setAirlines(airlines)
      }
    }

    const getExternalAgenciesPayload: GetExternalAgenciesRequestPayload = {
      onSuccess: (agencies) => {
        setAgencies(agencies)
      }
    }

    if (open) {
      dispatch(getExternalAirlines(getExternalAirlinesPayload))
      dispatch(getExternalAgencies(getExternalAgenciesPayload))
    }
  }, [dispatch, open])

  const initialValues: RequestAddExistingFlightFormValues = {
    bookingType: 'airline',
    airlineId: '',
    confirmationCode: '',
    ticketNumber: '',
    agencyId: '',
    referenceNumber: '',
    emailAddress: ''
  }

  const validationSchema = Yup.object().shape({
    bookingType: Yup.string().oneOf(['airline', 'travelAgency']).required('Required'),
    airlineId: Yup.string().when('bookingType', {
      is: 'airline',
      then: (schema) => schema.required('Required')
    }),
    confirmationCode: Yup.string().when('bookingType', {
      is: 'airline',
      then: (schema) => schema.required('Required')
    }),
    ticketNumber: Yup.string().when('bookingType', {
      is: 'airline',
      then: (schema) => schema.required('Required')
    }),
    agencyId: Yup.string().when('bookingType', {
      is: 'travelAgency',
      then: (schema) => schema.required('Required')
    }),
    referenceNumber: Yup.string().when('bookingType', {
      is: 'travelAgency',
      then: (schema) => schema.required('Required')
    }),
    emailAddress: Yup.string().when('bookingType', {
      is: 'travelAgency',
      then: (schema) => schema.email('Invalid email address').required('Required')
    })
  })

  const getFormSections = useCallback(
    (bookingType: BookingType): FormSection[] => [
      {
        title: '',
        fields: [
          {
            name: 'bookingType',
            label: 'I Booked With',
            type: 'select',
            options: [
              { value: 'airline', label: 'An Airline' },
              { value: 'travelAgency', label: 'An Online Travel Agency' }
            ],
            grid: { md: 6, xs: 12 },
            required: true,
            fullWidth: true
          },
          ...(bookingType === 'airline'
            ? [
                {
                  name: 'airlineId',
                  label: 'Airline',
                  type: 'select',
                  options:
                    airlines?.map((airline) => ({
                      value: airline.id,
                      label: airline.name
                    })) || [],
                  grid: { md: 6, xs: 12 },
                  required: true,
                  fullWidth: true
                },
                {
                  name: 'confirmationCode',
                  label: 'Confirmation Code',
                  type: 'text',
                  grid: { xs: 12 },
                  required: true,
                  fullWidth: true
                },
                {
                  name: 'ticketNumber',
                  label: 'Ticket Number',
                  type: 'text',
                  grid: { xs: 12 },
                  required: true,
                  fullWidth: true
                }
              ]
            : [
                {
                  name: 'agencyId',
                  label: 'Online Travel Agency',
                  type: 'select',
                  options:
                    agencies?.map((agency) => ({
                      value: agency.id,
                      label: agency.name
                    })) || [],
                  grid: { md: 6, xs: 12 },
                  required: true,
                  fullWidth: true
                },
                {
                  name: 'referenceNumber',
                  label: 'Itinerary Reference Number',
                  type: 'text',
                  grid: { xs: 12 },
                  required: true,
                  fullWidth: true
                },
                {
                  name: 'emailAddress',
                  label: 'Email Address Used for Booking',
                  type: 'email',
                  grid: { xs: 12 },
                  required: true,
                  fullWidth: true
                }
              ])
        ]
      }
    ],
    [airlines, agencies]
  )

  const handleSubmit = useCallback(
    (values: unknown) => {
      const typedValues = values as RequestAddExistingFlightFormValues

      let request: CreateRequestForExternalAirlineBookingRequest | CreateRequestForExternalTravelAgencyBookingRequest

      if (typedValues.bookingType === 'airline') {
        request = {
          bookingProviderType: 'airline',
          bookingProviderId: typedValues.airlineId,
          confirmationCode: typedValues.confirmationCode,
          ticketNumber: typedValues.ticketNumber
        } as CreateRequestForExternalAirlineBookingRequest
      } else {
        request = {
          bookingProviderType: 'travelAgency',
          bookingProviderId: typedValues.agencyId,
          itineraryReferenceNumber: typedValues.referenceNumber,
          email: typedValues.emailAddress
        } as CreateRequestForExternalTravelAgencyBookingRequest
      }

      if (offerRequest) {
        request.offerRequestId = offerRequest.offerRequestId.toString()
      }

      const payload: CreateRequestForExternalFlightRequestPayload = {
        request,
        onSuccess: () => {
          onClose()
          onSubmit(typedValues)
          dispatch(resetCreateRequestForExternalFlight())
          navigate(routes.myTrips.path)
        }
      }

      dispatch(createRequestForExternalFlight(payload))
    },
    [dispatch, onClose, onSubmit, offerRequest, navigate]
  )

  const handleFormChange = useCallback(
    (formik: Partial<FormikProps<unknown>>) => {
      const typedValues = formik.values as RequestAddExistingFlightFormValues

      if (typedValues.bookingType !== currentBookingType) {
        setCurrentBookingType(typedValues.bookingType)
      }

      if (typedValues.bookingType === 'airline') {
        if (typedValues.airlineId !== '' && typedValues.confirmationCode !== '' && typedValues.ticketNumber !== '') {
          setIsFormValid(true)
        } else {
          setIsFormValid(false)
        }
      } else {
        if (typedValues.agencyId !== '' && typedValues.referenceNumber !== '' && typedValues.emailAddress !== '') {
          setIsFormValid(true)
        } else {
          setIsFormValid(false)
        }
      }
    },
    [currentBookingType]
  )

  const modalBody = (
    <Box sx={styles.modalBody}>
      <Form
        hideButtons
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onFormChange={handleFormChange}
        formSections={getFormSections(currentBookingType)}
        formRef={formRef}
      />
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add Existing Flight Request"
      subtitle="Please provide your flight booking details below"
      body={modalBody}
      primaryButton={{
        label: submitLoading ? 'Submitting...' : 'Submit for Review',
        onClick: () => {
          formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        },
        disabled: airlinesLoading || agenciesLoading || submitLoading || !isFormValid,
        loading: submitLoading
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose
      }}
    />
  )
}

export default RequestAddExistingFlightModal
