import React from 'react'

import { Box, TextField } from '@mui/material'

import { Chip } from '../Chip/Chip.component'

import useResponsiveness from '../../hooks/responsive.hooks'
import styles from './UrlWidget.styles'
import { UrlWidgetProps } from './UrlWidget.types'

const UrlWidget: React.FC<UrlWidgetProps> = (props: UrlWidgetProps) => {
  const [isDesktop] = useResponsiveness()

  const {
    urls,
    inputValue,
    handleInputChange,
    handleInputKeyPress,
    removeUrl,
    isValidMarketplaceUrl,
    placeholder = isDesktop
      ? 'Paste your Amazon links here to add items to the cart.'
      : 'Paste your Amazon links here.',
    isCompact = false,
    errors,
    loading = false
  } = props

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedText = event.clipboardData.getData('Text')

    if (pastedText) {
      event.preventDefault()
      handleInputChange({ target: { value: pastedText } } as React.ChangeEvent<HTMLInputElement>)
      handleInputKeyPress({ key: 'Enter' } as React.KeyboardEvent, pastedText)
    }
  }

  //TODO: Fix issue with placeholder text not showing up after clearing all chips
  return (
    <Box sx={styles.container(isCompact, loading)}>
      {urls.map((url, index) => (
        <Chip
          key={index}
          label={url}
          onDelete={() => removeUrl(url)}
          error={
            !isValidMarketplaceUrl(url) ? { text: 'INVALID', errorBorder: true, hideErrorText: !isDesktop } : undefined
          }
          sx={styles.chip(isCompact)}
          isCompact={isCompact}
          size={isCompact ? 'small' : 'medium'}
          tooltipText={!isCompact ? undefined : errors?.find((error) => error.url === url)?.error?.message}
        />
      ))}

      <TextField
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        placeholder={placeholder}
        onPaste={handlePaste}
        onPasteCapture={handlePaste}
        variant="standard"
        InputProps={{
          disableUnderline: true
        }}
        sx={styles.input(isCompact)}
      />
    </Box>
  )
}

export default UrlWidget
