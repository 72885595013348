import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  CabinClass,
  CabinClassDisplayName,
  TripType,
  cabinClassDisplayName,
  cabinClassMapping
} from '@/shared/functions/Flights/flights.types'
import useResponsiveness from '@/shared/hooks/responsive.hooks'
import FlightLandIcon from '@mui/icons-material/FlightLandRounded'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoffRounded'
import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { FormikErrors, FormikProps, useFormik } from 'formik'
import _ from 'lodash'
import throttle from 'lodash/throttle'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import Button from '../../../../shared/components/Button/Button.component'
import LoyaltyProgramForm from '../../../../shared/components/LoyaltyProgramForm/LoyaltyProgramForm.component'
import RequestAddExistingFlightModal from '../../../../shared/components/RequestAddExistingFlightModal/RequestAddExistingFlightModal.component'
import ResponsiveDisplayWrapper from '../../../../shared/components/ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'
import Spacer from '../../../../shared/components/Spacer/Spacer.component'
import PassengerSelectionModal from './PassengerSelectionModal/PassengerSelectionModal.component'
import TempDropdown from './TempDropdown/TempDropdown.component'
import DateRangePicker from '@/shared/components/DatePicker/DateRangePicker.component'
import SingleDatePicker from '@/shared/components/DatePicker/SingleDatePicker.component'

import env from '../../../../networkRequests/apiClient/env.config'
import { getFlightPlaces } from '../../../../redux/states/flights/getFlightPlaces/getFlightPlaces.slice'
import {
  FlightPlace,
  GetFlightPlacesRequest
} from '../../../../redux/states/flights/getFlightPlaces/getFlightPlaces.types'
import { searchFlights } from '../../../../redux/states/flights/searchFlights/searchFlights.slice'
import { SearchFlightsRequestPayload } from '../../../../redux/states/flights/searchFlights/searchFlights.types'
import {
  Passenger,
  SearchFlightsRequest
} from '../../../../redux/states/flights/searchFlights/types/SearchFlightRequest.types'
import { setFlightSearchValues } from '../../../../redux/states/flights/searchWidget/flightSearchWidget.actions'
import { addLoyaltyProgram } from '../../../../redux/states/profile/addLoyaltyProgram/addLoyaltyProgram.slice'
import { AddLoyaltyProgramRequestPayload } from '../../../../redux/states/profile/addLoyaltyProgram/addLoyaltyProgram.types'
import { getLoyaltyPrograms } from '../../../../redux/states/profile/getLoyaltyPrograms/getLoyaltyPrograms.slice'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { LoyaltyProgramFormValues } from '../../../../shared/components/LoyaltyProgramForm/LoyaltyProgramForm.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import { RequestAddExistingFlightFormValues } from '../../../../shared/components/RequestAddExistingFlightModal/RequestAddExistingFlightModal.types'
import { isAuthenticated } from '../../../../shared/functions/Auth/auth.functions'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import localStorage from '../../../../shared/functions/LocalStorage/localStorage'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import FlightSearchResultsStyles from '../../../FlightSearchResults/FlightSearchResults.styles'
import { RequiredCountries } from '../../../Offers/components/OfferItem/OfferItem.types'
import { REDIRECT_TO_TRAVEL_PAGE_TO_ADD_EXISTING_FLIGHT_KEY } from '../../../TripSummary/TripSummary.dictionary'
import styles from './FlightSearchWidget.styles'
import { Flight, FlightSearchInitialValues, FlightSearchWidgetProps, InitialFlight } from './FlightSearchWidget.types'

const FlightSearchWidget: React.FC<FlightSearchWidgetProps> = (props: FlightSearchWidgetProps) => {
  const {
    isCompact = false,
    requiredCountries,
    initialValues,
    renewItinerary,
    showAddExistingFlightButton = false,
    setDates,
    setPassengers,
    searchFlightConfig
  } = props

  const [loaded, setLoaded] = useState<boolean>(false)
  const [preSearchedPlaces, setPreSearchedPlaces] = useState<FlightPlace[]>([])
  const [passengerModalOpen, setPassengerModalOpen] = useState<boolean>(false)
  const [loadingFrom, setLoadingFrom] = useState<boolean[]>([])
  const [loadingTo, setLoadingTo] = useState<boolean[]>([])
  const [placesFrom, setPlacesFrom] = useState<FlightPlace[][]>([])
  const [placesTo, setPlacesTo] = useState<FlightPlace[][]>([])
  const [departureDateFormats, setDepartureDateFormats] = useState<string[]>([])
  const [returnDateFormats, setReturnDateFormats] = useState<string[]>([])
  const [airlineIataCode, setAirlineIataCode] = useState<string>('')
  const [loyaltyProgramAccountNumber, setLoyaltyProgramAccountNumber] = useState<string>('')
  const [doAutoSearch, setDoAutoSearch] = useState<boolean>(false)
  const [flightDateModalIndex, setFlightDateModalIndex] = useState<number | null>(null)
  const [loyaltyProgramFormErrors, setLoyaltyProgramFormErrors] = useState<FormikErrors<LoyaltyProgramFormValues>>({})
  const [currentSearchFlightsRequestPayload, setCurrentSearchFlightsRequestPayload] =
    useState<SearchFlightsRequestPayload | null>(null)
  const { success: loyaltyPrograms } = useAppSelector((state) => state.getLoyaltyPrograms)
  const [isAddExistingFlightModalOpen, setIsAddExistingFlightModalOpen] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isDesktop] = useResponsiveness()

  const tripTypeOptions = [
    { value: 'roundTrip', label: 'Round Trip' },
    { value: 'oneWay', label: 'One Way' },
    { value: 'multiCity', label: 'Multi-City' }
  ]

  const formik: FormikProps<FlightSearchInitialValues> = useFormik({
    initialValues: {
      tripType: 'roundTrip' as TripType,
      flights: [
        {
          from: null,
          to: null,
          departure: null
        }
      ] as InitialFlight[],
      cabinClass: cabinClassDisplayName.Economy as CabinClassDisplayName,
      adults: 1,
      children: 0,
      childrenAges: [] as number[]
    },
    validationSchema: Yup.object({
      tripType: Yup.string().required('Trip type is required'),
      flights: Yup.array().of(
        Yup.object({
          from: Yup.object().required('From location is required'),
          to: Yup.object().required('To location is required'),
          departure: Yup.date()
            .required('Departure date is required')
            .test('max-date', 'Date cannot be after the required arrival date', function (value) {
              if (!value) return true // Skip validation if no date selected

              const { parent } = this
              const index = parseInt(this.path.split('[')[1])

              if (searchFlightConfig?.neededByDateISO) {
                const configDestination = searchFlightConfig.destinationCountryCode
                const maxDateTime = DateTime.fromISO(searchFlightConfig.neededByDateISO)

                switch (formik.values.tripType) {
                  case 'oneWay': {
                    if (parent.to?.iata_country_code === configDestination) {
                      return DateTime.fromJSDate(value) <= maxDateTime
                    }
                    break
                  }

                  case 'roundTrip': {
                    if (parent.to?.iata_country_code === configDestination) {
                      return DateTime.fromJSDate(value) <= maxDateTime
                    }
                    break
                  }

                  case 'multiCity': {
                    const destinationLegIndex = formik.values.flights.findIndex(
                      (flight) => flight.to?.iata_country_code === configDestination
                    )

                    if (index <= destinationLegIndex) {
                      return DateTime.fromJSDate(value) <= maxDateTime
                    }
                    break
                  }
                }
              }

              return true
            }),
          return: Yup.date()
            .min(Yup.ref('departure'), 'Return date must be after departure date')
            .nullable()
            .test('max-date', 'Date cannot be after the required arrival date', function (value) {
              if (!value) return true // Skip validation if no date selected

              const { parent } = this
              if (searchFlightConfig?.neededByDateISO && formik.values.tripType === 'roundTrip') {
                const configDestination = searchFlightConfig.destinationCountryCode
                const maxDateTime = DateTime.fromISO(searchFlightConfig.neededByDateISO)

                if (parent.from?.iata_country_code === configDestination) {
                  return DateTime.fromJSDate(value) <= maxDateTime
                }
              }

              return true
            })
        })
      ),
      cabinClass: Yup.string().required('Cabin class is required'),
      adults: Yup.number().min(1, 'At least one adult is required').required('Number of adults is required'),
      children: Yup.number()
        .min(0, 'Number of children cannot be negative')
        .required('Number of children is required')
        .test('match-children-ages', 'Number of children must match the number of ages', function (value) {
          // TODO: Resolve issue where removing a child causes this test to fail
          return value === this.parent.childrenAges.length
        }),
      childrenAges: Yup.array().of(Yup.number().min(0, 'Age cannot be negative'))
    }),

    onSubmit: (values) => {
      onSearchButtonPressed(values)
    }
  })

  useEffect(() => {
    // TODO: Call search Only once
    setDoAutoSearch(false)
    if (doAutoSearch) {
      if (renewItinerary) {
        formik.submitForm()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doAutoSearch, renewItinerary])

  useEffect(() => {
    if (loaded || !initialValues) {
      setDoAutoSearch(true)

      return
    }

    const newValues = {
      ...initialValues,
      flights: initialValues?.flights.map((flight) => {
        return {
          ...flight,
          from: flight.from,
          to: flight.to,
          departure: flight.departure ? date(flight.departure).dateTime : null,
          return: flight.return ? date(flight.return).dateTime : null
        }
      })
    }

    formik.setFieldValue('tripType', newValues.tripType)
    formik.setFieldValue('flights', newValues?.flights)
    formik.setFieldValue('cabinClass', newValues.cabinClass)
    formik.setFieldValue('adults', newValues.adults)
    formik.setFieldValue('children', newValues.children)
    formik.setFieldValue('childrenAges', newValues.childrenAges)

    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, initialValues])

  const initializeDateFormats = (flights: Flight[]) => {
    setDepartureDateFormats(flights.map(() => dateTimeFormats.date.calendar))
    setReturnDateFormats(flights.map(() => dateTimeFormats.date.calendar))
  }

  const initializeLoadingStates = (flights: Flight[]) => {
    setLoadingFrom(flights.map(() => false))
    setLoadingTo(flights.map(() => false))
  }

  const initializePlaces = (flights: Flight[]) => {
    setPlacesFrom(flights.map(() => []))
    setPlacesTo(flights.map(() => []))
  }

  useEffect(() => {
    initializeDateFormats(formik.values.flights as Flight[])
    initializeLoadingStates(formik.values.flights as Flight[])
    initializePlaces(formik.values.flights as Flight[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.flights.length])

  useEffect(() => {
    const preSearchPlaces = async () => {
      if (requiredCountries) {
        const places: FlightPlace[] = []

        for (const country of [
          requiredCountries.itemLocation,
          requiredCountries.deliveryLocation
        ] as RequiredCountries['deliveryLocation'][]) {
          const searchQuery = country.cityIataCode || country.iataCode
          const getFlightPlacesPayload: GetFlightPlacesRequest = { searchQuery, allResults: true }

          await dispatch(
            getFlightPlaces({
              getFlightPlacesRequest: getFlightPlacesPayload,
              onSuccess: (data: FlightPlace[]) => {
                places.push(...data)
              }
            })
          ).unwrap()
        }

        setPreSearchedPlaces(places)
      }
    }

    preSearchPlaces()
  }, [requiredCountries, dispatch])

  const handleTripTypeChange = (value: string) => {
    formik.setFieldValue('tripType', value)
    const firstFlight = formik.values.flights[0]

    if (value !== 'multiCity') {
      formik.setFieldValue('flights', [
        { from: firstFlight.from, to: firstFlight.to, departure: firstFlight.departure }
      ])
    }
  }

  const handleTripTypeChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTripTypeChange(event.target.value)
  }

  const handleTripTypeChangeSelect = (value: string) => {
    handleTripTypeChange(value)
  }

  const addFlight = () => {
    const newFlight = {
      from: null,
      to: null,
      departure: null
    }

    formik.setFieldValue('flights', [...formik.values.flights, newFlight])
  }

  const removeFlight = (index: number) => {
    formik.setFieldValue(
      'flights',
      formik.values.flights.filter((_, i) => i !== index)
    )
  }

  const isFormValid = () => {
    const isValid = formik.isValid && Object.keys(formik.errors).length === 0
    let isFlightsValid = true

    formik.values.flights.forEach((flight) => {
      isFlightsValid = flight.from !== null && flight.to !== null && flight.departure !== null

      if (formik.values.tripType === 'roundTrip') {
        isFlightsValid = isFlightsValid && flight.return !== undefined
      }
    })

    return isValid && isFlightsValid
  }

  const handleInputChange = useCallback(
    (index: number, value: string, field: 'from' | 'to') => {
      if (preSearchedPlaces.length > 0 && formik.values.tripType !== 'multiCity') {
        const filteredPlaces = preSearchedPlaces
          .filter((place) => {
            const lowercaseValue = value.toLowerCase()

            return (
              place.name.toLowerCase() === lowercaseValue ||
              place.country.toLowerCase() === lowercaseValue ||
              place.iata_city_code.toLowerCase() === lowercaseValue ||
              place.iata_code.toLowerCase() === lowercaseValue ||
              place.city_name?.toLowerCase() === lowercaseValue ||
              place.name.toLowerCase().includes(lowercaseValue) ||
              place.country.toLowerCase().includes(lowercaseValue) ||
              place.iata_city_code.toLowerCase().includes(lowercaseValue) ||
              place.iata_code.toLowerCase().includes(lowercaseValue) ||
              place.city_name?.toLowerCase().includes(lowercaseValue)
            )
          })
          .sort((a, b) => {
            const aExactMatch =
              a.name.toLowerCase() === value.toLowerCase() ||
              a.country.toLowerCase() === value.toLowerCase() ||
              a.iata_city_code.toLowerCase() === value.toLowerCase() ||
              a.iata_code.toLowerCase() === value.toLowerCase() ||
              a.city_name?.toLowerCase() === value.toLowerCase()
            const bExactMatch =
              b.name.toLowerCase() === value.toLowerCase() ||
              b.country.toLowerCase() === value.toLowerCase() ||
              b.iata_city_code.toLowerCase() === value.toLowerCase() ||
              b.iata_code.toLowerCase() === value.toLowerCase() ||
              b.city_name?.toLowerCase() === value.toLowerCase()

            return Number(bExactMatch) - Number(aExactMatch)
          })

        if (field === 'from') {
          setPlacesFrom((prev) => {
            const newPlacesFrom = [...prev]
            newPlacesFrom[index] = filteredPlaces

            return newPlacesFrom
          })
        } else {
          setPlacesTo((prev) => {
            const newPlacesTo = [...prev]
            newPlacesTo[index] = filteredPlaces

            return newPlacesTo
          })
        }
      } else {
        const getFlightPlacesRequest: GetFlightPlacesRequest = {
          searchQuery: value
        }

        const requestPayload = {
          getFlightPlacesRequest,
          onSuccess: (data: FlightPlace[]) => {
            if (field === 'from') {
              setLoadingFrom((prev) => {
                const newLoadingFrom = [...prev]
                newLoadingFrom[index] = false

                return newLoadingFrom
              })

              setPlacesFrom((prev) => {
                const newPlacesFrom = [...prev]
                newPlacesFrom[index] = data

                return newPlacesFrom
              })
            } else {
              setLoadingTo((prev) => {
                const newLoadingTo = [...prev]
                newLoadingTo[index] = false

                return newLoadingTo
              })

              setPlacesTo((prev) => {
                const newPlacesTo = [...prev]
                newPlacesTo[index] = data

                return newPlacesTo
              })
            }
          }
        }

        if (value.trim() !== '') {
          if (field === 'from') {
            setLoadingFrom((prev) => {
              const newLoadingFrom = [...prev]
              newLoadingFrom[index] = true

              return newLoadingFrom
            })
          } else {
            setLoadingTo((prev) => {
              const newLoadingTo = [...prev]
              newLoadingTo[index] = true

              return newLoadingTo
            })
          }

          dispatch(getFlightPlaces(requestPayload))
        }
      }
    },
    [dispatch, preSearchedPlaces, formik.values.tripType]
  )

  const throttledHandleInputChange = useMemo(() => throttle(handleInputChange, 700), [handleInputChange])

  const MAX_MODAL_DISPLAYS = parseInt(env.LOYALTY_PROGRAM_MODAL_DISPLAY_LIMIT)

  const handleSearch = useCallback(
    (searchFlightsRequestPayload: SearchFlightsRequestPayload, skipModal?: boolean) => {
      setCurrentSearchFlightsRequestPayload(searchFlightsRequestPayload)

      const userLoyaltyProgramExists = loyaltyPrograms?.data && loyaltyPrograms.data.length > 0
      const modalDisplayCount = parseInt(localStorage.getItem('loyaltyModalDisplayCount') || '0')

      const handleAction = (action: 'save' | 'skip') => {
        if (_.isEmpty(loyaltyProgramFormErrors) || action === 'skip') {
          const loyaltyProgramPayload: AddLoyaltyProgramRequestPayload = {
            request: {
              airline_iata_code: airlineIataCode,
              account_number: loyaltyProgramAccountNumber
            }
          }

          if (isAuthenticated()) {
            let loyaltyProgramAdded = false

            if (action === 'save') {
              dispatch(addLoyaltyProgram(loyaltyProgramPayload))
            }

            searchFlightsRequestPayload.request.passengers = searchFlightsRequestPayload.request.passengers.map(
              (passenger) => {
                if (
                  !loyaltyProgramAdded &&
                  passenger.type === 'adult' &&
                  loyaltyProgramPayload.request.account_number
                ) {
                  if (!passenger.loyalty_programme_accounts) {
                    passenger.loyalty_programme_accounts = []
                  }

                  passenger.loyalty_programme_accounts.push({
                    account_number: loyaltyProgramPayload.request.account_number,
                    airline_iata_code: loyaltyProgramPayload.request.airline_iata_code
                  })

                  loyaltyProgramAdded = true
                }

                return passenger
              }
            )
          } else {
            // TODO: Save loyalty program data to storage and then add it on next login
            // TODO: Delete loyalty program data from storage after user logs in
          }

          dispatch(searchFlights(searchFlightsRequestPayload))

          const flightSearchInitialValues = {
            ...formik.values,
            flights: formik.values.flights.map((flight) => ({
              ...flight,
              departure: flight.departure ? date(flight.departure).dateTime.toISO() : null,
              return: flight.return ? date(flight.return).dateTime.toISO() : null
            }))
          }

          if (!renewItinerary) {
            navigate(routes.flightSearchResults.path, {
              state: {
                request: searchFlightsRequestPayload.request,
                requiredCountries: requiredCountries,
                flightSearchInitialValues
              }
            })
          }
        }
      }

      if (skipModal || userLoyaltyProgramExists || modalDisplayCount >= MAX_MODAL_DISPLAYS) {
        handleAction('skip')
      } else {
        Modal.show({
          title: 'Loyalty Program',
          subtitle: 'Register your Loyalty Program. You could be eligible for better pricing or flight awards.',
          body: (
            <LoyaltyProgramForm
              setAirlineIataCode={setAirlineIataCode}
              setLoyaltyProgramAccountNumber={setLoyaltyProgramAccountNumber}
              setErrors={setLoyaltyProgramFormErrors}
              onSubmit={() => handleAction('save')}
            />
          ),
          primaryButton: {
            label: 'Register and Continue',
            disabled: airlineIataCode === '' || loyaltyProgramAccountNumber === '',
            onClick: () => handleAction('save')
          },
          secondaryButton: {
            label: 'Skip',
            onClick: () => {
              handleAction('skip')
              localStorage.setItem('loyaltyModalDisplayCount', modalDisplayCount + 1)
            }
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loyaltyProgramAccountNumber,
      loyaltyProgramFormErrors,
      airlineIataCode,
      dispatch,
      navigate,
      requiredCountries,
      initialValues,
      loyaltyPrograms?.data
    ]
  )

  useEffect(
    function reShowModalWithUpdatedValues() {
      if (Modal.isOpen() && currentSearchFlightsRequestPayload) {
        handleSearch(currentSearchFlightsRequestPayload)
      }
    },
    [airlineIataCode, loyaltyProgramAccountNumber, currentSearchFlightsRequestPayload, handleSearch]
  )

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(getLoyaltyPrograms({}))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateRequiredCountries = (
    sourceCountry: { iataCode: string },
    deliveryLocation: { iataCode: string; neededByDate: string },
    inputValues: FlightSearchInitialValues
  ): boolean => {
    const sourceMatch = inputValues.flights.reduce(
      (acc, flight, index) => {
        if (
          flight.from?.iata_code === sourceCountry.iataCode ||
          flight.from?.iata_country_code === sourceCountry.iataCode ||
          flight.to?.iata_code === sourceCountry.iataCode ||
          flight.to?.iata_country_code === sourceCountry.iataCode
        ) {
          return {
            index,
            isFromCountry:
              flight.from?.iata_code === sourceCountry.iataCode ||
              flight.from?.iata_country_code === sourceCountry.iataCode,
            departureDate: flight.departure
          }
        }

        return acc
      },
      { index: -1, isFromCountry: false, departureDate: null as string | null }
    )

    const deliveryMatch = inputValues.flights.reduce(
      (acc, flight, index) => {
        if (
          flight.to?.iata_code === deliveryLocation.iataCode ||
          flight.to?.iata_country_code === deliveryLocation.iataCode ||
          flight.from?.iata_code === deliveryLocation.iataCode ||
          flight.from?.iata_country_code === deliveryLocation.iataCode
        ) {
          return {
            index,
            isFromCountry:
              flight.from?.iata_code === deliveryLocation.iataCode ||
              flight.from?.iata_country_code === deliveryLocation.iataCode,
            departureDate: flight.departure
          }
        }

        return acc
      },
      { index: -1, isFromCountry: false, departureDate: null as string | null }
    )

    const { index: sourceIndex, isFromCountry: sourceIsFromCountry, departureDate: sourceDepartureDate } = sourceMatch
    const {
      index: deliveryIndex,
      isFromCountry: deliveryIsFromCountry,
      departureDate: deliveryDepartureDate
    } = deliveryMatch

    if (sourceIndex === deliveryIndex) {
      if (sourceIsFromCountry && !deliveryIsFromCountry) {
        return new Date(sourceDepartureDate as string) <= new Date(deliveryDepartureDate as string)
      }
    }

    if (sourceIndex === -1 || deliveryIndex === -1 || deliveryIndex <= sourceIndex) {
      return false
    }

    const deliveryDate = new Date(deliveryLocation.neededByDate)
    const arrivalDate = new Date(inputValues.flights[deliveryIndex].departure as string)

    return arrivalDate <= deliveryDate
  }

  const onSearchButtonPressed = (values: FlightSearchInitialValues) => {
    const serializableValues = {
      ...values,
      flights: values.flights.map((flight) => ({
        ...flight,
        departure: flight.departure?.toString(),
        return: flight.return?.toString()
      }))
    }

    setDates?.({
      departure: values.flights[0].departure ? date(values.flights[0].departure).dateTime.toJSDate() : undefined,
      return: values.flights[0].return ? date(values.flights[0].return).dateTime.toJSDate() : undefined
    })

    setPassengers?.(values.adults + values.children)

    if (values.tripType === 'multiCity' && requiredCountries) {
      const isValid = validateRequiredCountries(
        requiredCountries.itemLocation,
        requiredCountries.deliveryLocation,
        values
      )

      if (!isValid) {
        Modal.show({
          title: 'Invalid Flight Selection',
          body: (
            <Typography sx={FlightSearchResultsStyles.requiredFlightsSubtitle}>
              You need to have a flight from{' '}
              <span style={FlightSearchResultsStyles.linkText}>{searchFlightConfig?.origin}</span> to{' '}
              <span style={FlightSearchResultsStyles.linkText}>{searchFlightConfig?.destination}</span> arriving by{' '}
              <span style={FlightSearchResultsStyles.linkText}>{searchFlightConfig?.neededByDate}</span>.
            </Typography>
          ),
          primaryButton: {
            label: 'OK'
          }
        })

        return
      }
    }

    dispatch(setFlightSearchValues(serializableValues as FlightSearchInitialValues))

    const slices = values.flights.map((flight) => ({
      origin: flight.from?.iata_code || '',
      destination: flight.to?.iata_code || '',
      departure_date: flight.departure ? date(flight.departure).format(dateTimeFormats.date.api) : ''
    }))

    if (values.tripType === 'roundTrip') {
      slices.push({
        origin: slices[0].destination,
        destination: slices[0].origin,
        departure_date: values.flights[0].return ? date(values.flights[0].return).format(dateTimeFormats.date.api) : ''
      })
    }

    const passengers: Passenger[] = []

    for (let i = 0; i < values.adults; i++) {
      passengers.push({ type: 'adult' })
    }

    for (let i = 0; i < values.children; i++) {
      passengers.push({ age: values.childrenAges[i] })
    }

    const searchFlightsRequest: SearchFlightsRequest = {
      slices: slices.map((slice) => ({
        ...slice,
        departure_date: slice.departure_date || ''
      })),
      passengers,
      cabin_class: Object.keys(cabinClassMapping).find(
        (key) => cabinClassMapping[key as CabinClass] === values.cabinClass
      ) as CabinClass
    }

    const searchFlightsRequestPayload: SearchFlightsRequestPayload = {
      request: searchFlightsRequest
    }

    handleSearch(searchFlightsRequestPayload, isCompact)
  }

  const getRequiredCountryText = (requiredCountry: RequiredCountries['deliveryLocation']) => {
    if (requiredCountry?.city) {
      return `Search by city or airport in ${requiredCountry.city}, ${requiredCountry.name}`
    }

    return `Search by city or airport in ${requiredCountry.name}`
  }

  const gridProps = () => {
    const baseProps = { xs: 12, sm: 12 }

    if (isCompact) {
      return { ...baseProps, md: 2.75, lg: 2.75 }
    } else {
      return { ...baseProps, md: 6, lg: 3 }
    }
  }

  const getLocationField = (flight: Flight, index: number, field: 'from' | 'to') => {
    const loading = field === 'from' ? loadingFrom[index] : loadingTo[index]
    const selectedOption = field === 'from' ? flight.from : flight.to

    let places = field === 'from' ? placesFrom[index] : placesTo[index]
    const errors = formik.errors.flights as FormikErrors<Flight>[]
    const touched = formik.touched.flights?.[index]?.[field]
    const error = touched ? errors?.[index]?.[field] : null

    const handleBlur = () => {
      formik.setFieldTouched(`flights.${index}.${field}`, true)
    }

    const validationLocationField = () => {
      if (!requiredCountries) {
        return 'Search by city or airport'
      }

      switch (formik.values.tripType) {
        case 'roundTrip': {
          if (field === 'from') {
            const toFieldValue = formik.values.flights[index].to

            if (!toFieldValue) {
              return `Search by city or airport in ${requiredCountries.itemLocation.name} or ${requiredCountries.deliveryLocation.city}, ${requiredCountries.deliveryLocation.name}`
            } else {
              const toFieldCountryCode = toFieldValue.iata_country_code
              const fromFieldRequiredCountry =
                requiredCountries.itemLocation.iataCode === toFieldCountryCode
                  ? requiredCountries.deliveryLocation
                  : requiredCountries.itemLocation

              places = places.filter((place) => place.iata_country_code === fromFieldRequiredCountry.iataCode)

              return getRequiredCountryText(fromFieldRequiredCountry as RequiredCountries['deliveryLocation'])
            }
          } else {
            const fromFieldValue = formik.values.flights[index].from

            if (!fromFieldValue) {
              return `Search by city or airport in ${requiredCountries.itemLocation.name} or ${requiredCountries.deliveryLocation.city}, ${requiredCountries.deliveryLocation.name}`
            } else {
              const fromFieldCountryCode = fromFieldValue.iata_country_code

              const toFieldRequiredCountry =
                requiredCountries.itemLocation.iataCode === fromFieldCountryCode
                  ? requiredCountries.deliveryLocation
                  : requiredCountries.itemLocation

              places = places.filter((place) => place.iata_country_code === toFieldRequiredCountry.iataCode)

              return getRequiredCountryText(toFieldRequiredCountry as RequiredCountries['deliveryLocation'])
            }
          }
        }
        case 'oneWay': {
          if (field === 'from') {
            places = places.filter((place) => place.iata_country_code === requiredCountries.itemLocation.iataCode)

            return `Search by city or airport in ${requiredCountries.itemLocation.name}`
          } else {
            places = places.filter((place) => place.iata_country_code === requiredCountries.deliveryLocation.iataCode)

            return `Search by city or airport in ${requiredCountries.deliveryLocation.city}, ${requiredCountries.deliveryLocation.name}`
          }
        }
      }
    }

    const noOptionsText = validationLocationField()

    const flightIcon =
      field === 'from' ? <FlightTakeoffIcon sx={styles.optionIcon} /> : <FlightLandIcon sx={styles.optionIcon} />

    return (
      <Grid item {...gridProps()} key={`${index} ${field}`}>
        <Box
          sx={{
            ...(isCompact ? styles.fieldCompact() : styles.field()),
            ...(isCompact && error ? styles.compactSearchableInputError : {})
          }}
        >
          {!isCompact && <Typography sx={styles.label}>{field === 'from' ? 'FROM' : 'TO'}</Typography>}

          <Autocomplete
            key={`${index}-${field}`}
            options={places || []}
            getOptionLabel={(option) => `${option.city_name ?? option.name} (${option.iata_code})`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={!!renewItinerary}
            filterOptions={(options, params) => {
              if (options?.length === 0) return []

              const filtered = options?.filter((option) => {
                return (
                  option.name.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                  option.country.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                  option.iata_city_code.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                  option.iata_code.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                  option.city_name?.toLowerCase().includes(params.inputValue.toLowerCase())
                )
              })

              return filtered
            }}
            sx={{
              ...styles.searchableDropdown
            }}
            PaperComponent={TempDropdown}
            value={selectedOption}
            onChange={(_e, newValue) => {
              const updatedFlights = [...formik.values.flights]
              updatedFlights[index][`${field}`] = newValue
              formik.setFieldValue('flights', updatedFlights)
            }}
            onInputChange={(_e, value) => throttledHandleInputChange(index, value, `${field}`)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Location"
                sx={{
                  ...(isCompact ? styles.searchableInputCompact : styles.searchableInput)
                }}
                onBlur={handleBlur}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: isCompact ? <Box sx={styles.flightIconContainer}>{flightIcon}</Box> : null,
                  endAdornment: (
                    <Box sx={styles.searchableInputContainer}>
                      {loading && (
                        <CircularProgress size={20} sx={params.inputProps.value ? styles.loadingIcon : null} />
                      )}
                      {params.InputProps.endAdornment}
                    </Box>
                  )
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props} sx={styles.autocompleteOption} key={option.id}>
                <ResponsiveDisplayWrapper isDesktop>{flightIcon}</ResponsiveDisplayWrapper>
                {option.name} ({option.iata_code})
              </Box>
            )}
            noOptionsText={noOptionsText || 'Search by city or airport'}
          />
        </Box>
      </Grid>
    )
  }

  const getDateField = (flight: Flight, index: number, field: 'departure' | 'range') => {
    const dateFormats = field === 'departure' ? departureDateFormats : returnDateFormats
    const setDateFormats = field === 'departure' ? setDepartureDateFormats : setReturnDateFormats
    const isDateRange = field === 'range'
    const errors = formik.errors.flights as FormikErrors<Flight>[]
    const touched = formik.touched.flights?.[index]?.[isDateRange ? 'departure' : field]
    let error = touched ? errors?.[index]?.[isDateRange ? 'departure' : field] : null

    if (error?.toString().includes('flights[')) {
      error = isDateRange ? 'Departure and return dates are required' : 'Departure date is required'
    }

    const handleFocus = () => {
      const newDateFormats = [...dateFormats]

      newDateFormats[index] = dateTimeFormats.date.default
      setDateFormats(newDateFormats)
    }

    const handleBlur = (day: Date) => {
      const inputValue = day

      if (inputValue) {
        const newDateFormats = [...dateFormats]
        newDateFormats[index] = dateTimeFormats.date.calendar
        setDateFormats(newDateFormats)
      }

      formik.setFieldTouched(`flights.${index}.${field}`, true)
    }

    const maxDate = () => {
      if (searchFlightConfig?.neededByDateISO) {
        const configDestination = searchFlightConfig.destinationCountryCode

        switch (formik.values.tripType) {
          // For oneWay trips, apply to the single departure date
          case 'oneWay': {
            const flight = formik.values.flights[index]

            if (flight.to?.iata_country_code === configDestination) {
              return DateTime.fromISO(searchFlightConfig.neededByDateISO)
            }

            break
          }

          // For roundTrip trips
          case 'roundTrip': {
            const flight = formik.values.flights[index]
            // If the to location matches destination, apply to departure date
            if (
              (field === 'departure' && flight.to?.iata_country_code === configDestination) ||
              flight.from?.iata_country_code === configDestination
            ) {
              return DateTime.fromISO(searchFlightConfig.neededByDateISO)
            }

            return null
          }

          // For multiCity trips
          case 'multiCity': {
            // Find the index of the leg that goes to the destination
            const destinationLegIndex = formik.values.flights.findIndex(
              (flight) => flight.to?.iata_country_code === configDestination
            )

            if (destinationLegIndex === -1) {
              // If no destination leg is found yet, apply max date to all legs
              return DateTime.fromISO(searchFlightConfig.neededByDateISO)
            } else {
              // If we found the destination leg:
              // Apply max date to all legs up to and including the destination leg
              // Leave subsequent legs unrestricted
              if (index <= destinationLegIndex) {
                return DateTime.fromISO(searchFlightConfig.neededByDateISO)
              }

              // Return null for legs after the destination leg to leave them unrestricted
              return undefined
            }
          }
        }
      }

      if (renewItinerary) {
        const latestDate = renewItinerary.itinerary.legs.reduce((latest, leg) => {
          const legDate = DateTime.fromISO(leg.travelDate)

          return legDate > latest ? legDate : latest
        }, DateTime.fromISO(renewItinerary.itinerary.legs[0].travelDate))

        return latestDate
      }
    }

    const getDateText = (flight: Flight) => {
      const dateFormat = dateTimeFormats.date.short

      if (isDateRange && flight.departure) {
        return flight.return
          ? `${date(flight.departure).format(dateFormat)} - ${date(flight.return).format(dateFormat)}`
          : date(flight.departure).format(dateFormat)
      }

      return flight.departure ? date(flight.departure).format(dateFormat) : <span>Pick a date</span>
    }

    const getSelectedSingleDate = () => {
      return date(flight.departure as DateTime).dateTime.toJSDate()
    }

    const flightErrors = formik.errors.flights?.[index] as FormikErrors<Flight>
    let departureError = flightErrors?.departure?.toString() ?? undefined

    if (departureError?.includes('Departure date is required')) {
      departureError = undefined
    }

    return (
      <Grid item {...gridProps()}>
        <Grid
          onClick={() => {
            if (flightDateModalIndex === null) {
              setFlightDateModalIndex(index)
            }
          }}
          item
          xs={12}
          sx={isCompact ? styles.fieldCompact(departureError) : styles.field(departureError)}
        >
          {flightDateModalIndex !== null &&
            (isDateRange ? (
              <DateRangePicker
                open={flightDateModalIndex !== null}
                onClose={() => setFlightDateModalIndex(null)}
                setDate={(date) => {
                  if (date?.from) {
                    const updatedFlights = [...formik.values.flights]
                    updatedFlights[0].departure = date.from.toISOString()

                    if (date.to) {
                      updatedFlights[0].return = date.to.toISOString()
                    }

                    formik.setFieldValue('flights', updatedFlights)
                    formik.setFieldTouched(`flights.${index}.departure`, true)

                    if (date.to) {
                      formik.setFieldTouched(`flights.${index}.return`, true)
                    }
                  }
                }}
                date={{
                  from: flight.departure ? date(flight.departure).dateTime.toJSDate() : undefined,
                  to: flight.return ? date(flight.return).dateTime.toJSDate() : undefined
                }}
                error={departureError}
                toDate={maxDate()?.toJSDate()}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disablePast
              />
            ) : (
              <SingleDatePicker
                open={flightDateModalIndex !== null}
                onClose={() => setFlightDateModalIndex(null)}
                setDate={(date) => {
                  const updatedFlights = [...formik.values.flights]

                  updatedFlights[flightDateModalIndex][`${field}`] = date?.toISOString() as unknown as string // TODO: Fix this incorrect typing
                  formik.setFieldValue('flights', updatedFlights)
                  formik.setFieldTouched(`flights.${flightDateModalIndex}.${field}`, true)
                }}
                date={getSelectedSingleDate()}
                toDate={maxDate()?.toJSDate()}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disablePast
              />
            ))}

          {!isCompact && <Typography sx={styles.label}>DATES</Typography>}

          <Typography sx={styles.infoText(isCompact)}>{getDateText(flight)}</Typography>
        </Grid>
      </Grid>
    )
  }

  const handleAddExistingFlightClick = () => {
    if (!isAuthenticated()) {
      localStorage.setItem(REDIRECT_TO_TRAVEL_PAGE_TO_ADD_EXISTING_FLIGHT_KEY, true)
      navigate(routes.login.path)

      return
    } else {
      setIsAddExistingFlightModalOpen(true)
    }
  }

  const handleAddExistingFlightSubmit = (_values: RequestAddExistingFlightFormValues) => {
    setIsAddExistingFlightModalOpen(false)
  }

  const getAddExistingFlightButton = () => {
    if (showAddExistingFlightButton) {
      return <Button buttonType="primary" text={'Add Existing Flight'} onClick={handleAddExistingFlightClick} />
    }
  }

  return (
    <Box sx={{ ...styles.widgetContainer, ...(isCompact ? styles.widgetContainerCompact(isMobile) : {}) }}>
      <form onSubmit={formik.handleSubmit} style={styles.widgetForm}>
        <Box sx={styles.tripDetailsContainer}>
          <ResponsiveDisplayWrapper isDesktop>
            <RadioGroup
              row
              value={formik.values.tripType}
              onChange={handleTripTypeChangeRadio}
              sx={styles.tripTypeGroup}
            >
              {tripTypeOptions.map((option) => (
                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
              ))}
            </RadioGroup>
          </ResponsiveDisplayWrapper>

          <Divider orientation="vertical" flexItem sx={styles.divider} />

          <Box
            sx={{
              ...styles.passengerCabinContainer,
              ...(isCompact ? styles.passengerCabinContainerCompact : {})
            }}
          >
            <PassengerSelectionModal
              open={passengerModalOpen}
              onClose={() => setPassengerModalOpen(false)}
              adults={formik.values.adults}
              children={formik.values.children}
              childrenAges={formik.values.childrenAges}
              setAdults={(value) => formik.setFieldValue('adults', value)}
              setChildren={(value) => formik.setFieldValue('children', value)}
              setChildrenAges={(value) => formik.setFieldValue('childrenAges', value)}
            />

            <ResponsiveDisplayWrapper isDesktop={false}>
              <Select
                value={formik.values.tripType}
                onChange={(e) => handleTripTypeChangeSelect(e.target.value)}
                displayEmpty
                sx={styles.barebonesDropdown}
              >
                {tripTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </ResponsiveDisplayWrapper>

            <Select
              value={formik.values.cabinClass}
              onChange={(e) => formik.setFieldValue('cabinClass', e.target.value as string)}
              displayEmpty
              sx={styles.barebonesDropdown}
            >
              <MenuItem value="Economy">Economy</MenuItem>
              <MenuItem value="Economy Premium">Economy Premium</MenuItem>
              <MenuItem value="Business">Business Class</MenuItem>
              <MenuItem value="First">First Class</MenuItem>
            </Select>

            <Spacer />

            {isDesktop && getAddExistingFlightButton()}
          </Box>
        </Box>

        <Box sx={styles.flightSearchContainer}>
          {formik.values.flights.map((flight, index) => (
            <Box key={index}>
              <Box sx={styles.multiCityDividerContainer}>
                {formik.values.flights.length > 1 && index !== 0 && (
                  <Divider orientation="horizontal" sx={styles.multiCityDivider} />
                )}
              </Box>

              {formik.values.tripType === 'multiCity' && (
                <Box sx={styles.multiCityDividerContainer}>
                  <Typography sx={styles.multiCityDividerFlight}>Flight {index + 1}</Typography>
                  {index > 0 && (
                    <Button
                      onClick={() => removeFlight(index)}
                      style={styles.multiCityDividerRemove}
                      buttonType="tertiary"
                      text={'Remove'}
                      key={index}
                    />
                  )}
                </Box>
              )}

              <Box key={index} sx={styles.searchFieldsContainer}>
                <Grid container spacing={isMobile ? 2 : 4}>
                  {!isMobile && getLocationField(flight as Flight, index, 'from')}

                  {!isMobile && getLocationField(flight as Flight, index, 'to')}

                  <Grid spacing={4} item sx={styles.locationFieldContainer}>
                    {getLocationField(flight as Flight, index, 'from')}
                    {getLocationField(flight as Flight, index, 'to')}
                  </Grid>

                  {formik.values.tripType !== 'roundTrip' && getDateField(flight as Flight, index, 'departure')}

                  {formik.values.tripType === 'roundTrip' && getDateField(flight as Flight, index, 'range')}

                  {index === 0 && (
                    <Grid item {...gridProps()} onClick={() => setPassengerModalOpen(true)}>
                      <Box sx={isCompact ? styles.fieldCompact() : styles.field()}>
                        {!isCompact && <Typography sx={styles.label}>PASSENGERS</Typography>}

                        <Box sx={styles.passengerDropdownContent}>
                          {(isCompact || isMobile) && (
                            <Typography sx={styles.infoText(isCompact)}>
                              {formik.values.adults + formik.values.children}&nbsp;Passenger
                              {formik.values.adults + formik.values.children > 1 ? 's' : ''}
                            </Typography>
                          )}

                          {!(isMobile || isCompact) && (
                            <Box sx={styles.passengerDropdownText}>
                              <Typography sx={styles.infoText(isCompact)}>
                                {formik.values.adults}&nbsp;Adult
                                {formik.values.adults > 1 ? 's' : ''}
                              </Typography>

                              {formik.values.children > 0 && (
                                <Typography sx={styles.infoSubtitle}>
                                  {formik.values.children}&nbsp;
                                  {formik.values.children > 1 || formik.values.children === 0 ? 'Children' : 'Child'}
                                </Typography>
                              )}
                            </Box>
                          )}

                          {isMobile && <ArrowDropDownIcon sx={styles.dropdownIcon} />}
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {isCompact && isDesktop && index === 0 && (
                    <Grid item md={1} lg={1} sx={styles.compactSearchButtonContainer}>
                      <IconButton
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => onSearchButtonPressed(formik.values)}
                        disabled={!isFormValid()}
                        sx={styles.compactSearchIconContainer}
                        aria-label="Search flights"
                      >
                        <SearchIcon sx={styles.compactSearchIcon} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>

        <Box sx={styles.buttonsContainer(formik.values.tripType === 'multiCity')}>
          {formik.values.tripType === 'multiCity' && (
            <Button
              variant="outlined"
              color="primary"
              onClick={addFlight}
              buttonType="secondary"
              text="Add Flight"
              large={isMobile || isCompact ? false : true}
              fullWidth={isMobile}
            />
          )}

          {!isCompact && isMobile && getAddExistingFlightButton()}

          {(!isCompact || (isCompact && isMobile)) && (
            <Button
              variant="contained"
              buttonType="primary"
              text={isMobile ? 'Search' : 'Search Flights'}
              large={isMobile ? false : true}
              icon={
                isMobile ? <SearchIcon sx={styles.mobileSearchIcon} /> : <SearchIcon sx={styles.desktopSearchIcon} />
              }
              onClick={() => onSearchButtonPressed(formik.values)}
              iconPosition="end"
              type="submit"
              disabled={!isFormValid()}
              fullWidth={isMobile}
            />
          )}
        </Box>
      </form>

      <RequestAddExistingFlightModal
        open={isAddExistingFlightModalOpen}
        onClose={() => setIsAddExistingFlightModalOpen(false)}
        onSubmit={handleAddExistingFlightSubmit}
      />
    </Box>
  )
}

export default FlightSearchWidget
