import { UserAssignedVirtualCardDetails } from '../../../../redux/states/payments/getAssignedVirtualCardDetails/getAssignedVirtualCardDetails.types'
import { changeCase } from '../../../../shared/functions/String/string.functions'

export function getEmailBody(virtualCardDetails: UserAssignedVirtualCardDetails) {
  return `My ${changeCase(virtualCardDetails.status.description).lower}, but I have not yet purchased any items.

Card Holder Name: ${virtualCardDetails.cardHolderName}
Card Expiry: ${virtualCardDetails.expirationDate}
Card ID: ${virtualCardDetails.cardId}`
}
