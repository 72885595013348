import React from 'react'

import ChatWindow from '../ChatWindow/ChatWindow.component'

import { useMessaging } from '../../context/Messaging.context'
import { ChatProviderProps } from './ChatProvider.types'

const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const messaging = useMessaging()

  return (
    <>
      {children}

      {messaging.isOpen && messaging.currentChatPartner && messaging.currentUserParticipant && (
        <ChatWindow
          isOpen={messaging.isOpen}
          isExpanded={messaging.isExpanded}
          currentChatPartner={messaging.currentChatPartner}
          currentUserParticipant={messaging.currentUserParticipant}
          messages={messaging.messages}
          currentFirebaseUserId={messaging.currentFirebaseUserId}
          onClose={messaging.closeChat}
          onToggleExpand={messaging.toggleExpand}
          onSendMessage={messaging.sendMessage}
        />
      )}
    </>
  )
}

export default ChatProvider
