import UploadIcon from '@mui/icons-material/Upload'

import images from '../../assets/images'

const { CursorIcon, CartIcon, ChecklistIcon } = images

const dictionary = {
  pageBanner: {
    title: 'Save Over 40% on Shipping',
    subtitle: 'Simply paste your Amazon URLs below. Add items to your cart and submit request to our Hurriers.',
    links: [
      {
        text: 'How It Works - Shop on Hurrier',
        url: 'https://www.youtube.com/watch?v=udqAV9rzT7E'
      }
    ]
  },

  howToTravelAndEarn: {
    title: 'How it works',
    subtitle: 'In 4 simple steps, you can have your packages at your door.',
    steps: [
      {
        icon: <CursorIcon />,
        title: 'Paste Amazon URL',
        description: 'Paste Amazon link in Hurrier'
      },

      {
        icon: <UploadIcon />,
        title: 'Submit Order',
        description: 'Submit your Order and pay securely.'
      },

      {
        icon: <ChecklistIcon />,
        title: 'Get Offer Accepted',
        description: 'Hurrier will match you with a Traveler'
      },

      {
        icon: <CartIcon />,
        title: 'Receive Item',
        description: 'Receive item from Traveler'
      }
    ]
  }
}

export default dictionary
