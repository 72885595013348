import { TableColumn } from '@/shared/components/Table/Table.types'
import { stripPrefixFromId } from '@/shared/functions/ID/id.functions'
import { getStatusColor } from '@/shared/functions/Status/status.functions'
import { Chip } from '@mui/material'

import { ExternalFlightBookingRequestAdmin } from '../../../../redux/states/admin/getAllExternalFlightBookingRequestsForAdmin/getAllExternalFlightBookingRequestsForAdmin.types'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'

export const externalFlightBookingRequestTableColumns: TableColumn<ExternalFlightBookingRequestAdmin>[] = [
  {
    key: 'id',
    label: 'ID',
    render: (row: ExternalFlightBookingRequestAdmin) => stripPrefixFromId(row.id)
  },
  {
    key: 'travelerName',
    label: 'Traveler',
    render: (row: ExternalFlightBookingRequestAdmin) => `${row.traveler.firstName} ${row.traveler.lastName}`
  },
  {
    key: 'source',
    label: 'Source',
    render: (row: ExternalFlightBookingRequestAdmin) =>
      row.providerBookingType === 'airline'
        ? row.airlineBookingDetails?.airline?.name || 'N/A'
        : row.travelAgencyBookingDetails?.travelAgency?.name || 'N/A'
  },
  {
    key: 'createdAt',
    label: 'Requested',
    render: (row: ExternalFlightBookingRequestAdmin) => {
      const formattedDate = date(row.creationDate).format(dateTimeFormats.date.medium)

      return formattedDate || 'N/A'
    }
  }
]

export const externalFlightBookingProcessedTableColumns: TableColumn<ExternalFlightBookingRequestAdmin>[] = [
  {
    key: 'id',
    label: 'ID',
    render: (row: ExternalFlightBookingRequestAdmin) => stripPrefixFromId(row.id)
  },
  {
    key: 'travelerName',
    label: 'Traveler',
    render: (row: ExternalFlightBookingRequestAdmin) => `${row.traveler.firstName} ${row.traveler.lastName}`
  },
  {
    key: 'source',
    label: 'Source',
    render: (row: ExternalFlightBookingRequestAdmin) =>
      row.providerBookingType === 'airline'
        ? row.airlineBookingDetails?.airline?.name || 'N/A'
        : row.travelAgencyBookingDetails?.travelAgency?.name || 'N/A'
  },
  {
    key: 'createdAt',
    label: 'Requested',
    render: (row: ExternalFlightBookingRequestAdmin) => {
      const formattedDate = date(row.creationDate).format(dateTimeFormats.date.medium)

      return formattedDate || 'N/A'
    }
  },
  {
    key: 'status',
    label: 'Status',
    render: (row: ExternalFlightBookingRequestAdmin) => (
      <Chip label={row.status.displayName} color={getStatusColor(row.status.name)} />
    )
  }
]
