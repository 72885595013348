import React from 'react'

import theme from '@/shared/styles/themes/default.theme'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from '@mui/material'

import Button from '../Button/Button.component'

import styles from './Modal.styles'
import { ModalProps } from './Modal.types'

const Modal: React.FC<ModalProps> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const {
    open,
    onClose,
    title,
    subtitle,
    primaryButton,
    secondaryButton,
    tertiaryButton,
    body,
    headerRightSection,
    maxWidth,
    showCloseButton = false,
    icon,
    testId
  } = props

  const primaryButtonAction = () => (
    <Button
      buttonType="primary"
      altText={`${title} modal ${primaryButton?.label}`}
      text={primaryButton?.label?.toUpperCase() || ''}
      fullWidth={isMobile || !secondaryButton}
      onClick={primaryButton?.onClick}
      disabled={primaryButton?.disabled || primaryButton?.loading}
      loading={primaryButton?.loading}
      tooltipText={primaryButton?.tooltipText}
    />
  )

  const secondaryButtonAction = () => (
    <Button
      buttonType="tertiary"
      altText={`${title} modal ${secondaryButton?.label}`}
      text={secondaryButton?.label?.toUpperCase() || ''}
      onClick={secondaryButton?.onClick}
      outline
      tooltipText={secondaryButton?.tooltipText}
      fullWidth={isMobile}
    />
  )

  const tertiaryButtonAction = () => (
    <Button
      buttonType="tertiary"
      altText={`${title} modal ${tertiaryButton?.label}`}
      text={tertiaryButton?.label?.toUpperCase() || ''}
      onClick={tertiaryButton?.onClick}
      fullWidth={isMobile}
    />
  )

  const buttonsPresent = primaryButton || secondaryButton

  const renderIcon = () => {
    if (icon === 'error') {
      return <ErrorIcon sx={[styles.baseIcon, styles.errorIcon]} />
    } else if (icon === 'warning') {
      return <WarningIcon sx={[styles.baseIcon, styles.warningIcon]} />
    } else if (icon === 'info') {
      return <InfoIcon sx={[styles.baseIcon, styles.infoIcon]} />
    } else if (icon === 'success') {
      return <CheckCircleIcon sx={[styles.baseIcon, styles.successIcon]} />
    }
  }

  const handleClose = (
    _: React.SyntheticEvent | Event,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'
  ) => {
    const isLoading = primaryButton?.loading || secondaryButton?.loading || tertiaryButton?.loading

    if (isLoading && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return
    } else {
      props.onClose?.()
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={maxWidth || 'sm'}
      onClose={handleClose}
      sx={styles.dialog}
      PaperProps={{
        'data-testid': testId,
        'aria-controls': 'modal-dialog',
        'aria-labelledby': 'modal-dialog'
      }}
    >
      {showCloseButton && (
        <Box sx={styles.closeButtonContainer}>
          <Button
            buttonType="tertiary"
            icon={<CloseIcon sx={styles.closeButtonIcon} />}
            altText={`Close ${title} modal`}
            iconPosition="center"
            onClick={onClose}
            sx={styles.closeButton}
          />
        </Box>
      )}

      <DialogContent>
        <Box sx={styles.headerContainer}>
          {icon && <Box sx={styles.iconContainer}>{renderIcon()}</Box>}

          <Box sx={styles.titleContainer}>
            {title && (
              <Typography variant="h5" sx={[styles.title, icon ? styles.titleWithIcon : {}]}>
                {title}
              </Typography>
            )}

            {headerRightSection && headerRightSection}
          </Box>

          {subtitle && (
            <Typography variant="subtitle1" sx={styles.subtitle(!!headerRightSection)}>
              {subtitle}
            </Typography>
          )}
        </Box>

        {body && body}

        {buttonsPresent && (
          <DialogActions disableSpacing={true} sx={styles.dialogActions}>
            {tertiaryButton && tertiaryButtonAction()}
            {secondaryButton && secondaryButtonAction()}
            {primaryButton && primaryButtonAction()}
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default Modal
