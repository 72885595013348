import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createRequestForVirtualCardApi } from './createRequestForVirtualCard.api'
import createRequestForVirtualCardInitialState from './createRequestForVirtualCard.initialState'
import {
  CreateRequestForVirtualCardErrorResponse,
  CreateRequestForVirtualCardRequestPayload
} from './createRequestForVirtualCard.types'

export const createRequestForVirtualCard = createAsyncThunk(
  urls.payments.createRequestForVirtualCard,
  async (payload: CreateRequestForVirtualCardRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await createRequestForVirtualCardApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse, undefined, true)

      return rejectWithValue(error)
    }
  }
)

const createRequestForVirtualCardSlice = createSlice({
  name: urls.payments.createRequestForVirtualCard,
  initialState: createRequestForVirtualCardInitialState,
  reducers: {
    resetCreateRequestForVirtualCard: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRequestForVirtualCard.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createRequestForVirtualCard.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createRequestForVirtualCard.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateRequestForVirtualCardErrorResponse
        state.success = null
      })
  }
})

export const { actions: createRequestForVirtualCardActions, reducer: createRequestForVirtualCardReducer } =
  createRequestForVirtualCardSlice
export const { resetCreateRequestForVirtualCard } = createRequestForVirtualCardActions
export default createRequestForVirtualCardSlice.reducer
