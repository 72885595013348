import React, { useCallback } from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { Box, CircularProgress, Container, Divider, Typography } from '@mui/material'

import Form from '../Form/Form.component'
import Link from '../Link/Link.component'

import styles from './StatusUpdatePage.styles'
import { StatusUpdatePageProps } from './StatusUpdatePage.types'

const StatusUpdatePage: React.FC<StatusUpdatePageProps> = (props) => {
  const { title, subtitle, action, status, form } = props

  const renderIcon = useCallback(() => {
    switch (status) {
      case 'fetching':
        return (
          <HourglassEmptyIcon
            sx={{
              ...styles.icon,
              animation: 'spin 5s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)'
                },
                '100%': {
                  transform: 'rotate(360deg)'
                }
              }
            }}
          />
        )

      case 'success':
        return <CheckCircleIcon sx={styles.icon} />

      case 'pending':
        return <HourglassEmptyIcon sx={styles.pendingIcon} />

      case 'failed':
        return <ErrorIcon sx={styles.failedIcon} />

      default:
        return null
    }
  }, [status])

  return (
    <Container component="main" sx={styles.container}>
      <Box sx={styles.content}>
        <Box sx={styles.iconContainer}>{renderIcon()}</Box>

        <Typography variant="h5" sx={styles.title}>
          {title}
        </Typography>

        <Typography sx={styles.subtitle}>{subtitle}</Typography>

        {action && (
          <Typography sx={styles.actionText}>
            {action.prompt}{' '}
            {action.loading ? (
              <CircularProgress size={16} />
            ) : (
              <Link href={action.link} showColor onClick={action.onClick}>
                {action.linkText}
              </Link>
            )}
          </Typography>
        )}

        {form && (
          <Box sx={styles.formContainer}>
            <Divider sx={styles.divider} />
            <Form {...form} />
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default StatusUpdatePage
