import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Divider, Grid, Typography } from '@mui/material'

import Button from '../../shared/components/Button/Button.component'
import EmptyState from '../../shared/components/EmptyState/EmptyState.component'
import PaymentDetails from '../../shared/components/PaymentDetails/PaymentDetails.component'
import { PaymentSubmittedSuccessfully } from '../../shared/components/PaymentSubmittedSuccessfully/PaymentSubmittedSuccessfully.component'
import ResponsiveDisplayWrapper from '../../shared/components/ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'
import Stepper from '../../shared/components/Stepper/Stepper.component'
import HourrierCard from '../SharedTrip/components/HourrierCard/HourrierCard.component'
import { MarketplaceLinksInput } from '../Shop/components/MarketplaceLinksInput/MarketplaceLinksInput.component'
import CartStep from './components/CartStep/CartStep.component'
import DeliveryDetails from './components/DeliveryDetails/DeliveryDetails.component'
import Overview from './components/Overview/Overview.component'

import { Country } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { getCountriesAndCities } from '../../redux/states/flights/getCountriesAndCities/getCountriesAndCities.slice'
import { CountryAndCities } from '../../redux/states/flights/getCountriesAndCities/getCountriesAndCities.types'
import {
  clearFlightItinerarySuccess,
  getFlightItinerary
} from '../../redux/states/flights/getFlightItinerary/getFlightItinerary.slice'
import { GetFlightItineraryRequest } from '../../redux/states/flights/getFlightItinerary/getFlightItinerary.types'
import { clearItemDetailsSuccess } from '../../redux/states/items/getItemDetails/getItemDetails.slice'
import { Item } from '../../redux/states/items/getItemDetails/getItemDetails.types'
import { createOfferRequest } from '../../redux/states/offers/createOfferRequest/createOfferRequest.slice'
import {
  CreateOfferRequestRequest,
  CreateOfferRequestRequestPayload,
  DeliveryTimePeriod
} from '../../redux/states/offers/createOfferRequest/createOfferRequest.types'
import {
  createOfferRequestPaymentIntent,
  resetCreateOfferRequestPaymentIntent
} from '../../redux/states/payments/createOfferRequestPaymentIntent/createOfferRequestPaymentIntent.slice'
import {
  CreateOfferRequestPaymentIntentRequestPayload,
  PaymentIntentDeliveryDetails,
  PaymentIntentOfferDetail
} from '../../redux/states/payments/createOfferRequestPaymentIntent/createOfferRequestPaymentIntent.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { PaymentFormRef } from '../../shared/components/StripeCheckoutForm/StripeCheckoutForm.types'
import disclaimers from '../../shared/dictionaries/disclaimers.dictionaries'
import { isAuthenticated } from '../../shared/functions/Auth/auth.functions'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { sanitizePhoneNumber } from '../../shared/functions/String/string.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import useResponsiveness from '../../shared/hooks/responsive.hooks'
import {
  CART_STORAGE_KEY,
  FLIGHT_ITINERARY_ID_STORAGE_KEY,
  OFFER_REQUEST_SUBMISSION_STORAGE_KEY,
  REDIRECT_TO_CART_KEY,
  steps
} from './Cart.dictionary'
import styles from './Cart.styles'
import { CartItem } from './Cart.types'
import { DeliveryDetailsValues } from './components/DeliveryDetails/DeliveryDetails.types'

export const Cart: React.FC = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>([])
  const [validCartItems, setValidCartItems] = useState<CartItem[]>([])
  const [activeStep, setActiveStep] = useState(0)
  const [deliveryDetails, setDeliveryDetails] = useState<DeliveryDetailsValues | null>(null)
  const [isDeliveryFormValid, setIsDeliveryFormValid] = useState(false)
  const [isPaymentFormValid, setIsPaymentFormValid] = useState(false)
  const [paymentRequestLoading, setPaymentRequestLoading] = useState(false)
  const [isDesktop] = useResponsiveness()

  const itemData = useAppSelector((state: RootState) => state.getItemDetails.success)
  const countriesAndCities = useAppSelector((state: RootState) => state.getCountriesAndCities.success)
  const flightItinerary = useAppSelector((state: RootState) => state.getFlightItinerary.success)
  const {
    loading: offerRequestLoading,
    success: offerRequestSuccess,
    error: offerRequestError
  } = useAppSelector((state: RootState) => state.createOfferRequest)
  const { loading: createOfferRequestPaymentIntentLoading, success: createOfferRequestPaymentIntentSuccess } =
    useAppSelector((state: RootState) => state.createOfferRequestPaymentIntent)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()
  const navigationProps = router.getNavigationProps()

  const isInitialized = useRef(false)
  const paymentFormRef = useRef<PaymentFormRef>(null)

  const flightItineraryId: number | null = navigationProps?.state?.flightItineraryId
  const cart: CartItem[] = navigationProps?.state?.cart
  const shouldNavigateToCart: boolean | null = navigationProps?.state?.shouldNavigateToCart

  const paymentIntent = router.getUrlParam('payment_intent')
  const redirectStatusFromPayment = router.getUrlParam('redirect_status')
  const paymentSuccess = redirectStatusFromPayment === 'succeeded' && paymentIntent !== null

  const setupIntent = router.getUrlParam('setup_intent')
  const redirectStatusFromSetup = router.getUrlParam('redirect_status')
  const setupPaymentDetailsSuccess = redirectStatusFromSetup === 'succeeded' && setupIntent !== null

  useEffect(() => {
    if (!flightItineraryId || flightItinerary?.data) return

    const getAllFlightItinerariesPayload: GetFlightItineraryRequest = {
      id: flightItineraryId.toString()
    }

    dispatch(
      getFlightItinerary({
        request: getAllFlightItinerariesPayload
      })
    )
  }, [flightItineraryId, dispatch, flightItinerary?.data])

  useEffect(() => {
    dispatch(getCountriesAndCities({}))

    return () => {
      // clearCartFromLocalStorage()
      dispatch(clearFlightItinerarySuccess())
      dispatch(clearItemDetailsSuccess())
    }
  }, [dispatch, cart])

  useEffect(() => {
    if (cart?.length > 0) {
      setCartItems(cart)
    }
  }, [cart])

  const handleSubmitOrder = useCallback(() => {
    const orderSubmission: string | null = localStorage.getItem(OFFER_REQUEST_SUBMISSION_STORAGE_KEY)

    if (orderSubmission) {
      const parsedOrderSubmission: CreateOfferRequestRequest = JSON.parse(orderSubmission)

      const payload: CreateOfferRequestRequestPayload = {
        request: {
          offerDetails: parsedOrderSubmission.offerDetails,
          shopperDetails: parsedOrderSubmission.shopperDetails,
          paymentIntentId: parsedOrderSubmission.paymentIntentId,
          flightItineraryId: parsedOrderSubmission.flightItineraryId
            ? Number(parsedOrderSubmission.flightItineraryId)
            : undefined
        },

        onSuccess: () => {
          dispatch(resetCreateOfferRequestPaymentIntent())
          localStorage.removeItem(OFFER_REQUEST_SUBMISSION_STORAGE_KEY)
          window.scrollTo(0, 0)
        }
      }

      dispatch(createOfferRequest(payload))
    }
  }, [dispatch])

  useEffect(() => {
    if (paymentSuccess || setupPaymentDetailsSuccess) {
      handleSubmitOrder()
      setActiveStep(3)
      window.scrollTo(0, 0)
    }
  }, [paymentSuccess, setupPaymentDetailsSuccess, handleSubmitOrder])

  const orderCountries = useMemo(() => {
    const countrySet = new Set<Country>()

    cartItems.forEach((item) => {
      if (item.country && item.stockStatus !== 'Out of Stock') {
        countrySet.add(item.country)
      }
    })

    setValidCartItems(cartItems.filter((item) => item.stockStatus !== 'Out of Stock'))

    return Array.from(countrySet)
  }, [cartItems])

  const saveCartToLocalStorage = useCallback(
    (items: CartItem[]) => {
      localStorage.setItem(REDIRECT_TO_CART_KEY, true)
      localStorage.setItem(CART_STORAGE_KEY, items)

      if (flightItineraryId) {
        localStorage.setItem(FLIGHT_ITINERARY_ID_STORAGE_KEY, flightItineraryId)
      }
    },
    [flightItineraryId]
  )

  const initializeCartFromItemData = (items: Item[]) => {
    const newCartItems = items.map((item) => ({
      ...item,
      quantity: 1
    }))

    setCartItems(newCartItems)
  }

  const mergeNewItemsIntoCart = useCallback((newItems: Item[]) => {
    setCartItems((prevCartItems) => {
      const updatedCartItems = [...prevCartItems]

      newItems.forEach((newItem) => {
        const existingItemIndex = updatedCartItems.findIndex((item) => item.id === newItem.id)

        if (existingItemIndex === -1) {
          updatedCartItems.unshift({ ...newItem, quantity: 1 })
        }
      })

      return updatedCartItems
    })
  }, [])

  useEffect(() => {
    if (!isInitialized.current) {
      const initializedFromStorage = cart?.length > 0

      if (initializedFromStorage && shouldNavigateToCart) {
        setActiveStep(0)
      }

      if (!initializedFromStorage && itemData?.data?.processedItems) {
        initializeCartFromItemData(itemData.data.processedItems)
      }

      isInitialized.current = true
    } else if (itemData?.data?.processedItems) {
      mergeNewItemsIntoCart(itemData.data.processedItems)
    }

    // TODO: Determine if we need to save the cart to local storage when the user navigates away from the cart page
    // return () => {
    //   saveCartToLocalStorage(cartItems)
    // }
  }, [itemData, cart, shouldNavigateToCart, mergeNewItemsIntoCart])

  const updateItemQuantity = (itemId: number, newQuantity: number) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => (item.id === itemId ? { ...item, quantity: newQuantity } : item))
    )
  }

  const removeItemFromCart = (itemToRemove: CartItem) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemToRemove.id))
  }

  const handleContinueClick = () => {
    if (isAuthenticated()) {
      setActiveStep(1)
    } else {
      saveCartToLocalStorage(cartItems)
      navigate(routes.login.path)
    }
  }

  const handleDeliveryDetailsChange = (values: DeliveryDetailsValues, isValid: boolean) => {
    setDeliveryDetails(values)
    /*
      isValid is true when the form initially loads for some reason and becomes invalid as soon as
      the user clicks off the initial input
      so we check if the form is valid and the country is not empty to set the form to valid
    */
    setIsDeliveryFormValid(isValid && values.deliveryTo.country !== '')
  }

  const handleContinueToPayment = () => {
    setActiveStep(2)
  }

  const handlePaymentSubmission = () => {
    if (deliveryDetails && isDeliveryFormValid && isPaymentFormValid) {
      const orderSubmission: CreateOfferRequestRequest = {
        offerDetails: validCartItems.map((item) => ({
          itemId: Number(item.id),
          itemQuantity: item.quantity
        })),

        shopperDetails: {
          firstName: deliveryDetails.firstName,
          lastName: deliveryDetails.lastName,
          phoneNumber: deliveryDetails.phoneNumber ? sanitizePhoneNumber(deliveryDetails.phoneNumber) || '' : '',

          deliveryTo: {
            country: deliveryDetails.deliveryTo.country,
            city: deliveryDetails.deliveryTo.city
          },

          timePeriod: deliveryDetails.timePeriod as DeliveryTimePeriod,
          deliveryInstructions: deliveryDetails.deliveryInstructions
        },

        paymentIntentId: createOfferRequestPaymentIntentSuccess?.data?.paymentIntent?.id ?? '',

        flightItineraryId: flightItineraryId ?? flightItinerary?.data?.id
      }

      localStorage.setItem(OFFER_REQUEST_SUBMISSION_STORAGE_KEY, JSON.stringify(orderSubmission))

      paymentFormRef.current?.submitPayment()
    }
  }

  const handleStepClick = (step: number) => {
    setActiveStep(step)

    if (step !== 2) {
      dispatch(resetCreateOfferRequestPaymentIntent())
    }
  }

  const renderStepHeader = () => {
    let headerTitle = ''
    let headerSubtitle = ''

    switch (activeStep) {
      case 0:
        headerTitle = 'My Cart'
        break

      case 1:
        headerTitle = 'Delivery Details'
        headerSubtitle = 'Provide your delivery details.'
        break

      case 2:
        headerTitle = 'Payment Details'
        headerSubtitle = 'Provide your payment details.'
        break

      default:
        return null
    }

    return (
      <Box sx={styles.stepHeader}>
        <Typography variant={isDesktop ? 'h4' : 'h6'} sx={styles.title}>
          {headerTitle}
        </Typography>

        {
          <Typography variant="subtitle1" sx={styles.subtitle}>
            {headerSubtitle}
          </Typography>
        }
      </Box>
    )
  }

  const renderStepContent = () => {
    const checkoutMode = 'payment'
    let checkoutSessionOfferDetails: PaymentIntentOfferDetail[] = []
    let checkoutSessionDeliveryDetails: PaymentIntentDeliveryDetails = {
      countryIataCode: '',
      cityIataCode: ''
    }
    let handleCreateOfferRequestPaymentIntentRequest = null

    switch (activeStep) {
      case 0:
        return (
          <CartStep
            cartItems={cartItems}
            updateItemQuantity={updateItemQuantity}
            isAuthenticated={isAuthenticated}
            handleContinueClick={handleContinueClick}
            removeItemFromCart={removeItemFromCart}
          />
        )

      case 1:
        return (
          <DeliveryDetails
            flightItinerary={flightItinerary?.data}
            onFormChange={handleDeliveryDetailsChange}
            orderCountries={orderCountries}
            countriesAndCities={countriesAndCities?.data as CountryAndCities[]}
          />
        )

      case 2:
        checkoutSessionOfferDetails = validCartItems.map((item) => ({
          itemId: Number(item.id),
          itemQuantity: item.quantity
        }))

        checkoutSessionDeliveryDetails = {
          countryIataCode: deliveryDetails?.deliveryTo.country ?? '',
          cityIataCode: deliveryDetails?.deliveryTo.city ?? ''
        }

        handleCreateOfferRequestPaymentIntentRequest = () => {
          const payload: CreateOfferRequestPaymentIntentRequestPayload = {
            request: {
              deliveryDetails: checkoutSessionDeliveryDetails,
              offerDetails: checkoutSessionOfferDetails
            }
          }

          dispatch(createOfferRequestPaymentIntent(payload))
        }

        return (
          <PaymentDetails
            mode={checkoutMode}
            redirectPath={routes.cart.path}
            paymentIntent={createOfferRequestPaymentIntentSuccess?.data?.paymentIntent}
            customerSession={createOfferRequestPaymentIntentSuccess?.data?.customerSession}
            currency={createOfferRequestPaymentIntentSuccess?.data?.currency}
            paymentIntentLoading={createOfferRequestPaymentIntentLoading}
            handleCreatePaymentIntentRequest={handleCreateOfferRequestPaymentIntentRequest}
            setIsPaymentFormValid={setIsPaymentFormValid}
            setPaymentRequestLoading={setPaymentRequestLoading}
            paymentFormRef={paymentFormRef}
          />
        )

      case 3:
        return (
          <PaymentSubmittedSuccessfully
            title="Order Submitted Successfully"
            description="Your order was successfully submitted as an offer to our Hurriers. Once accepted, you will receive an email notification."
            button={{
              text: 'View My Orders',
              onClick: () => navigate(routes.orders.path),
              buttonType: 'primary'
            }}
            loadingState={{ loading: offerRequestLoading, text: 'Processing your order' }}
            success={offerRequestSuccess !== null}
            errorState={
              offerRequestError
                ? {
                    title: 'Order Submission Failed',
                    subtitle: `There was an issue while processing your order. Try submitting again.`,
                    button: {
                      text: 'Try Again',
                      onClick: handleSubmitOrder
                    }
                  }
                : undefined
            }
          />
        )

      default:
        return null
    }
  }

  const getActiveButton = () => {
    if (activeStep === 0) {
      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth={isDesktop}
          sx={styles.submitButton}
          buttonType="primary"
          text={isAuthenticated() ? 'Continue' : 'Login to continue'}
          onClick={handleContinueClick}
          disabled={validCartItems.length === 0}
        />
      )
    } else if (activeStep === 1) {
      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth={isDesktop}
          sx={styles.submitButton}
          buttonType="primary"
          text={isDesktop ? 'Continue to Payment' : 'Continue'}
          onClick={handleContinueToPayment}
          disabled={!isDeliveryFormValid}
          tooltipText={!isDeliveryFormValid ? 'Please ensure all required fields are filled correctly.' : undefined}
        />
      )
    } else if (activeStep === 2) {
      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth={isDesktop}
          sx={styles.submitButton}
          buttonType="primary"
          text={isDesktop ? 'Submit Order' : 'Submit'}
          loading={paymentRequestLoading || offerRequestLoading}
          onClick={handlePaymentSubmission}
          disabled={!isPaymentFormValid || paymentRequestLoading || offerRequestLoading}
          tooltipText={
            !isPaymentFormValid
              ? 'Please ensure all required fields are filled correctly.'
              : disclaimers.amazonAssociateEarnings
          }
        />
      )
    }
  }

  const renderContent = () => {
    if (cartItems.length === 0 && !(setupPaymentDetailsSuccess || paymentSuccess)) {
      return (
        <Box sx={styles.emptyStateContainer}>
          <EmptyState title="Your cart is empty" subtitle="Add items to your cart to get started." />
        </Box>
      )
    } else {
      const isOverviewSectionVisible = activeStep !== 3 && !(setupPaymentDetailsSuccess || paymentSuccess)

      return (
        <Stepper
          activeStep={activeStep}
          steps={steps}
          showStepper={activeStep !== 0}
          onStepClick={handleStepClick}
          stepperWidth="70%"
        >
          {renderStepHeader()}

          <Grid container spacing={isOverviewSectionVisible ? 4 : 0} sx={styles.cartContent}>
            <Grid item xs={12} md={isOverviewSectionVisible ? 8 : 12}>
              <Box sx={styles.stepContent} id="step-content-container">
                {renderStepContent()}
              </Box>
            </Grid>

            {isOverviewSectionVisible && isDesktop && (
              <Grid item xs={12} md={4}>
                {flightItinerary?.data && (
                  <Box sx={styles.hourrierCardContainer}>
                    <HourrierCard flightItinerary={flightItinerary?.data} />
                  </Box>
                )}

                <Overview
                  items={cartItems}
                  currency={createOfferRequestPaymentIntentSuccess?.data?.currency}
                  tax={createOfferRequestPaymentIntentSuccess?.data?.tax}
                  fees={createOfferRequestPaymentIntentSuccess?.data?.fees}
                  subtotal={createOfferRequestPaymentIntentSuccess?.data?.subtotal}
                  total={createOfferRequestPaymentIntentSuccess?.data?.total}
                  orderCountries={orderCountries}
                />

                {getActiveButton()}
              </Grid>
            )}
          </Grid>

          {isOverviewSectionVisible && (
            <ResponsiveDisplayWrapper isDesktop={false}>
              {/* TODO: Allow footer to still be visible on mobile for overview */}
              <Box sx={styles.overviewContainer}>
                {flightItinerary?.data && (
                  <>
                    <HourrierCard flightItinerary={flightItinerary?.data} /> <Divider sx={styles.divider} />
                  </>
                )}

                <Overview
                  items={cartItems}
                  currency={createOfferRequestPaymentIntentSuccess?.data?.currency}
                  tax={createOfferRequestPaymentIntentSuccess?.data?.tax}
                  fees={createOfferRequestPaymentIntentSuccess?.data?.fees}
                  subtotal={createOfferRequestPaymentIntentSuccess?.data?.subtotal}
                  total={createOfferRequestPaymentIntentSuccess?.data?.total}
                  orderCountries={orderCountries}
                  getActiveButton={getActiveButton}
                />
              </Box>
            </ResponsiveDisplayWrapper>
          )}
        </Stepper>
      )
    }
  }

  return (
    <Box sx={styles.container}>
      {activeStep < 1 && <MarketplaceLinksInput isCompact />}
      {renderContent()}
    </Box>
  )
}
export default Cart
