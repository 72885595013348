import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getExternalAirlinesApi } from './getExternalAirlines.api'
import getExternalAirlinesInitialState from './getExternalAirlines.initialState'
import { GetExternalAirlinesErrorResponse, GetExternalAirlinesRequestPayload } from './getExternalAirlines.types'

export const getExternalAirlines = createAsyncThunk(
  urls.flights.airlines,
  async (payload: GetExternalAirlinesRequestPayload, { rejectWithValue }) => {
    const { onSuccess } = payload

    try {
      const response = await getExternalAirlinesApi()

      onSuccess && onSuccess(response.data)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getExternalAirlinesSlice = createSlice({
  name: urls.flights.airlines,
  initialState: getExternalAirlinesInitialState,
  reducers: {
    resetGetExternalAirlines: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExternalAirlines.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getExternalAirlines.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getExternalAirlines.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetExternalAirlinesErrorResponse
        state.success = null
      })
  }
})

export const { actions: getExternalAirlinesActions, reducer: getExternalAirlinesReducer } = getExternalAirlinesSlice
export const { resetGetExternalAirlines } = getExternalAirlinesActions
export default getExternalAirlinesSlice.reducer
