import { CSSProperties } from 'react'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  itemRequestContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  } as CSSProperties,

  itemDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  } as CSSProperties,

  itemDetailsInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  } as CSSProperties,

  itemImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px'
  } as CSSProperties,

  itemImage: {
    objectFit: 'contain',
    width: '32px',
    height: '32px'
  } as CSSProperties,

  itemTitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  } as CSSProperties,

  itemDetailsLinkText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontWeight: theme.typography.fontWeightBold
    }
  } as CSSProperties,

  itemDetailsDivider: (orientation: 'vertical' | 'horizontal') =>
    ({
      backgroundColor: theme.palette.grey[200],
      margin: theme.spacing(0, 4),
      [theme.breakpoints.down('md')]: {
        margin: orientation === 'vertical' ? theme.spacing(0, 1.5) : theme.spacing(2, 0)
      }
    }) as CSSProperties,

  shopperInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    }
  } as CSSProperties,

  rowTitleText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.grey[500]
  } as CSSProperties,

  amazonPriceText: {
    fontSize: theme.typography.caption.fontSize,
    fontStyle: 'italic',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  } as CSSProperties,

  rowValueText: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.blue[800]
    }
  } as CSSProperties,

  itemQuantityAndPriceContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center'
  } as CSSProperties,

  priceContainer: {
    [theme.breakpoints.down('md')]: {
      width: '50%'
    }
  } as CSSProperties
}

export default styles
