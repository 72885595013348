import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { GetExternalAgenciesErrorResponse, GetExternalAgenciesSuccessResponse } from './getExternalAgencies.types'

export const getExternalAgenciesApi = async (): Promise<GetExternalAgenciesSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.agencies,
    method: 'GET',
    data: {},
    params: {},
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetExternalAgenciesSuccessResponse
  } else {
    throw response as GetExternalAgenciesErrorResponse
  }
}

export default getExternalAgenciesApi
