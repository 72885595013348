import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getPublicOffersApi } from './getPublicOffers.api'
import getPublicOffersInitialState from './getPublicOffers.initialState'
import {
  GetPublicOffersErrorResponse,
  GetPublicOffersRequest,
  GetPublicOffersSuccessResponse
} from './getPublicOffers.types'

export const getPublicOffers = createAsyncThunk(
  urls.publicOffers.getPublicOffers,
  async (
    {
      request,
      onSuccess
    }: { request: GetPublicOffersRequest; onSuccess: (data: GetPublicOffersSuccessResponse) => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await getPublicOffersApi(request)

      onSuccess(response)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getPublicOffersSlice = createSlice({
  name: urls.publicOffers.getPublicOffers,
  initialState: getPublicOffersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublicOffers.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getPublicOffers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getPublicOffers.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetPublicOffersErrorResponse
        state.success = null
      })
  }
})

export const { actions: getPublicOffersActions, reducer: getPublicOffersReducer } = getPublicOffersSlice
export default getPublicOffersSlice.reducer
