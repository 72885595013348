import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CheckOnboardingInfoErrorResponse,
  CheckOnboardingInfoRequest,
  CheckOnboardingInfoSuccessResponse
} from './checkOnboardingInfo.types'

export const checkOnboardingInfoApi = async (
  request: CheckOnboardingInfoRequest
): Promise<CheckOnboardingInfoSuccessResponse> => {
  const response = await configureRequest({
    url: urls.payments.checkOnboardingInfo,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CheckOnboardingInfoSuccessResponse
  } else {
    throw response as CheckOnboardingInfoErrorResponse
  }
}

export default checkOnboardingInfoApi
