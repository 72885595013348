import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Chip from '@mui/material/Chip'

import { AssignedVirtualCard } from '../../../../redux/states/admin/payments/virtualCards/getAllAssignedVirtualCardsForAdmin/getAllAssignedVirtualCardsForAdmin.types'
import { VirtualCardRequest } from '../../../../redux/states/admin/payments/virtualCards/getAllVirtualCardRequestsForAdmin/getAllVirtualCardRequestsForAdmin.types'
import { FormSection } from '../../../../shared/components/Form/Form.types'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { FlightItineraryLeg } from '../../../../shared/functions/Places/places.types'
import { getStatusColor } from '../../../../shared/functions/Status/status.functions'
import { changeCase, formatMoney } from '../../../../shared/functions/String/string.functions'
import { getFormattedTripDetails } from '../../../Trips/Trips.functions'

const virtualCardRequestTableColumns = [
  { key: 'id', label: 'Request ID' },
  {
    key: 'createdAt',
    label: 'Request Date',
    render: (row: VirtualCardRequest) => date(row.createdAt).format(dateTimeFormats.date.medium)
  },
  {
    key: 'traveler',
    label: 'Traveler',
    render: (row: VirtualCardRequest) => `${row.user.firstName} ${row.user.lastName}`
  },
  {
    key: 'user.email',
    label: 'Email',
    render: (row: VirtualCardRequest) => row.user.email
  },
  {
    key: 'flightItineraryDetails',
    label: 'Flight Itinerary',
    render: (row: VirtualCardRequest) =>
      getFormattedTripDetails(
        row.flightItineraryDetails?.legs as unknown as FlightItineraryLeg[],
        row.flightItineraryDetails?.tripType,
        true
      )
  },
  {
    key: 'spendingLimit',
    label: 'Spending Limit',
    render: (row: VirtualCardRequest) =>
      formatMoney(row.purchaseDetails.spendingLimit, row.purchaseDetails.orderCurrency)
  },
  {
    key: 'purchaseDetails.expirationDate',
    label: 'Expiration Date',
    render: (row: VirtualCardRequest) => date(row.purchaseDetails.expirationDate).format(dateTimeFormats.date.medium)
  },
  {
    key: 'stripeDetails.idVerificationStatus',
    label: 'Stripe ID Status',
    render: (row: VirtualCardRequest) => (
      <Chip
        label={
          row.stripeDetails.idVerification.status
            ? changeCase(row.stripeDetails.idVerification.status).sentence
            : 'Unverified'
        }
        color={
          row.stripeDetails.idVerification.status === 'verified'
            ? 'success'
            : row.stripeDetails.idVerification.status === 'pending'
              ? 'warning'
              : 'error'
        }
      />
    )
  }
]

const assignedVirtualCardTableColumns = [
  {
    key: 'cardDetails.cardHolderName',
    label: 'Card Holder',
    render: (row: AssignedVirtualCard) => row.cardDetails.cardHolderName
  },
  {
    key: 'cardDetails.truncatedCardNumber',
    label: 'Card Number',
    render: (row: AssignedVirtualCard) => `•••${row.cardDetails.truncatedCardNumber}`
  },
  {
    key: 'latestSpendingLimitRequired',
    label: 'Latest Amount Required',
    render: (row: AssignedVirtualCard) => {
      const latestSpendingLimitRequiredAmount = row.latestSpendingLimitRequired.amount
      const allocatedSpendingLimit = row.cardDetails.spendingLimit
      const orderCurrency = row.cardDetails.orderCurrency
      const amount = formatMoney(latestSpendingLimitRequiredAmount, orderCurrency)
      let formattedAmount

      if (Number(latestSpendingLimitRequiredAmount.toFixed(2)) > Number(allocatedSpendingLimit.toFixed(2))) {
        formattedAmount = <Chip label={amount} icon={<ArrowUpwardIcon />} color={'warning'} />
      } else {
        formattedAmount = amount
      }

      return formattedAmount
    }
  },
  {
    key: 'cardDetails.spendingLimit',
    label: 'Spending Limit Allocated',
    render: (row: AssignedVirtualCard) => formatMoney(row.cardDetails.spendingLimit, row.cardDetails.orderCurrency)
  },
  {
    key: 'cardDetails.createdAt',
    label: 'Created',
    render: (row: AssignedVirtualCard) => date(row.cardDetails.createdAt).format(dateTimeFormats.date.medium)
  },
  {
    key: 'cardDetails.expirationDate',
    label: 'Expires',
    render: (row: AssignedVirtualCard) => date(row.cardDetails.expirationDate).format(dateTimeFormats.date.medium)
  },
  {
    key: 'cardDetails.flightItineraryDetails.id',
    label: 'Flight Itinerary',
    render: (row: AssignedVirtualCard) =>
      getFormattedTripDetails(
        row.flightItineraryDetails?.legs as unknown as FlightItineraryLeg[],
        row.flightItineraryDetails?.tripType,
        true
      )
  },
  {
    key: 'flightItineraryDetails.confirmationCode',
    label: 'Confirmation Code',
    render: (row: AssignedVirtualCard) => row.flightItineraryDetails.confirmationCode
  },
  {
    key: 'cardDetails.status',
    label: 'Status',
    render: (row: AssignedVirtualCard) => (
      <Chip label={row.cardDetails.status.displayName} color={getStatusColor(row.cardDetails.status.name)} />
    )
  }
]

const updateSpendingLimitFormFieldsSections: FormSection[] = [
  {
    title: 'Personal Information',
    alignment: 'left',
    fields: [
      {
        name: 'cardHolderName',
        label: 'Card Holder Name',
        type: 'text',
        placeholder: 'Enter the card holder name',
        readonly: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      },
      {
        name: 'userId',
        label: 'User ID',
        type: 'text',
        placeholder: 'Enter the user ID',
        readonly: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      },
      {
        name: 'email',
        label: 'Email',
        type: 'text',
        placeholder: 'Enter the email',
        readonly: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        type: 'text',
        placeholder: 'Enter the phone number',
        readonly: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      }
    ]
  },
  {
    title: 'Card Details',
    alignment: 'left',
    fields: [
      {
        name: 'cardNumber',
        label: 'Card Number',
        type: 'text',
        placeholder: 'Enter the card number',
        readonly: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      },
      {
        name: 'expirationDate',
        label: 'Expiration Date',
        type: 'text',
        readonly: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      },
      {
        name: 'latestSpendingLimitRequired',
        label: 'Latest Amount Required',
        type: 'text',
        readonly: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      },
      {
        name: 'spendingLimit',
        label: 'Spending Limit',
        type: 'number',
        placeholder: 'Enter the spending limit',
        autoFocus: true,
        fullWidth: true,
        grid: { xs: 12, sm: 6 }
      }
    ]
  }
]

export { virtualCardRequestTableColumns, assignedVirtualCardTableColumns, updateSpendingLimitFormFieldsSections }
