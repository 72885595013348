export const markdownRegexRules = [
  // Headings
  { regex: /^# (.+)/gm, replacer: (_match: string, p1: string) => `<h1 class="custom-h1">${p1}</h1>` },
  { regex: /^## (.+)/gm, replacer: (_match: string, p1: string) => `<h2 class="custom-h2">${p1}</h2>` },
  { regex: /^### (.+)/gm, replacer: (_match: string, p1: string) => `<h3 class="custom-h3">${p1}</h3>` },

  // Bold and Italics
  {
    regex: /\*\*(.+?)\*\*/gm,
    replacer: (_match: string, p1: string) => `<strong class="custom-strong">${p1}</strong>`
  },
  { regex: /\*(.+?)\*/gm, replacer: (_match: string, p1: string) => `<em class="custom-em">${p1}</em>` },

  // Links
  {
    regex: /\[(.+?)\]\((.+?)\)/gm,
    replacer: (_match: string, text: string, url: string) => `<a href="${url}" class="custom-link">${text}</a>`
  },

  // Lists
  { regex: /^\- (.+)/gm, replacer: (_match: string, p1: string) => `<li class="custom-list-item">${p1}</li>` },

  // Blockquote
  {
    regex: /^> (.+)/gm,
    replacer: (_match: string, p1: string) => `<blockquote class="custom-blockquote">${p1}</blockquote>`
  },

  // Code Blocks
  {
    regex: /```([\s\S]+?)```/gm,
    replacer: (_match: string, p1: string) => `<pre class="custom-code"><code>${p1}</code></pre>`
  },

  // Inline Code
  { regex: /`(.+?)`/gm, replacer: (_match: string, p1: string) => `<code class="custom-inline-code">${p1}</code>` },

  // Horizontal Rule
  { regex: /^---$/gm, replacer: () => `<hr class="custom-hr" />` },

  // Wrap regular text in <p> tags
  {
    regex: /^(?!<.*?>)(?!\s*[\-*#>])([^\n]+)$/gm,
    replacer: (_match: string, p1: string) => `<p class="custom-paragraph">${p1}</p>`
  }
]
