import React, { useEffect, useRef } from 'react'

import { isAuthenticated } from '@/shared/functions/Auth/auth.functions'
import { CloseOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'

import ChatInput from '../ChatInput/ChatInput.component'
import ChatMessage from '../ChatMessage/ChatMessage.component'

import styles from './ChatWindow.styles'
import { ChatWindowProps } from './ChatWindow.types'

const ChatWindow: React.FC<ChatWindowProps> = (props) => {
  const {
    isOpen,
    isExpanded,
    currentChatPartner,
    currentUserParticipant,
    messages,
    onClose,
    onToggleExpand,
    onSendMessage
  } = props

  const messagesEndRef = useRef<HTMLDivElement>(null)

  useEffect(
    function scrollToBottomWhenMessagesChange() {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    },
    [messages]
  )

  useEffect(
    function scrollToBottomWhenOpened() {
      if (messagesEndRef.current && isExpanded) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    },
    [isExpanded]
  )

  if (!isOpen || !currentChatPartner || !currentUserParticipant || !isAuthenticated()) return null

  return (
    <Box
      sx={[
        styles.chatWindowContainer,
        isExpanded ? styles.chatWindowContainerExpanded : styles.chatWindowContainerCollapsed
      ]}
    >
      <Box
        sx={styles.chatHeader}
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            onToggleExpand()
          }
        }}
      >
        <Box sx={styles.headerInfo}>
          <Typography sx={styles.headerTitle}>
            {currentChatPartner.recipient.firstName} {currentChatPartner.recipient.lastName}
          </Typography>

          <Typography sx={styles.headerSubtitle}>Order #{currentChatPartner.orderId}</Typography>
        </Box>

        <Box sx={styles.headerActions}>
          <IconButton size="small" sx={styles.iconButton} onClick={onToggleExpand}>
            {isExpanded ? <KeyboardArrowDownOutlined fontSize="small" /> : <KeyboardArrowUpOutlined fontSize="small" />}
          </IconButton>

          <IconButton size="small" sx={styles.iconButton} onClick={onClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {isExpanded && (
        <>
          <Box sx={styles.messagesContainer}>
            {messages.length === 0 && (
              <Box sx={styles.noMessagesContainer}>
                <Typography sx={styles.noMessagesText}>No messages yet. Start a conversation.</Typography>
              </Box>
            )}

            {messages.map((message) => (
              <ChatMessage
                key={message.id}
                message={message}
                isCurrentUser={message.senderHurrierUserId === currentUserParticipant.hurrierUserId}
              />
            ))}

            <div ref={messagesEndRef} />
          </Box>

          <ChatInput onSendMessage={onSendMessage} />
        </>
      )}
    </Box>
  )
}

export default ChatWindow
