import axios, { AxiosError } from 'axios'
import qs from 'qs'

import logger from '../../shared/functions/Logger/logger.functions'
import { ApiErrorResponse, ApiResponse } from '../../shared/types/api.types'
import { ApiClientRequestConfig } from './apiClient.types'
import env from './env.config'

/**
 * Configures and sends an API request.
 *
 * @param {ApiClientRequestConfig} config - The configuration object for the request.
 * @param {string} config.url - The URL to which the request is sent.
 * @param {'GET' | 'POST' | 'PUT' | 'DELETE'} config.method - The HTTP method to use for the request.
 * @param {Record<string, string>} [config.headers] - Optional headers to include in the request.
 * @param {object} [config.data] - Optional data to send with the request.
 * @param {number} [config.timeout=20000] - Optional timeout for the request in milliseconds.
 * @returns {Promise<ApiResponse<object>>} - A promise that resolves to the response data which will be of type ApiSuccessResponse or ApiErrorResponse.
 *
 * @throws {AxiosError | ApiErrorResponse} Will throw an error if the request fails.
 *
 * @example
 * const response = await configureRequest({
 *   url: urls.auth.signUp,
 *   method: 'POST',
 *   data: {
 *     username: 'exampleUser',
 *     email: 'user@example.com',
 *     password: 'examplePassword',
 *   },
 * })
 * console.log(response)
 */
export const configureRequest = async (config: ApiClientRequestConfig): Promise<ApiResponse<object>> => {
  const { url, method, headers = {}, data, timeout = 30000, params } = config

  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...headers
  }

  const requestConfig: ApiClientRequestConfig = {
    url: `${env.API_BASE_URL}/${url}`,
    method,
    headers: defaultHeaders,
    data,
    timeout,
    withCredentials: true,
    params
  }

  return executeRequest(requestConfig)
    .then((response) => {
      return response
    })
    .catch((error) => {
      logger.logError(error, 'Error during request', 'configureRequest')
      throw error
    })
}

/**
 * Executes an API request using the provided configuration.
 *
 * @param {ApiClientRequestConfig} requestConfig - The configuration object for the request.
 * @returns {Promise<ApiResponse<object>>} - A promise that resolves to the response data which will be of type ApiSuccessResponse or ApiErrorResponse.
 *
 * @throws {AxiosError | ApiErrorResponse} Will throw an error if the request fails.
 *
 * @example
 * const response = await executeRequest({
 *   url: 'https://api.example.com/data',
 *   method: 'GET',
 *   headers: {
 *     'Authorization': 'Bearer token'
 *   }
 * })
 * console.log(response)
 */
export const executeRequest = async (requestConfig: ApiClientRequestConfig): Promise<ApiResponse<object>> => {
  try {
    const response = await axios({
      ...requestConfig,
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    })

    return response.data
  } catch (error) {
    const axiosError = error as AxiosError
    const hourrierError = axiosError.response?.data as ApiErrorResponse
    const errorMessage = hourrierError?.message || 'Unknown error'

    logger.logError(hourrierError ?? axiosError, errorMessage, 'executeRequest')

    throw hourrierError ?? axiosError
  }
}

/**
 * Replaces path variables in a URL with corresponding values from an object.
 *
 * @param {string} path - The URL path containing variables in the format :variableName
 * @param {Record<string, string | number>} params - Object containing key-value pairs to replace variables
 * @returns {string} The URL with variables replaced with actual values
 *
 * @example
 * const path = '/users/:userId/posts/:postId'
 * const params = { userId: '123', postId: '456' }
 * const result = replacePathVariables(path, params)
 * // Returns: '/users/123/posts/456'
 */
export const replacePathVariables = (path: string, params: Record<string, string | number>): string => {
  try {
    let updatedPath = path

    Object.entries(params).forEach(([key, value]) => {
      const regex = new RegExp(`:${key}`, 'g')
      updatedPath = updatedPath.replace(regex, value.toString())
    })

    return updatedPath
  } catch (error) {
    logger.logError(error, 'Error replacing path variables', 'replacePathVariables')
    throw error
  }
}
