import { CSSProperties } from 'react'

const styles = {
  container: {},

  section: {
    marginBottom: 4,
    '&:last-child': { marginBottom: 0 }
  },

  sectionTitle: {
    marginBottom: 2,
    fontWeight: 'medium',
    color: 'text.primary'
  },

  legContainer: {
    marginBottom: 2,
    '&:last-child': { marginBottom: 0 }
  },

  legSection: {
    padding: 2,
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 1
  },

  airlineInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5
  },

  airlineLogo: {
    width: 20,
    height: 20,
    objectFit: 'contain'
  } as CSSProperties,

  divider: {
    marginTop: 2,
    marginBottom: 2,
    borderColor: 'divider'
  },

  skeletonContainer: {},

  skeletonItem: {
    marginBottom: 3,
    borderRadius: 2
    // '&:last-child': { marginBottom: 0 }
  }
}

export default styles
