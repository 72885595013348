import localStorage from '../LocalStorage/localStorage'
import { UserRole } from './userRoleManagement.types'

const USER_ROLE_KEY = 'userRole'

/**
 * Sets the user role in local storage
 * @param role - The user role to set
 */
export const setUserRole = (role: UserRole): void => {
  localStorage.setItem(USER_ROLE_KEY, role)
}

/**
 * Gets the user role from local storage
 * @returns The user role or null if not set
 */
export const getUserRole = (): UserRole | null => {
  const role = localStorage.getItem(USER_ROLE_KEY)

  return role ? (role as UserRole) : null
}

/**
 * Removes the user role from local storage
 */
export const removeUserRole = (): void => {
  localStorage.removeItem(USER_ROLE_KEY)
}
