import theme from '../../styles/themes/default.theme'

const styles = {
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[10],
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none'
    }
  },

  textContainer: {
    flex: 1,
    padding: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingX: theme.spacing(3),
      paddingY: 0
    }
  },

  title: {
    fontWeight: 'bold',
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(5),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },

  subtitle: {
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(5),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginBottom: 0
    }
  },

  carouselContainer: {
    flex: 1,
    height: '470px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  imageContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius / 4,
    overflow: 'hidden'
  },

  image: {
    width: '100%',
    height: '100%'
  },

  linksContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    flexDirection: 'column'
  },

  link: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }
}

export default styles
