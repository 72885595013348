import React, { useCallback, useMemo, useState } from 'react'

import { formatMoney } from '@/shared/functions/String/string.functions'
import { Check, Warning } from '@mui/icons-material'
import { Box, Card, CardContent, Divider, Typography } from '@mui/material'

import Button from '../../../../../../shared/components/Button/Button.component'
import BaggageDetailsModal from './components/BaggageDetailsModal/BaggageDetailsModal.component'

import { useAppSelector } from '../../../../../../shared/hooks/redux.hooks'
import styles from './BaggageDetails.styles'
import { BaggageDetailsProps } from './BaggageDetails.types'
import { BaggageSelections } from './components/BaggageDetailsModal/BaggageDetailsModal.types'

const BaggageDetails: React.FC<BaggageDetailsProps> = ({ fareOffer, onBaggageSelectionsChange }) => {
  const [open, setOpen] = useState(false)
  const [selections, setSelections] = useState<BaggageSelections>({})
  const [showMore, setShowMore] = useState(false)

  const { success: baggageDetailsResponse } = useAppSelector((state) => state.getBaggageDetails)

  const isUnavailable = useMemo(() => {
    return baggageDetailsResponse?.data.length === 0
  }, [baggageDetailsResponse])

  const onConfirmSelections = useCallback(
    (newSelections: BaggageSelections) => {
      setSelections(newSelections)
      setOpen(false)
      onBaggageSelectionsChange(newSelections)
    },
    [onBaggageSelectionsChange]
  )

  const getPassengerType = (passengerId: string) => {
    const passenger = fareOffer.passengers.find((p) => p.id === passengerId)

    return passenger ? passenger.type : 'adult'
  }

  const renderBaggageItem = (item: string, included: boolean, added: boolean = false) => (
    <Box sx={styles.baggageItem}>
      {included ? <Check sx={styles.checkIcon} /> : <Warning sx={styles.cancelIcon} />}
      <Typography>{item}</Typography>
      {added && <Typography sx={styles.addedText}>- added</Typography>}
    </Box>
  )

  const renderPassengerBaggage = (
    segmentId: string,
    passengerId: string,
    baggageSelections: BaggageSelections[number][number]
  ) => {
    const passengerType = getPassengerType(passengerId)
    const passengerIndex = fareOffer.passengers.findIndex((p) => p.id === passengerId) + 1

    return (
      baggageSelections.length > 0 && (
        <Box key={`${segmentId}-${passengerId}`} sx={styles.passengerContainer}>
          <Typography sx={styles.passengerTitle}>
            Passenger {passengerIndex}: {passengerType.charAt(0).toUpperCase() + passengerType.slice(1)}
          </Typography>

          {baggageSelections.map((selection, idx) => (
            <Box key={`${passengerId}-${idx}`} sx={styles.baggageItem}>
              <Typography>{selection.type}</Typography>

              <Typography sx={styles.baggagePrice}>+ {formatMoney(selection.cost, selection.currency)}</Typography>
            </Box>
          ))}
        </Box>
      )
    )
  }

  const renderSegment = (
    segmentId: string,
    passengerBaggages: { [passengerId: string]: BaggageSelections[number][number] },
    isLast: boolean
  ) => {
    const segment = fareOffer.slices.flatMap((slice) => slice.segments).find((seg) => seg.id === segmentId)
    const hasSelections =
      Object.keys(passengerBaggages).length > 0 &&
      Object.values(passengerBaggages).some((selections) => selections.length > 0)

    return (
      hasSelections && (
        <Box key={segmentId} sx={styles.segmentContainer(isLast)}>
          <Typography variant="h6" sx={styles.segmentTitle}>
            {segment?.origin.city_name} to {segment?.destination.city_name}
          </Typography>

          {Object.entries(passengerBaggages).map(([passengerId, baggageSelections]) =>
            renderPassengerBaggage(segmentId, passengerId, baggageSelections)
          )}
        </Box>
      )
    )
  }

  const overallBaggageInfo = useMemo(() => {
    const segments = fareOffer.slices.flatMap((slice) => slice.segments)

    const hasCarryOn = segments.some((segment) =>
      segment.passengers.some((passenger) =>
        passenger.baggages.some((bag) => bag.type === 'carry_on' && bag.quantity > 0)
      )
    )

    const hasChecked = segments.some((segment) =>
      segment.passengers.some((passenger) =>
        passenger.baggages.some((bag) => bag.type === 'checked' && bag.quantity > 0)
      )
    )

    return { carryOn: hasCarryOn, checked: hasChecked }
  }, [fareOffer])

  const hasSelections = Object.values(selections).some((segmentSelections) =>
    Object.values(segmentSelections).some((passengerSelections) => passengerSelections.length > 0)
  )

  return (
    <Card sx={styles.baggageCard}>
      <CardContent>
        <Box sx={styles.titleContainer}>
          <Typography variant="h6" sx={styles.title}>
            Baggage Details
          </Typography>

          <Button
            buttonType="tertiary"
            text={hasSelections ? 'Edit Bags' : 'Add extra bags'}
            onClick={() => setOpen(true)}
            disabled={isUnavailable}
            tooltipText={isUnavailable ? 'Extra bags are not available.' : ''}
          />
        </Box>

        <Divider sx={styles.divider} />

        {renderBaggageItem('Carry on included', overallBaggageInfo.carryOn)}
        {overallBaggageInfo.checked && renderBaggageItem('Checked bags included', true)}
        {!overallBaggageInfo.checked && renderBaggageItem('Checked bags are not included', false)}

        {isUnavailable && renderBaggageItem('Extra bags are not available', false)}

        {hasSelections && (
          <>
            <Divider sx={styles.divider} />

            {Object.entries(selections)
              .slice(0, showMore ? undefined : 2)
              .map(([segmentId, passengerBaggages], index) =>
                renderSegment(segmentId, passengerBaggages, index === Object.keys(selections).length - 1)
              )}

            {Object.keys(selections).length > 2 && (
              <Button
                containerStyle={styles.showMoreButtonContainer}
                buttonType="secondary"
                altText={showMore ? 'Show Less Bags' : 'Show More Bags'}
                text={showMore ? 'Show Less' : 'Show More'}
                variant="text"
                style={styles.showMoreButton}
                onClick={() => setShowMore(!showMore)}
              />
            )}
          </>
        )}
      </CardContent>

      <BaggageDetailsModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirmSelections={onConfirmSelections}
        fareOffer={fareOffer}
      />
    </Card>
  )
}

export default BaggageDetails
