import Snackbar from '@/shared/components/Snackbar/Snackbar.functions'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createRequestForExternalFlightApi } from './createRequestForExternalFlight.api'
import createRequestForExternalFlightInitialState from './createRequestForExternalFlight.initialState'
import {
  CreateRequestForExternalFlightErrorResponse,
  CreateRequestForExternalFlightRequestPayload
} from './createRequestForExternalFlight.types'

export const createRequestForExternalFlight = createAsyncThunk(
  urls.flights.createExternalFlightBookingRequest,
  async (payload: CreateRequestForExternalFlightRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await createRequestForExternalFlightApi(request)

      onSuccess && onSuccess()

      Snackbar.show({
        message: response.userFriendlyMessage,
        severity: 'success'
      })

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const createRequestForExternalFlightSlice = createSlice({
  name: urls.flights.createExternalFlightBookingRequest,
  initialState: createRequestForExternalFlightInitialState,
  reducers: {
    resetCreateRequestForExternalFlight: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRequestForExternalFlight.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createRequestForExternalFlight.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createRequestForExternalFlight.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateRequestForExternalFlightErrorResponse
        state.success = null
      })
  }
})

export const { actions: createRequestForExternalFlightActions, reducer: createRequestForExternalFlightReducer } =
  createRequestForExternalFlightSlice
export const { resetCreateRequestForExternalFlight } = createRequestForExternalFlightActions

export default createRequestForExternalFlightSlice.reducer
