import React from 'react'

import Person from '@mui/icons-material/Person2Rounded'
import { Avatar as MUIAvatar } from '@mui/material'

import styles from './Avatar.styles'
import { AvatarProps, AvatarSize } from './Avatar.types'

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const { firstName, lastName, size = 'medium', noRightMargin, ...muiProps } = props

  const isName = !!(firstName && lastName) || !!firstName

  const initials =
    firstName && lastName ? (
      `${firstName[0]}${lastName[0]}`
    ) : firstName ? (
      `${firstName[0]}`
    ) : (
      <Person sx={styles.personIcon(AvatarSize[size])} />
    )

  return (
    <MUIAvatar {...muiProps} ref={ref} sx={styles.container(AvatarSize[size], !!isName, noRightMargin)}>
      {initials}
    </MUIAvatar>
  )
})

export default Avatar
