const status = {
  userStatus: {
    active: 'active',
    unverified: 'unverified',
    locked: 'locked',
    disabled: 'disabled',
    softDeleted: 'soft_deleted'
  },

  itemRequestStatus: {
    pendingAcceptanceByTraveler: 'pending_acceptance_by_traveler',
    acceptedByTraveler: 'accepted_by_traveler',
    deniedByTraveler: 'denied_by_traveler',
    cancelledByShopper: 'cancelled_by_shopper',
    reassignedByShopper: 'reassigned_by_shopper'
  },

  deliveryStatus: {
    purchasedByTraveler: 'purchased_by_traveler',
    inTransitToTraveler: 'in_transit_to_traveler',
    deliveredToTravelerAddress: 'delivered_to_traveler_address',
    confirmedReceivedByTraveler: 'confirmed_received_by_traveler',
    confirmedInDestinationCountry: 'confirmed_in_destination_country',
    confirmedDeliveredToShopperByTraveler: 'confirmed_delivered_to_shopper_by_traveler',
    confirmedDeliveredToShopperByShopper: 'confirmed_delivered_to_shopper_by_shopper',
    lost: 'lost',
    outOfStock: 'out_of_stock'
  },

  flightStatus: {
    interestedInFlight: 'interested_in_flight',
    noLongerInterestedInFlight: 'no_longer_interested_in_flight',
    flightBooked: 'flight_booked',
    flightBookingCancelledBeforePayment: 'flight_booking_cancelled_before_payment',
    flightPaid: 'flight_paid',
    flightCancelledAfterPayment: 'flight_cancelled_after_payment',
    flightRefunded: 'flight_refunded',
    flightCompleted: 'flight_completed'
  },

  externalFlightBookingStatus: {
    externalFlightBookingRequestCreated: 'external_flight_booking_request_created',
    externalFlightBookingRequestProcessing: 'external_flight_booking_request_processing',
    externalFlightBookingRequestDenied: 'external_flight_booking_request_denied',
    externalFlightBookingRequestCancelled: 'external_flight_booking_request_cancelled',
    itineraryCreatedForExternalFlightBooking: 'itinerary_created_for_external_flight_booking'
  },

  paymentStatus: {
    // Payment Statuses
    paymentHoldCreated: 'payment_hold_created',
    paymentHoldProcessing: 'payment_hold_processing',
    paymentHoldConfirmed: 'payment_hold_confirmed',
    paymentHoldFailed: 'payment_hold_failed',
    paymentHoldCancelled: 'payment_hold_cancelled',

    paymentHoldReleaseFailed: 'payment_hold_release_failed',

    paymentCreated: 'payment_created',
    paymentProcessing: 'payment_processing',
    paymentConfirmed: 'payment_confirmed',
    paymentFailed: 'payment_failed',
    paymentCancelled: 'payment_cancelled',

    // Refund Statuses
    refundCreated: 'refund_created',
    refundProcessing: 'refund_processing',
    refundConfirmed: 'refund_confirmed',
    refundFailed: 'refund_failed',
    refundCancelled: 'refund_cancelled'
  },

  transferStatus: {
    transferCreated: 'transfer_created',
    transferProcessing: 'transfer_processing',
    transferConfirmed: 'transfer_confirmed',
    transferFailed: 'transfer_failed',
    transferCancelled: 'transfer_cancelled'
  },

  virtualCardStatus: {
    virtualCardRequestCreated: 'virtual_card_request_created',
    virtualCardRequestProcessing: 'virtual_card_request_processing',
    virtualCardRequestDenied: 'virtual_card_request_denied',
    virtualCardRequestCancelled: 'virtual_card_request_cancelled',
    virtualCardAssigned: 'virtual_card_assigned',
    virtualCardRemoved: 'virtual_card_removed',
    virtualCardExpired: 'virtual_card_expired'
  }
}

const statusColors = {
  userStatus: {
    active: 'success',
    unverified: 'warning',
    locked: 'error',
    disabled: 'error',
    softDeleted: 'error'
  },

  itemRequestStatus: {
    pendingAcceptanceByTraveler: 'warning',
    acceptedByTraveler: 'success',
    deniedByTraveler: 'error',
    cancelledByShopper: 'error',
    reassignedByShopper: 'warning'
  },

  deliveryStatus: {
    purchasedByTraveler: 'success',
    inTransitToTraveler: 'warning',
    deliveredToTravelerAddress: 'success',
    confirmedReceivedByTraveler: 'success',
    confirmedInDestinationCountry: 'success',
    confirmedDeliveredToShopperByTraveler: 'success',
    confirmedDeliveredToShopperByShopper: 'success',
    lost: 'error',
    outOfStock: 'error'
  },

  flightStatus: {
    interestedInFlight: 'warning',
    noLongerInterestedInFlight: 'error',
    flightBooked: 'success',
    flightBookingCancelledBeforePayment: 'error',
    flightPaid: 'success',
    flightCancelledAfterPayment: 'error',
    flightRefunded: 'success',
    flightCompleted: 'success'
  },

  paymentStatus: {
    paymentHoldCreated: 'warning',
    paymentHoldProcessing: 'warning',
    paymentHoldConfirmed: 'success',
    paymentHoldFailed: 'error',
    paymentHoldCancelled: 'error',
    paymentHoldReleaseFailed: 'error',
    paymentCreated: 'warning',
    paymentProcessing: 'warning',
    paymentConfirmed: 'success',
    paymentFailed: 'error',
    paymentCancelled: 'error'
  },

  transferStatus: {
    transferCreated: 'warning',
    transferProcessing: 'warning',
    transferConfirmed: 'success',
    transferFailed: 'error',
    transferCancelled: 'error'
  },

  virtualCardStatus: {
    virtualCardRequestCreated: 'warning',
    virtualCardRequestProcessing: 'warning',
    virtualCardRequestDenied: 'error',
    virtualCardRequestCancelled: 'error',
    virtualCardAssigned: 'success',
    virtualCardRemoved: 'error',
    virtualCardExpired: 'error'
  },

  externalFlightBookingStatus: {
    externalFlightBookingRequestCreated: 'warning',
    externalFlightBookingRequestProcessing: 'warning',
    externalFlightBookingRequestDenied: 'error',
    externalFlightBookingRequestCancelled: 'error',
    itineraryCreatedForExternalFlightBooking: 'success'
  }
}

export { statusColors }

export default status
