import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateExternalFlightBookingPaymentIntentErrorResponse,
  CreateExternalFlightBookingPaymentIntentRequest,
  CreateExternalFlightBookingPaymentIntentSuccessResponse
} from './createExternalFlightBookingPaymentIntent.types'

export const createExternalFlightBookingPaymentIntentApi = async (
  request: CreateExternalFlightBookingPaymentIntentRequest
): Promise<CreateExternalFlightBookingPaymentIntentSuccessResponse> => {
  const response = await configureRequest({
    url: urls.payments.createExternalFlightBookingPaymentIntent,
    method: 'POST',
    data: request,
    params: {},
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateExternalFlightBookingPaymentIntentSuccessResponse
  } else {
    throw response as CreateExternalFlightBookingPaymentIntentErrorResponse
  }
}

export default createExternalFlightBookingPaymentIntentApi
