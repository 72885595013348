const styles = {
  container: {},

  sectionTitle: {
    marginBottom: 2
  },

  skeletonCard: {
    marginBottom: 3,
    borderRadius: 2
  },

  skeletonContent: {
    padding: 2
  },

  sideSection: {
    marginBottom: 3
  },

  contentContainer: {
    marginTop: 2
  }
}

export default styles
