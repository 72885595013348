import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../networkRequests/apiClient/env.config'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetTripDetailsForAdminErrorResponse,
  GetTripDetailsForAdminRequest,
  GetTripDetailsForAdminSuccessResponse
} from './getTripDetailsForAdmin.types'

export const getTripDetailsForAdminApi = async (
  request: GetTripDetailsForAdminRequest
): Promise<GetTripDetailsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getTripDetailsForAdmin, {
      flightItineraryId: request.flightItineraryId
    }),
    method: 'GET',
    params: {
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetTripDetailsForAdminSuccessResponse
  } else {
    throw response as GetTripDetailsForAdminErrorResponse
  }
}

export default getTripDetailsForAdminApi
