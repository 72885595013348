import { useContext, useEffect, useState } from 'react'

import { sortOptions, stopOptions } from '@/pages/FlightSearchResults/FlightSearchResults.dictionary'
import { Box, SelectChangeEvent } from '@mui/material'

import { FlightSearchResultContext } from '@/pages/FlightSearchResults/FlightSearchResults.container'

import FilterDropdown from '../../../../../shared/components/FilterDropdown/FilterDropdown.component'

import styles from './FilterResults.styles'
import { FilterResultsProps } from './FilterResults.types'

const FilterResults = ({ setFilters, availableAirlines }: FilterResultsProps) => {
  const { selectedSearchValues, setSelectedSearchValues } = useContext(FlightSearchResultContext)
  const [selectedAirlines, setSelectedAirlines] = useState<string[]>(selectedSearchValues.airlines)
  const [selectedSort, setSelectedSort] = useState<string>(selectedSearchValues.sort)
  const [selectedStop, setSelectedStop] = useState<string>(selectedSearchValues.stops)

  useEffect(() => {
    setFilters({
      airlines: selectedAirlines.includes('All') ? [] : selectedAirlines,
      sort: selectedSort,
      stops: selectedStop
    })
  }, [selectedAirlines, selectedSort, selectedStop, setFilters])

  const handleAirlineChange = (event: SelectChangeEvent<string | number | (string | number)[]>) => {
    const value = event.target.value as string[]
    let selectedValue
    if (value.includes('All') && value.length > 1) {
      selectedValue = value.filter((airline) => airline !== 'All')
    } else if (value.length === 0) {
      selectedValue = ['All']
    } else {
      selectedValue = value
    }
    setSelectedAirlines(selectedValue)
    setSelectedSearchValues({
      ...selectedSearchValues,
      airlines: selectedValue
    })
  }

  const handleSortChange = (event: SelectChangeEvent<string | number | (string | number)[]>) => {
    setSelectedSort(event.target.value as string)
    setSelectedSearchValues({
      ...selectedSearchValues,
      sort: event.target.value as string
    })
  }

  const handleStopChange = (event: SelectChangeEvent<string | number | (string | number)[]>) => {
    setSelectedStop(event.target.value as string)
    setSelectedSearchValues({
      ...selectedSearchValues,
      stops: event.target.value as string
    })
  }

  const handleResetSort = () => {
    setSelectedSort(sortOptions[0])
  }

  const handleResetAirlines = () => {
    setSelectedAirlines(['All'])
  }

  const handleResetStop = () => {
    setSelectedStop(stopOptions[3])
  }

  return (
    <Box sx={styles.container}>
      {/* {currentSearchValues && (
        <EditSearch searchInfo={currentSearchValues} onEditSearch={() => setEditSearchModalOpen(true)} />
      )}

      <EditSearchModal open={editSearchModalOpen} onClose={() => setEditSearchModalOpen(false)} /> */}

      <FilterDropdown
        label="Sort"
        options={sortOptions}
        selectedValue={selectedSort}
        onChange={handleSortChange}
        resetHandler={handleResetSort}
      />

      <FilterDropdown
        label="Airline"
        options={[...availableAirlines.map((airline) => airline.name), 'All']}
        selectedValue={selectedAirlines}
        onChange={handleAirlineChange}
        multiple
        renderValue={(selected) => (selected as string[]).join(', ')}
        resetHandler={handleResetAirlines}
      />

      <FilterDropdown
        label="Stops"
        options={stopOptions}
        selectedValue={selectedStop}
        onChange={handleStopChange}
        resetHandler={handleResetStop}
      />
    </Box>
  )
}

export default FilterResults
