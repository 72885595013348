import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  widgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.shadows[10],
    borderRadius: '20px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  },

  widgetContainerCompact: (isMobile: boolean) => ({
    padding: theme.spacing(1, 10),
    boxShadow: 'none',
    borderRadius: '0px',
    borderTop: `1.5px solid ${theme.palette.grey[200]}`,
    borderBottom: isMobile ? 'none' : `1.5px solid ${theme.palette.grey[200]}`,
    borderLeft: 'none',
    borderRight: 'none'
  }),

  widgetForm: {
    width: '100%'
  },

  tripDetailsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  tripTypeGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },

  tripTypeGroupCompact: {
    justifyContent: 'flex-start'
  },

  passengerCabinContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    width: '100%',
    gap: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto',
      marginBottom: theme.spacing(1)
    }
  },

  compactSearchIconContainer: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(0.8, 2.5),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'not-allowed'
    }
  },

  compactSearchIcon: {
    fontSize: '32px',
    color: theme.palette.primary.contrastText
  },

  passengerCabinContainerCompact: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  dropdownHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  dropdownIcon: {
    color: theme.palette.grey[500]
  },

  divider: {
    height: 25,
    alignSelf: 'center',
    backgroundColor: theme.palette.grey[400],
    marginRight: theme.spacing(2),
    display: { xs: 'none', md: 'block' }
  },

  multiCityDivider: {
    width: '100%'
  },

  multiCityDividerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5)
  },

  multiCityDividerFlight: {
    fontSize: '20px',
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold'
  },

  multiCityDividerRemove: {
    fontSize: '16px',
    fontWeight: 'bold'
  },

  searchableDropdown: {
    '.MuiAutocomplete-popupIndicator': {
      display: 'none'
    },
    '.MuiAutocomplete-inputRoot': {
      paddingRight: '0 !important'
    },
    '.MuiOutlinedInput-root': {
      padding: '0 !important'
    },
    '.MuiOutlinedInput-input': {
      padding: '0 !important',
      margin: '0 !important'
    }
  },

  searchableInput: {
    textAlign: 'left',

    '& fieldset': {
      border: 'none'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold',
      fontSize: '24px'
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiInputBase-input': {
        fontSize: '16px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiInputBase-input': {
        fontSize: '14px'
      }
    }
  },

  searchableInputCompact: {
    textAlign: 'left',

    '& fieldset': {
      border: 'none'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold',
      fontSize: '16px'
    }
  },

  compactSearchableInputError: {
    border: `1px solid ${theme.palette.error.main}`
  },

  flightIconContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  searchableInputContainer: {},

  compactSearchButtonContainer: {
    display: 'flex'
    // flexDirection: 'row',
    // justifyContent: 'flex-end'
  },

  compactSearchButton: {
    boxShadow: theme.shadows[1]
    // padding: '0px !important',
    // minWidth: 'unset !important',
    // width: '30px',
    // height: '45px',
    // paddingLeft: theme.spacing(4),
    // alignSelf: 'flex-start'
    // height: '30px'
  },

  loadingIcon: {
    marginRight: theme.spacing(5)
  },

  autocompleteOption: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px'
  },

  optionIcon: {
    marginRight: theme.spacing(2),
    fontSize: '20px',
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary.main
    }
  },

  barebonesDropdown: {
    '& fieldset': {
      border: 'none'
    },

    '.MuiSelect-select': {
      padding: 0
    },

    '.MuiOutlinedInput-input': {
      padding: 0
    }
  },

  flightSearchContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },

  searchFieldsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },

  locationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: '3 1 60%',
    gap: theme.spacing(2)
  },

  datesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: '2 1 40%',
    gap: theme.spacing(2)
  },

  field: (error?: string) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(3),
    width: '100%',
    minHeight: '133px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    [theme.breakpoints.down('md')]: {
      minHeight: '56px',
      paddingX: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1),
      border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey[300]}`
    }
  }),

  fieldCompact: (error?: string) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 1.5, 0, 2.5),
    width: '100%',
    maxHeight: '45px !important',
    height: '45px !important',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    border: `1px solid ${error ? theme.palette.error.main : 'transparent'}`
  }),
  label: {
    fontSize: '12px',
    color: theme.palette.grey[500],
    textAlign: 'left',
    marginBottom: theme.spacing(1)
  },

  additionalInfoText: {
    fontSize: '12px',
    textAlign: 'left'
  },

  locationAdditionalInfoText: {
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: 'bold',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },

  countryText: {
    fontWeight: 400
  },

  errorText: {
    fontSize: '12px',
    textAlign: 'left',
    color: theme.palette.error.main
  },

  textField: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
      backgroundColor: theme.palette.common.white
    }
  },

  dateField: {
    width: '100%'
  },

  buttonsContainer: (isMultiCity: boolean) => ({
    display: 'flex',
    justifyContent: isMultiCity ? 'space-between' : 'flex-end',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1)
    }
  }),

  removeIconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },

  removeIconButtonContainer: {
    backgroundColor: '#EDE9FE',
    borderColor: '#C4B5FD',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: -12,
    top: 3
  },

  removeIcon: {
    color: theme.palette.primary.main,
    fontSize: '15px'
  },

  mobileSearchIcon: {
    width: '20px',
    height: '20px'
  },

  desktopSearchIcon: {
    width: '30px',
    height: '30px'
  },

  locationFieldContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(0.5)
    }
  },

  passengerDropdownContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row'
    }
  },

  passengerDropdownText: {
    display: 'flex',
    flexDirection: 'column'
  },

  infoText: (isCompact: boolean) => ({
    fontSize: isCompact ? '16px' : '24px',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }),

  infoSubtitle: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'left',
    width: '100%'
  }
}

export default styles
