import { SxProps } from '@mui/material'

import theme from '../../../../../../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    marginBottom: 2
  },

  title: {
    marginBottom: 1,
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  accordion: {
    marginBottom: 2,
    '&:last-child': { marginBottom: 0 }
  },

  accordionHeaderContent: {
    width: '100%'
  },

  offerRequestFirstRow: {
    display: 'flex',
    alignItems: 'flex-start'
  },

  offerRequestFirstRowChipsContainer: {
    display: 'flex',
    gap: 1
  },

  offerRequestTitle: {
    marginBottom: 1
  },

  shopperInfo: {
    color: 'text.secondary'
  },

  itemsContainer: {
    paddingTop: 2
  },

  itemCard: {
    marginBottom: 2,
    '&:last-child': { marginBottom: 0 }
  },

  skeletonContainer: {
    // marginBottom: 2
  },

  skeletonItem: {
    marginBottom: 2,
    borderRadius: 2
  }
}

export default styles
