import React, { useEffect } from 'react'

import env from '@/networkRequests/apiClient/env.config'
import { Box } from '@mui/material'

import Loader from '../Loader/Loader.component'
import StripeCheckoutForm from '../StripeCheckoutForm/StripeCheckoutForm.component'
import StripeConnectOnboardingForm from '../StripeConnectOnboardingForm/StripeConnectOnboardingForm.component'

import styles from './PaymentDetails.styles'
import { PaymentDetailsProps } from './PaymentDetails.types'

const PaymentDetails: React.FC<PaymentDetailsProps> = (props: PaymentDetailsProps) => {
  const {
    paymentIntent,
    customerSession,
    accountSession,
    paymentIntentLoading,
    handleCreatePaymentIntentRequest,
    setIsPaymentFormValid,
    setPaymentRequestLoading,
    setSetupPaymentLoading,
    paymentFormRef,
    mode,
    currency,
    redirectPath,
    isTransfer,
    setupIntent,
    onOnboardingComplete
  } = props

  useEffect(() => {
    if (!isTransfer) {
      handleCreatePaymentIntentRequest()
    }

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (paymentIntentLoading) {
    return <Loader loading={paymentIntentLoading} text="Getting payment details" />
  } else if (paymentIntent?.id || customerSession?.secret) {
    if (isTransfer && onOnboardingComplete) {
      if (accountSession?.secret) {
        return (
          <Box id="payment-details" sx={styles.container}>
            <StripeConnectOnboardingForm
              accountSessionClientSecret={accountSession?.secret}
              onOnboardingComplete={onOnboardingComplete}
            />
          </Box>
        )
      } else {
        return null
      }
    } else {
      return (
        <Box id="payment-details" sx={styles.container}>
          <StripeCheckoutForm
            mode={mode}
            redirectPath={redirectPath}
            paymentIntentClientSecret={paymentIntent?.secret ?? setupIntent?.secret}
            customerSessionClientSecret={customerSession?.secret}
            currency={currency ?? env.STRIPE_MAIN_CURRENCY}
            setIsPaymentFormValid={setIsPaymentFormValid}
            setPaymentRequestLoading={setPaymentRequestLoading}
            setSetupPaymentLoading={setSetupPaymentLoading}
            paymentFormRef={paymentFormRef!}
          />
        </Box>
      )
    }
  }

  return null
}

export default PaymentDetails
