import React, { useMemo, useState } from 'react'

import { resendEmailVerification } from '@/redux/states/auth/resendEmailVerification/resendEmailVerification.slice'
import { ResendEmailVerificationRequestPayload } from '@/redux/states/auth/resendEmailVerification/resendEmailVerification.types'
import Snackbar from '@/shared/components/Snackbar/Snackbar.functions'
import * as Yup from 'yup'

import Loader from '../../shared/components/Loader/Loader.component'
import StatusUpdatePage from '../../shared/components/StatusUpdatePage/StatusUpdatePage.component'

import { verifyEmail } from '../../redux/states/auth/verifyEmail/verifyEmail.slice'
import { VerifyEmailRequest } from '../../redux/states/auth/verifyEmail/verifyEmail.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import reactHooks from '../../shared/hooks/react.hooks'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'

const { useOnceEffect } = reactHooks

const SignUpVerification: React.FC = () => {
  const { loading, error } = useAppSelector((state) => state.verifyEmail)
  const resendLoading = useAppSelector((state) => state.resendEmailVerification.loading)
  const [userEmail, setUserEmail] = useState<string>('')

  const emailToken = router.getUrlParam('emailToken')
  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const verifyEmailPayload = useMemo(() => {
    if (!emailToken) {
      return null
    } else {
      const verifyEmailRequest: VerifyEmailRequest = { verificationToken: emailToken }

      const onSuccess = () => {
        navigate(routes.login.path, { replace: true })
      }

      return { verifyEmailRequest, onSuccess }
    }
  }, [emailToken, navigate])

  useOnceEffect(() => {
    if (verifyEmailPayload) {
      dispatch(verifyEmail(verifyEmailPayload))
    }
  })

  const onResendEmailVerification = (values: unknown) => {
    const email = (values as { email: string }).email
    setUserEmail(email)

    const payload: ResendEmailVerificationRequestPayload = {
      request: { email },
      onSuccess: () => {
        Snackbar.show({
          message: 'Email sent successfully. Please check your email and click the link to verify your account.',
          severity: 'success'
        })
      }
    }

    dispatch(resendEmailVerification(payload))
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  })

  const getResendVerificationEmailForm = () => {
    return {
      initialValues: { email: userEmail },
      validationSchema,
      formFields: [
        {
          name: 'email',
          label: 'Email Address',
          type: 'email',
          grid: { xs: 12 },
          required: true,
          fullWidth: true,
          autoComplete: 'email'
        }
      ],
      onSubmit: onResendEmailVerification,
      buttonText: 'Resend Verification Email',
      loading: resendLoading
    }
  }

  if (loading) {
    return <Loader loading={loading} size="large" text="Verifying your email..." marginTop={10} />
  } else if (error) {
    return (
      <StatusUpdatePage
        title="Failed to verify email"
        subtitle={error.userFriendlyMessage}
        status="failed"
        form={getResendVerificationEmailForm()}
      />
    )
  } else {
    return null
  }
}

export default SignUpVerification
