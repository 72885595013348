import { ConfigDescription } from './firebase.types'

const configKeys: Record<string, ConfigDescription> = {
  appIcon: { dataType: 'string', description: 'URL for the app icon' },
  testimonials: { dataType: 'array', description: 'Array of testimonials' },
  travelBannerImages: { dataType: 'array', description: 'Array of URLs for the travel banner images' },
  shopBannerImages: { dataType: 'array', description: 'Array of URLs for the shop banner images' },
  validMarketplaceUrls: { dataType: 'array', description: 'Array of valid marketplace URLs' },
  hourrierFee: { dataType: 'number', description: 'Hurrier fee' },
  countries: { dataType: 'array', description: 'Array of countries' },
  countryTaxMap: { dataType: 'array', description: 'Map of country codes to tax rates' },
  countryMarketplaceMap: { dataType: 'array', description: 'Map of country codes to marketplace URLs' },
  offerRequestsCount: { dataType: 'number', description: 'Number of offer requests' },
  termsOfServices: { dataType: 'string', description: 'Terms of services' },
  privacyPolicy: { dataType: 'string', description: 'Privacy policy' },
  trustAndSafety: { dataType: 'string', description: 'Trust and safety' },
  faqs: { dataType: 'string', description: 'FAQs' },
  deliveryPolicy: { dataType: 'string', description: 'Delivery policy' },
  cookiePolicy: { dataType: 'string', description: 'Cookie policy' }
}

const firebaseDictionary = {
  remoteConfigVariables: configKeys
}

export default firebaseDictionary
