import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import { setAdminLoginStatus } from '../../../../shared/functions/Auth/auth.functions'
import { setAuthenticationStatus } from '../../../../shared/functions/Auth/auth.functions'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole, setUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { loginApi } from './login.api'
import loginInitialState from './login.initialState'
import { LoginErrorResponse, LoginRequest } from './login.types'

export const login = createAsyncThunk(
  urls.auth.login,
  async ({ loginRequest, onSuccess }: { loginRequest: LoginRequest; onSuccess: () => void }, { rejectWithValue }) => {
    try {
      const response = await loginApi(loginRequest)
      const userRoles = response.data.roles
      const storedUserRole = getUserRole()

      setAuthenticationStatus(true)

      if (userRoles && userRoles.includes(UserRole.admin)) {
        setAdminLoginStatus(true)
      } else if (storedUserRole === null || storedUserRole === undefined) {
        setUserRole(UserRole.traveler)
      }

      onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const loginSlice = createSlice({
  name: urls.auth.login,
  initialState: loginInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as LoginErrorResponse
        state.success = null
      })
  }
})

export const { actions: loginActions, reducer: loginReducer } = loginSlice
export default loginSlice.reducer
