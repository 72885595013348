import React from 'react'

import { Box, Chip, Skeleton, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import Accordion from '../../../../../../../../../../shared/components/Accordion/Accordion.component'
import EmptyState from '../../../../../../../../../../shared/components/EmptyState/EmptyState.component'
import ItemRequestCard from '../../../../../UserDetails/components/OrderDetails/components/ItemRequestCard/ItemRequestCard.component'

import { getTripOfferRequestsForAdmin } from '../../../../../../../../../../redux/states/admin/trips/getTripOfferRequestsForAdmin/getTripOfferRequestsForAdmin.slice'
import { GetTripOfferRequestsForAdminRequestPayload } from '../../../../../../../../../../redux/states/admin/trips/getTripOfferRequestsForAdmin/getTripOfferRequestsForAdmin.types'
import { RootState } from '../../../../../../../../../../redux/store/store.types'
import status from '../../../../../../../../../../shared/functions/Status/status.dictionaries'
import {
  formatMoney,
  joinElementsWithBullet,
  joinStringsWithBullet
} from '../../../../../../../../../../shared/functions/String/string.functions'
import { useAppDispatch } from '../../../../../../../../../../shared/hooks/redux.hooks'
import linkStyles from '../../../../../../../../../../shared/styles/link.styles'
import styles from './OfferRequestsAccordion.styles'
import { OfferRequestsAccordionProps } from './OfferRequestsAccordion.types'

const OfferRequestsAccordion: React.FC<OfferRequestsAccordionProps> = (props) => {
  const { tripId } = props

  const dispatch = useAppDispatch()
  const { loading, success, error } = useSelector((state: RootState) => state.getTripOfferRequestsForAdmin)

  const handleReload = () => {
    const payload: GetTripOfferRequestsForAdminRequestPayload = {
      request: { flightItineraryId: tripId, page: 1, size: 10 }
    }

    dispatch(getTripOfferRequestsForAdmin(payload))
  }

  if (loading) {
    return (
      <Box sx={styles.skeletonContainer}>
        <Skeleton variant="text" width="40%" height={45} />
        <Skeleton variant="rectangular" height={65} sx={styles.skeletonItem} />
      </Box>
    )
  } else if (error) {
    return (
      <EmptyState
        title="Failed to load offer requests"
        button={{ text: 'Try Again', onClick: handleReload }}
        errorId={error.correlationId}
      />
    )
  } else if (success?.data.offerRequests) {
    const offerRequests = success.data.offerRequests
    let content = null

    const renderOfferRequests = () => {
      return offerRequests.map((offerRequest) => {
        const formattedAmazonCredit = formatMoney(offerRequest.totalItemsCost, offerRequest.currency)
        const formattedTravelerBenefit = formatMoney(offerRequest.totalTravelerBenefit, offerRequest.currency)

        const chips: { label: string; value?: number; color: 'success' | 'error' }[] = [
          { label: `Credit: ${formattedAmazonCredit}`, value: offerRequest.totalItemsCost, color: 'success' },
          { label: `Benefit: ${formattedTravelerBenefit}`, value: offerRequest.totalTravelerBenefit, color: 'success' }
        ]

        const areAllItemRequestsCancelled = offerRequest.itemRequests.every(
          (itemRequest) =>
            itemRequest.status.name === status.itemRequestStatus.cancelledByShopper ||
            itemRequest.status.name === status.itemRequestStatus.deniedByTraveler
        )

        if (areAllItemRequestsCancelled) {
          chips.length = 0
          chips.push({ label: `Cancelled`, color: 'error' })
        }

        const getOfferRequestChips = () => {
          return (
            <Box sx={styles.offerRequestFirstRowChipsContainer}>
              {chips.map((chip) => {
                if ((chip.value && chip.value > 0) || chip.value === undefined) {
                  return <Chip label={chip.label} color={chip.color} size="small" />
                }

                return null
              })}
            </Box>
          )
        }

        return (
          <Accordion
            key={offerRequest.id}
            header={{
              mainContent: (
                <Box sx={styles.accordionHeaderContent}>
                  <Box sx={styles.offerRequestFirstRow}>
                    <Typography variant="body1" sx={styles.offerRequestTitle}>
                      {joinStringsWithBullet(
                        [`Order #${offerRequest.id}`, `${offerRequest.itemRequests.length}`],
                        true
                      )}
                    </Typography>

                    {getOfferRequestChips()}
                  </Box>

                  <Typography variant="caption" sx={styles.shopperInfo}>
                    {joinElementsWithBullet([
                      `${offerRequest.shopper.firstName} ${offerRequest.shopper.lastName}`,
                      `${offerRequest.shopper.id}`,
                      <a href={`mailto:${offerRequest.shopper.email}`} key="email" style={linkStyles}>
                        {offerRequest.shopper.email}
                      </a>
                    ])}
                  </Typography>
                </Box>
              )
            }}
            body={
              <Box sx={styles.itemsContainer}>
                {offerRequest.itemRequests.map((itemRequest) => (
                  <ItemRequestCard key={itemRequest.id} itemRequest={itemRequest} />
                ))}
              </Box>
            }
          />
        )
      })
    }

    if (offerRequests.length > 0) {
      content = renderOfferRequests()
    } else {
      content = <EmptyState title="No offer requests found" />
    }

    return (
      <Box sx={styles.container}>
        <Typography variant="subtitle1" sx={styles.title}>
          {joinElementsWithBullet(['Orders', offerRequests.length.toString()])}
        </Typography>

        {content}
      </Box>
    )
  } else {
    return null
  }
}

export default OfferRequestsAccordion
