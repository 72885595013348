import React, { useEffect } from 'react'

import theme from '@/shared/styles/themes/default.theme'
import { ReactComponent } from '@/shared/types/react.types'
import { Box, CircularProgress, Button as MuiButton, Tooltip, useMediaQuery } from '@mui/material'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'

import logger from '../../functions/Logger/logger.functions'
import styles from './Button.styles'
import { ButtonProps } from './Button.types'

const Button: ReactComponent<ButtonProps & MuiButtonProps> = ({
  buttonType,
  icon,
  iconPosition = 'start',
  isNav = false,
  isActiveNav = false,
  text,
  altText,
  outline,
  inverted,
  animateIconOnPress = false,
  disabled = false,
  loading = false,
  fullWidth = false,
  large = false,
  marginTop = '0px',
  onClick,
  tooltipText,
  style,
  containerStyle,
  hideTextOnLoading = false,
  testId,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [animate, setAnimate] = React.useState(false)

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const onlyShowLoaderWhenLoading = (hideTextOnLoading === true || isMobile) && isLoading

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonName =
      altText?.toLowerCase().replace(/\s+/g, '_') || text?.toLowerCase().replace(/\s+/g, '_') || 'button'

    if (animateIconOnPress) {
      setAnimate((prev) => !prev)
    }

    logger.logEvent(`${buttonName}_button_clicked`)

    if (onClick) onClick(event)
  }

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const getVariant = () => {
    if (buttonType === 'quaternary') return 'outlined'

    return outline ? 'outlined' : 'contained'
  }

  return (
    <Tooltip
      style={containerStyle}
      title={tooltipText || ''}
      disableHoverListener={!tooltipText}
      disableFocusListener={!tooltipText}
      arrow
    >
      <Box sx={styles.buttonContainer(fullWidth)}>
        <MuiButton
          variant={getVariant()}
          color={buttonType}
          startIcon={iconPosition === 'start' && icon && <Box sx={styles.iconWrapper(animate)}>{icon}</Box>}
          endIcon={iconPosition === 'end' && icon && <Box sx={styles.iconWrapper(animate)}>{icon}</Box>}
          disabled={disabled || isLoading}
          onClick={handleClick}
          sx={styles.button(isNav, isActiveNav, large, marginTop, outline, inverted, buttonType)}
          fullWidth={fullWidth}
          style={style}
          role="button"
          data-testid={testId}
          {...props}
        >
          {icon && iconPosition === 'center' && !onlyShowLoaderWhenLoading && (
            <Box sx={styles.iconWrapper(animate)}>{icon}</Box>
          )}

          {text && !onlyShowLoaderWhenLoading && <>{text}</>}
          {isLoading && <CircularProgress size={24} sx={styles.loading(hideTextOnLoading)} />}
        </MuiButton>
      </Box>
    </Tooltip>
  )
}

export default Button
