import { useCallback, useEffect, useMemo, useState } from 'react'

import { CabinClass } from '@/shared/functions/Flights/flights.types'
import useResponsiveness from '@/shared/hooks/responsive.hooks'
import { ReactComponent } from '@/shared/types/react.types'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, CircularProgress, Grid, Link, SvgIcon, Typography } from '@mui/material'

import TripPreferencesModal from '../../../../shared/components/TripPreferencesModal/TripPreferencesModal.component'
import FilterResults from './FilterResults/FilterResults.component'
import FlightOffer from './FlightOffer/FlightOffer.component'
import SelectedFlightOffer from './SelectedFlightOffer/SelectedFlightOffer.component'
import ResponsiveDisplayWrapper from '@/shared/components/ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'

import images from '../../../../assets/images'
import { getFlightFares } from '../../../../redux/states/flights/getFlightFares/getFlightFares.slice'
import { GetFlightFaresRequestPayload } from '../../../../redux/states/flights/getFlightFares/getFlightFares.types'
import {
  FareDetailsResponse,
  FareOffer
} from '../../../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { getNextFlights } from '../../../../redux/states/flights/getNextFlights/getNextFlights.slice'
import { SearchFlightsSuccessResponse } from '../../../../redux/states/flights/searchFlights/searchFlights.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { formatMoney } from '../../../../shared/functions/String/string.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import theme from '../../../../shared/styles/themes/default.theme'
import { formatFlightPartialOffers } from '../../FlightSearchResults.functions'
import { FormattedPartialOffer, Route } from '../../types/FormattedSearchResults.types'
import { AirlineOption, FilterOptions } from './FilterResults/FilterResults.types'
import styles from './TripSelector.styles'
import { TripSelectorProps } from './TripSelector.types'

// Todo: Clear selectedOffers when new search is made
const TripSelector: ReactComponent<TripSelectorProps> = (props, _ref) => {
  const {
    routes: flightRoutes,
    offers,
    onComplete,
    initialBaseOfferId,
    isLoadingInitialFlightSearch,
    searchedValues,
    existingFlightItinerary,
    hideFilters,
    hideFlights,
    setRouteIndex
  } = props

  const [selectedOffers, setSelectedOffers] = useState<FormattedPartialOffer[]>([])
  const [currentRouteIndex, setCurrentRouteIndex] = useState<number>(selectedOffers.length)
  const [currentOffers, setCurrentOffers] = useState<FormattedPartialOffer[]>(offers)
  const [baseOfferId, setBaseOfferId] = useState<string | undefined>(initialBaseOfferId)
  const [filters, setFilters] = useState<FilterOptions>({ airlines: [], sort: '', stops: '' })
  const [availableAirlines, setAvailableAirlines] = useState<AirlineOption[]>([])
  const [tripPreferencesModalOpen, setTripPreferencesModalOpen] = useState<boolean>(false)
  const [loadingOfferId, setLoadingOfferId] = useState<string>('')
  const [fare, setFare] = useState<FareOffer | null>(null)
  const [isDesktop] = useResponsiveness()

  const [history, setHistory] = useState<{
    [key: number]: { route: Route; offers: FormattedPartialOffer[]; selectedOffers: FormattedPartialOffer[] }
  }>([])
  const { success: response, loading: isLoadingNextFlights } = useAppSelector(
    (state: RootState) => state.getNextFlights
  )
  const { loading: isLoadingFareDetails } = useAppSelector((state: RootState) => state.getFlightFares)

  const [expandedOfferId, setExpandedOfferId] = useState<string | null>(null)

  useEffect(() => {
    setSelectedOffers([])
    setHistory({})
    setCurrentRouteIndex(0)
    setRouteIndex?.(0)
  }, [initialBaseOfferId]) // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    setBaseOfferId(initialBaseOfferId)
  }, [initialBaseOfferId])

  useEffect(() => {
    setCurrentOffers(offers)
  }, [offers])

  useEffect(() => {
    if (!response) return

    const { offers } = formatFlightPartialOffers(response as unknown as SearchFlightsSuccessResponse)

    setCurrentOffers(offers)
  }, [response])

  useEffect(() => {
    const airlineMap = new Map<string, number>()

    currentOffers.forEach((offer) => {
      const totalAmount = Number(offer.totalAmount)

      if (!airlineMap.has(offer.airline) || totalAmount < airlineMap.get(offer.airline)!) {
        airlineMap.set(offer.airline, totalAmount)
      }
    })

    const uniqueAirlines = Array.from(airlineMap.entries()).map(([name, lowestPrice]) => ({ name, lowestPrice }))

    setAvailableAirlines(uniqueAirlines)
  }, [setAvailableAirlines, currentOffers])

  const onSelectFlightOffer = useCallback(
    (offer: FormattedPartialOffer) => () => {
      if (!baseOfferId) return

      setHistory((prev) => ({
        ...prev,
        [currentRouteIndex]: { route: flightRoutes[currentRouteIndex], offers: currentOffers, selectedOffers }
      }))

      if (currentRouteIndex < flightRoutes.length - 1) {
        setSelectedOffers((prev) => {
          const offerExists = prev.some((existingOffer) => existingOffer.id === offer.id)

          return offerExists ? prev : [...prev, offer]
        })

        const requestPayload = {
          getNextFlightsRequest: {
            outboundOfferId: baseOfferId,
            selected_partial_offers: [...selectedOffers.map((offer) => offer.id), offer.id]
          },

          onSuccess: () => {
            setCurrentRouteIndex((prev) => prev + 1)
            setRouteIndex?.(currentRouteIndex + 1)
            // scroll to top
            window.scrollTo(0, 0)
          }
        }

        dispatch(getNextFlights(requestPayload))
      } else {
        setLoadingOfferId(offer.id)

        const payload: GetFlightFaresRequestPayload = {
          request: {
            outboundOfferId: baseOfferId,
            selected_partial_offers: [...selectedOffers.map((offer) => offer.id), offer.id]
          },

          onSuccess: (response: FareDetailsResponse) => {
            const fare = response.offers[0]
            fare.cabinClass = response.cabin_class as CabinClass

            setFare(fare)

            if (!existingFlightItinerary) {
              setTripPreferencesModalOpen(true)
            } else {
              navigate(routes.tripSummary.path, {
                state: {
                  fare,
                  searchedValues,
                  existingFlightItinerary,
                  isBookingFlight: existingFlightItinerary!
                }
              })
            }
          }
        }

        dispatch(getFlightFares(payload))
      }
    },
    [
      dispatch,
      baseOfferId,
      currentRouteIndex,
      flightRoutes,
      selectedOffers,
      currentOffers,
      existingFlightItinerary,
      navigate,
      searchedValues,
      setRouteIndex
    ]
  )

  const changeSelectedFlight = useCallback(
    (index: number) => {
      setCurrentRouteIndex(index)
      setRouteIndex?.(index)
      setCurrentOffers(history[index].offers)
      setSelectedOffers(history[index].selectedOffers)
    },
    [history, setCurrentOffers, setRouteIndex, setSelectedOffers]
  )

  const breadcrumbs = useMemo(() => {
    return flightRoutes.map((route, index) => {
      if (currentRouteIndex > index) {
        return (
          <Link
            underline="hover"
            key={index}
            color="inherit"
            sx={styles.activeBreadcrumb}
            onClick={() => changeSelectedFlight(index)}
          >
            {route?.origin.iata_code} to {route?.destination.iata_code}
          </Link>
        )
      } else {
        return (
          <Link underline={'none'} key={index} color="inherit" sx={styles.inactiveBreadcrumb}>
            {route?.origin.iata_code} to {route?.destination.iata_code}
          </Link>
        )
      }
    })
  }, [flightRoutes, currentRouteIndex, changeSelectedFlight])

  const HeaderView = useMemo(() => {
    if (flightRoutes.length === 0) return null

    return (
      <Typography variant="h5" sx={styles.title}>
        <ResponsiveDisplayWrapper fullWidth isDesktop={true}>
          Choose your flight from {flightRoutes[currentRouteIndex]?.origin.iata_code} to{' '}
          {flightRoutes[currentRouteIndex]?.destination.iata_code}
        </ResponsiveDisplayWrapper>

        <ResponsiveDisplayWrapper fullWidth isDesktop={false}>
          Choose your departing flight
        </ResponsiveDisplayWrapper>
      </Typography>
    )
  }, [flightRoutes, currentRouteIndex])

  const showLoadingDetails = () => {
    function GradientCircularProgress() {
      return (
        <Box sx={styles.loadingContainer}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: theme.palette.primary.light,
              opacity: 0.5
            }}
            size={isDesktop ? 200 : 100}
            thickness={1}
            {...props}
            value={100}
          />

          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor={theme.palette.primary.main} />
                <stop offset="100%" stopColor={theme.palette.primary.light} />
              </linearGradient>
            </defs>
          </svg>

          <CircularProgress sx={styles.loadingProgress} size={isDesktop ? 200 : 100} thickness={2} />

          <Box sx={styles.loadingFlightContainer}>
            <SvgIcon component={images.PlaneIcon} sx={styles.loadingFlightIcon} />

            {/* <Typography sx={styles.loadingFlightText}>
              {flightRoutes[currentRouteIndex]?.origin.iata_code} to{' '}
              {flightRoutes[currentRouteIndex]?.destination.iata_code}
            </Typography> */}
          </Box>
        </Box>
      )
    }

    return (
      <Box sx={styles.loadingDetails}>
        <GradientCircularProgress />

        <Box sx={styles.loadingDetailsContent}>
          <Typography variant={isDesktop ? 'h4' : 'subtitle1'} sx={styles.loadingTitle}>
            We are finding your flight!
          </Typography>

          <Typography variant={isDesktop ? 'h6' : 'caption'} sx={styles.loadingSubtitle}>
            This may take a few seconds
          </Typography>
        </Box>
      </Box>
    )
  }

  const isLoadingFlights = useMemo(() => {
    return isLoadingInitialFlightSearch || isLoadingNextFlights
  }, [isLoadingInitialFlightSearch, isLoadingNextFlights])

  const filteredOffers = useMemo(() => {
    const filtered = currentOffers.filter((offer) => {
      if (filters.airlines.length > 0 && !filters.airlines.includes(offer.airline)) return false

      const stopCount = offer.slices[0].segments.length - 1

      if (filters.stops === 'Direct only' && stopCount !== 0) return false
      if (filters.stops === '1 stop at most' && stopCount > 1) return false
      if (filters.stops === '2 stops at most' && stopCount > 2) return false

      return true
    })

    switch (filters.sort) {
      case 'Cheapest':
        return filtered.sort((a, b) => Number(a.totalAmount) - Number(b.totalAmount))

      case 'Fastest':
        return filtered.sort((a, b) => {
          const aDuration = a.slices.reduce((acc, slice) => acc + parseInt(slice.totalDuration), 0)
          const bDuration = b.slices.reduce((acc, slice) => acc + parseInt(slice.totalDuration), 0)

          return aDuration - bDuration
        })

      case 'Best':
        // Assuming 'Best' is the default sorting or some custom logic
        return filtered

      default:
        return filtered
    }
  }, [currentOffers, filters])

  const getNumberOfStops = (offer: FareOffer) => {
    return Math.max(...offer.slices.map((slice) => slice.segments.length - 1))
  }

  const onSelectTripPreferences = (preferences: { price: number; stops: number }) => {
    if (onComplete) {
      onComplete(fare as FareOffer, preferences)
      setCurrentRouteIndex(currentRouteIndex - 1)
      setRouteIndex?.(currentRouteIndex - 1)
      setCurrentOffers(history[currentRouteIndex - 1].offers)
      setSelectedOffers(history[currentRouteIndex - 1].selectedOffers)
    } else {
      navigate(routes.tripSummary.path, {
        state: {
          fare,
          searchedValues,
          tripPreferences: preferences,
          existingFlightItinerary,
          isBookingFlight: existingFlightItinerary!
        }
      })
    }
  }

  const getDisplayAmount = useCallback(
    (selectedOffer: FormattedPartialOffer) => {
      if (currentRouteIndex === 0) {
        return `${formatMoney(Number(selectedOffer.totalAmount), selectedOffer.currency)}` // Display total amount for the first route
      } else {
        const firstOfferTotal = selectedOffers[0]?.totalAmount || 0 // Get the total amount of the first selected offer
        const difference = Number(selectedOffer.totalAmount) - Number(firstOfferTotal)
        const formattedDifference = difference === 0 ? '0' : Math.abs(difference).toFixed(2)

        return `+${formatMoney(formattedDifference, selectedOffer.currency)}`
      }
    },
    [currentRouteIndex, selectedOffers]
  )

  const showMainContent = () => {
    const getBreadcrumbs = () => {
      return (
        <Breadcrumbs
          sx={styles.breadcrumbs}
          separator={<NavigateNextIcon fontSize="small" sx={styles.breadcrumbsSeparator} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      )
    }

    return (
      <Grid container id="trip-selector-main-container">
        <Grid sx={styles.tripSelectorContainer} gap={3}>
          {!hideFilters && (
            <Grid item xs={12} sm={3}>
              <FilterResults setFilters={setFilters} availableAirlines={availableAirlines} />
            </Grid>
          )}

          <Box sx={styles.selectedOffersContainer}>
            {selectedOffers.map((selectedOffer, index) => (
              <SelectedFlightOffer
                key={selectedOffer.id}
                offer={selectedOffer}
                stopCount={selectedOffer.slices[0].segments.length - 1}
                change={() => changeSelectedFlight(index)}
              />
            ))}
          </Box>

          {!hideFlights && (
            <Grid item xs={12} sm={9}>
              {getBreadcrumbs()}

              {!isLoadingFlights && flightRoutes.length > 0 && (
                <Box sx={styles.header}>
                  {HeaderView}

                  <Typography variant="body2" sx={styles.subtitle}>
                    Prices are shown in {fare?.base_currency ?? 'USD'} currency.
                  </Typography>
                </Box>
              )}

              {isLoadingFlights && showLoadingDetails()}

              {!isLoadingFlights && (
                <Box sx={styles.offersContainer}>
                  {filteredOffers.map((offer) => (
                    <FlightOffer
                      key={offer.id}
                      onSelect={onSelectFlightOffer(offer)}
                      isLoading={isLoadingFareDetails && loadingOfferId === offer.id}
                      duration={offer.slices[0].totalDuration}
                      stopCount={offer.slices[0].segments.length - 1}
                      airlineLogo={offer.airlineLogo}
                      originAirportName={offer.slices[0]?.origin.iata_code}
                      destinationAirportName={offer.slices[offer.slices.length - 1].destination.iata_code}
                      departureTime={offer.slices[0].segments[0].departure.time}
                      arrivalTime={offer.slices[offer.slices.length - 1].segments[0].arrival.time}
                      cost={getDisplayAmount(offer)}
                      currency={offer.currency}
                      travelClass={offer.travelClass}
                      offer={offer}
                      expanded={expandedOfferId === offer.id}
                      onExpand={() => setExpandedOfferId(expandedOfferId === offer.id ? null : offer.id)}
                    />
                  ))}
                </Box>
              )}
            </Grid>
          )}
        </Grid>

        <TripPreferencesModal
          open={tripPreferencesModalOpen}
          onSubmit={onSelectTripPreferences}
          onClose={() => setTripPreferencesModalOpen(false)}
          minimumStops={fare ? getNumberOfStops(fare) : 0}
          flightTotalPrice={fare ? parseFloat(fare.total_amount) : 0}
        />
      </Grid>
    )
  }

  return <Box sx={styles.container}>{showMainContent()}</Box>
}

export default TripSelector
