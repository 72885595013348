import theme from '@/shared/styles/themes/default.theme'
import { Theme } from '@mui/material'

const styles = {
  chatWindowContainer: (theme: Theme) => ({
    position: 'fixed',
    bottom: 10,
    right: 80,
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    zIndex: 1200,
    transition: 'all 0.3s ease',
    maxHeight: '70vh'
  }),

  chatWindowContainerCollapsed: {
    height: 'auto',
    width: 320
  },

  chatWindowContainerExpanded: {
    height: 450
  },

  chatHeader: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    cursor: 'pointer'
  }),

  headerInfo: {
    display: 'flex',
    flexDirection: 'column'
  },

  headerTitle: {
    fontSize: '0.9rem',
    fontWeight: 600,
    marginBottom: '2px',
    color: theme.palette.primary.contrastText
  },

  headerSubtitle: {
    fontSize: '0.75rem',
    opacity: 0.8,
    color: theme.palette.primary.contrastText
  },

  headerActions: {
    display: 'flex',
    alignItems: 'center'
  },

  iconButton: {
    padding: '4px',
    color: 'inherit'
  },

  messagesContainer: {
    flexGrow: 1,
    padding: '10px 15px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },

  messageWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },

  messageSent: (theme: Theme) => ({
    alignSelf: 'flex-end',
    maxWidth: '75%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '12px 12px 0 12px',
    padding: '8px 12px',
    wordBreak: 'break-word'
  }),

  messageReceived: (theme: Theme) => ({
    alignSelf: 'flex-start',
    maxWidth: '75%',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    borderRadius: '12px 12px 12px 0',
    padding: '8px 12px',
    wordBreak: 'break-word'
  }),

  messageTime: {
    fontSize: '0.7rem',
    marginTop: '4px',
    opacity: 0.7,
    textAlign: 'right'
  },

  messageTimeSent: {
    alignSelf: 'flex-end'
  },

  messageTimeReceived: {
    alignSelf: 'flex-start'
  },

  inputContainer: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderTop: `1px solid ${theme.palette.divider}`
  }),

  textField: {
    flexGrow: 1
  },

  sendButton: {
    marginLeft: '8px'
  },

  noMessagesContainer: {
    padding: '10px',
    backgroundColor: '#f5f5f5',
    borderRadius: '5px'
  },

  noMessagesText: {
    fontSize: '0.8rem',
    color: '#666'
  }
}

export default styles
