import env from '@/networkRequests/apiClient/env.config'

import logger from '../Logger/logger.functions'
import { EventName, EventParams } from './metaPixel.types'

/**
 * Initialize Facebook Pixel
 * Note: This is already handled in the index.html file
 */
export const initFBPixel = (): void => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq('init', env.META_PIXEL_ID)
  }
}

/**
 * Track a standard Facebook Pixel event
 * @param eventName - Name of the standard event
 * @param params - Optional parameters for the event
 */
export const trackEvent = (eventName: EventName, params?: EventParams): void => {
  try {
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('track', eventName, params)
    }
  } catch (error) {
    logger.logError(error, `Error tracking Facebook Pixel event "${eventName}"`, 'MetaPixel')
  }
}

/**
 * Track a custom Facebook Pixel event
 * @param eventName - Name of the custom event
 * @param params - Optional parameters for the event
 */
export const trackCustomEvent = (eventName: string, params?: EventParams): void => {
  try {
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('trackCustom', eventName, params)
    }
  } catch (error) {
    logger.logError(error, `Error tracking custom Facebook Pixel event "${eventName}"`, 'MetaPixel')
  }
}

// Common event tracking functions for frequently used events
export const trackPageView = () => trackEvent('PageView')
export const trackPurchase = (params: EventParams) => trackEvent('Purchase', params)
export const trackLead = (params?: EventParams) => trackEvent('Lead', params)
export const trackCompleteRegistration = (params?: EventParams) => trackEvent('CompleteRegistration', params)
export const trackInitiateCheckout = (params?: EventParams) => trackEvent('InitiateCheckout', params)

export default {
  logEvent: trackCustomEvent
}
