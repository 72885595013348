import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: {
      xs: theme.spacing(0, 3, 3, 3),
      md: theme.spacing(0, 10, 5, 10)
    },
    borderTop: '1px solid #e0e0e0'
  } as SxProps,

  container: {
    marginBottom: { xs: theme.spacing(3), md: theme.spacing(10) },
    marginTop: { xs: theme.spacing(3), md: theme.spacing(10) },
    paddingBottom: { xs: theme.spacing(1), md: theme.spacing(0) }
  },

  hourrierLogo: {
    width: '125px',
    marginBottom: theme.spacing(2),
    flex: 1
  },

  sectionContainer: {
    paddingBottom: { xs: theme.spacing(2), md: theme.spacing(0) }
  },

  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  } as SxProps,

  form: {
    display: { xs: 'flex', sm: 'inline-block', md: 'inline-block', lg: 'inline-block' },
    alignItems: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2)
  } as SxProps,

  textField: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    }
  } as SxProps,

  icon: {
    marginRight: theme.spacing(1),
    width: 12,
    height: 12
  },

  sectionItemContainer: {
    paddingBottom: { xs: theme.spacing(1), md: theme.spacing(2) }
  },

  link: {
    color: theme.palette.text.secondary
  },

  copyright: {
    marginTop: theme.spacing(5)
  }
}

export default styles
