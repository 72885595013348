import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  ResendEmailVerificationErrorResponse,
  ResendEmailVerificationRequest,
  ResendEmailVerificationSuccessResponse
} from './resendEmailVerification.types'

export const resendEmailVerificationApi = async (
  request: ResendEmailVerificationRequest
): Promise<ResendEmailVerificationSuccessResponse> => {
  const response = await configureRequest({
    url: urls.auth.resendEmailVerification,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ResendEmailVerificationSuccessResponse
  } else {
    throw response as ResendEmailVerificationErrorResponse
  }
}

export default resendEmailVerificationApi
