import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../../shared/types/api.types'
import { getTripDetailsForAdminApi } from './getTripDetailsForAdmin.api'
import getTripDetailsForAdminInitialState from './getTripDetailsForAdmin.initialState'
import {
  GetTripDetailsForAdminErrorResponse,
  GetTripDetailsForAdminRequestPayload
} from './getTripDetailsForAdmin.types'

export const getTripDetailsForAdmin = createAsyncThunk(
  urls.admin.getTripDetailsForAdmin,
  async (payload: GetTripDetailsForAdminRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getTripDetailsForAdminApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getTripDetailsForAdminSlice = createSlice({
  name: urls.admin.getTripDetailsForAdmin,
  initialState: getTripDetailsForAdminInitialState,
  reducers: {
    resetGetTripDetailsForAdmin: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTripDetailsForAdmin.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getTripDetailsForAdmin.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getTripDetailsForAdmin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetTripDetailsForAdminErrorResponse
        state.success = null
      })
  }
})

export const { actions: getTripDetailsForAdminActions, reducer: getTripDetailsForAdminReducer } =
  getTripDetailsForAdminSlice
export const { resetGetTripDetailsForAdmin } = getTripDetailsForAdminActions
export default getTripDetailsForAdminSlice.reducer
