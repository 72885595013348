import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../shared/styles/themes/default.theme'

const styles = {
  container: (marginTop?: number) =>
    ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px dashed ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(3),
      gap: theme.spacing(1),
      padding: theme.spacing(6, 2),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        paddingX: 0
      },
      marginTop
    }) as SxProps,

  icon: {
    color: theme.palette.text.secondary
  } as CSSProperties,

  textContainer: (hasCorrelationId: boolean) =>
    ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
      flex: 1,
      marginBottom: theme.spacing(hasCorrelationId ? 5 : 2),
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }) as SxProps,

  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1)
  } as SxProps,

  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  } as SxProps,

  correlationId: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    textAlign: 'center',
    color: theme.palette.text.disabled,
    maxWidth: '100%',
    overflowWrap: 'break-word'
  } as SxProps
}

export default styles
