import { CSSProperties } from 'react'

import theme from '../../../../../../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    gap: 3,
    display: 'flex',
    flexDirection: 'column'
  },

  contentSection: {},

  contentSectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600
  },

  nestedTable: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius
  } as CSSProperties,

  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  } as CSSProperties
}

export default styles
