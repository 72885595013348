import { TripType } from '@/shared/functions/Flights/flights.types'

import { FlightItinerary } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { FlightItineraryLeg } from '../../shared/functions/Places/places.types'
import { joinStringsWithBullet } from '../../shared/functions/String/string.functions'

export const getFormattedTripDetails = (
  legs: FlightItineraryLeg[],
  tripType: TripType,
  shortFormat: boolean = false
): string => {
  const firstLeg = legs[0]
  let tripDetails = ''

  switch (tripType) {
    case 'oneWay':
      if (shortFormat) {
        tripDetails = `${firstLeg.origin.airport.iataCode} → ${firstLeg.destination.airport.iataCode}`
      } else {
        tripDetails = `${firstLeg.origin.city.name} (${firstLeg.origin.airport.iataCode}) → ${firstLeg.destination.city.name} (${firstLeg.destination.airport.iataCode})`
      }
      break

    case 'roundTrip':
      if (shortFormat) {
        tripDetails = `${firstLeg.origin.airport.iataCode} ⇌ ${firstLeg.destination.airport.iataCode}`
      } else {
        tripDetails = `${firstLeg.origin.city.name} (${firstLeg.origin.airport.iataCode}) ⇌ ${firstLeg.destination.city.name} (${firstLeg.destination.airport.iataCode})`
      }
      break

    case 'multiCity':
      if (shortFormat) {
        tripDetails = legs
          .map((leg) => `${leg.origin.airport.iataCode} → ${leg.destination.airport.iataCode}`)
          .join('\u00A0 • \u00A0')
      } else {
        tripDetails = legs
          .map(
            (leg) =>
              `${leg.origin.city.name} (${leg.origin.airport.iataCode}) → ${leg.destination.city.name} (${leg.destination.airport.iataCode})`
          )
          .join('\u00A0 • \u00A0')
      }
      break
  }

  return tripDetails
}

export const getFormattedTripDetailsMobile = (legs: FlightItineraryLeg[], tripType: TripType): string => {
  const firstLeg = legs[0]
  let tripDetails = ''

  switch (tripType) {
    case 'oneWay':
      tripDetails = `${firstLeg.origin.airport.iataCode} → ${firstLeg.destination.airport.iataCode}`
      break

    case 'roundTrip':
      tripDetails = `${firstLeg.origin.airport.iataCode} ⇌ ${firstLeg.destination.airport.iataCode}`
      break

    case 'multiCity':
      tripDetails = legs
        .map((leg) => `${leg.origin.airport.iataCode} → ${leg.destination.airport.iataCode}`)
        .join('\u00A0 • \u00A0')
      break
  }

  return tripDetails
}

export const getFormattedTravelerDateDetails = (flightItinerary: FlightItinerary): string => {
  const firstLeg = flightItinerary.itinerary.legs[0]
  const lastLeg = flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1]
  const tripType = flightItinerary.itinerary.tripType
  let dateDetails = ''

  if (tripType === 'oneWay') {
    dateDetails = joinStringsWithBullet([
      `Departing: ${date(firstLeg.travelDate).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  if (tripType === 'roundTrip') {
    dateDetails = joinStringsWithBullet([
      `Departing: ${date(firstLeg.travelDate).format(dateTimeFormats.date.medium)}`,
      `Returning: ${date(lastLeg.travelDate).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  if (tripType === 'multiCity') {
    dateDetails = joinStringsWithBullet([
      `Departing: ${date(firstLeg.travelDate).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  return dateDetails
}
