import { configureRequest, replacePathVariables } from '../../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../../networkRequests/apiClient/endpoints'
import env from '../../../../../networkRequests/apiClient/env.config'
import { UserRole } from '../../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetAllItemRequestsForAdminErrorResponse,
  GetAllItemRequestsForAdminRequest,
  GetAllItemRequestsForAdminSuccessResponse
} from './getAllItemRequestsForAdmin.types'

export const getAllItemRequestsForAdminApi = async (
  request: GetAllItemRequestsForAdminRequest
): Promise<GetAllItemRequestsForAdminSuccessResponse> => {
  const response = await configureRequest({
    url: replacePathVariables(urls.admin.getAllItemRequestsForAdmin, {
      offerRequestId: request.offerRequestId
    }),
    method: 'GET',
    data: request,
    params: {
      page: request.page ?? 1,
      size: request.size ?? env.DEFAULT_PAGE_SIZE
    },
    headers: {
      'X-User-Role': UserRole.admin
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllItemRequestsForAdminSuccessResponse
  } else {
    throw response as GetAllItemRequestsForAdminErrorResponse
  }
}

export default getAllItemRequestsForAdminApi
