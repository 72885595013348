import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'
import { OnRowClick } from './Table.types'

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  } as SxProps,

  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column'
  } as SxProps,

  skeletonTableHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    gap: 2,
    width: '100%',
    height: '53px',
    backgroundColor: theme.palette.grey[100],
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderBottomWidth: 0
  } as SxProps,

  skeletonTableBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    py: theme.spacing(2)
  } as SxProps,

  skeletonTableBodyRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
    height: '53px'
  } as SxProps,

  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto',
    borderRadius: theme.spacing(2)
  } as SxProps,

  table: {
    borderCollapse: 'collapse'
  } as SxProps,

  title: {
    marginBottom: 1,
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  tableRow: (onRowClick?: OnRowClick) => ({
    cursor: onRowClick ? 'pointer' : 'default',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: onRowClick ? 'action.hover' : 'inherit'
    }
  }),

  tableHeadCell: {
    fontWeight: 600,
    backgroundColor: theme.palette.grey[200],
    border: '1px solid rgba(224, 224, 224, 1)',
    position: 'sticky',
    top: 0,
    zIndex: 1
  } as SxProps,

  tableCell: {
    whiteSpace: 'nowrap',
    border: '1px solid rgba(224, 224, 224, 1)'
  } as SxProps,

  loadingCell: {
    padding: 0
  } as SxProps,

  emptyCell: {
    textAlign: 'center',
    padding: 4
  } as SxProps,

  paginationContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2
  },

  paginationButton: {
    minWidth: 100
  },

  paginationText: {
    color: 'text.secondary'
  }
} as const

export default styles
