import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { resendEmailVerificationApi } from './resendEmailVerification.api'
import resendEmailVerificationInitialState from './resendEmailVerification.initialState'
import {
  ResendEmailVerificationErrorResponse,
  ResendEmailVerificationRequestPayload
} from './resendEmailVerification.types'

export const resendEmailVerification = createAsyncThunk(
  urls.auth.resendEmailVerification,
  async (payload: ResendEmailVerificationRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await resendEmailVerificationApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const resendEmailVerificationSlice = createSlice({
  name: urls.auth.resendEmailVerification,
  initialState: resendEmailVerificationInitialState,
  reducers: {
    resetResendEmailVerification: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendEmailVerification.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(resendEmailVerification.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(resendEmailVerification.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ResendEmailVerificationErrorResponse
        state.success = null
      })
  }
})

export const { actions: resendEmailVerificationActions, reducer: resendEmailVerificationReducer } =
  resendEmailVerificationSlice
export const { resetResendEmailVerification } = resendEmailVerificationActions
export default resendEmailVerificationSlice.reducer
