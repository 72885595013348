import { SxProps } from '@mui/material'

const styles = {
  dashboardLayout: {
    margin: 0,
    padding: 0,
    '& .MuiContainer-root': {
      maxWidth: '100% !important',
      paddingLeft: '50px !important',
      paddingRight: '50px !important',
      margin: 0
    },
    '& .MuiDrawer-root': {
      maxWidth: '250px',
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        maxWidth: '280px',
        boxSizing: 'border-box'
      }
    }
  } as SxProps
}

export default styles
