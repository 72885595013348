import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius / 2,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      boxShadow: 'none',
      borderRadius: 0
    }
  } as SxProps
}

export default styles
