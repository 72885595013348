import React from 'react'

import { Box, Skeleton } from '@mui/material'

import styles from './SkeletonLoading.styles'
import { SkeletonLoadingProps } from './SkeletonLoading.types'

const SkeletonLoading: React.ForwardRefRenderFunction<HTMLDivElement, SkeletonLoadingProps> = (props) => {
  const { searchBar, verticalFilters, layout = 'list', isDesktop } = props

  const isMasonry = layout === 'masonry'
  const isLeftContainerPresent = !!verticalFilters && isDesktop

  return (
    <Box sx={styles.skeletonMainContainer(!!searchBar)}>
      {searchBar && isDesktop && (
        <Box sx={styles.searchBarSkeleton}>
          <Skeleton variant="rounded" height={50} width="88%" />
          <Skeleton variant="rounded" height={50} width="12%" />
        </Box>
      )}

      <Box sx={styles.skeletonContentContainer(!!searchBar)}>
        {isLeftContainerPresent && (
          <Box sx={styles.skeletonContentLeftContainer}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Box key={index} sx={styles.skeletonContentLeftContainerItem}>
                <Box sx={styles.skeletonContentLeftContainerItemTitle}>
                  <Skeleton variant="rounded" height={20} width="40%" />
                  <Skeleton variant="rounded" height={10} width="20%" />
                </Box>

                <Skeleton variant="rounded" height={50} width="100%" />
              </Box>
            ))}
          </Box>
        )}

        <Box sx={styles.skeletonContentRightContainer(isLeftContainerPresent)}>
          <Box sx={styles.skeletonGroupHeader}>
            <Box sx={styles.skeletonGroupHeaderTitle}>
              <Skeleton variant="rounded" height={30} width="20%" />
              <Skeleton variant="rounded" height={20} width="30%" />
            </Box>

            <Box sx={styles.skeletonGroupTabContainer}>
              <Box sx={styles.skeletonGroupTabs}>
                <Skeleton variant="rounded" height={20} width="100%" />
                <Skeleton variant="rounded" height={20} width="100%" />
              </Box>

              <Skeleton variant="rounded" height={10} width="100%" />
            </Box>
          </Box>

          <Box sx={styles.skeletonGroupBody(isMasonry)}>
            <Skeleton variant="rounded" height={isMasonry ? 400 : 100} width="100%" sx={styles.searchBarSkeletonItem} />
            <Skeleton variant="rounded" height={isMasonry ? 400 : 100} width="100%" sx={styles.searchBarSkeletonItem} />

            {!isMasonry && (
              <Skeleton
                variant="rounded"
                height={isMasonry ? 400 : 100}
                width="100%"
                sx={styles.searchBarSkeletonItem}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SkeletonLoading
